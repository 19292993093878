/* eslint-disable react/prop-types */
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
    Box, 
    Skeleton,
    Button,
    ButtonGroup, 
    Dialog, 
    DialogActions, 
    DialogContent,
    TableContainer, 
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Select,
    MenuItem,
    TextField,
    Alert
} from "@mui/material";
import { Snackbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import { executeQuery, queryDB } from "../../../../util/QueryDB";
import { useTheme } from '@mui/material';
import { UserContext } from "../../../../contexts/UserContext";
import { GetNameAndEmpID } from "../../../../util/Loading";
import { Fade } from '@mui/material';
import { admin_server_mapping_get_query_1, admin_server_mapping_get_query_2, admin_server_mapping_get_query_3, admin_server_mapping_get_query_4 } from "../../../../catalystDB/queryGenerator";



export default function ReportConfiguration() {
    const [rows, setRows] = useState(null);
    const {loginUserDept} = useContext(UserContext);
    const theme = useTheme();

    useEffect(() => {

        async function getConfRows() {
            const result = await queryDB(admin_server_mapping_get_query_1(loginUserDept));
            var arr = [];

            for(var r of result) {
                arr.push([r.ServerConfiguration.Server, r.ServerConfiguration.Clusters, r.ServerConfiguration.ROWID,r.ServerConfiguration.IssueProductType]);
            }

            setRows(arr);
        }

        getConfRows();

    }, []);

    if(rows === null) {
        return (
            <Box className='page-content-2'>
                <Skeleton variant="rectangular" width={"100%"} height={35} />
                <br></br>
                <Skeleton variant="rectangular" width={"100%"} sx={{ height: "calc(100vh - 175px)" }} />
            </Box>
        );
    }

    return (
        <Box className='page-content-2'>
            {rows.length === 0 ? (
               <Fade in={true}>
               <div style={{ color: theme.palette.background.text }}>
               <TopBar rows={rows} setRows={setRows} />
                   <h1>No Tasks Found :(</h1>
               </div>
              </Fade>
            ) : (
                <>
                    <TopBar rows={rows} setRows={setRows} />
                    <Content rows={rows} setRows={setRows} />
                </>
            )}
        </Box>
    );
}

function TopBar({rows, setRows}) {

    const [openPopup, setOpenPopup] = useState(false);

    const handleMenuOpen = (event) => {
		setOpenPopup(true);
	};

    const HandleClosePopup = () => {
		setOpenPopup(false);
	};

    return (
        <div className="mytasks-top-bar">
            <div className="mytasks-top-bar-left-group">
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Button variant="contained" size="small" onClick={handleMenuOpen}>
                        <AddIcon />
                    </Button>
                </ButtonGroup>
            </div>
            <Dialog open={openPopup} onClose={HandleClosePopup} fullScreen>
                <DialogActions>
                    <Button onClick={HandleClosePopup} variant="text" fontSize="large"><CloseIcon /></Button>
                </DialogActions>
                <DialogContent>
                    <AddNewConf setOpenPopup={setOpenPopup} rows={rows} setRows={setRows}/>
                </DialogContent>
            </Dialog>
        </div>
    );
}

function Content({rows, setRows}) {
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('');
    return (
        <>
        <div className="mytasks-table">
            <TableContainer className="mytasks-table-container" sx={{
                flex: 1
            }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow className="mytasks-table-head">
                            <TableCell align="left" sx={{maxWidth: "5px"}}>S.No.</TableCell>
                            <TableCell align="center">Server</TableCell>
                            <TableCell align="center">Clusters</TableCell>
                            <TableCell align="center">IssueProductType</TableCell>
                            <TableCell align="right" sx={{maxWidth: "5px"}} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows.map((row, index) => {
                                return <Row index={index} row={row} rows={rows} setRows={setRows} key={index} alertMessage={alertMessage} alertOpen={alertOpen} setAlertMessage={setAlertMessage} setAlertOpen={setAlertOpen} alertSeverity={alertSeverity} setAlertSeverity={setAlertSeverity}/>
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
            <div>
                <Snackbar
                    open={alertOpen}
                    autoHideDuration={3000} // Adjust the duration as needed
                    onClose={() => setAlertOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                >
                    <Alert
                        onClose={() => setAlertOpen(false)}
                        severity={alertSeverity} 
                    >
                        {alertMessage}
                    </Alert>
                </Snackbar>
            </div>
        </>
    );
}

function Row({index, row, rows, setRows , alertMessage, alertOpen, setAlertMessage, setAlertOpen, alertSeverity, setAlertSeverity}) {
    const theme = useTheme();
    const [isHovered, setIsHovered] = useState(false);

    // const [alertOpen, setAlertOpen] = useState(false);
    // const [alertMessage, setAlertMessage] = useState('');

    return (
        <TableRow
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
                '& > *': { borderBottom: 'unset' },
                backgroundColor: theme.palette.background.tableRow,
                '&:hover': {
                    backgroundColor: theme.palette.background.tableRowHover + " !important"
                }
            }}
        >
            <TableCell>{index+1}</TableCell>
            <TableCell align="center">{row[0]}</TableCell>
            <TableCell align="center">{row[1]}</TableCell>
            <TableCell align="center">{row[3]}</TableCell>
            <TableCell align="right">
                <Button sx={{
                    borderRadius: "300px",
                    visibility: isHovered ? "visible" : "hidden"
                }} color="error">
                    <DeleteIcon fontSize="small" onClick={() => handleRowDelete(row, rows, setRows , alertMessage,alertOpen,setAlertMessage,setAlertOpen, alertSeverity, setAlertSeverity)}/>
                </Button>
            </TableCell>
        </TableRow>
    );
}

async function handleRowDelete(row, rows, setRows, alertMessage, alertOpen, setAlertMessage, setAlertOpen, alertSeverity, setAlertSeverity) {
    try {
        const rowID = row[2]; 

        var newRows = rows.filter((r) => {
            if (r === row) {
                return false;
            } else {
                return true;
            }
        });
        setRows(newRows);
        // console.log(rowID);
        try {
            await executeQuery(admin_server_mapping_get_query_2(rowID));
            setAlertMessage('Deleted successfully');
            setAlertOpen(true);
            setAlertSeverity("success")
        } catch (e) {
            setAlertMessage('Failed to Delete :(');
            setAlertOpen(true);
            setAlertSeverity("error");
        }
    } catch(e) {
        
    }
}



function AddNewConf({setOpenPopup, rows, setRows}) {
    // const {allServers} = useContext(UserContext);
  

    const {teamDetails,allServers,loginUserDept} = useContext(UserContext);
    const theme = useTheme();
    var options = [];
    var labels = [];
    for(const people in teamDetails.current) {
        if(people === undefined || people === null || people === "") {
            continue;
        }
        options.push(people);
        labels.push(<GetNameAndEmpID emails={people} />);
    }
    const [selectedOption, setSelectedOption] = useState("");
    const [clusterName, setClusterName] = useState("");
    const [issueProductType, setIssueProductType] = useState("");
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertSeverity, setAlertSeverity] = useState(null);

    const [error, setError] = useState('');

  

   const handleFormSubmit = async (event) => {
    event.preventDefault();
  
    let found = false;
  
    for (const row of rows) {
      if (row[0] === selectedOption) {
        found = true;
        try {
          const result = await executeQuery(admin_server_mapping_get_query_3(selectedOption, clusterName, issueProductType, row));
          setAlertSeverity("success");
          setAlertMessage("Successfully updated!");
          setRows((prevRows) =>
            prevRows.map((prevRow) =>
              prevRow[2] === row[2]
                ? [result[0].ServerConfiguration.Server, result[0].ServerConfiguration.Clusters, result[0].ServerConfiguration.ROWID, result[0].ServerConfiguration.IssueProductType]
                : prevRow
            )
          );
          setTimeout(() => {
            setAlertMessage(null);
            setOpenPopup(false);
          }, 1500);
        } catch (e) {
          setAlertSeverity("error");
          setAlertMessage("Unable to update :(");
          setTimeout(() => {
            setAlertMessage(null);
          }, 3000);
        }
        break;
      }
    }
  
    if (!found) {
        // const parsedArray = JSON.parse(clusterName);
      if (
        clusterName[0] === "[" &&
        clusterName[clusterName.length - 1] === "]" &&
        clusterName[1] === '"' &&
        clusterName[clusterName.length - 2] === '"' &&
        issueProductType[0] === "[" &&
        issueProductType[issueProductType.length - 1] === "]" &&
        issueProductType[1] === '"' &&
        issueProductType[issueProductType.length - 2] === '"'
      ) {
        try {
          const result = await executeQuery(admin_server_mapping_get_query_4(selectedOption, clusterName, issueProductType, loginUserDept));
          setAlertSeverity("success");
          setAlertMessage("Successfully added!");
          setRows([...rows, [result[0].ServerConfiguration.Server, result[0].ServerConfiguration.Clusters, result[0].ServerConfiguration.ROWID, result[0].ServerConfiguration.IssueProductType]]);
          setTimeout(() => {
            setAlertMessage(null);
            setOpenPopup(false);
          }, 1500);
        } catch (e) {
          setAlertSeverity("error");
          setAlertMessage("Unable to add :(");
          setTimeout(() => {
            setAlertMessage(null);
          }, 3000);
        }
      } else {
        setAlertSeverity("error");
        setAlertMessage('Please enter values in the correct format (e.g., ["wsserver-pretesting"] or ["zvpmediaserver-pre,zvpmediaserver-main"]).');
        setTimeout(() => {
          setAlertMessage(null);
        }, 3000);
      }
    }
  };
  

   


    return (
        <Box sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            fontFamily: 'Poppins, sans-serif',
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
            backgroundColor: theme.palette.mode === "light" ? "#fff" : "1A1E24"
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                justifyContent: "center",
                fontFamily: 'Poppins, sans-serif',
                width: "90%",
                height: "88vh",
                padding: "0px 50px 10px 50px",
                border: "1px solid #ddd", // Add a border to create the outer box
                borderRadius: "5px", // Add border radius for rounded corners
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)", // Add a shadow for depth
                color: theme.palette.background.color,
                backgroundColor: theme.palette.mode === "light" ? "#fff" : "1A1E24",
                gap: "50px"
            }}>
                {
                    alertMessage !== null ?
                        <Alert severity={alertSeverity} sx={{marginTop: "10px"}}>{alertMessage}</Alert>
                        :
                        null

                }
                <form>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "100px",
                    alignItems: "center",
                    justifyContent: "left",
                    paddingTop: "100px",
                    fontFamily: 'Poppins, sans-serif',
                    paddingLeft: "495px"
                }}>
                    <h4>Server Select</h4>
                    <SingleSelect options={options} selectedOption={selectedOption} setSelectedOption={setSelectedOption} allServers={allServers} label={labels} height={"100px"} required={true} />
                </Box>

                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "54px",
                    alignItems: "center",
                    justifyContent: "left",
                    fontFamily: 'Poppins, sans-serif',
                    paddingLeft: "534px"
                }}>
                    <h4>Cluster Name</h4>
                    <TextField id="outlined-basic" variant="outlined" sx={{
                        width: "500px"
                    }} value={clusterName} onChange={(event) => {
                        setClusterName(event.target.value);
                      }}/>
                </Box>

                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "54px",
                    alignItems: "center",
                    justifyContent: "left",
                    fontFamily: 'Poppins, sans-serif',
                    paddingLeft: "500px"
                }}>
                    <h4>IssueProductType</h4>
                    <TextField id="outlined-basic" variant="outlined" sx={{
                        width: "500px"
                    }} value={issueProductType} onChange={(event) => {
                        setIssueProductType(event.target.value);
                      }}/>
                </Box>
                <Box sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: 'Poppins, sans-serif',
                    paddingTop: "100px"
                }}>
                    <Button variant="contained" onClick={handleFormSubmit} style={{ marginTop: 5 }}>Submit</Button>
                </Box>
                </form>
            </Box>
        </Box>
    );
}


function SingleSelect({ options, selectedOption, label, setSelectedOption, height, required = false,allServers }) {

	const [clearButton, setClearButton] = useState(false);
    // const [serversList, setServersList] = useState([]);
    // const sortedServersList = [...serversList].sort((a, b) => a.localeCompare(b));

	function handleSelect(event) 
	{
		setSelectedOption(event.target.value);
        // console.log(event.target.value)
		setClearButton(true);
	}

	function handleMouseEnter() 
	{
		if (selectedOption === "") {
			return;
		}
		setClearButton(true);
	}

	function handleMouseLeave() 
	{
		if (selectedOption === "") {
			return;
		}
		setClearButton(false);
	}

	function handleClearClick() 
	{
		setSelectedOption("");
		setClearButton(false);
	}

	function handleUnfocus(event) 
	{
		setTimeout(() => setClearButton(false), 200);
	}

    // {options.map((value, index) => (
    //     console.log(index)
    // ))};
    // console.log(allServers)
    

	return (
		<Box style={{
			display: "flex",
			flexDirection: "row",
			justifyContent: "left"
		}}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<Select
				required={required}
				labelId="role-user-email"
				value={selectedOption}
				style={{
					minHeight: height,
					height: "auto",
					minWidth: "250px",
					maxWidth: "400px"
				}}
				MenuProps={{
					style: {
						maxHeight: 600
					}
				}}
				onChange={handleSelect}
				onBlur={handleUnfocus}
			>
				{allServers.map((item, index) => {
		           return <MenuItem value={item} key={index}>{item}</MenuItem>
	            })};
                {/* {options.map((value, index) => (
					<MenuItem key={index} value={value}>{label[index]}</MenuItem>
				))}; */}
			</Select>
			<Button style={{ visibility: clearButton ? "visible" : "hidden" }} onClick={handleClearClick}>
				<CancelIcon fontSize="small" color="error" />
			</Button>
		</Box>
	);
}