import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../../../contexts/UserContext.js';
import styles from '../Tickets.css';
import { useTheme } from '@mui/material/styles';
import AllOutIcon from '@mui/icons-material/AllOut';
import BlockIcon from '@mui/icons-material/Block';
import FilterListIcon from '@mui/icons-material/FilterList'; // Add the filter menu icon
import {
  Button,
  ButtonGroup,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Snackbar,
  Alert,  
  CircularProgress
} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { GetNameAndEmpID } from '../../../../util/Loading.js';
import Tooltip from '@mui/material/Tooltip';
import { queryDB } from '../../../../util/QueryDB.js';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Box } from "@mui/material";
import { MoreView } from '../newTicketsTabCore/table/extra_components/MoreView.js';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { MoreMenuApproval } from '../newTicketsTabCore/table/extra_components/MoreMenuApproval.js';
import { team_view_component_get_query_2, team_view_component_get_query_3,team_view_component_get_query_4,team_view_component_get_query_5} from '../../../../catalystDB/queryGenerator.js';

export function Approval() {
    const { userDetails, teamDetails,loginUserEmail,serverConfiguration,GlobalServer,loginUserDept} = useContext(UserContext);
    const [loading, setLoading] = useState(undefined);
    const [data, setData] = useState(undefined);
    const [approvedTickets, setApprovedTickets] = useState([]);
    const theme = useTheme();
    const [showClosedData, setShowClosedData] = useState(false);
    const [showAlert1, setShowAlert1] = useState(false);
    const [showAlert2, setShowAlert2] = useState(false);
    const [showAlert3, setShowAlert3] = useState(false);
    const [moreViewOpen, setMoreViewOpen] = useState(false);
    const [localStatus, setLocalStatus] = useState(null);
    const [localClaimedDRI, setLocalClaimedDRI] = useState(null); // either null or DRI from assign action
    const [clickedRowData, setClickedRowData] = useState(null);
    const [moreMenuAnchor, setMoreMenuAnchor] = useState(null);
    const [moreIconVisible, setMoreIconVisible] = useState(false);
    const [localDeleted, setLocalDeleted] = useState(false);
    const [moreVisibile, setMoreVisible] = useState(true);
    var SelectedGlobalServer = (GlobalServer === "unknown" || GlobalServer === undefined) ?  "No Server" : GlobalServer;
    
    // console.log(team_view_component_get_query_2);

    useEffect(() => {
        setLocalStatus(null);
    }, []);
// 

    useEffect(() => {
        function fetchTicketData() {
            const fetchValuesData = async () => {
                if (SelectedGlobalServer == "No Server") {

                try {
            // console.log("hello");

                    const result = await queryDB(team_view_component_get_query_2(userDetails,loginUserEmail.current,loginUserDept));
                    // console.log("Hi");
                    if (result.length === 0) {
                        setLoading(false);
                        setData(undefined);
                    } else {
                        setData(result);
                        setLoading(true);
                    }
                } catch (error) {
                    // console.log(userDetails);
                    // console.log(error);
                    setLoading(false);
                    setData(undefined);
                }
            }
            };
            fetchValuesData();
        }
        fetchTicketData();
    }, []);


    useEffect(() => {
        setData(undefined);
        function fetchTicketData() {
            const fetchValuesData = async () => {
                try {
                    var result;
                    if(showClosedData === false){
                        if (SelectedGlobalServer === "No Server") {
                            result = await queryDB(team_view_component_get_query_2(userDetails,loginUserEmail.current,loginUserDept));
                        }
                        else{
                            result = await queryDB(team_view_component_get_query_4(userDetails,serverConfiguration,GlobalServer,loginUserDept));
                        }
                         
                    }
                    else{
                        if (SelectedGlobalServer === "No Server") {
                            result = await queryDB(team_view_component_get_query_3(userDetails,loginUserEmail.current,loginUserDept));
                        }
                        else{
                            result = await queryDB(team_view_component_get_query_5(userDetails,serverConfiguration,GlobalServer,loginUserDept));
                        }
                        
                    }
                    // console.log(Hi);
                    // console.log(result);
                    if (result.length === 0) {
                        setLoading(false);
                        setData(null);
                    } else {
                        setData(result);
                        // console.log(result)
                        setLoading(true);
                    }
                } catch (error) {
                    setLoading(false);
                    setData(undefined);
                }
            };
            fetchValuesData();
        }
        fetchTicketData();
    }, [showClosedData,GlobalServer]);

    const handleClick = (row) => {
        setShowAlert1(true);
    
        const url = '/server/desk?task=Approve&ID=' + row.Tickets.ID;
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
    
        fetch(url, {
            method: 'POST',
            headers: headers,
        })
        .then(response => {
            // console.log("Status: " + response.status); 
            if (response.status === 200) {
                setShowAlert1(false);
                setShowAlert2(true);
                setApprovedTickets(prevApprovedTickets => [...prevApprovedTickets, row]);
                const updatedData = data.filter(ticket => ticket.Tickets.ID !== row.Tickets.ID);
                setData(updatedData);
                var detialsSet = [
                    { "Status": "Completed", "ROWID": row.Tickets.ID},
                 ];
                 
                var datastore = window.catalyst.table;
                var table = datastore.tableId('5781000009307031');
                var updatePromise = table.updateRow(detialsSet);
                updatePromise
               .then((response) => {
                    //    console.log(response.content);
                 })
                .catch((err) => {
                    //    console.log(err);
                });

            }
        })
        .catch(error => {
            console.error("Error while fetching:", error);
            if (error.response) {
                console.error("Response:", error.response);
            }
        });
        
    };
    
    
   
    
    const handleClick1 = (row) => {

        setShowAlert1(true);

        const url = '/server/desk?task=NotApproved&ID=' + row.Tickets.ID;
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
        fetch(url, {
            method: 'POST',
            headers: headers,
        })
        .then(response => {
        
            if (response.status === 200) {
                setShowAlert1(false);
                setShowAlert3(true); 
                var detialsSet = [
                    { "Status": "Ticket Rejected", "ROWID": row.Tickets.ID},
                 ];
                 
                var datastore = window.catalyst.table;
                var table = datastore.tableId('5781000009307031');
                var updatePromise = table.updateRow(detialsSet);
                updatePromise
               .then((response) => {
                    //    console.log(response.content);
                 })
                .catch((err) => {
                    //    console.log(err);
                });
            }
        })
        .catch(error => {
            console.error("Error while fetching:", error);
        });

        
    };

    function handleMoreViewClick(data) {
        // console.log(data)
        setClickedRowData(data);
        setMoreViewOpen(true);
    }

    const buttonStyle = {
        backgroundColor: 'green',
        opacity: 0.7,
        color: 'white',
        border: 'black',
        padding: '10px 10px',
        borderRadius: '10px',
        cursor: 'pointer',
        // Add more styles as needed
    };

    const buttonStyle1 = {
        backgroundColor: 'red',
        opacity: 0.7,
        color: 'white',
        border: 'black',
        padding: '10px 10px',
        borderRadius: '10px',
        cursor: 'pointer',
        // Add more styles as needed
    };

    

    const TextOnlyTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
    ))(`
          color: black;
          background-color: white;  
      `);

    // const filteredData = showClosedData ? data.filter(row => row.Tickets.Status === "Completed") : data;

    const toggleClosedData = () => {
        setShowClosedData((prevShowClosedData) => !prevShowClosedData);
    };

    useEffect(() => {
        if (showAlert2) {
            const timer = setTimeout(() => {
                setShowAlert2(false);
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [showAlert2]);

    useEffect(() => {
        if (showAlert3) {
            const timer = setTimeout(() => {
                setShowAlert3(false);
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [showAlert3]);

    if (data === undefined) {
        return (
          
            <Box style={{ 
                position: "fixed",
                top: "50%",
                left: "55%",
                transform: "translate(-50%, -50%)",
                textAlign: "center"
            }}>
                <CircularProgress size={"2.4rem"} />
                <br />
                <span>fetching tickets...</span>
            </Box>
            
            
        );
    }
    else if(data === null) {
        return (
        
            <>
           <div className="tickets-table page-content-2" style={{ color: theme.palette.background.text }}>
               <div  sx={{margin:"20px !important"}} className="milestone-top-bar-right-group">
                    <ButtonGroup variant="contained">
                        <Tooltip title={"toggle Approved/Declined tickets"}>
                            <Button  variant="contained" size="small" onClick={toggleClosedData}>
                                {!showClosedData ? <AllOutIcon /> : <BlockIcon />}
                            </Button>
                        </Tooltip>
                        
                    </ButtonGroup>
                </div>
                <Box sx={{textAlign:"center"}}> No Tickets Found </Box>
            </div>
            </>
        );
        
    }

    else{

        // {data.map((row, index) => (
        //   console.log(row.Tickets)
        // ))}

    return (
        <>
        {/* <ApprovalTopBar toggleClosedData ={toggleClosedData} showClosedData ={showClosedData}/> */}
        <div className="tickets-table page-content-2" style={{ color: theme.palette.background.text }}>
               <div  sx={{margin:"20px !important"}} className="milestone-top-bar-right-group">
                    <ButtonGroup variant="contained">
                        <Tooltip title={"toggle Approved/Declined tickets"}>
                            <Button  variant="contained" size="small" onClick={toggleClosedData}>
                                {!showClosedData ? <AllOutIcon /> : <BlockIcon />}
                            </Button>
                        </Tooltip>
                        
                    </ButtonGroup>
                </div>
            <TableContainer component={Paper} className="mytasks-table-container">
                <Table stickyHeader>
                    <TableHead>
                                       
                        <TableRow className="mytasks-table-head">
                            {showClosedData === false ? (
                                <>
                                    <TableCell align="center" className={styles.bold}> DeskTicketNumber </TableCell>
                                    <TableCell align="center">Product Name</TableCell>
                                    <TableCell align="center">Issue/Query</TableCell>
                                    <TableCell align="left">Raised By</TableCell>
                                    <TableCell align="left">Current DRI</TableCell>
                                    <TableCell align="center">Resolution Given</TableCell>
                                    <TableCell align="center">Allow To Close</TableCell>
                                    <TableCell align="center">Give Proper Resolution</TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align='right'></TableCell>
                                </>
                            ): (
                                <>
                                    <TableCell align="center" className={styles.bold}> DeskTicketNumber </TableCell>
                                    <TableCell align="center">Product Name</TableCell>
                                    <TableCell align="center">Issue/Query</TableCell>
                                    <TableCell align="left">Raised By</TableCell>
                                    <TableCell align="left">Current DRI</TableCell>
                                    <TableCell align="center">Resolution Given</TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align='right'></TableCell>
                                </>
                            )}
                            
                           
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow key={index} className="mytasks-table-head">
                              <TableCell className={`${styles.centeredCell} ${styles.desk_link}`}>
                                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                        {row.Tickets.DeskTicketNumber && (
                                            <a href={row.Tickets.DeskTicketURL} target="_blank" className={styles.deskTicketLink}>
                                                {row.Tickets.DeskTicketNumber}
                                            </a>
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell align="center">{row.Tickets.Product_Type}</TableCell>
                                <TableCell
                                    className={["", " ", undefined, null].some((value) => row.Tickets.Type === value) ? 'slide-animation' : ''}
                                    align="center"
                                    style={{ width: "10vw", maxWidth: "13vw", wordWrap: "break-word" }}
                                >
                                    {["", " ", undefined, null].some((value) => row.Tickets.Type === value) ? (
                                        <span
                                            style={{
                                                cursor: 'pointer',
                                                textDecoration: row.clicked ? 'underline' : 'none',
                                                fontWeight: row.clicked ? 'bold' : 'normal',
                                            }}
                                        >
                                            {row.Tickets.Type === "Issue" ?
                                                row.Tickets.Issue === "" ? "-" : "Issue"
                                                :
                                                row.Tickets.Query === "" ? "-" : "Query"}
                                        </span>
                                    ) : (
                                        <TextOnlyTooltip
                                            title={
                                                <span style={{ fontFamily: 'Poppins', fontSize: '0.875rem', color: "Black", backgroundImage: "" }}>
                                                    {row.Tickets.Type === "Issue" ?
                                                        row.Tickets.Issue === "" ? "-" : row.Tickets.Issue
                                                        :
                                                        row.Tickets.Query === "" ? "-" : row.Tickets.Query}
                                                </span>
                                            }
                                            followCursor
                                        >
                                            <span
                                                style={{
                                                    cursor: 'pointer',
                                                    textDecoration: row.clicked ? 'underline' : 'none',
                                                    fontWeight: row.clicked ? 'bold' : 'normal',
                                                }}
                                            >
                                                {row.Tickets.Type === "Issue" ?
                                                    row.Tickets.Issue === "" ? "-" : "Issue"
                                                    :
                                                    row.Tickets.Query === "" ? "-" : "Query"}
                                            </span>
                                        </TextOnlyTooltip>
                                    )}
                                </TableCell>
                                <TableCell align="left">{<GetNameAndEmpID emails={[row.Tickets.IssueReportedBy]} />}</TableCell>

                                <TableCell align="center">{<GetNameAndEmpID emails={[row.Tickets.Current_Developer]} />}</TableCell>
                                <TableCell align="center">
                                    {["", " ", undefined, null].some((value) => row.Tickets.Status === value) ? (
                                        <span style={{ fontFamily: 'Poppins', fontSize: '0.875rem', color: "Black", backgroundImage: "" }}>
                                            {row.Tickets.Type === "Issue" ?
                                                row.Tickets.Issue === "" ? "-" : "Issue Resolved"
                                                :
                                                row.Tickets.Query === "" ? "-" : "Query Resolved"}
                                        </span>
                                    ) : (
                                        <TextOnlyTooltip
                                            title={
                                                <span style={{ fontFamily: 'Poppins', fontSize: '0.875rem', color: "Black", backgroundImage: "" }}>
                                                    {row.Tickets.Type === "Issue" ?
                                                        row.Tickets.Issue === "" ? "-" : row.Tickets.IssueResolutionGiven
                                                        :
                                                        row.Tickets.Query === "" ? "-" : row.Tickets.QueryResolutionGiven}
                                                </span>
                                            }
                                            followCursor
                                        >
                                            <span
                                                style={{
                                                    cursor: 'pointer',
                                                    textDecoration: row.clicked ? 'underline' : 'none',
                                                    fontWeight: row.clicked ? 'bold' : 'normal',
                                                }}
                                            >{row.Tickets.Type === "Issue" ? (row.Tickets.Issue === "" ? "-" : "Issue Resolved") : (row.Tickets.Query === "" ? "-" : "Query Resolved")}</span>
                                        </TextOnlyTooltip>
                                    )}
                                </TableCell>
                                {showClosedData === false ? (
                                    <>
                                     <TableCell align="center">
                                     <Button style={buttonStyle} onClick={() => handleClick(row)}>
                                         Approve
                                     </Button>
                                 </TableCell>
                                 <TableCell align="center">
                                     <Button style={buttonStyle1} onClick={() => handleClick1(row)}>
                                         Reject
                                     </Button>
                                 </TableCell>
                                 </>
                                ) : (
                                    <></>
                                )}
                                
                                <TableCell  className={styles.serial_number} align='right'> 
                                    <Button onClick={() => handleMoreViewClick(row)}  sx={{borderRadius: "100px", padding: "1px"}}>
                                        <InfoOutlinedIcon />
                                    </Button>
                                    <MoreView data={clickedRowData && clickedRowData.Tickets}   open={moreViewOpen} setOpen={setMoreViewOpen} localStatus={localStatus} localClaimedDRI={localClaimedDRI} handleClaimButtonClick={"No data"} />
                                    
                                 </TableCell>  
                                 
                                 <TableCell align="right" style={{width: "2px", cursor: "pointer"}}>
                                     <MoreMenuApproval className='slide-animation' moreVisibile={moreVisibile} setMoreVisible={setMoreVisible} row={row} />
                                 </TableCell>
                             
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
           
            {/* Render the approved tickets component only when showClosedData is true */}
            {/* {showClosedData && approvedTicketsComponent} */}
        </div>
        <Snackbar
                    open={showAlert1}

                    onClose={() => setShowAlert1(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert severity="success">Status is updating.. </Alert>
                </Snackbar>

                <Snackbar
                    open={showAlert2}

                    onClose={() => setShowAlert2(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert severity="success">Tickect has been approved 👍 </Alert>
                </Snackbar>

                <Snackbar
                    open={showAlert3}

                    onClose={() => setShowAlert3(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert severity="success">Tickect has been rejected ❌ </Alert>
                </Snackbar>
        </>
    );
 }
 
}
