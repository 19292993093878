export const APP_DATA_FETCHER_1_DEFAULT = "SELECT * FROM TeamDetails";
export const APP_DATA_FETCHER_2_DEFAULT = "SELECT CREATEDTIME,CREATORID,Email_Id,MODIFIEDTIME,ROWID,refreshTokenLastUpdatedTime,refreshTokenScopes,userId FROM Token";

export const QUERY_DB_1_DEFAULT = "SELECT DISTINCT DRI from TaskBreaker WHERE Component='Zone'";
export const QUERY_DB_2_DEFAULT = "SELECT * FROM SupportMemberForm";

export const ROADMAP_DATA_FETCHER_1_DEFAULT = "SELECT * FROM TaskBreaker WHERE ReportName='Roadmap_Form_Report'";

export const MILESTONE_DATA_FETCHER_1_DEFAULT = "SELECT * FROM TaskDetails";
export const MILESTONE_DATA_FETCHER_2_DEFAULT = "SELECT TaskID FROM TaskBreaker";

export const BUILD_DATA_FETCHER_1_DEFAULT = `SELECT * FROM AllBuildDetails`;
export const MY_TASKS_DATA_FETCHER_1_DEFAULT = "SELECT * FROM TaskBreaker AND ReportName='TaskBreaker_Data_Report'";

export const TASK_BREAKER_DATA_FETCHER_1_DEFAULT = "SELECT * FROM TaskBreaker AND ReportName='TaskBreaker_Data_Report'";
export const TASK_BREAKER_DATA_FETCHER_2_DEFAULT = "SELECT * FROM TeamDetails";

export const INCIDENT_DATA_FETCHER_1_DEFAULT = "SELECT * FROM IncidentReport";

// #################################################################

export function milestone_component_get_query_1(milestone_id) {
    return `SELECT * FROM TaskDetails WHERE MilestoneId= '${milestone_id}' GROUP BY MilestoneId `;
}

export function milestone_component_get_query_2(milestone_id) {
    return `SELECT * FROM TaskDetails WHERE MilestoneId= '${milestone_id}' `;
}

// #################################################################


export function quarterly_component_get_query_1(loginUserDept) {
    return `SELECT * FROM QuarterlyRMap WHERE department LIKE '*${loginUserDept.current}*' `;
}

export function quarterly_component_get_query_2(loginUserDept) {
    return `SELECT * FROM TaskBreaker WHERE department LIKE '*${loginUserDept.current}*' `;
}

export function quarterly_component_get_query_3(loginUserDept) {
    return `SELECT * FROM RoadmapExternal WHERE NewRoadMapName != 'null' AND department LIKE '*${loginUserDept.current}*' `;
}

export function quarterly_component_get_query_4(arr, index) {
    return `SELECT * FROM TaskDetails WHERE MilestoneId = '` + arr[index][9] + `'`;
}

// #################################################################

export function quarterly_info_component_get_query_1(MilestoneID) {
    return `SELECT ServerName FROM QuarterlyRMap WHERE MilestoneID = '` + MilestoneID + `'`;
}

export function quarterly_info_component_get_query_2(MilestoneID) {
    return `SELECT * FROM TaskDetails WHERE MilestoneId = '` + MilestoneID + `'`;
}

// #################################################################

export function quarterly_roadmap_component_get_query_1(queryFilter) {
    return `SELECT * FROM TaskBreaker WHERE ReportName= 'Roadmap_Form_Report' AND (${queryFilter}) AND TaskStatus not in ('Closed', 'Cancelled') AND Tasklist_created_for_roadmap is null ORDER BY Component`;
}

export function quarterly_roadmap_component_get_query_2() {
    return `SELECT NewRoadMapName FROM TaskBreaker WHERE NewRoadMapName != '' `;
}

export function quarterly_roadmap_component_get_query_3(queryFilter) {
    return `SELECT * FROM TaskBreaker WHERE (${queryFilter}) AND ReportName= 'Roadmap_Form_Report' AND TaskStatus not in ('Closed', 'Cancelled') AND Tasklist_created_for_roadmap is null ORDER BY Component`;
}

export function quarterly_roadmap_component_get_query_4(queryFilter) {
    return `SELECT * FROM TaskBreaker WHERE (${queryFilter}) AND ReportName= 'Roadmap_Form_Report' AND TaskStatus not in ('Closed', 'Cancelled') AND Tasklist_created_for_roadmap is null ORDER BY Component`;
}

export function quarterly_roadmap_component_get_query_5() {
    return `SELECT * FROM TaskBreaker WHERE CombinedRoadmap  is not null AND ReportName= 'Roadmap_Form_Report' AND TaskStatus not in ('Closed', 'Cancelled') AND Tasklist_created_for_roadmap is null ORDER BY Component`;
}

export function quarterly_roadmap_component_get_query_6() {
    return `SELECT * FROM TaskBreaker where Type_Of_Roadmap ='external' AND ReportName= 'Roadmap_Form_Report'`;
}

export function quarterly_roadmap_component_get_query_7() {
    return `SELECT * FROM TaskBreaker where Type_Of_Roadmap ='internal' AND ReportName= 'Roadmap_Form_Report'`;
}

export function quarterly_roadmap_component_get_query_8(queryFilter) {
    return `SELECT * FROM TaskBreaker WHERE (${queryFilter}) AND ReportName='Roadmap_Form_Report' AND TaskStatus != 'Closed' AND Tasklist_created_for_roadmap is null ORDER BY Component`;
}

export function quarterly_roadmap_component_get_query_9() {
    return `SELECT Roadmap FROM RoadmapExternal`;
}

// #################################################################

export function quarterly_milestone_card_component_get_query_1(MilestoneID) {
    return `SELECT * FROM TaskDetails WHERE MilestoneId = '` + MilestoneID + `'`;
}

// #################################################################

export function team_view_component_get_query_1(actions, loginUserDept, serverConfiguration, offset, ignore_list, view, ORDER_BY, ROWS_PER_FETCH,loginUserEmail) {
    var server_products = [];
    try {      
        actions.filters.servers.map(serv => {
            if (serv in serverConfiguration.current) {
                const products = JSON.parse(serverConfiguration.current[serv].IssueProductType);
                products.forEach(prod => {
                    if (!server_products.includes(prod)) {
                        server_products.push(prod);
                    }
                });
            }
        });
    } catch (e) { }

    var ignore_filter = ``;
    try {
        if (ignore_list.includes(loginUserEmail)) {
            ignore_filter = ` (IssueReportedBy NOT IN ('${ignore_list.join(`','`)}')) AND `;
        }
        else{
            ignore_filter = ` (Product_Type NOT IN ('Test')) AND `;
        }
        // ignore_filter = ignore_list.length === 0 ? `` : ` (IssueReportedBy NOT IN ('${ignore_list.join(`','`)}')) AND `;
    } catch (e) { }

    const issue_search = ` Issue LIKE '*${actions?.search_text}*' OR `;
    const query_search = ` Query LIKE '*${actions?.search_text}*' OR `;
    const reported_by_search = ` IssueReportedBy LIKE '*${actions?.search_text}*' OR `;
    const all_developers_search = ` AllDevelopers LIKE '*${actions?.search_text}*' OR `;
    // const desk_ticket_number_search = ` DeskTicketNumber LIKE '*${actions?.search_text}*' OR `;
    const desk_ticket_number_search = ` TicketID LIKE '*${actions?.search_text}*' `;
    // const all_forwarded_search = ` AllForwarded_From_to_To LIKE '*${actions?.search_text}*' `;

    const search_filter = (actions?.search_text) !== undefined ?
        ` (${issue_search} ${query_search} ${reported_by_search} ${all_developers_search} ${desk_ticket_number_search} ) AND `
        :
        ``;

    const closed_filter = actions?.show_closed === true ?
            ` (Status = 'Completed') AND ` 
            : 
        actions?.show_closed === false ?
            ` (Status NOT IN ('Completed','Ticket Rejected')) AND `
            :
        actions?.show_closed === null ? 
            ` (Status = 'Ticket Rejected') AND `
            :
            ``;

    const server_filter = actions?.filters?.servers.length !== 0 ?
        ` ( Product_Type IN ('${server_products.join(`','`)}') ) AND `
        :
        ``;

    const module_filter = actions?.filters?.module.length !== 0 ?
        ` ( Module IN ('${actions?.filters?.module.join(`','`)}') ) AND `
        :
        ``;

    const type_filter = actions?.filters?.type.length !== 0 ?
        ` ( SupportType IN ('${actions?.filters?.type.join(`','`)}') ) AND `
        :
        ``;

    const support_type_filter = actions?.filters?.type.length !== 0 ? type_filter : module_filter;

    const department_filter = ` (department LIKE '*${loginUserDept.current}*') `;

    const sort_by = ` ORDER BY ` + ORDER_BY[actions.sort];

    const limit_suffix = ` LIMIT ${offset}, ${ROWS_PER_FETCH} `;

    const query = `SELECT * FROM Tickets WHERE (${ignore_filter} ${search_filter} ${closed_filter} ${server_filter} ${support_type_filter} ${department_filter}) ${sort_by} ${limit_suffix} `;

    return query;
}

export function team_view_component_get_query_2(userDetails,loginUserEmail,loginUserDept){
   if(loginUserEmail === 'mariaraj@zohocorp.com'){
        return `SELECT * FROM Tickets WHERE Status = 'Resolution Waiting For Approval' order by CREATEDTIME DESC`;
    }
    else{
        return `SELECT * FROM Tickets WHERE Approval_To LIKE '*${userDetails.current.auth.email_id}*' AND Status = 'Resolution Waiting For Approval' AND department LIKE '*${loginUserDept.current}*' order by CREATEDTIME DESC`;
    }
}


export function team_view_component_get_query_3(userDetails,loginUserEmail,loginUserDept){
    if(loginUserEmail === 'mariaraj@zohocorp.com'){
        return `SELECT * FROM Tickets WHERE Status = 'Completed' order by CREATEDTIME DESC`;
    }
    else{
    return `SELECT * FROM Tickets WHERE Approval_To LIKE '*${userDetails.current.auth.email_id}*' AND Status = 'Completed' AND department LIKE '*${loginUserDept.current}*' order by CREATEDTIME DESC`;
    }
 }

 export function team_view_component_get_query_4(userDetails,serverConfiguration,GlobalServer,loginUserDept){

    var server_products = [];
    try {      
     
            if (GlobalServer in serverConfiguration.current) {
                const products = JSON.parse(serverConfiguration.current[GlobalServer].IssueProductType);
                products.forEach(prod => {
                    if (!server_products.includes(prod)) {
                        server_products.push(prod);
                    }
                });
            }
    } catch (e) { }
   
 return `SELECT * FROM Tickets WHERE Approval_To LIKE '*${userDetails.current.auth.email_id}*' AND Status = 'Resolution Waiting For Approval' AND  Product_Type IN ('${server_products.join(`','`)}') AND department LIKE '*${loginUserDept.current}*' order by CREATEDTIME DESC`;
     
 }

 export function team_view_component_get_query_5(userDetails,serverConfiguration,GlobalServer,loginUserDept){

    var server_products = [];
    try {      
     
            if (GlobalServer in serverConfiguration.current) {
                const products = JSON.parse(serverConfiguration.current[GlobalServer].IssueProductType);
                products.forEach(prod => {
                    if (!server_products.includes(prod)) {
                        server_products.push(prod);
                    }
                });
            }
    } catch (e) { }
   
 return `SELECT * FROM Tickets WHERE Approval_To LIKE '*${userDetails.current.auth.email_id}*' AND Status = 'Completed' AND  Product_Type IN ('${server_products.join(`','`)}') AND department LIKE '*${loginUserDept.current}*' order by CREATEDTIME DESC`;
     
 }

 export function team_view_component_get_query_6(actions,serverConfiguration,DeskTicketNumber,loginUserDept){

    var server_products = [];
    try {      
        actions.filters.servers.map(serv => {
            if (serv in serverConfiguration.current) {
                const products = JSON.parse(serverConfiguration.current[serv].IssueProductType);
                products.forEach(prod => {
                    if (!server_products.includes(prod)) {
                        server_products.push(prod);
                    }
                });
            }
        });
    } catch (e) { }


    return `SELECT * FROM Tickets WHERE DeskTicketNumber LIKE '*${DeskTicketNumber}*' AND Product_Type IN ('${server_products.join(`','`)}') AND department LIKE '*${loginUserDept.current}*'`;
 }

// #################################################################

export function tickets_pre_claim_get_query_1(data) {
    return `DELETE FROM Tickets WHERE DeskTicketNumber= '${data.DeskTicketNumber}' AND ID= '${data.ID}' AND ROWID= '${data.ROWID}'`;
}

// #################################################################

export function roadmap_data_updater_get_query_1(removeFromCatalyst) {
    return `DELETE FROM TaskBreaker WHERE CreatorRowid in ('${removeFromCatalyst.join("','")}')`;
}

export function roadmap_data_updater_get_query_2(creatorRowIDs) {
    return `DELETE FROM TaskBreaker WHERE CreatorRowid in ('${creatorRowIDs.join("','")}')`;
}

// #################################################################

export function roadmap_component_get_query_1(queryFilter, loginUserDept) {
    return `SELECT * FROM TaskBreaker WHERE ReportName= 'Roadmap_Form_Report' AND (${queryFilter}) AND TaskStatus not in ('Closed', 'Cancelled') AND Tasklist_created_for_roadmap is null AND department LIKE '*${loginUserDept.current}*' ORDER BY Component`;
}

export function roadmap_component_get_query_2(queryFilter, loginUserDept) {
    return `SELECT * FROM TaskBreaker WHERE (${queryFilter}) AND ReportName= 'Roadmap_Form_Report' AND TaskStatus not in ('Closed', 'Cancelled') AND Tasklist_created_for_roadmap is null AND department LIKE '*${loginUserDept.current}*' ORDER BY Component`;
}

export function roadmap_component_get_query_3(loginUserDept) {
    return `SELECT * FROM TaskBreaker WHERE CombinedRoadmap  is not null AND ReportName= 'Roadmap_Form_Report' AND TaskStatus not in ('Closed', 'Cancelled') AND Tasklist_created_for_roadmap is null AND department LIKE '*${loginUserDept.current}*' ORDER BY Component`;
}

export function roadmap_component_get_query_4(queryFilter, loginUserDept) {
    return `SELECT * FROM TaskBreaker WHERE (${queryFilter}) AND ReportName='Roadmap_Form_Report' AND TaskStatus != 'Closed' AND Tasklist_created_for_roadmap is null AND department LIKE '*${loginUserDept.current}*' ORDER BY Component`;
}

export function roadmap_component_get_query_5(dept) {
    return `SELECT * FROM TeamDetails WHERE department LIKE '*${dept}*'`;
}

// #################################################################

export function estimator_component_get_query_1(selectedOption) {
    return `SELECT TaskName FROM TaskBreaker where ReportName='TaskBreaker_Data_Report' and TaskPriority ='High' and Component = '${selectedOption}' and TaskStatus != 'Closed'`;
}

export function estimator_component_get_query_2(selectedOption) {
    return `SELECT TaskName FROM TaskBreaker where ReportName='TaskBreaker_Data_Report' and TaskPriority ='Medium' and Component = '${selectedOption}' and TaskStatus != 'Closed'`;
}

export function estimator_component_get_query_3(selectedOption) {
    return `SELECT TaskName FROM TaskBreaker where ReportName='TaskBreaker_Data_Report' and TaskPriority ='Low' and Component = '${selectedOption}' and TaskStatus != 'Closed'`;
}

// #################################################################

export function info_div_get_query_1(milestoneId) {
    return `SELECT TaskID FROM TaskBreaker WHERE MilestoneID = '${milestoneId}'`;
}

// #################################################################

export function milestone_task_get_query_1(tasklistId) {
    return `SELECT * FROM TaskBreaker WHERE TaskListid= '${tasklistId}' AND ReportName= 'TaskBreaker_Data_Report' AND IsTaskDeleted is null`;
}

export function milestone_task_get_query_2(tasklistId) {
    return `SELECT * FROM TaskBreaker WHERE TaskListid= '${tasklistId}' AND ReportName= 'TaskBreaker_Data_Report' AND IsTaskDeleted is null`;
}

export function milestone_task_get_query_3(allRows, index) {
    return `SELECT * FROM TaskBreaker WHERE ReportName= 'TaskBreaker_Data_Report' AND DRI = '${allRows[index][7]}' AND (TaskStatus = 'Ongoing' OR TaskStatus = 'In Progress') AND IsTaskDeleted is null`;
}

export function milestone_task_get_query_4(allRows, index) {
    return `SELECT * FROM TaskBreaker WHERE ReportName= 'TaskBreaker_Data_Report' AND DRI = '${allRows[index][7]}' AND (TaskStatus = 'Ongoing' OR TaskStatus = 'In Progress') AND IsTaskDeleted is null`;
}

// #################################################################

export function milestone_main_component_get_query_1(user_email, queryStringSuffix,loginUserDept) {
    return `SELECT * FROM TaskDetails WHERE (Email= '${user_email}' OR Component IN ${queryStringSuffix}) AND MilestoneStatus not in ('Completed','Cancelled') AND Approval_Status not in ('Waiting For Approval') AND IsTasklistDeleted is null AND department LIKE '*${loginUserDept.current}*' GROUP BY MilestoneId ORDER BY Component`;
}

export function milestone_main_component_get_query_2(user_email, queryStringSuffix, toggleClosedFilter, loginUserDept) {
    return `SELECT * FROM TaskDetails WHERE (Email= '${user_email}' OR Component IN ${queryStringSuffix}) AND ${toggleClosedFilter} AND IsTasklistDeleted is null AND department LIKE '*${loginUserDept.current}*' GROUP BY MilestoneId ORDER BY Component`;
}

export function milestone_main_component_get_query_3(user_email, queryStringSuffix, toggleClosedFilter) {
    return `SELECT * FROM TaskDetails WHERE (Email= '${user_email}' OR Component IN ${queryStringSuffix}) AND ${toggleClosedFilter} AND IsTasklistDeleted is null GROUP BY MilestoneId ORDER BY Component`;
}

export function milestone_main_component_get_query_4(queryFilter, toggleClosedFilter, loginUserDept) {
    return `SELECT * FROM TaskDetails WHERE (${queryFilter}) AND ${toggleClosedFilter} AND IsTasklistDeleted is null AND department LIKE '*${loginUserDept.current}*' GROUP BY MilestoneId ORDER BY Component`;
}

export function milestone_main_component_get_query_5(MilestoneRowID, loginUserDept) {
    return `SELECT * FROM TaskDetails WHERE Milestone_Rowid = ${MilestoneRowID} AND department LIKE '*${loginUserDept.current}*'`;
}

export function milestone_main_component_get_query_6(dept) {
    return `SELECT * FROM TeamDetails WHERE department LIKE '*${dept}*'`;
}

export function milestone_main_component_get_query_7(milestoneName,milestoneId) {
    return `SELECT * FROM TaskDetails WHERE MilestoneId= ${milestoneId}  AND MilestoneName= '${milestoneName}'  AND IsTasklistDeleted is null`;
}

// #################################################################

export function tasklist_component_get_query_1(milestoneId, milestoneName) {
    // console.log(`SELECT * FROM TaskDetails WHERE MilestoneId= '${milestoneId}'  AND MilestoneName= '${milestoneName}' AND IsTasklistDeleted is null`)
    return `SELECT * FROM TaskDetails WHERE MilestoneId= '${milestoneId}'  AND MilestoneName= '${milestoneName}' AND IsTasklistDeleted is null`;
}

export function tasklist_component_get_query_2(milestoneId, milestoneName) {
    // console.log(`SELECT * FROM TaskDetails WHERE MilestoneId= '${milestoneId}'  AND MilestoneName= '${milestoneName}' AND IsTasklistDeleted is null`)
    return `SELECT * FROM TaskDetails WHERE MilestoneId= '${milestoneId}'  AND MilestoneName= '${milestoneName}' AND IsTasklistDeleted is null`;
}

// #################################################################

export function admin_external_ticket_users_get_query_1() {
    return `SELECT * from SupportMemberForm`;
}

// #################################################################

export function admin_report_configuration_get_query_1(loginUserDept) {
    return `SELECT * from AlertChannelConfiguration WHERE department LIKE '*${loginUserDept.current}*'`;
}

export function admin_report_configuration_get_query_2(rowID) {
    return `DELETE FROM AlertChannelConfiguration WHERE ROWID = ${rowID}`;
}

export function admin_report_configuration_get_query_3(selectedOption, channelID,loginUserDept) {
    return `INSERT INTO AlertChannelConfiguration VALUES (${selectedOption},${channelID},${loginUserDept.current})`;
}

// #################################################################

export function admin_build_configuration_get_query(loginUserDept) {
    return `SELECT * from DCConfiguration WHERE department LIKE '*${loginUserDept.current}*'`;
}

export function admin_build_configuration_get_query_2(valuesString, selectedOption, indexString, loginUserDept) {
    return `INSERT INTO DCConfiguration (Server, ${valuesString},department) VALUES ('${selectedOption}', ${indexString},'${loginUserDept.current}')`;
}

export function admin_build_configuration_get_query_3(resultString, selectedOption, loginUserDept) {
    return `UPDATE DCConfiguration SET ${resultString} WHERE Server = ${selectedOption} AND department LIKE '*${loginUserDept.current}*'`;
}

// #################################################################

export function admin_server_mapping_get_query_1(loginUserDept) {
    return `SELECT * from ServerConfiguration WHERE department LIKE '*${loginUserDept.current}*'`;
}

export function admin_server_mapping_get_query_2(rowID) {
    return `DELETE FROM ServerConfiguration WHERE ROWID = ${rowID}`;
}

export function admin_server_mapping_get_query_3(selectedOption, clusterName, issueProductType, row) {
    return `UPDATE ServerConfiguration SET Server='${selectedOption}' ,Clusters='${clusterName}',IssueProductType='${issueProductType}' WHERE ROWID='${row[2]}'`;
}

export function admin_server_mapping_get_query_4(selectedOption, clusterName, issueProductType, loginUserDept) {
    return `INSERT INTO ServerConfiguration (Server,Clusters, IssueProductType,department) VALUES ('${selectedOption}','${clusterName}','${issueProductType}','${loginUserDept.current}')`;
}

// #################################################################

export function admin_zone_configuration_get_query_1() {
    return `SELECT * from Confs`;
}

export function admin_zone_configuration_get_query_2(keyName) {
    return `SELECT * FROM Confs WHERE KEY_ = '${keyName}'`;
}

export function admin_zone_configuration_get_query_3(rowID) {
    return `DELETE FROM Confs WHERE ROWID = ${rowID}`;
}

export function admin_zone_configuration_get_query_4(value, keyName) {
    return `UPDATE Confs SET VALUE='${value}' WHERE KEY_='${keyName}'`;
}

export function admin_zone_configuration_get_query_5(keyName, value) {
    return `INSERT INTO Confs (KEY_, VALUE) VALUES ('${keyName}', '${value}')`;
}

// #################################################################

export function builds_qa_status_component_get_query_1(BuildLabel, dc) {
    return `SELECT * FROM  QATest WHERE newBuildLabel LIKE '*${BuildLabel}*' AND DC LIKE '*${dc}*'`;
}

export function builds_qa_status_component_get_query_2(BuildLabel, dc_with_extra_setups, dc) {
    return `SELECT * FROM QATest WHERE newBuildLabel LIKE '*${BuildLabel}*' AND DC LIKE '*${dc_with_extra_setups[dc][0]}*' AND cluster LIKE '*-${dc_with_extra_setups[dc][1]}*' `;
}

// #################################################################

export function builds_self_builds_component_get_query_1(userDetails, loginUserDept) {
    return `SELECT * FROM  AllBuildDetails  WHERE BuildBy LIKE '*${userDetails.current.auth.email_id}*' AND department LIKE '*${loginUserDept.current}*' ORDER BY CREATEDTIME DESC`;
}

// #################################################################

export function builds_build_status_component_get_query_1(Server, loginUserDept) {
    return `SELECT * FROM  DCConfiguration  WHERE Server LIKE '*${Server}*' AND department LIKE '*${loginUserDept.current}*' `;
}

// #################################################################

export function builds_my_builds_component_get_query_1() {
    return `SELECT * FROM QATest`;
}

// #################################################################

export function builds_server_builds_component_get_query_1(user_email, queryStringSuffix, loginUserDept) {
    return `SELECT * FROM  AllBuildDetails WHERE (BuildBy= '${user_email}' OR Server IN ${queryStringSuffix}) AND department LIKE '*${loginUserDept.current}*' ORDER BY CREATEDTIME DESC`;
}

// #################################################################

export function builds_build_update_component_get_query_1(user_servers, loginUserDept) {
    if(user_servers === "No Server")
    {
        // console.log(user_servers)
        return `SELECT * FROM AllBuildDetails WHERE department LIKE '*${loginUserDept.current}*' ORDER BY CREATEDTIME DESC`;
    }
    else{
        // console.log(user_servers)
    return `SELECT * FROM AllBuildDetails WHERE department LIKE '*${loginUserDept.current}*' AND Server = '${user_servers}' ORDER BY CREATEDTIME DESC`;
    }
    
}

// #################################################################

export function my_tasks_component_get_query_1(user_email, toggleClosedFilter, loginUserDept) {
    return `SELECT * FROM TaskBreaker WHERE DRI= '${user_email}' AND ReportName= 'TaskBreaker_Data_Report' AND ${toggleClosedFilter} AND IsTaskDeleted is null AND department LIKE '*${loginUserDept.current}*'`;
}

export function my_tasks_component_get_query_2(queryFilter, toggleClosedFilter, loginUserDept) {
    return `SELECT * FROM TaskBreaker WHERE ${queryFilter} AND ReportName= 'TaskBreaker_Data_Report' AND ${toggleClosedFilter} AND IsTaskDeleted is null AND department LIKE '*${loginUserDept.current}*'`;
}

export function my_tasks_component_get_query_3(user_email, toggleClosedFilter, loginUserDept) {
    return `SELECT * FROM TaskBreaker WHERE DRI= '${user_email}' AND ReportName= 'TaskBreaker_Data_Report' AND ${toggleClosedFilter} AND IsTaskDeleted is null AND department LIKE '*${loginUserDept.current}*'`;
}

export function my_tasks_component_get_query_4(queryFilter, toggleClosedFilter, loginUserDept) {
    return `SELECT * FROM TaskBreaker WHERE ${queryFilter} AND ReportName= 'TaskBreaker_Data_Report' AND ${toggleClosedFilter} AND IsTaskDeleted is null AND department LIKE '*${loginUserDept.current}*'`;
}

export function my_tasks_component_get_query_5(toggleClosedFilter, loginUserDept) {
    return `SELECT * FROM TaskBreaker WHERE  ReportName= 'TaskBreaker_Data_Report' AND ${toggleClosedFilter} AND IsTaskDeleted is null AND department LIKE '*${loginUserDept.current}*'`;
}

export function my_tasks_component_get_query_6(toggleClosedFilter, loginUserDept) {
    return `SELECT * FROM TaskBreaker WHERE  ReportName= 'TaskBreaker_Data_Report' AND ${toggleClosedFilter} AND IsTaskDeleted is null AND department LIKE '*${loginUserDept.current}*'`;
}

export function my_tasks_component_get_query_7(queryFilter, toggleClosedFilter, loginUserDept) {
    return `SELECT * FROM TaskBreaker WHERE ${queryFilter} AND ReportName= 'TaskBreaker_Data_Report' AND ${toggleClosedFilter} AND IsTaskDeleted is null AND department LIKE '*${loginUserDept.current}*'`;
}

export function my_tasks_component_get_query_8(allRows, index) {
    return `SELECT * FROM TaskBreaker WHERE ReportName= 'TaskBreaker_Data_Report' AND DRI = '${allRows[index][7]}' AND (TaskStatus = 'Ongoing' OR TaskStatus = 'In Progress')`;
}

export function my_tasks_component_get_query_9(dept) {
    return `SELECT * FROM TeamDetails WHERE department LIKE '*${dept}*'`;
}

// #################################################################

export function dashboard_data_fetcher_get_query_1(ONE_WEEK_BEFORE_TODAY, loginUserDept) {
    return `SELECT * FROM TaskBreaker WHERE (CREATEDTIME >= '${ONE_WEEK_BEFORE_TODAY}' OR TaskStartDate_ >= '${ONE_WEEK_BEFORE_TODAY}' OR TaskDueDate_ >= '${ONE_WEEK_BEFORE_TODAY}' OR TaskCompletionDate >= '${ONE_WEEK_BEFORE_TODAY}') AND department LIKE '*${loginUserDept.current}*' AND IsTaskDeleted is null`;
}

export function dashboard_data_fetcher_get_query_2(ONE_WEEK_BEFORE_TODAY, loginUserDept) {
    return `SELECT * FROM TaskDetails WHERE (TasklistStartDate_ >= '${ONE_WEEK_BEFORE_TODAY}' OR TasklistDueDate_ >= '${ONE_WEEK_BEFORE_TODAY}' OR TasklistCompletionDate >= '${ONE_WEEK_BEFORE_TODAY}') AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null`;
}

export function dashboard_data_fetcher_get_query_3(ONE_WEEK_BEFORE_TODAY, loginUserDept) {
    return `SELECT * FROM Tickets WHERE (CREATEDTIME >= '${ONE_WEEK_BEFORE_TODAY}' OR ResolvedOn >= '${ONE_WEEK_BEFORE_TODAY}') AND department LIKE '*${loginUserDept.current}*' `;
}

export function dashboard_data_fetcher_get_query_4(ONE_WEEK_BEFORE_TODAY, loginUserDept) {
    return `SELECT * FROM QATest WHERE (startedAt >= '${ONE_WEEK_BEFORE_TODAY}') AND department LIKE '*${loginUserDept.current}*' `;
}

export function dashboard_data_fetcher_get_query_5(ONE_WEEK_BEFORE_TODAY, loginUserDept) {
    return `SELECT * FROM AllBuildDetails WHERE (CREATEDTIME >= '${ONE_WEEK_BEFORE_TODAY}') AND department LIKE '*${loginUserDept.current}*' `;
}

export function dashboard_data_fetcher_get_query_6(ONE_YEAR_BEFORE_TODAY, loginUserDept) {
    return `SELECT * FROM TaskBreaker WHERE (CREATEDTIME >= '${ONE_YEAR_BEFORE_TODAY}' OR TaskStartDate_ >= '${ONE_YEAR_BEFORE_TODAY}' OR TaskDueDate_ >= '${ONE_YEAR_BEFORE_TODAY}' OR TaskCompletionDate >= '${ONE_YEAR_BEFORE_TODAY}') AND department LIKE '*${loginUserDept.current}*' AND IsTaskDeleted is null `;
}

export function dashboard_data_fetcher_get_query_7(ONE_YEAR_BEFORE_TODAY, loginUserDept) {
    return `SELECT * FROM TaskDetails WHERE (TasklistStartDate_ >= '${ONE_YEAR_BEFORE_TODAY}' OR TasklistDueDate_ >= '${ONE_YEAR_BEFORE_TODAY}' OR TasklistCompletionDate >= '${ONE_YEAR_BEFORE_TODAY}') AND department LIKE '*${loginUserDept.current}*'AND IsTasklistDeleted is null  `;
}

export function dashboard_data_fetcher_get_query_8(ONE_YEAR_BEFORE_TODAY, loginUserDept) {
    return `SELECT * FROM Tickets WHERE (CREATEDTIME >= '${ONE_YEAR_BEFORE_TODAY}' OR ResolvedOn >= '${ONE_YEAR_BEFORE_TODAY}') AND department LIKE '*${loginUserDept.current}*' `;
}

export function dashboard_data_fetcher_get_query_9(ONE_YEAR_BEFORE_TODAY, loginUserDept) {
    return `SELECT * FROM QATest WHERE (startedAt >= '${ONE_YEAR_BEFORE_TODAY}') AND department LIKE '*${loginUserDept.current}*' `;
}

export function dashboard_data_fetcher_get_query_10(ONE_YEAR_BEFORE_TODAY, loginUserDept) {
    return `SELECT * FROM AllBuildDetails WHERE (CREATEDTIME >= '${ONE_YEAR_BEFORE_TODAY}') AND department LIKE '*${loginUserDept.current}*' `;
}

// #################################################################


export function round_table_get_query_1(loginUserDept,userServers,userModule) {

    userServers = userServers.split(",");
    userServers = userServers.join("','");

    userModule = userModule.split(',');
    userModule = userModule.map(module => `'${module}'`).join(',');

    return `SELECT * FROM ExternalFeatureReport WHERE TypeOfRoadmap ='External' AND (TasklistStatus != 'Closed' Or TasklistStatus is null) AND CompletionDate is null AND Over_All_ETA is not null AND department LIKE '*${loginUserDept.current}*' AND (Module in ('${userServers}') OR Module in (${userModule}))`;
}

export function round_table_get_query_2(loginUserDept,GlobalServer) {
    return `SELECT * FROM ExternalFeatureReport WHERE TypeOfRoadmap ='External' AND (TasklistStatus != 'Closed' Or TasklistStatus is null) AND CompletionDate is null AND Over_All_ETA is not null AND department LIKE '*${loginUserDept.current}*' AND (Module = '${GlobalServer}')`;
}

export function round_table_get_query_3(loginUserDept,userServers,userModule) {

    userServers = userServers.split(",");
    userServers = userServers.join("','");

    userModule = userModule.split(',');
    userModule = userModule.map(module => `'${module}'`).join(',');

    return `SELECT * FROM ExternalFeatureReport WHERE TypeOfRoadmap ='External' AND TasklistStatus = 'Closed' AND CompletionDate is not null AND department LIKE '*${loginUserDept.current}*' AND (Module in ('${userServers}') OR Module in (${userModule})) order by CompletionDate DESC`;
}

export function round_table_get_query_4(loginUserDept,GlobalServer) {
    return `SELECT * FROM ExternalFeatureReport WHERE TypeOfRoadmap ='External' AND TasklistStatus = 'Closed' AND CompletionDate is not null AND department LIKE '*${loginUserDept.current}*' AND (Module = '${GlobalServer}') order by CompletionDate DESC`;
}

export function round_table_get_query_5(loginUserDept,userServers,userModule) {

    userServers = userServers.split(",");
    userServers = userServers.join("','");

    userModule = userModule.split(',');
    userModule = userModule.map(module => `'${module}'`).join(',');

    return `SELECT * FROM ExternalFeatureReport WHERE TypeOfRoadmap ='External' AND TasklistStatus is null AND Over_All_ETA is null AND department LIKE '*${loginUserDept.current}*' AND (Module in ('${userServers}') OR Module in (${userModule}))`;
}

export function round_table_get_query_6(loginUserDept,GlobalServer) {
    return `SELECT * FROM ExternalFeatureReport WHERE TypeOfRoadmap ='External' AND TasklistStatus is null AND Over_All_ETA is null AND department LIKE '*${loginUserDept.current}*' AND (Module = '${GlobalServer}')`;
}

// #################################################################

export function internal_component_get_query_1(loginUserDept,userServers,userModule) {
    
    userServers = userServers.split(",");
    userServers = userServers.join("','");
    
    userModule = userModule.split(',');
    userModule = userModule.map(module => `'${module}'`).join(',');

    return `SELECT * FROM ExternalFeatureReport WHERE TypeOfRoadmap ='Internal' AND (TasklistStatus != 'Closed' Or TasklistStatus is null) AND CompletionDate is null AND Over_All_ETA is not null AND department LIKE '*${loginUserDept.current}*' AND (Module in ('${userServers}') OR Module in (${userModule}))`;
}

export function internal_component_get_query_2(loginUserDept,GlobalServer) {
    return `SELECT * FROM ExternalFeatureReport WHERE TypeOfRoadmap ='Internal' AND (TasklistStatus != 'Closed' Or TasklistStatus is null) AND CompletionDate is null AND Over_All_ETA is not null AND department LIKE '*${loginUserDept.current}*' AND (Module = '${GlobalServer}')`;
}

export function internal_component_get_query_3(loginUserDept,userServers,userModule) {
     
    userServers = userServers.split(",");
    userServers = userServers.join("','");

    userModule = userModule.split(',');
    userModule = userModule.map(module => `'${module}'`).join(',');

    return `SELECT * FROM ExternalFeatureReport WHERE TypeOfRoadmap ='Internal' AND TasklistStatus = 'Closed' AND CompletionDate is not null AND department LIKE '*${loginUserDept.current}*' AND (Module in ('${userServers}') OR Module in (${userModule})) order by CompletionDate DESC`;
}

export function internal_component_get_query_4(loginUserDept,GlobalServer) {
    return `SELECT * FROM ExternalFeatureReport WHERE TypeOfRoadmap ='Internal' AND TasklistStatus = 'Closed' AND CompletionDate is not null AND department LIKE '*${loginUserDept.current}*' AND (Module = '${GlobalServer}') order by CompletionDate DESC`;
}

export function internal_component_get_query_5(loginUserDept,userServers,userModule) {
     
    userServers = userServers.split(",");
    userServers = userServers.join("','");

    userModule = userModule.split(',');
    userModule = userModule.map(module => `'${module}'`).join(',');

    return `SELECT * FROM ExternalFeatureReport WHERE TypeOfRoadmap ='Internal' AND TasklistStatus is null AND Over_All_ETA is null AND department LIKE '*${loginUserDept.current}*' AND (Module in ('${userServers}') OR Module in (${userModule}))`;
}

export function internal_component_get_query_6(loginUserDept,GlobalServer) {
    return `SELECT * FROM ExternalFeatureReport WHERE TypeOfRoadmap ='Internal' AND TasklistStatus is null AND Over_All_ETA is null AND department LIKE '*${loginUserDept.current}*' AND (Module = '${GlobalServer}')`;
}

// #################################################################

export function task_breaker_component_get_query_1(queryFilter) {
    return `SELECT * FROM TaskBreaker WHERE ReportName= 'TaskBreaker_Data_Report' AND (${queryFilter}) AND TaskStatus not in ('Closed', 'Cancelled','Updated to IDC') AND IsTaskDeleted is null ORDER BY Component`;
}

export function task_breaker_component_get_query_2(queryFilter, query_String_Suffix, toggleClosedFilter) {
    return `SELECT * FROM TaskBreaker WHERE (${queryFilter}) AND ReportName = 'TaskBreaker_Data_Report' AND Component IN ${query_String_Suffix} AND ${toggleClosedFilter} AND IsTaskDeleted is null ORDER BY Component`;
}

export function task_breaker_component_get_query_3(queryFilter, toggleClosedFilter) {
    return `SELECT * FROM TaskBreaker WHERE (${queryFilter}) AND ReportName= 'TaskBreaker_Data_Report' AND ${toggleClosedFilter} AND IsTaskDeleted is null ORDER BY Component`;
}

export function task_breaker_component_get_query_4(queryFilter, toggleClosedFilter) {
    return `SELECT * FROM TaskBreaker WHERE ReportName= 'TaskBreaker_Data_Report' AND (${queryFilter}) AND ${toggleClosedFilter} AND IsTaskDeleted is null ORDER BY Component`;
}

export function task_breaker_component_get_query_5(dept) {
    return `SELECT * FROM TeamDetails WHERE department LIKE '*${dept}*'`;
}

// #################################################################

export function report_3_report_data_get_query_1(status, startDate, endDate, filter, loginUserDept) {
    return "SELECT * FROM TaskDetails WHERE MilestoneStatus = '" + status + "' AND CREATEDTIME >= '" + startDate + "' AND CREATEDTIME <= '" + endDate + "' " + filter + " AND department LIKE '*" + loginUserDept.current + "*' GROUP BY MilestoneId";
}

export function report_3_report_data_get_query_2(startDate, endDate, filter, loginUserDept) {
    return `SELECT * FROM TaskDetails WHERE CREATEDTIME >= '${startDate}' AND CREATEDTIME <= '${endDate}' ${filter} AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null`;
}

export function report_3_report_data_get_query_3(startDate, endDate, filter, loginUserDept) {
    return `SELECT * FROM TaskBreaker WHERE CREATEDTIME >= '${startDate}' AND CREATEDTIME <= '${endDate}' ${filter} AND ReportName != 'Roadmap_Form_Report' AND department LIKE '*${loginUserDept.current}*'`;
}

// #################################################################

export function raw_report_data_fetcher_get_query_1(selectedval) {
    return `SELECT MilestoneName FROM TaskDetails WHERE Component ='${selectedval}' AND IsTasklistDeleted is null`;
}

export function raw_report_data_fetcher_get_query_2(chartStatus, loginUserDept) {
    return `SELECT TasklistName FROM TaskDetails WHERE MilestoneName ='${chartStatus}' AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null`;
}

export function raw_report_data_fetcher_get_query_3(individualTaskName, loginUserDept) {
    return `SELECT DRI FROM TaskDetails WHERE TaskListName ='${individualTaskName}' AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null`;
}

export function raw_report_data_fetcher_get_query_4(individualTaskName, loginUserDept) {
    return `SELECT TasklistStartDate_	FROM TaskDetails WHERE TaskListName ='${individualTaskName}' AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null`;
}

export function raw_report_data_fetcher_get_query_5(individualTaskName, loginUserDept) {
    return `SELECT TasklistDueDate_	FROM TaskDetails WHERE TaskListName ='${individualTaskName}' AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null`;
}

export function raw_report_data_fetcher_get_query_6(individualTaskName, loginUserDept) {
    return `SELECT TasklistStatus FROM TaskDetails WHERE TaskListName = '${individualTaskName}' AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null`;
}

export function raw_report_data_fetcher_get_query_7(selectedval, loginUserDept) {
    return `SELECT TaskName FROM TaskBreaker WHERE TaskListName ='${selectedval.value}' AND department LIKE '*${loginUserDept.current}*' AND IsTaskDeleted is null`;
}

export function raw_report_data_fetcher_get_query_8(individualTaskName, loginUserDept) {
    return `SELECT DRI FROM TaskBreaker WHERE TaskName ='${individualTaskName}' AND department LIKE '*${loginUserDept.current}*' AND IsTaskDeleted is null`;
}

export function raw_report_data_fetcher_get_query_9(individualTaskName, loginUserDept) {
    return `SELECT TaskStartDate_ FROM TaskBreaker WHERE TaskName ='${individualTaskName}' AND department LIKE '*${loginUserDept.current}*' AND IsTaskDeleted is null`;
}

export function raw_report_data_fetcher_get_query_10(individualTaskName, loginUserDept) {
    return `SELECT TaskDueDate_ FROM TaskBreaker WHERE TaskName ='${individualTaskName}' AND department LIKE '*${loginUserDept.current}*' AND IsTaskDeleted is null`;
}

export function raw_report_data_fetcher_get_query_11(individualTaskName, loginUserDept) {
    return ` SELECT TaskStatus FROM TaskBreaker WHERE TaskName = '${individualTaskName}' AND department LIKE '*${loginUserDept.current}*' AND IsTaskDeleted is null`;
}

// #################################################################

export function report_3_data_fetcher_get_query_1(timeRange, subjectType, subject, teamDetails, loginUserDept, findMentees) {
    var [start, end] = timeRange;
    start = start.toISOString().split("T")[0];
    end = end.toISOString().split("T")[0];

    // var queryPrefix = `SELECT DISTINCT(MilestoneId),
    //                     MilestoneName,
    //                     Email, 
    //                     MilestoneStartDate_, 
    //                     MilestoneDueDate_, 
    //                     MilestoneCompletionDate,
    //                     MilestoneType,
    //                     Release_Type
    //                 FROM TaskDetails`;

    var queryPrefix = `SELECT * FROM TaskDetails `;
    queryPrefix = queryPrefix.replaceAll("\n", "");
    
    var subjectFilter = ``;
    if(subjectType === "Server") {
        subjectFilter = ` Component= '` + subject + `' `;
    } else if(subjectType === "MentorTree") {
        var mentees = findMentees([subject], teamDetails);
        mentees.push(subject);

        subjectFilter = ` Email IN ('${mentees.join("','")}') `;
    } else if (subjectType === "Mentor") {
        subjectFilter = ` Email= '` + subject + `' `;
    }

    var startDateFilter = ` (MilestoneStartDate_ >= '` + start + `' AND MilestoneStartDate_ <= '` + end + `') `;
    var dueDateFilter = ` (MilestoneDueDate_ >= '` + start + `' AND MilestoneDueDate_ <= '` + end + `') `;
    var completionDateFilter = ` (MilestoneCompletionDate >= '` + start + `' AND MilestoneCompletionDate <= '` + end + `') `;
    // var dateFilter = ` (` + startDateFilter + ` OR ` + dueDateFilter + ` OR ` + completionDateFilter + `) `;
    var dateFilter =  ` (` + startDateFilter + `) `;

    var filter = ` WHERE (${subjectFilter} AND ${dateFilter} AND IsTasklistDeleted IS NULL AND department LIKE '*${loginUserDept.current}*' ) AND  IsTasklistDeleted is null GROUP BY MilestoneId `;

    var query = queryPrefix + filter;

    return query
}

export function report_3_data_fetcher_get_query_2(milestoneIdArray, teamDetails, loginUserDept) {
    
    var queryPrefix = ` SELECT * from TaskBreaker `;

    var subjectFilter = ` (MilestoneID IN ('${milestoneIdArray.join("','")}')) `;

    var roadmapFilter = ` ReportName= 'TaskBreaker_Data_Report' `;
    
    var query = queryPrefix + ` WHERE ` + subjectFilter + ` AND ` + roadmapFilter + ` AND department LIKE '*${loginUserDept.current}*' AND IsTaskDeleted is null`;

    return query;
}

export function report_3_data_fetcher_get_query_3(timeRange, subjectType, subject, teamDetails, loginUserDept, findMentees) {
    var [start, end] = timeRange;
    start = start.toISOString().split("T")[0];
    end = end.toISOString().split("T")[0];

    var queryPrefix = `SELECT * FROM TaskDetails`;
    queryPrefix = queryPrefix.replaceAll("\n", "");
    
    var subjectFilter = ``;
    if(subjectType === "Server") {
        subjectFilter = ` Component= '` + subject + `' `;
    } else if(subjectType === "MentorTree" ) {
        var mentees = findMentees([subject], teamDetails);
        mentees.push(subject);

        subjectFilter = ` Email IN ('${mentees.join("','")}') `;
    } else if (subjectType === "Mentor") {
        subjectFilter = ` Email= '` + subject + `' `;
    }

    var startDateFilter = ` (MilestoneStartDate_ >= '` + start + `' AND MilestoneStartDate_ <= '` + end + `') `;
    var dueDateFilter = ` (MilestoneDueDate_ >= '` + start + `' AND MilestoneDueDate_ <= '` + end + `') `;
    var completionDateFilter = ` (MilestoneCompletionDate >= '` + start + `' AND MilestoneCompletionDate <= '` + end + `') `;
    // var dateFilter = ` (` + startDateFilter + ` OR ` + dueDateFilter + ` OR ` + completionDateFilter + `) `;
    var dateFilter =  ` (` + startDateFilter + `) `;

    var filter = ` WHERE (${subjectFilter} AND ${dateFilter} AND IsTasklistDeleted IS NULL AND department LIKE '*${loginUserDept.current}*' ) AND IsTasklistDeleted is null`;

    var query = queryPrefix + filter;

    return query
}

export function report_3_data_fetcher_get_query_4(tasklistNameArray, tasklistComponentArray, teamDetails, loginUserDept) {
    var queryPrefix = ` SELECT * from TaskBreaker `;

    var subjectFilter = ` (TaskName IN ('${tasklistNameArray.join("','")}')) `;

    var roadmapFilter = ` ReportName= 'Roadmap_Form_Report' `;

    var componentFilter = ` Component IN '${tasklistComponentArray.join("','")}' `;
    
    var query = queryPrefix + ` WHERE ` + subjectFilter + ` AND ` + componentFilter + ` AND ` + roadmapFilter + ` AND department LIKE '*` + loginUserDept.current + `*' AND IsTaskDeleted is null`;

    return query;
}

export function report_3_data_fetcher_get_query_5(milestoneID, teamDetails, loginUserDept) {
    var queryPrefix = ` SELECT * from TaskBreaker `;

    var subjectFilter = ` (MilestoneID= '${milestoneID}') `;

    var roadmapFilter = ` ReportName= 'TaskBreaker_Data_Report' `;
    
    var query = queryPrefix + ` WHERE ` + subjectFilter + ` AND ` + roadmapFilter + ` AND department LIKE '*${loginUserDept.current}*' AND IsTaskDeleted is null`;

    return query;
}

export function report_3_data_fetcher_get_query_6(timeRange, subjectType, subject, teamDetails, loginUserDept, findMentees) {
    var [start, end] = timeRange;
    start = start.toISOString().split("T")[0];
    end = end.toISOString().split("T")[0];

    var queryPrefix = `SELECT * FROM BuildBasicForm `;
    queryPrefix = queryPrefix.replaceAll("\n", "");
    
    var subjectFilter = ``;
    if(subjectType === "Server") {
        subjectFilter = ` server= '` + subject + `' `;
    } else if(subjectType === "MentorTree") {
        var mentees = findMentees([subject], teamDetails);
        mentees.push(subject);

        subjectFilter = ` buildBy IN ('${mentees.join("','")}') `;
    } else if (subjectType === "Mentor") {
        subjectFilter = ` buildBy= '` + subject + `' `;
    }

    var startDateFilter = ` (CREATEDTIME >= '` + start + `' AND CREATEDTIME <= '` + end + `') `;
    var dateFilter = ` (` + startDateFilter + `) `;

    var filter = ` WHERE (` + subjectFilter + ` AND ` + dateFilter + ` AND department LIKE '*${loginUserDept.current}*' ) `;

    var query = queryPrefix + filter;

    return query
}

export function report_3_data_fetcher_get_query_7(timeRange, subjectType, subject, teamDetails, loginUserDept, findMentees, findPeopleUnderServer) {
    var [start, end] = timeRange;
    start = start.toISOString().split("T")[0];
    end = end.toISOString().split("T")[0];

    var queryPrefix = `SELECT * FROM Tickets `;
    queryPrefix = queryPrefix.replaceAll("\n", "");
    
    var subjectFilter = ``;
    if(subjectType === "Server") {
        const people = findPeopleUnderServer(subject, teamDetails);
        subjectFilter = ` ClaimedBy_AssingedTo IN ('${people.join("','")}') OR ClaimedBy_Mail_ID IN ('${people.join("','")}') OR Assigned_To IN ('${people.join("','")}') `;
    } else if (subjectType === "MentorTree") {
        var mentees = findMentees([subject], teamDetails);
        mentees.push(subject);

        subjectFilter = ` ClaimedBy_AssingedTo IN ('${mentees.join("','")}') OR ClaimedBy_Mail_ID IN ('${mentees.join("','")}') OR Assigned_To IN  ('${mentees.join("','")}') `;
    } else if (subjectType === "Mentor") {
        subjectFilter = ` ClaimedBy_AssingedTo LIKE '*${subject}*' OR AllForwarded_From_to_To LIKE '*${subject}*' OR ClaimedBy_Mail_ID LIKE '*${subject}*' OR Assigned_To LIKE '*${subject}*' OR ForwardHistory LIKE '*${subject}*' `;
    }

    var startDateFilter = ` (CREATEDTIME >= '` + start + `' AND CREATEDTIME <= '` + end + `') `;
    var dateFilter = ` (` + startDateFilter + `) `;

    var filter = ` WHERE (` + subjectFilter + ` AND ` + dateFilter + ` AND department LIKE '*${loginUserDept.current}*' ) `;

    var query = queryPrefix + filter;

    return query
}

export function report_3_data_fetcher_get_query_8(timeRange, subjectType, subject, teamDetails, loginUserDept, findMentees) {
    var [start, end] = timeRange;
    start = start.toISOString().split("T")[0];
    end = end.toISOString().split("T")[0];

    var queryPrefix = `SELECT * FROM BuildDetails `;
    queryPrefix = queryPrefix.replaceAll("\n", "");
    
    var subjectFilter = ``;
    if(subjectType === "Server") {
        subjectFilter = ` server= '${subject}' `;
    } else if(subjectType === "MentorTree") {
        var mentees = findMentees([subject], teamDetails);
        mentees.push(subject);

        subjectFilter = ` buildBy IN ('${mentees.join("','")}') `;
    }else if (subjectType === "Mentor") {
        subjectFilter = ` buildBy= '${subject}' `;
    }

    var startDateFilter = ` (CREATEDTIME >= '` + start + `' AND CREATEDTIME <= '` + end + `') `;
    var dateFilter = ` (` + startDateFilter + `) `;

    var filter = ` WHERE (` + subjectFilter + ` AND ` + dateFilter + ` AND department LIKE '*${loginUserDept.current}*' ) `;

    var query = queryPrefix + filter;

    return query
}

export function report_3_data_fetcher_get_query_9(timeRange, subjectType, subject, teamDetails, loginUserDept, findMentees) {
    
    var [start, end] = timeRange;
    start = start.toISOString().split("T")[0];
    end = end.toISOString().split("T")[0];

    var queryPrefix = `SELECT * FROM TaskBreaker `;
    
    var subjectFilter = ``;
    if(subjectType === "Server") {
        subjectFilter = ` Component= '${subject}' `;
    } else if(subjectType === "MentorTree") {
        var mentees = findMentees([subject], teamDetails);
        mentees.push(subject);

        subjectFilter = ` DRI IN ('${mentees.join("','")}') `;
    } else if (subjectType === "Mentor") {
        subjectFilter = ` DRI= '${subject}' `;
    }

    var startDateFilter = ` (TaskStartDate_ >= '` + start + `' AND TaskStartDate_ <= '` + end + `') `;
    var dueDateFilter = ` (TaskDueDate_ >= '` + start + `' AND TaskDueDate_ <= '` + end + `') `;
    var completionDateFilter = ` (TaskCompletionDate >= '` + start + `' AND TaskCompletionDate <= '` + end + `') `;
    // var dateFilter = ` (` + startDateFilter + ` OR ` + dueDateFilter + ` OR ` + completionDateFilter + `) `;
    var dateFilter =  ` (` + startDateFilter + `) `;

    var filter = ` WHERE (` + subjectFilter + ` AND ` + dateFilter + ` AND department LIKE '*` + loginUserDept.current  + `*' ) AND IsTaskDeleted is null`;

    var query = queryPrefix + filter;

    return query
}

export function report_3_data_fetcher_get_query_10(timeRange, listOfClusters, teamDetails, loginUserDept) {
    var [start, end] = timeRange;
    start = start.toISOString().split("T")[0];
    end = end.toISOString().split("T")[0];

    var queryPrefix = `SELECT * FROM QATest `;
    
    var subjectFilter = ` cluster IN ('${listOfClusters.join("','")}') `;

    var startDateFilter = ` (startedAt >= '` + start + `' AND startedAt <= '` + end + `') `;
    var dateFilter = ` (` + startDateFilter + `) `;

    var filter = ` WHERE (` + subjectFilter + ` AND ` + dateFilter + ` AND department LIKE '*${loginUserDept.current}*' ) `;

    var query = queryPrefix + filter;

    return query
}

// #################################################################

export function report_3_data_fetcher_get_query_11(timeRange, subjectType, subject, teamDetails, loginUserDept, findMentees, findPeopleUnderServer) {
    const start = new Date(timeRange[0]).toISOString().split("T")[0];
    const end = new Date(timeRange[1]).toISOString().split("T")[0];

    var subjectFilter = ``;
    if(subjectType === "Server") {
        const people = findPeopleUnderServer(subject, teamDetails);
        subjectFilter = ` ClaimedBy_AssingedTo IN ('${people.join("','")}') OR ClaimedBy_Mail_ID IN ('${people.join("','")}') OR Assigned_To IN ('${people.join("','")}') `;
    } else if (subjectType === "MentorTree") {
        var mentees = findMentees([subject], teamDetails);
        mentees.push(subject);

        subjectFilter = ` ClaimedBy_AssingedTo IN ('${mentees.join("','")}') OR ClaimedBy_Mail_ID IN ('${mentees.join("','")}') OR Assigned_To IN  ('${mentees.join("','")}') `;
    } else if (subjectType === "Mentor") {
        subjectFilter = ` ClaimedBy_AssingedTo LIKE '*${subject}*' OR AllForwarded_From_to_To LIKE '*${subject}*' OR ClaimedBy_Mail_ID LIKE '*${subject}*' OR Assigned_To LIKE '*${subject}*' OR ForwardHistory LIKE '*${subject}*' `;
    }

    const query = `SELECT * FROM Tickets WHERE (CREATEDTIME >= '${start}' AND CREATEDTIME <= '${end}') AND (${subjectFilter}) AND department LIKE '*${loginUserDept.current}*' `;

    return query;
}

export function report_3_data_fetcher_get_query_12(timeRange, subjectType, subject, teamDetails, loginUserDept, findMentees) {
    var [start, end] = timeRange;
    start = start.toISOString().split("T")[0];
    end = end.toISOString().split("T")[0];

    var queryPrefix = `SELECT * FROM TaskBreaker `;
    
    var subjectFilter = ``;
    if(subjectType === "Server") {
        subjectFilter = ` Component= '${subject}' `;
    } else if(subjectType === "MentorTree") {
        var mentees = findMentees([subject], teamDetails);
        mentees.push(subject);

        subjectFilter = ` DRI IN ('${mentees.join("','")}') `;
    } else if (subjectType === "Mentor") {
        subjectFilter = ` DRI= '${subject}' `;
    }

    var startDateFilter = ` (TaskStartDate_ >= '` + start + `' AND TaskStartDate_ <= '` + end + `') `;
    var dueDateFilter = ` (TaskDueDate_ >= '` + start + `' AND TaskDueDate_ <= '` + end + `') `;
    var completionDateFilter = ` (TaskCompletionDate >= '` + start + `' AND TaskCompletionDate <= '` + end + `') `;
    // var dateFilter = ` (` + startDateFilter + ` OR ` + dueDateFilter + ` OR ` + completionDateFilter + `) `;
    var dateFilter =  ` (` + startDateFilter + `) `;

    var filter = ` WHERE (` + subjectFilter + ` AND ` + dateFilter + ` AND department LIKE '*${loginUserDept.current}*' ) AND IsTaskDeleted is null`;

    var query = queryPrefix + filter;

    return query
}

// #################################################################


export function report_3_data_fetcher_get_query_13(timeRange, subjectType, subject, teamDetails, loginUserDept, findMentees, findPeopleUnderServer) {
    const start = new Date(timeRange[0]).toISOString().split("T")[0];
    const end = new Date(timeRange[1]).toISOString().split("T")[0];

    var subjectFilter = ``;
    if(subjectType === "Server") {
        const people = findPeopleUnderServer(subject, teamDetails);
        subjectFilter = ` ClaimedBy_AssingedTo IN ('${people.join("','")}') OR ClaimedBy_Mail_ID IN ('${people.join("','")}') OR Assigned_To IN ('${people.join("','")}') `;
    } else if (subjectType === "MentorTree") {
        var mentees = findMentees([subject], teamDetails);
        mentees.push(subject);

        subjectFilter = ` ClaimedBy_AssingedTo IN ('${mentees.join("','")}') OR ClaimedBy_Mail_ID IN ('${mentees.join("','")}') OR Assigned_To IN  ('${mentees.join("','")}') `;
    } else if (subjectType === "Mentor") {
        subjectFilter = ` ClaimedBy_AssingedTo LIKE '*${subject}*' OR AllForwarded_From_to_To LIKE '*${subject}*' OR ClaimedBy_Mail_ID LIKE '*${subject}*' OR Assigned_To LIKE '*${subject}*' OR ForwardHistory LIKE '*${subject}*' `;
    }

    const query = `SELECT * FROM Tickets WHERE (CREATEDTIME >= '${start}' AND CREATEDTIME <= '${end}') AND (${subjectFilter} AND department LIKE '*${loginUserDept.current}*' ) `;

    return query;
}

export function report_3_data_fetcher_get_query_14(timeRange, subjectType, subject, teamDetails, loginUserDept, findMentees) {
    var [start, end] = timeRange;
    start = start.toISOString().split("T")[0];
    end = end.toISOString().split("T")[0];

    var queryPrefix = `SELECT * FROM TaskBreaker `;
    
    var subjectFilter = ``;
    if(subjectType === "Server") {
        subjectFilter = ` Component= '${subject}' `;
    } else if(subjectType === "MentorTree") {
        var mentees = findMentees([subject], teamDetails);
        mentees.push(subject);

        subjectFilter = ` DRI IN ('${mentees.join("','")}') `;
    } else if (subjectType === "Mentor") {
        subjectFilter = ` DRI= '${subject}' `;
    }

    var startDateFilter = ` (TaskStartDate_ >= '` + start + `' AND TaskStartDate_ <= '` + end + `') `;
    var dueDateFilter = ` (TaskDueDate_ >= '` + start + `' AND TaskDueDate_ <= '` + end + `') `;
    var completionDateFilter = ` (TaskCompletionDate >= '` + start + `' AND TaskCompletionDate <= '` + end + `') `;
    // var dateFilter = ` (` + startDateFilter + ` OR ` + dueDateFilter + ` OR ` + completionDateFilter + `) `;
    var dateFilter =  ` (` + startDateFilter + `) `;

    var filter = ` WHERE (` + subjectFilter + ` AND ` + dateFilter + ` AND department LIKE '*${loginUserDept.current}*' ) AND IsTaskDeleted is null`;

    var query = queryPrefix + filter;

    return query
}

// #################################################################

export function report_5_data_fetcher_get_query_1(viewSelected, loginUserDept) {
    if (viewSelected === "Combined") {
        return `SELECT * from ExternalFeatureReport WHERE (TypeOfRoadmap ='Internal' OR TypeOfRoadmap ='External') AND department LIKE '*${loginUserDept.current}*'`
    }
    else{
        return `SELECT * from ExternalFeatureReport WHERE  TypeOfRoadmap ='${viewSelected}' AND department LIKE '*${loginUserDept.current}*'`;
    }
}

export function report_5_data_fetcher_get_query_2(selectedOption, loginUserDept) {
    return `SELECT * from TaskDetails where Component = '${selectedOption}' and IsExternalMilestone is null AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null`;
}

export function report_5_data_fetcher_get_query_3(selectedOption, loginUserDept) {
    return `SELECT * FROM TaskDetails where Component = '${selectedOption}' and IsExternalMilestone = ' ' and TasklistStatus not in ('Completed','TasklistCompleted','Updated To Idc') AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null`;
}

export function report_5_data_fetcher_get_query_4(selectedOption, loginUserDept) {
    return `SELECT TaskName,TaskPriority,Email FROM TaskBreaker where ReportName='Roadmap_Form_Report' and Component = '${selectedOption}' and TaskStatus = 'RMap' and Tasklist_created_for_roadmap is null AND department LIKE '*${loginUserDept.current}*' AND IsTaskDeleted is null`;
}

export function report_5_data_fetcher_get_query_5(dept) {
    return `SELECT * FROM TeamDetails WHERE department LIKE '*${dept}*' AND IsTasklistDeleted is null`;
}

export function report_5_data_fetcher_get_query_6(viewSelected, loginUserDept,GlobalServer) {
    if (viewSelected === "Combined") {
        return `SELECT * from ExternalFeatureReport WHERE (TypeOfRoadmap ='Internal' OR TypeOfRoadmap ='External') AND department LIKE '*${loginUserDept.current}*' AND Module ='${GlobalServer}'`;
    }
    else{
        return `SELECT * from ExternalFeatureReport WHERE  TypeOfRoadmap ='${viewSelected}' AND department LIKE '*${loginUserDept.current}*' AND Module ='${GlobalServer}'`;
    }
    
}

// #################################################################

export function report_5_external_component_get_query_1(selectedOption, loginUserDept) {
    return `SELECT * FROM TaskDetails where Component = '${selectedOption}' and IsExternalMilestone = 'external' and TasklistStatus not in ('Completed','TasklistCompleted','Updated To Idc') AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null`;
}

export function report_5_external_component_get_query_2(selectedOption, loginUserDept) {
    return `SELECT * from ExternalFeatureReport where IsTasklistCreated is null and Module = '${selectedOption}' AND department LIKE '*${loginUserDept.current}*' AND TypeOfRoadmap ='External'`;
}

// #################################################################

export function report_2_on_time_vs_delayed_data_fetcher_get_query_1(startDate, endDate, filter, loginUserDept) {
    return `SELECT * FROM TaskBreaker WHERE CREATEDTIME >= '${startDate}' AND CREATEDTIME <= '${endDate}' ${filter} AND ReportName != 'Roadmap_Form_Report' AND department LIKE '*${loginUserDept.current}*' AND IsTaskDeleted is null`;
}

// #################################################################

export function report_2_data_fetcher_get_query_1(startDate, endDate, filter, loginUserDept) {
    return `SELECT MilestoneName, MilestoneID, MilestoneStartDate, MilestoneCompletionDate, MilestoneDueDate FROM TaskDetails WHERE CREATEDTIME >= '${startDate}' AND CREATEDTIME <= '${endDate}' ${filter} AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null GROUP BY MilestoneId `;
}

export function report_2_data_fetcher_get_query_2(startDate, endDate, filter, loginUserDept) {
    return `SELECT * FROM TaskDetails WHERE CREATEDTIME >= '${startDate}' AND CREATEDTIME <= '${endDate}' ${filter} AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null`;
}

export function report_2_data_fetcher_get_query_3(startDate, endDate, filter, loginUserDept) {
    return `SELECT * FROM TaskBreaker WHERE CREATEDTIME >= '${startDate}' AND CREATEDTIME <= '${endDate}' ${filter} AND ReportName= 'TaskBreaker_Data_Report' AND department LIKE '*${loginUserDept.current}*' AND IsTaskDeleted is null`;
}

export function report_2_data_fetcher_get_query_4(startDate, endDate, filter, loginUserDept) {
    return `SELECT * FROM TaskBreaker WHERE CREATEDTIME >= '${startDate}' AND CREATEDTIME <= '${endDate}' ${filter} AND ReportName= 'Roadmap_Form_Report' AND department LIKE '*${loginUserDept.current}*' AND IsTaskDeleted is null`;
}

export function report_2_data_fetcher_get_query_5(startDate, endDate, issuesFilter, loginUserDept) {
    return `SELECT * FROM Tickets WHERE CREATEDTIME >= '${startDate}' AND CREATEDTIME <= '${endDate}' ${issuesFilter} AND department LIKE '*${loginUserDept.current}*' `;
}

// #################################################################

export function external_report_milestone_data_fetcher_get_query_1(milestoneId) {
    return `SELECT * FROM TaskDetails WHERE MilestoneId= '${milestoneId}' AND IsTasklistDeleted is null`;
}

export function external_report_milestone_data_fetcher_get_query_2(milestoneId) {
    return `SELECT * FROM TaskBreaker WHERE MilestoneID= '${milestoneId}' AND IsTaskDeleted is null`;
}

export function external_report_milestone_data_fetcher_get_query_3(milestoneId) {
    return `SELECT * FROM AllBuildDetails WHERE Associated_Tasks LIKE '*${milestoneId}*'`;
}

// #################################################################

export function report_1_release_planner_data_fetcher_get_query_1(months, month_ind, quarters, loginUserDept) {
    return `SELECT * FROM QuarterlyRMap WHERE (Quareter= '${months[month_ind]}' OR Quareter= '${quarters[month_ind]}') AND department LIKE '*${loginUserDept.current}*' `;
}

export function report_1_release_planner_data_fetcher_get_query_2(months, month_ind, quarters, loginUserDept, current) {
    return `SELECT * FROM QuarterlyRMap WHERE (Quareter= '${months[month_ind]}' OR Quareter= '${quarters[month_ind]}') AND Server= '${current}' AND department LIKE '*${loginUserDept.current}*' `;
}

export function report_1_release_planner_data_fetcher_get_query_3(release_roadmaps, loginUserDept) {
    return `SELECT * FROM TaskBreaker WHERE (TaskListName IN ('${release_roadmaps.join("','")}') ) AND department LIKE '*${loginUserDept.current}*' AND IsTaskDeleted is null`;
}

export function report_1_release_planner_data_fetcher_get_query_4(release_roadmaps, current, loginUserDept) {
    return `SELECT * FROM TaskBreaker WHERE (TaskListName IN ('${release_roadmaps.join("','")}') ) AND (Component= '${current}') AND department LIKE '*${loginUserDept.current}*' AND IsTaskDeleted is null`;
}

// #################################################################

export function report_1_data_fetcher_get_query_1(loginUserDept) {
    return `SELECT COUNT(TaskStatus),TaskStatus FROM TaskBreaker WHERE department LIKE '*${loginUserDept.current}*' AND IsTaskDeleted is null GROUP BY TaskStatus`;
}

export function report_1_data_fetcher_get_query_2(loginUserDept) {
    return `SELECT TaskStatus FROM TaskBreaker WHERE department LIKE '*${loginUserDept.current}*' AND IsTaskDeleted is null GROUP BY TaskStatus`;
}

export function report_1_data_fetcher_get_query_3(currentSelect, loginUserDept) {
    return `SELECT COUNT(TaskStatus),TaskStatus FROM TaskBreaker WHERE Component= '${currentSelect}' AND department LIKE '*${loginUserDept.current}*' AND IsTaskDeleted is null GROUP BY TaskStatus`;
}

export function report_1_data_fetcher_get_query_4(currentSelect, loginUserDept) {
    return `SELECT TaskStatus FROM TaskBreaker WHERE Component= '${currentSelect}' AND department LIKE '*${loginUserDept.current}*' AND IsTaskDeleted is null GROUP BY TaskStatus`;
}

export function report_1_data_fetcher_get_query_5(loginUserDept) {
    return `SELECT COUNT(TasklistStatus),TasklistStatus FROM TaskDetails WHERE department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null GROUP BY TasklistStatus`;
}

export function report_1_data_fetcher_get_query_6(loginUserDept) {
    return `SELECT TasklistStatus FROM TaskDetails WHERE department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null GROUP BY TasklistStatus`;
}

export function report_1_data_fetcher_get_query_7(currentSelect, loginUserDept) {
    return `SELECT COUNT(TasklistStatus),TasklistStatus FROM TaskDetails WHERE Component= '${currentSelect}' AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null GROUP BY TasklistStatus`;
}

export function report_1_data_fetcher_get_query_8(currentSelect, loginUserDept) {
    return `SELECT TasklistStatus FROM TaskDetails WHERE Component= '${currentSelect}' AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null GROUP BY TasklistStatus`;
}

export function report_1_data_fetcher_get_query_9(loginUserDept) {
    return `SELECT DISTINCT(MilestoneID) FROM TaskDetails WHERE MilestoneStatus='Completed' AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null `;
}

export function report_1_data_fetcher_get_query_10(loginUserDept) {
    return `SELECT DISTINCT(MilestoneID) FROM TaskDetails WHERE MilestoneStatus='Not Completed' AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null `;
}

export function report_1_data_fetcher_get_query_11(currentSelect, loginUserDept) {
    return `SELECT DISTINCT(MilestoneID) FROM TaskDetails WHERE MilestoneStatus='Completed' AND Component= '${currentSelect}' AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null`;
}

export function report_1_data_fetcher_get_query_12(currentSelect, loginUserDept) {
    return `SELECT DISTINCT(MilestoneID) FROM TaskDetails WHERE MilestoneStatus='Not Completed' AND Component= '${currentSelect}' AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null`;
}

export function report_1_data_fetcher_get_query_13(server, loginUserDept) {
    return `SELECT DISTINCT(DRI) FROM TaskBreaker WHERE Component='${server}' AND department LIKE '*${loginUserDept.current}*' `;
}

export function report_1_data_fetcher_get_query_14(server, loginUserDept) {
    return `SELECT DISTINCT(DRI) FROM TaskBreaker WHERE Component='${server}' AND (TaskStatus='Ongoing' OR TaskStatus='In Progress') AND department LIKE '*${loginUserDept.current}*' `;
}

export function report_1_data_fetcher_get_query_15(loginUserDept) {
    return `SELECT DISTINCT(DRI) FROM TaskBreaker WHERE Component!='Test' AND department LIKE '*${loginUserDept.current}*' `;
}

export function report_1_data_fetcher_get_query_16(loginUserDept) {
    return `SELECT DISTINCT(DRI) FROM TaskBreaker WHERE Component!='Test' AND (TaskStatus='Ongoing' OR TaskStatus='In Progress') AND department LIKE '*${loginUserDept.current}*' `;
}

export function report_1_data_fetcher_get_query_17(server, loginUserDept) {
    return `SELECT TaskDueDateExtended FROM TaskBreaker WHERE (TaskStatus!='Closed' AND TaskStatus!='Cancelled' AND TaskStatus!='RMap' AND TaskDueDateExtended IS NOT NULL AND Component='${server}') AND department LIKE '*${loginUserDept.current}*' AND IsTasklDeleted is null GROUP BY TaskDueDateExtended`;
}

export function report_1_data_fetcher_get_query_18(server, loginUserDept) {
    return `SELECT COUNT(TaskDueDateExtended) FROM TaskBreaker WHERE (TaskStatus!='Closed' AND TaskStatus!='Cancelled' AND TaskStatus!='RMap' AND TaskDueDateExtended IS NOT NULL AND Component='${server}') AND department LIKE '*${loginUserDept.current}*' AND IsTaskDeleted is null GROUP BY TaskDueDateExtended`;
}

export function report_1_data_fetcher_get_query_19(server, loginUserDept) {
    return `SELECT TasklistDueDateExtended FROM TaskDetails WHERE (TasklistStatus!='TasklistCompleted' AND TasklistDueDateExtended IS NOT NULL AND Component='${server}') AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null GROUP BY TasklistDueDateExtended`;
}

export function report_1_data_fetcher_get_query_20(server, loginUserDept) {
    return `SELECT COUNT(TasklistDueDateExtended) FROM TaskDetails WHERE (TasklistStatus!='TasklistCompleted' AND TasklistDueDateExtended IS NOT NULL AND Component='${server}') AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null GROUP BY TasklistDueDateExtended`;
}

export function report_1_data_fetcher_get_query_21(server, loginUserDept) {
    return `SELECT MilestoneDueDateExtended FROM TaskDetails WHERE (MilestoneStatus!='Completed' AND MilestoneDueDateExtended IS NOT NULL AND Component='${server}') AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null GROUP BY MilestoneDueDateExtended`;
}

export function report_1_data_fetcher_get_query_22(server, loginUserDept) {
    return `SELECT COUNT(MilestoneDueDateExtended) FROM TaskDetails WHERE (MilestoneStatus!='Completed' AND MilestoneDueDateExtended IS NOT NULL AND Component='${server}') AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null GROUP BY MilestoneDueDateExtended`;
}

export function report_1_data_fetcher_get_query_23(today, yesterday, server, loginUserDept) {
    return `SELECT COUNT(TaskStatus) FROM TaskBreaker WHERE (TaskCompletionDate <= '${today}' AND TaskCompletionDate >= '${yesterday}' AND Component = '${server}') AND department LIKE '*${loginUserDept.current}*' AND IsTaskDeleted is null`;
}

export function report_1_data_fetcher_get_query_24(today, yesterday, server, loginUserDept) {
    return `SELECT COUNT(TasklistStatus) FROM TaskDetails WHERE (TasklistCompletionDate <= '${today}' AND TasklistCompletionDate >= '${yesterday}' AND Component = '${server}') AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null`;
}

export function report_1_data_fetcher_get_query_25(today, yesterday, server, loginUserDept) {
    return `SELECT COUNT(MilestoneStatus) FROM TaskDetails WHERE (MilestoneCompletionDate IS NOT NULL AND MilestoneCompletionDate <= '${today}' AND MilestoneCompletionDate >= '${yesterday}' AND Component = '${server}') AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null`;
}

export function report_1_data_fetcher_get_query_26(today, lastWeek, server, loginUserDept) {
    return `SELECT COUNT(TaskStatus) FROM TaskBreaker WHERE (TaskCompletionDate <= '${today}' AND TaskCompletionDate >= '${lastWeek}' AND Component = '${server}' AND department LIKE '*${loginUserDept.current}*')`;
}

export function report_1_data_fetcher_get_query_27(today, lastWeek, server, loginUserDept) {
    return `SELECT COUNT(TasklistStatus) FROM TaskDetails WHERE (TasklistCompletionDate <= '${today}' AND TasklistCompletionDate >= '${lastWeek}' AND Component = '${server}' AND department LIKE '*${loginUserDept.current}*')`;
}

export function report_1_data_fetcher_get_query_28(today, lastWeek, server, loginUserDept) {
    return `SELECT COUNT(MilestoneStatus) FROM TaskDetails WHERE (MilestoneCompletionDate IS NOT NULL AND MilestoneCompletionDate <= '${today}' AND MilestoneCompletionDate >= '${lastWeek}' AND Component = '${server}' AND department LIKE '*${loginUserDept.current}*')`;
}

export function report_1_data_fetcher_get_query_29(dateString, server, loginUserDept) {
    return `SELECT COUNT(MilestoneName) from TaskDetails WHERE (TaskDetails.MilestoneDueDate_ > TaskDetails.MilestoneCompletionDate  OR (TaskDetails.MilestoneDueDate_ > '${dateString}' AND TaskDetails.MilestoneCompletionDate IS NULL)) AND Component='${server}' AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null`;
}

export function report_1_data_fetcher_get_query_30(dateString, server, loginUserDept) {
    return `SELECT COUNT(MilestoneName) from TaskDetails WHERE (TaskDetails.MilestoneDueDate_ < TaskDetails.MilestoneCompletionDate  OR (TaskDetails.MilestoneDueDate_ < '${dateString}' AND TaskDetails.MilestoneCompletionDate IS NULL)) AND Component='${server}' AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null`;
}

export function report_1_data_fetcher_get_query_31(server, loginUserDept) {
    return `SELECT DISTINCT(MilestoneId), MilestoneName, Email, MilestoneStartDate_, MilestoneDueDate_, MilestoneCompletionDate FROM TaskDetails WHERE Component='${server}' AND MilestoneDueDate_ IS NOT NULL AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null`;
}

export function report_1_data_fetcher_get_query_32(milestone, loginUserDept) {
    return `SELECT * FROM TaskDetails WHERE MilestoneName='${milestone}' AND TasklistDueDate_ IS NOT NULL AND department LIKE '*${loginUserDept.current}*' AND IsTasklistDeleted is null`;
}

export function report_1_data_fetcher_get_query_33(milestone, tasklist, loginUserDept) {
    return `SELECT * FROM TaskBreaker WHERE MilestoneName='${milestone}' AND TaskListName='${tasklist}' AND TaskDueDate_ IS NOT NULL AND department LIKE '*${loginUserDept.current}*' AND IsTaskDeleted is null`;
}

// #################################################################

export function combined_milestone_get_query_1(loginUserDept) {
    return  `SELECT * FROM TaskDetails  WHERE MultiServer = 'Yes' AND department LIKE '*${loginUserDept.current}*'`;
}

export function combined_milestone_get_query_2(MilestoneName) {
    return `SELECT * FROM TaskDetails WHERE MilestoneName = '${MilestoneName}' AND MultiServer = 'Yes'`;
}

// #################################################################

export function combined_milestone_info_get_query_1(MilestoneName, Server) {
    return `SELECT * FROM TaskDetails WHERE MilestoneName = '${MilestoneName}' AND Component = '${Server}'`;
}

// #################################################################

export function external_internal_roadmap_get_query_1() {
    return `SELECT * FROM RoadmapExternal where TypeofRoadmap ='internal'`;
}

export function external_internal_roadmap_get_query_2() {
    return `SELECT * FROM Configuration`;
}

export function external_internal_roadmap_get_query_3(user_email, queryStringSuffix) {
    return `SELECT * FROM externalRoadmap WHERE DRI= '${user_email}' OR Component IN ${queryStringSuffix}`;
}

export function external_internal_roadmap_get_query_4(newRoadMapName, lastTwoValues) {
    return `Update TaskBreaker set NewRoadMapName ='${newRoadMapName}'WHERE CreatorRowid= '${lastTwoValues[2]}'`;
}

export function external_internal_roadmap_get_query_5(row) {
    return `SELECT * FROM TaskDetails WHERE IsExternalMilestone = 'external' AND TasklistName = '${row[0]}' `;
}

// #################################################################

export function external_external_delete_get_query_1(creatorRowIDs) {
    return `DELETE FROM RoadmapExternal WHERE CreatorRowid in ('${creatorRowIDs.join("','")}')`;
}

export function external_external_delete_get_query_2(creatorRowIDs) {
    return `DELETE FROM RoadmapExternal WHERE CreatorRowid in ('${creatorRowIDs.join("','")}')`;
}

// #################################################################

export function external_user_get_query_1(viewSelected, loginUserDept) {
    return `SELECT * FROM ExternalFeatureReport WHERE TypeOfRoadmap ='${viewSelected}'`;
}

// #################################################################

export function external_get_query_1(loginUserDept) {
    return `SELECT * FROM RoadmapExternal where TypeofRoadmap ='external' AND department LIKE '*${loginUserDept.current}*' `;
}

export function external_get_query_2() {
    return `SELECT * FROM Configuration`;
}

export function external_get_query_3(user_email, queryStringSuffix) {
    return `SELECT * FROM externalRoadmap WHERE DRI= '${user_email}' OR Component IN ${queryStringSuffix}`;
}

export function external_get_query_4(newRoadMapName, lastTwoValues) {
    return `Update TaskBreaker set NewRoadMapName ='${newRoadMapName}'WHERE CreatorRowid= '${lastTwoValues[2]}'`;
}

export function external_get_query_5(row) {
    return `SELECT * FROM TaskDetails WHERE IsExternalMilestone = 'external' AND TasklistName = '${row[0]}' `;
}

// #################################################################

export function external_current_progress_get_query_1(loginUserDept) {
    return `SELECT * FROM QuarterlyRMap WHERE department LIKE '*${loginUserDept.current}*'`;
}

export function external_current_progress_get_query_2(roadMapName) {
    return `SELECT COUNT(TasklistName) FROM TaskDetails WHERE TasklistName = '${roadMapName}' AND TasklistStatus = 'Open'`;
}

export function external_current_progress_get_query_3(item) {
    return `SELECT * FROM TaskDetails WHERE MilestoneId = '` + item + `'`;
}

// #################################################################

export function external_component_get_query_1(selectedOption) {
    return `SELECT * FROM ExternalFeatureReport where Module = '${selectedOption}'`;
}

export function external_component_get_query_2(selectedOption) {
    return `SELECT * FROM ExternalFeatureReport where Module = '${selectedOption}'`;
}

// #################################################################

export function past_incidents_get_query_1(loginUserDept) {
    return `SELECT * FROM IncidentReport where IncidentStatus in ('Closed') AND department LIKE '*${loginUserDept.current}*'`;
}

export function past_incidents_get_query_2(toggleClosedFilter, loginUserDept) {
    return `SELECT * FROM IncidentReport WHERE ${toggleClosedFilter} AND department LIKE '*${loginUserDept.current}*'`;
}

export function past_incidents_get_query_3(queryFilter, toggleClosedFilter, loginUserDept) {
    return `SELECT * FROM IncidentReport WHERE (${queryFilter}) AND ${toggleClosedFilter} AND department LIKE '*${loginUserDept.current}*'`;
}

export function past_incidents_get_query_4(dept) {
    return `SELECT * FROM TeamDetails WHERE department LIKE '*${dept}*'`;
}
////////////////////////////////////////###########################

export function issue_incidents_get_query_1(loginUserDept) {
    return `SELECT * FROM IncidentManagementReport where IncidentStatus in ('Approved and posted') AND department LIKE '*${loginUserDept.current}*'`;
}

export function issue_incidents_get_query_2(toggleClosedFilter, loginUserDept) {
    return `SELECT * FROM IncidentManagementReport WHERE ${toggleClosedFilter} AND department LIKE '*${loginUserDept.current}*'`;
}

export function issue_incidents_get_query_3(queryFilter, toggleClosedFilter, loginUserDept) {
    return `SELECT * FROM IncidentManagementReport WHERE (${queryFilter}) AND ${toggleClosedFilter} AND department LIKE '*${loginUserDept.current}*'`;
}

export function issue_incidents_get_query_4(dept) {
    return `SELECT * FROM IncidentManagementReport WHERE department LIKE '*${dept}*'`;
}

// #################################################################

export function incident_monitor_get_query_1(loginUserDept) {
    return `SELECT * FROM IncidentReport WHERE department LIKE '*${loginUserDept.current}*'`;
}

// #################################################################

export function incident_history_get_query_1(loginUserDept) {
    return `SELECT * FROM IncidentReport WHERE department LIKE '*${loginUserDept.current}*'`;
}

// #################################################################

export function live_incidents_get_query_1(loginUserDept) {

    return `SELECT * FROM IncidentReport where IncidentStatus not in ('Closed') AND department LIKE '*${loginUserDept.current}*' order by CREATEDTIME DESC`;
}

export function live_incidents_get_query_2(toggleClosedFilter, loginUserDept) {
    return `SELECT * FROM IncidentReport WHERE ${toggleClosedFilter} AND department LIKE '*${loginUserDept.current}*' order by CREATEDTIME DESC`;
}

export function live_incidents_get_query_3(queryFilter, toggleClosedFilter, loginUserDept) {
    return `SELECT * FROM IncidentReport WHERE (${queryFilter}) AND ${toggleClosedFilter} AND department LIKE '*${loginUserDept.current}*' order by CREATEDTIME DESC`;
}

export function live_incidents_get_query_4(dept) {
    return `SELECT * FROM TeamDetails WHERE department LIKE '*${dept}*' order by CREATEDTIME DESC`;
}

export function live_incidents_get_query_5(status , creatorRowId) {
    return `UPDATE IncidentReport SET IncidentStatus = '${status}' Where CreatorRowid = '${creatorRowId}'`;
}

// #################################################################

export function incidents_inference_get_query_1(DeskID, loginUserDept) {
    return "SELECT * FROM IncidentReport where TicketID ='"+DeskID.DeskID+"' AND department LIKE '*" + loginUserDept.current + "*'";
}

// #################################################################

export function notifications_get_query_1(user_email) {
    return "SELECT * FROM Notifications WHERE UserEmail='" + user_email + "' AND Is_User_Updated is null order by CREATEDTIME DESC";
}

export function notifications_get_query_2(message, Is_Approved_Or_Rejected, user_email, CreatorId) {
    return "INSERT INTO Notifications (Message, Is_Approved_Or_Rejected, UserEmail, Creator_RowId) VALUES ('"+ message+"', '"+ Is_Approved_Or_Rejected+"', '"+ user_email+"', '"+ CreatorId+"')";
}

export function notifications_get_query_3(CreatorId) {
    return "UPDATE Notifications SET Is_User_Updated = 'Yes' WHERE Creator_RowId = '"+CreatorId+"'";
}

export function notifications_get_query_4(user_email) {
    return "SELECT Notification_Count FROM NotificationCount WHERE UserEmail='" + user_email + "'";
}

export function notifications_get_query_5(user_email, notificationDataCount) {
    return "UPDATE NotificationCount SET Notification_Count = '"+ notificationDataCount +"' WHERE UserEmail ='" + user_email + "'";
}

// #################################################################

export function app_component_get_query_1() {
    return `SELECT * FROM TeamDetails WHERE Email IS NOT NULL`;
}

export function app_component_get_query_2() {
    return `SELECT Email_ID, Name, Zuid FROM SupportMemberForm WHERE Email_ID IS NOT NULL`;
}

export function app_component_get_query_3(userEmail) {
    return `SELECT CREATEDTIME,CREATORID,Email_Id,MODIFIEDTIME,ROWID,refreshTokenLastUpdatedTime,refreshTokenScopes,userId FROM Token  WHERE Email_Id LIKE '*${userEmail}*'`;
}

export function app_component_get_query_4(loginUserDept) {
    return `Select * from ServerConfiguration WHERE department LIKE '*${loginUserDept.current}*'`;
}

export function app_component_get_query_5() {
    return `SELECT * FROM Confs`;
}

// #################################################################

export function top_bar_component_get_query_1(dept, loginUserEmail) {
    return `UPDATE TeamDetails set view_= '${dept}' WHERE Email= '${loginUserEmail.current}'`;
}

// #################################################################

export function release_planner_report_query(currentSelect, department, view) {
    const today = new Date().toISOString().split("T")[0];
    var viewFilter = ``;
    if(view !== 'Overall') {
        viewFilter = ` AND TypeOfRoadmap= '${view}' `
    }
    var serverFilter = ``;
    if(currentSelect !== 'Default' && typeof currentSelect !== 'object' ) {
        serverFilter = ` AND Module_Servers LIKE '*${currentSelect}*' `;
    }
    return [
        `SELECT Count(ROWID) from ExternalFeatureReport WHERE Over_All_ETA IS NULL AND department LIKE '*${department}*' ${viewFilter} ${serverFilter}`, // still not considered features, yet to do
        `SELECT Count(ROWID) from ExternalFeatureReport WHERE (Over_All_ETA IS NOT NULL AND CompletedDate IS NULL) AND (Over_All_ETA >= '${today}') AND department LIKE '*${department}*' ${viewFilter} ${serverFilter}`, // considered, on time.
        `SELECT Count(ROWID) from ExternalFeatureReport WHERE (Over_All_ETA IS NOT NULL AND CompletedDate IS NULL) AND (Over_All_ETA < '${today}') AND department LIKE '*${department}*' ${viewFilter} ${serverFilter}`, // considered, delayed.
        `SELECT COUNT(ROWID) from ExternalFeatureReport WHERE (Over_All_ETA IS NOT NULL AND CompletedDate IS NOT NULL) AND (CompletedDate <= ExternalFeatureReport.Over_All_ETA) AND department LIKE '*${department}*' ${viewFilter} ${serverFilter}`, // completed, on time.
        `SELECT COUNT(ROWID) from ExternalFeatureReport WHERE (Over_All_ETA IS NOT NULL AND CompletedDate IS NOT NULL) AND (CompletedDate > ExternalFeatureReport.Over_All_ETA) AND department LIKE '*${department}*' ${viewFilter} ${serverFilter}`// completed, delayed.
    ];
}

 // #################################################################
 
 export function timeLine_page_component_get_query_1(userserver) {

    function formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

      const today = new Date();
      const formattedDate = formatDate(today);
      userserver = userserver.split(",");
      userserver = userserver.join("','");

    return(
         `SELECT Data FROM TimelineData WHERE Date_ = '${formattedDate}' and  Component in ('${userserver}') and Department = 'ZVP' order by MilestoneCompletionDate`
        // `SELECT Data FROM User_Based_Timeline_Report WHERE Date_ = '2024-04-09'`
    );
}

export function timeLine_page_component_get_query_2(SelectedServer) {

    function formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

      const today = new Date();
  const formattedDate = formatDate(today);
    return(
        //  `SELECT Data FROM User_Based_Timeline_Report WHERE Date_ = '2024-04-09' AND Component = '${SelectedServer}'`
        `SELECT Data FROM TimelineData WHERE Date_ = '${formattedDate}' AND Component = '${SelectedServer}' and Department = 'ZVP' order by MilestoneCompletionDate`
    );
}

export function timeLine_page_component_get_query_3(SelectedDRI) {

    function formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

      const today = new Date();
  const formattedDate = formatDate(today);
    return(
        //  `SELECT Data FROM TimelineData WHERE Date_ = '${formattedDate}'`
        `SELECT Data FROM User_Based_Timeline_Report WHERE Date_ = '${formattedDate}' AND Useremail = '${SelectedDRI}' and Department = 'ZVP' order by MilestoneCompletionDate`
    );
}

export function timeLine_page_component_get_query_4(SearchedText) {

    function formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

      const today = new Date();
  const formattedDate = formatDate(today);
    return(
        //  `SELECT Data FROM TimelineData WHERE Date_ = '${formattedDate}'`
        `SELECT Data FROM User_Based_Timeline_Report WHERE Date_ = '${formattedDate}' AND Data LIKE '*${SearchedText}*' and Department = 'ZVP' order by MilestoneCompletionDate`
    );
}

export function timeLine_page_component_get_query_5(StartDate,EndaDate) {

    function formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

      const today = new Date();
  const formattedDate = formatDate(today);
    return(
        // `Select Data FROM User_Based_Timeline_Report WHERE MilestoneCompletionDate BETWEEN '${StartDate}' AND '${EndaDate}' and Date_ = '${formattedDate}'  and department = 'ZVP' order by MilestoneCompletionDate`
        `Select Data FROM TimelineData WHERE MilestoneCompletionDate BETWEEN '${StartDate}' AND '${EndaDate}' and Date_ = '${formattedDate}'  and department = 'ZVP' order by MilestoneCompletionDate`
    );
}

export function timeLine_page_component_get_query_6(StartDate,EndaDate,Server) {

    function formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

      const today = new Date();
  const formattedDate = formatDate(today);
    return(
        // `Select Data FROM User_Based_Timeline_Report WHERE MilestoneCompletionDate BETWEEN '${StartDate}' AND '${EndaDate}' and Date_ = '${formattedDate}'  and department = 'ZVP' order by MilestoneCompletionDate`
        `Select Data FROM TimelineData WHERE MilestoneCompletionDate BETWEEN '${StartDate}' AND '${EndaDate}' and Date_ = '${formattedDate}'  AND Component = '${Server}'  and department = 'ZVP' order by MilestoneCompletionDate`
    );
}

export function timeLine_page_component_get_query_7(StartDate,EndaDate,SelectedDRI) {

    function formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

    const today = new Date();
    const formattedDate = formatDate(today);

    return(
        `Select Data FROM User_Based_Timeline_Report WHERE MilestoneCompletionDate BETWEEN '${StartDate}' AND '${EndaDate}' and Date_ = '${formattedDate}'  AND Useremail = '${SelectedDRI}'  and department = 'ZVP' order by MilestoneCompletionDate`
    );
}

// ############################################

export function ticketsReport_page_component_get_query_1() {

    function formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

    const today = new Date();
    const formattedDate = formatDate(today);

    return(
         `SELECT Data FROM TicketsReport WHERE date_ = '${formattedDate}' and department = 'ZVP' order by AddedOrCompletedTime DESC`
        // `SELECT Data FROM User_Based_Timeline_Report WHERE Date_ = '2024-04-09'`
    );
}

export function ticketsReport_page_component_get_query_2(SearchedText) {

    function formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

    const today = new Date();
    const formattedDate = formatDate(today);

    return(
         `SELECT Data FROM TicketsReport WHERE date_ = '${formattedDate}' AND data LIKE '*${SearchedText}*' and department = 'ZVP' order by AddedOrCompletedTime DESC`
        // `SELECT Data FROM User_Based_Timeline_Report WHERE Date_ = '2024-04-09'`
    );
}

export function ticketsReport_page_component_get_query_3(startDate,endDate) {

    function formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

    const today = new Date();
    const formattedDate = formatDate(today);

    return(
         `Select Data FROM TicketsReport WHERE AddedOrCompletedTime BETWEEN '${startDate}' AND '${endDate}' and date_ = '${formattedDate}'  and department = 'ZVP' order by AddedOrCompletedTime DESC`
        // `SELECT Data FROM User_Based_Timeline_Report WHERE Date_ = '2024-04-09'`
    );
}

export function ticketsReport_page_component_get_query_4(SelectedGlobalServer) {

    function formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

    const today = new Date();
    const formattedDate = formatDate(today);

    return(
         `SELECT Data FROM TicketsReport WHERE date_ = '${formattedDate}' AND server = '${SelectedGlobalServer}' and department = 'ZVP' order by AddedOrCompletedTime DESC`
        // `SELECT Data FROM User_Based_Timeline_Report WHERE Date_ = '2024-04-09'`
    );
}

// ##############################################################

export function allStatData_page_component_get_query_1() {

    const today = new Date();
    const formattedDate = formatDate(today);

    function formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    return(
        `SELECT SUM(Total_Milestone_Created) ,  SUM(Ongoing_Milestone) , SUM(Delayed_Milestone), SUM(Ontime_Milestone) FROM Quarterly WHERE Date_ = '${formattedDate}' AND Department = 'ZVP'`
    );
}

export function allStatData_page_component_get_query_2() {

    const today = new Date();
    const formattedDate = formatDate(today);

    function formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    return(
        `SELECT SUM(Total_Tasklist_created) ,  SUM(Ongoing_Tasklist) , SUM(Delayed_Tasklist), SUM(Ontime_Tasklist) FROM Quarterly WHERE Date_ = '${formattedDate}' AND Department = 'ZVP'`
    );
}

export function allStatData_page_component_get_query_3() {

    const today = new Date();
    const formattedDate = formatDate(today);

    function formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    return(
        `SELECT SUM(Total_Task_Created) ,  SUM(Ongoing_Task) , SUM(Delayed_Task), SUM(Ontime_Task) FROM Quarterly WHERE Date_ = '${formattedDate}' AND Department = 'ZVP'`
    );
}

export function allStatData_page_component_get_query_4(SelectedServer) {

    const today = new Date();
    const formattedDate = formatDate(today);

    function formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    return(
        `SELECT Total_Milestone_Created , Ongoing_Milestone , Delayed_Milestone , Ontime_Milestone  FROM Quarterly WHERE Date_ = '${formattedDate}' AND Server = '${SelectedServer}' AND Department = 'ZVP'`
    );
}

export function allStatData_page_component_get_query_5(SelectedServer) {

    const today = new Date();
    const formattedDate = formatDate(today);

    function formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    return(
        `SELECT Total_Tasklist_created , Ongoing_Tasklist , Delayed_Tasklist , Ontime_Tasklist  FROM Quarterly WHERE Date_ = '${formattedDate}' AND Server = '${SelectedServer}' AND Department = 'ZVP'`
    );
}

export function allStatData_page_component_get_query_6(SelectedServer) {

    const today = new Date();
    const formattedDate = formatDate(today);

    function formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    return(
        `SELECT Total_Task_Created , Ongoing_Task , Delayed_Task , Ontime_Task  FROM Quarterly WHERE Date_ = '${formattedDate}' AND Server = '${SelectedServer}' AND Department = 'ZVP'`
    );
}