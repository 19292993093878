/* eslint-disable react/prop-types */
import React, {useEffect, useRef, useState, useContext} from "react";
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import CircularProgress from '@mui/material/CircularProgress';
import { getSankeyData } from "./Report2DataFetcher";
import { UserContext } from "../../../../contexts/UserContext";

import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { ButtonGroup, Button } from "@mui/material";

var chartObj;

export function OverviewSection({startTime, endTime, view, selection}) {
	const theme = useTheme();
	const chartRef = useRef();
	const [sankeyData, setSankeyData] = useState(null);
	const [loaded, setLoaded] = useState(null);
	const {teamDetails, loginUserDept} = useContext(UserContext);
	const currentLoading = useRef(null);
	const [showStatus, setShowStatus] = useState(false); // tasklist status
	const [showTaskStatus, setShowTaskStatus] = useState(true); // task status

	useEffect(() => {
		currentLoading.current = startTime+""+endTime+""+view+""+selection+""+showStatus;
		setLoaded(currentLoading.current);
	}, [startTime, endTime, view, selection, showStatus])

	// useEffect(() => {
	// 	if (loaded) {
	// 		var chartJson = Object.assign({}, sankeyJson);
	// 		chartJson.seriesdata.chartdata[0].data = sankeyData;
	// 		chartObj = new window.$ZC.charts(chartRef.current, chartJson);
	// 	} else if(loaded !== false) {
	// 		console.log("HERE: " + currentLoading.current);
	// 		var loadingNow = startTime+""+endTime+""+view+""+selection;
	// 		getSankeyData(startTime, endTime, view, selection, teamDetails).then((response) => {
	// 			console.log("GOT RESPONSE: ");
	// 			console.log(currentLoading.current);
	// 			console.log(loadingNow);
	// 			if(loadingNow === currentLoading.current) {
	// 				console.log("changed");
	// 				setSankeyData(response);
	// 				setLoaded(true);
	// 			}
	// 		}).catch((e) => {
	// 			// console.log("Exception occured");
	// 			setLoaded(false);
	// 		});
	// 	}
	// 	// else {
	// 	// 	// console.log("Fasle");
	// 	// }

	// }, [loaded]);

	useEffect(() => {
		if(loaded !== true && loaded !== false) {
			var loadingNow = startTime+""+endTime+""+view+""+selection+""+showStatus;
			// console.log(loadingNow)
			getSankeyData(startTime, endTime, view, selection, teamDetails, showStatus, loginUserDept).then((response) => {
				if(loadingNow === currentLoading.current) {
					setSankeyData(response);
					if([undefined, null].includes(response[0]) || response[0].length === 0) {
						setLoaded(undefined);
					} else {
						setLoaded(true);
					}
				}
			}).catch((e) => {
				if(loadingNow === currentLoading.current) {
					setLoaded(false);
				}
			});
		}
	}, [loaded])


	useEffect(() => {
		if(loaded === true) {
			var chartJson = Object.assign({}, sankeyJson);
			chartJson.seriesdata.chartdata[0].data = sankeyData;
			chartObj = new window.$ZC.charts(chartRef.current, chartJson);
		}
	}, [loaded]);

	function handleShowStatusChange(state) {
		if(state) {
			if(state === "task") {
				setShowStatus(false);
				setShowTaskStatus(true);
			} else {
				setShowStatus(true);
				setShowTaskStatus(false);
			}
			return;
		}
		setShowTaskStatus(showStatus);
		setShowStatus(!showStatus);
	}

	function handleShowTaskStatus() {
		setShowStatus(showTaskStatus);
		setShowTaskStatus(!showTaskStatus);
	}
	// console.log("hi")

	return (
		<Box sx={{
			width: "100%",
			height: "100%",
			justifyContent: "top",
			position: "relative"
		}}>
			<Box style={{
				padding: "1px",
				fontWeight: 600,
				fontSize: "calc(0.8vw)",
				color: theme.palette.mode === "light" ? "black" : "white"
			}}>
				<span style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					gap: 150,
					flexDirection: "row"
				}}>
					{/* <div>
						<span style={{marginRight: 10}}>Show Task Status</span>
						<IOSSwitch onChange={handleShowTaskStatus} checked={showTaskStatus}/>
					</div>
					<div>
						<span style={{marginRight: 10}}>Show Tasklist Status</span>
						<IOSSwitch onChange={handleShowStatusChange} checked={showStatus} />
					</div> */}
					<ButtonGroup size="small">
						<Button sx={{borderRadius: "50px", textTransform: "none"}} variant={showStatus ? "outlined" : "contained"}
							onClick={() => handleShowStatusChange("task")}
						>
							Task Status
						</Button>
						<Button sx={{borderRadius: "50px", textTransform: "none"}} variant={showStatus ? "contained" : "outlined"}
							onClick={() => handleShowStatusChange("tasklist")}
						>
							Tasklist Status
						</Button>
					</ButtonGroup>
				</span>
			</Box>
			<Box sx={{
				width: "100%",
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "space-around",
				position: "absolute",
				transform: "translateY(70%)"
			}}>
				<span>
					Milestone
				</span>
				<span>
					Tasklist
				</span>
				{
					showTaskStatus ?
						<span>
							Tasks
						</span>
						:
						null
				}
				<span>
					Status
				</span>
			</Box>
			<Box style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				height: "100%"
			}}>
				{

					loaded === true ?
						<div ref={chartRef} style={{
							height: "100%",
							width: "100%",
							padding: "100px",
							paddingTop: "150px"
						}}>
						</div>
						:
						loaded === false ?	
							<h1>Unable to fetch :(</h1>
							:
							loaded === undefined ?
								<h1>Nothing Found &nbsp; :o</h1>
								:
								<CircularProgress />
				}
			</Box>
		</Box>
	);
}

var sankeyJson = {
	"metadata": {
		"axes": {
			"x": [
				0
			],
			"y": [
				[
					1
				]
			],
			"z": [
				2
			],
			"tooltip": [
				0
			]
		},
		"columns": [
			{
				"dataindex": 0,
				"columnname": "from",
				"datatype": "ordinal"
			},
			{
				"dataindex": 1,
				"columnname": "to",
				"datatype": "ordinal"
			},
			{
				"dataindex": 2,
				"columnname": "magnitude",
				"datatype": "ordinal"
			}
		]
	},
	"seriesdata": {
		"chartdata": [
			{
				"type": "sankey",
				"data": []
			}
		]
	},
	"tooltip": {
		"enabled": true,
		"content": (event, data, chartObj) => {
			return data.actData[0]
		},
		"shadow": "2px 2px 30px black",
		"opacity": 0.95,
		"fontWeight": "bold",
		"fontColor": "black",
		"borderColor": "black"
	},
	"chart": {
		"zoom": {
			"enabled": false
		},
		"plot": {
			"plotoptions": {
				"sankey": {
					"align": "center",
					"node": {
						"width": 1500
					},
					"datalabels": {
						"show": true,
						"textOverlap": "ellipsis",
						"labelPos": "inside",
						"textShadow": "1px 1px 7px rgb(5,5,5,0.8)",
						"fontColor": "white",
						"fontSize": 10
					}
				}
			}
		},
		"axes": {
			"zaxis": {
				"label": {
					"tooltip": false
				}
			}
		}
	},
	"legend": {
		"show": false,
		"colors": [
			"#7cb5ec",
			"#f15c80",
			"#e4d354",
			"#90ed7d",
			"#f7a35c",
			"#8085e9",
			"#f45b5b",
			"#91e8e1"
		],
		"vAlign": "bottom",
		"layout": "horizontal",
		"highlightEffect": {
			"selectedSeries": "moody",
			"unselectedSeries": "fadeOut",
			"applyUnselectedSeriesToPlot": true
		}
	},
	"canvas": {
		"fontFamily": "helvetica, sans-serif",
		"fontSize": 1,
		"title": {
			"show": false
		},
		"subtitle": {
			"show": false
		},
		"events": {
			"onload": "MapDemo.addNavigationButtonsNotes"
		},
		"background": {
			"alpha": 0
		}
	}
};


const IOSSwitch = styled((props) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} onChange={props.onChange} checked={props.checked} />
))(({ theme }) => ({
	width: 46,
	height: 26,
	padding: 0,
	'& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 2,
		transitionDuration: '250ms',
		'&.Mui-checked': {
			transform: 'translateX(20px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				backgroundColor: theme.palette.mode === 'dark' ? 'primary' : 'primary',
				opacity: 1,
				border: 0
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.5
			}
		},
		'&.Mui-focusVisible .MuiSwitch-thumb': {
			color: '#33cf4d',
			border: '6px solid #fff'
		},
		'&.Mui-disabled .MuiSwitch-thumb': {
			color:
				theme.palette.mode === 'light'
					? theme.palette.grey[100]
					: theme.palette.grey[600]
		},
		'&.Mui-disabled + .MuiSwitch-track': {
			opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
		}
	},
	'& .MuiSwitch-thumb': {
		boxSizing: 'border-box',
		width: 22,
		height: 22
	},
	'& .MuiSwitch-track': {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
		opacity: 1,
		transition: theme.transitions.create(['background-color'], {
			duration: 500
		})
	}
}));