import { isDateInRange, isDateString } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    const chartData = [];
    const dataMap = {
        "Milestones Completed": []
    };
    const milestoneIdMap = {};
    
    var milestoneCount = 0;
    var milestoneCompletedCount = 0;

    catalystDB.TaskDetails.map(item => {

        if([undefined, null, "", " "].includes(item.MilestoneId)) {
            return;
        }

        if(item.MilestoneId in milestoneIdMap) {
            return;
        } else {
            milestoneIdMap[item.MilestoneId] = true;
        }

        const dateString = item.CREATEDTIME;

        if(isDateString(dateString) && isDateInRange(dateString, startTime)) {
            const completionDateString = item.MilestoneCompletionDate;

            milestoneCount++;

            if([undefined, null, "", " "].includes(completionDateString) && !isDateString(completionDateString)) {
                return;
            }

            dataMap["Milestones Completed"].push(item);

            milestoneCompletedCount++;
        }
    }); 

    
    chartData.push({
        "type": "bullet",
        "seriesname": "Milestones Completed",
        "data": [
            [
                [
                    "",
                    milestoneCompletedCount,
                    [
                        milestoneCount
                    ]
                ]
            ]
        ]
    });

    return [chartData, dataMap];
}