import React, { useEffect, useState, useContext} from 'react';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { queryDB } from "../../../util/QueryDB";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import InsertPageBreakRoundedIcon from '@mui/icons-material/InsertPageBreakRounded';
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import Tooltip from '@mui/material/Tooltip';
import ArrowCircleUpTwoToneIcon from '@mui/icons-material/ArrowCircleUpTwoTone';
import ArrowCircleDownTwoToneIcon from '@mui/icons-material/ArrowCircleDownTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import Brightness1TwoToneIcon from '@mui/icons-material/Brightness1TwoTone';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import InsertLinkTwoToneIcon from '@mui/icons-material/InsertLinkTwoTone';
// import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';

import Task from './Task.js';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { getFromTaskDetailsForTasklists } from './MilestoneDataFetcher.js';
import { GetNameAndEmpID, days_between } from '../../../util/Loading.js';

import { Fade } from '@mui/material';
import { Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import StopIcon from '@mui/icons-material/Stop';
import PentagonIcon from '@mui/icons-material/Pentagon';
import CircleIcon from '@mui/icons-material/Circle';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { tasklist_component_get_query_1,tasklist_component_get_query_2} from '../../../catalystDB/queryGenerator.js';
import ThreadMsgLink from './ThreadMsgLink.js';
import { UserContext } from '../../../contexts/UserContext.js';
import dayjs from 'dayjs';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function TaskList({ milestoneName, milestoneId, toggle }) {
	return (
		<>
			<TaskListTable milestoneName={milestoneName} milestoneId={milestoneId} toggle={toggle} />
		</>
	);
}

export function TaskListTable({ milestoneName, milestoneId, toggle }) {
	const [rows, setRows] = useState([]);
	const [contentLoaded, setContentLoaded] = useState(false);

	useEffect(() => {
		async function run() {
			try {
				const result = await getFromTaskDetailsForTasklists(tasklist_component_get_query_1(milestoneId, milestoneName), true);
				//const result = await getFromTaskDetailsForTasklists(`SELECT * FROM TaskDetails WHERE MilestoneId= ${milestoneId}`);
				// console.log(`SELECT * FROM TaskDetails WHERE MilestoneId= ${milestoneId}  AND MilestoneName= '${milestoneName}'  AND IsTasklistDeleted is null`)
				// console.log(contentLoaded)
				result.sort((a, b) => {
					const timeA = new Date(a[12]);
					const timeB = new Date(b[12]);
					
					
					if (timeA < timeB) {
					  return -1; 
					} else if (timeA > timeB) {
					  return 1; 
					} else {
					  return 0;
					}
				  });
				// console.log(result)
	
				result.sort((a, b) => {
					const timeA = new Date(a[12]);
					const timeB = new Date(b[12]);
	
					if (timeA < timeB) {
						return -1;
					} else if (timeA > timeB) {
						return 1;
					} else {
						return 0;
					}
				});
				
				setRows(result);
				setContentLoaded(true);
			}
			catch (e) {
				setContentLoaded(null);
			}
		}
		run();
		// setInterval(_ => {
		// 	// console.log("hlo");
		// 	 run();
		//   }, 10*1000);
	}, []);
	

	if (contentLoaded === null) {
		return <Fade in={true}><h3 style={{ textAlign: "center" }}>No Tasklists Found :(</h3></Fade>;
	}
	if (!contentLoaded) {
		//return <div style={{textAlign: "center", transform: "scale(0.6)"}}><SpinningLoadingComponent /></div>;
		return <Skeleton variant="rectangular" width={"100%"} height={42} sx={{
			marginBottom: "10px",
			'&.MuiSkeleton-root': {
				transform: "none",
				animation: "none"
			}
		}} />;
	}

	return (
		<Fade in={true}>
			<div className="tasklist-table">
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow className="tasklist-table-head">
								<TableCell align="center">S.No</TableCell>
								<TableCell align="left">Tasklist Name</TableCell>
								<TableCell align="center">DRI</TableCell>
								<TableCell align="center">Priority</TableCell>
								<TableCell align="center">Start Date</TableCell>
								<TableCell align="center">
									{toggle.current ? "Completion Date" : "Due Date"}</TableCell>
								<TableCell align="center">Status</TableCell>
								<TableCell align="center"></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row, index) => (
								<Row key={index} row={row} index={index} milestoneName={milestoneName} toggle={toggle} setRows={setRows} setContentLoaded={setContentLoaded} milestoneId={milestoneId}/>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</Fade>
	);
}

function Row({row, index, milestoneName, tasklistId, toggle, setRows, setContentLoaded, milestoneId}) {

	const [moreVisibile, setMoreVisible] = useState(false);
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const [moreButton, setMoreButton] = useState(false);
	const [tasks, setTasks] = useState(<p>loading...</p>);
	function handleOpen(event) {
		if (!moreButton) {
			setOpen(true);
		}
		// setOpen(true);
		setTasks(<Task tasklistId={row[6]} milestoneName={milestoneName} tasklistName={row[0]} serverName={row[9]	} />);
	};

	function handleClose() {
		setOpen(false);
		setContentLoaded(false);
	
		async function run() {
			try {
				const result = await getFromTaskDetailsForTasklists(
					tasklist_component_get_query_2(milestoneId, milestoneName)
				);
				result.sort((a, b) => {
					const timeA = new Date(a[12]);
					const timeB = new Date(b[12]);
	
					return timeA - timeB;
				});
	
				setRows(result);
				setContentLoaded(true);
			} catch (e) {
				console.error('Error fetching task details:', e);
				setContentLoaded(null);
			}
		}
	
		run();
	}
	
	

	var status = <></>;

	if (row[5] === "Ongoing") {
		status = <AccessTimeFilledRoundedIcon style={{ color: "orange" }} />;
	}
	else if (row[5] === "Completed") {
		status = <CheckCircleRoundedIcon style={{ color: "green" }} />;
	}
	else if (row[5] === "Ready for task breaking") {
		status = <InsertPageBreakRoundedIcon style={{ color: "chocolate" }} />;
	}
	else if (row[5] === "Broken down into individual tasks") {
		status = <PlaylistAddCheckRoundedIcon style={{ color: "teal" }} />;
	}

	var priority = "none";

	if (row[4] === "High") {
		priority = <ArrowCircleUpTwoToneIcon style={{ color: "crimson" }} />;
	}
	else if (row[4] === "Medium") {
		priority = <RemoveCircleTwoToneIcon style={{ color: "orange" }} />;
	}
	else if (row[4] === "Low") {
		priority = <ArrowCircleDownTwoToneIcon style={{ color: "mediumseagreen" }} />;
	}
	else {
		priority = <Brightness1TwoToneIcon style={{ color: "grey" }} />;
	}

	const statusComponent = {
		"Open": {
			"name": "Open",
			"color": theme.palette.mode === "dark" ? "cornflowerblue" : "blue",
			"component": <PlayArrowIcon style={{ width: "22px", marginRight: "1px" }} />
		},
		"Ongoing": {
			"name": "Ongoing",
			"color": "orange",
			"component": <CircleIcon style={{ width: "15px", marginRight: "5px" }} />
		},
		"Onhold": {
			"name": "Onhold",
			"color": "mediumorchid",
			"component": <StopIcon style={{ width: "20px", marginRight: "5px" }} />
		},
		"TasklistCompleted": {
			"name": "Completed",
			"color": "green",
			"component": <PentagonIcon style={{ width: "15px", marginRight: "5px" }} />
		},
		"Closed": {
			"name": "Completed",
			"color": "green",
			"component": <PentagonIcon style={{ width: "15px", marginRight: "5px" }} />
		},
		"-": {
			"name": "-",
			"color": theme.palette.mode === "dark" ? "white" : "black",
			"component": <></>
		}
	};

	var color = "green";

	const today = new Date();
	const startDate = new Date(row[2]);
	const endDate = new Date(row[3]);
	const totDays = days_between(startDate, endDate);

	// console.log(days_between(today, startDate));

	if (today < startDate) {
		color = "green";
	}
	else if (row[5] === "Closed" || row[5] === "Updated to IDC" || row[5] === "Cancelled" || row[5] === "Completed") {
		if (theme.palette.mode === "dark") {
			color = "White";
		}
		else {
			color = "black";
		}
	}
	else if (days_between(today, startDate) < (0.25 * totDays).toFixed(0)) {
		color = "forestgreen";
	}
	else if (days_between(today, startDate) < (0.5 * totDays).toFixed(0)) {
		color = "darksalmon";
	}
	else if (days_between(today, startDate) < (0.75 * totDays).toFixed(0)) {
		color = "darkgoldenrod";
	}
	else if (days_between(today, startDate) < (totDays).toFixed(0)) {
		color = "chocolate";
	}
	else {
		color = "crimson";
	}
	if (row[3] === "-" || row[2] === "-") {
		color = "";
	}

	const TextOnlyTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
	))(`
          color: black;
          background-color: white;
      `);

	const formatDate = (inputDate) => {
		const months = [
			'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
			'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
		];

		const date = new Date(inputDate);
		const day = date.getDate();
		const month = months[date.getMonth()];
		const year = date.getFullYear();

		return `${day}-${month}-${year}`;
	};
	var formattedDate_new = "";
	if (row[10] === "-" || row[10] === "" || row[10] === null || row[10] === " ") {
		//  console.log(row[10]);
	}
	else {
		const inputDate = row[10];
		formattedDate_new = formatDate(inputDate);
	}
	// console.log(row[0])
	// useEffect(() => {
	// 	window.location.hash = `/${row[0]}`;
	//   }, [row[0]]);

	return (
		<>
			<Dialog
				fullScreen
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				PaperProps={{
					style: {
						backgroundColor: theme.palette.background.pageContent,
						//   backdropFilter: "blur(800000px)",
						boxShadow: "none"
					}
				}}
			>
				<div style={{ display: "block" }}>
					<DialogTitle style={{ float: "left", display: "flex", alignItems: "center" }}>
						<Tooltip title={"Milestone Name"}><div>{milestoneName}</div></Tooltip>
						<ArrowRightIcon />
						<Tooltip title={"Tasklist Name"}><div>{row[0]}</div></Tooltip>
					</DialogTitle>
					<DialogActions style={{ float: "right", padding: "16px 24px 16px 24px" }}>
						<Button onClick={handleClose} variant="text"><CloseIcon fontSize="medium" /></Button>
					</DialogActions>
				</div>
				<DialogContent style={{ paddingTop: 0 }}>
					<DialogContentText component={'span'}>
						{/* <Task /> */}
						{tasks}
					</DialogContentText>
				</DialogContent>
			</Dialog>
			<TableRow hover={true} onClick={(event) => {
				if (event.target.className === "tasklist-projects-url-link") {
					return;
				}
				
			}}
				sx={{
					backgroundColor: theme.palette.background.tableRow + " !important",
					'&:hover': {
						backgroundColor: theme.palette.background.tableRowHover + " !important"
					}
				}}
				onMouseEnter={() => setMoreVisible(true)}
				onMouseLeave={() => setMoreVisible(false)}
			>


				<TableCell align="center" onClick={(event) => {handleOpen()}} style={{ width: "2.5vw", maxWidth: "2.5vw" }}>{index + 1}</TableCell>
				{["", " ", undefined, null].some(value => row[8] === value) ? (
					<TableCell align="left" onClick={(event) => {handleOpen()}} style={{ maxWidth: "20vw", wordWrap: "break-word" }}>
						{/* <Link to={row[7]} target='_blank' className="tasklist-projects-url-link"
							style={{ textDecoration: "none", color: "inherit" }}
							onMouseEnter={(event) => {
								event.target.style.color = theme.palette.primary.main;
							}}
							onMouseLeave={(event) => {
								event.target.style.color = "inherit";
							}}> */}
							{row[0]} <span style={{color: "crimson"}} >{row[15] === "Yes" ? "(deleted)" : ""}</span>
						{/* </Link> */}
						{/* {row[0]} */}
					</TableCell>
				) : (
					<TextOnlyTooltip title={<span style={{ fontFamily: 'Poppins', fontSize: '0.875rem', color: "Black", backgroundImage: "" }}>{row[8]} </span>} followCursor >

						<TableCell align="left" onClick={(event) => {handleOpen()}} style={{ maxWidth: "20vw", wordWrap: "break-word" }}>
							{/* <Link to={row[7]} target='_blank' className="tasklist-projects-url-link"
								style={{ textDecoration: "none", color: "inherit" }}
								onMouseEnter={(event) => {
									event.target.style.color = theme.palette.primary.main;
								}}
								onMouseLeave={(event) => {
									event.target.style.color = "inherit";
								}}> */}
								{row[0]}  <span style={{color: "crimson"}} >{row[15] === "Yes" ? "(deleted)" : ""}</span>
							{/* </Link> */}
							{/* {row[0]} */}
						</TableCell>
					</TextOnlyTooltip>
				)}
				<TableCell align="center" onClick={(event) => {handleOpen()}} style={{ width: "8vw" }}><GetNameAndEmpID emails={row[1]} /></TableCell>
				<TableCell align="center" onClick={(event) => {handleOpen()}} ><Box>{priority}<p>{row[4]}</p></Box></TableCell>
				<TableCell align="center" onClick={(event) => {handleOpen()}} style={{ minWidth: "3vw", hyphens: "none" }}>{row[2]}</TableCell>
				<TableCell align="center" onClick={(event) => {handleOpen()}} style={{ minWidth: "3vw", hyphens: "none", color: color }}>
					{
						row[5] === 'Completed' || row[5] === 'Cancelled' || row[5] === 'Updated to IDC' || row[5] === 'Closed' ?
							<Box>
								<Box sx={{
									fontSize: "1rem"
								}}>
									{formattedDate_new === "" || formattedDate_new === null ? "-" : formattedDate_new}
								</Box>
								<Box sx={{
									fontSize: "0.6rem",
									color: theme.palette.background.text
								}}>
									{toggle.current ? ("DueDate :" + row[3]) : ([undefined, "", " ", null, "-"].includes(row[3]) || [undefined, "", " ", null, "-"].includes(row[10]) ? "" : "DueDate :" + row[3])}
								</Box>
							</Box>
							:
							row[3]
					}
				</TableCell>
				{/* <TableCell align="center"><Tooltip title={row[5]} placement="right">{status}</Tooltip></TableCell> */}
				<TableCell align="center" onClick={(event) => {handleOpen()}}>
					{
						statusComponent[row[5]] === undefined ?row[5] :
							<div style={{
								display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center",
								color: statusComponent[row[5]] === undefined ? <></> : statusComponent[row[5]].color
							}}>
								{statusComponent[row[5]] === undefined ? "" : statusComponent[row[5]].component}
								{statusComponent[row[5]] === undefined ? "-" : statusComponent[row[5]].name}
							</div>
					}

				</TableCell>
				<MoreSection className='slide-animation' moreVisibile={moreVisibile} setMoreVisible={setMoreVisible} MilestoneUniqueID1={row[11]}  setMoreButton={setMoreButton} projectsLink={row[7]} CreatorRowid ={row[16]} TaskListId={row[6]} rowID={row[17]}/>

			</TableRow>
			
		</>
	);
}




function MoreSection({ moreVisibile, setMoreVisible, MilestoneUniqueID1,  setMoreButton, projectsLink,CreatorRowid, TaskListId,rowID}) {


	const [copiedText, setCopiedText] = useState('');
	const [moreAnchor, setMoreAnchor] = useState(null);
	const [showAlert, setShowAlert] = useState(false);
	const [showAlert2, setShowAlert2] = useState(false);
	const [showAlert7, setShowAlert7] = useState(false);
	const [showAlertCompleted, setShowAlertCompleted] = useState(false);
	const [showAlertDuteDateCompleted, setShowAlertDuteDateCompleted] = useState(false);
	const [showAlertError, setShowAlertError] = useState(false);
	const [more, setmore] = useState(false);
	const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
	const [extendDueDate, setExtendDueDate] = useState(false);
	const MilestoneUniqueID = MilestoneUniqueID1; // Replace 'YourUniqueID' with the actual value you want to copy
	const { userDetails} = useContext(UserContext);
	const user_email = userDetails.current.auth.email_id;
	const [selectedDate, setSelectedDate] = useState(null);

	const isWeekend = (date) => {
		const day = date.day();
	  
		return day === 0 || day === 6;
	  };
	
	const handleDeleteClick = () => {
		setDeleteConfirmationOpen(true);
	};

	const handleExtendDue = () => {
		setExtendDueDate(true);
	};
	
	const handleExtendDueCancel = () => {
		setExtendDueDate(false);
		setMoreVisible(false);
	};
	
	const handleCancelDelete = () => {
		setDeleteConfirmationOpen(false);
	};

	async function deleteTasklist() {
		
		const url = '/server/sample?table=DeleteTaskList&CreatorRowId=' + CreatorRowid +'&TaskListId='+ TaskListId;
		const headers = {
			'Content-Type': 'application/json',
		};
		fetch(url, {
			method: 'POST',
			headers: headers,
		})
			.then(response => {
				if (!response.ok) {
					throw new Error('Request failed with status: ' + response.status);
				}
				if (response.headers.get('content-length') === '0') {
					throw new Error('Empty response');
				}
				return response.json();
			})
			.then(data => {
				const statusCode = data.StatusCode;
				if (statusCode == "200") {
					setShowAlert(false);
					setShowAlertCompleted(true);
					setTimeout(()=> {
						setShowAlertCompleted(false);
					}, 3000);
				}
				else{
					setShowAlertError(true);
					setTimeout(()=> {
						setShowAlertError(false);
					}, 3000);
				}
			})
			.catch(error => {
				console.error(error);
			});

			var detailsSet = [
                {"IsTasklistDeleted": "Yes", "ROWID": rowID},
             ];
             
            var datastore = window.catalyst.table;
            var table = datastore.tableId('5781000006858394');
            var updatePromise = table.updateRow(detailsSet);
            updatePromise
           .then((response) => {
                    //console.log(response.content);
             })
            .catch((err) => {
                    //console.log(err);
            });

	}

	const handleConfirmDelete = () => {
		try {
				setShowAlert(true);
				deleteTasklist();
				setDeleteConfirmationOpen(false);
				setMoreVisible(false);
		}
		catch (error) {
			console.error(error);
		}
	};

	async function ExtendDueDateTasklist() {

		const formattedDueDate = selectedDate ? dayjs(selectedDate).format('YYYY-MM-DD') : null;
        // console.log('Selected Date:', formattedDueDate);

		const url = '/server/sample?table=ExtendTasklistDueDate&CreatorRowId=' + CreatorRowid +'&TaskListId='+ TaskListId+'&NewDueDate='+ formattedDueDate;
		const headers = {
			'Content-Type': 'application/json',
		};
		fetch(url, {
			method: 'POST',
			headers: headers,
		})
			.then(response => {
				if (!response.ok) {
					throw new Error('Request failed with status: ' + response.status);
				}
				if (response.headers.get('content-length') === '0') {
					throw new Error('Empty response');
				}
				return response.json();
			})
			.then(data => {
				const statusCode = data.StatusCode;
				if (statusCode == "200") {
					setShowAlert2(false);
					setShowAlertDuteDateCompleted(true);
					setTimeout(()=> {
						setShowAlertDuteDateCompleted(false);
					}, 3000);
				}
				else{
					setShowAlertError(true);
					setShowAlert2(false);
					setTimeout(()=> {
						setShowAlertError(false);
					}, 3000);
				}
			})
			.catch(error => {
				console.error(error);
			});

		// 	var detailsSet = [
        //         {"IsTasklistDeleted": "Yes", "ROWID": rowID},
        //      ];
             
        //     var datastore = window.catalyst.table;
        //     var table = datastore.tableId('5781000006858394');
        //     var updatePromise = table.updateRow(detailsSet);
        //     updatePromise
        //    .then((response) => {
        //             //console.log(response.content);
        //      })
        //     .catch((err) => {
        //             //console.log(err);
        //     });

	}

	const handleConfirmExtendDueDate = () => {
		try {
				setShowAlert2(true);
				ExtendDueDateTasklist();
				setExtendDueDate(false);
		}
		catch (error) {
			console.error(error);
		}
	};

	const handleCopy = async () => {
		try {
			setShowAlert7(true);
			await navigator.clipboard.writeText(MilestoneUniqueID);
			setCopiedText(MilestoneUniqueID);
		} catch (err) {
			console.error('Failed to copy text: ', err);
		}
	};

 useEffect(() => {
	if (showAlert7) {
		const timer = setTimeout(() => {
			setShowAlert7(false);
		}, 2000);

		return () => clearTimeout(timer);
	}
}, [showAlert7]);
	return (
		<TableCell align="right" style={{ width: "5px", cursor: "pointer" }}>
			<Fade in={moreVisibile} timeout={100}>
				<MoreVertIcon onClick={(event) => {  setMoreAnchor(event.currentTarget); setMoreButton(true);}}/>
			</Fade>
			<Menu anchorEl={moreAnchor}
				open={moreAnchor !== null}
				onClose={() => { setMoreAnchor(null) ; setMoreButton(false);}}
			>
				<Tooltip title={MilestoneUniqueID} placement="right-start">
					<MenuItem onClick={handleCopy}  style={{ display: 'flex', alignItems: 'center',justifyContent: 'space-between', fontSize:"15px"}}>
						<ContentCopyIcon variant="contained" color="primary"  style={{ fontSize: '15px' }}></ContentCopyIcon>
						&nbsp;Tasklist UniqueID
					</MenuItem>
				</Tooltip>

				<MenuItem>
				        <InsertLinkTwoToneIcon style={{ fontSize: '18px' }}></InsertLinkTwoToneIcon>
						<Link to={projectsLink} target='_blank' className="tasklist-projects-url-link" style={{ textDecoration: "none", color: "inherit" }}>
						&nbsp;&nbsp;Open in Projects
						</Link>
				</MenuItem>

				<MenuItem onClick={handleExtendDue}>
						<Box style={{ display: "flex" }}>
							<TodayTwoToneIcon fontSize="small" style={{ fontSize: '18px' }}/>
							&nbsp;&nbsp;Extend Due Date
						</Box>
				</MenuItem>

               {user_email == "sharafath.s@zohocorp.com" ? (
					<MenuItem onClick={handleDeleteClick}>
						<Box style={{ display: "flex" }}>
							<DeleteIcon fontSize="small" style={{ fontSize: '18px' }}/>
							&nbsp;&nbsp;&nbsp;Delete
						</Box>
					</MenuItem>
			   ):(
				<></>
			   )}
				{/*  <MenuItem onClick={handleDeleteClick}>
				 	<Box style={{ display: "flex" }}>
				 		<DeleteIcon fontSize="small" />
				 		&nbsp;Delete
				 	</Box>
				 </MenuItem> */}

				<Dialog open={deleteConfirmationOpen} onClose={handleCancelDelete}>
					<DialogTitle>Confirm Deletion</DialogTitle>

					<DialogContent>
						Are you sure you want to delete this item?
					</DialogContent>

					<DialogActions>
						<Button onClick={handleCancelDelete} color="primary">
							Cancel
						</Button>
						<Button onClick={handleConfirmDelete} color="primary">
							Confirm
						</Button>
					</DialogActions>
					
				</Dialog>

				<Dialog
                    open={extendDueDate}
                    onClose={handleExtendDueCancel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
				>
					<DialogContent>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DateCalendar disablePast  shouldDisableDate={isWeekend} onChange={(selectedDate) => setSelectedDate(selectedDate)}/>
						</LocalizationProvider>
					</DialogContent>

					<DialogActions>
						<Button onClick={handleExtendDueCancel}>
							Cancel
						</Button>

						<Button onClick={handleConfirmExtendDueDate} autoFocus>
							Confirm
						</Button>

					</DialogActions>
				</Dialog>
			</Menu>

			<Snackbar
                    open={showAlert7}
                    onClose={() => setShowAlert7(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert severity="success">Tasklist UniqueID copied to clipboard 👍 </Alert>
            </Snackbar>

			<Snackbar
				open={showAlert}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="info">Deleting in progress...</Alert>
			</Snackbar>

			<Snackbar
				open={showAlertCompleted}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="info">TaskList is Deleted 😊</Alert>
			</Snackbar>

			<Snackbar
				open={showAlertDuteDateCompleted}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="info">TaskList Due Date is Extended Successfully 😊</Alert>
			</Snackbar>

			<Snackbar
				open={showAlertError}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="error">Some error occured please contanct "Zone_Support" channel.</Alert>
			</Snackbar>

			<Snackbar
				open={showAlert2}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="info">Due Date is getting update....</Alert>
			</Snackbar>

		</TableCell>

	);

}

export default TaskList;
