import React, { useEffect, useState, useContext } from 'react';
import { DashboardContext } from '../../dashboardMain/stateStorage/DashboardStateStorage';
import { useTheme } from '@emotion/react';

import {
    Box
} from '@mui/material';

import { getChartData } from './gridItem_1_dataProcessor';

import { Count } from '../../../../../util/components/Count/Count';
import { ISSUE_COUNT_COLORS_DARK, ISSUE_COUNT_COLORS_LIGHT } from '../../dashboardMain/DashboardUtil';

export function GridItem_1() {

    const { getDashboardContext, catalystDB, startTime } = useContext(DashboardContext);
    const { currentSelectedGridItem } = useContext(getDashboardContext());

    const isOpen = currentSelectedGridItem === "GridItem_1";
    const theme = useTheme();

    var colors = ISSUE_COUNT_COLORS_LIGHT;
    if(theme.palette.mode === "dark") {
        colors = ISSUE_COUNT_COLORS_DARK;
    }

    if(catalystDB === undefined) {
        return null;
    } else if(catalystDB.Tickets === null) {
        return null;
    }

    const chartData = getChartData(catalystDB, startTime);

    return (
        <Box sx={{
            height: "100%",
            width: "100%",
            boxSizing: "border-box"
        }}>
            <Count data={chartData} unit="Total" title={""} heading={"Issues"} colors={colors} />
        </Box>
    );

}