import React, { useContext, useEffect, useRef } from 'react';

import { Box, Button, Grid } from '@mui/material';
import {useTheme} from '@mui/material';

import { DashboardContext, DashboardContextProvider } from './stateStorage/DashboardStateStorage';
import { DashboardNavigator } from './DashboardNavigator';
import { fetchRemoteDB } from './DashboardDataFetcher';

import { CircularProgress } from '@mui/material';
import { UserContext } from '../../../../contexts/UserContext';

export default function Dashboard() {
    const theme = useTheme();

    return (
        <DashboardContextProvider>
            <Box className='page-content-3' 

            sx={{
                backgroundImage: theme.palette.mode === "light" ? 
                    "linear-gradient(to bottom right, #b2b6b8, #819ca3) !important"
                    :
                    "linear-gradient(to bottom right, #011317, #272f3d) !important",
                padding: "3px",
                boxSizing: "border-box"
            }}
            >
                <DashboardNavigator />
                <DashboardComponent />
            </Box>
        </DashboardContextProvider>
    );
}

function DashboardComponent() {
    const { getDashboardComponent } = useContext(DashboardContext);
    const theme = useTheme();
    const {loginUserDept} = useContext(UserContext);

    const DashboardComponent = getDashboardComponent();

    const { catalystDB, setCatalystDB, startTime } = useContext(DashboardContext);

    useEffect(() => {
        fetchRemoteDB(catalystDB, setCatalystDB, loginUserDept);
    }, []);

    if(catalystDB === undefined) {
        return (
            <Box sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                transform: "translateY(-5%)",
                gap: "15px"
            }}>
                <CircularProgress />
                <Box sx={{
                    color: theme.palette.background.textSecondary
                }}
                >
                    fetching data and piecing it together...
                </Box>
            </Box>
        )
    }

    return (
        <Box sx={{
            height: "100%",
            width: "100%"
        }}
        >
            {DashboardComponent}
        </Box>
    );
}