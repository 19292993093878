import { report_2_on_time_vs_delayed_data_fetcher_get_query_1 } from "../../../../../catalystDB/queryGenerator";
import { findMentees } from "../../../../../util/Loading";
import { queryDB } from "../../../../../util/QueryDB";

const cache = {};

export function fetchData(startTime, endTime, view, selection, teamDetails, loginUserDept) {
    const key = startTime+endTime+view+selection;

    if(key in cache) {
        return Promise.resolve(cache[key]);
    }

    const query = getQuery(startTime, endTime, view, selection, teamDetails, loginUserDept);
    return new Promise((resolve, reject) => {
        queryDB(query).then(result => {
            cache[key] = result;  
            return resolve(result);
        }).catch(error => {
            return reject(error);
        })
    });
}

function getQuery(startTime, endTime, view, selection, teamDetails, loginUserDept) {
    const startDate = new Date(startTime*1000).toISOString().split("T")[0];
    const endDate = new Date(endTime*1000).toISOString().split("T")[0];
    var filter = "";
    if(selection === "Overall") {
        filter = "";
    }
    else if(view === "ServerView") {
        filter = " AND Component = '" + selection + "'";
    } else if(view === "MentorView") {
        var menteesList = findMentees([selection], teamDetails);
        menteesList.push(selection);
        filter = " AND DRI IN '" + menteesList.join("','") + "'";
    }   

    const taskQuery = report_2_on_time_vs_delayed_data_fetcher_get_query_1(startDate, endDate, filter, loginUserDept);

    return taskQuery;
}