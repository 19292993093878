import { isDateInRange, isDateString, isTask } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    var chartData = [];
    var ticketsMap = {};

    catalystDB.Tickets.map(item => {
        const dateString = item.CREATEDTIME;

        if(isDateString(dateString) && isDateInRange(dateString, startTime)) {
            const completionDateString = new Date(item.CREATEDTIME).toISOString().split("T")[0];

            if([undefined, null, "", " "].includes(completionDateString)) {
                return;
            }

            if(completionDateString in ticketsMap) {
                ticketsMap[completionDateString] += 1;
            } else {
                ticketsMap[completionDateString] = 1;
            }
        }
    }); 

    var keys = Object.keys(ticketsMap);
    keys.sort((a, b) => {
        return new Date(a).getTime() < new Date(b).getTime() ? -1 : 1;
    })

    for(const key of keys) {
        chartData.push([
            key,
            ticketsMap[key]
        ]);
    }

    return chartData;
}