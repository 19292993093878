import React, {useContext, useState} from "react";
import { Box, Button, Menu, MenuItem } from "@mui/material";

import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { styled, useTheme } from '@mui/material/styles';
import { EmployeeCard } from "../../Loading";
import { UserContext } from "../../../contexts/UserContext";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import dayjs from "dayjs";

export function Filter({filter, setFilter}) {
    return (
        <Box sx={{
            minWidth: "500px",
            maxHeight: "700px",
            maxWidth: "1000px",
            display: "flex",
            flexDirection: "column",
            gap: "30px"
        }}>
            <DRISelector filter={filter} setFilter={setFilter} />
            <CompletedFilter filter={filter} setFilter={setFilter} />
            <FeatureType filter={filter} setFilter={setFilter} />
            <Quarter filter={filter} setFilter={setFilter} />
            <Month filter={filter} setFilter={setFilter} />
            <StartEndDate filter={filter} setFilter={setFilter} />
            <DatePickers filter={filter} setFilter={setFilter} />
        </Box>
    );
}

function CompletedFilter({filter, setFilter}) {
    const theme = useTheme();

    function handleClick(type) {
        setFilter({
            ...filter,
            "completed": type
        });
    }
console.log(filter)
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px"
        }}>
            <StyledButton size="small" active={filter.completed || filter.completed === undefined} onClick={() => handleClick(true)} >
                Completed
            </StyledButton>
            <StyledButton size="small" active={!filter.completed || filter.completed === undefined} onClick={() => handleClick(false)} >
                Incomplete
            </StyledButton>
        </Box>
    );
}

function FeatureType({filter, setFilter}) {
    const theme = useTheme();

    function handleClick(type) {
        setFilter({
            ...filter,
            "feature_type": type
        });
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px"
        }}>
            <StyledButton size="small" active={filter.feature_type === "quarter"} onClick={() => handleClick("quarter")} >
                Quarter
            </StyledButton>
            <StyledButton size="small" active={filter.feature_type === "month"} onClick={() => handleClick("month")} >
                Month
            </StyledButton>
            <StyledButton size="small" active={filter.feature_type === "both"} onClick={() => handleClick("both")} >
                Both
            </StyledButton>
        </Box>
    );
}

function Quarter({filter, setFilter}) {
    const quarters = ["Q1", "Q2", "Q3", "Q4"];
    var prev_filter = filter.quarter === undefined ? [] : filter.quarter;

    function handleClick(type) {
        if(prev_filter.includes(type)) {
            prev_filter = prev_filter.filter(i => {
                return i !== type;
            });
        } else {
            prev_filter.push(type);
        }

        setFilter({
            ...filter,
            "quarter": prev_filter
        });
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px"
        }}>
            {
                quarters.map(quart => {
                    return (
                        <StyledButton size="small" active={prev_filter.includes(quart)} onClick={() => handleClick(quart)} >
                            {quart}
                        </StyledButton>
                    );
                })
            }
        </Box>
    );
}

function Month({filter, setFilter}) {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var prev_filter = filter.month === undefined ? [] : filter.month;

    function handleClick(type) {
        if(prev_filter.includes(type)) {
            prev_filter = prev_filter.filter(i => {
                return i !== type;
            });
        } else {
            prev_filter.push(type);
        }

        setFilter({
            ...filter,
            "month": prev_filter
        });
    }
    
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px"
        }}>
            {
                months.map(month => {
                    return (
                        <StyledButton size="small" active={prev_filter.includes(month)} onClick={() => handleClick(month)} >
                            {month}
                        </StyledButton>
                    );
                })
            }
        </Box>
    );
}

function StartEndDate({filter, setFilter}) {

    var default_start = new Date();
    var default_end = new Date();

    default_start.setDate(default_start.getDate() - 31);
    default_end.setDate(default_end.getDate() + 31);

    function handleClick(type, value) {
        const obj = {
            ...filter
        };


        if(filter[type] !== undefined) {
            obj[type] = undefined;
        } else {
            obj[type] = value;
        }

        setFilter({
            ...filter,
            ...obj
        });
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px"
        }}>
            <StyledButton size="small" active={filter.start_date !== undefined} onClick={() => handleClick("start_date", filter.start_date !== undefined ? filter.start_date : default_start)} >
                Start Date
            </StyledButton>
            <StyledButton size="small" active={filter.end_date !== undefined} onClick={() => handleClick("end_date", filter.end_date !== undefined ? filter.end_date : default_end)} >
                End Date
            </StyledButton>
        </Box>
    );
}

function DatePickers({filter, setFilter}) {
    var default_start = new Date();
    var default_end = new Date();

    default_start.setDate(default_start.getDate() - 30);

    function handleDateChange(newVal, type) {
        var obj = {
            ...filter
        };
        obj[type] = newVal.$d;

        setFilter(obj);
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-around",
            paddingBottom: "10px"
        }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                {
                    filter.start_date !== undefined ? 
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            alignItems: "center"
                        }}>
                            <span>choose start date</span>
                            <StaticDateTimePicker defaultValue={dayjs(filter.start_date !== undefined ? filter.start_date : default_start)} componentsProps={{ actionBar: { actions: [] } }} onChange={(newVal) => handleDateChange(newVal, "start_date")} orientation="portrait" />
                        </Box>
                        :
                        null
                }
                {
                    filter.end_date !== undefined ? 
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            alignItems: "center"
                        }}>
                            <span>choose end date</span>
                            <StaticDateTimePicker defaultValue={dayjs(filter.end_date !== undefined ? filter.end_date : default_end)} componentsProps={{ actionBar: { actions: [] } }} onChange={(newVal) => handleDateChange(newVal, "end_date")} orientation="portrait" />
                        </Box>
                        :
                        null
                }
            </LocalizationProvider>
        </Box>
    );
}

function DRISelector({filter, setFilter}) {
    const {teamDetails} = useContext(UserContext);
    const [anchor, setAnchor] = useState(null);
    var allUsers = Object.keys(teamDetails.current);
    allUsers = allUsers.filter(item => item!== "").sort();
    const theme = useTheme();

    function handleSubjectClick(event, item) {
        setAnchor(null);
        setFilter({
            ...filter,
            "dri": item
        });
    }

    function handleDRIClick() {
        if("dri" in filter) {
            const old_filter = Object.assign({}, filter);
            delete old_filter.dri;
            setFilter(old_filter);
        } else {
            setFilter({
                ...filter,
                "dri": allUsers[0]
            });
        }
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px"
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
            }}>
                <StyledButton size="small" active={"dri" in filter} onClick={handleDRIClick} >
                    DRI
                </StyledButton>
            </Box>
            {
                "dri" in filter ? 
                    <Button variant="outlined" size="small" onClick={(event) => setAnchor(event.currentTarget)} style={{
                        textTransform: "none",
                        color: theme.palette.background.text,
                        padding: "0px",
                        paddingRight: "5px",
                        borderRadius: "200px",
                        fontWeight: "900",
                        borderWidth: "0.5px",
                        borderColor: theme.palette.mode === "light" ? "black" : "grey"
                    }} disableRipple="true">
                        <EmployeeCard emails={filter.dri} />
                        {
                            anchor !== null ?
                                <ArrowDropUpIcon />
                                :
                                <ArrowDropDownIcon />
                        }
                    </Button>
                    :
                    null
            }
            <Menu
                anchorEl={anchor}
                open={anchor !== null}
                onClose={() => setAnchor(null)}
                sx={{
                    maxHeight: "550px",
                    marginTop: "10px"
                }}
            >
                {
                    allUsers.map((item, index) => {
                        return (
                            <MenuItem key={index} onClick={(event) => handleSubjectClick(event, item)}>
                                {<EmployeeCard emails={item} />}
                            </MenuItem>
                        );
                    })
                }
            </Menu>
        </Box>
    );
}

const StyledButton = styled(Button)(({ theme, active }) => ({
    borderRadius: "100px",
    textTransform: "none",
    backgroundImage: !active ? 
        theme.palette.mode === "light" ?
            'linear-gradient( 95deg,#818285cc 0%,#6b6c6ecc 100%)'
            :
            'linear-gradient( 95deg, #4b535e 0%,#434d5e 100%)'
        :
        theme.palette.mode === "light" ?
            'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(138,35,135) 100%)'
            :
            'linear-gradient( 95deg, #2894c9 0%, #2846c9 100%)'
    ,
    color: !active ? 
    theme.palette.mode === "light" ? 
    "#eeeeee"
    :
    "lightgrey" : "white",
    borderColor: "orange",
    paddingLeft: "10px",
    paddingRight: "10px"
}));