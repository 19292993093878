/* eslint-disable react/prop-types */

import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import React from "react";
import { CircularProgress } from "@mui/material";

import { fetchData } from "./dataFetcher";
import { processData } from "./dataProcessor";
import { styled } from '@mui/material/styles';
import { Paper, Grid } from "@mui/material";

import { OnTime_VS_Delayed } from "./OnTime_VS_Delayed";
import { BuildsAssociated } from "./BuildsAssociated";
import { Contribution } from "./Contribution";
import { Split } from "./Split";
import { MilestoneSummary } from "./MilestoneSummary";
import { Priority } from "./Priority";

const Item = styled(Paper)(({ theme, h, g }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.6),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: h ? h : "",
    boxSizing: "border-box",
    gap: g ? g : "5px",
    display: "flex",
    flexDirection: "column"
}));

export function MilestoneReport({milestoneId}) {

    const [data, setData] = useState(undefined);

    useEffect(() => {

        async function getData() {
            fetchData(milestoneId).then(result => {
                const processedData = processData(result);
                setData(processedData);
            }).catch(error => {
                setData(null);
            });
        }

        getData();

        return () => {
            setData(undefined);
        }
    }, []);

    if(data === undefined) {
        return (
            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                fontSize: "1.2rem"
            }}>
                <CircularProgress size={"1.5rem"} />
                Generating Milestone Report...
            </Box>
        );
    }

    if(data === null) {
        return (
            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                fontSize: "1.2rem"
            }}>
                Unable to generate any Reports for this Milestone
            </Box>
        );
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={0.75}>
                <Grid item xs={6} md={5}>
                    <Item h={"240px"}>
                        <Box sx={{height: "5%"}}>
                            Summary
                        </Box>
                        <Box sx={{
                            height: "95%",
                            textAlign: "left",
                            paddingLeft: "15px",
                            boxSizing: "border-box",
                            overflowY: "scroll"
                        }}>
                            <MilestoneSummary data={data} />
                        </Box>
                    </Item>
                </Grid>
                <Grid item xs={6} md={3.5}>
                    <Item h={"240px"}>
                        <Box sx={{ height: "5%" }}>
                            Priority
                        </Box>
                        <Box sx={{
                            height: "95%",
                            textAlign: "left"
                        }}>
                            <Priority data={data} />
                        </Box>
                    </Item>
                </Grid>
                <Grid item xs={6} md={3.5}>
                    <Item h={"240px"}>
                        <Box sx={{ height: "5%" }}>
                            OnTime vs Delayed Tasks
                        </Box>
                        <Box sx={{
                            height: "95%",
                            textAlign: "left"
                        }}>
                            <OnTime_VS_Delayed data={data} />
                        </Box>
                    </Item>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Item h={"290px"}>
                        <Box sx={{ height: "5%" }}>
                            Builds Associated
                        </Box>
                        <Box sx={{
                            height: "95%",
                            textAlign: "left"
                        }}>
                            <BuildsAssociated data={data} />
                        </Box>
                    </Item>
                </Grid>
                <Grid item xs={6} md={8}>
                    <Item h={"290px"}>
                        <Box sx={{ height: "5%" }}>
                            Contribution
                        </Box>
                        <Box sx={{
                            height: "95%",
                            textAlign: "left"
                        }}>
                            <Contribution data={data} />
                        </Box>
                    </Item>
                </Grid>
                <Grid item xs={6} md={12}>
                    <Item h={"50px"} g={1}>
                        <Box sx={{
                            height: "100%",
                            textAlign: "left",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex"
                        }}>
                            <Split data={data} />
                        </Box>
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
}

