
export var isDateString = function(dateString) {
    return (![null, undefined].includes(dateString) && new Date(dateString) !== "Invalid Date") && !isNaN(new Date(dateString));
}


export var isTask = function(item) {
    return item.ReportName === "TaskBreaker_Data_Report";
}

export var isRoadmap = function(item) {
    return item.ReportName === "Roadmap_Form_Report";
}

export var daysBetween = function(firstDate, secondDate) {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    return Math.round(((firstDate - secondDate) / oneDay));
}