/* eslint-disable react/prop-types */

import React, {useEffect} from "react";
import { Box } from "@mui/material";
import { PieChart } from "../../../../../util/components/pieChart/PieChart";
import { PIE_CHART_COLORS_DARK, PIE_CHART_COLORS_LIGHT } from "../../../dashboard/dashboardMain/DashboardUtil";
import { useTheme } from '@mui/material/styles';

export function Priority({data}) {
    
    const theme = useTheme();

    var colors = PIE_CHART_COLORS_LIGHT;
    if(theme.palette.mode === "dark") {
        colors = PIE_CHART_COLORS_DARK;
    }

    return (
        <Box sx={{
            height: "100%",
            width: "100%"
        }}>
            <PieChart data={data.tasks_priority}
                title={""}
                colors={colors}
                hallow={20}
                padAngle={ 0.060}
                // open={isOpen}
                // clickHandler={clickHandler}
                rerender={data.tasks_priority}
            />
        </Box>
    );
}