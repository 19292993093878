/* eslint-disable react/prop-types */
import React from 'react';
import {Box} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {Button} from '@mui/material';
import Alert from '@mui/material/Alert';
import { Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeskLogo from "./desk.svg";
import CliqLogo from "./cliq.png";

export function UserConsent({navigate}) {
	const theme = useTheme();

    function handleCloseClick() {
        window.catalyst.auth.signOut("/");
    }

    return (
        <Box style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <Paper style={{
                minWidth: "40vw",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: 5,
                borderRadius: 25,
                margin: "auto",
                marginTop: "auto"
            }}>
                <Box style={{
                    width: "100%"
                }}>
                    <Button style={{
                        float: "right"
                    }} onClick={handleCloseClick}>
                        <CloseIcon />
                    </Button>
                </Box>
                <Box style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: 15,
                    padding: "0px 20px 20px 20px",
                    borderRadius: 25
                }}>
                    <Box style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center"
                    }}>
                        <Box style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 5
                        }}>
                            <span style={{
                                color: theme.palette.background.text,
                                marginBottom: 10,
                                fontWeight: "bold"
                            }}>
                                Allow Zone to access your desk details?
                            </span>
                            
                            <Box style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "left",
                                fontSize: "0.7rem"
                            }}>
                                <Box style={{
                                    width: "100%",
                                    paddingLeft: "30px",
                                    paddingRight: "30px"
                                }}>
                                    <img src={CliqLogo} height={35} alt="zoho cliq logo"></img>
                                </Box>
                                <ul>
                                    <li>
                                        To post messages to your conversations directly or by using Incoming Webhooks
                                    </li>
                                    <li>
                                        Modify your chats
                                    </li>
                                </ul>
                            </Box>

                            <Box style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "left",
                                fontSize: "0.7rem"
                            }}>
                                <Box style={{
                                    width: "100%",
                                    paddingLeft: "30px",
                                    paddingRight: "30px"
                                }}>
                                    <img src={DeskLogo} height={35} alt="zoho desk logo"></img>
                                </Box>
                                <ul>
                                    <li>
                                        Search across data in your desk account.
                                    </li>
                                    <li>
                                        Read contacts, accounts and their sub resources
                                    </li>
                                    <li>
                                        Read tasks and its sub resources
                                    </li>
                                    <li>
                                        Read tickets and its sub resources
                                    </li>
                                    <li>
                                        Update tickets and its sub resources
                                    </li> 
                                </ul>
                            </Box>
                            <Box>
                                <Button variant='contained' onClick={navigate} style={{
                                    borderRadius: 50,
                                    fontSize: "0.7rem"
                                }}>
                                    Proceed to allow
                                </Button>
                            </Box>
                        </Box>

                    </Box>
                    <Box style={{
                        minWidth: 100,
                        maxWidth: 620
                    }}>
                        <Alert severity="info" style={{
                            borderRadius: 10,
                            fontSize: "0.7rem"
                        }}>
                            <b>Note:</b>
                            <br />
                            If you haven&#39;t accepted the invitation yet, please check your mail for the Invite.
                        </Alert>
                    </Box>
                </Box>
            </Paper >
        </Box>
    );
}
