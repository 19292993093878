/* eslint-disable react/prop-types */ 
/* esline-disable react/jsx-key */
import React from "react";
import { useEffect, useRef, useState, useContext } from "react";
import { UserContext } from "../../../../../contexts/UserContext";
import { useTheme } from '@mui/material/styles';
import { Box, ButtonGroup, Skeleton, Tab } from "@mui/material";
import { ReportContext } from "../newReportGeneral/NewReport";
import { getMilestoneRows } from "../reportSection1/ReportSection1DataFetcher"; 

import AirIcon from '@mui/icons-material/Air';
import { PieChart } from "../newReportGeneral/NewReportUtilComponents";

export function ReportSection5() {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            width: "100%",
            height: "98%",
            gap: "10px"
        }}>
             <Box sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.1rem",
                fontWeight: "bold"
            }}>
                Feature vs Fix vs Research
            </Box>
            <ReportBody/>
        </Box>
       
    );
}   


function ReportBody({setExpanded}) {

    const {
        subjectType, 
        setSubjectType, 
        subject, 
        setSubject, 
        timeFrame, 
        setTimeFrame, 
        time, 
        setTime,
        timeRange,
        setTimeRange
    } = useContext(ReportContext);
    const theme = useTheme();
    const {teamDetails, loginUserDept} = useContext(UserContext);
    
    const [loaded, setLoaded] = useState(undefined);
    const [milestoneRows, setMilestoneRows] = useState(undefined);
    const [chartData, setChartData] = useState(undefined);

    // var chartData = [
    //     [
    //         [
    //             "sdfasdfasdfasdfasdfasdfasdfasdfasdf",
    //             19
    //         ],
    //         [
    //             1312137000000,
    //             13
    //         ],
    //         [
    //             1314815400000,
    //             13
    //         ],
    //         [
    //             1317407400000,
    //             12
    //         ],
    //         [
    //             1320085800000,
    //             15
    //         ],
    //         [
    //             1322677800000,
    //             9
    //         ],
    //         [
    //             1325356200000,
    //             17
    //         ],
    //         [
    //             1328034600000,
    //             18
    //         ],
    //         [
    //             1330540200000,
    //             24
    //         ],
    //         [
    //             1333218600000,
    //             14
    //         ],
    //         [
    //             1335810600000,
    //             16
    //         ],
    //         [
    //             1338489000000,
    //             18
    //         ]
    //     ]
    // ];
    
    useEffect(() => {
        const controller = new AbortController();
        async function pullMilestoneRows() {

            getMilestoneRows(timeRange, subjectType, subject, teamDetails, loginUserDept).then((response) => {
                if(!controller.signal.aborted) {
                    if(response.length !== 0) {

                        var feature_count = 0;
                        var fix_count = 0;
                        var research_count = 0;
                        var data = [];

                        response.forEach(item => {
                            if(item.milestone_type === "Research") {
                                research_count += 1;
                            } else if(item.milestone_type === "Release") {
                                if(item.release_type === "Feature") {
                                    feature_count += 1;
                                } else if(item.release_type === "Fixes") {
                                    fix_count += 1;
                                } else {
                                    feature_count += 1;
                                }
                            } else {
                                feature_count += 1;
                            }
                        })

                        data.push([
                            [
                                "Feature Release",
                                feature_count
                            ],
                            [
                                "Fix Release",
                                fix_count
                            ],
                            [
                                "Research",
                                research_count
                            ]
                        ]);

                        setChartData(data);
                        setMilestoneRows(response);
                        setLoaded(true);
                    } else {
                        setLoaded(false);
                    } 
                }
            }).catch((error) => {
                if(!controller.signal.aborted) {
                    setLoaded(false);
                }
            });
        }

        setLoaded(undefined);
        pullMilestoneRows();

        return () => {
            controller.abort();
        };

    }, [timeRange, subjectType,subject]);


    if(loaded === undefined) {
        return (
            <Skeleton variant={"rounded"} height={"98%"} width={"100%"} animation="wave" />
        );
    } else if(loaded === false) {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%"
            }}>
                <Box>
                    <AirIcon sx={{
                        fontSize: "6rem",
                        color: theme.palette.mode === "light" ? "rgb(20, 20, 20, 0.06)": "rgb(200, 200, 200, 0.06)"
                    }} />
                </Box>
                <Box sx={{
                    fontSize: "0.7rem",
                    color: theme.palette.mode === "light" ? "rgb(10, 10, 10, 0.3)": "rgb(200, 200, 200, 0.4)"
                }}>
                    Nothing found
                </Box>
            </Box>
        );
    } else {
        return (
            <Box  sx={{
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                overflowY: "scroll",
                boxSizing: "border-box",
                backgroundColor: theme.palette.mode === 'dark' ? 'rgb(24,30,35)' : '#FAFAFA',
                borderRadius: "12px",
                overflowX: "hidden"
            }}
            >
                <PieChart title={""} columnName1={"col1"} columnName2={"col2"} data={chartData} colors={["#46BAC1", "#FF759C", "#8E69A6"]}/>
            </Box>
        );
    }
}