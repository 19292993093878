import React, {useState} from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Box } from "@mui/material";
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { MilestoneComponent } from "../MilestoneComponent/MilestoneComponent";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTheme } from "@mui/material";

import {COLORS} from './SplitSingle';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function MilestoneView({ open, setOpen, milestone_id, servers, index }) {

    const [currentMilestone, setCurrentMilestone] = useState(milestone_id);
    const [value, setValue] = useState(index);
    const theme = useTheme();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setCurrentMilestone(servers[newValue].milestone_id);
    };

    return (
        <React.Fragment>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{
                    position: 'relative' 
                }}>
                    <Toolbar sx={{
                        position: 'relative',
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons={false}
                            aria-label="scrollable prevent tabs example"
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: (theme.palette.mode === "light" ? COLORS[value].a : COLORS[value].c)
                                }
                            }}
                        >
                            {
                                servers.map((server, index) => {
                                    return <Tab label={server.name} sx={{
                                        color: (theme.palette.mode === "light" ? COLORS[index].a : COLORS[index].a) + " !important",
                                        fontSize: "1.1rem",
                                        textTransform: "none",
                                        fontWeight: "bold",
                                        borderRadius: "10px",
                                        margin: "0px 5px"
                                    }} />
                                })
                            }
                        </Tabs>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            <CloseIcon />
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box  sx={{
                    overflowY: "scroll",
                    height: "100%"
                }}>
                    <MilestoneComponent milestone_id={currentMilestone} style={{height: "50%"}} key={currentMilestone} />
                </Box>
            </Dialog>
        </React.Fragment>
    );
}