import { queryDB } from "../../../../util/QueryDB";
import { findMentees, findPeopleUnderServer } from "../../../../util/Loading";
import { report_2_data_fetcher_get_query_1, report_2_data_fetcher_get_query_2, report_2_data_fetcher_get_query_3, report_2_data_fetcher_get_query_4, report_2_data_fetcher_get_query_5 } from "../../../../catalystDB/queryGenerator";

export function fetchData(startTime, endTime, view, selection, teamDetails, getRmap=false, getIssues=false, loginUserDept) {

    const startDate = new Date(startTime*1000).toISOString().split("T")[0];
    const endDate = new Date(endTime*1000).toISOString().split("T")[0];
    var totalCount = getRmap ? 4 : 3;
    totalCount = getIssues ? totalCount+1 : totalCount;
    // console.log(startDate);
    // console.log(endDate);
    const key = startTime+"-"+endTime;

    var count = 0;
    var result = [{},{},{},{},{}];

    var filter = "";
    if(selection === "Overall") {
        filter = "";
    }
    else if(view === "ServerView") {
        filter = " AND Component = '" + selection + "'";
    } else if(view === "MentorView") {
        var menteesList = findMentees([selection], teamDetails);
        menteesList.push(selection);
        filter = " AND DRI IN '" + menteesList.join("','") + "'";
    }   

    var issuesFilter = "";
    if(selection === "Overall") {
        issuesFilter = "";
    } else if(view === "ServerView") {
       var peopleList = findPeopleUnderServer(selection, teamDetails);
    //    console.log(peopleList);
       peopleList.push(selection);
       issuesFilter = " AND (ClaimedBy_AssingedTo IN '" + peopleList.join("','") + "' ";
       peopleList.map((people) => {
           issuesFilter += " OR AllForwarded_From_to_To LIKE '*" + people + "*' ";
       })
       issuesFilter += ")";
    } else if(view === "MentorView") {
        var menteesList = findMentees([selection], teamDetails);
        menteesList.push(selection);
        issuesFilter = " AND (ClaimedBy_AssingedTo IN '" + menteesList.join("','") + "' ";
        menteesList.map((mentee) => {
            issuesFilter += " OR AllForwarded_From_to_To LIKE '*" + mentee + "*' ";
        })
        issuesFilter += ")";
    }

    const milestoneQuery = report_2_data_fetcher_get_query_1(startDate, endDate, filter, loginUserDept);
    const tasklsitQuery = report_2_data_fetcher_get_query_2(startDate, endDate, filter, loginUserDept);
    const taskQuery = report_2_data_fetcher_get_query_3(startDate, endDate, filter, loginUserDept);
    const rmapQuery =  report_2_data_fetcher_get_query_4(startDate, endDate, filter, loginUserDept);
    const issuesQuery = report_2_data_fetcher_get_query_5(startDate, endDate, issuesFilter, loginUserDept);

    // console.log("milestoneQuery: ----------------------------------");
    // console.log(milestoneQuery);
    // console.log("TasklistQuery: -----------------------------------");
    // console.log(tasklsitQuery);
    // console.log("TaskQuery: ---------------------------------------");
    // console.log(taskQuery);
    // console.log("RmapQuery: -----------------------------------------");
    // console.log(rmapQuery);
    // console.log("Issues Query: -----------------------------------------");
    // console.log(issuesQuery);

    return new Promise((resolve, reject) => {
        getData(milestoneQuery).then((res) => {
            result[0] = res;
            count++;
            if(count == totalCount) {
                return resolve(result);
            }
        }).catch((e) => {
            return reject(e);
        })
        getData(tasklsitQuery).then((res) => {
            result[1] = res;
            count++;
            if(count == totalCount) {
                return resolve(result);
            }
        }).catch((e) => {
            return reject(e);
        })
        getData(taskQuery).then((res) => {
            result[2] = res;
            count++;
            if(count == totalCount) {
                return resolve(result);
            }
        }).catch((e) => {
            return reject(e);
        })
        if(getRmap) {
            getData(rmapQuery).then((res) => {
                result[3] = res;
                count++;
                if(count == totalCount) {
                    return resolve(result);
                }
            }).catch((e) => {
                return reject(e);
            })
        }
        if(getIssues) {
            getData(issuesQuery).then((res) => {
                result[4] = res;
                count++;
                if(count == totalCount) {
                    return resolve(result);
                }
            }).catch((e) => {
                return reject(e);
            })
        }
    })
}

var sankeyData = [
	[
		[
			" 1",
			"m1 tasklist 1",
			1
		],
		[
			"milestone 1",
			"m1 tasklist 2",
			1
		],
		[
			"milestone 2",
			"m2 tasklist 1",
			1
		],
		[
			"milestone 2",
			"m2 tasklist 2",
			1
		],
		[
			"milestone 2",
			"m2 tasklist 3",
			1
		],
		[
			"m1 tasklist 1",
			"m1 t1 task 1",
			1
		],
		[
			"m1 tasklist 1",
			"m1 t1 task 2",
			1
		],
		[
			"m1 tasklist 2",
			"m1 t1 task 3",
			1
		],
		[
			"m2 tasklist 1",
			"m2 t1 task 15",
			1
		],
		[
			"m2 tasklist 1",
			"m2 t1 task 15",
			1
		],
		[
			"m2 tasklist 1",
			"m2 t1 task 152",
			1
		],
		[
			"m2 tasklist 1",
			"m2 t1 task 115",
			1
		],
		[
			"m2 tasklist 1",
			"m2 t1 task 11",
			1
		],
		[
			"m2 tasklist 2",
			"m2 t2 task 31",
			1
		],
		[
			"m2 tasklist 3",
			"m2 t3 task 321",
			1
		],
		[
			"m2 tasklist 3",
			"m2 t3 task 331",
			1
		],
		[
			"m2 tasklist 3",
			"m2 t3 task 341",
			1
		],
		[
			"m2 tasklist 3",
			"m2 t3 task 351",
			1
		],
		[
			"m2 tasklist 3",
			"m2 t3 task 361",
			1
		],
		[
			"m2 tasklist 3",
			"m2 t3 task 323",
			1
		]
	]
];

var cache = {};

function getData(query) {
    if(cache[query] === undefined) {
        return new Promise(async (resolve, reject) => {
            try {
                const data = await queryDB(query);
                // console.log(data);
                // localStorage.setItem(query, JSON.stringify(data));
                cache[query] = data;
                return resolve(data);
            } catch (e) {
                return reject(e);
            }
        });
    } else {
        // return Promise.resolve(JSON.parse(localStorage.getItem(query)));
        return Promise.resolve(cache[query]);
    }    
}

export async function getSankeyData(startTime, endTime, view, selection, teamDetails, showStatus, loginUserDept) {
    return await fetchData(startTime, endTime, view, selection, teamDetails, false, false, loginUserDept).then((data) => {
        var arr = [];
        data[1].forEach(element => {
            arr.push([element.TaskDetails.MilestoneName+" ", element.TaskDetails.TasklistName+"  ", 1]);
            if(showStatus) {
                arr.push([element.TaskDetails.TasklistName+"  ", element.TaskDetails.TasklistStatus, 1])
            }
        });

        data[2].forEach(element => {
            if(!showStatus) {
                arr.push([element.TaskBreaker.TaskListName+"  ", element.TaskBreaker.TaskName+"   ", 1]);
                arr.push([element.TaskBreaker.TaskName+"   ", element.TaskBreaker.TaskStatus, 1]);
            }
        });

        return Promise.resolve([arr]);
    }).catch((e) => {
        // console.log(e);
        return Promise.reject(e);
    })
}

export async function getTimelineData(startTime, endTime, view, selection, teamDetails, loginUserDept) {
    return await fetchData(startTime, endTime, view, selection, teamDetails, true, false, loginUserDept).then((data) => {
        // console.log(data);
        var result = [];
        var milestones = [];
        data[0].forEach((item) => {
            milestones.push([item.TaskDetails.MilestoneName, item.TaskDetails.MilestoneId]);
        })
        result.push(milestones);
        result.push(data);
        return Promise.resolve(result);
    }).catch((e) => {
        // console.log(e);
        return Promise.reject(e);
    })
}

export async function getHeatmapData(startTime, endTime, view, selection, teamDetails, loginUserDept) {
    return await fetchData(startTime, endTime, view, selection, teamDetails, false, true, loginUserDept).then((data) => {
        var peopleArr = [];
        if(view === "ServerView") {
            peopleArr = findPeopleUnderServer(selection, teamDetails);
        } else if(view === "MentorView") {
            peopleArr = findMentees([selection], teamDetails);
            peopleArr.push(selection);
        }
        return Promise.resolve([data, peopleArr]);
    }).catch((e) => {
        // console.log(e);
        return Promise.reject(e);
    })
}