import React, { useEffect, useState } from "react";
import { Box, useTheme,Tooltip,Paper,Divider} from "@mui/material";
import RoadMapLinkCardsData from './RoadMapLinkCardsData'


export default function RoadMapLinkCards({ ChatUrls , ChatDescription }) {
    const theme = useTheme();

	return (
        // <Box >hi</Box>
		<Box style={{
                padding: "10px",
				backgroundColor: theme.palette.background.infoDivOuter
			}}
		>
			<Paper className="info-div-paper"
				sx = {{
					backgroundColor: theme.palette.background.infoDivInner + " !important"
                    
				}}
			>
				<RoadMapLinkCardsData ChatUrls={ChatUrls} ChatDescription={ChatDescription}/>
				{/* <Divider /> */}
			</Paper>
		</Box>
	);
   
}


