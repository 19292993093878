import { isDateString } from "../../util/ReportUtil";

export function process(data) {

    const early_data = {
        "type": "line",
        "seriesname": "On Time",
        "data": []
    };
    const late_data = {
        "type": "line",
        "seriesname": "Delayed",
        "data": []
    };
    const d = [];
    const early_tasks = {};
    const late_tasks = {};

    data.map(item => {
        const task = item.TaskBreaker;

        if(isDateString(task.TaskDueDate_) && isDateString(task.TaskCompletionDate)) {
            const due = new Date(task.TaskDueDate_).getTime();
            const completed = new Date(task.TaskCompletionDate).getTime();

            if(completed <= due) {
                if(completed in early_tasks) {
                    early_tasks[completed] += 1;
                } else {
                    early_tasks[completed] = 1;
                }
            } else {
                if(completed in late_tasks) {
                    late_tasks[completed] += 1;
                } else {
                    late_tasks[completed] = 1;
                }
            }
        }
    });

    var early_tasks_time = Object.keys(early_tasks);

    early_tasks_time.sort();

    early_tasks_time.map(time => {
        const count = early_tasks[time];
        early_data.data.push([
            time, 
            count
        ]);
    });

    var late_tasks_time =  Object.keys(late_tasks);

    late_tasks_time.sort();

   late_tasks_time.map(time => {
        const count = late_tasks[time];
        late_data.data.push([
            time, 
            count
        ]);
    }); 

    d.push(early_data);
    d.push(late_data);

    return d;
}