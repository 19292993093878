const BOT_UNIQUE_NAME = `zvpsupport`;

export function close_ticket(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, setExtraComps) {
    setExtraComps("CLOSE_TICKET");
}

export function forward_to(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, setExtraComps, setLocalClaimedDRI, postData) {
    if(postData === undefined) { // trigger Forward dialog to get postData
        setExtraComps("FORWARD_TO");
        setAnchor(null);
        setMoreIconVisible(null);
    } else { // call the forwardTo action in server with post data
        const pre_message = `Forwarding #${data.DeskTicketNumber} to ${postData.type === "user" ? postData.dri : postData.ticket_component}`;
        const post_success_message = `forwarded #${data.DeskTicketNumber} to ${postData.type === "user" ? postData.dri+"!" : postData.ticket_component+"! and DRI will be changed once they've assigned one."}`;
        const post_failure_message = `Unable to forward #${data.DeskTicketNumber} to ${postData.type === "user" ? postData.dri : postData.ticket_component}`;
        // initial info message
        send_initial_msg(setEventFeedbackQueue, pre_message);
        // fowardNotes => oldForwardNotes => notes history
        // fowardHistory => oldForwardHistory => user history
        // forwardedComponent => oldForwarded components => compoents history
        const url = `/server/desk?task=forward&ID=${data.ID}&thread_id=${encodeURIComponent(data.ThreadID)}&Forward_To=${postData.type}&developer=${postData.dri}&component=${postData.ticket_component}&data=${postData.reason}&user=${postData.user}&ForwardNotes=${data.ForwardNotes}&ForwardHistory=${encodeURIComponent(data.ForwardHistory)}&ForwardedComponent=${data.Forwarded_Component}&allDevelopers=${encodeURIComponent(data.AllDevelopers)}`;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(response => {
            if(response.status !== 200) {
                return null;
            }
            return response;
        }).then(data => {
                if (data !== null && data.status == 200) { // success
                    send_success_msg_no_auto_close(setEventFeedbackQueue, post_success_message);
                    if(postData.type === "user") {
                        setLocalClaimedDRI(postData.dri);
                    }
                }
                else { // failure
                    send_failure_msg(setEventFeedbackQueue, post_failure_message);
                }
            })
            .catch(error => { // failure
                console.log('Error claiming ticket:', error);
                send_failure_msg(setEventFeedbackQueue, post_failure_message);
            })
            .finally(() => {
                remove_initial_msg(setEventFeedbackQueue, pre_message);
            });
    
    }
}

export function reply_as_bot(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, setExtraComps, userEmail, message) {
    if(message === undefined) { // trigger BotReply component to get message
        setExtraComps("REPLY_AS_BOT");
        setAnchor(null);
        setMoreIconVisible(false);
    } else { // got the message from BotReply component, post it.
        const pre_message = `Replying for #${data.DeskTicketNumber}`;
        const post_success_message = `Replied to #${data.DeskTicketNumber}`;
        const post_failure_message = `Unable to reply as bot for #${data.DeskTicketNumber}`;
        // initial info message
        send_initial_msg(setEventFeedbackQueue, pre_message);
        const encodedMessage = encodeURIComponent(message);

        const url = `/server/desk?task=ReplyAsBot&user=${userEmail}&ticket_id=${data.DeskTicketNumber}&thread_id=${encodeURIComponent(data.Chat_ID)}&data=${encodedMessage}&ID=${data.ID}&channelUniqueName=${data.ChannelUniqueName}&botname=${BOT_UNIQUE_NAME}`;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(response => {
            if(response.status !== 200) {
                return null;
            }
            return response;
        }).then(data => {
                if (data !== null && data.status == 200) { // success
                    send_success_msg(setEventFeedbackQueue, post_success_message);
                }
                else { // failure
                    send_failure_msg(setEventFeedbackQueue, post_failure_message);
                }
            })
            .catch(error => { // failure
              
                send_failure_msg(setEventFeedbackQueue, post_failure_message);
            })
            .finally(() => {
                remove_initial_msg(setEventFeedbackQueue, pre_message);
            });
    }
}

export function declare_as_incident(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, setExtraComps) {
    setExtraComps("DECLARE_AS_INCIDENT");
}

export function follow_thread(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, zuid) {
    setAnchor(null);
    setMoreIconVisible(false);
    const pre_message = `Adding you as a follower for #${data.DeskTicketNumber} ticket's Cliq chat`;
    const post_success_message = `You are now following #${data.DeskTicketNumber} ticket's Cliq chat!`;
    const post_failure_message = `Unable to add you as a follower for #${data.DeskTicketNumber} ticket's Cliq chat.`;
    // initial info message
    send_initial_msg(setEventFeedbackQueue, pre_message);

    const url = `/server/userToken/cliq?task=follow&zuid=${zuid}&thread_id=${encodeURIComponent(data.Chat_ID)}`; // userEmail also works instead of zuid
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(response => {
        if(response.status !== 200) {
            return null;
        }
        return response;
    }).then(data => {
            if (data !== null && data.status == 200) { // success
                send_success_msg(setEventFeedbackQueue, post_success_message);
            }
            else { // failure
                send_failure_msg(setEventFeedbackQueue, post_failure_message);
            }
        })
        .catch(error => { // failure
            console.log('Error claiming ticket:', error);
            send_failure_msg(setEventFeedbackQueue, post_failure_message);
        })
        .finally(() => {
            remove_initial_msg(setEventFeedbackQueue, pre_message);
        });
}

export function go_to_thread(data, setAnchor, setMoreIconVisible) {
    const url = `https://cliq.zoho.com/company/64396901/chats/${data.Chat_ID}`;
    window.open(url, '_blank').focus();
    setAnchor(null);
    setMoreIconVisible(false);
}

// ====================== util functions ====================

function send_initial_msg(setEventFeedbackQueue, pre_message) {
    setEventFeedbackQueue(queue => {
        return [...queue, {
            message: pre_message,
            auto_hide: false,
            loading: true
        }]
    });
}

function remove_initial_msg(setEventFeedbackQueue, pre_message) {
    setEventFeedbackQueue(queue => {
        return queue.filter(q => q.message !== pre_message);
    });
}

function send_success_msg(setEventFeedbackQueue, post_success_message) {
    setEventFeedbackQueue(queue => {
        return [...queue, {
            message: post_success_message,
            auto_hide: true,
            loading: false,
            success: true
        }]
    });
}

function send_failure_msg(setEventFeedbackQueue, post_failure_message) {
    setEventFeedbackQueue(queue => {
        return [...queue, {
            message: post_failure_message,
            auto_hide: true,
            loading: false,
            success: false
        }]
    });
}

function send_success_msg_no_auto_close(setEventFeedbackQueue, post_success_message) {
    setEventFeedbackQueue(queue => {
        return [...queue, {
            message: post_success_message,
            auto_hide: false,
            loading: false,
            success: true
        }]
    });
}