import React, { useState, useEffect ,useContext} from 'react';
// import { queryDB } from '../../../util/QueryDB';
import { queryDB } from '../../../../util/QueryDB';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import HexagonIcon from '@mui/icons-material/Hexagon';
import { group_by_order, find_row_span } from '../../../../util/GroupingFunctions.js';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
// import CombinedInfo from './../../pageContent/CombinedMilestone/CombinedInfo'
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import { GetNameAndEmpID, days_between } from '../../../../util/Loading.js';
// import { UserContext } from '../../../contexts/UserContext.js';
import { UserContext } from '../../../../contexts/UserContext';
import { styled } from '@mui/material/styles';
import { external_current_progress_get_query_1, external_current_progress_get_query_2, external_current_progress_get_query_3 } from '../../../../catalystDB/queryGenerator.js';
// import (CurrentRoadMapInfo) from './currentRoadmap.js'
// import CurrentProgress from './../pageBody/pageContent/External/current/currentRoadmap.js';


var dataCache = {};
var dataCache1 = {};

const SelfMyTable = () => {
    const [valuesData, setValuesData] = useState([]);
    const [DataCheck , setDataCheck] = useState(false);
    const { userDetails, teamDetails, loginUserDept } = useContext(UserContext);
    const user_email = userDetails.current.auth.email_id;
    const user_servers = teamDetails.current[user_email].Servers;
    const queryStringSuffix = "('" + user_servers.replaceAll(",", "','") + "')";
    // console.log(user_servers)
    
    const theme = useTheme();
    
  
    useEffect(() => {
  
      const fetchValuesData = async () => {
        try {
        //   const result = await queryDB(`SELECT * FROM  AllBuildDetails  WHERE BuildBy = '*${userDetails.current.auth.email_id}*'`);
       const result = await queryDB(external_current_progress_get_query_1(loginUserDept));
       // const result = await queryDB(`SELECT * FROM QuarterlyRMap WHERE UniqueValueForRMap ='Yes'`);
          setValuesData(result);
          setDataCheck(true)
        } catch (error) {
          console.error(error);
        }
      };
  
      fetchValuesData();
    }, []);
//   console.log(valuesData)
  
    var dataAsArray = valuesData.map((item) => {
      return [
      item.QuarterlyRMap.Year,
      item.QuarterlyRMap.RoadMapName,
      item.QuarterlyRMap.Status,
      item.QuarterlyRMap.Quareter,
      item.QuarterlyRMap.Server,
      item.QuarterlyRMap.MilestoneStatus,
      item.QuarterlyRMap.FeatureType,
      item.QuarterlyRMap.ParallelDueDayCount,
      [undefined, "", " ", null].includes(item.QuarterlyRMap.MilestoneStartDate) ? "-" : item.QuarterlyRMap.MilestoneStartDate,
      // item.QuarterlyRMap.MilestoneStartDate,
      item.QuarterlyRMap.MilestoneID,
      item.QuarterlyRMap.ServerName,
      // item.QuarterlyRMap.SequentialDueDayCount
      [undefined, "", " ", null,0].includes(item.QuarterlyRMap.SequentialDueDayCount) ? "-" : item.QuarterlyRMap.SequentialDueDayCount,
      item.QuarterlyRMap.ROWID,
      item.QuarterlyRMap.Quareter,
      item.QuarterlyRMap.Year,
      item.QuarterlyRMap.NewRoadMapName,
      item.QuarterlyRMap.UniqueValueForRMap,


      ];
    });
  
    const uniqueNewRowMapNames = new Set();
    
  
   
   
    // console.log(DataCheck)
    return (
      <>
      {DataCheck === false ? (
      
      <div className='page-content-2'>
      <div className="mytasks-table">
          <Table stickyHeader>
            <TableHead>
              <TableRow >
          <TableCell colSpan={7}>
            <Skeleton
              variant="rectangular"
              width={"100%"}
              sx={{ height: "calc(100vh - 180px)" }}
            />
          </TableCell>
        </TableRow>
        </TableHead>
        </Table>
        </div>
        </div>
    
        )
        :
        (
            valuesData.length === 0 ? (
              <div className='page-content-2'>
                <div className="mytasks-table">
                <Box component="h1" sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }}>
                    No data available
                </Box>
                </div>
              </div>
            ) : (
                <div className='page-content-2'>
                <div className="mytasks-table">
                  <TableContainer component={Paper} className="mytasks-table-container">
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow className="mytasks-table-head">
                          {/* <TableCell className='milestone-table-grouper-header'/> */}
                          <TableCell align="center">S.NO.</TableCell>
                          <TableCell align="center" >Name</TableCell>
                          <TableCell align="center" >Assosiated Servers</TableCell>
                          <TableCell align="center" >Progress %</TableCell>
                         <TableCell></TableCell>
        
                        </TableRow>
                      </TableHead>
          
                      <TableBody>
                      {valuesData.map((item, index) => {
                  const newRowMapName = item.QuarterlyRMap.NewRoadMapName;
                  const Year = item.QuarterlyRMap.Year;
                  const Quarter = item.QuarterlyRMap.Quareter;
                  const RoadmapName = item.QuarterlyRMap.RoadMapName;
                  const Status = item.QuarterlyRMap.Status;
                  const RowId = item.QuarterlyRMap.ROWID;
                  const MilestoneId = item.QuarterlyRMap.MilestoneID;

                  if (!uniqueNewRowMapNames.has(newRowMapName)) {
                    uniqueNewRowMapNames.add(newRowMapName);
                    const rowIndex = uniqueNewRowMapNames.size;
                    return (
                      <Row
                        key={index}
                        index={rowIndex}
                        newRowMapName={newRowMapName}
                        valuesData={valuesData}
                        Year={Year}
                        Quarter={Quarter}
                        RoadmapName={RoadmapName}
                        Status={Status}
                        RowId={RowId}
                        MilestoneId={MilestoneId}
                        dataAsArray={dataAsArray}
                      />
                    );
                  }

                  return null; // Skip rendering if newRowMapName is not unique
                })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            )
          )}
      
      </>
    );

}

function Row({index,newRowMapName,valuesData,Year, Quarter, RoadmapName, Status,RowId,MilestoneId,dataAsArray}){
    const [moreVisibile, setMoreVisible] = useState(false);
    const [statusCheck, setStatusCheck] = useState(false);
    const theme = useTheme();
    const [open, setOpen] = React.useState(null);
    const [rowDatas , setrowDatas] = useState("");
    const [rowIdDatas , setrowIdDatas] = useState("");
    const [MilestoneData, setMilestoneData] = useState([]);
    const [taskListData, setTaskListData] = React.useState([]);



    const associatedServers = valuesData
    .filter((item) => item.QuarterlyRMap.NewRoadMapName === newRowMapName)
    .map((item) => item.QuarterlyRMap.Server)
    .join(', ');
    // console.log(dataAsArray.map((item) => item[9]))
   
    const uniqueData = valuesData.filter((item) => item.QuarterlyRMap.NewRoadMapName === newRowMapName);
    // {uniqueData.map((item, dataIndex) => (
        const rowIds = uniqueData.map((item) => item.QuarterlyRMap.ROWID);
        const rowsToUpdate = uniqueData.map((item) => item.QuarterlyRMap.MilestoneID );

        const roadMapNamesArray = [];
        uniqueData.forEach((item, dataIndex) => {
            const roadMapName = item.QuarterlyRMap.RoadMapName;
            roadMapNamesArray.push(roadMapName); 
          });
        //   console.log(roadMapNamesArray)


         
          
    //   console.log(taskListData.length)
    // ))}
  
    const handleClick = () => {
      if (open === index) {
              setOpen(null);
          }
    
          else {
              setOpen(index);
          }
    };

   
      
  
    function processData(rowId, data) {
        // Implement your logic here
        setrowDatas(data)
        setrowIdDatas(rowId)
        // console.log(`Processing Data for RowId: ${rowId}`);
        // console.log(data);
      
        // You can perform further processing or pass the data to another component/function
      }
      

    useEffect(() => {
        if (roadMapNamesArray && roadMapNamesArray.length > 0) {
            const fetchTaskListCounts = async (roadMapNamesArray) => {
              const counts = [];
          
              await Promise.all(
                roadMapNamesArray.map(async (roadMapName) => {
                  try {
                    const query = external_current_progress_get_query_2(roadMapName);
                    const result = await queryDB(query);
                    counts.push({ roadMapName, count: result[0]['COUNT(TasklistName)'] || 0 });
                  } catch (error) {
                    // console.error(`Error fetching count for ${roadMapName}:`, error);
                    counts.push({ roadMapName, count: 0 });
                  }
                })
              );
          
              return counts;
            };
          
            fetchTaskListCounts(roadMapNamesArray).then((counts) => {
            //   console.log(counts.length);
              setTaskListData(counts.length);
            });
          } else {
            // console.error('roadMapNamesArray is undefined or empty');
          }
        // fetchTaskListCounts();
		fetchValuesData1();
	}, []);
    // console.log(taskListData)

    const fetchValuesData1 = async () => {
        try {
          // Use Promise.all to execute multiple async queries in parallel
          const promises = rowsToUpdate.map(async (item) => {
            const queryString = external_current_progress_get_query_3(item);
            if (queryString in dataCache) {
              return dataCache[queryString];
            } else {
              const result = await queryDB(queryString);
              dataCache[queryString] = result;
              return result;
            }
          });
      
          const results = await Promise.all(promises);
      
          // Store each result in an array
          const queryResults = results.map((result, index) => ({
            rowId: rowsToUpdate[index],
            data: result,
          }));
      
          // You can access individual query results by iterating through queryResults array
          queryResults.forEach((queryResult) => {
            const { rowId, data } = queryResult;
            processData(rowId, data);
            // Do something with rowId and data
            // console.log(`RowId: ${rowId}, Data:`, data);
          });
        } catch (error) {
        //   console.error(error);
        }
      };
      
      

	var dataAsArray1 = MilestoneData.map((item) => {
		return [
			
			item.TaskDetails.MilestoneName,
			item.TaskDetails.Component,
			item.TaskDetails.DRI,
			[undefined, "", " ", null].includes(item.TaskDetails.MilestoneStartDate) ? "-" : item.TaskDetails.MilestoneStartDate,
			[undefined, "", " ", null].includes(item.TaskDetails.MilestoneDueDate) ? "-" : item.TaskDetails.MilestoneDueDate,
      item.TaskDetails.TasklistStatus
			
		];
	});

    {rowsToUpdate.map((item, index) => {
        if (item !== null) {
          const rowId = rowIds[index];
          const detialsSet = [
            { "Status": "MilestoneCreated", "ROWID": rowId },
          ];
      
          const datastore = window.catalyst.table;
          const table = datastore.tableId('5781000010268237');
          const updatePromise = table.updateRow(detialsSet);
          updatePromise
            .then((response) => {
              // Handle success
              // console.log(response.content);
            })
            .catch((err) => {
              // Handle error
              // console.log(err);
            });
        }
      })}
      

   
    return (
      
      <>
        <TableRow
              key={index}
              hover={true}
              sx={{
                backgroundColor: theme.palette.background.tableRow,
                '&:hover': {
                  backgroundColor: theme.palette.background.tableRowHover + " !important"
                }
              }}
              onMouseEnter={() => setMoreVisible(true)}
              onMouseLeave={() => setMoreVisible(false)}
            >
              {/* {serTemp} */}
             
            
             
                 <TableCell align="center"  style={{ width: "2.5vw", maxWidth: "2.5vw" }}	onClick={handleClick}>{index}</TableCell>
                 <TableCell align="center"  	onClick={handleClick}>{newRowMapName}</TableCell>
                 <TableCell align="center" 	onClick={handleClick}>{associatedServers}</TableCell>
                 <TableCell align="center"  	onClick={handleClick}></TableCell>
                 <TableCell align="left" className='slide-animation' style={{ width: "30px"  }}>
                    <IconButton
                                size="small"
                                onClick={handleClick}
                            >
                                {open === null ? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon />}
                    </IconButton>
                </TableCell>
        </TableRow>
  
               
                 
                       <TableRow className="info-div-row"	 sx={{backgroundColor: theme.palette.background.infoDivOuter + " !important"}}>
                       <TableCell colSpan={8} style={{ padding:0 }}>
                      <Collapse in={open !== null} timeout="auto" unmountOnExit>
                      <Box className="info-div-paper"
                        sx={{
                            padding: "20px",
                            margin: "10px",
                            backgroundColor: theme.palette.background.infoDivInner + " !important"
                        }}>
                                <TableContainer component={Paper} className="mytasks-table-container">
                                <Table stickyHeader>
                                <TableHead>
                                    <TableRow className="mytasks-table-head">
                                    <TableCell align="center">S.NO</TableCell>
                                    <TableCell align="center">Year</TableCell>
                                    <TableCell align="center"> Quarter</TableCell>
                                    <TableCell align="center">RoadmapName</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {uniqueData.map((item, dataIndex) => (
                                    <TableRow  key={dataIndex}   sx={{
                                        backgroundColor: theme.palette.background.tableRow,
                                        '&:hover': {
                                          backgroundColor: theme.palette.background.tableRowHover + " !important"
                                        }
                                      }}>
                                        <TableCell  className='slide-animation' style={{ width: "30px"  }}align="center">{dataIndex+1}</TableCell>
                                        <TableCell  align="center">{item.QuarterlyRMap.Year}</TableCell>
                                        <TableCell  align="center">{item.QuarterlyRMap.Quareter}</TableCell>
                                        <TableCell  align="center">{item.QuarterlyRMap.RoadMapName}</TableCell>
                                        <TableCell  align="center">{item.QuarterlyRMap.Status}</TableCell>
                                       
                                    </TableRow>
                                    ))}
                                </TableBody>
                                </Table>
                            </TableContainer>
                                        </Box>
                                    {/* <CombinedInfo   index={index}  MilestoneName={dataAsArray1.map((item) => item[1])} MilestoneStartDate={dataAsArray1.map((item) => item[8])} MilestoneDueDate={dataAsArray1.map((item) => item[9])} Status={dataAsArray1.map((item) => item[5])} MilestoneId={dataAsArray1.map((item) => item[0])} DRI={dataAsArray1.map((item) => item[12])} Server={dataAsArray1.map((item) => item[4])} Tasklistid={dataAsArray1.map((item) => item[7])} TasklistPriority={dataAsArray1.map((item) => item[13])} MilestoneDescription={dataAsArray1.map((item) => item[14])} MilestoneStatus={dataAsArray1.map((item) => item[15])} TasklistStatus={dataAsArray1.map((item) => item[6])}/> */}
                            </Collapse>
                            </TableCell>
                            </TableRow>       
                
      </>
      
      
    )  ;
    }
  
  

export default SelfMyTable;



