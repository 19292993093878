import { external_report_milestone_data_fetcher_get_query_1, external_report_milestone_data_fetcher_get_query_2, external_report_milestone_data_fetcher_get_query_3 } from "../../../../../catalystDB/queryGenerator";
import { queryDB } from "../../../../../util/QueryDB";

const cache = {};

export async function fetchData(milestoneId) {

    if(milestoneId in cache) {
        return cache[milestoneId];
    }

    const data = {
        "TaskDetails": undefined,
        "TaskBreaker": undefined,
        "AllBuildDetails": undefined
    };
    return new Promise((resolve, reject) => {
        const query_1 = external_report_milestone_data_fetcher_get_query_1(milestoneId);
        const query_2 = external_report_milestone_data_fetcher_get_query_2(milestoneId);
        const query_3 = external_report_milestone_data_fetcher_get_query_3(milestoneId);

        Promise.allSettled([
            queryDB(query_1),
            queryDB(query_2),
            queryDB(query_3)
        ]).then(values => {
            if(values[0].status === "fulfilled" && values[0].value.length !== 0) {
                data.TaskDetails = values[0].value.map(item => {
                    return item.TaskDetails;
                })
            }
            if(values[1].status === "fulfilled" && values[1].value.length !== 0) {
                data.TaskBreaker = values[1].value.map(item => {
                    return item.TaskBreaker;
                })
            }
            if(values[2].status === "fulfilled" && values[2].value.length !== 0) {
                data.AllBuildDetails = values[2].value.map(item => {
                    return item.AllBuildDetails;
                })
            }
            cache[milestoneId] = data;
            return resolve(data);
        }).catch(error => {
            return reject(data);
        });

    });
}