import React, { useContext, useLayoutEffect, useState, useEffect } from 'react';
import { Route, Routes, Link, Navigate } from "react-router-dom";

import { ThemeProvider } from '@mui/material/styles';
import { lightTheme, darkTheme } from './contexts/ThemeContext.js';
import './App.css';
import TopBar from './topBar/TopBar.js';
import PageBody from './pageBody/PageBody.js';
import LoginPage from './loginPage/LoginPage.js';
import ZVPLogo from "./icons/ZVPLogo.svg";

import { TabDetails, TabStateProvider, TabStateContext } from './contexts/TabStateContext.js';
import UserDetailsProvider, { UserContext } from './contexts/UserContext.js';

import { getFromTeamDetails, getTokenDetails } from './AppDataFetcher.js';
import { Fade } from '@mui/material';
import { Box } from '@mui/material';
import { LoadingComponent, days_between, getHourDifference } from './util/Loading.js';

import { useTheme } from '@mui/material/styles';
import { UserConsent } from './loginPage/UserConsent.js';
import {ExternalUser} from './pageBody/pageContent/External/ExternalUser.js'
import SideBar from './pageBody/sideBar/SideBar.js'
import Alert from '@mui/material/Alert';

import { setCookie, getCookie, deleteCookie } from './util/Loading.js';
import { executeQuery } from './util/QueryDB.js';
import { app_component_get_query_1, app_component_get_query_2, app_component_get_query_3, app_component_get_query_4, app_component_get_query_5 } from './catalystDB/queryGenerator.js';
import { count } from 'd3';

const configuredScopes = "Desk.tickets.UPDATE,Desk.search.READ,Desk.tickets.READ,Desk.contacts.READ,Desk.tasks.READ,ZohoCliq.Chats.UPDATE,ZohoCliq.Messages.READ,ZohoCliq.Chats.READ,ZohoCliq.Webhooks.CREATE,ZohoCliq.Channels.UPDATE";

	
function PageContainer({ selectedOuterTab, selectedInnerTab, mode, setMode }) {
	const theme = useTheme();
	const { userDetails, teamDetails, supportUsers } = useContext(UserContext);
	const {screenShotRef} = useContext(TabStateContext);
	

	// if(getCookie("zone-session-start-time") === null) {
	// 	setCookie("zone-session-start-time", new Date(), 2);
	// } else {
	// 	const zoneSessionStartTime = getCookie("zone-session-start-time");
	// 	window.zoneTime = zoneSessionStartTime;
	// 	window.getTime = getHourDifference;

	// 	if(getHourDifference(new Date(zoneSessionStartTime), new Date()) >= 24) {
	// 		deleteCookie("zone-session-start-time");
	// 		window.catalyst.auth.signOut("/#/login"); 
	// 	}  
	// }

	// var targetDistance = 3;
	// function handleMouseMove(event)
	// {
	// 	const userEmail = userDetails.current.auth.email_id;
	// 	const userRole = teamDetails.current[userEmail].BetaUser;
	// 	if(userRole === "No")
	// 	{
	// 		return;
	// 	}
	// 	//return;
	// 	const outerSideBar = document.getElementsByClassName("outer-side-bar")[0];
	// 	const innerSideBar = document.getElementsByClassName("inner-side-bar")[0];

	// 	if(event.clientX < targetDistance)
	// 	{
	// 		outerSideBar.style.display = "block";
	// 		innerSideBar.style.left = "50px";
	// 		innerSideBar.style.width = "200px";
	// 		targetDistance = 50;
	// 	}
	// 	else
	// 	{
	// 		outerSideBar.style.display = "none";
	// 		innerSideBar.style.left = "0px";
	// 		innerSideBar.style.width = "250px";
	// 		targetDistance = 3;
	// 	}
	// }

	// useEffect(() => {

	// 	document.body.addEventListener("mousemove", handleMouseMove);

	// 	return () => {
	//     	document.body.removeEventListener('mousemove', handleMouseMove);
	//     };

	// }, []);

	const { authState, tokenMailID } = useContext(UserContext);

	const { tabState, changeTabState } = useContext(TabStateContext);
	const userEmail = userDetails.current.auth.email_id;
	const tokenEmail = tokenMailID.current[userEmail];
	const userRole = teamDetails.current !== null ? teamDetails.current[userEmail].Role : "";
	changeTabState(selectedOuterTab, selectedInnerTab);

	const UnauthorizedRedirect = () => {
			// if(!window.location.href.includes("app")) {
			window.open("/app/#/login", "_blank");
			// }
		// return <Navigate to="/login" />; 
	};

	if (typeof tokenEmail === "undefined" || tokenEmail.refreshTokenLastUpdatedTime === null ||
		tokenEmail.refreshTokenScopes === null || tokenEmail.refreshTokenScopes !== configuredScopes) {

		console.log(" about to go permisions page");///need to check with query

		return (
			<PermissionsPage />
		);

	} else {
		const createdTime = new Date(tokenEmail.refreshTokenLastUpdatedTime);
		const currentTime = new Date();

		// Calculate the time difference in milliseconds
		const timeDifference = currentTime - createdTime;
		const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

		if (daysDifference > 60) {
			console.log("Token email is more than 60 days old");
			console.log(" about to go permisions page");///need to check with query

			return (
				<PermissionsPage />
			);
		}
	}

	

	 if (authState === "authorized") {

		return (
			<Fade in={true}>
				<div className="page-container" ref={screenShotRef}>
					<TopBar setMode={setMode} mode={mode}/>
					<PageBody />
				</div>
			</Fade>
		);
	}
	else if (authState === "unauthorized") {
		return <UnauthorizedRedirect />;
		// return (
		// 	<Navigate to="/login" />
		// );
	}
	else {
		return (
			<div style={{ width: "100%", height: "100vh", textAlign: "center", backgroundColor: theme.palette.background.pageContent }}>
				<div style={{ paddingTop: "42vh" }}>
					<LoadingComponent />
				</div>
			</div>
		);
	}
}

var redirected = false;
function LandingPage() {
	const { authState, userDetails, teamDetails, supportUsers, tokenMailID,loginUserDept } = useContext(UserContext);
	const {screenShotRef} = useContext(TabStateContext);
	// const [permission, setPermission] = useState(true);
	const unauthorizedRedirect = () => {
			if(!window.location.href.includes("login") && !redirected) {
					redirected = true;
					window.open("/app/#/login", "_blank");
				}
	};


	if (authState === "authorized") {

		// if(teamDetails.current === "ExternalUser") {
		// 	return <>Hello world</>;
		// }
		const userEmail = userDetails.current.auth.email_id;
		const userRole = teamDetails.current !== null ? teamDetails.current[userEmail].Role : "";
		const tokenEmail = tokenMailID.current !== null ? tokenMailID.current[userEmail] : "";

		// if (localStorage.getItem("tokenPermission") !== "granted") {
		if (typeof tokenEmail === "undefined" || tokenEmail.refreshTokenLastUpdatedTime === null ||
			tokenEmail.refreshTokenScopes === null || tokenEmail.refreshTokenScopes !== configuredScopes) {

			console.log(" about to go permisions page");///need to check with query

			return (
				<PermissionsPage />
			);

		}
		else {
			const createdTime = new Date(tokenEmail.refreshTokenLastUpdatedTime);
			const currentTime = new Date();

			// Calculate the time difference in milliseconds
			const timeDifference = currentTime - createdTime;
			const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
			// console.log(userRole)
	// console.log(teamDetails.current)


			if (daysDifference > 60) {
				console.log("Token email is more than 60 days old");
				console.log(" about to go permisions page");///need to check with query

				return (
					<PermissionsPage />
				);
			}
			else if (userRole === "ExternalUser") {
				// console.log("hello")
					// return <Navigate to="/external/externaluser" />;
					return window.location = "app/#/external/externaluser"
			}
			else if (userRole === "Developer") {
				// return (
				// 	<Navigate to="/tasks/myTasks" />
				// );
				return window.location = "/app/#/tasks/myTasks"
			}
			// else if (loginUserDept.current === "ZMP" ){
			// 	return (
			// 		<Navigate to="/tickets/myTickets" />
			// 	);
			// }
			// return (

			// 	<Navigate to="/tasks/milestone" />
			// );
			return window.location = "/app/#/tasks/milestone"
		}



	}
	else if (authState === "unauthorized") {
		unauthorizedRedirect();
		// return (
		// 	<Navigate to="/login" />
		// );
	}
	else {
		return (
			<div style={{ textAlign: "center", marginTop: "42vh" }}>
				<LoadingComponent />
			</div>
		);
	}
}

function PermissionsPage() {

	const navigate = () => {
		var redirectUri = "";
		console.log("inside-->--> PermissionsPage-->");
		const currentURL = window.location.href;
		const currentProtocol = window.location.protocol;
		const currentPort = window.location.port;
		const currentDomain = window.location.hostname;
		// console.log("currentURL-->", currentURL, " :: currentDomain-->", currentDomain);
		if (currentDomain === 'localhost') {
			redirectUri = `${currentProtocol}//${currentDomain}:${currentPort}/server/userToken/generateToken`;
		}
		else {
			redirectUri = `${currentProtocol}//${currentDomain}/server/userToken/generateToken`;
		}
		//const redirectUri = `http://localhost:3000/server/userToken/generateToken`;//ZohoCliq.Webhooks.CREATE,Desk.tickets.READ,ZohoCreator.report.UPDATE
		const clientId = '1000.7VEOAWXA6GMC8Y05HQ7F1KSLT24PQY';
		const authUrl = `https://accounts.zoho.com/oauth/v2/auth?scope=${configuredScopes}&client_id=${clientId}&state=testing&response_type=code&access_type=offline&redirect_uri=${redirectUri}`;
		// console.log("authUrl  --: " + authUrl);
		window.location.href = authUrl;
		//localStorage.setItem("tokenPermission", "granted");
		localStorage.removeItem("tokenPermission");
	};


	return (
		<UserConsent navigate={navigate} />
	);
}


function AppContainer({ mode, setMode }) {

	const { authState, setUserAuthDetails, setAuthState,userDetails } = useContext(UserContext);
	const { loginUserEmail, teamDetails, supportUsers, tokenMailID, serverConfiguration, loginUserDept, loginUserDeptActual,
		loginUserZuid, configuration, zoneAdmin } = useContext(UserContext);
	const { setAllServers } = useContext(UserContext);
	

	//const { supportUsers} = useContext(UserContext);

	useLayoutEffect(() => {

		window.catalyst.auth.isUserAuthenticated()
			.then(async result => {
				try {
					await getConfs();
					await getUserDetails(result.content.email_id);
					await getServerDetails();
				} catch(e) {
					console.log(e)
					console.log(result.content.email_id)
				}
				setUserAuthDetails(result.content);
				setAuthState("authorized");
			})
			.catch(error => {
				console.log("error >> "+error)
				setUserAuthDetails(null);
				setAuthState("unauthorized");
				if(!window.location.href.includes("login")) {
					// window.catalyst.auth.signOut("/#/login");
					window.location = ("/#/login")
					// window.open("/#/login", "_blank");
				}
				// if (!window.location.href.includes("login")) {
				// 	// window.catalyst.auth.signOut("/#/login");
				// 	console.log("Hi")
				// 	window.open("/#/login", "_blank");
				// }
				// window.location.reload();
			});

		async function getConfs() {
			const conf_query = app_component_get_query_5();
			executeQuery(conf_query).then(response => {
				configuration.current = {};
				response.map(item => {
					try {
						configuration.current[item.Confs.KEY_] = JSON.parse(item.Confs.VALUE);
					} catch(error) {
					}
				});
			}).catch(error => {
				console.info(error);
			})
		}

		async function getUserDetails(userEmail) {
			// const teamHead = "mariaraj@zohocorp.com";
			const res = await getFromTeamDetails(app_component_get_query_1());

			var detailsOfTeam = {};
			for (const row of res) {
				// if (row.TeamDetails.Email === teamHead) {
				// 	setAllServers(row.TeamDetails.Servers.split(",").sort());
				// }
				detailsOfTeam[row.TeamDetails.Email] = row.TeamDetails;
			}
			loginUserDept.current = detailsOfTeam[userEmail].ZoneAdmin ? detailsOfTeam[userEmail].view_ : detailsOfTeam[userEmail].department;
			loginUserDeptActual.current = detailsOfTeam[userEmail].department;
			loginUserZuid.current = detailsOfTeam[userEmail].zuid;
			zoneAdmin.current = detailsOfTeam[userEmail].ZoneAdmin;
		
           if(loginUserDept.current === "ExternalUser"){
			console.log("No data need to add")
		   }
		   else{
			setAllServers(configuration.current[`department.${loginUserDept.current}.servers`].sort());
		   }
			var filtered_team_details = {};
			for(let k in detailsOfTeam) {
				let v = detailsOfTeam[k];
				let showedInDepartments = v.ShowedInDepartments === null ? "" : v.ShowedInDepartments;
				if(v.department === loginUserDept.current || v.ZoneAdmin || showedInDepartments.includes(loginUserDept.current)) {
					filtered_team_details[k] = v;
				} 
			}
			teamDetails.current = filtered_team_details;
			


			var detailsOfSupport = {};
			try {
				const res2 = await getFromTeamDetails(app_component_get_query_2());
				for (const row of res2) {
					detailsOfSupport[row.SupportMemberForm.Email_ID] = row.SupportMemberForm;
				}
			} catch(e) {
			}
			supportUsers.current = detailsOfSupport;

            // const userEmail = userDetails.current.auth.email_id;
			const res3 = await getTokenDetails(app_component_get_query_3(userEmail)); ///WHERE Email_ID='deepa.mt@zohocorp.com'`);// ${userEmail}

			var detailsOfTokenMailID = {};
			for (const row of res3) {
				detailsOfTokenMailID[row.Token.Email_Id] = row.Token;
			}
			tokenMailID.current = detailsOfTokenMailID;

		}

		async function getServerDetails() {
			const queryString = app_component_get_query_4(loginUserDept);
			await window.catalyst.ZCatalystQL.executeQuery(queryString).then((response) => {
				if(response.status === 200) {
					var serverConfigurationObject = {};
					response.content.forEach(item => {
						serverConfigurationObject[item.ServerConfiguration.Server] = item.ServerConfiguration;
					});
					serverConfiguration.current = serverConfigurationObject;
				} else {
					console.error(response);
				}
			}).catch((error) => {
				console.error(error);
			});
		}

	}, []);

	const routeItems = [];

	routeItems.push(<Route key="landing-page" path="/" element={<LandingPage />} />);
	if (authState === "authorized") {
		const loginUserRole = teamDetails.current !== null ? [loginUserEmail.current].Role : "";
		const betaUser = teamDetails.current !== null ? [loginUserEmail.current].BetaUser : "";
		const userEmail = userDetails.current.auth.email_id;
	    const userRole = teamDetails.current !== null ? teamDetails.current[userEmail].Role : "";

		// if (userRole === "ExternalUser") {
		// 	console.log("hello")
		// }
		

		if (userRole === "ExternalUser") {
			// console.log("hello")
			for (const outerTab in TabDetails) {
				const outerTabDetails = TabDetails[outerTab];
				const outerTabSegment = outerTabDetails.segment;
				const outerIndex = outerTabDetails.index;
				if(outerTabDetails.innerTabsList === null) {
					routeItems.push(<Route key={50}
						path={"/external"}
						element={<PageContainer selectedOuterTab={"external"}
							selectedInnerTab={null}
							setMode={setMode}
							mode={mode}
						/>}
					/>);
					continue;
					}

					for (const innerTab in outerTabDetails.innerTabsList) {
						const innerTabDetails = outerTabDetails.innerTabsList[innerTab];
						const innerTabSegment = innerTabDetails.segment;
						const innerIndex = innerTabDetails.index;

						routeItems.push(<Route key={50 + 0}
							path={"/external" + "/externaluser" + "/*"}
							element={<PageContainer selectedOuterTab={"external"}
								selectedInnerTab={"externalUser"}
								setMode={setMode}
								mode={mode}
							/>}
						/>);
					}
			}
		}
		else{
			for (const outerTab in TabDetails) {
				const outerTabDetails = TabDetails[outerTab];
				const outerTabSegment = outerTabDetails.segment;
				const outerIndex = outerTabDetails.index;
				// console.log(outerTabSegment + " outerIndex --"+outerIndex)
				// console.log(outerIndex)
				// console.log(outerTabDetails.innerTabsList)

	
				if(outerTabDetails.innerTabsList === null) {
					routeItems.push(<Route key={outerIndex * 10}
						path={outerTabSegment}
						element={<PageContainer selectedOuterTab={outerTab}
							selectedInnerTab={null}
							setMode={setMode}
							mode={mode}
						/>}
					/>);
					continue;
				}
				// console.log(routeItems)

	
				for (const innerTab in outerTabDetails.innerTabsList) {
					const innerTabDetails = outerTabDetails.innerTabsList[innerTab];
					const innerTabSegment = innerTabDetails.segment;
					const innerIndex = innerTabDetails.index;
					// console.log(innerTabSegment)
				    // console.log(innerIndex)
	
					// if(innerTab === "report")
					// {
					// 	continue;
					// }
					// console.log("hi")
					if (outerTab === "releases" && ["Manager", "Mentor", "CEO", "Super Admin","Developer"].includes(loginUserRole)) {
						continue;
					}
	
					if (outerTab === "tasks" && innerTab === "report" && !(loginUserRole === "CEO" || loginUserRole === "Super Admin" || loginUserRole === "Manager" || loginUserRole === "Mentor")) {
						continue;
					}
					if (outerTab === "timeline" && innerTab === "report" && !(loginUserRole === "CEO" || loginUserRole === "Super Admin" || loginUserRole === "Manager" )) {
						continue;
					}
	
					if (outerTab === "tasks" && innerTab === "roles" && !(loginUserRole === "CEO" || loginUserRole === "Super Admin" || loginUserRole === "Manager" || loginUserRole === "Mentor")) {
						continue;
					}
					// if(outerTab === "primary" && innerTab === "quarterly" && !(loginUserRole === "CEO" || loginUserRole === "Super Admin" || loginUserRole === "Manager" || loginUserEmail==="sharafath.s@zohocorp.com"))
					// {
					// 	continue;
					// }
					if(outerTab === "tasks" && innerTab === "releaseplanner" && !(loginUserEmail.current==="sharafath.s@zohocorp.com"))
					{
						continue;
					}
					if(outerTab === "tasks" && innerTab === "CombinedMilestone" && !(loginUserEmail.current==="sharafath.s@zohocorp.com"))
					{
						continue;
					}
	
					if (outerTab === "tickets" && innerTab === "departmentTickets"&& ["Manager","CEO", "Super Admin"].includes(loginUserRole)) {
						continue;
					}
	
					if (outerTab === "admin" && !(betaUser === 'Yes') && !(zoneAdmin.current)) {
						continue;
					}
					// console.log(outerTab)
					// console.log(outerIndex)
					// console.log(innerIndex)
					// console.log(outerIndex * 10 + innerIndex)
	
					routeItems.push(<Route key={outerIndex * 10 + innerIndex}
						path={outerTabSegment + innerTabSegment + "/*"}
						element={<PageContainer selectedOuterTab={outerTab}
							selectedInnerTab={innerTab}
							setMode={setMode}
							mode={mode}
						/>}
					/>);
				}
			}
		}
		routeItems.push(<Route key={"otherPaths"} path="*" element={<LandingPage />} />);
	}
	else {
		routeItems.push(<Route key="landing-page" path="*" element={<LandingPage />} />);
	}

	routeItems.push(<Route key="login-page" path="/login" element={<LoginPage />} />);
	// routeItems.push(<Route key="404-page" path="*" element={<h1>404 not found</h1>} />);
	// console.log(routeItems)
	return (
		<Routes>
			{routeItems}
		</Routes>
	);
}

function App() {

	const [mode, setMode] = useState(localStorage.getItem("theme") !== null ? localStorage.getItem("theme") : "light");

	return (
		<>
			<UserDetailsProvider>
				<TabStateProvider>
					<ThemeProvider theme={mode === "light" ? lightTheme : darkTheme}>
						<AppContainer mode={mode} setMode={setMode} />
					</ThemeProvider>
				</TabStateProvider>
			</UserDetailsProvider>
		</>
	);
}

export default App;
