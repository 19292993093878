/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";

export function BulletChart({title, data, colors, palette, clickHandler, rerender}) {
    const chartRef = useRef();
    const theme = useTheme();
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);

    var chartJSON = {
        "legend": {
            "show": false,
            "layout": "vertical",
            "colorPallete": {
                "options": {
                    "multicolor": palette === undefined ? "BrightNDull" : palette
                }
            },
            "colors": colors !== undefined ? colors: "green"
        },
        "chart": {
            "axes": {
                "rotated": true,
                "xaxis": {
                    "show": false,
                    "label": {
                        "show": false
                    },
                    "axisline": {
                        "show": false
                    },
                    "tickmark": {
                        "color": theme.palette.background.textSecondary
                    }
                },
                "yaxis": [
                    {
                        "label": {
                            "show": false,
                            "text": "Count"
                        },
                        "grid": {
                            "color": "transparent"
                        },
                        "axisline": {
                            "show": false
                        },
                        "tickmark": {
                            "color": theme.palette.background.textSecondary
                        }
                    }
                ]
            },
            "plot": {
                "plotoptions": {
                    "bullet": {
                        "levelMarker": {
                            "dataindex": 2,
                            "color": [
                                "#88888855"
                            ],
                            "maxBandWidth": 50,
                            "enabled": true
                        },
                        "targetMarker": {
                            "dataindex": 3,
                            "outerStrokeColor": [
                                "grey"
                            ]
                        },
                        "bandWidth": 25,
                        "events": {
                            "click": clickHandler
                        }
                    }
                }
            },
            "marginBottom": 0
        },
        "metadata": {
            "axes": {
                "x": [
                    0
                ],
                "y": [
                    [
                        1
                    ]
                ],
                "clr": [
                    2
                ],
                "tooltip": [
                    "<div style='text-align:center;padding:0 10px 0 10px;color:black'><div style='font-size:12px;'>{{val(2)}}</div><div style='line-height: 1'>{{val(1)}}</div></div>"
                ]
            },
            "columns": [
                {
                    "dataindex": 0,
                    "columnname": "",
                    "datatype": "ordinal"
                },
                {
                    "dataindex": 1,
                    "columnname": "",
                    "datatype": "numeric"
                },
                {
                    "columnname": ""
                }
            ]
        },
        "seriesdata": {
            "chartdata": data
        },
        "tooltip": {
            "backgroundColor": "white",
            "opacity": 0.95,
            "fontColor": "rgba(0,0,0,0.7)",
            "shadow": "2px 2px 2px rgba(0,0,0,0.3)",
            "fontSize": 15,
            "maxWidth": "100%"
        },
        "canvas": {
            "fontFamily": "poppins",
            "fontSize": 1,
            "fontWeight": "bold",
            "fontColor": theme.palette.background.textSecondary,
            "title": {
                "text": title,
                "hAlign": "center",
                "fontSize": "1em"
            },
            "subtitle": {
                "show": false
            },
            "background": {
                "alpha": 0
            },
            "events": {
                "onerror": null
            },
            "border": {
                "show": false
            }
        }
    };

    useEffect(() => {

        var chartObj = null;

        function updateSize() {
            setWindowSize([window.innerWidth, window.innerHeight]);
        }

        if(chartRef !== undefined && chartRef.current !== undefined) {
            chartObj = window.$ZC.charts(chartRef.current, chartJSON);
            window.addEventListener('resize', updateSize);
        }

        return () => {
            if(chartObj !== null) {
                chartObj.destroy();
            }
            window.removeEventListener('resize', updateSize);
        }

    }, [theme.palette.mode, windowSize, data, rerender]); 

    return (
        <Box ref={chartRef} sx={{
            height: "100%",
            width: "100%"
        }}>
        </Box>
    );
}