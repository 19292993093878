import React, {useContext, useState} from "react";

import { 
    Dialog,
    DialogTitle,
    Button,
    Divider,
    Menu,
    MenuItem,
    Box
} from "@mui/material";
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { EmployeeCard } from "../../../../../../util/Loading";
import { UserContext } from "../../../../../../contexts/UserContext";
import styles from "../TicketsTable.module.css";
import { forward_to } from "../postClaimActions";

export function Forward({data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, setExtraComps, setLocalClaimedDRI}) {

    const { loginUserEmail, teamDetails, configuration } = useContext(UserContext);

    var users = teamDetails.current;
    var allUsers = Object.keys(users).sort();
    allUsers = allUsers.filter((item) => { return item !== "" });

    const allComponents = configuration.current["department.ZVP.ticket_components"];
    
    const [dri, setDri] = useState(allUsers[0]);
    const [comp, setComp] = useState(allComponents[0]);
    const [type, setType] = useState("user");
    const [message, setMessage] = useState("");

    function handleClose() {
        setExtraComps(null);
    }

    function handleButtonClick() {
        if(message === "") {
            alert("Kindly enter a reason for forwarding the ticket!");
            return;
        }
        const postData = {
            "type": type,
            "user": loginUserEmail.current,
            "dri" : dri,
            "ticket_component": comp,
            "reason": message
        };
        forward_to(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, setExtraComps, setLocalClaimedDRI, postData)
        setExtraComps(null);
    }

    return (
        <Dialog onClose={handleClose} open={true}>
            <DialogTitle className={styles.flex_row_space_between_}>
                Forward Ticket
                <Button sx={{borderRadius: "10px"}} onClick={handleClose}>
                    <CloseRoundedIcon fontSize="large" />
                </Button>
            </DialogTitle>
            <Divider />
            <Box className={styles.forward_list_item} >
                <Box className={styles.forward_list_item_left}>
                    To
                </Box>
                <Box className={styles.forward_list_item_right}>
                    <ForwardType type={type} setType={setType}/>
                </Box>
            </Box>
            <Divider variant="middle"/>
            {
                type === "user" ?
                    <Box className={styles.forward_list_item} >
                        <Box className={styles.forward_list_item_left}>
                            User
                        </Box>
                        <Box className={styles.forward_list_item_right}>
                            <DRIDropDown dri={dri} setDri={setDri} allUsers={allUsers} />
                        </Box>
                    </Box>
                    :
                    <Box className={styles.forward_list_item} >
                        <Box className={styles.forward_list_item_left}>
                            Component
                        </Box>
                        <Box className={styles.forward_list_item_right}>
                            <ComponentDropDown comp={comp} setComp={setComp} allComps={allComponents} />
                        </Box>
                    </Box>
            }
            <Divider variant="middle"/>
            <Box className={styles.forward_list_item} >
                <Box className={styles.forward_list_item_left}>
                    Reason
                </Box>
                <Box className={styles.forward_list_item_right}>
                    <ReasonTextBox message={message} setMessage={setMessage} />
                </Box>
            </Box>
            <Divider/>
            <Box className={styles.action_button}>
                <Button variant="contained" onClick={handleButtonClick}>Forward</Button>
            </Box>
        </Dialog>
    );
}

function ForwardType({type, setType}) {
   
    const [anchor, setAnchor] = useState(null);
    const allTypes = ["user", "component"];

    function handleCompClick(event, item) {
        setType(item);
        setAnchor(null);
    }

    return (
        <>
            <Button className={`${styles.flex_row_center} ${styles.forward_type_button}`} variant="contained" onClick={(event) => setAnchor(event.currentTarget)}>
                {type}
                <ArrowDropDownRoundedIcon />
            </Button>
            <Menu
                anchorEl={anchor}
                open={anchor !== null}
                onClose={() => setAnchor(null)}
                sx={{
                    maxHeight: "350px",
                    marginTop: "3px"
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                {
                    allTypes.map((item, index) => {
                        return (
                            <MenuItem key={index} onClick={(event) => handleCompClick(event, item)}>
                                {item}
                            </MenuItem>
                        );
                    })
                }
            </Menu>
        </>
    );
}

function DRIDropDown({dri, setDri, allUsers}) {
   
    const [anchor, setAnchor] = useState(null);
   
    function handleDRIClick(event, item) {
        setDri(item);
        setAnchor(null);
    }

    return (
        <>
            <Button className={`${styles.flex_row_center} ${styles.dri_button}`} variant="contained" onClick={(event) => setAnchor(event.currentTarget)}>
                <EmployeeCard emails={dri} />
                <ArrowDropDownRoundedIcon />
            </Button>
            <Menu
                anchorEl={anchor}
                open={anchor !== null}
                onClose={() => setAnchor(null)}
                sx={{
                    maxHeight: "350px",
                    marginTop: "3px"
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                {
                    allUsers.map((item, index) => {
                        return (
                            <MenuItem key={index} onClick={(event) => handleDRIClick(event, item)}>
                                {<EmployeeCard emails={item} />}
                            </MenuItem>
                        );
                    })
                }
            </Menu>
        </>
    );
}


function ComponentDropDown({comp, setComp, allComps}) {
   
    const [anchor, setAnchor] = useState(null);
   
    function handleCompClick(event, item) {
        setComp(item);
        setAnchor(null);
    }

    return (
        <>
            <Button className={`${styles.flex_row_center} ${styles.component_button}`} variant="contained" onClick={(event) => setAnchor(event.currentTarget)}>
                {comp}
                <ArrowDropDownRoundedIcon />
            </Button>
            <Menu
                anchorEl={anchor}
                open={anchor !== null}
                onClose={() => setAnchor(null)}
                sx={{
                    maxHeight: "350px",
                    marginTop: "3px"
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                {
                    allComps.map((item, index) => {
                        return (
                            <MenuItem key={index} onClick={(event) => handleCompClick(event, item)}>
                                {item}
                            </MenuItem>
                        );
                    })
                }
            </Menu>
        </>
    );
}

function ReasonTextBox({message, setMessage}) {

    function handleChange(event) {
        setMessage(event.target.value);
    }

    return (
        <textarea className={`${styles.forward_text_box} `} onChange={handleChange} rows={10} spellCheck={"false"} multiline autoFocus/>
    );
}