import React from "react";

import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { Tooltip } from "@mui/material";

import {
    styled
} from "@mui/material/styles";

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
    background-color: #eeeeeeaa;
    backdrop-filter: blur(20px);
    color: black;
    border: 1px solid black;
  `);

export function StatCount({ data, units, title }) {

    const theme = useTheme();

    var COLORS = [
        "#B3741D",
        "#192394",
        "#94251B",
        "#B3741D55",
        "#19239455",
        "#94251B55"
    ];

    if (theme.palette.mode === "dark") {
        COLORS = [
            "#EFE0A0",
            "#BFCEEF",
            "#F0BDBA",
            "#EFE0A033",
            "#BFCEEF33",
            "#F0BDBA33"
        ];
    }

    return (
        <Box sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
            padding: "12px",
            gap: "10px"
        }}>
            <Box sx={{
                height: "7%",
                fontWeight: "bold",
                fontSize: "0.8rem",
                textTransform: "uppercase",
                opacity: 0.7,
                userSelect: "none"
            }}>
                {"༺ " + title + " ༻"}
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
                backgroundColor: "#D9D32944",
                height: "58%",
                width: "100%",
                borderRadius: "10px 10px 0px 0px",
                border: "1px solid " + COLORS[3],
                // boxShadow: "1px 1px 5px 1px " + COLORS[3],
                fontSize: "10rem",
                fontWeight: "bold"
            }}>
                <InnerBox cont1={data.average} cont2={units[0]} cont3={"AVG"} hover={data.average_hover} color={COLORS[0]} />
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "35%",
                width: "100%",
                gap: "10px"
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-around",
                    height: "100%",
                    width: "50%",
                    backgroundColor: "#2B7ADC44",
                    borderRadius: "0px 0px 0px 10px",
                    border: "1px solid " + COLORS[4],
                    // boxShadow: "1px 1px 5px 1px " + COLORS[4],
                    fontSize: "4rem",
                    fontWeight: "bold"
                }}>
                    <InnerBox cont1={data.min} cont2={units[1]} cont3={"MIN"} hover={data.min_hover} color={COLORS[1]} />
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-around",
                    height: "100%",
                    width: "50%",
                    backgroundColor: "#DB274744",
                    borderRadius: "0px 0px 10px 0px",
                    border: "1px solid " + COLORS[5],
                    // boxShadow: "1px 1px 5px 1px " + COLORS[5],
                    fontSize: "4rem",
                    fontWeight: "bold"
                }}>
                    <InnerBox cont1={data.max} cont2={units[2]} cont3={"MAX"} hover={data.max_hover} color={COLORS[2]} />
                </Box>
            </Box>
        </Box>
    );
}

function InnerBox({ cont1, cont2, cont3, color, hover }) {
    return (
        <>
            <Box sx={{
                fontSize: "1rem",
                lineHeight: 1,
                paddingBottom: "5px",
                color: color
            }}>
                {cont3}
            </Box>
            <CustomTooltip title={hover} placement="top-start">
                <Box sx={{
                    lineHeight: 0.95,
                    color: color,
                    cursor: hover !== undefined ? "pointer" : "default"
                }}>
                    {cont1}
                </Box>
            </CustomTooltip>
            <Box sx={{
                fontSize: "0.8rem",
                lineHeight: 1,
                color: color
            }}>
                {cont2}
            </Box>
        </>
    );
}