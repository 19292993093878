import React, {useState} from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTheme } from "@mui/material";

export function NestedTable({data, isDeleted}) {
    const theme = useTheme(); 

    return (
        <Box sx={{display:"flex", gap: "10px", flexDirection: "column"}}>
            {isDeleted ? <span style={{color: "crimson"}} >(Deleted)</span> : ""}
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead sx={{
                        backgroundColor: "#253237"
                    }}>
                        <TableRow>
                            <TableCell  sx={{maxWidth: "1px", color: "white"}} />
                            {
                                data.columns.map(column => {
                                    return  <TableCell align="left" sx={{color: "white"}}>{column}</TableCell>
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.rows.map(row => {
                                return <Row data={row} />
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}


function Row({data}) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    
    return (
        <>
            <TableRow hover={true}
			sx={{
				'& > *': { borderBottom: 'unset' },
				backgroundColor: theme.palette.background.tableRow,
				'&:hover': {
					backgroundColor: theme.palette.background.tableRowHover + " !important"
				}
			}}>
                <VisibileRow open={open} setOpen={setOpen} data={data.visible} isCollapsible={data.hidden !== undefined || data.content !== undefined}/>
            </TableRow>
            {
                data.hidden !== undefined || data.content !== undefined ? 
                    <TableRow>
                        <TableCell style={{ padding: 0 }} colSpan={6}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <HiddenRow data={data} />
                            </Collapse>
                        </TableCell>
                    </TableRow>
                    :
                    null
            }
        </>
    );
}

function VisibileRow({open, setOpen, data, isCollapsible}) {
    return (
        <>
            {
                isCollapsible ?    
                    <TableCell sx={{maxWidth: "1px"}}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    :
                    <TableCell />
            }
            {
                data.map(item => {
                    return  <TableCell align="left">{item}</TableCell>
                })
            }
        </>
    );
}

function HiddenRow({data}) {
    const theme = useTheme();

    if(data.content !== undefined) {
        return data.content
    }

    return (
        <Box  sx={{
            padding: "40px 40px",
            backgroundColor: theme.palette.background.infoDivInner
        }}>
            <NestedTable data={data.hidden} />
        </Box>
    );
}