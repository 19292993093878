
import React, { useState, useRef, useLayoutEffect, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import AddIcon from '@mui/icons-material/Add';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ArrowCircleUpTwoToneIcon from '@mui/icons-material/ArrowCircleUpTwoTone';
import ArrowCircleDownTwoToneIcon from '@mui/icons-material/ArrowCircleDownTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import Brightness1TwoToneIcon from '@mui/icons-material/Brightness1TwoTone';
import HexagonIcon from '@mui/icons-material/Hexagon';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import FilterListIcon from '@mui/icons-material/FilterList';
import BlockIcon from '@mui/icons-material/Block';
import AllOutIcon from '@mui/icons-material/AllOut';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { group_by_order, find_row_span } from './../../../util/GroupingFunctions.js';
import { GetNameAndEmpID, days_between, MultiLevelDropdown } from '../../../util/Loading.js';
import { Skeleton } from '@mui/material';

import { useTheme } from '@mui/material';
import './MyTasks.css';
import { getFromTaskBreaker } from './MyTasksDataFetcher.js';
import { getFromTeamDetails } from '../taskBreaker/TBDataFetcher.js';
import { Fade } from '@mui/material';

import { UserContext } from '../../../contexts/UserContext.js';
import { TabStateContext } from '../../../contexts/TabStateContext.js';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import { Select } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { lazyFetch, lazyLoader, queryDB } from '../../../util/QueryDB.js';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import { my_tasks_component_get_query_1, my_tasks_component_get_query_2, my_tasks_component_get_query_3, my_tasks_component_get_query_4, my_tasks_component_get_query_5, my_tasks_component_get_query_6, my_tasks_component_get_query_7, my_tasks_component_get_query_8, my_tasks_component_get_query_9 } from '../../../catalystDB/queryGenerator.js';
import { OtherDRI } from '../../../util/OtherDRI.js';

function MyTasks() {
    const [searchText, setSearchText] = useState("");

    const [initialRows, setInitialRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [hasData, setHasData] = useState(null);

    const [driList, setDriList] = useState([]);
    const [driTree, setDriTree] = useState([]);

    const [serversList, setServersList] = useState([]);
    const { userDetails, teamDetails,allServers, loginUserDept, configuration } = useContext(UserContext);
    // console.log(loginUserDept)
    const user_email = userDetails.current.auth.email_id;
    const user_role = teamDetails.current[user_email].Role;
    const toggle = useRef(false);
    // const userserverArr = teamDetails.current[user_email].reportingTo.split(",");
    // console.log(user_email)


    const [sortBy, setSortBy] = useState("Default");
    const [filterBy, setFilterBy] = useState(["Default"]);
    const [DeveloperOpen, setDeveloperOpen] = useState(false);
    const [FilterInDevelopOrToggle, setFilterInDevelopOrToggle] = useState(true);

    const compareFunctions = {
        "Server": serverCompareFunction,
        "Type": typeCompareFunction,
        "TaskName": tasknameCompareFunction,
        "Priority": priorityCompareFunction,
        "MilestoneName": milestoneNameCompareFunction,
        "TasklistName": tasklistNameCompareFunction,
        "Status": statusCompareFunction,
        "DRI": driCompareFunction
    }

    // console.log(initialRows);

    // var initialRows = [];

    useEffect(() => {
        async function fetchData() {
            try {
                await setDriDropDown(setDriList, setDriTree, userDetails, teamDetails,loginUserDept.current, configuration);
                await setServerDropDown(setServersList, userDetails, teamDetails, allServers);
            }
            catch (error) {
                console.log(error);
            }

            // try {
            //     // const result = await getFromTaskBreaker(`SELECT * FROM TaskBreaker WHERE DRI= 'jayasuriya.bm@zohocorp.com'`);
            //     const result = await getFromTaskBreaker(`SELECT * FROM TaskBreaker WHERE DRI= '${user_email}' AND ReportName= 'TaskBreaker_Data_Report' AND TaskStatus not in ('Closed', 'Cancelled','Updated to IDC') AND IsTaskDeleted is null`);
            //     console.log("first")
            //     setInitialRows(result);
            //     setHasData(true);
            // }
            // catch (error) {
            //     console.log(error);
            //     setHasData(false);
            // }
        }
        fetchData();
    }, []);

   

    // const hookFlag1 = useRef(true);
    // useEffect(async () => {
    //     if(hookFlag1.current) {
    //         hookFlag1.current = false;
    //         return;
    //     }
    //     setSearchText("");
    //     const toggleClosedFilter = toggle.current ? " (TaskStatus = 'Closed' OR TaskStatus = 'Cancelled') " : " (TaskStatus != 'Closed' AND TaskStatus != 'Cancelled') ";

    //     try {
    //         const result = await getFromTaskBreaker(`SELECT * FROM TaskBreaker WHERE DRI= '${user_email}' AND ReportName= 'TaskBreaker_Data_Report' AND ${toggleClosedFilter}`);
    //         setInitialRows(result);
    //         setHasData(true);
    //     }
    //     catch (error) {
    //         console.log(error);
    //         setHasData(false);
    //     }

    // }, [toggle.current])

    const hookFlag2 = useRef(true);

    useEffect(() => {
		async function run() {
            if (hookFlag2.current) {
                hookFlag2.current = false;
                return;
            }
            const toggleClosedFilter = toggle.current ? " (TaskStatus in ('Closed', 'Cancelled','Updated to IDC')) " : " (TaskStatus not in ('Closed', 'Cancelled','Updated to IDC')) ";
            // setSearchText("");
            if (filterBy.includes("Default")) {
                try {
                   
                  
                  
                    const result = await getFromTaskBreaker(my_tasks_component_get_query_1(user_email, toggleClosedFilter, loginUserDept));
                    result.map((row, index) => {
                        if (row[6] === 'Closed' || row[6] === 'Updated to IDC' || row[6] === 'Cancelled') {
                            function compareCompletionDate(a, b) {
                                const currentDate = new Date();
                                const completionDateA = new Date(a[14]);
                                const completionDateB = new Date(b[14]);
                            
                                const timeDifferenceA = Math.abs(completionDateA - currentDate);
                                const timeDifferenceB = Math.abs(completionDateB - currentDate);
                            
                                return timeDifferenceA - timeDifferenceB;
                            }
                            
                            // Sorting the array based on completion dates
                            result.sort(compareCompletionDate);
                        }
                    });
                    // console.log("useeffect")
                    setInitialRows(result);
                    setHasData(true);
                }
                catch (error) {
                    // console.log(error);
                    setHasData(false);
                }
            } else {
                // var filters = [];
                // for(var item of filterBy) {
                // 	if(item.includes("@zohocorp.com")) {
                // 		filters.push(`DRI= '${item}'`);
                // 	} else {
                // 		filters.push(`Component= '${item}'`);
                // 	}
                // }
                var driFilter = [];
                var componentFilter = [];
                for (var item of filterBy) {
                    if (item.includes("@zohocorp.com")) {
                        driFilter.push(item);
                    } else {
                        componentFilter.push(item);
                    }
                }
                var queryFilter = "";
                if (componentFilter.length === 0 && driFilter.length !== 0) {
                    queryFilter = `  DRI in ('${driFilter.join("','")}') `;
                } else if (driFilter.length === 0 && componentFilter.length !== 0) {
                    queryFilter = ` ( Component in ('${componentFilter.join("','")}') ) `;
                } else if (componentFilter.length !== 0 && driFilter.length !== 0) {
                    queryFilter = ` ( DRI in ('${driFilter.join("','")}') AND  Component in ('${componentFilter.join("','")}') ) `;
                }
                var qString = my_tasks_component_get_query_2(queryFilter, toggleClosedFilter, loginUserDept);
                    // console.log(`SELECT * FROM TaskBreaker WHERE ${queryFilter} AND ReportName= 'TaskBreaker_Data_Report' AND ${toggleClosedFilter} AND IsTaskDeleted is null`)
                try {
                    const result = await getFromTaskBreaker(qString);
                    result.map((row, index) => {
                        if (row[6] === 'Closed' || row[6] === 'Updated to IDC' || row[6] === 'Cancelled') {
                            function compareCompletionDate(a, b) {
                                const currentDate = new Date();
                                const completionDateA = new Date(a[14]);
                                const completionDateB = new Date(b[14]);
                            
                                const timeDifferenceA = Math.abs(completionDateA - currentDate);
                                const timeDifferenceB = Math.abs(completionDateB - currentDate);
                            
                                return timeDifferenceA - timeDifferenceB;
                            }
                            
                            // Sorting the array based on completion dates
                            result.sort(compareCompletionDate);
                        }
                    });
                    setInitialRows(result);
                    setHasData(true);
                } catch (e) {
                    setHasData(false);
                }
            }
		}
		run();
	}, [toggle.current]);

    // useEffect(async () => {
        
    //     // setSearchText("");
    //     const toggleClosedFilter = !DeveloperOpen ?  `ReportingTo = '${user_email}'` : " (TaskStatus not in ('Closed', 'Cancelled','Updated to IDC','Open')) ";
    //     console.log(`SELECT * FROM TaskBreaker WHERE DRI= '${user_email}' AND ReportName= 'TaskBreaker_Data_Report' AND ${toggleClosedFilter} AND IsTaskDeleted is null`)
    //     // setSearchText("");
    // }, [handleToggleDeveloperButtonClick]);


    useEffect(() => {

        if(FilterInDevelopOrToggle){
            // console.log("if")
            // if (filterBy.length === 0) {
            //     setFilterBy(["Default"]);
            //   }
            ToggleFilter();

         }
    else{
        // console.log("else")
        handleToggleDeveloperData();

    }

    if (filterBy === "Default") {
        // console.log("hi")
        setFilterBy(["Default"]);
      }
    //   console.log("coming"+filterBy)
      
    }, [filterBy,toggle.current]);

    async function ToggleFilter(){
        // console.log("ToggleFilter")
        if (hookFlag2.current) {
            hookFlag2.current = false;
            return;
        }
        const toggleClosedFilter = toggle.current ? " (TaskStatus in ('Closed', 'Cancelled','Updated to IDC')) " : " (TaskStatus not in ('Closed', 'Cancelled','Updated to IDC')) ";
        // setSearchText("");
        if (filterBy.includes("Default")) {
            try {
               
              
                //   console.log(loginUserDept.current)
                //   console.log(toggleClosedFilter)
              
                const result = await getFromTaskBreaker(my_tasks_component_get_query_3(user_email, toggleClosedFilter, loginUserDept));
                result.map((row, index) => {
					if (row[6] === 'Closed' || row[6] === 'Updated to IDC' || row[6] === 'Cancelled') {
						function compareCompletionDate(a, b) {
                            const currentDate = new Date();
                            const completionDateA = new Date(a[14]);
                            const completionDateB = new Date(b[14]);
                        
                            const timeDifferenceA = Math.abs(completionDateA - currentDate);
                            const timeDifferenceB = Math.abs(completionDateB - currentDate);
                        
                            return timeDifferenceA - timeDifferenceB;
                        }
                        
                        // Sorting the array based on completion dates
                        result.sort(compareCompletionDate);
					}
				});
                // console.log(result);
                setInitialRows(result);
                setHasData(true);
            }
            catch (error) {
                // console.log(error);
                setHasData(false);
            }
        } else {
            // var filters = [];
            // for(var item of filterBy) {
            // 	if(item.includes("@zohocorp.com")) {
            // 		filters.push(`DRI= '${item}'`);
            // 	} else {
            // 		filters.push(`Component= '${item}'`);
            // 	}
            // }
            var driFilter = [];
            var componentFilter = [];
            for (var item of filterBy) {
                if (item.includes("@zohocorp.com")) {
                    driFilter.push(item);
                } else {
                    componentFilter.push(item);
                }
            }
            var queryFilter = "";
            if (componentFilter.length === 0 && driFilter.length !== 0) {
                queryFilter = `  DRI in ('${driFilter.join("','")}') `;
            } else if (driFilter.length === 0 && componentFilter.length !== 0) {
                queryFilter = ` (   DRI= '${user_email}' AND Component in ('${componentFilter.join("','")}')  ) `;
            } else if (componentFilter.length !== 0 && driFilter.length !== 0) {
                queryFilter = ` ( DRI in ('${driFilter.join("','")}') AND  Component in ('${componentFilter.join("','")}') ) `;
            }
            var qString = my_tasks_component_get_query_4(queryFilter, toggleClosedFilter, loginUserDept);
                // console.log(`SELECT * FROM TaskBreaker WHERE ${queryFilter} AND ReportName= 'TaskBreaker_Data_Report' AND ${toggleClosedFilter} AND IsTaskDeleted is null`)
            try {
                const result = await getFromTaskBreaker(qString);
                result.map((row, index) => {
					if (row[6] === 'Closed' || row[6] === 'Updated to IDC' || row[6] === 'Cancelled') {
						function compareCompletionDate(a, b) {
                            const currentDate = new Date();
                            const completionDateA = new Date(a[14]);
                            const completionDateB = new Date(b[14]);
                        
                            const timeDifferenceA = Math.abs(completionDateA - currentDate);
                            const timeDifferenceB = Math.abs(completionDateB - currentDate);
                        
                            return timeDifferenceA - timeDifferenceB;
                        }
                        
                        // Sorting the array based on completion dates
                        result.sort(compareCompletionDate);
					}
				});
                setInitialRows(result);
                setHasData(true);
            } catch (e) {
                setHasData(false);
            }
        }
    }


    async function handleToggleDeveloperButtonClick() {
        
        setHasData(null);
        setDeveloperOpen(!DeveloperOpen);

            const toggleClosedFilter = !DeveloperOpen ? 
            `ReportingTo = 'vijayshankar.a@zohocorp.com' AND TaskStatus not in ('Closed', 'Cancelled','Updated to IDC','Open')`
            : 
            `DRI= '${user_email}' AND TaskStatus not in ('Closed', 'Cancelled','Updated to IDC','Open')`;
            try {
                const result = await getFromTaskBreaker(my_tasks_component_get_query_5(toggleClosedFilter, loginUserDept));
                result.map((row, index) => {
					if (row[6] === 'Closed' || row[6] === 'Updated to IDC' || row[6] === 'Cancelled') {
						function compareCompletionDate(a, b) {
                            const currentDate = new Date();
                            const completionDateA = new Date(a[14]);
                            const completionDateB = new Date(b[14]);
                        
                            const timeDifferenceA = Math.abs(completionDateA - currentDate);
                            const timeDifferenceB = Math.abs(completionDateB - currentDate);
                        
                            return timeDifferenceA - timeDifferenceB;
                        }
                        
                        // Sorting the array based on completion dates
                        result.sort(compareCompletionDate);
					}
				});
                // console.log(`SELECT * FROM TaskBreaker WHERE  ReportName= 'TaskBreaker_Data_Report' AND ${toggleClosedFilter} AND IsTaskDeleted is null AND department= '${loginUserDept.current}'`)
                // console.log(result)
                setInitialRows(result);
                setHasData(true);
                if(!DeveloperOpen){
                    const driFilter = result
                    .filter(row => row[18] && row[18].includes("vijayshankar.a@zohocorp.com"))
                    .map(filteredRow => filteredRow[7]);
                    const uniqueDriFilter = [...new Set(driFilter)];
                    // console.log(uniqueDriFilter);
                    // console.log(driFilter)
                    setFilterInDevelopOrToggle(false)
                    setDeveloperOpen(true)
                    setFilterBy(uniqueDriFilter)


                    // setDeveloperOpen(!DeveloperOpen)
    
                }
                else{
                    // console.log("why ??")
                    setDeveloperOpen(false)
                    setFilterBy(["Default"])

    
                }
    
            }
            catch (error) {
                // console.log(error);
                setHasData(false);
            }
    

        // handleToggleDeveloperData();
        // console.log("hi")
}

async function handleToggleDeveloperData() {
    
    // console.log("handleToggleDeveloperDatas")
  

    if (hookFlag2.current) {
        hookFlag2.current = false;
        return;
    }
    
   
    // setSearchText("");
    if (filterBy.includes("Default")) {
        const toggleClosedFilter = !DeveloperOpen ?
        `DRI= '${user_email}' AND TaskStatus not in ('Closed', 'Cancelled','Updated to IDC','Open')`
        : 
        `ReportingTo = 'vijayshankar.a@zohocorp.com' AND TaskStatus not in ('Closed', 'Cancelled','Updated to IDC','Open')`;

        try {
            const result = await getFromTaskBreaker(my_tasks_component_get_query_6(toggleClosedFilter, loginUserDept));
            result.map((row, index) => {
                if (row[6] === 'Closed' || row[6] === 'Updated to IDC' || row[6] === 'Cancelled') {
                    function compareCompletionDate(a, b) {
                        const currentDate = new Date();
                        const completionDateA = new Date(a[14]);
                        const completionDateB = new Date(b[14]);
                    
                        const timeDifferenceA = Math.abs(completionDateA - currentDate);
                        const timeDifferenceB = Math.abs(completionDateB - currentDate);
                    
                        return timeDifferenceA - timeDifferenceB;
                    }
                    
                    // Sorting the array based on completion dates
                    result.sort(compareCompletionDate);
                }
            });
            // console.log(`SELECT * FROM TaskBreaker WHERE ReportName= 'TaskBreaker_Data_Report' AND ${toggleClosedFilter} AND IsTaskDeleted is null`)
            // console.log(result)
            setInitialRows(result);
            setHasData(true);
            // if(!DeveloperOpen){
            //     const driFilter = result
            //     .filter(row => row[18] && row[18].includes("vijayshankar.a@zohocorp.com"))
            //     .map(filteredRow => filteredRow[7]);
            //     const uniqueDriFilter = [...new Set(driFilter)];
            //     // console.log(uniqueDriFilter);
            //     // console.log(driFilter)
            //     setFilterBy(uniqueDriFilter)
            //     // setDeveloperOpen(!DeveloperOpen)

            // }
            // else{
            //     setFilterBy(["Default"])
            //     setDeveloperOpen(!DeveloperOpen)

            // }

        }
        catch (error) {
            console.log(error);
            setHasData(false);
        }

        


    } else {
        // var filters = [];
        // for(var item of filterBy) {
        // 	if(item.includes("@zohocorp.com")) {
        // 		filters.push(`DRI= '${item}'`);
        // 	} else {
        // 		filters.push(`Component= '${item}'`);
        // 	}
        // }[]
        // console.log(DeveloperOpen)
        const toggleClosedFilter = DeveloperOpen ? 
        `ReportingTo = 'vijayshankar.a@zohocorp.com' AND TaskStatus not in ('Closed', 'Cancelled','Updated to IDC','Open')`
        : 
        `DRI= '${user_email}' AND TaskStatus not in ('Closed', 'Cancelled','Updated to IDC','Open')`;
        // if(DeveloperOpen!==true){
           
        //     setFilterBy(["Default"])
        //     setDeveloperOpen(!DeveloperOpen)

            
        // }
        // else{
        //     setFilterBy(["Default"])
        //     setDeveloperOpen(!DeveloperOpen)
        // }
        var driFilter = [];
        var componentFilter = [];
        for (var item of filterBy) {
            if (item.includes("@zohocorp.com")) {
                driFilter.push(item);
            } else {
                componentFilter.push(item);
            }
        }
        var queryFilter = "";
        if (componentFilter.length === 0 && driFilter.length !== 0) {
            queryFilter = `  DRI in ('${driFilter.join("','")}') `;
        } else if (driFilter.length === 0 && componentFilter.length !== 0) {
            queryFilter = ` Component in ('${componentFilter.join("','")}')`;
        } else if (componentFilter.length !== 0 && driFilter.length !== 0) {
            queryFilter = ` DRI in ('${driFilter.join("','")}') AND  Component in ('${componentFilter.join("','")}')`;
        }
        var qString = my_tasks_component_get_query_7(queryFilter, toggleClosedFilter, loginUserDept);
        // console.log(toggleClosedFilter)
        try {
            const result = await getFromTaskBreaker(qString);
            result.map((row, index) => {
                if (row[6] === 'Closed' || row[6] === 'Updated to IDC' || row[6] === 'Cancelled') {
                    function compareCompletionDate(a, b) {
                        const currentDate = new Date();
                        const completionDateA = new Date(a[14]);
                        const completionDateB = new Date(b[14]);
                    
                        const timeDifferenceA = Math.abs(completionDateA - currentDate);
                        const timeDifferenceB = Math.abs(completionDateB - currentDate);
                    
                        return timeDifferenceA - timeDifferenceB;
                    }
                    
                    // Sorting the array based on completion dates
                    result.sort(compareCompletionDate);
                }
            });
            setInitialRows(result);
            setHasData(true);
        } catch (e) {
            setHasData(false);
        }
}


}

    var rowsNew = group_by_order(initialRows, [2, 1], 0);
    // console.log(rowsNew);
    // console.log(initialRows);

    useEffect(() => {
        if (sortBy !== "Default") {
            rowsNew = rowsNew.sort(compareFunctions[sortBy]);
        }
        setRows(rowsNew);
    }, [initialRows, sortBy]);

    //var content = <div style={{textAlign: "center", marginTop: "35vh"}}><LoadingComponent /></div>;
    var content = (
        <>
            <MyTasksTopBar rowsCurrent={rowsNew} setRows={(r) => { setRows(r) }} setSearchText={setSearchText} drisList={driList} drisTree={driTree} serversList={serversList.sort()}
                toggle={toggle} setHasData={setHasData} setInitialRows={setInitialRows} userEmail={user_email} userRole={user_role} sortBy={sortBy} setSortBy={setSortBy}
                filterBy={filterBy} setFilterBy={setFilterBy} searchText={""} DeveloperOpen={DeveloperOpen} setDeveloperOpen={setDeveloperOpen} handleToggleDeveloperButtonClick={handleToggleDeveloperButtonClick} setFilterInDevelopOrToggle={setFilterInDevelopOrToggle}/>
            {/* <Skeleton variant="rectangular" width={1600} height={60} />; */}
            <Skeleton variant="rectangular" width={"100%"} sx={{ flex: 1 }} />
        </>
    );

    const theme = useTheme();

    if (initialRows.length !== 0 && hasData) {
        content = (
            <Fade in={true}>
                <div>
                    <MyTasksTopBar rowsCurrent={rowsNew} setRows={(r) => { setRows(r) }} setSearchText={setSearchText} drisList={driList} drisTree={driTree} serversList={serversList.sort()}
                        toggle={toggle} setHasData={setHasData} setInitialRows={setInitialRows} userEmail={user_email} userRole={user_role} sortBy={sortBy} setSortBy={setSortBy}
                        filterBy={filterBy} setFilterBy={setFilterBy} searchText={searchText} DeveloperOpen={DeveloperOpen} setDeveloperOpen={setDeveloperOpen} handleToggleDeveloperButtonClick={handleToggleDeveloperButtonClick} setFilterInDevelopOrToggle={setFilterInDevelopOrToggle}/>
                    <MyTasksTable toggle={toggle} rows={rows} searchText={searchText} allRows={initialRows} setRows={(r) => { setRows(r) }} sortBy={sortBy} setSortBy={setSortBy}
                        filterBy={filterBy} setFilterBy={setFilterBy} user_email={user_email}/>
                </div>
            </Fade>
        );
    }
    else if (hasData === false) {
        content = (
            <Fade in={true}>
                <div style={{ color: theme.palette.background.text }}>
                    <MyTasksTopBar rowsCurrent={rowsNew} setRows={(r) => setRows(r)} setSearchText={setSearchText} drisList={driList} drisTree={driTree} serversList={serversList.sort()}
                        toggle={toggle} setHasData={setHasData} setInitialRows={setInitialRows} userEmail={user_email} userRole={user_role} allRows={initialRows} sortBy={sortBy} setSortBy={setSortBy}
                        filterBy={filterBy} setFilterBy={setFilterBy} searchText={searchText} DeveloperOpen={DeveloperOpen} setDeveloperOpen={setDeveloperOpen} handleToggleDeveloperButtonClick={handleToggleDeveloperButtonClick} setFilterInDevelopOrToggle={setFilterInDevelopOrToggle}/>
                    <h1>No Tasks Found :(</h1>
                </div>
            </Fade>
        );
    }
    return (
        <div className="page-content-2">
            {content}
        </div>
    );
}

// function MultiLevelDropdown ({ dritree, handleDriTreeItemClick, handleDriTreeButtonClose, driAnchor })
//  {
//  const [closeMenu, setCloseMenu] = useState(false);

//  const handleMenuClose = () => {
//      setCloseMenu(true);
//  };

//  return (
//      <Menu
//          anchorEl={driAnchor}
//          open={ driAnchor !== null}
//          onClose={!closeMenu && handleDriTreeButtonClose}
//          anchorOrigin={{
//              vertical: "top",
//              horizontal: "left",
//          }}
//          transformOrigin={{
//              vertical: "top",
//              horizontal: "right",
//          }}
//          getContentAnchorEl={null}

//      >
//          {dritree.map((item) => (
//              <DropdownItem key={item.Email} title={item.name} mail={item.Email} subItems={item.mentees} image={item.image} setCloseMenu={handleMenuClose} handleDriTreeItemClick={handleDriTreeItemClick} />
//          ))}
//      </Menu>
//  );
// };

// function DropdownItem ({ title, mail, subItems, image, setCloseMenu, handleDriTreeItemClick }) 
// {
//  const [anchorEl, setAnchorEl] = useState(null);
//  const [open, setOpen] = useState(false);

//  const handleClick = (event) => {
//      if (event.target.closest('.arrow-icon')) {
//          setAnchorEl(event.currentTarget);
//          setOpen(true);
//      } else {
//          console.log(mail);
//          handleDriTreeItemClick({ name: title, Email: mail });
//          setCloseMenu(true);
//      }
//  };

//  const handleClose = () => {
//      setOpen(false);
//  };

//  const hasSubItems = Array.isArray(subItems) && subItems.length > 0;

//  return (
//      <>
//          <MenuItem className={`dropdown-item ${!hasSubItems ? 'no-subitems' : ''}`} onClick={handleClick}>
//              {hasSubItems && <ArrowLeftIcon className="arrow-icon" style={{ fontSize: 32 }} />}
//              {image && (
//                  <Avatar
//                      alt={title}
//                      src={image}
//                      style={{ marginRight: 8, width: 32, height: 32 }}
//                  />
//              )}
//              {title}
//          </MenuItem>
//          <Menu
//              anchorEl={anchorEl}
//              open={open}
//              onClose={handleClose}
//              anchorOrigin={{
//                  vertical: "top",
//                  horizontal: "left",
//              }}
//              transformOrigin={{
//                  vertical: "top",
//                  horizontal: "right",
//              }}
//              getContentAnchorEl={null}
//              keepMounted
//          >
//              {hasSubItems && subItems.map((subItem) => (
//                  <DropdownItem
//                      key={subItem.Email}
//                      title={subItem.name}
//                      mail={subItem.Email}
//                      subItems={subItem.mentees}
//                      image={subItem.image}
//                      setCloseMenu={setCloseMenu}
//                      handleDriTreeItemClick={handleDriTreeItemClick}
//                  />
//              ))}
//          </Menu>
//      </>
//  );
// };

function MyTasksTopBar({ toggle, setRows, rowsCurrent, setSearchText, drisList, drisTree, serversList, setHasData, setInitialRows, userEmail, userRole, sortBy, setSortBy, filterBy, setFilterBy, searchText, setDeveloperOpen, DeveloperOpen,handleToggleDeveloperButtonClick,setFilterInDevelopOrToggle }) {
    // const drisMenuList = drisList.map((item, index) => {
    //     return <MenuItem key={index} onClick={() => handleDriItemClick(item)}>{item}</MenuItem>
    // });

    const serversMenuList = serversList.map((item, index) => {
        return <MenuItem key={index} onClick={() => handleServerItemClick(item)}>{item}</MenuItem>
    });

    const [sortAnchor, setSortAnchor] = useState(null);
    const [filterAnchor, setFilterAnchor] = useState(null);
    const [driAnchor, setDriAnchor] = useState(null);
    const [serverAnchor, setServerAnchor] = useState(null);
    const [open, setOpen] = useState(false);
    
    //const [driTreeAnchor, setDriTreeAnchor] = useState(null);

    function handleSortButtonClick(event) {
        setSortAnchor(event.currentTarget);
    }

    function handleSortButtonClose() {
        setSortAnchor(null);
    }

    function handleSearchButtonClick() {
        // setSearchText("");
        setOpen(!open);
    }

    function handleFilterButtonClick(event) {
        setFilterAnchor(event.currentTarget);
        
    }

    function handleFilterButtonClose() {
        setFilterAnchor(null);
    }

    function handleDriButtonClose() {
        setDriAnchor(null);
        setFilterAnchor(null);
    }

    function handleEscKeyPress(event) {
        if (event.key === "Escape") {
            // setSearchText("");
            setOpen(false);
        }
    }

    // function handleDriTreeButtonClose() {
    //  setDriAnchor(null);
    //  //setDriTreeAnchor(null);
    //  setFilterAnchor(null);
    // }

    function handleServerButtonClose() {
        setServerAnchor(null);
        setFilterAnchor(null);
    }

    function handleSortItemClick(item) {
        setSortAnchor(null);
        setSortBy(item);
        // if(item === "Default")
        // {
        //     const rowsNew = group_by_order(rowsCurrent, [2, 1], 0);
        //     setRows(rowsNew);
        // }
        // else if(item === "Server")
        // {
        //     var rowsNew = [...rowsCurrent];
        //     rowsNew.sort(serverCompareFunction);
        //     setRows(rowsNew);
        // }
        // else if(item === "Type")
        // {
        //     var rowsNew = [...rowsCurrent];
        //     rowsNew.sort(typeCompareFunction);
        //     setRows(rowsNew);
        // }
        // else if(item === "Priority")
        // {
        //     var rowsNew = [...rowsCurrent];
        //     rowsNew.sort(priorityCompareFunction);
        //     setRows(rowsNew);
        // }
        // else if(item === "Status")
        // {
        //     var rowsNew = [...rowsCurrent];
        //     rowsNew.sort(statusCompareFunction);
        //     setRows(rowsNew);
        // }
        // else if(item === "TaskName")
        // {
        //     var rowsNew = [...rowsCurrent];
        //     rowsNew.sort(tasknameCompareFunction);
        //     setRows(rowsNew);
        // }
        // else if(item === "MilestoneName")
        // {
        //     var rowsNew = [...rowsCurrent];
        //     rowsNew.sort(milestoneNameCompareFunction);
        //     setRows(rowsNew);
        // }
        // else if(item === "TasklistName")
        // {
        //     var rowsNew = [...rowsCurrent];
        //     rowsNew.sort(tasklistNameCompareFunction);
        //     setRows(rowsNew);
        // }
        // else if(item === "DRI")
        // {
        //     var rowsNew = [...rowsCurrent];
        //     rowsNew.sort(driCompareFunction);
        //     setRows(rowsNew);
        // }
    }

    async function handleFilterItemClick(event, item) {
        if (item === "Server") {
            setServerAnchor(event.currentTarget);
        }
        else if (item === "Dri") {
            setDriAnchor(event.currentTarget);
        }

        else if (item === "Default") {
         
            setHasData(null);
            setFilterBy(["Default"]);
        }
    }


    // async function handleDriItemClick(item)
    // {
    //     try
    //     {
    //         setHasData(null);
    //         const result = await getFromTaskBreaker(`SELECT * FROM TaskBreaker WHERE DRI= '${item.Email}' AND ReportName='TaskBreaker_Data_Report'`);
    //         setInitialRows(result);
    //         setHasData(true);       
    //     }
    //     catch(error)
    //     {
    //         setHasData(false);
    //     }
    // }

    const handleDriTreeItemClick = async (item) => {
        // // console.log(item);
        // try {
        //     setHasData(null);
        //     const result = await getFromTaskBreaker(`SELECT * FROM TaskBreaker WHERE DRI= '${item.Email}' AND ReportName='TaskBreaker_Data_Report'`);
        //     setInitialRows(result);
        //     setHasData(true);
        //     //setCloseMenu(true);
        // }
        // catch (error) {
        //     setHasData(false);
        // };
        setHasData(null);
        var tempFilterBy = [...filterBy];
        var index = tempFilterBy.indexOf("Default");
        if (index > -1) {
            tempFilterBy.splice(index, 1);
        }
        index = tempFilterBy.indexOf(item.Email);
        if (index > -1) {
            tempFilterBy.splice(index, 1);
        }
        setFilterBy([item.Email, ...tempFilterBy]);
        // handleToggleDeveloperButtonClick();
        // console.log(item.Email)
    };
    // console.log(filterBy)
  

    async function handleServerItemClick(item) {
        // try
        // {
        //     setHasData(null);
        //     const result = await getFromTaskBreaker(`SELECT * FROM TaskBreaker WHERE Component = '${item}' AND ReportName='TaskBreaker_Data_Report'`);
        //     // console.log(result);
        //     setInitialRows(result);
        //     setHasData(true);       
        // }
        // catch(error)
        // {
        //     setHasData(false);
        // }
        setHasData(null);
        var tempFilterBy = [...filterBy];
        var index = tempFilterBy.indexOf("Default");
        if (index > -1) {
            tempFilterBy.splice(index, 1);
        }
        index = tempFilterBy.indexOf(item);
        if (index > -1) {
            tempFilterBy.splice(index, 1);
        }
        setFilterBy([item, ...tempFilterBy]);
    }

    function handleToggleClosedButtonClick() {
        toggle.current = !toggle.current;
        setHasData(null);
        setFilterInDevelopOrToggle(true)
        // setHasData(null);

        // var rowsNew = rowsCurrent.filter(row_ => {
        //     if(toggle.current)
        //     {
        //         if(row_[6] === "Closed" || row_[6] === "Cancelled")
        //         {
        //             return true;
        //         }
        //         return false;
        //     }
        //     else
        //     {
        //         if(row_[6] === "Closed" || row_[6] === "Cancelled")
        //         {
        //             return false;
        //         }
        //         return true;
        //     }
        // });

        // setRows(rowsNew);
    }

   

    function handleSearchTextChange(event) {
        setSearchText(event.target.value);
    }

    // console.log(serverAnchor);
    // console.log(driAnchor);



    function handleChipClick(event, item) {
        if (event.detail === 2) {
            setFilterBy([item]);
        }
    }

    return (
        <div className="mytasks-top-bar">
            <div className="mytasks-top-bar-left-group" style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "20px"
            }}>
                {/* <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Button variant="contained" size="small"><AddIcon /></Button>
                    <Button variant="contained" size="small"><EditNoteIcon /></Button>
                </ButtonGroup> */}

                <Box style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    width: "calc(50vw)",
                    overflowX: "scroll"
                }}
                    className="filter-sort-overflow-container"
                >
                    {
                        searchText !== "" ?
                            <Chip
                                label={
                                    <Box style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <Box style={{
                                            color: "#0c2c70",
                                            borderRight: "1px solid #103fa3",
                                            paddingRight: "6px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <SearchIcon />
                                        </Box>
                                        <Box style={{
                                            paddingLeft: "6px",
                                            color: "ghostwhite"
                                        }}>
                                            {searchText}
                                        </Box>
                                    </Box>
                                }
                                sx={{
                                    bgcolor: "#226DB4 !important",
                                    border: "1px solid #0c2c70"
                                }}
                                onDelete={() => {
                                    setSearchText("");
                                }}
                                deleteIcon={
                                    <CancelIcon
                                        onMSearchIconouseDown={(event) => event.stopPropagation()}
                                        sx={{
                                            color: "#0c2c70 !important"
                                        }}
                                    />
                                }
                            />
                            :
                            <></>
                    }
                    {
                        sortBy !== "Default" ?
                            <Chip
                                label={
                                    <Box style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <Box style={{
                                            color: "#14421c",
                                            borderRight: "1px solid #14421c",
                                            paddingRight: "6px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <SortIcon />
                                        </Box>
                                        <Box style={{
                                            paddingLeft: "6px",
                                            color: "ghostwhite",
                                            width: "70px",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            textAlign: "center"
                                        }}>
                                            <Tooltip title={sortBy}>
                                                {sortBy}
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                }
                                sx={{
                                    bgcolor: "rgb(8,153,73,0.9) !important",
                                    border: "1px solid #14421c"
                                }}
                                onDelete={() => {
                                    setSortBy("Default");
                                }}
                                deleteIcon={
                                    <CancelIcon
                                        onMouseDown={(event) => event.stopPropagation()}
                                        sx={{
                                            color: "#14421c !important"
                                        }}
                                    />
                                }
                            />
                            :
                            <></>
                    }
                    {
                        !filterBy.includes("Default") ?
                            filterBy.map((item, index) => {
                                return (
                                    <Chip
                                        key={index}
                                        label={
                                            <Box style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }} onClick={(event) => handleChipClick(event, item)}>
                                                <Box style={{
                                                    color: "#331511",
                                                    borderRight: "1px solid #53291c",
                                                    paddingRight: "6px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center"
                                                }}>
                                                    <FilterAltOutlinedIcon />
                                                </Box>
                                                <Box style={{
                                                    paddingLeft: "6px",
                                                    color: "ghostwhite",
                                                    width: "70px",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    textAlign: "center"
                                                }}>
                                                    <Tooltip title={item}>
                                                        {item}
                                                    </Tooltip>
                                                </Box>
                                            </Box>
                                        }
                                        sx={{
                                            // bgcolor: "rgb(228,37,39, 0.9) !important"
                                            background: "rgba(177,44,9,1) !important",
                                            border: "1px solid #53291c"
                                        }}
                                        onDelete={() => {
                                            setHasData(null);
                                            var tempFilterBy = [...filterBy];
                                            var index = tempFilterBy.indexOf(item);
                                            if (index > -1) {
                                                tempFilterBy.splice(index, 1);
                                            }
                                            if (tempFilterBy.length === 0) {
                                                tempFilterBy.push("Default");
                                            }
                                            setFilterBy(tempFilterBy);
                                        }}
                                        deleteIcon={
                                            <CancelIcon
                                                onMouseDown={(event) => event.stopPropagation()}
                                                sx={{
                                                    color: "#53291c !important"
                                                }}
                                            />
                                        }
                                    />
                                );
                            })
                            :
                            <></>
                    }
                    <div style={{
                    }} className="unselectable">
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                    </div>
                </Box>



            </div>
            <div className="mytasks-top-bar-right-group">
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Collapse in={open} timeout="auto" orientation="horizontal" unmountOnExit>
                        <TextField variant="outlined" placeholder="search" onChange={handleSearchTextChange} value={searchText} autoFocus onKeyDown={handleEscKeyPress} />
                    </Collapse>
                    <Tooltip title="search"><Button variant="contained" size="small" onClick={handleSearchButtonClick}><SearchIcon /></Button></Tooltip>
                    <Tooltip title={"toggle open/closed tasks"}>
                        <Button variant="contained" size="small" onClick={handleToggleClosedButtonClick}>
                            {!toggle.current ? <AllOutIcon /> : <BlockIcon />}
                            {/* <BlockIcon />s */}
                        </Button>
                    </Tooltip>
                    {/* <Tooltip title={"Developer/Mentor"}>
                        <Button variant="contained" size="small" onClick={handleToggleDeveloperButtonClick}>
                            {!DeveloperOpen ? <Person2OutlinedIcon /> : <PersonPinOutlinedIcon />}
                            
                        </Button>
                    </Tooltip> */}
                    <Tooltip title="filter by"><Button variant="contained" size="small" onClick={handleFilterButtonClick}><FilterAltOutlinedIcon /><ArrowDropDownIcon /></Button></Tooltip>
                    <Tooltip title="sort by"><Button variant="contained" size="small" onClick={handleSortButtonClick}><SortIcon /><ArrowDropDownIcon /></Button></Tooltip>
                </ButtonGroup>
                <Menu
                    anchorEl={sortAnchor}
                    open={sortAnchor !== null}
                    onClose={handleSortButtonClose}
                >
                    <MenuItem onClick={() => handleSortItemClick("Default")}>Default</MenuItem>
                    <MenuItem onClick={() => handleSortItemClick("Server")}>Server</MenuItem>
                    <MenuItem onClick={() => handleSortItemClick("Type")}>Type</MenuItem>
                    <MenuItem onClick={() => handleSortItemClick("TaskName")}>TaskName</MenuItem>
                    <MenuItem onClick={() => handleSortItemClick("Priority")}>Priority</MenuItem>
                    <MenuItem onClick={() => handleSortItemClick("MilestoneName")}>MilestoneName</MenuItem>
                    <MenuItem onClick={() => handleSortItemClick("TasklistName")}>TasklistName</MenuItem>
                    <MenuItem onClick={() => handleSortItemClick("Status")}>Status</MenuItem>
                    <MenuItem onClick={() => handleSortItemClick("DRI")}>DRI</MenuItem>
                </Menu>
                <Menu
                    anchorEl={filterAnchor}
                    open={filterAnchor !== null}
                    onClose={handleFilterButtonClose}
                >
                    <MenuItem onClick={(event) => handleFilterItemClick(event, "Default")} style={{ justifyContent: "center" }}>Default</MenuItem>
                    <MenuItem onClick={(event) => handleFilterItemClick(event, "Dri")}><ArrowLeftIcon />DRI</MenuItem>
                    <OtherDRI onClick={handleDriTreeItemClick}/>
                    <MenuItem onClick={(event) => handleFilterItemClick(event, "Server")}><ArrowLeftIcon />Server</MenuItem>
                </Menu>
                {drisTree && drisTree.length > 0 && (
                    <MultiLevelDropdown
                        dritree={drisTree}
                        driAnchor={driAnchor}
                        handleDriTreeButtonClose={handleDriButtonClose}
                        handleDriTreeItemClick={handleDriTreeItemClick}
                    />
                )}
                <Menu
                    anchorEl={serverAnchor}
                    open={serverAnchor !== null}
                    onClose={handleServerButtonClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    {serversMenuList}
                </Menu>
            </div>
        </div>
    );
}

function MyTasksTable({ toggle, rows, searchText, allRows, setRows, sortBy, setSortBy, filterBy, setFilterBy,user_email }) {
    // console.log("Table rendered");
    const classNameForColors = ["color-a", "color-b", "color-c", "color-d", "color-e", "color-f"];
    var ind = useRef(0);

    // const rowsNew = rows.filter(row_ => {
    //     if(toggle.current)
    //     {
    //         if(row_[6] === "Closed" || row_[6] === "Cancelled")
    //         {Row
    //             return true;
    //         }
    //         return false;
    //     }
    //     else
    //     {
    //         if(row_[6] === "Closed" || row_[6] === "Cancelled")
    //         {
    //             return false;
    //         }
    //         return true;
    //     }
    // });

    var newRows = rows.filter(row_ => {
        for (const item of row_) {
            if (item === null || item === undefined) {
                continue;
            }
            const aString = item.toLowerCase().replaceAll(" ", "");
            const bString = searchText.toLowerCase().replaceAll(" ", "");
            if (aString.indexOf(bString) !== -1) {
                return true;
            }
        }
        return false;
    });

    const theme = useTheme();

    if (newRows.length === 0) {
        return <h1 style={{ color: theme.palette.background.text }}>No Tasks Found :(</h1>;
    }

    const rowSpanInfo = find_row_span(newRows, [2]);

    return (
        <div className="mytasks-table">
            <TableContainer component={Paper} className="mytasks-table-container">
                <Table stickyHeader>
                    <TableHead>
                        <TableRow className="mytasks-table-head">
                            <TableCell align="center" className="mytasks-table-grouper-header" />
                            <TableCell align="center">S.No.</TableCell>
                            <TableCell align="left">Task Name</TableCell>
                            <TableCell align="left">Tasklist Name</TableCell>
                            <TableCell align="left">Milestone Name</TableCell>
                            <TableCell align="center">Priority</TableCell>
                            <TableCell align="center">DRI</TableCell>
                            <TableCell align="center">Start Date</TableCell>
                            <TableCell align="center">{toggle.current ? "Completion Date" : "Due Date"}</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center" className="mytasks-table-grouper-header" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {newRows.map((row, index) => (
                            <Row key={index} row={row} index={index} rowSpanInfo={rowSpanInfo[index]} classNameForColors={classNameForColors} ind={ind} allRows={allRows} setRows={setRows} user_email={user_email}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

function Row({ row, index, rowSpanInfo, classNameForColors, ind, setRows, allRows,user_email}) {

    var initial_value = row[6];
    // console.log(user_email)


    const [showAlert1, setShowAlert1] = useState(false);
    const [showAlert2, setShowAlert2] = useState(false);
    const [showAlert3, setShowAlert3] = useState(false);
    const [showAlert4, setShowAlert4] = useState(false);
    const [dependencyDialog, setDependencyDialog] = useState(false);
    const [hasDependency, setHasDependency] = useState(false);
    const [loading, setLoading] = useState(false);
    const [Total_Count, setTotal_Count] = useState([]);
    const textBox = useRef();

    // icons
    var priority = "None";
    if (row[3] === "High") {
        priority = <ArrowCircleUpTwoToneIcon style={{ color: "crimson" }} />;
    }
    else if (row[3] === "Medium") {
        priority = <RemoveCircleTwoToneIcon style={{ color: "orange" }} />;
    }
    else if (row[3] === "Low") {
        priority = <ArrowCircleDownTwoToneIcon style={{ color: "mediumseagreen" }} />;
    }
    else {
        priority = <Brightness1TwoToneIcon style={{ color: "grey" }} />;
    }

    var status = <HexagonIcon style={{ color: "grey", fontSize: "small" }} />;
    if (row[6] === "Open") {
        status = <HexagonIcon style={{ color: "green", fontSize: "small" }} />;
    }
    else if (row[6] === "In Progress") {
        status = <HexagonIcon style={{ color: "royalblue", fontSize: "small" }} />;
    }
    else if (row[6] === "In Review") {
        status = <HexagonIcon style={{ color: "bluegrey", fontSize: "small" }} />;
    }
    else if (row[6] === "To be Tested") {
        status = <HexagonIcon style={{ color: "orange", fontSize: "small" }} />;
    }
    else if (row[6] === "On Hold") {
        status = <HexagonIcon style={{ color: "goldenrod", fontSize: "small" }} />;
    }
    else if (row[6] === "Delayed") {
        status = <HexagonIcon style={{ color: "gold", fontSize: "small" }} />;
    }
    else if (row[6] === "Closed") {
        status = <HexagonIcon style={{ color: "tomato", fontSize: "small" }} />;
    }
    else if (row[6] === "Cancelled") {
        status = <HexagonIcon style={{ color: "navy", fontSize: "small" }} />;
    }
    else if (row[6] === "RMap") {
        status = <HexagonIcon style={{ color: "pink", fontSize: "small" }} />;
    }
    else if (row[6] === "Ongoing") {
        status = <HexagonIcon style={{ color: "salmon", fontSize: "small" }} />;
    }

    var { colorsCache } = useContext(TabStateContext);
    var colorClass = "color-a";
    if (row[2] in colorsCache) {
        colorClass = colorsCache[row[2]];
    }
    else {
        colorClass = classNameForColors[(ind.current) % 5];
        colorsCache[row[2]] = colorClass;
    }


    // side groupers
    var server = (
        <Tooltip title={row[2]} placement="right-start">
            <TableCell align="center" className={"mytasks-table-grouper-component mytasks-single-row " + row[2]}>
                <div style={{ whiteSpace: "nowrap" }}>{row[2].length > 8 ? row[2].slice(0, 8) + "..." : row[2]}</div>
            </TableCell>
        </Tooltip>
    );

    if (rowSpanInfo[2] === 0) {
        server = <></>;
    }
    else if (rowSpanInfo[2] !== 1) {
        server = (
            <TableCell align="center" className={"mytasks-table-grouper-component mytasks-multi-row " + row[2]} rowSpan={rowSpanInfo[2]} >
                <div style={{ whiteSpace: "nowrap" }}>{row[2]}</div>
            </TableCell>
        );
        ind.current = ind.current + 1;
    }
    else {
        ind.current = ind.current + 1;
    }

    var type = (
        <TableCell align="center" className={"mytasks-table-grouper-type mytasks-single-row " + row[1]}>
            <div style={{ whiteSpace: "nowrap" }}>{row[1]}</div>
        </TableCell>
    );

    if (rowSpanInfo[1] === 0) {
        type = <></>;
    }
    else if (rowSpanInfo[1] !== 1) {
        type = (
            <TableCell align="center" className={"mytasks-table-grouper-type mytasks-multi-row " + row[1]} rowSpan={rowSpanInfo[1]}>
                <div style={{ whiteSpace: "nowrap" }}>{row[1]}</div>
            </TableCell>
        );
    }

    const theme = useTheme();

    var color = "green";

    const today = new Date();
    const startDate = new Date(row[9]);
    const endDate = new Date(row[10]);
    const totDays = days_between(startDate, endDate);

    if (today < startDate) {
        color = "green";
    }
    else if (row[6] === "Closed" || row[6] === "Updated to IDC" || row[6] === "Cancelled") {
        if (theme.palette.mode === "dark") {
            color = "White";
        }
        else {
            color = "black";
        }
    }
    else if (days_between(today, startDate) < (0.25 * totDays).toFixed(0)) {
        color = "violet";
    }
    else if (days_between(today, startDate) < (0.5 * totDays).toFixed(0)) {
        color = "darksalmon";
    }
    else if (days_between(today, startDate) < (0.75 * totDays).toFixed(0)) {
        color = "darkgoldenrod";
    }
    else if (days_between(today, startDate) < (totDays).toFixed(0)) {
        color = "chocolate";
    }
    else {
        color = "crimson";
    }

    if (row[10] === "-" || row[9] === "-") {
        color = "";
    }

    function OngoingTaskbreaker() {
        useEffect(() => {
            const fetchValuesData = async () => {
                try {
                    const result = await getFromTaskBreaker(my_tasks_component_get_query_8(allRows, index));
                    result.map((row, index) => {
                        if (row[6] === 'Closed' || row[6] === 'Updated to IDC' || row[6] === 'Cancelled') {
                            function compareCompletionDate(a, b) {
                                const currentDate = new Date();
                                const completionDateA = new Date(a[14]);
                                const completionDateB = new Date(b[14]);
                            
                                const timeDifferenceA = Math.abs(completionDateA - currentDate);
                                const timeDifferenceB = Math.abs(completionDateB - currentDate);
                            
                                return timeDifferenceA - timeDifferenceB;
                            }
                            
                            // Sorting the array based on completion dates
                            result.sort(compareCompletionDate);
                        }
                    });
                    setTotal_Count(result)
                } catch (error) {
                    console.error(error);
                }
            };

            fetchValuesData();
        }, []);
    }
    OngoingTaskbreaker();

    function handleHasDependency(action) {
        if(action !== undefined) {
            const value = textBox.current.value;
            try {
                var tasks = "";
                for(var val of value.split(",")) {
                    tasks += val.trim() + ",";
                }
                const newObj = [{
                    "DependentTasks": tasks,
                    "ROWID": row[17]
                }];
                window.catalyst.table.tableId("TaskBreaker").updateRow(newObj).then(response => {
                }).catch(error => {
                });
                setDependencyDialog(false);
                handleMenuItemClick("On Hold", true);
            } catch(e) {
                alert("Kindly enter proper Task IDs, if more then one exists enter it comma separated");
                return;
            }
        } else {
            setHasDependency(true);
        }
    }

    const handleMenuItemClick = (selectedValue, proceed) => {

        if (proceed === undefined) {
            if (selectedValue === "On Hold") {
                setDependencyDialog(true);
                setHasDependency(false);
            } else {
                const newObj = [{
                    "DependentTasks": null,
                    "ROWID": row[17]
                }];
                window.catalyst.table.tableId("TaskBreaker").updateRow(newObj).then(response => {
                    handleMenuItemClick(selectedValue, true);
                }).catch(error => {
                });
            }
            return;
        }

        function count_ongoing(total, num) {
            if (num[6] == "Ongoing" || num[6] == "In Progress") {
                return total + 1;

            }

            return total;
        }
        // const ongoing_count = allRows.reduce(count_ongoing, 0);
        const ongoing_count = Total_Count.length;
        // if (ongoing_count < 2) {
            setShowAlert4(true);

            setLoading(true);
            //changing the status in creator form 
            const url = '/server/sample?table=StatusUpdate&rowid=' + row[11] + '&Status=' + selectedValue + '&StatusChangedBy=' + user_email;
            const headers = {
                'Content-Type': 'application/json',
            };
            fetch(url, {
                method: 'POST',
                headers: headers,

            })
                .then(response => {

                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error('Request failed with status: ' + response.status);
                    }
                })
                .then(data => {
                    const statusCode = data.StatusCode;
                    //console.log(statusCode);


                    setLoading(false);
                    setShowAlert4(false);
                    if (statusCode !== 3000) {
                        setShowAlert3(true);
                    }
                    else {
                        // setValue(selectedValue);
                        initial_value = selectedValue;

                        setShowAlert1(true);
                        setShowAlert2(false);
                        setShowAlert3(false);


                        const rowIndex = allRows.findIndex(row11 => row11.includes(row[11]));

                        //console.log(rowIndex);
                        //changing specfic index
                        var Newallrow = [...allRows];
                        Newallrow[rowIndex][6] = selectedValue;
                        //console.log("New Array :" + Newallrow);
                        Newallrow = group_by_order(Newallrow, [2, 1], 0);
                        setRows(Newallrow);
                    }

                })
                .catch(error => {
                    console.error(error);
                    setShowAlert4(false);
                    setShowAlert3(true);
                    setLoading(false);

                });
        // }
        // else {


        //     if (selectedValue != "Ongoing" && selectedValue != "In Progress") {


        //         setLoading(true);
        //         setShowAlert4(true);
        //         //changing the status in creator form 
        //         const url = '/server/sample?table=StatusUpdate&rowid=' + row[11] + '&Status=' + selectedValue + '&StatusChangedBy=' + user_email;
        //         const headers = {
        //             'Content-Type': 'application/json',
        //         };
        //         fetch(url, {
        //             method: 'POST',
        //             headers: headers,

        //         })
        //             .then(response => {

        //                 if (response.ok) {
        //                     return response.json();
        //                 } else {
        //                     throw new Error('Request failed with status: ' + response.status);
        //                 }
        //             })
        //             .then(data => {

        //                 const statusCode = data.StatusCode;
        //                 //console.log(statusCode);

        //                 setShowAlert4(false);
        //                 setLoading(false);
        //                 if (statusCode !== 3000) {
        //                     setShowAlert3(true);
        //                 }
        //                 else {
        //                     // setValue(selectedValue);
        //                     initial_value = selectedValue;

        //                     setShowAlert1(true);
        //                     setShowAlert2(false);
        //                     setShowAlert3(false);

        //                     const rowIndex = allRows.findIndex(row11 => row11.includes(row[11]));

        //                     //console.log(rowIndex);
        //                     //changing specfic index
        //                     var Newallrow = [...allRows];
        //                     Newallrow[rowIndex][6] = selectedValue;
        //                     //console.log("New Array :" + Newallrow);
        //                     Newallrow = group_by_order(Newallrow, [2, 1], 0);
        //                     setRows(Newallrow);

        //                 }

        //             })
        //             .catch(error => {
        //                 console.error(error);
        //                 // setShowAlert1(false);
        //                 // setShowAlert2(true);
        //                 setShowAlert4(false);
        //                 setShowAlert3(true);
        //                 setLoading(false);

        //             });
        //     }
        //     else {

        //         setLoading(false);


        //         setShowAlert1(false);
        //         setShowAlert2(true);

        //     }
        // }

    };

    useEffect(() => {
        if (showAlert1) {
            const timer = setTimeout(() => {
                setShowAlert1(false);
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [showAlert1]);

    useEffect(() => {
        if (showAlert2) {
            const timer = setTimeout(() => {
                setShowAlert2(false);
            }, 10000);

            return () => clearTimeout(timer);
        }
    }, [showAlert2]);

    useEffect(() => {
        if (showAlert3) {
            const timer = setTimeout(() => {
                setShowAlert3(false);
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [showAlert3]);


    const TextOnlyTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
    ))(`
          color: black;
          background-color: white;
      `);

    const formatDate = (inputDate) => {
        const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        const date = new Date(inputDate);
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };
    var formattedDate_new = "";
    if (row[14] === "-" || row[14] === "" || row[14] === null || row[14] === " ") {

    }
    else {
        const inputDate = row[14];
        formattedDate_new = formatDate(inputDate);
    }


    return (
        <TableRow
            hover={true}
            sx={{
                "& > *": { borderBottom: "unset" },
                backgroundColor: theme.palette.background.tableRow,
                "&:hover": {
                    backgroundColor:
                        theme.palette.background.tableRowHover + " !important",
                },
            }}
        >
            {server}
            <TableCell align="center" style={{ width: "2.5vw", maxWidth: "2.5vw" }}>
                {index + 1}
            </TableCell>

            {/* <TableCell align="left" style={{maxWidth: "20vw", wordWrap: "break-word"}}>
                <Link to={row[8]} target='_blank' style={{textDecoration: "none",
                                                          color: "inherit"}} 
                                                    onMouseEnter={(event) => {
                                                        event.target.style.color = theme.palette.primary.main;
                                                    }}
                                                    onMouseLeave={(event) => {
                                                        event.target.style.color = "inherit";
                                                    }}>
                    {row[0]}
                </Link>
            </TableCell> */}

            {["", " ", undefined, null].some((value) => row[12] === value) ? (
                <TableCell
                    align="left"
                    style={{ maxWidth: "20vw", wordWrap: "break-word" }}
                >
                    <Link
                        to={row[8]}
                        target="_blank"
                        style={{
                            textDecoration: "none",
                            color: "inherit",
                        }}
                        onMouseEnter={(event) => {
                            event.target.style.color = theme.palette.primary.main;
                        }}
                        onMouseLeave={(event) => {
                            event.target.style.color = "inherit";
                        }}
                    >
                        {row[0]}
                    </Link>
                    {/* {row[0]} */}
                </TableCell>
            ) : (
                <TextOnlyTooltip
                    title={
                        <span
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "0.875rem",
                                color: "Black",
                                backgroundImage: "",
                            }}
                        >
                            {row[12]}{" "}
                        </span>
                    }
                    followCursor
                >
                    <TableCell
                        align="left"
                        style={{ maxWidth: "20vw", wordWrap: "break-word" }}
                    >
                        <Link
                            to={row[8]}
                            target="_blank"
                            style={{
                                textDecoration: "none",
                                color: "inherit",
                            }}
                            onMouseEnter={(event) => {
                                event.target.style.color = theme.palette.primary.main;
                            }}
                            onMouseLeave={(event) => {
                                event.target.style.color = "inherit";
                            }}
                        >
                            {row[0]}
                        </Link>
                        {/* {row[0]} */}
                    </TableCell>
                </TextOnlyTooltip>
            )}

            <TableCell
                align="left"
                style={{ minWidth: "6vw", maxWidth: "10vw", wordWrap: "break-word" }}
            >
                {row[5]}
            </TableCell>
            <TableCell
                align="left"
                style={{ minWidth: "6vw", maxWidth: "10vw", wordWrap: "break-word" }}
            >
                {row[4]}
            </TableCell>
            <TableCell align="center">
                <Box className="flex-column">
                    {priority}
                    <p>{row[3]}</p>
                </Box>
            </TableCell>
            <TableCell align="center" style={{ minWidth: "10vW" }}>
                <GetNameAndEmpID emails={row[7]} />
            </TableCell>
            <TableCell align="center" style={{ minWidth: "6vw", hyphens: "none" }}>
                {row[9]}
            </TableCell>
            {/* <TableCell align="center" style={{minWidth: "6vw", hyphens: "none", color: color }}>{row[10]}</TableCell> */}

            <TableCell
                align="center"
                style={{
                    width: "5vw",
                    minWidth: "4vw",
                    hyphens: "none",
                    color: color,
                }}
            >
                {row[6] === "Completed" ||
                    row[6] === "Cancelled" ||
                    row[6] === "Updated to IDC" ||
                    row[6] === "Closed" ? (
                    <Box>
                        <Box
                            sx={{
                                fontSize: "0.88rem",
                            }}
                        >
                            {formattedDate_new === "" || formattedDate_new === null
                                ? "-"
                                : formattedDate_new}
                        </Box>
                        <Box
                            sx={{
                                fontSize: "0.6rem",
                                color: theme.palette.background.text,
                            }}
                        >
                            {[undefined, "", " ", null, "-"].includes(row[10]) ||
                                [undefined, "", " ", null, "-"].includes(row[14])
                                ? ""
                                : "DueDate :" + row[10]}
                        </Box>
                    </Box>
                ) : (
                    row[10]
                )}
            </TableCell>
            {/* <TableCell align="center"><Box className="flex-row">{status}<p>{row[6]}</p></Box></TableCell> */}

            <TableCell>
                <Select
                    value={initial_value}
                    disabled={loading}
                    sx={{
                        marginTop: 1,
                        width: 150,
                        height: 28,
                    }}
                >
                    <MenuItem
                        value={"Ongoing"}
                        onClick={() => handleMenuItemClick("Ongoing")}
                    >
                        Ongoing
                    </MenuItem>
                    <MenuItem
                        value={"Open"}
                        onClick={() => handleMenuItemClick("Open")}
                    >
                        Open
                    </MenuItem>
                    <MenuItem
                        value={"In Progress"}
                        onClick={() => handleMenuItemClick("In Progress")}
                    >
                        In Progress
                    </MenuItem>
                    <MenuItem
                        value={"In Review"}
                        onClick={() => handleMenuItemClick("In Review")}
                    >
                        In Review
                    </MenuItem>
                    <MenuItem
                        value={"To be Tested"}
                        onClick={() => handleMenuItemClick("To be Tested")}
                    >
                        To be Tested
                    </MenuItem>
                    <MenuItem
                        value={"Delayed"}
                        onClick={() => handleMenuItemClick("Delayed")}
                    >
                        Delayed
                    </MenuItem>
                    <MenuItem
                        value={"Cancelled"}
                        onClick={() => handleMenuItemClick("Cancelled")}
                    >
                        Cancelled
                    </MenuItem>
                    <MenuItem
                        value={"RMap"}
                        onClick={() => handleMenuItemClick("RMap")}
                    >
                        RMap
                    </MenuItem>
                    <MenuItem
                        value={"On Hold"}
                        onClick={() => handleMenuItemClick("On Hold")}
                    >
                        On Hold
                    </MenuItem>
                    <MenuItem
                        value={"Closed"}
                        onClick={() => handleMenuItemClick("Closed")}
                    >
                        Completed
                    </MenuItem>
                    <MenuItem
                        value={"Updated to IDC"}
                        onClick={() => handleMenuItemClick("Updated to IDC")}
                    >
                        Updated to IDC
                    </MenuItem>
                </Select>
                <Dialog
                    open={dependencyDialog}
                    onClose={() => setDependencyDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {hasDependency
                            ? "Dependencies"
                            : "Does this Task have any Dependent Tasks?"}
                    </DialogTitle>
                    <DialogContent>
                        {hasDependency ? (
                            <DialogContentText id="alert-dialog-description">
                                Enter the Dependent Tasks ID's (comma separated)
                            </DialogContentText>
                        ) : null}
                         {hasDependency ? (
                            <textarea
                                style={{
                                    marginTop: "20px",
                                    width: "92%",
                                    height: "100px",
                                    border: "1px solid black",
                                    backgroundColor: theme.palette.background.infoDivOuter,
                                    color: theme.palette.background.text
                                }}
                            ref={textBox}
                            >
                                {row[16]}
                            </textarea>
                        ) : null}
                    </DialogContent>
                    <DialogActions>
                        {!hasDependency ? (
                            <>
                                <Button onClick={() => {
                                    setHasDependency(false);
                                    setDependencyDialog(false);
                                }}>
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => {
                                        handleMenuItemClick("On Hold", true);
                                        setDependencyDialog(false);
                                        setHasDependency(false);
                                    }}
                                >
                                    No
                                </Button>
                                <Button onClick={() => handleHasDependency()} autoFocus>
                                    Yes
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button onClick={() => {
                                    setDependencyDialog(false);
                                    // setHasDependency(false);
                                }}>
                                    Cancel
                                </Button>
                                <Button onClick={() => {
                                    handleHasDependency("submit");
                                }}>
                                    Submit
                                </Button>
                            </>
                        )}
                    </DialogActions>
                </Dialog>
                <Snackbar
                    open={showAlert1}
                    onClose={() => setShowAlert1(false)}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <Alert severity="success">Status Updated 👍 </Alert>
                </Snackbar>

                <Snackbar
                    open={showAlert2}
                    onClose={() => setShowAlert2(false)}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <Alert severity="error">
                        Please update the status of the current ongoing tasks and attempt
                        to set the new task to the ongoing state.
                    </Alert>
                </Snackbar>

                <Snackbar
                    open={showAlert3}
                    onClose={() => setShowAlert3(false)}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <Alert severity="warning">try after some time</Alert>
                </Snackbar>

                <Snackbar
                    open={showAlert4}
                    onClose={() => setShowAlert4(false)}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <Alert severity="info">Updating..</Alert>
                </Snackbar>
            </TableCell>

            {type}
        </TableRow>
    );
}

export default MyTasks;


// ####################################################### util functions ###################################################################

function priorityCompareFunction(a, b) {
    const power = {
        "High": 3,
        "Medium": 2,
        "Low": 1,
        "None": 0
    };

    if (power[a[3]] < power[b[3]]) {
        return 1;
    }
    if (power[a[3]] > power[b[3]]) {
        return -1;
    }
    return 0;
}


function statusCompareFunction(a, b) {
    const power = {
        "Updated to IDC": 10,
        "Open": 9,
        "In Progress": 8,
        "Ongoing": 7,
        "To be Tested": 6,
        "In Review": 5,
        "On Hold": 4,
        "Delayed": 3,
        "Closed": 2,
        "Cancelled": 1,
        "Rmap": 0
    };

    if (power[a[6]] < power[b[6]]) {
        return 1;
    }
    if (power[a[6]] > power[b[6]]) {
        return -1;
    }
    return 0;
}

function serverCompareFunction(a, b) {
    if (a[2] < b[2]) {
        return -1;
    }
    if (a[2] > b[2]) {
        return 1;
    }
    return 0;
}

function typeCompareFunction(a, b) {
    if (a[1] < b[1]) {
        return -1;
    }
    if (a[1] > b[1]) {
        return 1;
    }
    return 0;
}

function tasknameCompareFunction(a, b) {
    if (a[0] < b[0]) {
        return -1;
    }
    else if (b[0] < a[0]) {
        return 1;
    }
    return 0;
};

function milestoneNameCompareFunction(a, b) {
    if (a[4] < b[4]) {
        return -1;
    }
    else if (b[4] < a[4]) {
        return 1;
    }
    return 0;
}

function tasklistNameCompareFunction(a, b) {
    if (a[5] < b[5]) {
        return -1;
    }
    else if (b[5] < a[5]) {
        return 1;
    }
    return 0;
}

function driCompareFunction(a, b) {
    if (a[7] < b[7]) {
        return -1;
    }
    else if (b[7] < a[7]) {
        return 1;
    }
    return 0;
}

async function setDriDropDown(setDriList, setDriTree, userDetails, teamDetails, dept, configuration) {
	const query = my_tasks_component_get_query_9(dept);
	var result2 = await getFromTeamDetails(query);
	var head_parent = "srinivasan@zohocorp.com";
    try {
        const rootUsers = configuration.current[`department.rootUsers`];
        if(dept in rootUsers) {
            head_parent = configuration.current[`department.rootUsers`][dept];
        }
    } catch(e) {}
	// const head_root = "mariaraj@zohocorp.com";
	const head_root = [];
	const employees = result2;
	var hierarchicalData = [];
	hierarchicalData = createHierarchy(employees, head_parent);

	return new Promise((resolve, reject) => {
		const userEmail = userDetails.current.auth.email_id;
		const userRole = teamDetails.current[userEmail].Role;
		const userReportingTo = teamDetails.current[userEmail].ReportingTo;
		var users = [];
		if (userRole === "CEO" && head_root.includes[userEmail]) {
			for (const user in teamDetails.current) {
				users.push(user);
			}

			users.sort();
			setDriList(users);
			setDriTree(hierarchicalData);
		}

		else if (userRole === "CEO" && !head_root.includes(userEmail)) {
			for (const user in teamDetails.current) {
				users.push(user);
			}
			users.sort();
			setDriList(users);
			setDriTree(hierarchicalData);
			users = findMentees([userEmail], teamDetails);
			users.sort();
			users = [userEmail, ...users];
			setDriList(users);
			hierarchicalData = createHierarchy(employees, userReportingTo);
			const getDriList = [];
			getDriList.push(getMentorWithMentees(hierarchicalData, userEmail));
			var hierarchicalData11 = [];
			hierarchicalData11 = createHierarchy(employees, head_parent);
			hierarchicalData11.push(getDriList[0]);
			setDriTree(hierarchicalData11);
		}

		else {
			users = findMentees([userEmail], teamDetails);
			users.sort();
			users = [userEmail, ...users];
			setDriList(users);
			hierarchicalData = createHierarchy(employees, userReportingTo);
			const getDriList = [];
			getDriList.push(getMentorWithMentees(hierarchicalData, userEmail));
			setDriTree(getDriList);
		}
		return resolve();
	});
}



async function setServerDropDown(setServersList, userDetails, teamDetails, allServers) {
	return new Promise((resolve, reject) => {
		const userEmail = userDetails.current.auth.email_id;
		const userRole = teamDetails.current[userEmail].Role;

		if (userRole === "CEO") {
			setServersList(allServers);
		}
		else {
			setServersList(teamDetails.current[userEmail].Servers.split(","));



		}
		return resolve();
	});
}

function findMentees(userEmails, teamDetails) {
    if (userEmails.length === 0) {
        return [];
    }

    const arr = [];

    for (const person in teamDetails.current) {
        if (userEmails.includes(teamDetails.current[person].ReportingTo)) {
            arr.push(person);
        }
    }

    const newArr = arr.concat(findMentees(arr, teamDetails));

    return newArr;
}

function createHierarchy(data, reportingTo) {
    const hierarchy = [];

    for (const employee of data) {
        //console.log(employee);
        if (employee.ReportingTo === reportingTo) {
            const subordinates = createHierarchy(data, employee.Email);
            if (subordinates.length) {
                employee.mentees = subordinates;
            }
            hierarchy.push(employee);
        }
    }
    hierarchy.sort((a, b) => {
        const empIDA = parseInt(a.EmployeeID, 10);
        const empIDB = parseInt(b.EmployeeID, 10);
        return empIDA - empIDB;
    });
    // hierarchy.sort((a, b) => {
    //  const empIDA = a.name.toLowerCase();
    //  const empIDB = b.name.toLowerCase();
    //  return empIDA.localeCompare(empIDB);
    //   });

    return hierarchy;
}
function getMentorWithMentees(hierarchy, mentorEmail) {
    for (const mentor of hierarchy) {
        if (mentor.Email === mentorEmail) {
            return mentor;
        } else if (mentor.mentees && mentor.mentees.length > 0) {
            const foundMentor = getMentorWithMentees(mentor.mentees, mentorEmail);
            if (foundMentor) {
                return foundMentor;
            }
        }
    }
    return null;
}
