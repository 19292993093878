import React from "react";
import { useTheme } from '@mui/material';
 
export default function TaskListStat({
    taskListData
}) {
    var heading = "Task List";
    var orientation = "VERTICAL";
 
    return (
        <div style={{
            backgroundColor: "#567a9922",
            width: "280px",
            borderRadius: "5px",
            padding: "10px",
            margin: "10px",
            scale: "0.8"
        }}>
            <div style={{
                textAlign: "center",
                fontSize: "21px"
            }}>
                {heading}
            </div>
            <div>
                {
                    taskListData.length  === 1 ?
                        <SingleStat taskListData={taskListData} />
                        :
                        taskListData.length === 2 && orientation === "HORIZONTAL" ?
                        <DoubleHorizontalStat taskListData={taskListData} />
                        :
                        taskListData.length === 2 && orientation === "VERTICAL" ?
                        <DoubleVerticalStat taskListData={taskListData} />
                        :
                        taskListData.length === 4 ?
                        <QuadrupleStat taskListData={taskListData} />
                        :
                    null
                }
            </div>
        </div>
    );
 
}

function SingleStat({taskListData}) {
    return (
        <div style={{
            padding: "10px",
            boxSizing: "border-box"
        }}>
            <div style={{
                fontSize: "57px",
                lineHeight: 1
            }}>
                {taskListData[0].value}
            </div>
            <div style={{
                fontWeight: "lighter"
            }}>
                {taskListData[0].label}
            </div>
        </div>
    );
}

function DoubleHorizontalStat({taskListData}) {

    const theme = useTheme();

    return (
        <div style={{
            padding: "10px",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "row",
            alignItems: "space-between",
            justifyContent: "space-between",
        }}>
            {
                taskListData.map((d, i) => {
                    return (
                        <>
                            <div style={{
                            }}>
                                <div style={{
                                    fontSize: "57px",
                                    lineHeight: 1
                                }}>
                                    {d.value}
                                </div>
                                <div style={{
                                    fontWeight: "lighter"
                                }}>
                                    {d.label}
                                </div>
                            </div>
                            {
                                i === 0 ?
                                    <div style={{borderRight: theme.palette.mode === "dark" ? "0.5px solid white": "0.5px solid black", height: "100px", width: "1px"}}></div>
                                    :
                                    null
                            }
                        </>
                    );
                })
            }
        </div>
    );
}

function DoubleVerticalStat({taskListData}) {

    const theme = useTheme();

    return (
        <div style={{
            padding: "10px",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: "space-between",
            justifyContent: "space-between",
            gap: "10px"
        }}>
            {
                taskListData.map((d, i) => {
                    return (
                        <>
                            <div style={{textAlign: "center", flex: 1}}>
                                <div style={{
                                    // fontSize: "57px",
                                    lineHeight: 1,
                                    width: "70%",
                                    fontSize: d.value < 999 ? "55px" : "49px"
                                }}>
                                    {d.value}
                                </div>
                                <div style={{
                                    fontWeight: "lighter"
                                }}>
                                    {d.label}
                                </div>
                            </div>
                            {
                                i === 0 ?
                                    <div style={{borderBottom: theme.palette.mode === "dark" ? "0.5px solid white": "0.5px solid black", width: "100%", height: "1px"}}></div>
                                    :
                                    null
                            }
                        </>
                    );
                })
            }
        </div>
    );
}

function QuadrupleStat({taskListData}) {

    const theme = useTheme();

    return (
        <div style={{
            padding: "10px",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "row",
            alignItems: "space-between",
            justifyContent: "space-between",
        }}>
            {
                taskListData.filter((d, i) => i < 2).map((d, i) => {
                    return (
                        <>
                            <DoubleVerticalStat taskListData={taskListData.filter((dd, j) => (j >= (2*i) && j<= ((2*i)+1)) )} />
                            {
                                i === 0 ?
                                    <div style={{borderRight: theme.palette.mode === "dark" ? "0.5px solid white": "0.5px solid black", height: "240px", width: "1px"}}></div>
                                    :
                                    null
                            }
                        </>
                    );
                })
            }
        </div>
    );
}