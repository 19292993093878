import React, { useEffect, useRef, useState,useContext } from "react";

import { 
    Dialog,
    Box,
    Divider,
    CircularProgress,
    Button
} from "@mui/material";
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { UserContext } from "../../../../../../contexts/UserContext";
import styles from "../TicketsTable.module.css";

export function CloseTicket({data, setExtraComps, setAnchor, setMoreIconVisible, setEventFeedbackQueue}) {

    const [loaded, setLoaded] = useState(false);
    const { loginUserEmail, teamDetails } = useContext(UserContext);

    const URL = `https://creatorapp.zoho.com/zohointranet/zvp-team/WMS_Support_Management/record-edit/WMS_Support_Management_Report/${data.ID}?close_Ticket_button=yes`;

    setAnchor(null);
    setMoreIconVisible(null);
    
    const loginUserRole = teamDetails.current[loginUserEmail.current].Role;
    function sendNoteMessage() {
        var message = "";
    if(loginUserRole === "Developer"){
        message = `Ticket will be closed, once approved.`;
    }
    else{
        message = `Ticket has been closed.`;
    }
        setEventFeedbackQueue(queue => {
                return [...queue, {
                    message: message,
                    auto_hide: false,
                    loading: false
                }]
            });
            setTimeout(() => {
                setEventFeedbackQueue(queue => {
                    return queue.filter(q => q.message !== message);
                });
            }, 3000);
    }

    function handleClose() {
        setExtraComps(null);
        sendNoteMessage();
    }

    function handleKeyDown(event) {

        if (event.keyCode === "Escape") {
            setExtraComps(null);
            sendNoteMessage();
        }
    }
    // maxWidth="md" // You can set this to 'sm', 'md', 'lg', 'xl' or false
    return (
        <Dialog onClose={handleClose} onKeyDown={handleKeyDown} open={true} fullWidth={true} maxWidth="md">
            <Box className={styles.flex_row_space_between}>
                <Box className={styles.close_ticket_note_container}>
                    <ErrorOutlineRoundedIcon fontSize="large"/>
                   {
                    loginUserRole === "Developer" ?
                        <Box className={styles.flex_column_left}>
      <span>Submiting this form will send an approval to your Reporting person,</span>
      <span>After Approval the ticket will be closed!</span>
      </Box>
    :
          
                          <Box className={styles.flex_column_left}>
                    <span>If you submit this form, it will be auto approved.</span>
                       <span>This ticket will be closed!</span>
 </Box>
                       
                   }     
                </Box>
                <Button sx={{borderRadius: "10px"}} onClick={handleClose}>
                    <CloseRoundedIcon fontSize="large" />
                </Button>
            </Box>
            <Divider />
            <Box className={styles.close_ticket_iframe_container} >
                {
                    loaded ?
                        null
                        :
                        <Box  sx={{width: "100%", zIndex: 1000, display: "flex", height: "660px !important", position: "absolute", alignItems: "center", justifyContent: "center"}}>
                            <CircularProgress />
                        </Box>
                }
                <iframe src={URL} key={data.ID} className={styles.close_ticket_iframe} height={"100%"} width={"100%"} onLoad={() => setLoaded(true)}/>
            </Box>
            <Divider />
        </Dialog>
    );
}