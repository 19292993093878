import { isDateInRange, isDateString, isRoadmap } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    const chartData = [];
    const dataMap = {
        "Ongoing - Delayed": [],
        "Ongoing - On Time": [],
        "Yet To Be Started": [],
        "Completed - On Time": [],
        "Completed - Delayed": []
    };

    const milestoneIdMap = {};
    const milestoneProgress = {
        "Ongoing - Delayed": 0,
        "Ongoing - On Time": 0,
        "Yet To Be Started": 0,
        "Completed - On Time": 0,
        "Completed - Delayed": 0
    };

    catalystDB.TaskDetails.map(item => {

        if([undefined, null, "", " "].includes(item.MilestoneId)) {
            return;
        }

        if(item.MilestoneId in milestoneIdMap) {
            return;
        } else {
            milestoneIdMap[item.MilestoneId] = true;
        }

        const dateString = item.CREATEDTIME;

        if(isDateString(dateString) && isDateInRange(dateString, startTime)) {
            
            const startDateString = item.MilestoneStartDate_;
            const dueDateString = item.MilestoneDueDate_;
            const completionDateString = item.MilestoneCompletionDate;
            
            if(isDateString(startDateString) && isDateString(dueDateString)) {
                if(isDateString(completionDateString)) {
                    if(new Date(completionDateString) <= new Date(dueDateString)) {
                        milestoneProgress["Completed - On Time"] += 1;
                        dataMap["Completed - On Time"].push(item);
                    } else {
                        milestoneProgress["Completed - Delayed"] += 1;
                        dataMap["Completed - Delayed"].push(item);
                    }
                } else {
                    if(new Date() <= new Date(dueDateString)) {
                        milestoneProgress["Ongoing - On Time"] += 1;
                        dataMap["Ongoing - On Time"].push(item);
                    } else {
                        milestoneProgress["Ongoing - Delayed"] += 1;
                        dataMap["Ongoing - Delayed"].push(item);
                    }
                }
            } else {
                milestoneProgress["Yet To Be Started"] += 1;
                dataMap["Yet To Be Started"].push(item);
            }
            
        }
    }); 

    for(const key in milestoneProgress) {
        const value = milestoneProgress[key];
        chartData.push([
            key, 
            value
        ]);
    }

    return [chartData, dataMap];
}