import { ROADMAP_DATA_FETCHER_1_DEFAULT } from "../../../catalystDB/queryGenerator";
import { queryDB } from "../../../util/QueryDB";

export function getFromTaskBreaker(queryString = ROADMAP_DATA_FETCHER_1_DEFAULT)
{
	return new Promise((resolve, reject) => 
	{
		const start = global.performance.now();

		queryDB(queryString).then((response) => {
			// console.log(response);
			const end = global.performance.now();
			// console.log(`took ${end-start} ms`);

			var data = [];
			for(const item of response)
			{
				const row = item.TaskBreaker;
				const result = [
					[undefined, "", " "].includes(row.TaskName) ? "-" : row.TaskName,
					[undefined, "", " "].includes(row.Component) ? "-" : row.Component,
					[undefined, "", " "].includes(row.TaskStatus) ? "-" : row.TaskStatus,
					!(["High", "Low", "Medium"].includes(row.TaskPriority)) ? "None" : row.TaskPriority,
					[undefined, "", " "].includes(row.Email) ? "-" : row.Email,
					row.TaskDescription,
					row.CreatorRowid,
					row.ROWID,
					row.TaskID,
					[undefined, "", " "].includes(row.CombinedRoadmap) ? "" : row.CombinedRoadmap,
					[undefined, "", " ",null].includes(row.NewRoadMapName) ? "-" : row.NewRoadMapName
					// [undefined, "", " ",null].includes(row.OnHoldReason) ? "-" : row.OnHoldReason
					
				];
				data.push(result);
			}
			// console.log("Roadmap Data :" + data);
			if(data.length === 0)
			{
				reject("none");
			}
			resolve(data);
		}).catch((error) => {
			reject(error);
		});

		// var zcql = window.catalyst.ZCatalystQL;
		// var zcqlPromise  = zcql.executeQuery(queryString);
		// zcqlPromise
		// 		.then((response) => {
		// 			// console.log(response);
		// 			const end = global.performance.now();
		// 			// console.log(`took ${end-start} ms`);

		// 			if(response.status === 400)
		// 			{
		// 				reject("error");
		// 			}
	
		// 			var data = [];
		// 			for(const item of response.content)
		// 			{
		// 				const row = item.TaskBreaker;
		// 				const result = [
						
		// 					[undefined, "", " "].includes(row.TaskName) ? "-" : row.TaskName,
		// 					[undefined, "", " "].includes(row.Component) ? "-" : row.Component,
		// 					[undefined, "", " "].includes(row.TaskStatus) ? "-" : row.TaskStatus,
					

		// 					!(["High", "Low", "Medium"].includes(row.TaskPriority)) ? "None" : row.TaskPriority,
		// 					[undefined, "", " "].includes(row.Email) ? "-" : row.Email,
		// 					row.TaskDescription
		// 				];
		// 				data.push(result);
		// 			}
		// 			if(data.length === 0)
		// 			{
		// 				reject("none");
		// 			}
		// 			resolve(data);
		// 		})
		// 		.catch((err) => {
		// 			reject(err);
		// 		});
	});
}

export function roadmapTransformFunction(response) {
	var data = [];
	for (const item of response) {
		const row = item.TaskBreaker;
		const result = [

			[undefined, "", " "].includes(row.TaskName) ? "-" : row.TaskName,
			[undefined, "", " "].includes(row.Component) ? "-" : row.Component,
			[undefined, "", " "].includes(row.TaskStatus) ? "-" : row.TaskStatus,


			!(["High", "Low", "Medium"].includes(row.TaskPriority)) ? "None" : row.TaskPriority,
			[undefined, "", " "].includes(row.Email) ? "-" : row.Email,
			row.TaskDescription,
			row.CreatorRowid,
			row.ROWID,
			row.TaskID,
			[undefined, "", " "].includes(row.CombinedRoadmap) ? "-" : row.CombinedRoadmap,
			[undefined, "", " ",null].includes(row.NewRoadMapName) ? "-" : row.NewRoadMapName,
			[undefined, "", " ",null].includes(row.RaoadMap_Chat_Url) ? "-" : row.RaoadMap_Chat_Url,
			[undefined, "", " ",null].includes(row.RoadMap_Chat_Description) ? "-" : row.RoadMap_Chat_Description,
			// row.NewRoadMapName
		];
		data.push(result);
	}
	if (data.length === 0) {
		return undefined;
	}
	return data;
}


export function roadmapTransformFunction1(response) {
	var data = [];
	for (const item of response) {
		const row = item.RoadmapExternal;
		const result = [

			[undefined, "", " "].includes(row.TaskName) ? "-" : row.Roadmap,
			[undefined, "", " "].includes(row.Component) ? "-" : row.NewRoadMapName,
			!(["High", "Low", "Medium"].includes(row.TaskPriority)) ? "None" : row.RoadmapPriority,
			[undefined, "", " "].includes(row.Email) ? "-" : row.RequestedBy,
			row.UserEmail
			
		];
		data.push(result);
	}
	if (data.length === 0) {
		return undefined;
	}
	return data;
}