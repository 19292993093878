/* eslint-disable react/prop-types */ 
import React from "react";
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import CircleIcon from '@mui/icons-material/Circle';

import HealingIcon from '@mui/icons-material/Healing';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box } from "@mui/material";
import { useState } from "react";
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';

import  Monitor  from './Monitor.js'
import  History  from './History.js'

import  Inference from './Inference.js'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import PreviewIcon from '@mui/icons-material/Preview';
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
import HistoryIcon from '@mui/icons-material/History';


const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});


export function IncidentsDialog({open, setOpen, dateTime, title, status,userdata,ClaimedBy,DeskID,ForwardTo,ResolvedOn}) {
    
    // console.log(userdata)
  //  console.log("Incident Dialog");

    const theme = useTheme();
    const [tab1, setTab1] = useState(1);

    function handleClose() {
        setOpen(!open);
    }

    const statusComps = {
        "Live":(
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "3px",
                color: "crimson",
                fontSize: "1rem"
            }}>
                <CircleIcon sx={{
                    fontSize: "0.9rem"
                }}/>
                <h3 style={{lineHeight: 1, margin: 4}}>
                    Live
                </h3>
            </Box>
        ),
        "Permanent Fix in Progress": (
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "3px",
                color: "chocolate",
                fontSize: "1rem"
            }}>
                <HealingIcon sx={{
                    fontSize: "1.1rem"
                }}/>
                <h3 style={{lineHeight: 1, margin: 4}}>
                    Permanent Fix
                    In Progress
                </h3>
            </Box>
        ),
        "RCA Provided": (
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "3px",
                color: "darkgoldenrod",
                fontSize: "1rem"
            }}>
                <FileCopyIcon sx={{
                    fontSize: "1.1rem" 
                }} />
                <h3 style={{lineHeight: 1, margin: 4}}>
                    RCA Provided
                </h3>
            </Box>
        ),
        "Closed": (
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "3px",
                color: "forestgreen",
                fontSize: "1rem"
            }}>
                <CheckCircleIcon sx={{
                    fontSize: "1.1rem"
                }}/>
                <h3 style={{lineHeight: 1, margin: 4}}>
                    Closed
                </h3>
            </Box>
        )
    }

    function PaperComponent(props: PaperProps) {
        return (
          <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
          >
            <Paper {...props} />
          </Draggable>
        );
      }
   
    return (
        <Dialog
        maxWidth="xl"
        // fullScreen
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={{
                style: {
                    backgroundColor: theme.palette.background.pageContent,
                    //   backdropFilter: "blur(800000px)",
                    boxShadow: "none",
                  
                }
            }}
        >
            <div style={{ display: "block" }}>
        
                <DialogTitle style={{ float: "left", display: "flex", alignItems: "center", gap: "20px" ,  cursor: 'move'}}>
                    <div>{title}</div>
                    <CircleIcon sx={{fontSize: "0.5rem"}} />



                    <div>{dateTime.toLocaleString()}</div>
                    <CircleIcon sx={{fontSize: "0.5rem"}} />
                    <div>
                        {statusComps[status]}
                    </div>
                </DialogTitle>
                <DialogActions style={{ float: "right", padding: "16px 24px 16px 24px" }}>
                    <Button onClick={handleClose} variant="text"><CloseIcon fontSize="medium" /></Button>
                </DialogActions>
     
            </div>
            <DialogContent style={{ paddingTop: 0 }}>
                <DialogContentText component={'span'}>
                <DialogContentContainer userdata={userdata} ClaimedBy={ClaimedBy} title="uuuu" status={status} DeskID={DeskID} ForwardTo={ForwardTo} ResolvedOn={ResolvedOn}/>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}


    function DialogContentContainer(props) {
        const { userdata, ClaimedBy, title, status,DeskID ,ForwardTo,ResolvedOn} = props;
      



    const [tab, setTab] = useState(0);

    function handleChange(event, newValue) {
 
        setTab(newValue);
    }
    let content;

    switch (tab) {
        case 0:
            content = < Monitor  userdata={userdata} />;
            break;
        case 1:
            content = <Inference DeskID={DeskID} />;
            break;
        case 2:
            content = <History  ClaimedBy={ClaimedBy} ForwardTo={ForwardTo} ResolvedOn={ResolvedOn} />
            break;
        default:
            content = null;
    }
    // console.log(content.props)

   // console.log(content);


    return (
        <div>
          <Tabs
            value={tab}
            onChange={handleChange}
            variant="fullWidth"
            sx={{
              minHeight: "0px",
              height: "40px",
              paddingBottom: "0px"
            }}
          >
           
              
 
              <Tab
                sx={{ minHeight: "auto", padding: 1, fontSize: 10 }}
                icon={<PreviewIcon />}
                iconPosition="start"
                label="Monitor"
              />

            {DeskID !=="-" &&(

             <Tab
              sx={{ minHeight: "auto", padding: 1, fontSize: 10 }}
              icon={<LightbulbCircleIcon />}
              iconPosition="start"
              label="Inference"
            />  
            )}

             {status === "Closed" &&  ClaimedBy !== "-" &&(
            <Tab
              sx={{ minHeight: "auto", padding: 1, fontSize: 10 }}
              icon={<HistoryIcon />}
              iconPosition="start"
              label="History"
            />
            )}
      
          </Tabs>
          {content}
        </div>
      );
      
}

export default IncidentsDialog;