//ignorei18n_start
import React, { useState, useRef, useLayoutEffect, useEffect, useContext } from 'react';
import Header from "./header/Header";
import TimelineComponent from "./timeline/Timeline";
import { getFromTicketsReport} from "../TickectDataFetcherForTimeLinePage/TickectDataFetcherForTimeLinePage"
import { ticketsReport_page_component_get_query_1,ticketsReport_page_component_get_query_2,ticketsReport_page_component_get_query_3,ticketsReport_page_component_get_query_4} from "../../../../catalystDB/queryGenerator";
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import EmptyData from '../../../../icons/EmptyData.png'
import { UserContext } from '../../../../contexts/UserContext';
// import InfiniteScroll from 'react-infinite-scroll-component'
 
export default function SupportTimelinePage({
    handleSearchTextChange,
    // data
}) {

    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [LazzyLoading, setLazzyLoading] = useState(false);
    const [offsetData, setOffsetData] = useState(1);
    const [incrementData, setIncrementData] = useState(100);
    const [userSerchedText, setUserSearchedText] = useState("");
    const [allDataWithoutAdding,setlAlDataWithoutAdding] = useState();
    const { serverConfiguration ,GlobalServer} = useContext(UserContext);
    const isInitialMount = useRef(true);
    // console.log(serverConfiguration)

    // var offsetData = 1;
    // var incrementData = 100;
    // var handleSearchTextChange = (v) => console.log(v);

    function CircularWithValueLabel({ loading }) {
        const [progress, setProgress] = React.useState(10);
      
        React.useEffect(() => {
          const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
          }, 800);
          return () => {
            clearInterval(timer);
          };
        }, []);
      
        return loading ? (
            <div style={{ position: "fixed", top: "50%", left: "60%", transform: "translate(-50%,-50%)" }}>
                 <CircularProgressWithLabel value={progress} />
            </div>
        ) : null;
      }

      function CircularProgressWithLabel(props) {
        return (
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="caption" component="div" color="text.secondary">
                {`${Math.round(props.value)}%`}
              </Typography>
            </Box>
          </Box>
        );
      }

      CircularProgressWithLabel.propTypes = {
        /**
         * The value of the progress indicator for the determinate variant.
         * Value between 0 and 100.
         * @default 0
         */
        value: PropTypes.number.isRequired,
      };

    useEffect(() => {
        var server_products = [];
        if (GlobalServer in serverConfiguration.current) {
            const products = JSON.parse(serverConfiguration.current[GlobalServer].IssueProductType);
            products.forEach(prod => {
                if (!server_products.includes(prod)) {
                    server_products.push(prod);
                }
            });
        }
        const fetchData = async () => {
            try {
                setLoading(true);
                var result;
                if (GlobalServer === "unknown" || GlobalServer === undefined) {
                    result = await getFromTicketsReport(ticketsReport_page_component_get_query_1(),offsetData);
                }
                else{
                    result = await getFromTicketsReport(ticketsReport_page_component_get_query_4(server_products.join(`','`)),offsetData);
                }
                // console.log(result)
                const parsedResult = result.map(o => {
                    try {
                        return JSON.parse(o, dateTimeReviver);
                    } catch (e) {
                        console.log(e);
                        return o;
                    }
                });
                setData(parsedResult);
                setlAlDataWithoutAdding(result)
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {

        var server_products = [];
        if (GlobalServer in serverConfiguration.current) {
            const products = JSON.parse(serverConfiguration.current[GlobalServer].IssueProductType);
            products.forEach(prod => {
                if (!server_products.includes(prod)) {
                    server_products.push(prod);
                }
            });
        }
        const fetchDataGLobalServer = async () => {
            try {
                setLoading(true);
                var result;
                if (GlobalServer === "unknown" || GlobalServer === undefined) {
                    result = await getFromTicketsReport(ticketsReport_page_component_get_query_1(),offsetData);
                }
                else{
                    result = await getFromTicketsReport(ticketsReport_page_component_get_query_4(server_products.join(`','`)),offsetData);
                }
                // console.log(result)
                const parsedResult = result.map(o => {
                    try {
                        return JSON.parse(o, dateTimeReviver);
                    } catch (e) {
                        console.log(e);
                        return o;
                    }
                });
                setData(parsedResult);
                setlAlDataWithoutAdding(result)
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        if (isInitialMount.current) {
            isInitialMount.current = false;
        }
        else{
            fetchDataGLobalServer();
        }
        
    }, [GlobalServer]);
    

    var handleSearchTextChange = (v) => {
        const fetchData = async () => {
            try {
                setLoading(true);
                if (userSerchedText === "") {
                    setOffsetData(1)
                }
                else if(userSerchedText != v){
                    setOffsetData(1)
                }
                setUserSearchedText(v);
                var result;
                if (v === "") {
                    result = await getFromTicketsReport(ticketsReport_page_component_get_query_1(),offsetData);
                }
                else{
                    result = await getFromTicketsReport(ticketsReport_page_component_get_query_2(v),offsetData);
                }
                    // const result = await getFromTicketsReport(ticketsReport_page_component_get_query_2(v),offsetData);
                    // console.log(result)
                const parsedResult = result.map(o => {
                    try {
                        return JSON.parse(o, dateTimeReviver);
                    } catch (e) {
                        console.log(e);
                        return o;
                    }
                });
                setData(parsedResult);
                setlAlDataWithoutAdding(result)
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    };

    var handleDateRange= (startDate, endDate) => {
        const fetchData = async () => {
            try {
                setOffsetData(1);
                setLoading(true);
                var result;
                if (startDate === "Reset" && endDate === "Reset") {
                    result = await getFromTicketsReport(ticketsReport_page_component_get_query_1(),offsetData);
                }
                else{
                    result = await getFromTicketsReport(ticketsReport_page_component_get_query_3(startDate,endDate),offsetData);
                }
                    // const result = await getFromTicketsReport(ticketsReport_page_component_get_query_2(v),offsetData);
                    // console.log(result)
                const parsedResult = result.map(o => {
                    try {
                        return JSON.parse(o, dateTimeReviver);
                    } catch (e) {
                        console.log(e);
                        return o;
                    }
                });
                setData(parsedResult);
                setlAlDataWithoutAdding(result)
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
 };


    const fetchData = async () => {
        var offsetDataCount = offsetData + 300;
        var server_products = [];
        if (GlobalServer in serverConfiguration.current) {
            const products = JSON.parse(serverConfiguration.current[GlobalServer].IssueProductType);
            products.forEach(prod => {
                if (!server_products.includes(prod)) {
                    server_products.push(prod);
                }
            });
        }
        try {
            var result;
            if (userSerchedText === "" && (GlobalServer === "unknown" || GlobalServer === undefined)) {
                result = await getFromTicketsReport(ticketsReport_page_component_get_query_1(),offsetDataCount);
            }
            else if ((userSerchedText === "" && (GlobalServer != "unknown" || GlobalServer != undefined))) {
                result = await getFromTicketsReport(ticketsReport_page_component_get_query_4(server_products.join(`','`)),offsetDataCount);
            }
            else{
                result = await getFromTicketsReport(ticketsReport_page_component_get_query_2(userSerchedText),offsetDataCount);
            }
            // const result = await getFromTicketsReport(ticketsReport_page_component_get_query_1(),offsetData);
            // console.log(result)
            const parsedResult = result.map(o => {
                try {
                    return JSON.parse(o, dateTimeReviver);
                } catch (e) {
                    console.log(e);
                    return o;
                }
            });
            setData([...data, ...parsedResult]);
            setlAlDataWithoutAdding(result)
            setOffsetData(offsetDataCount)
        } catch (error) {
            console.error(error);
        } finally {
            setLazzyLoading(false);
        }
    };

    const dateTimeReviver = function (key, value) {
        if (key.includes("date") && typeof value === 'string' && isDate(value)) {
            return new Date(value);
        }
        return value;
    }

    var isDate = function (date) {
        return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
    }

    return (
        // <div style={{
        //     display: "flex",
        //     flexDirection: "column",
        //     overflow: "hidden",
        //     height: "100%"
        // }}>
        
        <div  className="page-content-2">
            <Header
                handleSearch={handleSearchTextChange}
                handleDateRange={handleDateRange}
            />
             <CircularWithValueLabel loading={loading} />
             {!loading && data.length !==0 ? (
                    <TimelineComponent data={data} fetchData={fetchData} LazzyLoading={LazzyLoading} setLazzyLoading={setLazzyLoading} offsetData={offsetData} incrementData={incrementData} setOffsetData={setOffsetData} setIncrementData ={setIncrementData} allDataWithoutAdding={allDataWithoutAdding}/>
            ) : !loading && data.length === 0 ? (
                <div style={{
                    textAlign: "center",
                    marginTop: "40vh",
                    transform: "translateY(-50%)"
                  }}>
                    <img src ={EmptyData} alt="No Data Found Illustration" style={{ width: "200px" }} />
                    <p style={{ fontFamily: "Arial, sans-serif", fontSize: "20px", fontWeight: "bold", color: "#333" }}>
                      Oops! Looks like there's no data here.
                    </p>
                  </div>   
            ) : null}
            {/* {
                loading ? (
                    <h1 style={{ textAlign: "center", marginTop: "50vh", transform: "translateY(-50%)" }}>
                        Loading...
                    </h1>
                ) : data ? (
                    <TimelineComponent data={data} fetchData={fetchData} LazzyLoading={LazzyLoading} setLazzyLoading={setLazzyLoading} offsetData={offsetData} incrementData={incrementData} setOffsetData={setOffsetData} setIncrementData ={setIncrementData} allDataWithoutAdding={allDataWithoutAdding}/>
                ) : (
                    <h1 style={{ textAlign: "center", marginTop: "50vh", transform: "translateY(-50%)" }}>
                        No Data Found
                    </h1>
                )
            } */}
            {/* <TimelineComponent data={data} /> */}
        </div>
    );
}
//ignorei18n_end