/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */ 
import React from "react";
import { Box } from "@mui/material";
import { useContext } from "react";
import { ReportContext } from "../newReportGeneral/NewReport";
import { useTheme } from "@emotion/react";

import styled from "@emotion/styled";
import {
    Grid,
    Paper
} from "@mui/material";
import { EmployeeCard } from "../../../../../util/Loading";

export function TasksToolTipCard({tasksArray, tasksType, color}) {
    // console.log(tasksArray);
    return (
        <Box sx={{
            minHeight: "20vh",
            minWidth: "400px",
            MaxHeight: "50vh",
            width: "1065px",
            display: "flex",
            flexDirection: "column"
        }}>
            <TopCard tasksArray={tasksArray} tasksType={tasksType} color={color}/>
            <BottomCard tasksArray={tasksArray} tasksType={tasksType} color={color}/>
        </Box>
    );
}

function TopCard({tasksArray, tasksType, color}) {
    const {
        timeRange,
    } = useContext(ReportContext);
    const theme = useTheme();
    var taskPrefix = tasksArray.length === 1 ? "task" : "tasks";

    var name = "";
    if(tasksType === "old_completed") {
        name = (
            <>
                {taskPrefix} Completed Before 
                <span style={{
                    paddingLeft: "6px",
                    fontSize: "1.6rem",
                    color: theme.palette.zohoBlue.light
                }}>{formatDate(timeRange[0])}</span>
            </>
        ); 
    } else if(tasksType === "new_completed") {
        name = (
            <>
                {taskPrefix} Completed After 
                <span style={{
                    paddingLeft: "6px",
                    fontSize: "1.6rem",
                    color: theme.palette.zohoBlue.light
                }}>{formatDate(timeRange[0])}</span>
            </>
        );
    } else {
        name = taskPrefix + " yet to complete";
    }
    return (
        <Box sx={{
            width: "100%",
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.6rem",
            paddingTop: "10px",
            paddingBottom: "10px",
            color: color,
            fontWeight: "700",
            textShadow: theme.palette.mode === "light" ? "1px 1px 13px lightslategrey" : "1px 1px 13px darkslategrey",
            lineHeight: 1
        }}>
            <span style={{
                paddingRight: "13px",
                fontSize: "3.5rem"
            }}>
                {tasksArray.length}
            </span>
            {name}
        </Box>
    );
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgb(23,27,32)' : 'rgb(226,229,230)',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: "100px"
}));

function BottomCard({tasksArray, tasksType, color}) {
    return (
        <Box sx={{
            overflowY: "scroll",
            maxHeight: "50vh",
            paddingTop: "10px",
            paddingBottom: "10px"
        }} className="vertical-view-overflow-container">
            <Grid container spacing={2}>
                {tasksArray.map(item => {
                    return <TaskCard task={item} tasksType={tasksType} color={color} />
                })}
            </Grid>
        </Box>
    );
}

function TaskCard({task, tasksType, color}) {

    const theme = useTheme();
    var color = "darkgoldenrod";
    if(task.task_priority === "High") {
        color = "crimson";
    } else if(task.task_priority === "Low") {
        color = "dodgerblue";
    } else if(task.task_priority === "Medium") {
        color = "darkgoldenrod";
    } else {
        color = theme.palette.background.text;
    }

    return (
        <Grid item xs={12}>
            <Item>
                <Grid container spacing={2} sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    fontSize: "1rem",
                    fontWeight: "500",
                    color: theme.palette.background.text
                }}>
                    <Grid item xs={3}>
                        <Box sx={{
                            textAlign: "left",
                            paddingLeft: "30px"
                        }}>
                            {task.task_name}
                        </Box>
                    </Grid>
                    <Grid item xs={2.6}>
                        <Box>
                            <EmployeeCard emails={task.task_dri} />
                        </Box>
                    </Grid>
                    <Grid item xs={1.4}>
                        <Box>
                            <Box sx={{
                                fontSize: "0.5rem",
                                color: theme.palette.mode === "light" ? "grey" : "lightgrey"
                            }}>
                                Priority
                            </Box>
                            <Box sx={{
                                color : {color}
                            }}>
                                {task.task_priority}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={1.4}>
                        <Box>
                            <Box sx={{
                                fontSize: "0.5rem",
                                color: theme.palette.mode === "light" ? "grey" : "lightgrey"
                            }}>
                                Task Status
                            </Box>
                            <Box>
                                {task.task_status}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={1.2} sx={{
                        fontSize: "0.9rem"
                    }}>
                        <Box>
                            <Box sx={{
                                fontSize: "0.5rem",
                                color: theme.palette.mode === "light" ? "grey" : "lightgrey"
                            }}>
                                Start Date
                            </Box>
                            <Box>
                                {
                                    [null, undefined, "", " "].includes(task.start_date) && !isValidDateString(task.start_date) ?
                                        "-"
                                        :
                                        formatDate(task.start_date)
                                }
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={1.2} sx={{
                        fontSize: "0.9rem"
                    }}>
                        <Box>
                            <Box sx={{
                                fontSize: "0.5rem",
                                color: theme.palette.mode === "light" ? "grey" : "lightgrey"
                            }}>
                                Due Date
                            </Box>
                            <Box>
                                {
                                    [null, undefined, "", " "].includes(task.due_date) && !isValidDateString(task.due_date) ?
                                        "-"
                                        :
                                        formatDate(task.due_date)
                                }
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={1.2} sx={{
                        fontSize: "0.9rem"
                    }}>
                        <Box>
                            <Box sx={{
                                fontSize: "0.5rem",
                                color: theme.palette.mode === "light" ? "grey" : "lightgrey"
                            }}>
                                Completed Date
                            </Box>
                            <Box>
                                {
                                    [null, undefined, "", " "].includes(task.completed_date) && !isValidDateString(task.completed_date) ?
                                        "-"
                                        :
                                        formatDate(task.completed_date)
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Item>
        </Grid>
    );
}

function formatDate(date) {
    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    date = new Date(date);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const formattedDate = `${day}, ${months[monthIndex]} ${year}`;
    return formattedDate;
}

function isValidDateString(dateString) {
    const date = new Date(dateString);
    // Check if the parsed date is a valid date and not equal to "Invalid Date"
    return !isNaN(date) && date.toISOString() === dateString;
}