/* eslint-disable react/prop-types */

import React, { useContext, useEffect, useRef } from 'react';

import Slide from '@mui/material/Slide';

import { Box, Button, Grid } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { DashboardContext } from './stateStorage/DashboardStateStorage';

export function DashboardNavigator() {
    const { currentDashboard, changeCurrentDashboard, getDashboardIcon, getAllDashboardNames } = useContext(DashboardContext);
    const navigator = useRef();

    const icon = getDashboardIcon();

    const names = getAllDashboardNames();
    const icons = names.map((name) => {
        return getDashboardIcon(name);
    });

    useEffect(() => {
        navigator.current.style.top = "15px";
        // navigator.current.addEventListener("mouseover", () => {
        //     navigator.current.style.transform = "scale(1.1)";
        // });
        // navigator.current.addEventListener("mouseout", () => {
        //     navigator.current.style.transform = "scale(1)";
        // });
    }, []);

    function handleNavButtonClick(dashboardName) {
        changeCurrentDashboard(dashboardName);
    }

    return (
        <Slide direction="down" in={true} mountOnEnter unmountOnExit>
            <Box sx={{
                position: "absolute",
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                alignItems: "center",
                justifyContent: "center",
                left: 322,
                right: 300,
                marginLeft: "auto",
                marginRight: "auto",
                color: "white",
                fontWeight: "bold",
                cursor: "default",
                transition: "top .5s"
            }}
                ref={navigator}
            >
                <DashboardSwitcher 
                    changeCurrentDashboard={changeCurrentDashboard}
                    names={names}
                    currentDashboard={currentDashboard}
                    handleNavButtonClick={handleNavButtonClick}
                    icons={icons}
                />
                <RangeSelector />
            </Box>
        </Slide>
    );
}


function DashboardSwitcher({changeCurrentDashboard, names, currentDashboard, handleNavButtonClick, icons}) {

    useEffect(() => {
        dashboardSwitcher.current.addEventListener("mouseover", () => {
            dashboardSwitcher.current.style.transform = "scale(1.1)";
        });
        dashboardSwitcher.current.addEventListener("mouseout", () => {
            dashboardSwitcher.current.style.transform = "scale(1)";
        });
    }, []);

    const dashboardSwitcher = useRef();
    return (
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            transition: "all .2s"
        }}
        ref={dashboardSwitcher}
        >
            <Button sx={{
                padding: 0,
                borderRadius: 10,
                color: "white"
            }}
                onClick={() => changeCurrentDashboard("-")}
            >
                <ArrowLeftIcon fontSize="large" />
            </Button>
            {
                names.map((name, index) => {
                    return (
                        <Box sx={{
                            backgroundColor: "rgb(89, 125, 227, 0.2)",
                            padding: name === currentDashboard ? "8px" : "2px",
                            boxShadow: "1px 1px 10px 1px #181d29",
                            borderRadius: "20px",
                            width: name === currentDashboard ? "100px" : "80px",
                            display: "flex",
                            flexDirection: "row",
                            gap: "4px",
                            alignItems: "center",
                            justifyContent: "center",
                            opacity: name === currentDashboard ? 1 : 0.6,
                            fontSize: name === currentDashboard ? "1rem" : "0.8rem",
                            cursor: "pointer",
                            userSelect: "none"
                        }}
                            onClick={() => handleNavButtonClick(name)}
                            key={"dashboard-navigator-" + index}
                        >
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                {icons[index]}
                            </Box>
                            <Box>
                                {name}
                            </Box>
                        </Box>
                    );
                })
            }
            <Button sx={{
                padding: 0,
                borderRadius: 10,
                color: "white"
            }}
                onClick={() => changeCurrentDashboard("+")}
            >
                <ArrowRightIcon fontSize="large" />
            </Button>
        </Box>
    );
}

function RangeSelector() {

    const { startTime, setStartTime, catalystDB } = useContext(DashboardContext);
    const weekOnly = catalystDB === undefined || !catalystDB.all_data;

    useEffect(() => {
        if(!weekOnly) {
            setStartTime("year");
        }
    }, [weekOnly]);

    var positionValues = [
        [0, -100],
        [120, -100],
        [0, 20]
    ];

    if(startTime === "week") {
        positionValues = [
            [0, 20],
            [0, -100],
            [120, -100]
        ];
    } else if(startTime === "month") {
        positionValues = [
            [120, -100],
            [0, 20],
            [0, -100]
        ];
    }

    function handleClick(value) {
        setStartTime(value);
    }

    return (
        <Box sx={{
            display: "flex",
            backgroundColor: "orange",
            gap: "10px",
            color: "white",
            position: "relative"
        }}>
             {
                weekOnly ?
                    <RangeSelectorItem value={"Week"} position={positionValues[0]} clickHandler={() => handleClick("week")} single={true} />
                    :
                    <RangeSelectorItem value={"Week"} position={positionValues[0]} clickHandler={() => handleClick("week")} />

            }
            {
                weekOnly ?
                    null
                    :
                    <RangeSelectorItem value={"Month"} position={positionValues[1]} clickHandler={() => handleClick("month")} />

            }
            {
                weekOnly ?
                    null
                    :
                    <RangeSelectorItem value={"Year"} position={positionValues[2]} clickHandler={() => handleClick("year")} />

            }
        </Box>
    );
}

function RangeSelectorItem({value, position, clickHandler, single}) {
    const open = position[0] === 0 && position[1] === 20;
    return (
        <Box  sx={{
            position: "absolute",
            transform: single ? `translate(0%, -48%)` : `translate(${position[0]}%, ${position[1]}%)`,
            // top: position[0],
            // left: position[1],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: open ? "#2B60CC" : "rgb(46,52,71, 0.9)",
            opacity: open ? 1 : 0.6,
            boxShadow: "1px 1px 10px 1px " + (open ? "#2B60CC66" : "#181d29"),
            width: single ? "50px" : (open ? "35px" : "10px"),
            padding: "5px",
            borderRadius: "50px",
            lineHeight: 1,
            fontSize: single ? "1rem" : "0.7rem",
            transition: "all 0.6s ease",
            overflow: "hidden",
            cursor: "pointer",
            userSelect: "none"
        }} onClick={clickHandler}>
            {
                open ? value : value[0]
            }
        </Box>
    );
}