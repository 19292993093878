    import React, { useState, useContext } from "react";
    import { 
        Menu,
        MenuList,
        MenuItem,
        Box
    } from "@mui/material";
    import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
    import { UserContext } from "../../../../../../contexts/UserContext.js";
    import ClickAwayListener from '@mui/material/ClickAwayListener';
    import MoreVertIcon from '@mui/icons-material/MoreVert';
    import { Fade } from '@mui/material';
    import LinkIcon from '@mui/icons-material/Link';

    export const TICKET_STATUS = Object.freeze({
        TICKET_CREATED: "Ticket Created",
        OPEN: "Open",
        ON_HOLD: "On Hold",
        COMPLETED: "Completed",
        IMMEDIATE_FIX_IN_PROGRESS: "Immediate Fix In Progress",
        PERMANENT_FIX_IN_PROGRESS: "Permanent Fix In Progress",
        RESOLUTION_WAITING_FOR: "Resolution waiting for Approval",
        REJECTED: "Ticket Rejected"
    });

    export function MoreMenuApproval({moreVisibile, setMoreVisible, row})
    {
        //  console.log(row[23]);
        const [moreAnchor, setMoreAnchor] = useState(null);
    
        function handleEditClick() 
        {
    
            // console.log("clicked");
            const url = `https://cliq.zoho.com/company/64396901/chats/${row.Tickets.Chat_ID}`;
            window.open(url, '_blank').focus();
            setMoreAnchor(null)
    
      
        }
        return (
            <>
                    <Fade in={moreVisibile} timeout={100}>
                        <MoreVertIcon onClick={(event) => setMoreAnchor(event.currentTarget)} />
                    </Fade>
                    <Menu anchorEl={moreAnchor}
                        open={moreAnchor !== null}
                        onClose={() => { setMoreAnchor(null) }}
                    >
                        <MenuItem onClick={handleEditClick}>
                            <Box style={{ display: "flex" }}>
                                <LinkIcon fontSize="medium" />
                                &nbsp;
        
                                &nbsp;Go to Thread
        
                            </Box>
                        </MenuItem>
                    </Menu>
            </>
        );
    }
