/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext, useLayoutEffect } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useTheme } from '@mui/material';

import { GetNameAndEmpID } from '../../../../util/Loading.js';
import { group_by_order, find_row_span } from '../../../../util/GroupingFunctions.js';

import { Button, ButtonGroup } from '@mui/material';

import { Skeleton } from '@mui/material';

import {Menu, MenuItem} from '@mui/material';


import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';



import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';


import { UserContext } from '../../../../contexts/UserContext.js';

import Paper from '@mui/material/Paper';
import { getBuildDetails } from './BuildDataFetcher.js';

import './BuildUpdate.css';
import { builds_build_update_component_get_query_1 } from '../../../../catalystDB/queryGenerator.js';






function BuildUpdate() {
    //const TableComponent = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    // const { userDetails, teamDetails, loginUserDept } = useContext(UserContext);
    const { userDetails, teamDetails, loginUserDept, configuration,GlobalServer } = useContext(UserContext);
    const user_email = userDetails.current.auth.email_id;
    // const user_servers = teamDetails.current[user_email].Servers;
    var user_servers = (GlobalServer === "unknown" || GlobalServer === undefined) ?  "No Server" : GlobalServer;

    const [hasData, setHasData] = useState(null);
    const [initialRows, setInitialRows] = useState([]);
    const [driList, setDriList] = useState([]);
    const theme = useTheme();

    const userEmail = userDetails.current.auth.email_id;
    const userRole = teamDetails.current[userEmail].Role;
    useEffect(() => {

        const fetchAdditionalData = async () => {
            const pageSize = 300;
            let offset = 0;
            let allData = [];

            try {
                // let result = await getBuildDetails(`SELECT * FROM BuildBasicForm`);
                setLoading(true);
                let result = await getBuildDetails(builds_build_update_component_get_query_1(user_servers, loginUserDept));
                // let result = await getBuildDetails(`SELECT * FROM BuildBasicForm LIMIT ${pageSize} OFFSET ${offset}`);
                // allData = [...allData, ...result];

                // while (result.length === pageSize) {
                //     offset += pageSize;
                //     result = await getBuildDetails(`SELECT * FROM BuildBasicForm LIMIT ${pageSize} OFFSET ${offset}`);
                //     allData = [...allData, ...result];
                // }

                // console.log(allData);
                setLoading(false);
                if(Array.isArray(result) && result.length === 0) {
                    setHasData(false);
                } else {
                    setHasData(true);
                    setInitialRows(result);
                }

                //setInitialRows(allData);
            } catch (error) {
                setLoading(false);
                setHasData(false);
                console.log(error);
                // console.error('Error fetching additional data:', error);
            }
        };

        fetchAdditionalData();
        // console.log(initialRows)

        var users = [];
        for (const user in teamDetails.current) {
            users.push(user);
        }
        users.sort();
        setDriList(users);

        // const fetchData = async () => {
        //   try {
        //     //const response = await fetch('https://jsonplaceholder.typicode.com/posts');//
        //     const response = await fetch('https://sd.csez.zohocorpin.com/api/startupgrade?authtoken=8dff67883d160474cdcd4cdea66dd55adb206c842557cd309aabb5e412f28fa9&product=ZOHOWMS_DAVSERVER&bType=local&buildUrl=http://build/zoho/zohowms_davserver/webhost/jdk11/Jul_05_2023/AdvDAVServer.zip&comment=testbuild&build_by=ganeshkumar.rm');
        //     console.log(response);
        //     if (!response.ok) {
        //       throw new Error('Network response was not ok');
        //     }
        //     const jsonData = await response.json();
        //     setData(jsonData);
        //   } catch (error) {
        //     console.error('Error fetching data:', error);
        //   }
        // };

        // fetchData();
        //console.log(data);
    }, [GlobalServer]);



    var filteredData = initialRows;
    let rowSpanInfo = [];
    var rows = [];
    for (var item of filteredData) {
        rows.push(item);
    }


    if (rows.length > 0) {
        filteredData = group_by_order(rows, [6], 0);
        // console.log(filteredData);

        rowSpanInfo = find_row_span(filteredData, [6]);
        // console.log(rowSpanInfo);
    }

    const [openPopup, setOpenPopup] = useState(false);
    const [openPopup1, setOpenPopup1] = useState(false);
    const [openPopup2, setOpenPopup2] = useState(false);
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const [iframeLoaded1, setIframeLoaded1] = useState(false);
    const [iframeLoaded2, setIframeLoaded2] = useState(false);

    const handleMenuOpen = (event) => {
        setOpenPopup(true);
        // setAnchorEl(event.currentTarget);
    };
    const handleMenuOpen1 = (event) => {
        setOpenPopup1(true);
        // setAnchorEl(event.currentTarget);
    };
    const handleMenuOpen2 = (event) => {
        setOpenPopup2(true);
        // setAnchorEl(event.currentTarget);
    };

    // const handleMenuClose = () => {
    // 	setAnchorEl(null);
    // };

    // const handleAddItemClick = (event) => {
    // 	// Logic for handling the selected item in the dropdown
    // 	if (event.target.textContent === 'Create New TaskBreaker') {
    // 		setOpenPopup(true);
    // 	}
    // 	handleMenuClose(); // Close the dropdown menu
    // };	
    const [searchText, setSearchText] = useState("");

    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);


    const handleIframeLoad = () => {
        setIframeLoaded(true);
    };
    const handleIframeLoad1 = () => {
        setIframeLoaded1(true);
    };
    const handleIframeLoad2 = () => {
        setIframeLoaded2(true);
    };

    const [filterBy, setFilterBy] = useState(["Default"]);

    const handleClosePopup = () => {
        setOpenPopup(false);
        setOpen(false);
        setIframeLoaded(false);

    };

    const handleClosePopup1 = () => {
        setOpenPopup1(false);
        setOpen1(false);
        setIframeLoaded1(false);

    };

    const handleClosePopup2 = () => {
        setOpenPopup2(false);
        setOpen2(false);
        setIframeLoaded2(false);

    };

    function handleEscKeyPress(event) {
        if (event.key === "Escape") {
            setSearchText("");
            setOpen(false);
        }
    }


    const params = new URL(window.location.href).searchParams;
    const milestoneName = params.get("MilestoneName");
    const serverName = params.get("ServerName");
    const tasklistName = params.get("Parameter_Passing_Tasklist");

    // console.log(milestoneName);
    // console.log(serverName);
    // console.log(tasklistName);

    //	var iframeUrl = "https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/Report?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true";

    const [addAnchor, setAddAnchor] = useState(null);

    return (
        <div className="tickets-table page-content-2">
            <ButtonGroup variant="contained" sx={{
                marginBottom: "20px"
            }}>
                <Button variant="contained" size="small" onClick={(event) => {
                    if(addAnchor === null) {
                        setAddAnchor(event.currentTarget)
                    } else {
                        setAddAnchor(null);
                    }
                }}>
                    <AddIcon />
                    <Menu
                        anchorEl={addAnchor}
                        open={addAnchor !== null}
                        onClose={() => setAddAnchor(null)}
                    >
                        {/* <MenuItem onClick={() => {setAddAnchor(null); handleMenuOpen();}} >SD Build Update</MenuItem> */}
                        <MenuItem onClick={() => {setAddAnchor(null); handleMenuOpen1();}} >Build Upgrade</MenuItem>
                        <MenuItem onClick={() => {setAddAnchor(null); handleMenuOpen2();}} >Checklist</MenuItem>
                    </Menu>
                </Button>
{/* 
                <Tooltip title="search"><Button variant="contained" size="small" ><SearchIcon /></Button></Tooltip>
                <Tooltip title="filter by"><Button variant="contained" size="small" ><FilterListIcon /><ArrowDropDownIcon /></Button></Tooltip> */}
            </ButtonGroup>
            {loading ? (
                <div>
                    <Skeleton variant="rectangular" width={"100%"} height={35} />
                    <br></br>
                    <Skeleton variant="rectangular" width={"100%"} sx={{ height: "calc(100vh - 160px)" }} />
                </div>
            ) : 
                hasData ? 
            (
                <TableContainer component={Paper} className="tickets-table-container">
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow className="tickets-table-head">
                                {/* <TableCell align="center" className="tickets-table-grouper-header" /> */}
                                <TableCell align="left">S.No.</TableCell>
                                {/* <TableCell align="left">Ticket Number</TableCell>
                          <TableCell align="left">Ticket Id</TableCell> */}
                                <TableCell align="left">Server</TableCell>
                                <TableCell align="left">Build Label</TableCell>
                                <TableCell align="left">Build Type</TableCell>
                                <TableCell align="left">User</TableCell>
                                <TableCell align="left">Build Description</TableCell>

                                <TableCell align="left">Requested Time</TableCell>
                                {/* <TableCell align="left">Upgrade in SD</TableCell>
                                <TableCell align="left">Build Upgrade</TableCell> */}
                                {/* <TableCell align="center" className="tickets-table-grouper-header" /> */}
                            </TableRow>
                        </TableHead>
                        <Row filteredData={filteredData} rowSpanInfo={rowSpanInfo} catalystData={initialRows} user={userEmail} driList={driList} />

                    </Table>
                </TableContainer>
            )
            :
            (
                <box style={{
                    fontSize: "2rem",
                    color: theme.palette.background.text
                }}>
                    No Data Found.
                </box>
            )
            }
            <Dialog open={openPopup} onClose={handleClosePopup} fullScreen>
                <DialogActions>
                    <Button onClick={handleClosePopup} variant="text" fontSize="large"><CloseIcon /></Button>
                </DialogActions>
                <DialogContent>
                    {!iframeLoaded && (
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                height: '100%',
                                backgroundImage: 'linear-gradient(to right, #f0f0f0, #e0e0e0)',
                                borderRadius: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height="100%"
                                animation="wave"
                            />
                        </div>
                    )}
                    <iframe
                        src="https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/SD_Build_Update?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true"
                        width="100%"
                        height="100%"
                        title="External Content"
                        onLoad={handleIframeLoad}
                        frameBorder={0}
                        style={{ display: iframeLoaded ? 'block' : 'none' }}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={openPopup1} onClose={handleClosePopup1} fullScreen>
                <DialogActions>
                    <Button onClick={handleClosePopup1} variant="text" fontSize="large"><CloseIcon /></Button>
                </DialogActions>
                <DialogContent>
                    {!iframeLoaded1 && (
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                height: '100%',
                                backgroundImage: 'linear-gradient(to right, #f0f0f0, #e0e0e0)',
                                borderRadius: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height="100%"
                                animation="wave"
                            />
                        </div>
                    )}
                    <iframe
                        src="https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/Build_Upgrade_Form?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true"
                        width="100%"
                        height="100%"
                        title="External Content"
                        onLoad={handleIframeLoad1}
                        frameBorder={0}
                        style={{ display: iframeLoaded1 ? 'block' : 'none' }}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={openPopup2} onClose={handleClosePopup2} fullScreen>
                <DialogActions>
                    <Button onClick={handleClosePopup2} variant="text" fontSize="large"><CloseIcon /></Button>
                </DialogActions>
                <DialogContent>
                    {!iframeLoaded2 && (
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                height: '100%',
                                backgroundImage: 'linear-gradient(to right, #f0f0f0, #e0e0e0)',
                                borderRadius: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height="100%"
                                animation="wave"
                            />
                        </div>
                    )}
                    <iframe
                        src="https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/CheckLists_for_Build_Upgrade?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true"
                        width="100%"
                        height="100%"
                        title="External Content"
                        onLoad={handleIframeLoad2}
                        frameBorder={0}
                        style={{ display: iframeLoaded2 ? 'block' : 'none' }}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
}

function Row({ filteredData, rowSpanInfo, catalystData, user, driList }) {
    const [selectedRow, setSelectedRow] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const [subMenuOpen, setSubMenuOpen] = useState(false);
    const [showIframe, setShowIframe] = useState(false);
    const [showIncframe, setShowIncframe] = useState(false);
    const [forwardDialogOpen, setForwardDialogOpen] = useState(false);
    const [assignDialogOpen, setAssignDialogOpen] = useState(false);
    const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const [description, setDescription] = useState('');
    const [dropdownValue, setDropdownValue] = useState('');
    const [developerValue, setDeveloperValue] = useState('');
    const [componentValue, setComponentValue] = useState('');
    const [textValue, setTextValue] = useState('');
    const [closeUrl, setCloseUrl] = useState('');
    //console.log("driList-->", driList);

    var closeId = '';
    const [forwardNotes, setForwardNotes] = useState('');
    const [forwardBy, setForwardBy] = useState('');
    const [forwardedComponent, setForwardedComponent] = useState('');//setForwardBy
    const [localThreadID, setlocalThreadID] = useState('');
    const [ID, setID] = useState('');
    const [deskTicket, setdeskTicket] = useState('');
    const [rowTicketNumber, setRowTicketNumber] = useState('');
    const [rowTicketId, setRowTicketId] = useState('');
    const [botName, setBotName] = useState('');
    const [clickedRowIndex, setClickedRowIndex] = useState(null);
    const [clickedClaimIndex, setClickedClaimIndex] = useState(null);
    const [showClaimAlert1, setShowClaimAlert1] = useState(false);
    const [showClaimAlert2, setShowClaimAlert2] = useState(false);
    const [showClaimAlert3, setShowClaimAlert3] = useState(false);
    const [isClaiming, setIsClaiming] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [cellIndexForPopup, setCellIndexForPopup] = useState(null);
    const [cellValueForPopup, setCellValueForPopup] = useState(null);
    const [cellValuePart1, setCellValuePart1] = useState(null); // Add a state variable to store the first part of the cell value
    const [cellValuePart2, setCellValuePart2] = useState(null); // Add a state variable to store the second part of the cell value
// console.log(catalystData)
    const handleRowClick = (index) => {
        //console.log(index);
        if (selectedRow === index) {
            setSelectedRow(null);
        } else {
            setSelectedRow(index);
        }
    };

   

    return (
        <tbody>

            {catalystData.map((row, index) => (
                <React.Fragment key={index}>
                    <TableRow >
                        {/* {rowSpanInfo[index][6] === 0 ? (
                            <></>
                        ) : (
                            <TableCell className={`rotated-text ${row[6]}`} align="center" width={"5px"} rowSpan={2 * rowSpanInfo[index][6]}>
                                {row[0]}
                            </TableCell>
                        )} */}
                        {/* <TableCell align="center" width={"1px"} padding='0px'>
                        {row[0]}
                        </TableCell> */}
                        <TableCell width={"1px"} onClick={() => handleRowClick(index)}>{index + 1}</TableCell>
                        <TableCell style={{ width: "3px" }}> {row[0]} </TableCell>
                        <TableCell style={{ width: "3px" }}> {row[1]} </TableCell>
                        <TableCell style={{ width: "3px" }}> {row[2]} </TableCell>
                        <TableCell style={{ width: "3px" }}> {row[3]} </TableCell>
                        <TableCell style={{ width: "3px" }}> {row[4]} </TableCell>
                        <TableCell  align="center" style={{ width: "5.5vw", maxWidth: "4.5vw" }}> {row[5]} </TableCell>
                        {/* <TableCell style={{ width: "3px" }}> <Button variant="contained" color="primary" >START </Button></TableCell>
                        <TableCell style={{ width: "3px" }}> <Button variant="contained" color="primary" >START </Button></TableCell> */}

                    </TableRow>

                    <TableRow >

                    </TableRow>
                </React.Fragment>
            ))}
        </tbody>
    );
}

export default BuildUpdate;
