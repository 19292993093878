//ignorei18n_start
import { Button, Collapse, Divider } from "@mui/material";
import React, { useState } from "react";
import { formatDate } from "../Timeline";

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import History from "../history/History";
 
export default function Ticket({data}) {

    const keys = ["component", "created_time", "completed_time", "dri", "priority", "current_status", "is_converted_to_an_incident"];

    const [historyOpen, setHistoryOpen] = useState(false);
    // console.log(data.history)
 
    return (
        <>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // justifyContent: "flex-start",
                justifyContent: "space-around",
                paddingLeft: "10px",
                // gap: "30px"
            }}>
                {
                    keys.map((k, i) => {
                        return (
                            <React.Fragment key={i}>
                                <Stat k={k} v={
                                        typeof data[k] === "object" ? formatDate(data[k]) : data[k]
                                    } 
                                    color={"#A40606"}
                                    data={data}
                                />
                                {
                                    i !== keys.length - 1 ? <Divider orientation="vertical" flexItem style={{}}/> : null
                                }
                            </React.Fragment>
                        );
                    })
                }
            </div>
            {/* <Divider /> */}
            <div style={{
                display: "flex",
                flexDirection: "row",
            }}>
                 {data.history != "" && data.history != " " && data.history != undefined ?
                 (
                        <Button onClick={() => setHistoryOpen(s => !s)} style={{minWidth: 0, color: '#A40606'}}>    
                            {
                                historyOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                            }
                        </Button>
                    ):(
                        <></>
                    )}
            </div>
            {data.history != "" && data.history != " " && data.history != undefined ?
                 (
                <Collapse orientation="vertical" in={historyOpen} style={{
                    paddingLeft: "15px", 
                    // borderLeft: "3px solid #A40606", 
                    // boxShadow: "10px 0 15px -2px #c21f6033 inset", 
                    borderRadius: "10px",
                    margin: "10px 20px"
                }}>
                    <History data={data} />
                </Collapse>
                 ):(
                    <></>
                )}
            
        </>
    );
}

export function Stat({k, v, color, data}) {
    return (
        <div style={{
            width: "max-content",
            padding: "5px 10px",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            gap: "2px", 
        }}>
            <div style={{
                fontSize: "10px",
                fontWeight: "bold",
                opacity: 0.75,
                color: "white",
                padding: "0px 4px",
                borderRadius: "4px",
                // fontWeight: "bold",
                width: "fit-content",
                boxShadow: "0 5px 10px -6px " + color, 
                backgroundColor: color
            }}>
                {
                    k === "is_converted_to_an_incident" ? "Converted To Incident" :
                    k.replaceAll("_", " ")
                }
                {/* <Divider style={{borderColor: color}}/> */}
            </div>
            <div style={{
                fontSize: "12px",
                fontWeight: "bold",
                color: "rgb(71 85 105)"
            }}>
                {
                    k === "is_converted_to_an_incident" ? (v ? <a href={data.incident_url} target="_blank" style={{textDecoration: "underline"}}>Yes <OpenInNewIcon style={{height: "15px"}}/></a>  : "No") :
                    v ?? "-"
                }
            </div>
        </div>
    );
}

//ignorei18n_end