import { isDateInRange, isDateString } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    const chartData = [];
    const buildCountMap = {};

    catalystDB.AllBuildDetails.map(item => {
        const dateString = item.CREATEDTIME;

        if(isDateString(dateString) && isDateInRange(dateString, startTime)) {

            if([undefined, null, "", " "].includes(item.Server)) {
                return;
            }

            if(item.Server in buildCountMap) {
                buildCountMap[item.Server] += 1;
            } else {
                buildCountMap[item.Server] = 1;
            }

        }
    }); 

    for(const key in buildCountMap) {
        chartData.push([
            key,
            buildCountMap[key]
        ]);
    }

    return chartData;
}