import React, { useState, useEffect, useContext } from 'react';

import { getFromTaskBreaker } from './../tickets/TicketDataFetcher.js';

import { UserContext } from '../../../contexts/UserContext.js';

import './Tickets.css';
import { useTheme } from '@mui/material/styles';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import {
    Box,
    Skeleton,
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Select,
    MenuItem,
    TextField,
    Alert
} from "@mui/material";

import { GetNameAndEmpID } from '../../../util/Loading.js';
import Tooltip from '@mui/material/Tooltip';


function Approval() {

    const { userDetails, teamDetails } = useContext(UserContext);
    const [loading, setLoading] = useState(undefined);
    const [data, setData] = useState(undefined);
    const theme = useTheme();

    useEffect(() => {

        function OngoingTaskbreaker() {

            const fetchValuesData = async () => {
                try {
                    const result = await window.catalyst.ZCatalystQL.executeQuery(`SELECT * FROM Tickets WHERE IssueReportedBy LIKE '*${userDetails.current.auth.email_id}*' `);
                    // console.log(result);
                    if (result.length === 0) {
                        setLoading(false);
                        setData(undefined);
                    } else {
                        setData(result.content);
                        setLoading(true);
                    }
                } catch (error) {
                    setLoading(false);
                    setData(undefined);
                }
            };

            fetchValuesData();

        }

        OngoingTaskbreaker()

    }, []);
    const TextOnlyTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
    ))(`
          color: black;
          background-color: white;
      `);


    if (data === undefined) {
        return (
            <div className='page-content-2'>
                loading...
            </div>
        )
    }
    else {
        return (
            <div className='page-content-2'>
                <TableContainer component={Paper} className="mytasks-table-container">
                    <Table stickyHeader>

                        <TableHead>

                            <TableRow className="mytasks-table-head">
                                <TableCell align="center">S.No.</TableCell>
                                <TableCell align="left">Issue/Query</TableCell>
                                <TableCell align="left">Raised By</TableCell>
                                <TableCell align="left">Created Time</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Resolution Given</TableCell>
                            </TableRow>

                        </TableHead>

                        <TableBody>
                            
                            {data.map((row, index) => (
                                <TableRow key={index} className="mytasks-table-head">
                                    <TableCell align="center">{index+1}</TableCell>
                                    <TableCell align="left">{row.Tickets.Type}</TableCell>
                                    <TableCell align="left">{<GetNameAndEmpID emails={[row.Tickets.IssueReportedBy]} />}</TableCell>
                                    <TableCell align="left">{row.Tickets.CREATEDTIME}</TableCell>
                                    <TableCell align="center">{row.Tickets.Status}</TableCell>
                                    {/* <TableCell align="center">
                                        {
                                            row.Tickets.Type === "Issue" ?
                                                row.Tickets.Issue === "" ? "-" : "Issue Resolved"
                                                :
                                                row.Tickets.Query === "" ? "-" : "Query Resolved"
                                        }

                                    </TableCell> */}
                                                             <TableCell
                           
                            className={["", " ", undefined, null].some((value) => row.Tickets.Status === value) ? 'slide-animation' : ''}
                            align="center"
                            style={{ width: "10vw", maxWidth: "13vw", wordWrap: "break-word" }}
                        >
                            {["", " ", undefined, null].some((value) => row.Tickets.Status === value) ? (
                                <span
                                    style={{
                                        cursor: 'pointer',
                                        textDecoration: row.clicked ? 'underline' : 'none',
                                        fontWeight: row.clicked ? 'bold' : 'normal',
                                    }}
                                >
                                    {row.Tickets.Type === "Issue" ?
                                                row.Tickets.Issue === "" ? "-" : "Issue Resolved"
                                                :
                                                row.Tickets.Query === "" ? "-" : "Query Resolved"}
                                </span>
                            ) : (
                                <TextOnlyTooltip
                                    title={
                                        <span style={{ fontFamily: 'Poppins', fontSize: '0.875rem', color: "Black", backgroundImage: "" }}>
                                            {row.Tickets.Status}
                                        </span>
                                    }
                                    followCursor
                                >
                                    <span
                                        style={{
                                            cursor: 'pointer',
                                            textDecoration: row.clicked ? 'underline' : 'none',
                                            fontWeight: row.clicked ? 'bold' : 'normal',
                                        }}
                                    >
                                        {row.Tickets.Type === "Issue" ?
                                                row.Tickets.Issue === "" ? "-" : "Issue Resolved"
                                                :
                                                row.Tickets.Query === "" ? "-" : "Query Resolved"}
                                    </span>
                                </TextOnlyTooltip>
                            )}
                        </TableCell>
                                    {/* <TableCell
                           
                            className={["", " ", undefined, null].some((value) => row.Tickets.Type === value) ? 'slide-animation' : ''}
                            align="center"
                            style={{ width: "10vw", maxWidth: "13vw", wordWrap: "break-word" }}
                        >
                            {["", " ", undefined, null].some((value) => row.Tickets.Type === value) ? (
                                <span
                                    style={{
                                        cursor: 'pointer',
                                        textDecoration: row.clicked ? 'underline' : 'none',
                                        fontWeight: row.clicked ? 'bold' : 'normal',
                                    }}
                                >
                                    {row.Tickets.Status}
                                </span>
                            ) : (
                                <TextOnlyTooltip
                                    title={
                                        <span style={{ fontFamily: 'Poppins', fontSize: '0.875rem', color: "Black", backgroundImage: "" }}>
                                            {row[8]}
                                        </span>
                                    }
                                    followCursor
                                >
                                    <span
                                        style={{
                                            cursor: 'pointer',
                                            textDecoration: row.clicked ? 'underline' : 'none',
                                            fontWeight: row.clicked ? 'bold' : 'normal',
                                        }}
                                    >
                                        {row[3]}
                                    </span>
                                </TextOnlyTooltip>
                            )}
                        </TableCell> */}
                                </TableRow>
                            ))}

                        </TableBody>
                    </Table>

                </TableContainer>

            </div>
        );
    }

}

export default Approval;
