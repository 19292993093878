/* eslint-disable react/prop-types */
import React, {useState} from 'react';

import {
    Box
} from '@mui/material';
import BuildStatus from './BuildStatus';
import QAStatus from './QAStatus';

export default function InfoDiv({Server, BuildLabel, row}) {
    const [activeTab, setActiveTab] = useState("BuildStatus");

    if(activeTab === "BuildStatus") {
        return (
            <Box sx={{
                overflowX: "scroll"
            }}>
                <BuildStatus setActiveTab={setActiveTab} Server={Server} BuildLabel={BuildLabel} row={row}/>
            </Box>
        );
    } else {
        return (
            <QAStatus setActiveTab={setActiveTab} Server={Server} BuildLabel={BuildLabel} dc={activeTab}/>
        );
    }
}