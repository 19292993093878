/* eslint-disable react/prop-types */

import React, { useContext, useEffect, useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { 
    Box,
    Button 
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material';

import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { DashboardContext } from './stateStorage/DashboardStateStorage';

const Item = styled(Paper)(({ theme, lightMode }) => ({
    borderRadius: "5px",
    backgroundColor: lightMode ? "#ffffff99" : "#192030",
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
    // border: "1px solid " + (lightMode ? "lightgrey" : "-")
    // padding: "3px"
}));

const ItemOpen = styled(Paper)(({ theme, lightMode }) => ({
    borderRadius: "5px",
    backgroundColor: lightMode ? "#eeeeeebb" : "#192030bb",
    backdropFilter: "blur(50px)",
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
    // border: "1px solid " + (lightMode ? "lightgrey" : "-")
    // padding: "3px"
}));

const ItemBlured = styled(Paper)(({ theme, lightMode }) => ({
    borderRadius: "5px",
    background: lightMode ? "rgba(180, 180, 180, 0.4)" : "rgba(10, 10, 10, 0.4)",
    boxShadow: "0 4px 50px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(50px)",
    // backgroundColor: lightMode ? "ghostwhite" : "#192030",
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
}));

export function GridLayout({layout, dashboardName}) {

    const { getDashboardContext } = useContext(DashboardContext);
    const currentContext = getDashboardContext();

    return (
        <>
            <Grid container spacing={0} sx={{
                height: "100%"
            }}>
                {
                    layout.map((item, index) => {
                        return (
                            <GridItemGenerator key={dashboardName + "-" + index} uniqueKey={dashboardName + "-" + index} item={item} dashboardName={dashboardName} currentContext={currentContext}
                            />
                        );
                    })
                }
            </Grid>
        </>
    );
}

function GridItemGenerator({item, uniqueKey, dashboardName, currentContext}) {
    const gridContainer = useRef();
    const hiddenContainer = useRef();

    const transitionDelay = Math.random() / 2; // [0, 0.5)
    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";

    const {currentSelectedGridItem, changeCurrentSelectedGridItem} = useContext(currentContext); // current dashboardContext

    const expandIcon = useRef();
    const shrinkIcon = useRef();

    useEffect(() => {

        if(item.children !== undefined || gridContainer.current === null || hiddenContainer.current === null) {
            return;
        }

        setTimeout(() => {
            if(gridContainer.current !== null && gridContainer.current !== undefined) {
                gridContainer.current.style.opacity = 1;
            }
        }, 100);

        if(hiddenContainer.current !== undefined) {
            const positionDetails = gridContainer.current.getBoundingClientRect();

            hiddenContainer.current.style.top = positionDetails.top + "px";
            hiddenContainer.current.style.left = positionDetails.left + "px";
            hiddenContainer.current.style.height = positionDetails.height+"px";
            hiddenContainer.current.style.width = positionDetails.width+"px";
        }

    }, []);

    function handleGridItemClick(event) {

        if(item.children !== undefined) {
            return;
        }

        changeCurrentSelectedGridItem(item.name);
        
        hiddenContainer.current.style.zIndex = 2;
        hiddenContainer.current.style.display = "block";
        hiddenContainer.current.style.borderRadius = 0;

        hiddenContainer.current.focus();
        
        setTimeout(() => {
            if(hiddenContainer.current !== null && hiddenContainer.current !== undefined) {
                hiddenContainer.current.style.left = "0px";
                hiddenContainer.current.style.top = "0px";
                hiddenContainer.current.style.height = "100%";
                hiddenContainer.current.style.width = "100%";
                hiddenContainer.current.style.opacity = 1;
            }
        }, 250);
        
        setTimeout(() => {
            shrinkIcon.current.style.display = "block";
        }, 500);
        
    }

    function handleHiddenItemClick() {

        if(item.children !== undefined) {
            return;
        }

        changeCurrentSelectedGridItem(null);
        
        const positionDetails = gridContainer.current.getBoundingClientRect();
        
        hiddenContainer.current.style.top = positionDetails.top + "px";
        hiddenContainer.current.style.left = positionDetails.left + "px";
        hiddenContainer.current.style.height = positionDetails.height+"px";
        hiddenContainer.current.style.width = positionDetails.width+"px";
        hiddenContainer.current.style.opacity = 0.5;
        hiddenContainer.current.style.borderRadius = 1000;
        
        shrinkIcon.current.style.display = "none";
        
        setTimeout(() => {
            hiddenContainer.current.style.zIndex = -1;
            hiddenContainer.current.style.display = "none";
        }, 600);

    }

    function handleMouseEvent(type) {
        if(type === "enter") {
            expandIcon.current.style.display = "block";
        } else if (type === "leave") {
            expandIcon.current.style.display = "none";
        }
    }

    // function handleKeydown(event) {
    //     if (event.key === "Escape") {
    //         handleHiddenItemClick();
    //     } else {
    //         return;
    //     }
    // }

    return (
        <Grid item xs={item.x} sx={{
            padding: item.children === undefined ? "3px": 0,
            height: (item.y/12)*100 + "%",
            transitionDelay: transitionDelay + "s",
            boxSizing: "border-box"
        }}
        key={uniqueKey + "-" + item.name}
        >
            {
                item.children === undefined ?
                    <>
                        <ItemOpen lightMode={lightMode} sx={{
                            position: "absolute",
                            zIndex: -1,
                            display: "none",
                            transition: "all 0.4s ease-in-out, opacity 0.5s ease",
                            transitionDelay: "0.1s",
                            opacity: 0.5,
                            borderRadius: 1000
                        }}
                            ref={hiddenContainer}
                            key={uniqueKey+"-"+item.name+"-1"}
                            // onKeyDown={handleKeydown}
                            // tabIndex={0}
                        >
                            <Box sx={{
                                position: "absolute",
                                display: "none",
                                top: 0,
                                right: 0,
                                zIndex: 1,
                                borderRadius: "0px 0px 0px 50px",
                                // boxShadow: "10px 10px 40px 30px rgba(10, 10, 10, 0.3)",
                                // backgroundImage: "linear-gradient(45deg, rgba(10, 10, 10, 0.2) 0%, rgba(10, 10, 10, 0.3) 50%, rgba(10, 10, 10, 0.3) 100%)",
                                padding: "0px 0px 7px 7px"
                            }}
                            ref={shrinkIcon}
                            >
                                <Button sx={{
                                    borderRadius: "100px",
                                    padding: "8px 8px"
                                }}
                                    onClick={handleHiddenItemClick}
                                >
                                    <CloseFullscreenIcon fontSize='large' />
                                </Button>
                            </Box>
                            <GridItemAdapter comp={item.value} type={"expanded"} />
                            {/* {item.value_expanded} */}
                        {/* </ItemBlured> */}
                        </ItemOpen>
                        <Item lightMode={lightMode}
                            ref={gridContainer}
                            sx={{
                                opacity: 0,
                                transition: "opacity 0.4s ease",
                                transitionDelay: transitionDelay + "s",
                                position: "relative",
                                overflow: "hidden"
                            }}
                            onMouseEnter={() => handleMouseEvent("enter")}
                            onMouseLeave={() => handleMouseEvent("leave")}
                            key={uniqueKey+"-"+item.name+"-2"}
                        >
                            <Box sx={{
                                position: "absolute",
                                display: "none",
                                right: 0,
                                zIndex: 1
                            }}
                                ref={expandIcon}
                            >
                                {
                                    item.noExpansion === undefined ?
                                        <Button sx={{
                                            borderRadius: "100px"
                                        }}
                                            onClick={handleGridItemClick}
                                        >
                                            <OpenInFullIcon fontSize='small' />
                                        </Button>
                                        :
                                        null
                                }
                            </Box>
                            <GridItemAdapter comp={item.value} />
                            {/* {item.value} */}
                        </Item>
                    </>
                    :
                    <Grid container spacing={0} sx={{
                        height: "100%",
                        padding: 0
                    }}>
                        {
                            item.children.map((i, index) => {
                                return (
                                    <GridItemGenerator key={uniqueKey+ "-" + i.name + "-child-" + index} item={i} currentContext={currentContext}/>
                                );
                            })
                        }
                    </Grid>
            }
        </Grid>
    );
}

function GridItemAdapter({comp, type}) {
    const {getDashboardContext} = useContext(DashboardContext);
    const {currentSelectedGridItem} = useContext(getDashboardContext());

    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        if(currentSelectedGridItem !== null) {
            setTimeout(() => {
                setExpanded(true);
            }, 1000);
        }
        return () => {
            setExpanded(false);
        }
    }, [currentSelectedGridItem]);

    if(type === "expanded" && !expanded) {
        return null;
    }

    return comp;
}