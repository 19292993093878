import React, { useEffect, useState, useContext } from 'react';
import { DashboardContext } from '../../dashboardMain/stateStorage/DashboardStateStorage';
import { useTheme } from '@emotion/react';

import {
    Box
} from '@mui/material';

import { getChartData } from './gridItem_3_dataProcessor';

import { LineChartReact } from '../../../../../util/components/LineChart/LineChart';
import { ISSUES_LINE_CHART_COLORS_DARK, ISSUES_LINE_CHART_COLORS_LIGHT } from '../../dashboardMain/DashboardUtil';

export function GridItem_3() {

    const { getDashboardContext, catalystDB, startTime } = useContext(DashboardContext);
    const { currentSelectedGridItem } = useContext(getDashboardContext());

    const isOpen = currentSelectedGridItem === "GridItem_3";
    const theme = useTheme();

    var colors = ISSUES_LINE_CHART_COLORS_LIGHT;
    if (theme.palette.mode === "dark") {
        colors = ISSUES_LINE_CHART_COLORS_DARK;
    }

    if (catalystDB === undefined) {
        return null;
    } else if (catalystDB.Tickets === null) {
        return null;
    }

    const chartData = getChartData(catalystDB, startTime);

    return (
        <Box sx={{
            height: "100%",
            width: "100%",
            boxSizing: "border-box"
        }}>
            <LineChartReact data={chartData}
                title={"Tickets"}
                columnName1={"Date"}
                columnName2={"Tickets"}
                colors={colors}
                open={isOpen}
            />
        </Box>
    );

}