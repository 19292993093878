//ignorei18n_start
import { Button, Collapse, Divider, useTheme } from "@mui/material";
import React, { useState } from "react";
import { formatDate } from "../Timeline";
import { SplitSingle } from "../components/SplitSingle";

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Tasklists from "../tasklist/Tasklist";
 
export default function Milestone({data}) {

    const keys = ["component", "start_date", "due_date", "completed_date", "dri", "status"];

    const [tasklistsOpen, setTasklistsOpen] = useState(false);
 
    return (
        <>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // justifyContent: "flex-start",
                justifyContent: "space-around",
                paddingLeft: "10px",
                // gap: "30px"
            }}>
                {
                    keys.map((k, i) => {
                        return (
                            <React.Fragment key={i}>
                                <Stat k={k} v={
                                        typeof data[k] === "object" ? formatDate(data[k]) : data[k]
                                    } 
                                    color={"#3a43c7"}
                                />
                                {
                                    i !== keys.length - 1 ? <Divider orientation="vertical" flexItem style={{}}/> : null
                                }
                            </React.Fragment>
                        );
                    })
                }
            </div>
            {/* <Divider /> */}
            <div style={{
                display: "flex",
                flexDirection: "row"
            }}>
                <Button onClick={() => setTasklistsOpen(s => !s)} style={{minWidth: 0}}>    
                    {
                        tasklistsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                    }
                </Button>
                <SplitSingle data={data.progress} index={1} />
            </div>
            <Collapse orientation="vertical" in={tasklistsOpen} style={{
                paddingLeft: "15px", 
                borderLeft: "3px solid #c21f60", 
                boxShadow: "10px 0 15px -2px #c21f6033 inset", 
                borderRadius: "10px",
                margin: "10px 20px"
            }}>
                <Tasklists data={data.tasklists} />
            </Collapse>
        </>
    );
}

export function Stat({k, v, color}) {

    const theme = useTheme();

    return (
        <div style={{
            width: "max-content",
            padding: "5px 10px",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            gap: "2px", 
        }}>
            <div style={{
                fontSize: "10px",
                fontWeight: "bold",
                opacity: 0.75,
                color: "white",
                padding: "0px 4px",
                borderRadius: "4px",
                // fontWeight: "bold",
                width: "fit-content",
                boxShadow: "0 5px 10px -6px " + color, 
                backgroundColor: color
            }}>
                {k.replaceAll("_", " ")}
                {/* <Divider style={{borderColor: color}}/> */}
            </div>
            <div style={{
                fontSize: "12px",
                fontWeight: "bold",
                color:theme.palette.mode === "dark" ? "white" : "rgb(71 85 105)"
            }}>
                {v ?? "-"}
            </div>
        </div>
    );
}

//ignorei18n_end