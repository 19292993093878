/* eslint-disable react/prop-types */ 
/* esline-disable react/jsx-key */
import React from "react";
import { useEffect, useRef, useState, useContext } from "react";
import { UserContext } from "../../../../../contexts/UserContext";
import { useTheme } from '@mui/material/styles';
import { Box, ButtonGroup, Skeleton, Tab, Menu, MenuItem, Button } from "@mui/material";
import { ReportContext } from "../newReportGeneral/NewReport";
import { getMilestoneRows } from "../reportSection1/ReportSection1DataFetcher"; 
import { GanttChart } from "../newReportGeneral/NewReportUtilComponents";
import { getTasksForMilestoneID } from "../reportSection1/ReportSection1DataFetcher";
import { formatDate, isValidDate } from "../newReportGeneral/NewReportsUtil";
import { findMentees } from "../../../../../util/Loading";

import AirIcon from '@mui/icons-material/Air';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown.js';

export function ReportSection7() {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            width: "100%",
            height: "98%",
            gap: "10px"
        }}>
             <Box sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.1rem",
                fontWeight: "bold"
            }}>
                Tasks Timeline
            </Box>
            <ReportBody/>
        </Box>
       
    );
}   


function ReportBody({setExpanded}) {

    const {
        subjectType, 
        setSubjectType, 
        subject, 
        setSubject, 
        timeFrame, 
        setTimeFrame, 
        time, 
        setTime,
        timeRange,
        setTimeRange
    } = useContext(ReportContext);
    const theme = useTheme();
    
    const [loaded, setLoaded] = useState(undefined);
    const [milestoneRows, setMilestoneRows] = useState(undefined);
    const {teamDetails, loginUserDept} = useContext(UserContext);
    
    useEffect(() => {
        const controller = new AbortController();
        async function pullMilestoneRows() {
            
            getMilestoneRows(timeRange, subjectType, subject, teamDetails, loginUserDept).then((response) => {
                if(!controller.signal.aborted) {
                    if(response.length !== 0) {
                        setMilestoneRows(response);
                        setLoaded(true);
                    } else {
                        setLoaded(false);
                    }
                }
            }).catch((error) => {
                console.log(error);
                if(!controller.signal.aborted) {
                    setLoaded(false);
                }
            });
        }

        setLoaded(undefined);
        pullMilestoneRows();

        return () => {
            controller.abort();
        };

    }, [timeRange, subjectType,subject]);


    if(loaded === undefined) {
        return (
            <Skeleton variant={"rounded"} height={"98%"} width={"100%"} animation="wave" />
        );
    } else if(loaded === false) {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%"
            }}>
                <Box>
                    <AirIcon sx={{
                        fontSize: "6rem",
                        color: theme.palette.mode === "light" ? "rgb(20, 20, 20, 0.06)": "rgb(200, 200, 200, 0.06)"
                    }} />
                </Box>
                <Box sx={{
                    fontSize: "0.7rem",
                    color: theme.palette.mode === "light" ? "rgb(10, 10, 10, 0.3)": "rgb(200, 200, 200, 0.4)"
                }}>
                    Nothing found
                </Box>
            </Box>
        );
    } else {
        return (
            <Box  sx={{
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                overflowY: "scroll",
                boxSizing: "border-box",
                backgroundColor: theme.palette.mode === 'dark' ? 'rgb(24,30,35)' : '#FAFAFA',
                borderRadius: "12px",
                overflowX: "hidden",
                overflowY: "hidden"
            }}
            >
                <TaskGantt milestoneRows={milestoneRows}/>
            </Box>
        );
    }
}

function TaskGantt({milestoneRows}) {

    var initialSelection = undefined;
    try {
        if(milestoneRows.length !== 0) {
            initialSelection = milestoneRows[0].milestone_id;
        }
    } catch(e) {}
    const [selectedMilestone, setSelectedMilestone] = useState(initialSelection);
    const [loaded, setLoaded] = useState(undefined);
    const [chartData, setChartData] = useState(undefined);
    const theme = useTheme();
    const {teamDetails, loginUserDept} = useContext(UserContext);

    useEffect(() => {
        const controller = new AbortController();

        setLoaded(undefined);
        getTasksForMilestoneID(selectedMilestone, teamDetails, loginUserDept).then((response) => {
            if(!controller.signal.aborted) {
                if(response.length !== 0) {
                    var arr = [];

                    response.forEach((item) => {
                        try {
                            var startTime = new Date(item.TaskStartDate_);
                            var dueTime = new Date(item.TaskDueDate_);
                            var completedTime = new Date(item.TaskCompletionDate);
                            var perc = ((completedTime.getTime()-startTime.getTime())/(dueTime.getTime()-startTime.getTime()))*100;

                            if(perc < 0 || isNaN(perc)) {
                                perc = 0;
                            }

                            if(!isValidDate(startTime) || startTime.getTime() <= 0) {
                                return;
                            }

                            arr.push([
                                item.TaskName,
                                [
                                    startTime.getTime(),
                                    dueTime.getTime()
                                ],
                                perc,
                                item.TaskCompletionDate === null ? "-" : formatDate(completedTime),
                                item.DRI + (perc > 100 ? " - (overdue)" : "")
                            ]);

                        } catch(e) {}
                    });

                    if(arr.length === 0) {
                        setChartData(undefined);
                        setLoaded(false);
                    } else {
                        setChartData(arr);
                        setLoaded(true);
                    }
                } else {
                    setChartData(undefined);
                    setLoaded(false);
                }
            }
        }).catch((error) => {
            if(!controller.signal.aborted) {
                setLoaded(false);
            }
        });

    }, [selectedMilestone]);

    var content = (
        <Skeleton variant={"rounded"} height={"91%"} width={"99%"} animation="wave" sx={{marginTop: "20px"}} />
    );

    if(loaded === false) {
        content = (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "97%",
                width: "100%"
            }}>
                <Box>
                    <AirIcon sx={{
                        fontSize: "6rem",
                        color: theme.palette.mode === "light" ? "rgb(20, 20, 20, 0.06)": "rgb(200, 200, 200, 0.06)"
                    }} />
                </Box>
                <Box sx={{
                    fontSize: "0.7rem",
                    color: theme.palette.mode === "light" ? "rgb(10, 10, 10, 0.3)": "rgb(200, 200, 200, 0.4)"
                }}>
                    Nothing found
                </Box>
            </Box>
        );
    } else if(loaded !== undefined) {
        content = (
            <Box sx={{
                height: "100%",
                width: "100%"
            }}>
                <GanttChart title={""} xaxisLabel={""} yaxisLabel={""} columnName1={"Scheduled Date"}
                    columnName2={"Completed Date"} columnName3={"Milestone Owner"} data={chartData} />
            </Box>
        );
    }
    
    return (
        <Box sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        }}>
            <Box sx={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "right",
                margin: "10px",
                marginRight: "30px",
                fontWeight: 900,
                fontSize: "1rem",
                gap: "10px"
            }}>
                <span>choose Milestone</span>
                <TaskSelector milestoneRows={milestoneRows} selectedMilestone={selectedMilestone} setSelectedMilestone={setSelectedMilestone}/>
            </Box>
            {content}
        </Box>
    );
}

function TaskSelector({milestoneRows, selectedMilestone, setSelectedMilestone}) {

    const [anchor, setAnchor] = useState(null);

    return (
        <>
           <Button variant="contained" size="small" onClick={(event) => setAnchor(event.currentTarget)} style={{
                textTransform: "none",
                padding: "0px",
                paddingRight: "5px",
                borderRadius: "200px",
                fontWeight: "900",
                borderWidth: "2px"
            }} disableRipple="true">
                <>
                    <Box sx={{
                        padding: "6.5px",
                        paddingLeft: "15px"
                    }}>
                        {
                            milestoneRows.map(item => {
                                if(item.milestone_id === selectedMilestone) {
                                    return item.milestone_name;
                                }
                            })
                        }
                    </Box>
                    <ArrowDropDownIcon />
                </>
            </Button>
            <Menu
                anchorEl={anchor}
                open={anchor !== null}
                onClose={() => setAnchor(null)}
                sx={{
                    maxHeight: "750px"
                }}
            >
                {
                    milestoneRows.map((item, index) => {
                        return (
                            <MenuItem key={index} onClick={() => {setSelectedMilestone(item.milestone_id);setAnchor(null);}}>
                                {item.milestone_name}
                            </MenuItem>
                        );
                    })
                }
            </Menu>
        </>
    );
}