import React, { useEffect, useState, useContext } from 'react';
import { DashboardContext } from '../../dashboardMain/stateStorage/DashboardStateStorage';
import { useTheme } from '@emotion/react';

import {
    Box
} from '@mui/material';

import { getChartData } from './gridItem_8_dataProcessor';

import { PACKED_BUBBLE_CHART_COLORS_LIGHT, PACKED_BUBBLE_CHART_COLORS_DARK } from '../../dashboardMain/DashboardUtil';
import { PackedBubble } from '../../../../../util/components/PackedBubble/PackedBubble';

export function GridItem_8() {

    const { getDashboardContext, catalystDB, startTime } = useContext(DashboardContext);
    const { currentSelectedGridItem } = useContext(getDashboardContext());

    const isOpen = currentSelectedGridItem === "GridItem_8";
    const theme = useTheme();

    var colors = PACKED_BUBBLE_CHART_COLORS_LIGHT;
    if (theme.palette.mode === "dark") {
        colors = PACKED_BUBBLE_CHART_COLORS_DARK;
    }

    if (catalystDB === undefined) {
        return null;
    } else if (catalystDB.Tickets === null) {
        return null;
    }

    const chartData = getChartData(catalystDB, startTime);

    return (
        <Box sx={{
            height: "100%",
            width: "100%",
            boxSizing: "border-box"
        }}>
            <PackedBubble data={chartData}
                title={"Team Member's Contribution"}
                colors={colors}
                open={isOpen}
                columnName1={"User"}
                columnName2={"Handled"}
                columnName3={"Product"}
            />
        </Box>
    );

}