import React, {useState, useEffect, useContext, useRef} from "react";
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import CircleIcon from '@mui/icons-material/Circle';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import { Tooltip } from '@mui/material';
import { Button } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { UserContext } from "../../../../contexts/UserContext";
import CircularProgress from '@mui/material/CircularProgress';

import { getTimelineData } from "./Report2DataFetcher";
import { days_between } from "../../../../util/Loading";

export function ExecutionSection({startTime, endTime, view, selection}) {
	const theme = useTheme();
    var date = new Date();

    const [milestoneAnchor, setMilestoneAnchor] = useState(null);
    const [milestoneList, setMilestoneList] = useState([]);
    const [milestoneSelected, setMilestoneSelected] = useState(null);
    const [rawData, setRawData] = useState(null);

    const [loaded, setLoaded] = useState(null);
    const [timelineData, setTimelineData] = useState([]);
    const {teamDetails, loginUserDept} = useContext(UserContext);
    const [renderArr, setRenderArr] = useState([]);
    const currentLoading = useRef(null);

    // useEffect(() => {
	// 	setLoaded(null);
	// }, [startTime, endTime, view, selection])

    useEffect(() => {
        currentLoading.current = startTime + "" + endTime + "" + view + "" + selection;
        setLoaded(currentLoading.current);
    }, [startTime, endTime, view, selection])

    useEffect(() => {
        if(milestoneSelected !== null) {
            setTimeline();
        } else {
            setTimelineData([]);
        }
    }, [milestoneSelected])

    // useEffect(() => {
    //     // var selectedItem = selection;
	// 	if(loaded === null){
	// 		getTimelineData(startTime, endTime, view, selection, teamDetails).then((response) => {
    //             setRawData(response[1]);
    //             var allMilestones = response[0];
    //             allMilestones.sort(milestoneCompareFunction);
    //             setMilestoneList(allMilestones);
    //             setMilestoneSelected(allMilestones.length === 0 || [undefined, null, []].includes(allMilestones) ? null : allMilestones[1]);
	// 			setLoaded(true);
	// 		}).catch((e) => {
	// 			console.log("Exception occured");
	// 			setLoaded(false);
	// 		});
	// 	}

	// }, [loaded]);

    useEffect(() => {
		if(loaded !== true && loaded !== false) {
			var loadingNow = startTime+""+endTime+""+view+""+selection;
			getTimelineData(startTime, endTime, view, selection, teamDetails, loginUserDept).then((response) => {
                if(loadingNow === currentLoading.current) {
                    setRawData(response[1]);
                    var allMilestones = response[0];
                    allMilestones.sort(milestoneCompareFunction);
                    setMilestoneList(allMilestones);
                    setMilestoneSelected(allMilestones.length === 0 || [undefined, null, []].includes(allMilestones) ? null : allMilestones[0]);
                    setLoaded(true);
                }
			}).catch((e) => {
                if(loadingNow === currentLoading.current) {
                    setLoaded(false);
                }
			});
		}
	}, [loaded])

    function handleMilestoneChange(milestone) {
        setMilestoneSelected(milestone);
        setMilestoneAnchor(null);
    }

    function setTimeline() {
        var timeMap = {};

        var milestoneDetails = rawData[0];
        var tasklistDetails = rawData[1];
        var taskDetails = rawData[2];
        var rmapDetails = rawData[3];

        // console.log("################################# timeline details ########################################");
        // console.log(milestoneDetails);
        // console.log(tasklistDetails);
        // console.log(taskDetails);
        // console.log(rmapDetails);
        // console.log("################################# timeline details ########################################");

        var tasklistNames = [];
        var tasklistIds = [];

        if(milestoneSelected === undefined) {
            return;
        }
        var milestoneID = milestoneSelected[1];

        // console.log("================= milestone ====================");
        for(var milestone of milestoneDetails) {
            if(milestone.TaskDetails.MilestoneId === milestoneID) {
                // console.log(milestone);
                try {
                    var startDate =  milestone.TaskDetails.MilestoneStartDate;
                    var date = new Date(startDate);
                    if(isValidDate(date) && ![undefined, "", " ", null].includes(startDate)) {
                        var message = (
                            <div style={{
                                backgroundColor:"rgb(34,109,180,0.1)",
                                padding: "4px",
                                borderRadius: "6px",
                                border: "1px solid rgb(34,109,180,0.5)"
                            }}>
                                <span style={{
                                    color: "rgb(34,109,180,1)"
                                }}>
                                    {"Milestone"}
                                </span>
                                <br />
                                {milestone.TaskDetails.MilestoneName + " - Created"}
                            </div>
                        );
                        timeMap[date] = message;
                    }
                } catch(e) {}

                try {
                    var dueDate =  milestone.TaskDetails.MilestoneDueDate;
                    var date = new Date(dueDate);
                    if(isValidDate(date) && ![undefined, "", " ", null].includes(dueDate)) {
                        var message = (
                            <div style={{
                                backgroundColor: "rgb(34,109,180,0.1)",
                                padding: "4px",
                                borderRadius: "6px",
                                border: "1px solid rgb(34,109,180,0.5)"
                            }}>
                                <span style={{
                                    color: "rgb(34,109,180,1)"
                                }}>
                                    {"Milestone"}
                                </span>
                                <br />
                                {milestone.TaskDetails.MilestoneName + "  - Due date reached"}
                            </div>
                        );
                        timeMap[date] = message;
                    }
                } catch(e) {}
    
                try {

                    var endDate = milestone.TaskDetails.MilestoneCompletionDate;
                    var date = new Date(endDate);

                    var dueDate =  milestone.TaskDetails.MilestoneDueDate;
                    dueDate = [undefined, null].includes(dueDate) ? null : new Date(dueDate);

                    var message =  milestone.TaskDetails.MilestoneName + " - Completed!";
                    if(isValidDate(dueDate) && isValidDate(date)) {
                        if(date <= dueDate) {
                            message = <>{message}<span style={{color: "green"}}>{" Earlier by " + days_between(dueDate, date) + " day"}</span></>;
                        } else {
                            message = <>{message}<span style={{color: "red"}}>{" Delayed by " + days_between(date, dueDate) + " day"}</span></>;
                        }
                    }
                    if(isValidDate(date) && ![undefined, "", " ", null].includes(endDate)) {
                        var message = (
                            <div style={{
                                backgroundColor: "rgb(34,109,180,0.1)",
                                padding: "4px",
                                borderRadius: "6px",
                                border: "1px solid rgb(34,109,180,0.5)"
                            }}>
                                <span style={{
                                    color: "rgb(34,109,180,1)"
                                }}>
                                    {"Milestone"}
                                </span>
                                <br />
                                {message}
                            </div>
                        );
                        timeMap[date] = message;
                    }
                } catch(e) {}
                break;
            }
        }
        // console.log("======== tasklist ====================");
        var count = 0;
        for(var tasklist of tasklistDetails) {
            if(tasklist.TaskDetails.MilestoneId === milestoneID) {
                tasklistNames.push(tasklist.TaskDetails.TasklistName);
                tasklistIds.push(tasklist.TaskDetails.Tasklistid);
                // console.log(tasklist);
                try {
                    var startDate =  tasklist.TaskDetails.TasklistStartDate;
                    startDate = startDate.replaceAll("\t", "");
                    var date = new Date(startDate);
                    if(isValidDate(date) && ![undefined, "", " ", null].includes(startDate)) {
                        var message = (
                            <div style={{
                                backgroundColor: "rgb(8,153,73, 0.1)",
                                padding: "4px",
                                borderRadius: "6px",
                                border: "1px solid rgb(8,153,73,0.5)"
                            }}>
                                <span style={{
                                    color: "rgb(8,153,73,1)"
                                }}>
                                    {"Tasklist"}
                                </span>
                                <br />
                                {tasklist.TaskDetails.TasklistName + " - Created under "}
                                <span style={{
                                    color: "rgb(34,109,180,0.8)"
                                }}>
                                    {tasklist.TaskDetails.MilestoneName}
                                </span>
                            </div>
                        );
                        timeMap[date+""+(count++)] = message;
                    }
                } catch(e) {}

                try {
                    var dueDate =  tasklist.TaskDetails.TasklistDueDate;
                    dueDate = dueDate.replaceAll("\t", "");
                    var date = new Date(dueDate);
                    if(isValidDate(date) && ![undefined, "", " ", null].includes(dueDate)) {
                        var message = (
                            <div style={{
                                backgroundColor: "rgb(8,153,73, 0.1)",
                                padding: "4px",
                                borderRadius: "6px",
                                border: "1px solid rgb(8,153,73,0.5)"
                            }}>
                                <span style={{
                                    color: "rgb(8,153,73,1)"
                                }}>
                                    {"Tasklist"}
                                </span>
                                <br />
                                {tasklist.TaskDetails.TasklistName + " - Due date reached"}
                                {/* <span style={{
                                    color: "rgb(34,109,180,0.8)"
                                }}>
                                    {tasklist.TaskDetails.MilestoneName}
                                </span> */}
                            </div>
                        );
                        timeMap[date+""+(count++)] = message;
                    }
                } catch(e) {}
    
                try {

                    var endDate = tasklist.TaskDetails.TasklistCompletionDate;
                    endDate = endDate.replaceAll("\t", "");
                    var date = new Date(endDate);

                    var dueDate =  tasklist.TaskDetails.TasklistDueDate;
                    dueDate = dueDate.replaceAll("\t", "");
                    dueDate = [undefined, null].includes(dueDate) ? null : new Date(dueDate);

                    var message =  tasklist.TaskDetails.TasklistName + " - Completed!";
                    if(isValidDate(dueDate) && isValidDate(date)) {
                        if(date <= dueDate) {
                            message = <>{message}<span style={{color: "green"}}>{" Earlier by " + days_between(dueDate, date) + " day"}</span></>;
                        } else {
                            message = <>{message}<span style={{color: "red"}}>{" Delayed by " + days_between(date, dueDate) + " day"}</span></>;
                        }
                    }

                    if(isValidDate(date) && ![undefined, "", " ", null].includes(endDate)) {
                        var message = (
                            <div style={{
                                backgroundColor: "rgb(8,153,73, 0.1)",
                                padding: "4px",
                                borderRadius: "6px",
                                border: "1px solid rgb(8,153,73,0.5)"
                            }}>
                                <span style={{
                                    color: "rgb(8,153,73,1)"
                                }}>
                                    {"Tasklist"}
                                </span>
                                <br />
                                {message}
                            </div>
                        );
                        timeMap[date+""+(count++)] = message;
                    }
                } catch(e) {}
            }
        }

        // console.log(tasklistIds);

        // console.log("=============== task ===================");
        for(var task of taskDetails) {
            if(tasklistIds.includes(task.TaskBreaker.TaskListid)) {
                // console.log(task);
                try {
                    var startDate =  task.TaskBreaker.TaskStartDate;
                    var date = new Date(startDate);
                    if(isValidDate(date) && ![undefined, "", " ", null].includes(startDate)) {
                        var message = (
                            <div style={{
                                backgroundColor: "rgb(228,37,39, 0.1)",
                                padding: "4px",
                                borderRadius: "6px",
                                border: "1px solid rgb(228,37,39,0.5)"
                            }}>
                                <span style={{
                                    color: "rgb(228,37,39,1)"
                                }}>{"Task"}</span>
                                <br />
                                {task.TaskBreaker.TaskName + " - Created under "}
                                <span style={{
                                    color: "rgb(8,153,73,0.8)"
                                }}>
                                    {task.TaskBreaker.TaskListName}
                                </span>
                            </div>
                        );
                        timeMap[date+""+(count++)] = message;
                    }
                } catch(e) {}

                try {
                    var dueDate =  task.TaskBreaker.TaskDueDate;
                    var date = new Date(dueDate);
                    if(isValidDate(date) && ![undefined, "", " ", null].includes(dueDate)) {
                        var message = (
                            <div style={{
                                backgroundColor: "rgb(228,37,39, 0.1)",
                                padding: "4px",
                                borderRadius: "6px",
                                border: "1px solid rgb(228,37,39,0.5)"
                            }}>
                                <span style={{
                                    color: "rgb(228,37,39,1)"
                                }}>{"Task"}</span>
                                <br />
                                {task.TaskBreaker.TaskName + " - Due date reached"}
                            </div>
                        );
                        timeMap[date+""+(count++)] = message;
                    }
                } catch(e) {}
    
                try {

                    var endDate = task.TaskBreaker.TaskCompletionDate;
                    var date = new Date(endDate);

                    var dueDate = task.TaskBreaker.TaskDueDate;
                    dueDate = dueDate.replaceAll("\t", "");
                    dueDate = [undefined, null].includes(dueDate) ? null : new Date(dueDate);

                    var message =  task.TaskBreaker.TaskName + " - Completed!";
                    if(isValidDate(dueDate) && isValidDate(date)) {
                        if(date <= dueDate) {
                            message = <>{message}<span style={{color: "green"}}>{" Earlier by " + days_between(dueDate, date) + " day"}</span></>;
                        } else {
                            message = <>{message}<span style={{color: "red"}}>{" Delayed by " + days_between(date, dueDate) + " day"}</span></>;
                        }
                    }

                    if(isValidDate(date) && ![undefined, "", " ", null].includes(endDate)) {
                        var message = (
                            <div style={{
                                backgroundColor: "rgb(228,37,39, 0.1)",
                                padding: "4px",
                                borderRadius: "6px",
                                border: "1px solid rgb(228,37,39,0.5)"
                            }}>
                                <span style={{
                                    color: "rgb(228,37,39,1)"
                                }}>{"Task"}</span>
                                <br />
                                {message}
                            </div>
                        );
                        timeMap[date+""+(count++)] = message;
                    }
                } catch(e) {}
            }
        }

        // console.log("=========== roadmap ======================");

        for(var rmap of rmapDetails) {
            if(tasklistNames.includes(rmap.TaskBreaker.TaskName)) {
                // console.log(rmap);
                try {
                    var startDate =  rmap.TaskBreaker.CREATEDTIME;
                    var date = new Date(startDate);
                    if (isValidDate(date) && ![undefined, "", " ", null].includes(startDate)) {
                        var message = (
                            <div style={{
                                backgroundColor: "rgb(255,105,180, 0.1)",
                                padding: "4px",
                                borderRadius: "6px",
                                border: "1px solid rgb(255,105,180,0.5)"
                            }}>
                                <span style={{
                                    color: "rgb(255,105,180,1)"
                                }}>
                                    {"Roadmap"}
                                </span>
                                <br />
                                {rmap.TaskBreaker.TaskName + " - Created"}
                            </div>
                        );
                        timeMap[date] = message;
                    }
                } catch(e) {}
    
                // try {

                //     var endDate = task.TaskBreaker.TaskCompletionDate;
                //     var date = new Date(endDate);
                //     if(isValidDate(date) && ![undefined, "", " ", null].includes(endDate)) {
                //         var message = "Task: " + task.TaskBreaker.TaskName + " Completed!";
                //         timeMap[date] = message;
                //     }
                // } catch(e) {
                //     console.log(e);
                // }
            }
        }

        // console.log(timeMap);

        var finalArr = [];
        for(var date in timeMap) {
            var message = timeMap[date];
            var dateString = new Date(date).toDateString();

            finalArr.push([message, dateString]);
        }

        finalArr.sort((a, b) => {
            return new Date(a[1]) - new Date(b[1]);
        })

        setTimelineData(finalArr);
    }

    function isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }

    useEffect(() => {
        var renderArr = [];
        timelineData.forEach((item, index) => {
            renderArr.push(
                <TimelineItem key={index} sx={{padding: 0.5}}>
                    <TimelineOppositeContent color="text.secondary" sx={{flex: 0.1, fontSize: "0.8rem"}}>
                        <span style={{color: "#607d8b"}}>{item[1]}</span>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        {/* <TimelineDot sx={{color: "green !important"}}/> */}
                        <CircleIcon sx={{color:"#607d8b", fontSize: "0.8rem", padding: 1.3}} />
                        {index === timelineData.length-1 ? <></> : <TimelineConnector/>}
                    </TimelineSeparator>
                    <TimelineContent sx={{fontSize: "0.8rem"}} >
                        {item[0]}
                    </TimelineContent>
                </TimelineItem>
            );
        });
        setRenderArr(renderArr);
    }, [timelineData])

    function handleMilestoneClick(value) {
        if(milestoneList.length !== 0) {
            setMilestoneAnchor(value);
        }
    }

	return (
		<div style={{
			width: "100%",
			height: "100%",
			justifyContent: "top"
		}}>
			<div style={{
				fontWeight: 600,
				fontSize: "calc(1.0vw)",
				color: theme.palette.mode === "light" ? "black" : "white",
                overflow: "hidden"
			}}>
				<span style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "left",
					flexDirection: "row",
                    float: "left",
                    fontSize: "1rem"
				}}>
					Execution Report
				</span>
                <div style={{
                    float: "right"
                }}>

                    {
                        view === "ServerView" ?
                            <Tooltip title="select milestone">
                                <Button variant="contained" size="small" onClick={(event) => handleMilestoneClick(event.currentTarget)} style={{
                                    textTransform: "none"
                                }}>
                                    <>
                                        {
                                            [null, undefined].includes(milestoneSelected) ?
                                                <></>
                                                :
                                                milestoneSelected[0]
                                        }
                                        <ArrowDropDownIcon />
                                    </>
                                </Button>
                            </Tooltip>
                            :
                            <></>
                    }

                    <Menu
						anchorEl={milestoneAnchor}
						open={milestoneAnchor !== null}
						onClose={() => setMilestoneAnchor(null)}
						sx={{
							maxHeight: "750px"
						}}
					>
                       {milestoneList.map((milestone, index) => {
                            return <MenuItem key={index} onClick={() => handleMilestoneChange(milestone)}>{milestone[0]}</MenuItem>
                        })}
					</Menu>
                </div>
            </div>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%"
            }}>
                {/* {
                    loaded === null ?
                        <CircularProgress />
                        :
                        loaded === false ?
                            <h1>Unable to fetch :(</h1>
                            :
                            renderArr.length === 0 ?
                                <h1>Nothing Found &nbsp; :o</h1>
                                :
                                <Timeline sx={{
                                    height: "calc(66vh)",
                                    overflowY: "scroll"
                                }}
                                >
                                    {renderArr}
                                </Timeline>
                } */}

                {
                    loaded === true ?
                        renderArr.length === 0 ?
                                <h1>Nothing Found &nbsp; :o</h1>
                                :
                                <Timeline sx={{
                                    height: "calc(90%)",
                                    overflowY: "scroll"
                                }}
                                >
                                    {renderArr}
                                </Timeline>
                        :
                        loaded === false ?
                            <h1>Unable to fetch :(</h1>
                            :
                            <CircularProgress />
                }

            </div>
        </div>
    );
}

function milestoneCompareFunction(a, b) {
    if(a[0] > b[0]) {
        return 1;
    } else if(b[0] > a[0]) {
        return -1;
    } else {
        return 0;
    }
}