import { isDateInRange, isDateString } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    const chartData = [];
    
    const buildMap = {};

    catalystDB.AllBuildDetails.map(item => {

        const dateString = item.CREATEDTIME;

        if(isDateString(dateString) && isDateInRange(dateString, startTime)) {

            if([undefined, null, "", " "].includes(item.Server)) {
                return;
            }

            if(item.Server in buildMap) {
                if(item.DRI in buildMap[item.Server]) {
                    buildMap[item.Server][item.BuildBy] += 1; 
                } else {
                    buildMap[item.Server][item.BuildBy] = 1;
                }
            } else {
                buildMap[item.Server] = {};
                buildMap[item.Server][item.BuildBy] = 1;
            }
        }
    }); 

    for(const server in buildMap) {
        const arr = [];

        for(const user in buildMap[server]) {
            arr.push([
                user,
                buildMap[server][user]
            ])
        }

        chartData.push({
            "seriesname": server,
            "data": arr
        });
    }

    return chartData;
}