/* eslint-disable react/prop-types */ 
/* esline-disable react/jsx-key */
import React from "react";
import { useEffect, useRef, useState, useContext } from "react";
import { UserContext } from "../../../../../contexts/UserContext";
import { useTheme } from '@mui/material/styles';
import { Box, ButtonGroup, Skeleton, Tab } from "@mui/material";
import { ReportContext } from "../newReportGeneral/NewReport";
import { getMilestoneRows, getRoadmapAsLikeTasklist, getTasklistRows } from "../reportSection1/ReportSection1DataFetcher"; 

import AirIcon from '@mui/icons-material/Air';
import { PieChart, SunBurst } from "../newReportGeneral/NewReportUtilComponents";

export function ReportSection4() {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            width: "100%",
            height: "98%",
            gap: "10px"
        }}>
             <Box sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.1rem",
                fontWeight: "bold"
            }}>
                Milestone Type
            </Box>
            <ReportBody/>
        </Box>
       
    );
}   


function ReportBody({setExpanded}) {

    const {
        subjectType, 
        setSubjectType, 
        subject, 
        setSubject, 
        timeFrame, 
        setTimeFrame, 
        time, 
        setTime,
        timeRange,
        setTimeRange
    } = useContext(ReportContext);
    const theme = useTheme();
    const {teamDetails, loginUserDept} = useContext(UserContext);

    // var chartData = [
    //     {
    //         "seriesname": "Q1sdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdf",
    //         "data": [
    //             [
    //                 [
    //                     "new",
    //                     30026
    //                 ],
    //                 [
    //                     "Enhancements",
    //                     30370
    //                 ]
    //             ]
    //         ]
    //     },
    //     {
    //         "seriesname": "Q2",
    //         "data": [
    //             [
    //                 [
    //                     "Apr",
    //                     15186
    //                 ],
    //                 [
    //                     "May",
    //                     13851
    //                 ],
    //                 [
    //                     "Jun",
    //                     12828
    //                 ]
    //             ]
    //         ]
    //     },
    //     {
    //         "seriesname": "Q3",
    //         "data": [
    //             [
    //                 [
    //                     "Jul",
    //                     31266
    //                 ],
    //                 [
    //                     "Aug",
    //                     37097
    //                 ],
    //                 [
    //                     "Sep",
    //                     22524
    //                 ]
    //             ]
    //         ]
    //     },
    //     {
    //         "seriesname": "Q4",
    //         "data": [
    //             [
    //                 [
    //                     "Oct",
    //                     30267
    //                 ],
    //                 [
    //                     "Nov",
    //                     21744
    //                 ],
    //                 [
    //                     "Dec",
    //                     27043
    //                 ]
    //             ]
    //         ]
    //     }
    // ];
    
    const [loaded, setLoaded] = useState(undefined);
    const [tasklistRows, setTasklistRows] = useState(undefined);
    const [roadmapRows, setRoadmapRows] = useState(undefined);
    const [chartData, setChartData] = useState(undefined);
    
    useEffect(() => {
        const controller = new AbortController();

        async function getRoadmapRows(tasklistNameArray, tasklistComponentArray, teamDetails) {
            getRoadmapAsLikeTasklist(tasklistNameArray, tasklistComponentArray, loginUserDept).then((response) => {
                if(!controller.signal.aborted) {
                    if(response.length !== 0) {

                        var data = [];
                        var internal = [];
                        var external = [];

                        response.forEach(item => {
                            if(item.Type_Of_Roadmap === "internal") {
                                internal.push(item);
                            } else {
                                external.push(item);
                            }
                        })

                        var internal_new_count = 0;
                        var internal_enhancement_count = 0;

                        internal.map(item => {
                            if(item.Feature_Type === "New") {
                                internal_new_count += 1;
                            } else {
                                internal_enhancement_count += 1;
                            }
                        })

                        data.push({
                            "seriesname": "Internal",
                            "data": [
                                [
                                    "New",
                                    internal_new_count
                                ],
                                [
                                    "Enhancement",
                                    internal_enhancement_count
                                ]
                            ]
                        });

                        var external_new_count = 0;
                        var external_enhancement_count = 0;

                        external.map(item => {
                            if(item.Feature_Type === "New") {
                                external_new_count += 1;
                            } else {
                                external_enhancement_count += 1;
                            }
                        })

                        data.push({
                            "seriesname": "External",
                            "data": [
                                [
                                    "New",
                                    external_new_count
                                ],
                                [
                                    "Enhancement",
                                    external_enhancement_count
                                ]
                            ]
                        });

                        setRoadmapRows(response); 
                        setChartData(data);
                        setLoaded(true);
                    } else {
                        setLoaded(false);
                    }
                }
            }).catch((error) => {
                if(!controller.signal.aborted) {
                    setLoaded(false);
                }
            });
        }

        async function pullTasklistRows() {

            getTasklistRows(timeRange, subjectType, subject, teamDetails, loginUserDept).then((response) => {
                if(!controller.signal.aborted) {
                    if(response.length !== 0) {
                        setTasklistRows(response);

                        var tasklistNameArray = [];
                        var tasklistComponentArray = [];

                        response.forEach(item => {
                            tasklistNameArray.push(item.TasklistName);
                            tasklistComponentArray.push(item.Component);
                        })

                        getRoadmapRows(tasklistNameArray, tasklistComponentArray);
                    } else {
                        setLoaded(false);
                    }
                }
            }).catch((error) => {
                if(!controller.signal.aborted) {
                    setLoaded(false);
                }
            });
        }

        setLoaded(undefined);
        pullTasklistRows();

        return () => {
            controller.abort();
        };

    }, [timeRange, subjectType,subject]);


    if(loaded === undefined) {
        return (
            <Skeleton variant={"rounded"} height={"98%"} width={"100%"} animation="wave" />
        );
    } else if(loaded === false) {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%"
            }}>
                <Box>
                    <AirIcon sx={{
                        fontSize: "6rem",
                        color: theme.palette.mode === "light" ? "rgb(20, 20, 20, 0.06)": "rgb(200, 200, 200, 0.06)"
                    }} />
                </Box>
                <Box sx={{
                    fontSize: "0.7rem",
                    color: theme.palette.mode === "light" ? "rgb(10, 10, 10, 0.3)": "rgb(200, 200, 200, 0.4)"
                }}>
                    Nothing found
                </Box>
            </Box>
        );
    } else {
        return (
            <Box  sx={{
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                overflowY: "scroll",
                boxSizing: "border-box",
                backgroundColor: theme.palette.mode === 'dark' ? 'rgb(24,30,35)' : '#FAFAFA',
                borderRadius: "12px",
                overflowX: "hidden"
            }}
            >
                <SunBurst title={""} data={chartData} colors={["#46BAC1", "#8F6AA6"]}/>
            </Box>
        );
    }
}