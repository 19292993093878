import React, { useState,useEffect, useContext } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded';
import { Fade, Tooltip } from '@mui/material';
import { keyframes } from '@mui/system';
import { Button } from '@mui/material';
import { Skeleton } from '@mui/material';

import {
    Box,
    Menu,
    MenuItem,
    ButtonGroup
} from "@mui/material";

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';

import Paper from '@mui/material/Paper';
import { queryDB } from '../../../util/QueryDB';
import { EmployeeCards } from '../../../util/Loading.js';
import { UserContext } from '../../../contexts/UserContext';
import {useTheme} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import { external_user_get_query_1 } from '../../../catalystDB/queryGenerator.js';

import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

export function ExternalUser() {
    const [viewSelected, setViewSelected] = useState("External");
    const { userDetails, loginUserEmail, teamDetails } = useContext(UserContext);
    const loginUserRole = teamDetails.current[loginUserEmail.current].Role;

    return (
        <>
        <Box sx={{
            position: "absolute",
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            alignItems: "center",
            justifyContent: "center",
            left: 300,
            right: 300,
            top: 20,
            marginLeft: "auto",
            marginRight: "auto",
            color: "white",
            fontWeight: "bold",
            cursor: "default",
            transition: "top .5s",
            paddingLeft: "250px"
        }}
        >
            {["Manager", "Mentor", "CEO", "Super Admin","Developer"].includes(loginUserRole) ? (
                <ButtonGroup>
                <Button
                    variant={viewSelected === "Internal" ? "contained" : "outlined"}
                    size="small"
                    onClick={() => {
                        setViewSelected("Internal");
                    }}
                    sx={{
                        textTransform: "none",
                        borderRadius: "40px"
                    }}
                >
                    Internal
                </Button>
                    <Button
                        variant={viewSelected === "External" ? "contained" : "outlined"}
                        size="small"
                        onClick={() => setViewSelected("External")}
                        sx={{
                            textTransform: "none",
                            borderRadius: "40px"
                        }}
                    >
                        External
                    </Button>
                     
                </ButtonGroup>
            ):(
                <ButtonGroup>
                    <Button
                        variant={viewSelected === "External" ? "contained" : "outlined"}
                        size="small"
                        onClick={() => setViewSelected("External")}
                        sx={{
                            textTransform: "none",
                            borderRadius: "40px"
                        }}
                    >
                        External
                    </Button>
                     
                </ButtonGroup>
            )}
            
            </Box>
            
        <div className='page-content-2'>
            <ExternalUserTopBar viewSelected={viewSelected}/>
            <ExternalComponent viewSelected={viewSelected} setViewSelected={setViewSelected}/>
        </div>
    </>
    )

}

function ExternalComponent({viewSelected,setViewSelected}) {

    const [loaded, setLoaded] = useState(undefined);
    // console.log(loaded)

    const [rows, setRows] = useState(undefined);
    const { loginUserDept } = useContext(UserContext);
    const theme = useTheme();

    var rrows = [];

    useEffect(() => {
        setLoaded(undefined);
        Data()
            .then((queryResult) => {
                
                // console.log('Query result:', queryResult);
                if (queryResult.length > 0) {
                    for (var item of queryResult) {
                        var rrow = [];
                        rrow.push(item.ExternalFeatureReport.Feature);
                        rrow.push(item.ExternalFeatureReport.Module);
                        rrow.push(item.ExternalFeatureReport.Over_All_ETA);
                        rrow.push(item.ExternalFeatureReport.ReleasePlan);
                        rrow.push(item.ExternalFeatureReport.RequestedBy);
                        rrow.push(item.ExternalFeatureReport.CreatorRowid);
                        rrow.push(item.ExternalFeatureReport.TeamName);
                        rrow.push(item.ExternalFeatureReport.NeedClarification);//n0-7
                        rrow.push(item.ExternalFeatureReport.DocumentAgreed);
                        rrows.push(rrow);
                    }
                    setRows(rrows);
                    setLoaded(true);
                } else {
                    setLoaded(false);
                    setRows(undefined);
                }
            })
            .catch((error) => {
                //console.error('Error querying data:', error);
                setLoaded(false);
                setRows(undefined);
            });
    }, [viewSelected]);
	// console.log(loginUserDept)

    async function Data() {
        try {
            const result = await queryDB(external_user_get_query_1(viewSelected, loginUserDept));
            return result;
        } catch (error) {
            console.error('Error querying data:', error);
            throw error;
        }
    }

    if (loaded === undefined) {
        return (
                <>
                    <Skeleton variant="rectangular" width={"100%"} height={50} />
                    <br></br>
                    <Skeleton variant="rectangular" width={"100%"} height={115} />
                    <br></br>
                    <Skeleton variant="rectangular" width={"100%"} sx={{ flex: 1 }} />
                </>
        );
    } else if (loaded === null) {
        return (
            <Box  sx={{
                fontSize: "2rem",
                color: theme.palette.background.text
            }}>
                <h3>something went wrong...</h3>
            </Box>
        );
    } else if (loaded === false) {

        let message = "Oops! Looks like the feature request data is taking a day off";

        if (loginUserDept.current === "ZMP") {
            message = "Evolve...";
        } else if (loginUserDept.current === "ZVP") {
            message = "Oops! Looks like the feature request data is taking a day off";
        }

        return (
            <Box sx={{
                fontSize: "2rem",
                color: theme.palette.background.text
            }}>
                {message}
            </Box>
        );

    } else {
        return (
            <div >
                <ExternalUserTableData rows={rows} setRows={setRows}/>
            </div>
        );
    }

}

function ExternalUserTopBar(viewSelected,setViewSelected) {


    const [openPopup, setOpenPopup] = useState(false);
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const minimize = false;

    const handleOpenPopup = () => {
        setOpenPopup(true);
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleIframeLoad = () => {
        setIframeLoaded(true);
    };

    const grow = keyframes`
	0% {
		scale: 1;
	}
	20% {
		scale: 1.05;
		rotate: 0deg;
	}
	35% {
		rotate: -5deg;
	}
	45% {
		rotate: 5deg;
	}
	65% {
		rotate: -5deg;
	}
	80% {
		scale: 1.05;
		rotate: 0deg;
	}
	100% {
		scale: 1;
	}
	`;


    return (

        <div className="external-top-bar">
            <div className="external-top-bar-left" style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "20px",
            }}>
                
                <Button variant="contained" size="small" onClick={handleOpenPopup} sx={{
                    borderRadius: "100px",
                    padding: 0.9,
                    paddingRight: 1.2,
                    paddingLeft: 1.2
                }}>
                    {
                        minimize ?
                            <OpenInFullRoundedIcon sx={{
                                animation: `${grow} 1s ease`,
                                fontSize: "1.4rem"
                            }} />
                            :
                            <AddIcon sx={{ fontSize: "1.4rem" }} />
                    }
                </Button>
                <Dialog open={openPopup} onClose={handleClosePopup} sx={{
                    display: minimize ? "none" : "block"
                }} fullScreen>
                    <DialogActions>
                        <Button onClick={handleClosePopup} variant="text" fontSize="large"><CloseIcon /></Button>
                    </DialogActions>
                    <DialogContent>
                        {!iframeLoaded && (
                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '100%',
                                    backgroundImage: 'linear-gradient(to right, #f0f0f0, #e0e0e0)',
                                    borderRadius: 0,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height="100%"
                                    animation="wave"
                                />
                            </div>
                        )}
                        {viewSelected.viewSelected === "External" ? (
                             <iframe
                             src="https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/External_Feature_Request?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true&Type_of_Feature=External"
                             width="100%"
                             height="100%"
                             title="External Content"
                             onLoad={handleIframeLoad}
                             style={{ display: iframeLoaded ? 'block' : 'none' }}
                         />
                        ):(
                            <iframe
                            src="https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/External_Feature_Request?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true&Type_of_Feature=Internal"
                            width="100%"
                            height="100%"
                            title="External Content"
                            onLoad={handleIframeLoad}
                            style={{ display: iframeLoaded ? 'block' : 'none' }}
                        />
                        )}
                       
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    );
}

function ExternalUserTableData({rows, setRows}) {

    const handleDragEnd = (e) => {
        if (!e.destination) return;
        let tempData = Array.from(rows);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        setRows(tempData);

        console.log(tempData);
        // todo => update this order as per tempData in DB.
    };
    
    var filteredData = rows;
    return (
        <div>
            <div className="external-table">
                <TableContainer component={Paper} className="external-table-container"
                    
                >
                    <DragDropContext onDragEnd={handleDragEnd}>
                    <Table stickyHeader >
                        <TableHead
                        >
                            <TableRow className="external-table-head"

                            >
                                <TableCell align="left" className="external-table-grouper-header" />
                                <TableCell align="left" />
                                <TableCell align="left" style={{ width: '1vw' }}>S.No.</TableCell>
                                <TableCell align="center" style={{ width: '25vw' }}>Feature</TableCell>
                                {/* <TableCell align="center" style={{ width: '15vw' }}>Requester</TableCell> */}
                                <TableCell align="center" style={{ width: '11vw' }}>Module</TableCell>
                                <TableCell align="center" style={{ width: '15vw' }}>Overall ETA</TableCell>
                                {/* <TableCell align="center" style={{ width: '15vw' }}>Release Planned</TableCell> */}
                                <TableCell align="center" style={{ width: '10vw' }}>Requested By</TableCell>
                                {/* <TableCell align="right" /> */}
                                <TableCell align="center" style={{ width: '10vw' }}>Status</TableCell>
                                <TableCell align="right" className="external-table-grouper-header" />

                            </TableRow>
                        </TableHead>
                        <Droppable droppableId="droppable-1">
                            {(provider) => (
                                <TableBody id="list-view" ref={provider.innerRef} {...provider.droppableProps}>
                                    {
                                        Array.isArray(filteredData) ?
                                            filteredData.map((row, index) => {
                                                return <ExternalUserRow key={index} row={row} index={index} provider={provider}/>;
                                            })
                                            :
                                            null
                                    }
                                    {provider.placeholder}
                                </TableBody>
                            )}
                        </Droppable>
                    </Table>
                    </DragDropContext>
                </TableContainer>
            </div>
        </div>
    );
}

function ExternalUserRow({ row, index, provider}) {
    const [moreVisibile, setMoreVisible] = useState(false);
    const date = new Date(row[3]);

    const originalDate = row[2];
    const dateObj = new Date(originalDate);

    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = dateObj.toLocaleString('default', { month: 'short' });
    const year = dateObj.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;

    return (
        <Draggable draggableId={"darg-" + index} index={index}>
            {(provider) => (
            <TableRow className="external-table-head"
                {...provider.draggableProps} ref={provider.innerRef}
                onMouseEnter={() => setMoreVisible(true)}
                onMouseLeave={() => setMoreVisible(false)}>
                <TableCell align="center"></TableCell>
                <TableCell align="center" {...provider.dragHandleProps}> <DragIndicatorIcon /></TableCell>
                <TableCell align="center" >{index + 1}</TableCell>
                <TableCell align="center" >{row[0]}</TableCell>
                <TableCell align="center" style={{ color: 'orange' }}>{row[1]}</TableCell>
                <TableCell align="center" >{row[2] != null ? formattedDate : null} </TableCell>
                {/* <TableCell align="center" style={{ color: 'blue' }}>{date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                })}</TableCell> */}
                <TableCell align="center" ><EmployeeCards emails={row[4]} /></TableCell>
                <TableCell align="center">
                    {row[2] !== null ? (
                        <Tooltip title="Status: Considered">
                            <span style={{ color: 'Orange' }}>Considered</span>
                        </Tooltip>
                    ) : (
                        <>
                            {row[8] ? (
                                <Tooltip title="Verified Required Document">
                                    <span style={{ color: 'green' }}>Document Verified</span>
                                </Tooltip>
                            ) : (
                                <Tooltip title={row[7] ? "Document Unverified" : "Unverified"}>
                                    <span style={{ color: row[7] ? 'red' : 'blue' }}>
                                        {row[7] ? "Document Needs Clarification" : "Open"}
                                    </span>
                                </Tooltip>
                            )}
                        </>
                    )}
                </TableCell>
                {
                    row[7] &&
                    <MoreSection className='slide-animation' moreVisibile={moreVisibile} setMoreVisible={setMoreVisible} creatorRowID={row[5]} />
                }
            </TableRow>
            )}
        </Draggable>
    );
}
function MoreSection({ moreVisibile, setMoreVisible, creatorRowID, MilestoneUniqueID1, handleButtonClick, isButtonEnabled }) {
    const [moreAnchor, setMoreAnchor] = useState(null);
    const [editIframeLoaded, setEditIframeLoaded] = useState(false);
    const [showAlert7, setShowAlert7] = useState(false);
    const [copiedText, setCopiedText] = useState('');
    const [openPopupEdit, setOpenPopupEdit] = useState(false);

    const milestoneEditUrl = "https://creatorapp.zoho.com/zohointranet/zvp-team/External_Feature_Request/record-edit/All_External_Feature_Requests/" + creatorRowID + "/";

    const handleClosePopupEdit = () => {
        setOpenPopupEdit(false);
        setEditIframeLoaded(false);
        setMoreVisible(false);
    };

    const handleEditIframeLoad = () => {
        setEditIframeLoaded(true);
    };

    function handleEditClick() {
        setOpenPopupEdit(true);
        setMoreAnchor(null);
    }

    const MilestoneUniqueID = MilestoneUniqueID1;



    return (
        <TableCell align="right" style={{ width: "5px", cursor: "pointer" }}>
            <Fade in={moreVisibile} timeout={100}>
                <MoreVertIcon onClick={(event) => setMoreAnchor(event.currentTarget)} />
            </Fade>
            <Menu anchorEl={moreAnchor}
                open={moreAnchor !== null}
                onClose={() => { setMoreAnchor(null) }}
            >
                <MenuItem onClick={handleEditClick}>
                    <Box style={{ display: "flex" }}>
                        <EditIcon fontSize="small" />
                        &nbsp;Edit
                    </Box>
                </MenuItem>
            </Menu>
            <Dialog open={openPopupEdit} onClose={handleClosePopupEdit} fullScreen>
                <DialogActions>
                    <Button onClick={handleClosePopupEdit} variant="text" fontSize="large"><CloseIcon /></Button>
                </DialogActions>
                <DialogContent>
                    {!editIframeLoaded && (
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                height: '100%',
                                backgroundImage: 'linear-gradient(to right, #f0f0f0, #e0e0e0)',
                                borderRadius: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height="100%"
                                animation="wave"
                            />
                        </div>
                    )}
                    <iframe
                        src={milestoneEditUrl}
                        width="100%"
                        height="100%"
                        title="External Content"
                        frameBorder={0}
                        onLoad={handleEditIframeLoad}
                        style={{ display: editIframeLoaded ? 'block' : 'none' }}
                    />
                </DialogContent>
            </Dialog>

        </TableCell>
    );
}