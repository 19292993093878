import React, { useContext, useState, useEffect, Fragment  } from 'react';
import { Fade, Slide, Zoom, Grow } from '@mui/material';
import { Box } from '@mui/material';
import ZVPLogo from './../icons/ZVPLogo.svg';
import Avatar from '@mui/material/Avatar';
import { PropTypes } from "prop-types";

import { UserContext } from '../contexts/UserContext';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import { getOthersDetails } from "./QueryDB";

export function setCookie(name,value,exp_days) {
    var d = new Date();
    d.setTime(d.getTime() + (exp_days*24*60*60*1000));
    var expires = "expires=" + d.toGMTString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export function getCookie(name) {
    var cname = name + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i < ca.length; i++){
        var c = ca[i];
        while(c.charAt(0) == ' '){
            c = c.substring(1);
        }
        if(c.indexOf(cname) == 0){
            return c.substring(cname.length, c.length);
        }
    }
    return null;
}

export function deleteCookie(name) {
    var d = new Date();
    d.setTime(d.getTime() - (60*60*1000));
    var expires = "expires=" + d.toGMTString();
    document.cookie = name + "=;" + expires + ";path=/";
}

export function LoadingComponent()
{
	return (
        <Zoom in={true}>
            <Box sx={{
                        animation: "spin 3s cubic-bezier(.66,.39,.21,.67) infinite",
                        "@keyframes spin": {
                            "0%": {
                                transform: "scale( .5 ) rotate(360deg)"
                            },
                            "35%": {
                                transform: "scale( .7 ) rotate(240deg)"
                            },
                            "70%": {
                                transform: "scale( 1 ) rotate(120deg)"
                            },
                            "100%": {
                                transform: "scale( .5 ) rotate(0deg)"
                            }
                        }
                    }}>
                <img src={ZVPLogo} style={{height: "10vh", width: "10vw"}}/>
            </Box>
        </Zoom>
	);
}

export function SpinningLoadingComponent()
{
	return (
        <Zoom in={true}>
            <Box sx={{
                        animation: "spin 1.5s cubic-bezier(.66,.39,.21,.67) infinite",
                        "@keyframes spin": {
                            "0%": {
                                transform: "scale( .5 ) rotate(360deg)"
                            },
                            "35%": {
                                transform: "scale( .6 ) rotate(240deg)"
                            },
                            "70%": {
                                transform: "scale( .4 ) rotate(120deg)"
                            },
                            "100%": {
                                transform: "scale( .5 ) rotate(0deg)"
                            }
                        }
                    }}>
                <img src={ZVPLogo} style={{height: "10vh", width: "10vw"}}/>
            </Box>
        </Zoom>
	);
}

GetNameAndEmpID.propTypes ={
	email: PropTypes.string
}

function GetName({ email, h }) {
	//console.log(email);

	const { teamDetails } = useContext(UserContext);
	const { supportUsers } = useContext(UserContext);
	let username = "NAAA";
	let empID = "NA";
	let zuid = "NAAA";
	const [showFullName, setShowFullName] = useState(false);
	var longName = false;
	let truncatedUsername = "NA";
	
	try {
		//console.log(teamDetails.current[email]);
		
		  
		  if (teamDetails.current[email] === undefined) {
			
			username = supportUsers.current[email].Name;
	    	zuid = supportUsers.current[email].Zuid;
			//fetchUserDetails();
		  } else {
			username = teamDetails.current[email].PeopleUserName;
	    	zuid = teamDetails.current[email].zuid;
		  }

		  
		if(username.length > 18)
		{
			truncatedUsername = username.slice(0, 16) + "...";
			longName = true;
		}
		else
		{
			truncatedUsername = username;
			longName = false;
		}
	}
	catch (e) {
		 //console.log(e);
		return null;
	}

	const handleMouseEnter = () => {
		if(longName)
		{
			setShowFullName(true);
		}
	};

	const handleMouseLeave = () => {
		if(longName)
		{
			setShowFullName(false);
		}
	};

	return (
		<div
			className="flex-container"
			style={{
				width: "100%",
				height: '50px',
				whiteSpace: showFullName ? 'normal' : 'nowrap',
				overflow: showFullName ? 'auto' : 'hidden',
				textOverflow: 'ellipsis',
				scrollbarWidth: "none",
				display: 'flex',
        		alignItems: 'center'
			}}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<div style={{ paddingTop: '5px', float: 'left' }}>
				<Avatar
					alt={username}
					src={`https://contacts.zoho.com/file?ID=${zuid}&exp=6000&fs=thumb`}
					sx={{ width: h? h : 40, height: h ? h : 40, position: "static"}}
				/>
			</div>
			<div style={{ width: '100%', float: 'right' }}>
				<div style={{ height: '18px', fontSize: '0.775rem', textAlign: 'left', paddingLeft: '10px' }}>
					{/* {username} */}
					{showFullName && longName ? username : truncatedUsername}
				</div>
			</div>
			<br style={{ clear: 'both' }} />
		</div>
	);
}

export function GetNameAndEmpID({ emails, h, w, full }) {
	const { teamDetails } = useContext(UserContext);
	const [showFullName, setShowFullName] = useState(null);
	const [showScroll, setShowScroll] = useState(false);
	const [expanded, setExpanded] = useState(false);

	var email = [];
	//   const emailsamples ='vijayshankar.a@zohocorp.com,manoj.sakthivel@zohocorp.com,jayasuriya.bm@zohocorp.com,karthickkumar.r@zohocorp.com, hari.kandregula@zohocorp.com';
	// 		emails=emailsamples+emails;
	if (emails === "" || emails === "-" || emails === "NA" || emails === " ") {
		emails = [];
	}
	else if (emails && emails.includes(',')) {
		email = emails.split(',').map((str) => str.trim());
		//console.log(email);
	}
	else {

		email.push(emails);
	}

	if (email.length == 1 || email.length == 0) {
		//console.log(email);
		return (
			<GetName email={email[0]} h={h}/>
		);
	}
	else {
		const getEmailDetails = (email) => {
			const username = teamDetails.current[email]?.PeopleUserName || 'NA';
			const zuid = teamDetails.current[email]?.zuid || 'NA';
			//console.log("other fellows data-->",username, zuid);
			return { username, zuid };
		};

		const handleMouseEnter = (index) => {
			setShowFullName(index);
		};

		const handleMouseLeave = () => {
			setShowFullName(null);
		};

		const handleViewMoreClick = () => {
			setExpanded(true);
			if (email.length > 3) {
				setShowScroll(true);
			}
		};

		const handleCloseClick = () => {
			setExpanded(false);
			setShowScroll(false);
		};

		const renderAvatars = (emails) => {
			const emailList = (expanded || full) ? emails : emails.slice(0, 3);

			return (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						flexWrap: 'nowrap',
						overflowX: 'scroll',
						scrollBehavior: 'smooth',
						paddingRight: showScroll ? '21px' : '0',
					}}
				>
					{emailList.map((email, index) => {
						const { username, zuid } = getEmailDetails(email);

						return (
							<Tooltip key={index} title={username}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										//   marginRight: '8px',
										cursor: 'pointer',
										'&:hover': {
											zIndex: 1,
										}
									}}
									onMouseEnter={() => handleMouseEnter(index)}
									onMouseLeave={handleMouseLeave}
								>
									<Avatar
										alt={username}
										src={`https://contacts.zoho.com/file?ID=${zuid}&exp=6000&fs=thumb`}
										sx={{
											width: h? h : 40,
											height: h? h: 40,
											marginBottom: '4px',
										}}
									/>
									{showFullName === index && (
										<div
											style={{
												position: 'absolute',
												top: '100%',
												backgroundColor: 'rgba(0, 0, 0, 0.8)',
												color: 'white',
												padding: '4px',
												borderRadius: '4px',
												fontSize: '0.8rem',
												whiteSpace: 'nowrap',
											}}
										>
											{username}
										</div>
									)}
								</div>
							</Tooltip>
						);
					})}
					{full === undefined && emails.length > 3 && !expanded && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								marginRight: '8px',
								cursor: 'pointer',
								'&:hover': {
									zIndex: 1,
								},
							}}
							onClick={handleViewMoreClick}
						>
							<Tooltip title="View More">
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										width: 40,
										height: h? h : 40,
										backgroundColor: 'gray',
										borderRadius: '50%',
									}}
								>
									<AddIcon sx={{ fontSize: 20, color: 'white' }} />
								</div>
							</Tooltip>
						</div>
					)}
					{/* <div style={{ width: '12px' }} /> */}
				</div>
			);
		};

		return (
			<div
				className="flex-container"
				style={{
					width: w ? w : '170px',
					height: h? h+5: '50px',
					position: 'relative',
					borderRadius: '4px',
					padding: '4px',
					overflow: 'hidden',
				}}
			>
				{Array.isArray(email) ? (
					renderAvatars(email)
				) : (
					<Avatar
						alt={email}
						src={`https://contacts.zoho.com/file?ID=${getEmailDetails(email).zuid}&exp=6000&fs=thumb`}
						sx={{ width: 40, height: h ? h :40 }}
					/>
				)}
				{showScroll && (
					<div
						style={{
							position: 'absolute',
							top: '50%',
							right: '0',
							transform: 'translateY(-50%)',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: 'rgba(0, 0, 0, 0.8)',
							color: 'white',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							fontSize: '0.8rem',
						}}
						onClick={handleCloseClick}
					>
						<span>&#8249;</span>
					</div>
				)}
			</div>
		);
	}
}



export function GetNameAndEmpIDForIncident({ emails, Forwardnotes,Forwardtime}) {
	const { teamDetails } = useContext(UserContext);
	const [showFullName, setShowFullName] = useState(null);
	const [showScroll, setShowScroll] = useState(false);
	const [expanded, setExpanded] = useState(false);

	var email = [];
	if (emails === "" || emails === "-" || emails === "NA" || emails === " ") {
		emails = [];
	}
	else if (emails && emails.includes(',')) {
		email = emails.split(',').map((str) => str.trim());
		//console.log(email);
	}
	else {
		email.push(emails);
	}

	var ForwardNotesArray=[];
	ForwardNotesArray=Forwardnotes.split(',').map((str) => str.trim());
	ForwardNotesArray.push(Forwardnotes);
	


	var ForwardtimesArray=[];
	ForwardtimesArray=Forwardtime.split(',').map((str) => str.trim());
	ForwardtimesArray.push(Forwardtime);






	if (email.length == 1 || email.length == 0) {
		//console.log(email);
		return (
			<GetName email={email[0]} />
		);
	}
	else {
		const getEmailDetails = (email) => {
			const username = teamDetails.current[email]?.PeopleUserName || 'NA';
			const zuid = teamDetails.current[email]?.zuid || 'NA';
			//console.log("other fellows data-->",username, zuid);
			return { username, zuid };
		};

		const handleMouseEnter = (index) => {
			setShowFullName(index);
		};

		const handleMouseLeave = () => {
			setShowFullName(null);
		};

		const handleViewMoreClick = () => {
			setExpanded(true);
			if (email.length > 3) {
				setShowScroll(true);
			}
		};

		const handleCloseClick = () => {
			setExpanded(false);
			setShowScroll(false);
		};

		const renderAvatars = (emails) => {
			const emailList = expanded ? emails : emails.slice(0, 3);

			return (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						flexWrap: 'nowrap',
						overflowX: 'scroll',
						scrollBehavior: 'smooth',
						paddingRight: showScroll ? '21px' : '0',
					}}
				>
					{emailList.map((email, index) => {
						const { username, zuid } = getEmailDetails(email);

						return (
					
							<Tooltip key={index} title={

								<Fragment>
								<p>Name: {username}</p>
								<p>Forward notes: {ForwardNotesArray[index]}</p>
								<p>Forward Time: {ForwardtimesArray[index]}</p>
							  </Fragment>
								
							  }
							  >
							

								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										//   marginRight: '8px',
										cursor: 'pointer',
										'&:hover': {
											zIndex: 1,
										}
									}}
									onMouseEnter={() => handleMouseEnter(index)}
									onMouseLeave={handleMouseLeave}
								>
									<Avatar
										alt={username}
										src={`https://contacts.zoho.com/file?ID=${zuid}&exp=6000&fs=thumb`}
										sx={{
											width: 40,
											height: 40,
											marginBottom: '4px',
										}}
									/>
									{showFullName === index && (
										<div
											style={{
												position: 'absolute',
												top: '100%',
												backgroundColor: 'rgba(0, 0, 0, 0.8)',
												color: 'white',
												padding: '4px',
												borderRadius: '4px',
												fontSize: '0.8rem',
												whiteSpace: 'nowrap',
											}}
										>
											{username}
										</div>
									)}
								</div>
							</Tooltip>
						);
					})}
					{emails.length > 3 && !expanded && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								marginRight: '8px',
								cursor: 'pointer',
								'&:hover': {
									zIndex: 1,
								},
							}}
							onClick={handleViewMoreClick}
						>
							<Tooltip title="View More">
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										width: 40,
										height: 40,
										backgroundColor: 'gray',
										borderRadius: '50%',
									}}
								>
									<AddIcon sx={{ fontSize: 20, color: 'white' }} />
								</div>
							</Tooltip>
						</div>
					)}
					{/* <div style={{ width: '12px' }} /> */}
				</div>
			);
		};

		return (
			<div
				className="flex-container"
				style={{
					width: '170px',
					height: '50px',
					position: 'relative',
					borderRadius: '4px',
					padding: '4px',
					overflow: 'hidden',
				}}
			>
				{Array.isArray(email) ? (
					renderAvatars(email)
				) : (
					<Avatar
						alt={email}
						src={`https://contacts.zoho.com/file?ID=${getEmailDetails(email).zuid}&exp=6000&fs=thumb`}
						sx={{ width: 40, height: 40 }}
					/>
				)}
				{showScroll && (
					<div
						style={{
							position: 'absolute',
							top: '50%',
							right: '0',
							transform: 'translateY(-50%)',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: 'rgba(0, 0, 0, 0.8)',
							color: 'white',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							fontSize: '0.8rem',
						}}
						onClick={handleCloseClick}
					>
						<span>&#8249;</span>
					</div>
				)}
			</div>
		);
	}
}




export function EmployeeCard({ emails }) {
	const { teamDetails } = useContext(UserContext);

	var email = [];
	if (emails === "" || emails === "-" || emails === "NA" || emails === " ") {
		emails = [];
	}
	else if (emails && emails.includes(',')) {
		email = emails.split(',').map((str) => str.trim());
	}
	else {
		email.push(emails);
	}

	return (
		<EmployeeName email={email[0]} />
	);
}

export function EmployeeCards({ emails }) {
	const { teamDetails } = useContext(UserContext);

	var email = [];
	if (emails === "" || emails === "-" || emails === "NA" || emails === " ") {
		emails = [];
	}
	else if (emails && emails.includes(',')) {
		email = emails.split(',').map((str) => str.trim());
	}
	else {
		email.push(emails);
	}

	return (
		<EmployeeNamees email={email[0]} />
	);
}


function EmployeeName({ email }) {
	const { teamDetails } = useContext(UserContext);
	const { supportUsers } = useContext(UserContext);
	let zuid = null;
	let username = null;
	let truncatedUsername = null;

	try {

		if (teamDetails.current[email] === undefined) {
			username = supportUsers.current[email].Name;
			zuid = supportUsers.current[email].Zuid;
		} else {
			username = teamDetails.current[email].PeopleUserName;
			zuid = teamDetails.current[email].zuid;
		}


		if (username.length > 18) {
			truncatedUsername = username.slice(0, 16) + "...";
		}
		else {
			truncatedUsername = username;
		}
	}
	catch (e) {
		return null;
	}

	return (
		<div
			className="flex-container"
			style={{
				width: "100%",
				padding: "3px",
				paddingLeft: "4px",
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				scrollbarWidth: "none",
				display: 'flex',
				alignItems: 'center',
				justifyContent: "center"
			}}
		>
			<Avatar
				alt={username}
				src={`https://contacts.zoho.com/file?ID=${zuid}&exp=6000&fs=thumb`}
				sx={{ width: 30, height: 30 }}
			/>
			<div style={{ width: '100%', float: 'right' }}>
				<div style={{ fontSize: '0.7rem', textAlign: 'left', paddingLeft: '10px' }}>
					{username}
				</div>
			</div>
		</div>
	);
}

function EmployeeNamees({ email }) {
	const { teamDetails } = useContext(UserContext);
	const { supportUsers } = useContext(UserContext);
	let zuid = null;
	let username = null;
	let truncatedUsername = null;

	try {

		if (teamDetails.current[email] === undefined) {
			username = supportUsers.current[email].Name;
			zuid = supportUsers.current[email].Zuid;
		} else {
			username = teamDetails.current[email].PeopleUserName;
			zuid = teamDetails.current[email].zuid;
		}


		if (username.length > 18) {
			truncatedUsername = username.slice(0, 16) + "...";
		}
		else {
			truncatedUsername = username;
		}
	}
	catch (e) {
		return null;
	}

	return (
		<div
			className="flex-container"
			style={{
				width: "100%",
				padding: "0px",
				paddingLeft: "2px",
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				scrollbarWidth: "none",
				display: 'flex',
				alignItems: 'center',
				justifyContent: "center"
			}}
		>
			<Avatar
				alt={username}
				src={`https://contacts.zoho.com/file?ID=${zuid}&exp=6000&fs=thumb`}
				sx={{ width: 20, height: 20 }}
			/>
			<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
				<div style={{ fontSize: '0.9rem', textAlign: 'left', paddingLeft: '5px' }}>
					{username}
				</div>
			</div>
		</div>
	);
}

export function GetNameAdjustable({ email, length }) {
	if(length === undefined)
	{
		length = 16;
	}
	const { teamDetails } = useContext(UserContext);
	let username = "NA";
	let empID = "NA";
	let zuid = "NA";
	const [showFullName, setShowFullName] = useState(false);
	var longName = false;
	let truncatedUsername = "NA";
	try {
		username = teamDetails.current[email].PeopleUserName;
		zuid = teamDetails.current[email].zuid;
		if(username.length > length)
		{
			truncatedUsername = username.slice(0, length) + "...";
			longName = true;
		}
		else
		{
			truncatedUsername = username;
			longName = false;
		}
	}
	catch (e) {
		// console.log(e);
		return null;
	}

	const handleMouseEnter = () => {
		if(longName)
		{
			setShowFullName(true);
		}
	};

	const handleMouseLeave = () => {
		if(longName)
		{
			setShowFullName(false);
		}
	};

	return (
		<div
			className="flex-container"
			style={{
				width: "100%",
				height: '50px',
				whiteSpace: showFullName ? 'normal' : 'nowrap',
				overflow: showFullName ? 'auto' : 'hidden',
				textOverflow: 'ellipsis',
				scrollbarWidth: "none",
				display: 'flex',
        		alignItems: 'center'
			}}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<div style={{ paddingTop: '5px', float: 'left' }}>
				<Avatar
					alt={username}
					src={`https://contacts.zoho.com/file?ID=${zuid}&exp=6000&fs=thumb`}
					sx={{ width: 40, height: 40 }}
				/>
			</div>
			<div style={{ width: '100%', float: 'right' }
		}>
				<div style={{ height: '18px', fontSize: '0.775rem', textAlign: 'left', paddingLeft: '10px' }}>
					{/* {username} */}
					{showFullName && longName ? username : truncatedUsername}
				</div>
			</div>
			<br style={{ clear: 'both' }} />
		</div>
	);
}


export function GetNameAndEmpIDAdjustable({ emails, width, length }) {
	const { teamDetails } = useContext(UserContext);
	const [showFullName, setShowFullName] = useState(null);
	const [showScroll, setShowScroll] = useState(false);
	const [expanded, setExpanded] = useState(false);

	var email = [];
	//   const emailsamples ='vijayshankar.a@zohocorp.com,manoj.sakthivel@zohocorp.com,jayasuriya.bm@zohocorp.com,karthickkumar.r@zohocorp.com, hari.kandregula@zohocorp.com';
	// 		emails=emailsamples+emails;
	if (emails === "" || emails === "-" || emails === "NA" || emails === " ") {
		emails = [];
	}
	else if (emails && emails.includes(',')) {
		email = emails.split(',').map((str) => str.trim());
		//console.log(email);
	}
	else {

		email.push(emails);
	}

	if (email.length == 1 || email.length == 0) {
		return (
			<GetNameAdjustable email={email[0]} length={length}/>
		);
	}
	else {
		const getEmailDetails = (email) => {
			const username = teamDetails.current[email]?.PeopleUserName || 'NA';
			const zuid = teamDetails.current[email]?.zuid || 'NA';
			
			return { username, zuid };
		};

		const handleMouseEnter = (index) => {
			setShowFullName(index);
		};

		const handleMouseLeave = () => {
			setShowFullName(null);
		};

		const handleViewMoreClick = () => {
			setExpanded(true);
			if (email.length > 3) {
				setShowScroll(true);
			}
		};

		const handleCloseClick = () => {
			setExpanded(false);
			setShowScroll(false);
		};

		const renderAvatars = (emails) => {
			const emailList = expanded ? emails : emails.slice(0, 3);

			return (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						flexWrap: 'nowrap',
						overflowX: 'scroll',
						scrollBehavior: 'smooth',
						paddingRight: showScroll ? '21px' : '0',
					}}
				>
					{emailList.map((email, index) => {
						const { username, zuid } = getEmailDetails(email);

						return (
							<Tooltip key={index} title={username}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										//   marginRight: '8px',
										cursor: 'pointer',
										'&:hover': {
											zIndex: 1,
										},
									}}
									onMouseEnter={() => handleMouseEnter(index)}
									onMouseLeave={handleMouseLeave}
								>
									<Avatar
										alt={username}
										src={`https://contacts.zoho.com/file?ID=${zuid}&exp=6000&fs=thumb`}
										sx={{
											width: 40,
											height: 40,
											marginBottom: '4px',
										}}
									/>
									{showFullName === index && (
										<div
											style={{
												position: 'absolute',
												top: '100%',
												backgroundColor: 'rgba(0, 0, 0, 0.8)',
												color: 'white',
												padding: '4px',
												borderRadius: '4px',
												fontSize: '0.8rem',
												whiteSpace: 'nowrap',
											}}
										>
											{username}
										</div>
									)}
								</div>
							</Tooltip>
						);
					})}
					{emails.length > 3 && !expanded && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								marginRight: '8px',
								cursor: 'pointer',
								'&:hover': {
									zIndex: 1,
								},
							}}
							onClick={handleViewMoreClick}
						>
							<Tooltip title="View More">
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										width: 40,
										height: 40,
										backgroundColor: 'gray',
										borderRadius: '50%',
									}}
								>
									<AddIcon sx={{ fontSize: 20, color: 'white' }} />
								</div>
							</Tooltip>
						</div>
					)}
					{/* <div style={{ width: '12px' }} /> */}
				</div>
			);
		};

		return (
			<div
				className="flex-container"
				style={{
					width: width !== undefined ? '170px' : width,
					height: '50px',
					position: 'relative',
					borderRadius: '4px',
					padding: '4px',
					overflow: 'hidden',
				}}
			>
				{Array.isArray(email) ? (
					renderAvatars(email)
				) : (
					<Avatar
						alt={email}
						src={`https://contacts.zoho.com/file?ID=${getEmailDetails(email).zuid}&exp=6000&fs=thumb`}
						sx={{ width: 40, height: 40 }}
					/>
				)}
				{showScroll && (
					<div
						style={{
							position: 'absolute',
							top: '50%',
							right: '0',
							transform: 'translateY(-50%)',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: 'rgba(0, 0, 0, 0.8)',
							color: 'white',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							fontSize: '0.8rem',
						}}
						onClick={handleCloseClick}
					>
						<span>&#8249;</span>
					</div>
				)}
			</div>
		);
	}
}


String.prototype.toDate = function(format)
{
  var normalized      = this.replace(/[^a-zA-Z0-9]/g, '-');
  var normalizedFormat= format.toLowerCase().replace(/[^a-zA-Z0-9]/g, '-');
  var formatItems     = normalizedFormat.split('-');
  var dateItems       = normalized.split('-');

  var monthIndex  = formatItems.indexOf("mm");
  var dayIndex    = formatItems.indexOf("dd");
  var yearIndex   = formatItems.indexOf("yyyy");
  var hourIndex     = formatItems.indexOf("hh");
  var minutesIndex  = formatItems.indexOf("ii");
  var secondsIndex  = formatItems.indexOf("ss");

  var today = new Date();

  var year  = yearIndex>-1  ? dateItems[yearIndex]    : today.getFullYear();
  var month = monthIndex>-1 ? dateItems[monthIndex]-1 : today.getMonth()-1;
  var day   = dayIndex>-1   ? dateItems[dayIndex]     : today.getDate();

  var hour    = hourIndex>-1      ? dateItems[hourIndex]    : today.getHours();
  var minute  = minutesIndex>-1   ? dateItems[minutesIndex] : today.getMinutes();
  var second  = secondsIndex>-1   ? dateItems[secondsIndex] : today.getSeconds();

  return new Date(year,month,day,hour,minute,second);
};

export function days_between(date1, date2) {

    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(date1 - date2);

    // Convert back to days and return
    return Math.round(differenceMs / ONE_DAY);

}

export function getHourDifference(startDate, endDate) {
	// Calculate the time difference in milliseconds
	const timeDifference = endDate.getTime() - startDate.getTime();

	// Convert milliseconds to hours
	const hoursDifference = timeDifference / (1000 * 60 * 60);

	return hoursDifference;
}

export function getMinutesDifference(startDate, endDate) {
	// Calculate the time difference in milliseconds
	const timeDifference = endDate.getTime() - startDate.getTime();

	// Convert milliseconds to hours
	const hoursDifference = timeDifference / (1000 * 60);

	return hoursDifference;
}

export function MultiLevelDropdown ({ dritree, handleDriTreeItemClick, handleDriTreeButtonClose, driAnchor })
{
	const [closeMenu, setCloseMenu] = useState(false);

	const handleMenuClose = () => {
		setCloseMenu(true);
	};

	return (
		<Menu
			anchorEl={driAnchor}
			open={ driAnchor !== null}
			onClose={!closeMenu && handleDriTreeButtonClose}
			anchorOrigin={{
				vertical: "top",
				horizontal: "left",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			getcontentanchorel={null}
			
		>
			{dritree.map((item) => (
				(item === null) ? <></> :
				<DropdownItem key={item.Email} title={item.name} mail={item.Email} subItems={item.mentees} image={item.image} setCloseMenu={handleMenuClose} handleDriTreeItemClick={handleDriTreeItemClick} />
			))}
		</Menu>
	);
}

function DropdownItem ({ title, mail, subItems, image, setCloseMenu, handleDriTreeItemClick }) 
{
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(false);

	const handleClick = (event) => {
		if (event.target.closest('.arrow-icon')) {
			setAnchorEl(event.currentTarget);
			setOpen(true);
		} else {
			// console.log(mail);
			handleDriTreeItemClick({ name: title, Email: mail });
			setCloseMenu(true);
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const hasSubItems = Array.isArray(subItems) && subItems.length > 0;

	return (
		<>
			<MenuItem className={`dropdown-item ${!hasSubItems ? 'no-subitems' : ''}`} onClick={handleClick}>
				{hasSubItems && <ArrowLeftIcon className="arrow-icon" style={{ fontSize: 32 }} />}
				{image && (
					<Avatar
						alt={title}
						src={image}
						style={{ marginRight: 8, width: 32, height: 32 }}
					/>
				)}
				{title}
			</MenuItem>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				getcontentanchorel={null}
				keepMounted
			>
				{hasSubItems && subItems.map((subItem) => (
					<DropdownItem
						key={subItem.Email}
						title={subItem.name}
						mail={subItem.Email}
						subItems={subItem.mentees}
						image={subItem.image}
						setCloseMenu={setCloseMenu}
						handleDriTreeItemClick={handleDriTreeItemClick}
					/>
				))}
			</Menu>
		</>
	);
}

export function findMentees(userEmails, teamDetails)
{
    if(userEmails.length === 0)
    {
        return [];
    }

    const arr = [];

    for(const person in teamDetails.current)
    {
        if(userEmails.includes(teamDetails.current[person].ReportingTo))
        {
            arr.push(person);
        }
    }

    const newArr = arr.concat(findMentees(arr, teamDetails));

    return newArr;
}

export function findPeopleUnderServer(serverName, teamDetails)
{
	try {
		var peopleArr = [];
		for(var people in teamDetails.current) {
			var details = teamDetails.current[people];
			if([undefined, null, "", " "].includes(details.Servers)) {
				continue;
			}
			var serverArr = details.Servers.split(",");
			if(serverArr.includes(serverName)) {
				peopleArr.push(people);
				continue;
			}
		}
	} catch(e) {
		console.log(e);
	}
	

	return peopleArr;
}

export function isValidDateString(date_string) {
    return (new Date(date_string) !== "Invalid Date") && !isNaN(new Date(date_string));
}

const options = {
  year: 'numeric',
  month: 'short', // Short month name (e.g., "Jul" for July).
  day: '2-digit', // Two-digit day (e.g., "15").
  hour: '2-digit', // Two-digit hour (e.g., "12").
  minute: '2-digit', // Two-digit minute (e.g., "32").
  hour12: true, // Use 12-hour clock (AM/PM).
};

export function formatDate(date) {
	const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
	return formattedDate;
}
