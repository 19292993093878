/* eslint-disable react/prop-types */
import React, {useContext, useEffect, useRef, useState, useLayoutEffect} from "react";
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { Paper } from "@mui/material";
import { GetNameAndEmpID } from '../../../../util/Loading';
import { getHeatmapData } from "./Report2DataFetcher";
import { UserContext } from "../../../../contexts/UserContext";
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

export function PeopleDetailsSection({startTime, endTime, view, selection, timeRange}) {
	const theme = useTheme();
	const [loaded, setLoaded] = useState(null);
	const [dataArr, setDataArr] = useState([]);
	const [rawData, setRawData] = useState(null);
	const {teamDetails, loginUserDept} = useContext(UserContext);
	const currentLoading = useRef(null);

	useEffect(() => {
		currentLoading.current = startTime+""+endTime+""+view+""+selection+""+timeRange;
		setLoaded(currentLoading.current);
	}, [startTime, endTime, view, selection, timeRange]);

	useEffect(() => {
		var loadingNow = startTime+""+endTime+""+view+""+selection+""+timeRange;
		if(loaded !== true && loaded !== false) {
			getHeatmapData(startTime, endTime, view, selection, teamDetails, loginUserDept).then((response) => {
				if(loadingNow === currentLoading.current) {
					setRawData(response);
					setLoaded(true);
				}				
			}).catch((e) => {
				if(loadingNow === currentLoading.current) {
					setLoaded(false);
				}
			});
		}
	}, [loaded])

	// useEffect(() => {
	// 	setLoaded(null);
	// }, [startTime, endTime, view, selection, timeRange])

	// useEffect(async () => {
	// 	if(loaded === null) {
	// 		getHeatmapData(startTime, endTime, view, selection, teamDetails).then((response) => {
    //             setRawData(response);
	// 			setLoaded(true);
	// 		}).catch((e) => {
	// 			// console.log("Exception occured");
	// 			setLoaded(false);
	// 		});
	// 	}
	// }, [loaded]);

	useEffect(() => {
		if(rawData !== null) {
			var finalArr = [];
			var peopleArr = rawData[1];
			peopleArr.sort();
			var taskDetails = rawData[0][2];
			var issueDetails = rawData[0][4];

			// console.log(taskDetails);
			// console.log(issueDetails);

			for(var people of peopleArr) {
				var taskCount = 0;
				var taskOnTimeCount = 0;
				var taskDelayedCount = 0;
				var taskOnTimePercentage = 0;
				var issueCount = 0;

				for (var row of taskDetails) {
					if (row.TaskBreaker.DRI === people) {
						taskCount++;
						var dueDate = null;
						var completionDate = null;

						try {
							dueDate = row.TaskBreaker.TaskDueDate;
							if (![undefined, null, "", " "].includes(dueDate)) {
								dueDate = new Date(dueDate);
								if (!isValidDate(dueDate)) {
									dueDate = null;
								}
							}
						} catch (e) { }

						try {
							completionDate = row.TaskBreaker.TaskCompletionDate;
							if (![undefined, null, "", " "].includes(completionDate)) {
								completionDate = new Date(completionDate);
								if (!isValidDate(dueDate)) {
									dueDate = null;
								}
							}
						} catch (e) { }

						if (completionDate !== null) {
							if (dueDate === null) {
								taskOnTimeCount += 1;
							} else {
								if (completionDate <= dueDate) {
									taskOnTimeCount += 1;
								} else {
									taskDelayedCount += 1;
								}
							}
						}
					}
				}

				if(taskOnTimeCount + taskDelayedCount !== 0) {
					taskOnTimePercentage = taskOnTimeCount / (taskOnTimeCount + taskDelayedCount);
					taskOnTimePercentage *= 100;
					taskOnTimePercentage = taskOnTimePercentage.toFixed(0);
				} else {
					taskOnTimePercentage = NaN;
				}

				for(var row of issueDetails) {
					if(row.Tickets.ClaimedBy_AssingedTo !== null || row.Tickets.ClaimedBy_AssingedTo.includes(people) || row.Tickets.AllForwarded_From_to_To.includes(people)) {
						issueCount++;
					}
				}

				var taskValue = taskCount/(issueCount+taskCount);
				var issueValue = issueCount/(issueCount+taskCount);

				taskValue = isNaN(taskValue) ? "0" : taskValue.toFixed(2);
				issueValue = isNaN(issueValue) ? "0" : issueValue.toFixed(2);

				var taskRawData = [];

				for(var row of taskDetails) {
					if(row.TaskBreaker.DRI === people) {
						try {
							if([undefined, null, "", " "].includes(row.TaskBreaker.StatusDetails)) {
								if([undefined, null, "", " "].includes(row.TaskBreaker.TaskStartDate) || [undefined, null, "", " "].includes(row.TaskBreaker.TaskCompletionDate)) {
									continue;
								}
								var s = new Date(row.TaskBreaker.TaskStartDate);
								var e = new Date(row.TaskBreaker.TaskCompletionDate);
								taskRawData.push([s, e, 1]);
							} else {
								var arr = parseTaskStatusDetails(row.TaskBreaker.StatusDetails);
								if(Array.isArray(arr)) {
									taskRawData = taskRawData.concat(arr);
								}
							}
							
						} catch(e) {}

					}
				}

				var issueRawData = [];

				for(var row of issueDetails) {
					if(row.Tickets.ClaimedBy_AssingedTo !== null && row.Tickets.AllForwarded_From_to_To !== null && (row.Tickets.ClaimedBy_AssingedTo.includes(people) || row.Tickets.AllForwarded_From_to_To.includes(people))) {
						try {
							if(false && ([undefined, null, "", " "].includes(row.Tickets.ClaimedBy_AssingedTo) || [undefined, null, "", " "].includes(row.Tickets.AllForwarded_From_to_To))) {
								if([undefined, null, "", " "].includes(row.Tickets.CREATEDTIME) || [undefined, null, "", " "].includes(row.Tickets.MODIFIEDTIME)) {
									continue;
								}
								var s = new Date(row.Tickets.CREATEDTIME);
								var e = new Date(row.Tickets.MODIFIEDTIME);
								issueRawData.push([s, e, 1]);
							} else {
								var arr = parseTicketStatusDetails(row.Tickets.ClaimedBy_AssingedTo, row.Tickets.AllForwarded_From_to_To, row.Tickets.Closed_By, people);
								if(Array.isArray(arr)) {
									issueRawData = issueRawData.concat(arr);
								}
							}
							
						} catch(e) {} 

					}
				}

				var taskData = parseHeatMapData(taskRawData);

				for(var row of taskData) {
					row[2] = row[2].toFixed(2);
				}

				var issueData = parseHeatMapData(issueRawData);

				for(var row of issueData) {
					row[2] = row[2].toFixed(2);
				}

				var role = "Developer";
				try {
					role = teamDetails.current[people].Role;
				} catch(e) {}

				var obj = {
					"email": people,
					"role": role,
					"task": taskValue,
					"issue": issueValue,
					"taskData": taskData,
					"issueData": issueData,
					"onTimePercentage": taskOnTimePercentage
				}
				finalArr.push(obj);
				// console.log(obj);
			}
			// setDataArr([{
			// 	"email": "koveanthan.pon@zohocorp.com",
			// 	"task": 0.75,
			// 	"issue": 0.25,
			// 	"taskData": [],
			// 	"issueData": []
			// }])

			setDataArr(finalArr);
		}
	}, [rawData])


	function parseHeatMapData(startToEndArray) {

		// var startDate = new Date(startTime*1000);
		// var endDate = new Date(endTime*1000);

		let timeRangeBucket = {};
		var increment = 1*24*60*60;

		for(var i=startTime; i<=endTime; i+= increment) {
			var currentDate = new Date(i*1000).toDateString();
			timeRangeBucket[currentDate] = 0;
		}
		timeRangeBucket[new Date(endTime*1000).toDateString()] = 0;

		for(var item of startToEndArray) {
			var start = item[0];
			var end = item[1];
			var ratio = item[2];
			if(end < start) {
				var temp = start;
				start = end;
				end = temp;
			}
			
			// var dateRatio = increment/(getEpochFromDate(end)-getEpochFromDate(start)+increment);
			var dateRatio = 1/(dateDiffInDays(new Date(start), new Date(end))+1);
			if(isNaN(dateRatio)) {
				dateRatio = 0;
			} 

			// for(var i=start; i<=end; i+= increment) {
			// 	var currentDate = new Date(i).toDateString();
			// 	if(currentDate in timeRangeBucket) {
			// 		timeRangeBucket[currentDate] += (1*ratio*dateRatio);
			// 	}
			// } 
			for(var i=new Date(start); i<=new Date(end); i.setDate(i.getDate()+1)) {
				var currentDate = new Date(i).toDateString();
				if(currentDate in timeRangeBucket) {
					timeRangeBucket[currentDate] += (1*ratio*dateRatio);
				}
			} 
			
			if(new Date(end).toDateString() in timeRangeBucket) {
				timeRangeBucket[new Date(end).toDateString()] += (1*ratio*dateRatio);
			}
		}

		var keysSorted = Object.keys(timeRangeBucket).sort((a, b) => {

			var x = new Date(a);
			var y = new Date(b);

			if(x>y) {
				return 1; 
			} else if(y>x) {
				return -1;
			} else {
				return 0;
			}

		});

		var finalArr = [];

		for(var key of keysSorted) {
			var value = timeRangeBucket[key];

			finalArr.push([key, "", value]);
		}

		finalArr = groupBasedOnTimeRange(finalArr);

		return finalArr;
	}

	function groupBasedOnTimeRange(arr) {

		if(timeRange === "day") {
			return arr;
		} else if(timeRange === "week") {
			var newArr = [];
			var currentWeek = new Date(arr[0][0]).toDateString();
			var currentValue = 0;
			for(var item of arr) {
				var date = new Date(item[0]);
				if(date.getDay() === 0) {
					newArr.push([currentWeek, "",  currentValue]);
					currentWeek = date.toDateString();
					currentValue = 0;
				}
				var value = item[2];
				currentValue += value;
			}
			if(currentValue !== 0) {
				newArr.push([new Date(arr[arr.length-1][0]).toDateString(), "",  currentValue]);
			}
			return newArr;
		} else if(timeRange === "month") {
			var newArr = [];
			var currentMonth = new Date(arr[0][0]).toDateString();
			var currentValue = 0;
			for(var item of arr) {
				var date = new Date(item[0]);
				if(date.getDate() === 1) {
					newArr.push([currentMonth, "",  currentValue]);
					currentValue = 0;
					currentMonth = date.toDateString();
				}
				var value = item[2];
				currentValue += value;
			}
			if(currentValue !== 0) {
				newArr.push([new Date(arr[arr.length - 1][0]).toDateString(), "",  currentValue]);
			}
			return newArr;
		} else if(timeRange === "year") {
			var newArr = [];
			var currentValue = 0;
			var currentYear = new Date(arr[0][0]).toDateString();
			for(var item of arr) {
				var date = new Date(item[0]);
				var value = item[2];
				currentValue += value;
				if(date.getDate() === 1 && date.getMonth() === 0) {
					newArr.push([currentYear, "",  currentValue]);
					currentValue = 0;
					currentYear = date.toDateString();
				}
			}
			if(currentValue !== 0) {
				newArr.push([new Date(arr[arr.length - 1][0]).toDateString(), "",  currentValue]);
			}
			return newArr;
		}

	}

	function parseTaskStatusDetails(statusString) {
		const data = JSON.parse(`[${statusString.replace(/(^,)|(,$)/g, "")}]`);
		data.push({"today": new Date().toDateString()});
		var totalDaysWorked = 0;

		var arr = [];

		var startTime = null;

		for(var row of data) {
			var status = Object.keys(row)[0];
			var timeStamp = getEpochFromDate(row[status]);

			if(status === "Ongoing" || status === "InProgress") {
				if(startTime === null) {
					startTime = timeStamp;
				}
			} else {
				if(startTime === null) {
					continue;
				} else {
					var diff = dateDiffInDays(new Date(startTime*1000), new Date(timeStamp*1000));
					if(diff >= 0) {
						arr.push([new Date(startTime*1000), new Date(timeStamp*1000), diff+1]);
						totalDaysWorked += diff+1;
					}
					startTime = null;
				}
			}
		}

		for(var row of arr) {
			row[2] = row[2]/totalDaysWorked;
		}

		return arr;
	}

	function parseTicketStatusDetails(startTimeStamp, transitions, endTimeStamp, people) {
		var data = [];

		var name = startTimeStamp.substring(0, startTimeStamp.indexOf(' '));
		var time = startTimeStamp.substring(startTimeStamp.indexOf(' ')+1);
		time = new Date(time);

		if(![undefined, null, "", " "].includes(name) && ![undefined, null, "", " "].includes(time) && isValidDate(time)) {
			data.push([name, time]);
		}

		try {
			for(var row of transitions.split(",")) {
				var time = new Date(row.split("###")[1]);
				var name = row.split("###")[0];
				if(name !== null || name !== undefined) {
					name = name.split("=>>")[1];
					if(name !== undefined) {
						name = name.trim();
					}
					if(isValidDate(time)) {
						data.push([name, time]);
					}
				}
			}
		} catch(e) {}
		
		var name = endTimeStamp.substring(0, endTimeStamp.indexOf(' '));
		var time = endTimeStamp.substring(endTimeStamp.indexOf(' ')+1);
		time = new Date(time);

		if(![undefined, null, "", " "].includes(name) && ![undefined, null, "", " "].includes(time) && isValidDate(time)) {
			data.push([name+"+closed", time]);
		}

		data.push(["today", new Date()]);
		var totalDaysWorked = 0;

		var arr = [];

		var startTime = null;

		for(var row of data) {
			var name = row[0];
			var timeStamp = getEpochFromDate(row[1]);

			if(name === people) {
				if(startTime === null) {
					startTime = timeStamp;
				}
			} else {
				if(startTime === null) {
					continue;
				} else {
					var diff = dateDiffInDays(new Date(startTime*1000), new Date(timeStamp*1000));
					if(diff >= 0) {
						arr.push([new Date(startTime*1000), new Date(timeStamp*1000), diff+1]);
						totalDaysWorked += diff+1;
					}
					startTime = null;
				}
			}
		}

		for(var row of arr) {
			row[2] = row[2]/totalDaysWorked; 
		}

		return arr;

	}

	// var data = {
	// 	"email": "koveanthan.pon@zohocorp.com",
	// 	"task": 0.75,
	// 	"issue": 0.25,
	// 	"taskData": [],
	// 	"issueData": []
	// }

	dataArr.sort((a, b) => {
		if(a.email > b.email) {
			return 1;
		} else if (b.email > a.email) {
			return -1;
		} else {
			return 0;
		}
	})

	return (
		<Box sx={{
			width: "100%",
			height: "100%",
			justifyContent: "top"
		}}>
			{/* <Box style={{
				padding: "10px",
				fontWeight: 600,
				fontSize: "calc(1.0vw)",
				color: theme.palette.mode === "light" ? "black" : "white"
			}}>
				<span style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "left",
					flexDirection: "row"
				}}>
					Overview
				</span>
			</Box> */}
			<Box style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				height: "100%",
				gap: "10px",
				overflowY: "scroll",
				overscrollBehavior: "auto"
			}}>
				{/* {
					loaded === null ?
						<div style={{
							height: "100%",
							display: "flex",
							alignItems: "center"
						}}>
							<CircularProgress />
						</div>
						:
						loaded === false ?
							<div style={{
								height: "100%",
								display: "flex",
								alignItems: "center"
							}}>
								<h1>Unable to fetch :(</h1>
							</div>

							:
							dataArr.map((data, index) => {
								return <PeopleCard key={index} data={data} />
							})
				} */}

				{			
					loaded === true ?
						dataArr.map((data, index) => {
							var skip = true;
							for(var row of data.issueData) {
								if(row[2] !== "0.00") {
									skip = false;
								}
							}
							for(var row of data.taskData) {
								if(row[2] !== "0.00") {
									skip = false;
								}
							}
							if((data.role === "CEO" || data.role === "Manager") && skip) {
								return <></>;
							}
							return <PeopleCard key={index} data={data} />
						})
						:
						loaded === false ?
							<div style={{
								height: "100%",
								display: "flex",
								alignItems: "center"
							}}>
								<h1>Unable to fetch :(</h1>
							</div>
							:
							<div style={{
								height: "100%",
								display: "flex",
								alignItems: "center"
							}}>
								<CircularProgress />
							</div>
				}
			</Box>
		</Box>
	);
}

function PeopleCard({data}) {
	var taskDiv = useRef();
	var issueDiv = useRef();
	const theme = useTheme();
	const [taskClicked, setTaskClicked] = useState(false);
	const [issueClicked, setIssueClicked] = useState(false);

	const taskColors = [
		"rgb(8,153,73,1)",
		"rgb(8,153,73,0.8)",
		"rgb(8,153,73,0.65)",
		"rgb(8,153,73,0.5)",
		"rgb(8,153,73,0.3)",
		"rgb(8,153,73,0.15)",
		"rgb(8,153,73,0.04)"
	];

	const issueColors = [
		"rgb(228,37,39,1)",
		"rgb(228,37,39,0.8)",
		"rgb(228,37,39,0.65)",
		"rgb(228,37,39,0.5)",
		"rgb(228,37,39,0.3)",
		"rgb(228,37,39,0.15)",
		"rgb(228,37,39,0.04)"
	];

	function handleTaskClick() {
		setTaskClicked(!taskClicked);
	}

	function handleIssueClick() {
		return;	
		setIssueClicked(!issueClicked);
	}

	useLayoutEffect(() => {
		if(taskClicked === true) {
			return;
		}
		var chartJson = Object.assign({}, heatMapJson);
		chartJson.seriesdata.chartdata[0].data = [data.taskData];
		chartJson.legend.colors = taskColors;
		chartJson.canvas.fontColor = theme.palette.mode === "light" ? "black" : "white";
		chartJson.tooltip.fontColor = theme.palette.mode === "light" ? "black" : "white";
		chartJson.chart.plot.plotoptions.heatmap.strokeColor = theme.palette.mode === "light" ? "white" : "black";
		var chartObj = new window.$ZC.charts(taskDiv.current, chartJson);
	}, [theme.palette.mode, taskClicked]);

	useLayoutEffect(() => {
		if(issueClicked === true) {
			return;
		}
		var chartJson = Object.assign({}, heatMapJson);
		chartJson.seriesdata.chartdata[0].data = [data.issueData];
		chartJson.legend.colors = issueColors;
		chartJson.canvas.fontColor = theme.palette.mode === "light" ? "black" : "white";
		chartJson.tooltip.fontColor = theme.palette.mode === "light" ? "black" : "white";
		chartJson.chart.plot.plotoptions.heatmap.strokeColor = theme.palette.mode === "light" ? "white" : "black";
		var chartObj = new window.$ZC.charts(issueDiv.current, chartJson);
	}, [theme.palette.mode, issueClicked]);

	return (
		<Paper style={{
			padding: 10,
			width: "calc(100vw - 400px)"
		}}>
			<Box style={{
				height: "150px",
				display: "flex",
				flexDirection: "row",
				justifyContent: "center",
				alignItems: "center"
			}}>
				<div style={{
					width: "200px",
					padding: 10
				}}>
					<GetNameAndEmpID emails={data.email}/>
				</div>
				<div style={{
					width: "calc(100%-200px)",
					height: "100%",
					marginLeft: "20px"
				}}>
					<div style={{
						height: "49.7%",
						marginBottom: 5,
						display: "flex",
						flexDirection: "row"
					}}>
						<div style={{
							width: "100px",
							height: "90%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							textAlign: "center",
							backgroundColor: "rgb(8,153,73,0.2)",
							borderRadius: 5,
							padding: 2,
							cursor: "pointer",
							userSelect: "none"
						}} onClick={handleTaskClick}> 
							Tasks
							<div style={{
								fontSize: "2.3rem"
							}}>
								{(data.task * 100).toFixed(0)}%
							</div>
						</div>
						{
							taskClicked ? 
							<OnTimeDelayedPercentage onTimePercentage={data.onTimePercentage}/>
							:
							<>
								<div style={{
									height: "100%",
									minWidth: "calc(100vw - 720px)",
									display: "flex",
									alignItems: "center",
									justifyContent: "center"
								}} ref={taskDiv}>
								</div>
							</>
						}
					</div>
					<div style={{
						height: "49.7%",
						display: "flex",
						flexDirection: "row"
					}}>
						<div style={{
							width: "100px",
							height: "90%",
							display: "flex",
							flexDirection: "column",
							textAlign: "center",
							justifyContent: "center",
							backgroundColor: "rgb(228,37,39,0.2)",
							borderRadius: 5,
							padding: 2,
							cursor: "pointer",
							userSelect: "none"
						}} onClick={handleIssueClick}> 
							Issues
							<div style={{
								fontSize: "2.3rem"
							}}>
								{(data.issue * 100).toFixed(0)}%
							</div>
						</div>
						{
							issueClicked ?
								<OnTimeDelayedPercentage onTimePercentage={data.onTimePercentage}/>
								:
								<>
									<div style={{
										minWidth: "calc(100vw - 720px)",
										width: "calc(100vw-700px)",
										display: "flex",
										alignItems: "center",
										justifyContent: "center"
									}} ref={issueDiv}>
									</div>
								</>
						}
					</div>
				</div>
			</Box>
		</Paper>
	);
}

function OnTimeDelayedPercentage({onTimePercentage}) {
	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
		height: 20,
		borderRadius: 4,
		marginTop: 13,
		backgroundColor: "rgb(228,37,39,0.8) !important",
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 4,
			backgroundColor: "rgb(8,153,73,0.8)",
		}
	}));
	onTimePercentage = onTimePercentage + "";

	return (
		<div style={{
			height: "100%",
			width: "calc(100vw - 780px)",
			alignContent: "center",
			paddingLeft: "30px",
			paddingRight: "30px"
		}}>
			{
				onTimePercentage === "NaN" ?
					<span style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%"
					}}>
						<b>No Data :o</b>
					</span>
					:
					<>
						<BorderLinearProgress variant="determinate" value={onTimePercentage} />
						<div style={{
							display: "flex",
							flexDirection: "row",
							height: "45%",
							justifyContent: "space-around",
							alignItems: "center"
						}}>
							{
								onTimePercentage === "0" ?
									<></>
									:
									<div>
										<span style={{ color: "rgb(8,153,73,1)" }}>{"On Time: " + onTimePercentage + "%"}</span>
									</div>
							}
							{
								onTimePercentage === "100" ?
									<></>
									:
									<div>
										<span style={{ color: "rgb(228,37,39,1)" }}>{"Delayed: " + (100 - onTimePercentage) + "%"}</span>
									</div>
							}
						</div>
					</>
			}
		</div>
	);
}

var heatMapJson = {
	"seriesdata": {
		"chartdata": [
			{
				"type": "heatmap",
				"data": [
					[
						[
							"jun 2022",
							"",
							43
						],
						[
							"jul 2022",
							"",
							40
						],
						[
							"aug 2022",
							"",
							44
						],
						[
							"sep 2022",
							"",
							43
						],	
						[
							"oct 2022",
							"",
							603
						],
						[
							"nov 2022",
							"",
							19
						],
						[
							"dec 2022",
							"",
							47
						],
						[
							"jan 2023",
							"",
							117
						],
						[
							"feb 2023",
							"",
							700
						],
						[
							"mar 2023",
							"",
							700
						],	
						[
							"apr 2023",
							"",
							600
						],
						[
							"may 2023",
							"",
							700
						],
						[
							"jun 2023",
							"",
							500
						],
						[
							"jul 2023",
							"",
							23
						],
						[
							"aug 2023",
							"",
							31
						],
						[
							"sep 2023",
							"",
							43
						],
						[
							"oct 2023",
							"",
							40
						],
						[
							"nov 2023",
							"",
							44
						],
						[
							"dec 2023",
							"",
							43
						],	
						[
							"jan 2023",
							"",
							63
						],
						[
							"feb 2023",
							"",
							63
						]
					]
				]
			}
		]
	},
	"metadata": {
		"axes": {
			"x": [
				0
			],
			"y": [
				[
					1
				]
			],
			"clr": [
				2
			],
			"tooltip": [
				0,
				2
			]
		},
		"columns": [
			{
				"dataindex": 0,
				"columnname": "Time",
				"datatype": "ordinal"
			},
			{
				"dataindex": 1,
				"columnname": "",
				"datatype": "ordinal"
			},
			{
				"dataindex": 2,
				"columnname": "WorkDone",
				"datatype": "numeric"
			}
		]
	},
	"tooltip": {
		"enabled": true,
		"shadow": "1px 1px 5px black",
		"opacity": 1,
		"fontWeight": "bold",
		"fontColor": "black",
		"borderColor": "black"
	},
	"legend": {
		"colors": [
			"#C3282E",
			"#E54A50",
			"#FF695A",
			"#FEB370",
			"#FFE785",
			"#C4DFB2",
			"#95CF95"
		],
		"colorBand": {
			"type": "continuous",
			// "ranges":[[10,5000],[5,10],[1,5],[0.5,1],[0.11,0.5],[0.01,0.1],[0,0]]
			"stops": [
				5000,
				10,
				5,
				3,
				1,
				0.001,
				0
			]
		},
		"hAlign": "center",
		"vAlign": "bottom",
		"marginTop": 0,
		"filter": {
			"slider": {
				"shadow": null,
				"type": "triangleUp"
			},
			"track": {
				"fillColor": "rgba(5,5,5,0.7)"
			}
		},
		"itemHoverStyle": {
			"type": "enlarge"
		}
	},
	"chart": {
		"axes": {
			"xaxis": {
				"label": {
					"show": false
				},
				"ticklabel": {
					"fontSize": 8,
					"marginLeft": 0,
					"marginRight": 0
				},
				"axisline": {
					"show": false
				}
			},
			"yaxis": [
				{
					"label": {
						"show": false
					},
					"grid": {
						"color": "transparent"
					},
					"ticklabel": {
						"fontSize": 1
					},
					"axisline": {
						"show": false
					}
				}
			]
		},
		"plot": {
			"plotoptions": {
				"heatmap": {
					"animation": {
						"type": "sizingRandom",
						"easingType": "cubic"
					},
					"events": {
						"mousemove": (e, d) => {}
					},
					"strokeColor": "black",
					"borderRadius": 3,
					"datalabels": {
						"show": false
					}
				}
			}
		}
	},
	"canvas": {
		"fontFamily": "poppins",
		"title": {
			"show": false
		},
		"background": {
			"alpha": 0
		},
		"events": {
			"onerror": null
		}
	}
};

function getEpochFromDate(dateString) {
	var date = new Date(dateString);

	var epochTime = Math.floor(date.getTime() / 1000);

	return epochTime;
}

function dateDiffInDays(a, b) {
	const _MS_PER_DAY = 1000 * 60 * 60 * 24;
	const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
	const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

	return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

function isValidDate(d) {
	return d instanceof Date && !isNaN(d);
}