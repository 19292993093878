/* eslint-disable react/prop-types */ 
/* esline-disable react/jsx-key */
import React from "react";
import { useEffect, useRef, useState, useContext } from "react";
import { UserContext } from "../../../../../contexts/UserContext";
import { useTheme } from '@mui/material/styles';
import { Box, ButtonGroup, Skeleton, Tab } from "@mui/material";
import { ReportContext } from "../newReportGeneral/NewReport";
import { getMilestoneRows, getTicketRows } from "../reportSection1/ReportSection1DataFetcher"; 

import AirIcon from '@mui/icons-material/Air';
import { PieChart, SunBurst } from "../newReportGeneral/NewReportUtilComponents";

export function ReportSection12() {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            width: "100%",
            height: "98%",
            gap: "10px"
        }}>
             <Box sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.1rem",
                fontWeight: "bold"
            }}>
                Ticket Priority
            </Box>
            <ReportBody/>
        </Box>
    );
}   


function ReportBody({setExpanded}) {

    const {
        subjectType, 
        setSubjectType, 
        subject, 
        setSubject, 
        timeFrame, 
        setTimeFrame, 
        time, 
        setTime,
        timeRange,
        setTimeRange
    } = useContext(ReportContext);
    const theme = useTheme();
    
    const [loaded, setLoaded] = useState(undefined);
    // const [milestoneRows, setMilestoneRows] = useState(undefined);
    const [chartData, setChartData] = useState(undefined);
    const {teamDetails, loginUserDept} = useContext(UserContext);

    useEffect(() => {
        const controller = new AbortController();
        async function pullMilestoneRows() {

            getTicketRows(timeRange, subjectType, subject, teamDetails, loginUserDept).then((response) => {
                if(!controller.signal.aborted) {
                    if(response.length !== 0) {
                        const priorityMap = {};
                        const data = [];
                        response.forEach(item => {
                            if(item.Priority_1 in priorityMap) {
                                var obj = priorityMap[item.Priority_1];
                                if(item.Status in obj) {
                                    obj[item.Status] += 1;
                                } else {
                                    obj[item.Status] = 1;
                                }
                            } else {
                                priorityMap[item.Priority_1] = {};
                                priorityMap[item.Priority_1][item.Status] = 1;
                            } 
                        });

                        for(var key in priorityMap) {
                            var obj = priorityMap[key];
                            var arr = [];
                            for(var k in obj) {
                                arr.push([k, obj[k]]);
                            }
                            data.push({
                                "seriesname": key,
                                "data": arr
                            })
                        }

                        setChartData(data);

                        setLoaded(true);
                    } else {
                        setLoaded(false);
                    }
                }
            }).catch((error) => {
                if(!controller.signal.aborted) {
                    setLoaded(false);
                }
            });
        }

        setLoaded(undefined);
        pullMilestoneRows();

        return () => {
            controller.abort();
        };

    }, [timeRange, subjectType,subject]);

    if(loaded === undefined) {
        return (
            <Skeleton variant={"rounded"} height={"98%"} width={"100%"} animation="wave" />
        );
    } else if(loaded === false) {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%"
            }}>
                <Box>
                    <AirIcon sx={{
                        fontSize: "6rem",
                        color: theme.palette.mode === "light" ? "rgb(20, 20, 20, 0.06)": "rgb(200, 200, 200, 0.06)"
                    }} />
                </Box>
                <Box sx={{
                    fontSize: "0.7rem",
                    color: theme.palette.mode === "light" ? "rgb(10, 10, 10, 0.3)": "rgb(200, 200, 200, 0.4)"
                }}>
                    Nothing found
                </Box>
            </Box>
        );
    } else {
        return (
            <Box  sx={{
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                overflowY: "scroll",
                boxSizing: "border-box",
                backgroundColor: theme.palette.mode === 'dark' ? 'rgb(24,30,35)' : '#FAFAFA',
                borderRadius: "12px"
            }}
            >
                <SunBurst title={"Ticket Priority"} columnName1={"col1"} columnName2={"col2"} data={chartData} palette={"countries"}/>
            </Box>
        );
    }
}