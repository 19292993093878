/* eslint-disable react/prop-types */
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
    Box, 
    Skeleton,
    Button,
    ButtonGroup, 
    Dialog, 
    DialogActions, 
    DialogContent,
    TableContainer, 
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Select,
    MenuItem,
    TextField,
    Alert
} from "@mui/material";
import { Snackbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import { executeQuery, queryDB } from "../../../../util/QueryDB";
import { useTheme } from '@mui/material';
import { UserContext } from "../../../../contexts/UserContext";
import { GetNameAndEmpID } from "../../../../util/Loading";
import { Fade } from '@mui/material';
import { admin_external_ticket_users_get_query_1 } from "../../../../catalystDB/queryGenerator";



export default function ReportConfiguration() {
    const [rows, setRows] = useState(null);
    const {loginUserDept} = useContext(UserContext);
    const theme = useTheme();

    useEffect(() => {

        async function getConfRows() {
            const result = await queryDB(admin_external_ticket_users_get_query_1());
            var arr = [];

            for(var r of result) {
                arr.push([r.SupportMemberForm.Name, r.SupportMemberForm.Email_ID, r.SupportMemberForm.Zuid]);
            }

            setRows(arr);
        }

        getConfRows();

    }, []);

    if(rows === null) {
        return (
            <Box className='page-content-2'>
                <Skeleton variant="rectangular" width={"100%"} height={35} />
                <br></br>
                <Skeleton variant="rectangular" width={"100%"} sx={{ height: "calc(100vh - 175px)" }} />
            </Box>
        );
    }

    return (
        <Box className='page-content-2'>
            {rows.length === 0 ? (
               <Fade in={true}>
               <div style={{ color: theme.palette.background.text }}>
             
                   <h1>No Tasks Found :(</h1>
               </div>
              </Fade>
            ) : (
                <>
                    
                    <Content rows={rows} setRows={setRows} />
                </>
            )}
        </Box>
    );
}



function Content({rows, setRows}) {
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('');
    return (
        <>
        <div className="mytasks-table">
            <TableContainer className="mytasks-table-container" sx={{
                flex: 1
            }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow className="mytasks-table-head">
                            <TableCell align="center" sx={{maxWidth: "5px"}}>S.No.</TableCell>
                            <TableCell align="center">Name</TableCell>
                            <TableCell align="center">MailId</TableCell>
                            <TableCell align="center">ZUID</TableCell>
                    
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows.map((row, index) => {
                                return <Row index={index} row={row} rows={rows} setRows={setRows} key={index} alertMessage={alertMessage} alertOpen={alertOpen} setAlertMessage={setAlertMessage} setAlertOpen={setAlertOpen} alertSeverity={alertSeverity} setAlertSeverity={setAlertSeverity}/>
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
            <div>
                <Snackbar
                    open={alertOpen}
                    autoHideDuration={3000} // Adjust the duration as needed
                    onClose={() => setAlertOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                >
                    <Alert
                        onClose={() => setAlertOpen(false)}
                        severity={alertSeverity} 
                    >
                        {alertMessage}
                    </Alert>
                </Snackbar>
            </div>
        </>
    );
}



function Row({index, row, rows, setRows , alertMessage, alertOpen, setAlertMessage, setAlertOpen, alertSeverity, setAlertSeverity}) {
    const theme = useTheme();
    const [isHovered, setIsHovered] = useState(false);

    // const [alertOpen, setAlertOpen] = useState(false);
    // const [alertMessage, setAlertMessage] = useState('');

    return (
        <TableRow
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
                '& > *': { borderBottom: 'unset' },
                backgroundColor: theme.palette.background.tableRow,
                '&:hover': {
                    backgroundColor: theme.palette.background.tableRowHover + " !important"
                }
            }}
        >
            <TableCell align="center">{index+1}</TableCell>
            <TableCell align="center">{row[0]}</TableCell>
            <TableCell align="right" style={{ width: "5vw" }}><GetNameAndEmpID emails={row[1]} /> </TableCell>
            <TableCell align="center"> 
               {row[2]}
               {/* <GetNameAndEmpID emails={row[2]} /> */}
            </TableCell>
        </TableRow>
    );
}





