/* eslint-disable dot-notation */
import { StyledEngineProvider } from "@mui/material";
import { queryDB } from "../../../../util/QueryDB";
import { useContext } from "react";
import { UserContext } from "../../../../contexts/UserContext";
import { report_1_data_fetcher_get_query_1, report_1_data_fetcher_get_query_10, report_1_data_fetcher_get_query_11, report_1_data_fetcher_get_query_12, report_1_data_fetcher_get_query_13, report_1_data_fetcher_get_query_14, report_1_data_fetcher_get_query_15, report_1_data_fetcher_get_query_16, report_1_data_fetcher_get_query_17, report_1_data_fetcher_get_query_18, report_1_data_fetcher_get_query_19, report_1_data_fetcher_get_query_2, report_1_data_fetcher_get_query_20, report_1_data_fetcher_get_query_21, report_1_data_fetcher_get_query_22, report_1_data_fetcher_get_query_23, report_1_data_fetcher_get_query_24, report_1_data_fetcher_get_query_25, report_1_data_fetcher_get_query_26, report_1_data_fetcher_get_query_27, report_1_data_fetcher_get_query_28, report_1_data_fetcher_get_query_29, report_1_data_fetcher_get_query_3, report_1_data_fetcher_get_query_30, report_1_data_fetcher_get_query_31, report_1_data_fetcher_get_query_32, report_1_data_fetcher_get_query_33, report_1_data_fetcher_get_query_4, report_1_data_fetcher_get_query_5, report_1_data_fetcher_get_query_6, report_1_data_fetcher_get_query_7, report_1_data_fetcher_get_query_8, report_1_data_fetcher_get_query_9 } from "../../../../catalystDB/queryGenerator";

const section1DataCache = {};

export function getSection1Data1(currentSelect, loginUserDept) {
    if("s1d1"+currentSelect in section1DataCache) {
        return section1DataCache["s1d1"+currentSelect];
    }
    return new Promise(async (resolve, reject) => {
        var query1 = report_1_data_fetcher_get_query_1(loginUserDept);
        var query2 = report_1_data_fetcher_get_query_2(loginUserDept);
        if(currentSelect !== "Default" && typeof currentSelect !== "object") {
            query1 = report_1_data_fetcher_get_query_3(currentSelect, loginUserDept);
            query2 = report_1_data_fetcher_get_query_4(currentSelect, loginUserDept);
        }
        try {
            // console.log(query1)
            // console.log(query2)
            var result1 = await queryDB(query1);
            var result2 = await queryDB(query2);
            var statusObj = {};
            var ind = 0;
            var RMapCount = 0;
            var OpenCount = 0;
            var OngoingCount = 0;
            var InProgressCount = 0;
            var OnHoldCount = 0;
            var TobeTestedCount = 0;
            var InReviewCount = 0;
            var DelayedCount = 0;
            var CancelledCount = 0;
            var ClosedCount =0;
            
            for (var row of result1) {
                statusObj[result2[ind].TaskBreaker.TaskStatus] = row.TaskBreaker.TaskStatus;
                ind++;
                if (row.TaskBreaker.TaskStatus == "Ongoing") {
                    OngoingCount = row.TaskBreaker['COUNT(TaskStatus)'];
                    // console.log(OngoingCount) 
                }
                else if (row.TaskBreaker.TaskStatus == "RMap") {
                    RMapCount = row.TaskBreaker['COUNT(TaskStatus)'];
                    // console.log(RMapCount) 
                }
                else if (row.TaskBreaker.TaskStatus == "Open") {
                    OpenCount = row.TaskBreaker['COUNT(TaskStatus)'];
                }
                else if (row.TaskBreaker.TaskStatus == "In Progress") {
                    InProgressCount = row.TaskBreaker['COUNT(TaskStatus)'];
                }
                else if (row.TaskBreaker.TaskStatus == "On Hold") {
                    OnHoldCount = row.TaskBreaker['COUNT(TaskStatus)'];
                }
                else if (row.TaskBreaker.TaskStatus == "To be Tested") {
                    TobeTestedCount = row.TaskBreaker['COUNT(TaskStatus)'];
                }
                else if (row.TaskBreaker.TaskStatus == "In Review") {
                    InReviewCount = row.TaskBreaker['COUNT(TaskStatus)'];
                }
                else if (row.TaskBreaker.TaskStatus == "Delayed") {
                    DelayedCount = row.TaskBreaker['COUNT(TaskStatus)'];
                }
                else if (row.TaskBreaker.TaskStatus == "Cancelled") {
                    CancelledCount = row.TaskBreaker['COUNT(TaskStatus)'];
                }
                else if (row.TaskBreaker.TaskStatus == "Closed") {
                    ClosedCount = row.TaskBreaker['COUNT(TaskStatus)'];
                }
                // console.log(row)
            }
            // console.log( statusObj)
            // console.log(result2)
            var dataArr = [
                [
                    "RMap",
                    statusObj["RMap"] === undefined ? "0" : RMapCount
                ],
                [
                    "Open",
                    statusObj["Open"] === undefined ? "0" : OpenCount
                ],
                [
                    "Ongoing",
                    statusObj["Ongoing"] === undefined ? "0" :OngoingCount
                ],
                [
                    "In Progress",
                    statusObj["In Progress"] === undefined ? "0" : InProgressCount
                ],
                [
                    "On Hold",
                    statusObj["On Hold"] === undefined ? "0" : InReviewCount
                ],
                [
                    "To be Tested",
                    statusObj["To be Tested"] === undefined ? "0" : TobeTestedCount
                ],
                [
                    "In Review",
                    statusObj["In Review"] === undefined ? "0" : InReviewCount
                ],
                [
                    "Delayed",
                    statusObj["Delayed"] === undefined ? "0" : DelayedCount
                ],
                [
                    "Cancelled",
                    statusObj["Cancelled"] === undefined ? "0" : CancelledCount
                ],
                [
                    "Closed",
                    statusObj["Closed"] === undefined ? "0" : ClosedCount
                ]
            ];
            section1DataCache["s1d1"+currentSelect] = dataArr;
            // console.log(dataArr)
            return resolve(dataArr);
        } catch (e) {
            return reject(e);
        }
    });
}

export function getSection1Data2(currentSelect, loginUserDept) {
    if("s1d2"+currentSelect in section1DataCache) {
        return section1DataCache["s1d2"+currentSelect];
    }
    return new Promise(async (resolve, reject) => {
        var query1 =  report_1_data_fetcher_get_query_5(loginUserDept);
        var query2 =  report_1_data_fetcher_get_query_6(loginUserDept);
        if(currentSelect !== "Default" && typeof currentSelect !== "object") {
            query1 = report_1_data_fetcher_get_query_7(currentSelect, loginUserDept);
            query2 = report_1_data_fetcher_get_query_8(currentSelect, loginUserDept);
        }
        try {
            var result1 = await queryDB(query1);
            var result2 = await queryDB(query2);
            var statusObj = {};
            var ind = 0;
            var OpenCount = 0;
            var OngoingCount = 0;
            var OnHoldCount = 0;;
            var TasklistCompletedCount =0;
            for (var row of result1) {
                statusObj[result2[ind].TaskDetails.TasklistStatus] = row.TaskDetails.TasklistStatus;
                ind++;
                if (row.TaskDetails.TasklistStatus == "Ongoing") {
                    OngoingCount = row.TaskDetails['COUNT(TasklistStatus)'];
                }
                else if (row.TaskDetails.TasklistStatus == "Open") {
                    OpenCount = row.TaskDetails['COUNT(TasklistStatus)'];
                }
                else if (row.TaskDetails.TasklistStatus == "On Hold") {
                    OnHoldCount = row.TaskDetails['COUNT(TasklistStatus)'];
                }
                else if (row.TaskDetails.TasklistStatus == "TasklistCompleted") {
                    TasklistCompletedCount = row.TaskDetails['COUNT(TasklistStatus)'];
                }
            }
            var dataArr = [
                [
                    "Open",
                    statusObj["Open"] === undefined ? "0" : OpenCount
                ],
                [
                    "Ongoing",
                    statusObj["Ongoig"] === undefined ? "0" : OngoingCount
                ],
                [
                    "OnHold",
                    statusObj["OnHold"] === undefined ? "0" : OnHoldCount
                ],
                [
                    "Completed",
                    statusObj["TasklistCompleted"] === undefined ? "0" : TasklistCompletedCount
                ]
            ];
            section1DataCache["s1d2"+currentSelect] = dataArr;
            return resolve(dataArr);
        } catch (e) {
            return reject(e);
        }
    });
}

export function getSection1Data3(currentSelect, loginUserDept) {
    if("s1d3"+currentSelect in section1DataCache) {
        return section1DataCache["s1d3"+currentSelect];
    }
    return new Promise(async (resolve, reject) => {
        var query1 =  report_1_data_fetcher_get_query_9(loginUserDept);
        var query2 = report_1_data_fetcher_get_query_10(loginUserDept);
        if(currentSelect !== "Default" && typeof currentSelect !== "object") {
            query1 = report_1_data_fetcher_get_query_11(currentSelect, loginUserDept);
            query2 = report_1_data_fetcher_get_query_12(currentSelect, loginUserDept);
        }
        try {
            var result1 = await queryDB(query1);
            var result2 = await queryDB(query2);
           
            var dataArr = [
                [
                    "Ongoing",
                    result2.length
                ],
                [
                    "Completed",
                    result1.length
                ]
            ];
            section1DataCache["s1d3"+currentSelect] = dataArr;
            return resolve(dataArr);
        } catch (e) {
            return reject(e);
        }
    });
}

const section2DataCache = {};

export function getSection2Data(server, loginUserDept) {
    if(server in section2DataCache) {
        return new Promise((resolve, reject) => resolve(section2DataCache[server]));
    }
    return new Promise(async(resolve, reject) => {
        try {
            if(server !== "ALL") {
                var result1 = await queryDB(report_1_data_fetcher_get_query_13(server, loginUserDept));
                var result2 = await queryDB(report_1_data_fetcher_get_query_14(server, loginUserDept));    
            } else {
                var result1 = await queryDB(report_1_data_fetcher_get_query_15(loginUserDept));
                var result2 = await queryDB(report_1_data_fetcher_get_query_16(loginUserDept));    
            }

            var workingDRIs = [];
            var driArr = [];

            for(var row of result2) {
                var dri = row.TaskBreaker.DRI;
                if(dri !== null && dri !== " " && dri !== "")
                {
                    workingDRIs.push(dri);
                }
            }

            var ind=0;
            for(var row of result1) {
                var dri = row.TaskBreaker.DRI;
                if(dri !== null && dri !== " " && dri !== "" && !workingDRIs.includes(dri)) {
                    driArr.push(dri);
                }
            }

            section2DataCache[server] = driArr; 
            resolve(driArr);
        } catch(e) {
            // console.log(e);
            reject(e);
        }
    });
}

const section3DataCache = {};

export function getSection3Data(server, loginUserDept) {

    async function getData1() {
        var arr = [];
        try {
            var result1 = await queryDB(report_1_data_fetcher_get_query_17(server, loginUserDept));
            var result2 = await queryDB(report_1_data_fetcher_get_query_18(server, loginUserDept));

            var arr = [["On Time", 0],["Delayed", 0]];
            result1.map((item, index) => {
                var key = item.TaskBreaker.TaskDueDateExtended;
                if(key === "Yes") {
                    arr[1] = ["Delayed", result2[index].TaskBreaker.TaskDueDateExtended];
                } else if(key === "No"){
                    arr[0] = ["On Time", result2[index].TaskBreaker.TaskDueDateExtended];
                }       
            });
            if(arr[0][1]=== 0 && arr[1][1] === 0) {
                arr = [];
            }
        } catch(e) {
            // console.log(e);
        }
        
        return arr;
    }

    async function getData2() {
        var arr = [];
        try {
            var result1 = await queryDB(report_1_data_fetcher_get_query_19(server, loginUserDept));
            var result2 = await queryDB(report_1_data_fetcher_get_query_20(server, loginUserDept) );
    
            var arr = [["On Time", 0],["Delayed", 0]];
            result1.map((item, index) => {
                var key = item.TaskDetails.TasklistDueDateExtended;
                if(key === "Yes") {
                    arr[1] = ["Delayed", result2[index].TaskDetails.TasklistDueDateExtended];
                } else if(key === "No"){
                    arr[0] = ["On Time", result2[index].TaskDetails.TasklistDueDateExtended];
                }       
            });
            if(arr[0][1]=== 0 && arr[1][1] === 0) {
                arr = [];
            }
        } catch(e) {
            // console.log(e);
        }
        return arr;
    }

    async function getData3() {
        var arr = [];
        try {
            var result1 = await queryDB(report_1_data_fetcher_get_query_21(server, loginUserDept));
            var result2 = await queryDB(report_1_data_fetcher_get_query_22(server, loginUserDept));
    
            var arr = [["On Time", 0],["Delayed", 0]];
            result1.map((item, index) => {
                var key = item.TaskDetails.MilestoneDueDateExtended;
                if(key === "Yes") {
                    arr[1] = ["Delayed", result2[index].TaskDetails.MilestoneDueDateExtended];
                } else if(key === "No"){
                    arr[0] = ["On Time", result2[index].TaskDetails.MilestoneDueDateExtended];
                }       
            });
            if(arr[0][1]=== 0 && arr[1][1] === 0) {
                arr = [];
            }
        } catch(e) {
            // console.log(e);
        }
        return arr;
    }

    async function fetchAllData() {
        var count = 0;
        var finalArr = [[], [], []];
        return new Promise((resolve, reject) => {
            getData1().then((result) => {
                count += 1;
                finalArr[0] = result;
                if(count === 3) {
                    resolve(finalArr);
                }
            }).catch((e) => {
                return reject(e);
            })
            getData2().then((result) => {
                count += 1;
                finalArr[1] = result;
                if(count === 3) {
                    resolve(finalArr);
                }
            }).catch((e) => {
                return reject(e);
            })
            getData3().then((result) => {
                count += 1;
                finalArr[2] = result;
                if(count === 3) {
                    resolve(finalArr);
                }
            }).catch((e) => {
                return reject(e);
            })
        });
    }

    if(server in section3DataCache) {
        return section3DataCache[server];
    }
    return new Promise(async(resolve, reject) => {
        try {
            var finalArr = await fetchAllData();
            section3DataCache[server] = finalArr;
            return resolve(finalArr);
        } catch(e) {
            return reject(e);
        }
    });
}

const section4DataCache = {};

export function getSection4Data(server, loginUserDept) {

    var currentDate = new Date();
    var today = currentDate.toISOString();

    var yesterdayDate = new Date();
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);
    var yesterday = yesterdayDate.toISOString();

    async function getData1() {
        var result = await queryDB(report_1_data_fetcher_get_query_23(today, yesterday, server, loginUserDept));
        return result[0].TaskBreaker.TaskStatus;
    }

    async function getData2() {
        var result = await queryDB(report_1_data_fetcher_get_query_24(today, yesterday, server, loginUserDept));
        return result[0].TaskDetails.TasklistStatus;
    }

    async function getData3() {
        var result = await queryDB(report_1_data_fetcher_get_query_25(today, yesterday, server, loginUserDept));
        return result[0].TaskDetails.MilestoneStatus;
    }

    async function fetchAllData() {
        var count = 0;
        var dataArr = ['-', '-', '-'];
        return new Promise((resolve, reject) => {
            getData1().then((result) => {
                count += 1;
                dataArr[0] = result;
                if(count === 3) {
                    return resolve(dataArr);
                }
            }).catch((e) => {
                reject(e);                
            });
            getData2().then((result) => {
                count += 1;
                dataArr[1] = result;
                if(count === 3) {
                    return resolve(dataArr);
                }
            }).catch((e) => {
                reject(e);
            });
            getData3().then((result) => {
                count += 1;
                dataArr[2] = result;
                if(count === 3) {
                    return resolve(dataArr);
                }
            }).catch((e) => {
                reject(e);
            });
        })
       
    }
    
    if(server in section4DataCache) {
        return section4DataCache[server];
    }
    return new Promise(async (resolve, reject) => {
        try {
            var result = await fetchAllData();
            section4DataCache[server] = result;
            return resolve(result);
        } catch(e) {
            reject(e);
        }
    });
}

const section5DataCache = {}; 

export function getSection5Data(server, loginUserDept) {

    var currentDate = new Date();
    var today = currentDate.toISOString();

    var lastWeekDate = new Date();
    lastWeekDate.setDate(lastWeekDate.getDate() - 7);
    var lastWeek = lastWeekDate.toISOString();

    async function getData1() {
        var result = await queryDB(report_1_data_fetcher_get_query_26(today, lastWeek, server, loginUserDept) );
        return result[0].TaskBreaker.TaskStatus;
    }

    async function getData2() {
        var result = await queryDB(report_1_data_fetcher_get_query_27(today, lastWeek, server, loginUserDept));
        return result[0].TaskDetails.TasklistStatus;
    }

    async function getData3() {
        var result = await queryDB(report_1_data_fetcher_get_query_28(today, lastWeek, server, loginUserDept));
        return result[0].TaskDetails.MilestoneStatus;
    }

    async function fetchAllData() {
        var count = 0;
        var dataArr = ['-', '-', '-'];
        return new Promise((resolve, reject) => {
            getData1().then((result) => {
                count += 1;
                dataArr[0] = result;
                if(count === 3) {
                    return resolve(dataArr);
                }
            }).catch((e) => {
                reject(e);                
            });
            getData2().then((result) => {
                count += 1;
                dataArr[1] = result;
                if(count === 3) {
                    return resolve(dataArr);
                }
            }).catch((e) => {
                reject(e);
            });
            getData3().then((result) => {
                count += 1;
                dataArr[2] = result;
                if(count === 3) {
                    return resolve(dataArr);
                }
            }).catch((e) => {
                reject(e);
            });
        })
       
    }
    
    if(server in section5DataCache) {
        return section5DataCache[server];
    }
    return new Promise(async (resolve, reject) => {
        try {
            var result = await fetchAllData();
            section5DataCache[server] = result;
            return resolve(result);
        } catch(e) {
            reject(e);
        }
    });
}

const serverCache = {};
export function getNestedReportServerData(server, loginUserDept) {
    return new Promise(async (resolve, reject) => {
        var dateString = new Date().toISOString().split("T")[0];
        if(server in serverCache) {
            return resolve(serverCache[server]);
        }
        try {
            const result1 = await queryDB(report_1_data_fetcher_get_query_29(dateString, server, loginUserDept));
            const result2 = await queryDB(report_1_data_fetcher_get_query_30(dateString, server, loginUserDept));

            const result = [result1[0].TaskDetails.MilestoneName, result2[0].TaskDetails.MilestoneName];
            serverCache[server] = result;
            return resolve(result);
        } catch(e) {
            return reject(e);
        }
       
    })
}

const milestoneUnderServerCache = {};
export function getNestedReportMilestoneUnderServer(server, loginUserDept) {
    return new Promise(async (resolve, reject) => {
        if(server in milestoneUnderServerCache) {
            return resolve(milestoneUnderServerCache[server]);
        }
        try {
            const result = await queryDB(report_1_data_fetcher_get_query_31(server, loginUserDept));
            milestoneUnderServerCache[server] = result;
            return resolve(result);
        } catch(e) {
            return reject(e);
        }
    })
}

const tasklistUnderMilestoneCache = {};
export function getNestedReportTasklistUnderMilestone(milestone, loginUserDept) {
    return new Promise(async (resolve, reject) => {
        if(milestone in tasklistUnderMilestoneCache) {
            return resolve(tasklistUnderMilestoneCache[milestone]);
        }
        try {
            const result = await queryDB(report_1_data_fetcher_get_query_32(milestone, loginUserDept));
            tasklistUnderMilestoneCache[milestone] = result;
            // console.log(result);
            return resolve(result);
        } catch(e) {
            return reject(e);
        }
    })
}

const taskUnderTasklistCache = {};
export function getNestedReportTaskUnderTasklist(milestone, tasklist, loginUserDept) {
    return new Promise(async (resolve, reject) => {
        if(tasklist in taskUnderTasklistCache) {
            return resolve(taskUnderTasklistCache[tasklist]);
        }
        try {
            // console.log(`SELECT * FROM TaskBreaker WHERE MilestoneName='${milestone}' AND TaskListName='${tasklist}' AND TaskDueDate_ IS NOT NULL`);
            const result = await queryDB(report_1_data_fetcher_get_query_33(milestone, tasklist, loginUserDept));
            taskUnderTasklistCache[tasklist] = result;
            // console.log(result);
            return resolve(result);
        } catch(e) {
            return reject(e);
        }
    })
}