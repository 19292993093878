import React, { useEffect, useState, useContext } from 'react';
import { DashboardContext } from '../../dashboardMain/stateStorage/DashboardStateStorage';
import { useTheme } from '@emotion/react';

import {
    Box
} from '@mui/material';

import { getChartData } from './gridItem_12_dataProcessor';

import { PackedBubble } from '../../../../../util/components/PackedBubble/PackedBubble';
import { PACKED_BUBBLE_CHART_COLORS_DARK, PACKED_BUBBLE_CHART_COLORS_LIGHT } from '../../dashboardMain/DashboardUtil';

export function GridItem_12() {

    const { getDashboardContext, catalystDB, startTime } = useContext(DashboardContext);
    const { currentSelectedGridItem } = useContext(getDashboardContext());

    const isOpen = currentSelectedGridItem === "GridItem_12";
    const theme = useTheme();

    // red = #FF2519, #BF1F13
    // blue = #308DFF, #0700DE
    // yellow = #EBC417, #A88C08
    // green = #0DFF68, #04B725
    // purple = #BF19FF, #A313C2
    // cyan = #00E1FF, #009AC4
    // orange = #EB7317, #CC6E14
    // lime = #B8FF0D, #71BF0A

    var colors = PACKED_BUBBLE_CHART_COLORS_LIGHT;
    if(theme.palette.mode === "dark") {
        colors = PACKED_BUBBLE_CHART_COLORS_DARK;
    }

    if (catalystDB === undefined) {
        return null;
    } else if (catalystDB.TaskBreaker === null) {
        return null;
    }

    const chartData = getChartData(catalystDB, startTime);

    return (
        <Box sx={{
            height: "100%",
            width: "100%",
            boxSizing: "border-box"
        }}>
            <PackedBubble data={chartData}
                title={"Team Member's Contribution"}
                colors={colors}
                open={isOpen}
                columnName1={"User"}
                columnName2={"Completed"}
                columnName3={"Server"}
            />
        </Box>
    );

}