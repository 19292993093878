import React, {useContext, useEffect, useState} from 'react';
import { 
    Box, Button, CircularProgress, Divider, Tooltip, useTheme
} from '@mui/material';
import { UserContext } from '../../../../../contexts/UserContext';
import { getSection2Data } from '../ReportDataFetcher';
import { EmployeeCard, GetNameAndEmpID } from '../../../../../util/Loading';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';

export function FreeResources({currentSelect}) {
    const [data, setData] = useState(undefined);
    const {loginUserDept, teamDetails, configuration} = useContext(UserContext);
    const theme = useTheme();
    const server = currentSelect === "Default" ? "ALL" : currentSelect;
    const [freeResources, setFreeResources] = useState({});
    var rootUser = "srinivasan@zohocorp.com";
    try {
        rootUser = configuration.current[`department.rootUsers`][loginUserDept.current];
    } catch(e) {}

    useEffect(() => {
        setData(undefined);
        getSection2Data(server, loginUserDept).then((data) => {
            if(data.length === 0) {
                setData([]);
            } else {
                try {
                    const dt = data.filter(d => d.includes("@zohocorp.com") && !d.includes(","));
                    setData(dt)
                    try {
                        const newMap = {};
                        const mentees = getMentees(rootUser, teamDetails, loginUserDept);
                        if (mentees === undefined || mentees.length === 0) {
                            return;
                        }
                        var menteesMap = mentees.map(mentee => {
                            return {
                                name: mentee,
                                count: getFreeUsersCount(mentee, dt, teamDetails, loginUserDept)
                            };
                        });
                        newMap[0] = menteesMap;
                        setFreeResources(newMap);
                    } catch(e) {}
                } catch(e) {
                    setData(data);
                }
            }
        }).catch(error => {
            setData(null);
        });
    }, [currentSelect]);

    useEffect(() => {
        const lastNestedContainer = document.getElementsByClassName("last-nested-container")[0];
        if(lastNestedContainer !== undefined) {
            lastNestedContainer.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
    }, [freeResources]);

    if(data === undefined) {
        return <CenteredItem item={<CircularProgress />} />;
    } else if(data === null) {
        return <CenteredItem item={"Something went wrong try again..."} />;
    } else if(data.length === 0) {
        return <CenteredItem item={"looks like everyone's busy building something..."} />;
    }

    // console.log(getFreeUsersCount("koveanthan.pon@zohocorp.com", data, teamDetails))

    function handleItemClick(event, user, nestedLevel) {
        const siblings = document.getElementsByClassName(event.currentTarget.className);
        for (const sibling of siblings) {
            sibling.style.backgroundColor = theme.palette.mode === "light" ? "hsla(230, 30%, 90%, 0.4)" : "hsla(230, 30%, 10%, 0.3)";
        }
        event.currentTarget.style.backgroundColor = theme.palette.mode === "light"  ? "hsla(230, 40%, 75%, 0.5)" : "hsla(235, 50%, 15%, 0.5)";

        var newMap = {};
        for(var i=0; i<= nestedLevel; i++) {
            newMap[i] = freeResources[i];
        }
        const mentees = getMentees(user, teamDetails, loginUserDept);
        if(mentees === undefined || mentees.length === 0) {
            setFreeResources(newMap);
            return;
        }
        var menteesMap = mentees.map(mentee => {
            return {
                name: mentee,
                count: getFreeUsersCount(mentee, data, teamDetails, loginUserDept)
            };
        });
        newMap[Number.parseInt(nestedLevel)+1] = menteesMap;
        setFreeResources(newMap);
    }

    return (
        <Box sx={{        
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            marginBottom: "10px"
        }}>
            <Box sx={{
                flex: 1,
                lineHeight: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <h2 style={{lineHeight: 1}}>Free Resources</h2>
                <Tooltip title={"The Number listed for each person is the free resource count which also includes their mentees."}>
                    <Button sx={{borderRadius: "100px", padding: 0.3}}
                    >
                        <HelpOutlineRoundedIcon fontSize='small'/>
                    </Button>
                </Tooltip>
            </Box>
            <Divider flexItem></Divider>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                boxSizing: "border-box",
                overflowX: "scroll",
                flex: 20,
                width: "100%"
            }}>
                {
                    Object.keys(freeResources).map((item) => {
                        return (
                            <>
                                <Box sx={{
                                    minWidth: "32%",
                                    height: "95%",
                                    margin: "5px",
                                    padding: "5px",
                                    boxSizing: "border-box",
                                    display: "flex",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    flexDirection: "column",
                                    gap: "5px",
                                    overflowY: "scroll"
                                }}
                                    className={Number.parseInt(item) === Object.keys(freeResources).length -1 ? "last-nested-container" : ""}
                                    key={`nested-item-container-${item}`}
                                >
                                    {
                                        freeResources[item].map((user, index) => {
                                            return (
                                                <>
                                                    <Box sx={{
                                                        backgroundColor: theme.palette.mode === "light" ? "hsla(230, 30%, 90%, 0.4)" : "hsla(230, 30%, 10%, 0.3)",
                                                        borderRadius: "5px",
                                                        width: "100%",
                                                        minHeight: "60px",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        paddingLeft: "10px",
                                                        boxSizing: "border-box",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={(event) => handleItemClick(event, user.name, item)}
                                                    className={`nested-item-container-${theme.palette.mode}-${item}`}
                                                    key={`nested-item-container-${theme.palette.mode}-${item}-${index}-${user.name}}`}
                                                    >
                                                        <Box sx={{
                                                            flex: 5,

                                                        }}>
                                                            <EmployeeCard emails={user.name} />
                                                        </Box>
                                                        <Divider orientation="vertical" />
                                                        <Box sx={{
                                                            flex: 1,
                                                            fontSize: "2rem",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            color: "hsl(5, 60%, 50%)"
                                                        }}>
                                                            {user.count}
                                                        </Box>
                                                    </Box>
                                                    <Divider />
                                                </>
                                            );
                                        })
                                    }
                                </Box>
                                <Divider orientation="vertical" flexItem/>
                            </>
                        )
                    })
                }
            </Box>
        </Box>
    );
}

function CenteredItem({item}) {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%"
        }}>
            {item}
        </Box>
    );
}

function getFreeUsersCount(user, data, teamDetails, loginUserDept) {
    var count = 0;
    var mentees = [user];
    const MAX_ITERATION_COUNT = 15;
    var cur = 0;

    while(cur < MAX_ITERATION_COUNT && mentees.length !== 0) {
        var newArr = [];
        mentees.map(usr => {
            count += data.includes(usr) && isDeveloper(usr, teamDetails) ? 1 : 0;
            const ments = getMentees(usr, teamDetails, loginUserDept);
            if (Array.isArray(ments)) {
                newArr = [...newArr, ...ments];
            }
        });
        mentees = newArr;
        cur++;
    }

    return count;
}

function isDeveloper(user, teamDetails) {
    try {   
        return teamDetails.current[user].Role === "Developer";
    } catch(e) {
        return false;
    }
}

function isInDepartment(user, loginUserDept, teamDetails) {
    try {   
        return teamDetails.current[user].department === loginUserDept.current;
    } catch(e) {
        return false;
    }
}

function getMentees(user, teamDetails, loginUserDept) {
    const reportingMap = getReportingMap(teamDetails, loginUserDept);
    return reportingMap[user];
}

function getReportingMap(teamDetails, loginUserDept) {
    var reportingMap = {};

    for (const [user, details] of Object.entries(teamDetails.current)) {
        if(user.includes("@zohocorp.com") && isInDepartment(user, loginUserDept, teamDetails) && user !== null && user !== "") {
            const reportingTo = details.ReportingTo;
            if(reportingTo in reportingMap) {
                reportingMap[reportingTo].push(user);
            } else {
                reportingMap[reportingTo] = [user];
            }
        }
    }

    return reportingMap;
}