import React, { useState, useRef, useLayoutEffect, useEffect, useContext } from 'react';
import { Button, ButtonGroup, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import Select from '@mui/material/Select';
import { queryDB } from '../../../util/QueryDB';
import { format, addMonths } from 'date-fns';
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { Checkbox } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Snackbar from '@mui/material/Snackbar';
import { deleteMultipleRows, updateRolesInCreator } from './RoadmapDataUpdater.js';

import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import { Link } from "react-router-dom";

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Skeleton from '@mui/material/Skeleton';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';

import Alert from '@mui/material/Alert';
import { keyframes } from '@mui/system';

import ArrowCircleUpTwoToneIcon from '@mui/icons-material/ArrowCircleUpTwoTone';
import ArrowCircleDownTwoToneIcon from '@mui/icons-material/ArrowCircleDownTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import Brightness1TwoToneIcon from '@mui/icons-material/Brightness1TwoTone';
import HexagonIcon from '@mui/icons-material/Hexagon';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';

import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RoadMapLinkCards from './RoadMapLinkCards.js'

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { group_by_order, find_row_span } from './../../../util/GroupingFunctions.js';
import { GetNameAndEmpID, MultiLevelDropdown } from '../../../util/Loading.js';
import './Roadmap.css';
import { getFromTaskBreaker, roadmapTransformFunction } from './RoadmapDataFetcher.js';
import { getFromTeamDetails } from '../taskBreaker/TBDataFetcher.js';

import { Fade } from '@mui/material';
import { useTheme } from '@mui/material';

import { UserContext } from '../../../contexts/UserContext.js';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { TabStateContext } from '../../../contexts/TabStateContext.js';
import { it } from 'date-fns/locale';

import { lazyFetch, lazyLoader } from '../../../util/QueryDB.js';
import { CheckBox } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import { roadmap_component_get_query_1, roadmap_component_get_query_2, roadmap_component_get_query_3, roadmap_component_get_query_4, roadmap_component_get_query_5 } from '../../../catalystDB/queryGenerator.js';
import { OtherDRI } from '../../../util/OtherDRI.js';

function MyTasks() {
	const [searchText, setSearchText] = useState("");

	const [initialRows, setInitialRows] = useState([]);
	const [hasData, setHasData] = useState(null);

	const [driList, setDriList] = useState([]);
	const [driTree, setDriTree] = useState([]);
	const [setData, setShowData] = useState(false);

	const [serversList, setServersList] = useState([]);
	
	const { userDetails, teamDetails, allServers, loginUserDept, configuration,GlobalServer } = useContext(UserContext);
	const user_email = userDetails.current.auth.email_id;
	const user_role = teamDetails.current[user_email].Role;
	const localStorageFetchedServer = localStorage.getItem('selectedServerRoadMap');
	// const userserver = teamDetails.current[user_email].Servers.split(",").filter(server => {
	// 	return allServers.includes(server);
	// }).join(",");

	var userserver = (GlobalServer === "unknown" || GlobalServer === undefined) ? (localStorageFetchedServer !== null) ? localStorageFetchedServer : teamDetails.current[user_email].Servers.split(",").filter(server => allServers.includes(server)).join(",") :  GlobalServer;
	var allServer = teamDetails.current[user_email].Servers.split(",").filter(server => allServers.includes(server)).join(",");
	// const userserver =(GlobalServer === "unknown" || GlobalServer === undefined) ?  teamDetails.current[user_email].Servers.split(",").filter(server => allServers.includes(server)).join(",")
    // : GlobalServer;
	// console.log(userserver)

	const userserverArr = userserver.split(",");
	const [sortBy, setSortBy] = useState("Default");
	const [filterBy, setFilterBy] = useState(userserverArr);
	const [selectMode, setSelectMode] = useState(null);
	const [creatorRowIDs, setCreatorRowIDs] = useState([]);

	const toggle = useRef(false);

	const compareFunctions = {
		"Server": serverCompareFunction,
		"Priority": priorityCompareFunction,
		"Status": statusCompareFunction,
		"TaskName": tasknameCompareFunction,
		"DRI": driCompareFunction
	};

	const query_String_Suffix = "('" + userserver.replaceAll(",", "','") + "')";
	// var queryString = null;

	// if (user_role === "CEO") {
	// 	queryString = `SELECT * FROM TaskBreaker WHERE ReportName = 'Roadmap_Form_Report' AND TaskStatus != 'Closed' AND Tasklist_created_for_roadmap is null ORDER BY Component`;
	// } else {
	// 	queryString = `SELECT * FROM TaskBreaker WHERE ReportName = 'Roadmap_Form_Report' AND Component IN ${query_String_Suffix} AND TaskStatus !='Closed' AND Tasklist_created_for_roadmap is null ORDER BY Component`;
	// }

	var queryFilter = ` ( Component in ('${filterBy.join("','")}') ) `;
	var queryString = roadmap_component_get_query_1(queryFilter, loginUserDept);

	// generator
	const [lazyLoad, setLazyLoad] = useState(lazyLoader(queryString, roadmapTransformFunction));

	// var initialRows = [];

	useEffect(() => {
		async function run() {
            const user_email = userDetails.current.auth.email_id;

		try {
			await setDriDropDown(setDriList, setDriTree, userDetails, teamDetails, loginUserDept.current, configuration);

			await setServerDropDown(setServersList, userDetails, teamDetails, allServers);
		}
		catch (error) {
		}

		if (lazyLoad !== null && ((GlobalServer === "unknown" || GlobalServer === undefined) && localStorageFetchedServer === null)) {
			try {
				// const queryStringSuffix = "('" + userserver.replaceAll(",", "','") + "')";

				// const result = await getFromTaskBreaker(`SELECT * FROM TaskBreaker WHERE DRI= 'jayasuriya.bm@zohocorp.com'`);
				// const result = await getFromTaskBreaker(queryString);
				const result = await lazyLoad.next();
				if (result.value.content === undefined) {
					setHasData(false);
					setLazyLoad(null);
				} else if (result.value.isLast) {
					setHasData(true);
					setInitialRows(result.value.content);
					setLazyLoad(null);
				} else {
					setHasData(true);
					setInitialRows(result.value.content);
					// console.log(result.value.content)
				}


				// setInitialRows(result);

			}
			catch (error) {
				setHasData(false);
			}
		}
		}
		run();
	}, []);

	useEffect(() => {
		if(GlobalServer === "unknown" || GlobalServer === undefined){
			setFilterBy(userserverArr)
		}
		else if(GlobalServer != "unknown" && GlobalServer != undefined){
			setFilterBy([GlobalServer])
		}
		// console.log("Hi")
		// console.log("hi")
	  }, [GlobalServer]);

	// var rowsNew = group_by_order(initialRows, [1, 3], 0);
	var rowsNew = group_by_order(initialRows, [1], 0);

	const hookFlag = useRef(true);
	useEffect(() => {
		async function run() {
			if(hookFlag.current) {
				hookFlag.current = false;
				return;
			}
			// setSearchText("");
			if (filterBy.includes("Default")) {
				try {
					// setHasData(null);
					// const result = await getFromTaskBreaker(queryString);
					// setHasData(true);
					// setInitialRows(result);
					setHasData(null);
					queryFilter = `( Component in ${query_String_Suffix} )`;
	
					const query = roadmap_component_get_query_2(queryFilter, loginUserDept);
					
					// const query = `SELECT * FROM TaskBreaker WHERE (${queryFilter}) AND ReportName= 'Roadmap_Form_Report' AND TaskStatus not in ('Closed', 'Cancelled') AND Tasklist_created_for_roadmap is null ORDER BY Component`;
					const newLazyLoader = lazyLoader(query, roadmapTransformFunction);
					setLazyLoad(newLazyLoader);
					const result = await newLazyLoader.next();
					if (result.value.content === undefined) {
						setHasData(false);
						setLazyLoad(null);
					} else if (result.value.isLast) {
						setHasData(true);
						setInitialRows(result.value.content);
						setLazyLoad(null);
					} else {
						setHasData(true);
						setInitialRows(result.value.content);
						console.log(result.value.content)
					}
				}
				catch (error) {
					setHasData(false);
					setLazyLoad(null);
				}
			}
			else if (filterBy.includes("Combined Roadmap")) {
				try {
					// setHasData(null);
					// const result = await getFromTaskBreaker(queryString);
					// setHasData(true);
					// setInitialRows(result);
					setHasData(null);
					const query = roadmap_component_get_query_3(loginUserDept);
					const newLazyLoader = lazyLoader(query, roadmapTransformFunction);
					setLazyLoad(newLazyLoader);
					const result = await newLazyLoader.next();
					if (result.value.content === undefined) {
						setHasData(false);
						setLazyLoad(null);
					} else if (result.value.isLast) {
						setHasData(true);
						setInitialRows(result.value.content);
						setLazyLoad(null);
					} else {
						setHasData(true);
						setInitialRows(result.value.content);
					}
				}
				catch (error) {
					setHasData(false);
					setLazyLoad(null);
				}
			} 
			else {
				// var filters = [];
				// for(var item of filterBy) {
				// 	if(item.includes("@zohocorp.com")) {
				// 		filters.push(`Email= '${item}'`);
				// 	} else {
				// 		filters.push(`Component= '${item}'`);
				// 	}
				// }
				// var queryFilter = filters.join(" OR ");
	
				var driFilter = [];
				var componentFilter = [];
				for (var item of filterBy) {
					if (item.includes("@zohocorp.com")) {
						driFilter.push(item);
					} else {
						 if(GlobalServer === "unknown" || GlobalServer === undefined){
							 componentFilter.push(item);
						  }
						  else{
							  componentFilter.push(GlobalServer);
						   }	
					}
				}
				var queryFilter = "";
				if (componentFilter.length === 0 && driFilter.length !== 0) {
					queryFilter = ` ( Email in ('${driFilter.join("','")}') ) `;
				} else if (driFilter.length === 0 && componentFilter.length !== 0) {
					queryFilter = ` ( Component in ('${componentFilter.join("','")}') ) `;
				} else if (componentFilter.length !== 0 && driFilter.length !== 0) {
					queryFilter = ` ( Email in ('${driFilter.join("','")}') AND  Component in ('${componentFilter.join("','")}') ) `;
				}
	
				var qString = roadmap_component_get_query_4(queryFilter, loginUserDept);
	
				setHasData(null);
				const newLazyLoader = lazyLoader(qString, roadmapTransformFunction);
				setLazyLoad(newLazyLoader);
				const result = await newLazyLoader.next();
				if (result.value.content === undefined) {
					setHasData(false);
					setLazyLoad(null);
				} else if (result.value.isLast) {
					setHasData(true);
					setInitialRows(result.value.content);
					// console.log(result.value.content)
					setLazyLoad(null);
				} else {
					setHasData(true);
					setInitialRows(result.value.content);
					// console.log(result.value.content)
				}
	
				// try {
				// 	setHasData(null);
				// 	const result = await getFromTaskBreaker(`SELECT * FROM TaskBreaker WHERE ${queryFilter} AND ReportName='Roadmap_Form_Report' AND TaskStatus != 'Closed' AND Tasklist_created_for_roadmap is null ORDER BY Component`);
				// 	setHasData(true);
				// 	setInitialRows(result);
				// 	setLazyLoad(null);
				// }
				// catch (error) {
				// 	setHasData(false);
				// }
				
				// if (!currentFilter.includes("@zohocorp.com")) {
				// 	try {
				// 		setHasData(null);
				// 		const result = await getFromTaskBreaker(`SELECT * FROM TaskBreaker WHERE Component = '${currentFilter}' AND ReportName='Roadmap_Form_Report' AND TaskStatus != 'Closed' AND Tasklist_created_for_roadmap is null ORDER BY Component`);
				// 		setHasData(true);
				// 		setInitialRows(result);
				// 		setLazyLoad(null);
				// 	}
				// 	catch (error) {
				// 		setHasData(false);
				// 	}
				// } else {
				// 	try {
				// 		setHasData(null);
				// 		const result = await getFromTaskBreaker(`SELECT * FROM TaskBreaker WHERE Email=  '${currentFilter}' AND ReportName='Roadmap_Form_Report' AND TaskStatus != 'Closed' AND Tasklist_created_for_roadmap is null ORDER BY Component`);
				// 		setHasData(true);
				// 		setInitialRows(result);
				// 		setLazyLoad(null);
				// 		//setCloseMenu(true);
				// 	}
				// 	catch (error) {
				// 		setHasData(false);
				// 	};
				// }
			}
		}
		run();
	}, [filterBy]);

	useEffect(() => {
		var rowsNew = group_by_order(initialRows, [1], 0);
		if (sortBy !== "Default") {
			rowsNew = rowsNew.sort(compareFunctions[sortBy]);
		}
		setRows(rowsNew);
	}, [initialRows, sortBy]);

	const [rows, setRows] = useState(rowsNew);

	window.addEventListener('beforeunload', () => {
        localStorage.removeItem('selectedServerRoadMap');
    });


	// var content = <div style={{textAlign: "center", marginTop: "35vh"}}><LoadingComponent /></div>;
	// var content = <div style={{textAlign: "center", marginTop: "35vh"}}><LoadingComponent /></div>;

	var content = (
		<>
				<MyTasksTopBar rowsCurrent={rowsNew} setSearchText={setSearchText} drisList={driList} drisTree={driTree} serversList={serversList}
						setHasData={setHasData} initialRows={initialRows} setInitialRows={setInitialRows} userEmail={user_email} userRole={user_role} queryStringSuffix={query_String_Suffix}
						lazyLoad={lazyLoad} setLazyLoad={setLazyLoad} sortBy={sortBy} setSortBy={setSortBy} filterBy={filterBy} setFilterBy={setFilterBy} setData={setData} setShowData={setShowData}
						searchText={""} selectMode={selectMode} setSelectMode={setSelectMode} creatorRowIDs={creatorRowIDs} setCreatorRowIDs={setCreatorRowIDs} rows={rows} setRows={setRows} userserverArr={userserverArr} GlobalServer={GlobalServer} allServer={allServer}/>
			{/* <Skeleton variant="rectangular" width={1600} height={60} />; */}
			<Skeleton variant="rectangular" width={"100%"} sx={{ flex: 1}} />
		</>
	);

	const theme = useTheme();

	if (initialRows.length !== 0 && hasData) {
		content = (
			<Fade in={true}>
				<div>
					<MyTasksTopBar rowsCurrent={rowsNew} setRows={setRows} setSearchText={setSearchText} drisList={driList} drisTree={driTree} serversList={serversList}
						setHasData={setHasData} initialRows={initialRows} setInitialRows={setInitialRows} userEmail={user_email} userRole={user_role} queryStringSuffix={query_String_Suffix}
						lazyLoad={lazyLoad} setLazyLoad={setLazyLoad} sortBy={sortBy} setSortBy={setSortBy} filterBy={filterBy} setFilterBy={setFilterBy} setData={setData} setShowData={setShowData}
						searchText={searchText} selectMode={selectMode} setSelectMode={setSelectMode} creatorRowIDs={creatorRowIDs} setCreatorRowIDs={setCreatorRowIDs} rows={rows} userserverArr={userserverArr} GlobalServer={GlobalServer} allServer={allServer}/>
					<MyTasksTable rows={rows} searchText={searchText} rowsCurrent={rowsNew} setRows={setRows} lazyLoad={lazyLoad} setInitialRows={setInitialRows} initialRows={initialRows}
						setLazyLoad={setLazyLoad} filterBy={filterBy} setFilterBy={setFilterBy} setData={setData} setShowData={setShowData} selectMode={selectMode} setSelectMode={setSelectMode}
						creatorRowIDs={creatorRowIDs} setCreatorRowIDs={setCreatorRowIDs}/>
				</div>
			</Fade>
		);
	}
	else if (hasData === false) {
		content = (
			<Fade in={true}>
				<div style={{ color: theme.palette.background.text }}>

					<MyTasksTopBar rowsCurrent={rowsNew} setRows={setRows} setSearchText={setSearchText} drisList={driList} drisTree={driTree} serversList={serversList}
						setHasData={setHasData} initialRows={initialRows} setInitialRows={setInitialRows} userEmail={user_email} userRole={user_role} queryStringSuffix={query_String_Suffix}
						lazyLoad={lazyLoad} setLazyLoad={setLazyLoad} sortBy={sortBy} setSortBy={setSortBy} filterBy={filterBy} setFilterBy={setFilterBy} setData={setData} setShowData={setShowData}
						searchText={searchText} selectMode={selectMode} setSelectMode={setSelectMode} creatorRowIDs={creatorRowIDs} setCreatorRowIDs={setCreatorRowIDs} rows={rows} userserverArr={userserverArr} GlobalServer={GlobalServer} allServer={allServer}/>
					<h1>No Tasks Found :(</h1>
				</div>
			</Fade>


		);
	}
	return (
		<div className="page-content-2">
			{content}
		</div>
	);
}

function MyTasksTopBar({ rowsCurrent, setSearchText, drisList, drisTree, serversList, setHasData, initialRows, setInitialRows,
	userEmail, userRole, queryStringSuffix, lazyLoad, setLazyLoad, sortBy, setSortBy, filterBy, setFilterBy, setData, setShowData, searchText,
	selectMode, setSelectMode, creatorRowIDs, setCreatorRowIDs, rows, setRows,userserverArr,GlobalServer,allServer}) {
	// const drisMenuList = drisList.map((item, index) => {
	// 	return <MenuItem key={index} onClick={() => handleDriItemClick(item)}>{item}</MenuItem>
	// });

	const sortedServersList = [...serversList].sort((a, b) => a.localeCompare(b));

	const serversMenuList = sortedServersList.map((item, index) => {
		return <MenuItem key={index} onClick={() => handleServerItemClick(item)}>{item}</MenuItem>
	});

	const [sortAnchor, setSortAnchor] = useState(null);
	const [filterAnchor, setFilterAnchor] = useState(null);
	const [driAnchor, setDriAnchor] = useState(null);
	const [serverAnchor, setServerAnchor] = useState(null);
	const toggle = useRef(false);
	const [minimize, setMinimize] = useState(false);
	const [minimize1, setMinimize1] = useState(false);
	const [isIconChecked, setIsIconChecked] = useState(true);

	const [open, setOpen] = useState(false);
	const [open1, setOpen1] = useState(false);

	function handleSortButtonClick(event) {
		setSortAnchor(event.currentTarget);
	}

	function handleSortButtonClose() {
		setSortAnchor(null);
	}

	function handleSearchButtonClick() {
		// setSearchText("");
		setOpen(!open);
	}

	function handleFilterButtonClick(event) {
		setFilterAnchor(event.currentTarget);
	}

	function handleFilterButtonClose() {
		setFilterAnchor(null);
	}
	function showData() {
		setSelectMode(!setData ? "quarterly" : null);
		setShowData(!setData);
		setIsIconChecked(!isIconChecked);
	};

	function handleDriButtonClose() {
		setDriAnchor(null);
		setFilterAnchor(null);
	}

	function handleServerButtonClose() {
		setServerAnchor(null);
		setFilterAnchor(null);
	}

	function handleSortItemClick(item) {
		setSortAnchor(null);
		setSortBy(item);
		// if (item === "Default") {
		// 	const rowsNew = group_by_order(rowsCurrent, [1, 3], 0);
		// 	setRows(rowsNew);
		// }
		// else if (item === "Server") {
		// 	var rowsNew = [...rowsCurrent];
		// 	rowsNew.sort(serverCompareFunction);
		// 	setRows(rowsNew);
		// }

		// else if (item === "Priority") {
		// 	var rowsNew = [...rowsCurrent];
		// 	rowsNew.sort(priorityCompareFunction);
		// 	setRows(rowsNew);
		// }
		// else if (item === "Status") {
		// 	var rowsNew = [...rowsCurrent];
		// 	rowsNew.sort(statusCompareFunction);
		// 	setRows(rowsNew);
		// }
		// else if (item === "TaskName") {
		// 	var rowsNew = [...rowsCurrent];
		// 	rowsNew.sort(tasknameCompareFunction);
		// 	setRows(rowsNew);
		// }


		// else if (item === "DRI") {
		// 	var rowsNew = [...rowsCurrent];
		// 	rowsNew.sort(driCompareFunction);
		// 	setRows(rowsNew);
		// }
	}

	async function handleFilterItemClick(event, item) {
		// var queryString = null;
		// if (userRole === "CEO") {
		// 	queryString = `SELECT * FROM TaskBreaker WHERE ReportName = 'Roadmap_Form_Report' AND TaskStatus != 'Closed' AND Tasklist_created_for_roadmap is null ORDER BY Component`;
		// } else {
		// 	queryString = `SELECT * FROM TaskBreaker WHERE ReportName = 'Roadmap_Form_Report' AND Component IN ${queryStringSuffix} AND TaskStatus !='Closed' AND Tasklist_created_for_roadmap is null ORDER BY Component`;
		// }

		if (item === "Server") {
			setServerAnchor(event.currentTarget);
		}
		else if (item === "Dri") {
			setDriAnchor(event.currentTarget);
		}

		else if(item === "Combined Roadmap")
		{
			setFilterBy(["Combined Roadmap"]);
		}

		else if (item === "Default") {
			// try {
			// 	// setHasData(null);
			// 	// const result = await getFromTaskBreaker(queryString);

			// 	// setHasData(true);

			// 	// setInitialRows(result);


			// 	setHasData(null);
			// 	const newLazyLoader = lazyLoader(queryString, roadmapTransformFunction);
			// 	setLazyLoad(newLazyLoader);
			// 	const result = await newLazyLoader.next();
			// 	if (result.value.content === undefined) {
			// 		setHasData(false);
			// 		setLazyLoad(null);
			// 	} else if (result.value.isLast) {
			// 		setInitialRows(result.value.content);
			// 		setLazyLoad(null);
			// 	} else {
			// 		setHasData(true);
			// 		setInitialRows(result.value.content);
			// 	}
			// }
			// catch (error) {
			// 	setHasData(false);
			// 	setLazyLoad(null);
			// }
			setFilterBy(["Default"]);
		}
	}

	// async function handleDriItemClick(item)
	// {
	// 	try
	// 	{
	// 		setHasData(null);
	// 		const result = await getFromTaskBreaker(`SELECT * FROM TaskBreaker WHERE Email= '${item}' AND ReportName='Roadmap_Form_Report' AND TaskStatus != 'Closed' AND Tasklist_created_for_roadmap is null ORDER BY Component`);
	// 		setHasData(true);		
	// 		setInitialRows(result);
	// 		setLazyLoad(null);
	// 	}
	// 	catch(error)
	// 	{
	// 		setHasData(false);
	// 	}
	// 	var tempFilterBy = [...filterBy];
	// 	var index = tempFilterBy.indexOf("Default");
	// 	if(index > -1) {
	// 		tempFilterBy.splice(index, 1);
	// 	}
	// 	index = tempFilterBy.indexOf(item);
	// 	if(index > -1) {
	// 		tempFilterBy.splice(index, 1);
	// 	}
	// 	setFilterBy([...tempFilterBy, item]);
	// }

	const handleDriTreeItemClick = async (item) => {
		// try {
		// 	setHasData(null);
		// 	const result = await getFromTaskBreaker(`SELECT * FROM TaskBreaker WHERE Email=  '${item.Email}' AND ReportName='Roadmap_Form_Report' AND TaskStatus != 'Closed' AND Tasklist_created_for_roadmap is null ORDER BY Component`);
		// 	setHasData(true);
		// 	setInitialRows(result);
		// 	setLazyLoad(null);
		// 	//setCloseMenu(true);
		// }
		// catch (error) {
		// 	setHasData(false);
		// };
		// setFilterBy(item.Email);
		var tempFilterBy = [...filterBy];
		var index = tempFilterBy.indexOf("Default");
		if (index > -1) {
			tempFilterBy.splice(index, 1);
		}
		index = tempFilterBy.indexOf(item.Email);
		if (index > -1) {
			tempFilterBy.splice(index, 1);
		}
		setFilterBy([item.Email, ...tempFilterBy]);
	};

	async function handleServerItemClick(item) {
		// try {
		// 	setHasData(null);
		// 	const result = await getFromTaskBreaker(`SELECT * FROM TaskBreaker WHERE Component = '${item}' AND ReportName='Roadmap_Form_Report' AND TaskStatus != 'Closed' AND Tasklist_created_for_roadmap is null ORDER BY Component`);
		// 	setHasData(true);
		// 	setInitialRows(result);
		// 	setLazyLoad(null);
		// }
		// catch (error) {
		// 	setHasData(false);
		// }
		// setFilterBy("Server: " + item);
		// console.log(item)
		var tempFilterBy = [...filterBy];
		var index = tempFilterBy.indexOf("Default");
		if (index > -1) {
			tempFilterBy.splice(index, 1);
		}
		index = tempFilterBy.indexOf(item);
		if (index > -1) {
			tempFilterBy.splice(index, 1);
		}
		// setFilterBy([item, ...tempFilterBy]);
		setFilterBy([item]);
		localStorage.setItem('selectedServerRoadMap', item);
	}

	function handleToggleClosedButtonClick() {
		toggle.current = !toggle.current;
		var rowsNew = rowsCurrent.filter(row_ => {
			if (toggle.current) {
				if (row_[5] === "Closed") {
					return true;
				}
				return false;
			}
			else {
				if (row_[5] === "Closed") {
					return false;
				}
				return true;
			}
		});

		setRows(rowsNew);
	}

	function handleSearchTextChange(event) {
		setSearchText(event.target.value);
	}

	useLayoutEffect(() => {

		var rowsNew = rowsCurrent.filter(row_ => {
			if (row_[6] === "Closed") {
				return false;
			}
			return true;
		});

		setRows(rowsNew);

	}, []);

	// const [anchorEl, setAnchorEl] = useState(null);
	const [openPopup, setOpenPopup] = useState(false);
	const [openPopup1, setOpenPopup1] = useState(false);
	const [iframeLoaded, setIframeLoaded] = useState(false);
	const [iframeLoaded1, setIframeLoaded1] = useState(false);

	const handleMenuOpen = (event) => {
		if(minimize) {
			setMinimize(false);
		} else {
			setOpenPopup(true);
		}
		setOpenPopup(true);
		//   setAnchorEl(event.currentTarget);
	};
	const handleMenuOpen1 = (event) => {
		if(minimize) {
			setMinimize1(false);
		} else {
			setOpenPopup1(true);
		}
		setOpenPopup1(true);
		//   setAnchorEl(event.currentTarget);
	};

	// const handleMenuClose = () => {
	//   setAnchorEl(null);
	// };

	// const handleAddItemClick = (event) => {
	// 	// Logic for handling the selected item in the dropdown
	// 	if (event.target.textContent === 'Create New Roadmap') {
	// 	  setOpenPopup(true);
	// 	}
	// 	handleMenuClose(); // Close the dropdown menu
	//   };

	const handleClosePopup = () => {
		setFilterBy([...filterBy]);
		setOpenPopup(false);
		setIframeLoaded(false);
	};

	const handleClosePopup1 = () => {
		setFilterBy([...filterBy]);
		setOpenPopup1(false);
		setIframeLoaded1(false);
	};


	const handleIframeLoad = () => {
		setIframeLoaded(true);
	};
	const handleIframeLoad1 = () => {
		setIframeLoaded1(true);
	};

	function handleEscKeyPress(event) {
		if(event.key === "Escape") {
			// setSearchText("");
			setOpen(false);
		}
	}

	function handleMinimizeClick(event) {
		setMinimize(true);
	}
	function handleMinimizeClick1(event) {
		setMinimize1(true);
	}

	const grow = keyframes`
	0% {
		scale: 1;
	}
	20% {
		scale: 1.2;
		rotate: 0deg;
	}
	35% {
		rotate: -20deg;
	}
	45% {
		rotate: 20deg;
	}
	65% {
		rotate: -20deg;
	}
	80% {
		scale: 1.2;
		rotate: 0deg;
	}
	100% {
		scale: 1;
	}
	`;

	const [showAlert1, setShowAlert1] = useState(false);
	const [showAlert2, setShowAlert2] = useState(false);
	const [showAlert3, setShowAlert3] = useState(false);

	async function handleDeleteClick() {
		try {
			setShowAlert1(true);
			var deletedArr = await deleteMultipleRows(creatorRowIDs);
			var rowsNew = initialRows.filter(r => {
				return !deletedArr.includes(r[6])
			});
			setInitialRows(rowsNew);

			setShowAlert1(false);
			setShowAlert2(true);
			setTimeout(()=> {
				setShowAlert2(false);
			}, 2000);

		} catch(e) {
			setShowAlert1(false);
			setShowAlert3(true);
			setTimeout(()=> {
				setShowAlert3(false);
			}, 2000);
		}
		setShowData(false);
		setCreatorRowIDs([]);
		setSelectMode(null);
	}

	function handleCancelClick() {
		setShowData(false);
		setCreatorRowIDs([]);
		setSelectMode(null);
	}
	const [addAnchor, setAddAnchor] = useState(null);

	function handleChipClick(event, item) {
		if(event.detail === 2) {
			setFilterBy([item]);
			localStorage.setItem('selectedServerRoadMap', item);
		}
	}

	const { loginUserEmail, teamDetails, supportUsers, tokenMailID } = useContext(UserContext);
	const loginUserRole = teamDetails.current[loginUserEmail.current].Role;
	


	return (
		<div className="mytasks-top-bar">
			{/*<div className="mytasks-top-bar-left-group">
				<ButtonGroup variant="contained" aria-label="outlined primary button group">
					<Button variant="contained" size="small"><AddIcon /></Button>
	
				</ButtonGroup>
			</div>*/}

			<Snackbar
				open={showAlert1}

				// onClose={() => setShowAlert1(false)}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}

			>
				<Alert severity="info">Deleting in progress...</Alert>
			</Snackbar>

			<Snackbar
				open={showAlert2}

				onClose={() => setShowAlert2(false)}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="success">Roadmap Deleted 👍 </Alert>


			</Snackbar>

			<Snackbar
				open={showAlert3}

				onClose={() => setShowAlert1(false)}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="error">Please try again after some time.</Alert>
			</Snackbar>


			<div className="mytasks-top-bar-left-group" style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				gap: "6px"
			}}>
				<Button variant="contained" size="small" 
				onClick={(event) => {
                    if(addAnchor === null) {
                        setAddAnchor(event.currentTarget)
                    } else {
                        setAddAnchor(null);
                    }
                }}

				
				
				sx={{
					borderRadius: "100px",
					padding: 0.9,
					paddingRight: 1.2,
					paddingLeft: 1.2
				}}>
					{
						minimize ?
							<OpenInFullRoundedIcon sx={{
								animation: `${grow} 1s ease`,
								fontSize: "1.4rem"
							}} />
							:
							<AddIcon sx={{fontSize: "1.4rem"}} />
						

							
					}
					 <Menu
                        anchorEl={addAnchor}
                        open={addAnchor !== null}
                        onClose={() => setAddAnchor(null)}
                    >
                        {/* <MenuItem onClick={() => {setAddAnchor(null); handleMenuOpen();}} >SD Build Update</MenuItem> */}
                        <MenuItem onClick={() => {setAddAnchor(null); handleMenuOpen();}} >Create Roadmap</MenuItem>
                        <MenuItem onClick={() => {setAddAnchor(null); handleMenuOpen1();}} >Create Milestone</MenuItem>
                    </Menu>

				</Button>
				{
					selectMode === "delete" ? 
						<Box sx={{display: "flex", gap: "6px", paddingRight: "6px", borderRight: "1px solid grey"}}>
							<Button variant='contained' sx={{
								backgroundColor: "rgba(177,44,9,1)",
								color: "#2b0b02",
								borderRadius: "100px",
								"&:hover": {
									backgroundColor: "#d63d13"
								},
								paddingRight: 1.2,
								paddingLeft: 1.2,
								paddingTop: 0.47,
								paddingBottom: 0.47
								}} 
								onClick={handleDeleteClick}
							>
									<DeleteForeverIcon sx={{
										animation: `${grow} 1s ease`,
										fontSize: "1.4rem",
										marginRight: "4px"
									}} />
									<span style={{
										color: "white",
										textTransform: "none",
										borderLeft: "1px solid #2b0b02",
										paddingLeft: "6px"
									}}>Delete</span>
								</Button>
							<Button variant='contained' sx={{
								backgroundColor: "#226DB4",
								color: "#081c33",
								borderRadius: "100px",
								"&:hover": {
									backgroundColor: "#297dcc"
								},
								paddingRight: 1.2,
								paddingLeft: 1.2,
								paddingTop: 0.47,
								paddingBottom: 0.47
								}} size="small"
								onClick={handleCancelClick}
							>
								<CancelIcon sx={{
										animation: `${grow} 1s ease`,
										fontSize: "1.3rem",
										marginRight: "4px"
									}} />
									<span style={{
										color: "white",
										textTransform: "none",
										borderLeft: "1px solid #081c33",
										paddingLeft: "6px"
									}}>Cancel</span>
								</Button>
						</Box>
						:
						<>
						</>
				}

				<Box style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					gap: "10px",
					width: "calc(50vw)",
					overflowX: "scroll"
				}}
					className="filter-sort-overflow-container"
				>
					{
                        searchText !== "" ?
                            <Chip
								key={"search-chip"}
                                label={
                                    <Box style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <Box style={{
                                            color: "#0c2c70",
                                            borderRight: "1px solid #103fa3",
                                            paddingRight: "6px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <SearchIcon />
                                        </Box>
                                        <Box style={{
                                            paddingLeft: "6px",
                                            color: "ghostwhite"
											// width: "60px",
											// overflow: "hidden",
											// textOverflow: "ellipsis",
											// textAlign: "center"
                                        }}>
                                            {/* <Tooltip title={searchText} > */}
												{searchText}
											{/* </Tooltip> */}
                                        </Box>
                                    </Box>
                                }
                                sx={{
                                    bgcolor: "#226DB4 !important",
                                    border: "1px solid #0c2c70"
                                }}
                                onDelete={() => {
                                    setSearchText("");
                                }}
                                deleteIcon={
                                    <CancelIcon
                                        onMouseDown={(event) => event.stopPropagation()}
                                        sx={{
                                            color: "#0c2c70 !important"
                                        }}
                                    />
                                }
                            />
                            :
                            <></>
                    }
					{
						sortBy !== "Default" ?
							<Chip
								key={"sort-chip"}
								label={
									<Box style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "center",
										alignItems: "center"
									}}>
										<Box style={{
											color: "#1c5326",
											borderRight: "1px solid #1c5326",
											paddingRight: "6px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center"
										}}>
											<SortIcon />
										</Box>
										<Box style={{
											paddingLeft: "6px",
											color: "ghostwhite",
											width: "70px",
											overflow: "hidden",
											textOverflow: "ellipsis",
											textAlign: "center"
										}}>
											 <Tooltip title={sortBy} >
												{sortBy}
											 </Tooltip>
										</Box>
									</Box>
								}
								sx={{
									bgcolor: "rgb(8,153,73,0.9) !important",
									border: "1px solid #1c5326"
								}}
								onDelete={() => {
									setSortBy("Default");
								}}
								deleteIcon={
									<CancelIcon
										onMouseDown={(event) => event.stopPropagation()}
										sx={{
											color: "#1c5326 !important"
										}}
									/>
								}
							/>
							:
							<></>
					}
					{
						!filterBy.includes("Default") ?
							filterBy.map((item, index) => {
								return (
									<Chip
										key={"filter-chip-"+index}
										label={
											<Box style={{
												display: "flex",
												flexDirection: "row",
												justifyContent: "center",
												alignItems: "center"
											}} onClick={(event) => handleChipClick(event, item)}>
												<Box style={{
													color: "#331511",
													borderRight: "1px solid #53291c",
													paddingRight: "6px",
													display: "flex",
													alignItems: "center",
													justifyContent: "center"
												}}>
													<FilterAltOutlinedIcon />
												</Box>
												<Box style={{
													paddingLeft: "6px",
													color: "ghostwhite",
													width: "70px",
													overflow: "hidden",
													textOverflow: "ellipsis",
													textAlign: "center"
												}}>
													<Tooltip title={item}>
														{item}
													</Tooltip>
												</Box>
											</Box>
										}
										sx={{
											// bgcolor: "rgb(228,37,39, 0.9) !important"
											background: "rgba(177,44,9,1) !important",
											border: "1px solid #53291c"
										}}
										onDelete={() => {
											localStorage.removeItem('selectedServerRoadMap');
											var tempFilterBy = [...filterBy];
											var index = tempFilterBy.indexOf(item);
											// console.log(tempFilterBy)
											
											if (index > -1) {
												tempFilterBy.splice(index, 1);
												setFilterBy(tempFilterBy);
											}
											if (tempFilterBy.length === 0) {
												
												tempFilterBy.push("Zone");
												userserverArr = localStorage.getItem('selectedServerRoadMap') !== null ? [localStorage.getItem('selectedServerRoadMap')] : allServer.split(",");
												setFilterBy(userserverArr);
											}
											else if(tempFilterBy.length != 0){
												setFilterBy(tempFilterBy);
											}
										}}
										deleteIcon={
											GlobalServer === "unknown" ||  GlobalServer === undefined ? (
												<CancelIcon
												onMouseDown={(event) => event.stopPropagation()}
												sx={{
													color: "#53291c !important"
												}}
											/>
											) : (
												<></>
											)
											
										}
									/>
								);
							})
							:
							<></>
					}
					<div style={{
					}} className="unselectable">
						&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
					</div>
				</Box>

				{/* <Menu
			  anchorEl={anchorEl}
			  open={Boolean(anchorEl)}
			  onClose={handleMenuClose}
			  anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			  }}
			  transformOrigin={{
				vertical: 'top',
				horizontal: 'left',n
			  }}
			>
			  <MenuItem onClick={handleAddItemClick}>Create New Roadmap</MenuItem>
			</Menu> */}
			</div>
			<Dialog open={openPopup} onClose={handleClosePopup} sx={{
				// zIndex: minimize ? -1 : 1300
				display: minimize ? "none" : "block"
			}} fullScreen>
				<DialogActions>
					{/* <Button onClick={handleMinimizeClick} variant="text" fontSize="large"><RemoveIcon/></Button> */}
					{!navigator.onLine && (
					<Alert sx={{marginRight:"669px",width:"247px",alignItems:"center",textAlign:"center",justifyContent:"center"}} severity="error">Kindly check you network connection.</Alert>
				)}
					<Button onClick={handleClosePopup} variant="text" fontSize="large"><CloseIcon /></Button>
				</DialogActions>
				<DialogContent>
					{!iframeLoaded && (
						<div
							style={{
								position: 'relative',
								width: '100%',
								height: '100%',
								backgroundImage: 'linear-gradient(to right, #f0f0f0, #e0e0e0)',
								borderRadius: 0,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Skeleton
								variant="rectangular"
								width="100%"
								height="100%"
								animation="wave"
							/>
						</div>
					)}
					<iframe
					    // src = "https://creatorapp.zoho.com/zohointranet/zvp-team//form-embed/External_Feature_Request?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true"
						src="https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/Roadmap_Form?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true"
						width="100%"
						height="100%"
						title="External Content"
						onLoad={handleIframeLoad}
						frameBorder={0}
						style={{ display: iframeLoaded ? 'block' : 'none' }}
					/>
				</DialogContent>
			</Dialog>





			<Dialog open={openPopup1} onClose={handleClosePopup1} sx={{
				// zIndex: minimize ? -1 : 1300
				display: minimize ? "none" : "block"
			}} fullScreen>
				<DialogActions>
					{/* <Button onClick={handleMinimizeClick1} variant="text" fontSize="large"><RemoveIcon/></Button> */}
					{!navigator.onLine && (
					<Alert sx={{marginRight:"669px",width:"247px",alignItems:"center",textAlign:"center",justifyContent:"center"}} severity="error">Kindly check you network connection.</Alert>
				)}
					<Button onClick={handleClosePopup1} variant="text" fontSize="large"><CloseIcon /></Button>
				</DialogActions>
				<DialogContent>
					{!iframeLoaded1 && (
						<div
							style={{
								position: 'relative',
								width: '100%',
								height: '100%',
								backgroundImage: 'linear-gradient(to right, #f0f0f0, #e0e0e0)',
								borderRadius: 0,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Skeleton
								variant="rectangular"
								width="100%"
								height="100%"
								animation="wave"
							/>
						</div>
					)}
					<iframe
						src="https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/NewMilestone?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true"
						width="100%"
						height="100%"
						title="External Content"
						onLoad={handleIframeLoad1}
						frameBorder={0}
						style={{ display: iframeLoaded1 ? 'block' : 'none' }}
					/>
				</DialogContent>
			</Dialog>



			


			<div className="mytasks-top-bar-right-group">
				<ButtonGroup variant="contained" aria-label="outlined primary button group">
					<Collapse in={open} timeout="auto" orientation="horizontal" unmountOnExit>
						<TextField variant="outlined" placeholder="search" onChange={handleSearchTextChange} value={searchText} autoFocus onKeyDown={handleEscKeyPress} />
					</Collapse>
					<Tooltip title="search"><Button variant="contained" size="small" onClick={handleSearchButtonClick}><SearchIcon /></Button></Tooltip>
					{/* <Button variant='contained' size="small" onClick={showData}><CheckBoxOutlinedIcon /></Button> */}
					<Tooltip title="filter by"><Button variant="contained" size="small" onClick={handleFilterButtonClick}><FilterAltOutlinedIcon  /><ArrowDropDownIcon  /></Button></Tooltip>
					<Tooltip title="sort by"><Button variant="contained" size="small" onClick={handleSortButtonClick}><SortIcon  /><ArrowDropDownIcon  /></Button></Tooltip>
				</ButtonGroup>
				<Menu
					anchorEl={sortAnchor}
					open={sortAnchor !== null}
					onClose={handleSortButtonClose}
				>
					<MenuItem onClick={() => handleSortItemClick("Default")}>Default</MenuItem>
					{/* <MenuItem onClick={() => handleSortItemClick("Server")}>Server</MenuItem> */}
					{(GlobalServer !== "unknown" && GlobalServer !== undefined) ?(
						<></>
					):(
						<MenuItem onClick={() => handleSortItemClick("Server")}>Server</MenuItem>
					)}

					<MenuItem onClick={() => handleSortItemClick("TaskName")}>TaskName</MenuItem>
					<MenuItem onClick={() => handleSortItemClick("Priority")}>Priority</MenuItem>

					<MenuItem onClick={() => handleSortItemClick("DRI")}>DRI</MenuItem>
				</Menu>
				<Menu
					anchorEl={filterAnchor}
					open={filterAnchor !== null}
					onClose={handleFilterButtonClose}
				>
					<MenuItem onClick={(event) => handleFilterItemClick(event, "Default")} style={{ justifyContent: "left" }}>Default</MenuItem>
					<MenuItem onClick={(event) => handleFilterItemClick(event, "Dri")}><ArrowLeftIcon />DRI</MenuItem>
					<OtherDRI onClick={handleDriTreeItemClick}/>
					{(GlobalServer !== "unknown" && GlobalServer !== undefined) ?(
						<></>
					):(
						<MenuItem onClick={(event) => handleFilterItemClick(event, "Server")}><ArrowLeftIcon />Server</MenuItem>
					)}
					{/* <MenuItem onClick={(event) => handleFilterItemClick(event, "Server")}><ArrowLeftIcon />Server</MenuItem> */}
					<MenuItem onClick={(event) => handleFilterItemClick(event, "Combined Roadmap")}>Combined Roadmap</MenuItem>
				</Menu>
				{drisTree && drisTree.length > 0 && (
					<MultiLevelDropdown
						dritree={drisTree}
						driAnchor={driAnchor}
						handleDriTreeButtonClose={handleDriButtonClose}
						handleDriTreeItemClick={handleDriTreeItemClick}
					/>
				)}
				{/* {userRole !== "CEO" ? (
				<Menu
					anchorEl={driAnchor}
					open={driAnchor !== null}
					onClose={handleDriButtonClose}
					anchorOrigin={{
						vertical: "top",
						horizontal: "left",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
				>
					{drisMenuList}
				</Menu>
				) : (
					<MultiLevelDropdown
						dritree={drisTree}
						driAnchor={driAnchor}
						setHasData={setHasData}
						setInitialRows={setHasData}
						handleDriTreeButtonClose={handleDriButtonClose}
						handleDriTreeItemClick={handleDriTreeItemClick}
					/>
				)} */}
				<Menu
					anchorEl={serverAnchor}
					open={serverAnchor !== null}
					onClose={handleServerButtonClose}
					anchorOrigin={{
						vertical: "top",
						horizontal: "left",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
				>
					{serversMenuList}
				</Menu>
			</div>
		</div>
	);
}


function MyTasksTable({ rows, searchText, setRows, rowsCurrent, lazyLoad, initialRows, setInitialRows, setLazyLoad, filterBy, setFilterBy, setData, setShowData, selectMode, setSelectMode,
	creatorRowIDs, setCreatorRowIDs }) {

		
	
	const [selectedRows, setSelectedRows] = useState([]);
	const [selectedOption, setSelectedOption] = useState("");
	const [selectedOption2, setSelectedOption2] = useState("Year");
	const { userDetails } = useContext(UserContext);
	const [dataDb, setDbData] = useState(false);
	const [yearsData, setYearsData] = useState([]);
	const [monthData, setMonthData] = useState([]);
	const userEmail = userDetails.current.auth.email_id;
	const { loginUserEmail, teamDetails, supportUsers, tokenMailID } = useContext(UserContext);
	const [currentQuarter, setCurrentQuarter] = useState([]);
	const [openDialog, setOpenDialog] = useState(false);
	const [openSecondDialog, setOpenSecondDialog] = useState(false);
	const [newRoadMapName, setNewRoadMapName] = useState('');
	const [newUniqueValue, setNewUniqueValue] = useState('');
	// const [externalRoadmap, setExternalRoadmap] = useState([]);
	
	const handleOpenSecondDialog = () => {
		setOpenSecondDialog(true);
	  };
	  const handleCloseSecondDialog = () => {
		setOpenSecondDialog(false);
	  };
	  useEffect(() => {
		// Check if newUniqueValue has a value and then use it in sendDataToJava
		if (newUniqueValue !== "") {
		  const selectedData = selectedRows.map((index) => rows[index].toString());
		  const selectedYear = yearsData.toString();
	  
		  if (selectedData.length > 0) {
			sendDataToJava(selectedOption, selectedYear, selectedData, userEmail, newRoadMapName, newUniqueValue);
			setShowData(false);
		  } else {
			alert("No data selected for submission.");
		  }
		}
	  }, [newUniqueValue]);

	  const handleCloseSecondDialogNo = () => {
		setNewUniqueValue("Yes")
		// const selectedData = selectedRows.map((index) => rows[index].toString());
        // const selectedYear=yearsData.toString();
		// if (selectedData.length > 0) {
		// 	  sendDataToJava(selectedOption, selectedYear, selectedData, userEmail,newRoadMapName,newUniqueValue);
		// 	  setShowData(false);
		//   } else {
		// 	alert("No data selected for submission.");
		//   }
		  setShowData(false);
		  setOpenSecondDialog(false);
	  };

	const handleOpenDialog = () => {
		setOpenDialog(true);
	  };
	  
	  // Function to close the dialog
	  const handleCloseDialog = () => {
		setOpenDialog(false);
	  };
	  
	  // Function to handle the "Yes" button click
	  const handleConfirmYes = () => {
		// setOpenSecondDialog(true)
		// Add your logic here for when the user clicks "Yes"
		// This function will be called when the user clicks "Yes" in the dialog
		// You can submit the data or perform any other action here
		// handleCloseDialog();
		handleOpenSecondDialog();
	  };
	  
	  
	  // Function to handle the "No" button click
	  const handleConfirmNo = () => {
		setNewUniqueValue("No")
		setNewRoadMapName("-")
		
		
		// const selectedData = selectedRows.map((index) => rows[index].toString());
        // const selectedYear=yearsData.toString();
		// if (selectedData.length > 0) {
		// 	  sendDataToJava(selectedOption, selectedYear, selectedData, userEmail,newRoadMapName,newUniqueValue);
		// 	  setShowData(false);
		//   } else {
		// 	alert("No data selected for submission.");
		//   }
		  setShowData(false);
		// Add your logic here for when the user clicks "No"
		// This function will be called when the user clicks "No" in the dialog
		handleCloseDialog();
	  };

		

	useEffect(() => {
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const now = new Date();
		const month = now.getMonth() + 1;
		setYearsData(year)

		// const month = now.getMonth() + 1;
			// if (month >= 1 && month <= 3) {
				
			// 	var arr=['Q1', 'Q2', 'Q3', 'Q4'];
			
			// }
			// else if (month >= 4 && month <= 6) {

			// 	var arr=['Q2', 'Q3', 'Q4', 'Q1'];
			// 	if(arr.includes("Q1")){
			// 		setYearsData(year+1);
			// 	}
			// }
			// else if (month >= 7 && month <= 9) {

			// 	var arr=['Q3', 'Q4', 'Q1', 'Q2'];
			// 		if(arr.includes("Q1")){
			// 			setCurrentQuarter([`Q3 ${year}`, `Q4 ${year}`, `Q1 ${year+1}`, `Q2 ${year+1}`]);
			// 		}

			// 		if(arr.includes("Q2")){
			// 			setCurrentQuarter([`Q3 ${year}`, `Q4 ${year}`, `Q1 ${year+1}`, `Q2 ${year+1}`]);
			// 		} 
			// }
			// else {
			// 	var arr=['Q4', 'Q1', 'Q2', 'Q3'];
			// 		if(arr.includes("Q1")){
			// 			setYearsData(year+1)
			// 		}

			// 		if(arr.includes("Q2")){
			// 			setYearsData(year+1)
			// 		}

			// 		if(arr.includes("Q3")){
			// 			setYearsData(year+1)
			// 		}
			// }


		
		
	  
		let initialSelectedOption = ''; 
	  
		if (month >= 1 && month <= 3) {

			setCurrentQuarter([`Q1 ${year}`, `Q2 ${year}`, `Q3 ${year}`, `Q4 ${year}`]);
		  initialSelectedOption = `Q1 ${year}`; 

		}
		 else if (month >= 4 && month <= 6) {

			var arr=['Q2', 'Q3', 'Q4', 'Q1'];
				if(arr.includes("Q1")){
					setCurrentQuarter([`Q2 ${year}`, `Q3 ${year}`, `Q4 ${year}`, `Q1 ${year+1}`]);
				}
		  initialSelectedOption = `Q2 ${year}`;
		}
		
		 else if (month >= 7 && month <= 9) {
		//   setCurrentQuarter(['Q3', 'Q4', 'Q1', 'Q2']);
		  var arr=['Q3', 'Q4', 'Q1', 'Q2'];
			if(arr.includes("Q1")){
				setCurrentQuarter([`Q3 ${year}`, `Q4 ${year}`, `Q1 ${year+1}`, `Q2 ${year+1}`]);
			}
		  initialSelectedOption = `Q3 ${year}`; 
		}

		 else {
			var arr=['Q4', 'Q1', 'Q2', 'Q3'];
			if(arr.includes("Q1")){
				setCurrentQuarter([`Q4 ${year}`, `Q1 ${year+1}`, `Q2 ${year+1}`, `Q3 ${year+1}`]);
			}
		  initialSelectedOption = `Q4 ${year}`;
		}
	  
		setSelectedOption(initialSelectedOption);
		const loginUserRole = teamDetails.current[loginUserEmail.current].Role;
		
		
		

		// const fetchYearsData = async () => {
		// 	try {
		// 		const result = await queryDB(`SELECT Roadmap FROM RoadmapExternal`);
		// 		setExternalRoadmap(result)
		// 	} catch (error) {
		// 		//   console.error(error);
		// 	}
		// };

		// fetchYearsData();
	}, []);
	 

	const sendDataToJava = (selectedOption, selectedYear, selectedData, userEmail,RoadMapName,newUniqueValue) => {
		const javaEndpoint = '/server/roadMapSelectedData';
		fetch(javaEndpoint, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ option: selectedOption, option1: selectedYear, data: selectedData, name: userEmail,NewRoadName: RoadMapName ,uniqueData :newUniqueValue}),
		})
			.then((response) => response.json())
			.then((data) => {
			})
			.catch((error) => {
				// console.error(error); 
			});
	};

	// const handleSubmit = () => {
	// 	const selectedData = selectedRows.map((index) => rows[index].toString());
    //     const selectedYear=yearsData.toString();
	// 	if (selectedData.length > 0) {
	// 		  sendDataToJava(selectedOption, selectedYear, selectedData, userEmail);
	// 		  setShowData(false);
	// 	  } else {
	// 		alert("No data selected for submission.");
	// 	  }
	// };

	const classNameForColors = ["color-a", "color-b", "color-c", "color-d", "color-e", "color-f"];
	var ind = useRef(0);
	var scrollContainer = useRef();
	var lastScrollTop = useRef(0);
	var loadingDiv = useRef();
	var locked = useRef(false);

	rows = rows.filter(row_ => {
		for (const item of row_) {
			if (item === null) {
				continue;
			}
			const aString = item.toLowerCase().replaceAll(" ", "");
			const bString = searchText.toLowerCase().replaceAll(" ", "");
			if (aString.indexOf(bString) !== -1) {
				return true;
			}
		}
		return false;
	});

	const handleScroll = async (event) => {
		const myDiv = scrollContainer.current;
		var needMore = false;

		if (myDiv.scrollTop > lastScrollTop.current && (myDiv.offsetHeight + myDiv.scrollTop >= myDiv.scrollHeight - 50)) {
			needMore = true;
		}

		lastScrollTop.current = myDiv.scrollTop;
		if (lazyLoad === null || searchText !== "") {
			return;
		}
		if (needMore && !locked.current) {
			locked.current = true;
			const data = await lazyLoad.next();
			if (data.value.content === undefined) {
				setLazyLoad(null);
				// setDoneFetching(true);
			} else if (data.value.isLast) {
				setInitialRows([...initialRows, ...data.value.content]);
				setLazyLoad(null);
			} else {
				// setRows([...rows, ...data.value]);
				setInitialRows([...initialRows, ...data.value.content]);
			}
			locked.current = false;
		}
	};

	const theme = useTheme();
	if (rows.length === 0) {
		return <h1 style={{ color: theme.palette.background.text }}>No Tasks Found :(</h1>;
	}

	const rowSpanInfo = find_row_span(rows, [2]);
	const value = [
		"Year",
		"Month",
	];
	

	const handleDropdownChange = (event) => {
		const selectedValue = event.target.value;
		const now = new Date();
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		// setYearsData(year)
		// setSelectedOption(selectedValue);
		setYearsData(year)
		setSelectedOption(selectedValue);
		
	};
	
	const handleDropdownChange3 = (event) => {
		const selectedValue3 = event.target.value;
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const currentMonth = currentDate.getMonth();
		// const check = ['September', 'October', 'November', 'December','January', 'February']
		const monthToNumber = {
			January: 0,
			February: 1,
			March: 2,
			April: 3,
			May: 4,
			June: 5,
			July: 6,
			August: 7,
			September: 8, 
			October: 9,
			November: 10,
			December: 11,
		  }; 
		
		const selectedMonthNumber = monthToNumber[selectedValue3];
		const calculatedYear = year + (selectedMonthNumber <= 7 ? 1 : 0);
		setYearsData(calculatedYear.toString())
		// const nextSixMonths = Array.from({ length: 7 }, (i, index) => {
		// const nextMonth = addMonths(currentDate, index);
		// return format(nextMonth, 'MMMM');
	    // });
		// 	const monthNames = [
		// 	  'January', 'February', 'March', 'April', 'May', 'June',
		// 	  'July', 'August', 'September', 'October', 'November', 'December'
		// 	];
		// if(currentMonth >= 6){
		// 	setYearsData(year+1)
		// }
		// if (currentMonth >= 6) {
		// 	// If the current month is July or later, set the selected year to the next year
		// 	selectedYear = currentYear + 1;
		//   }
		// else{
		// 	setYearsData(year)
		// }
		setSelectedOption(selectedValue3);
	};
	const handleDropdownChange2 = (event) => {
		const selectedValue2 = event.target.value;
		setSelectedOption2(selectedValue2);
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		setYearsData(year)
		if (selectedValue2 === "Month") {

			const nextSixMonths = Array.from({ length: 7 }, (i, index) => {
				const nextMonth = addMonths(currentDate, index);
				return format(nextMonth, 'MMMM');

			});
			const currentMonth = currentDate.getMonth(); // Returns a value from 0 to 11
			const monthNames = [
			  'January', 'February', 'March', 'April', 'May', 'June',
			  'July', 'August', 'September', 'October', 'November', 'December'
			];
			
			setMonthData(nextSixMonths);
			setSelectedOption(monthNames[currentMonth]);
			setDbData(true)
		}
		else {
			setDbData(false)
			currentQuarter.map((item,index) => (
			setSelectedOption(currentQuarter[index])
			))
			
		}
	};
	
	
	
	return (
		<div className="mytasks-table">

			<Dialog
				    
					aria-labelledby="confirm-dialog"
					open={openDialog}
					onClose={handleCloseDialog}
					>
						{openSecondDialog === true ? (<div style={{ display: "block" ,height:"400px"}}>	
			<DialogTitle style={{ float: "left", display: "flex", alignItems: "center" }}>
				<div>1) Do you want to associate these under single planner?</div>
			</DialogTitle>
			<DialogActions style={{ float: "right", padding: "16px 24px 16px 24px" }}>
			<Button
				variant="contained"
				onClick={handleConfirmNo}
				color="text"
				>
				No
				</Button>
				<Button
				variant="contained"
				onClick={handleConfirmYes}
				color="text"
				>
				Yes
				</Button>
				{/* <br></br> */}
			  {/* <Button onClick={handleClose} variant="text"><CloseIcon fontSize="medium" /></Button> */}
			</DialogActions>
			<div >
				<br></br> 
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
			<DialogTitle><div sx={{paddingBottom:"50px"}}>2) New Release planner name</div></DialogTitle>
			<DialogContent>
			<TextField label="New Release planner name" fullWidth  value={newRoadMapName} onChange={(e) => setNewRoadMapName(e.target.value)}/>
			</DialogContent>
			<DialogTitle><div sx={{paddingBottom:"50px"}}>3) do you want to associate these with external release?</div></DialogTitle>
			<DialogActions style={{ float: "right", padding: "16px 24px 16px 24px" }}>
			<Button
				variant="contained"
				// onClick={}
				color="text"
				>
				No
				</Button>
				<Button
				variant="contained"
				onClick={handleCloseSecondDialogNo}
				color="text"
				>
				Yes
				</Button>
				{/* <br></br> */}
			  {/* <Button onClick={handleClose} variant="text"><CloseIcon fontSize="medium" /></Button> */}
			</DialogActions>
			</div>
			{/* <Select style={{ color: theme.palette.mode === "light" ? 'black' : 'white', border: theme.palette.mode === "light" ? '1px solid black':'1px solid white', margin: 0,width:'114px' ,height:'24px',display: "flex",float: "right",marginLeft:"10px",marginTop: "-4px"}} value={selectedOption}>
				{externalRoadmap.map((item,index) => (
					
					<MenuItem  style={{ textAlign: 'center' }}>
						{item}
					</MenuItem>
					
				))}
			</Select> */}
			
			
		  </div>
		  )
		  :
		  (
		  <div style={{ display: "block"}}>	
			<DialogTitle style={{ float: "left", display: "flex", alignItems: "center" }}>
				<div>1) Do you want to associate these under single planner?</div>
			</DialogTitle>
			<DialogActions style={{ float: "right", padding: "16px 24px 16px 24px" }}>
			<Button
				variant="contained"
				onClick={handleConfirmNo}
				color="text"
				>
				No
				</Button>
				<Button
				variant="contained"
				onClick={handleConfirmYes}
				color="text"
				>
				Yes
				</Button></DialogActions></div>)}
			
		</Dialog>

		{/* <Dialog
        aria-labelledby="second-dialog"
        open={openSecondDialog}
        onClose={handleCloseSecondDialog}
      >
        <DialogTitle>New Release planner name</DialogTitle>
        <DialogContent>
          <TextField label="New Release planner name" fullWidth  value={newRoadMapName} onChange={(e) => setNewRoadMapName(e.target.value)}/>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCloseSecondDialogNo}
            color="text"
          >
            Submit
          </Button>
         
        </DialogActions>
      </Dialog> */}
			
			
								{
									setData && selectMode === "quarterly" ? (
										<>

											{/* <TableCell> */}
											{/* <Box sx={{
												display: "flex",
												flexDirection: "row !important",
												gap: "10px",
												alignItems: "center",
												justifyContent: "center"
											}}> */}
												
												<Button style={{ color: theme.palette.mode === "light" ? 'black' : 'white', border: theme.palette.mode === "light" ? '1px solid black':'1px solid white',height:'22px',display: "flex",	float: "right",marginLeft:"10px",marginTop: "-4px" }} onClick={handleOpenDialog}>submit</Button>
												

												{
													dataDb ?
														(
															// <TableRow className="mytasks-table-head">
															// <Box>
															<>
																<Select style={{ color: theme.palette.mode === "light" ? 'black' : 'white',  border: theme.palette.mode === "light" ? '1px solid black':'1px solid white', margin: 0,width:'138px',height:'26px',display: "flex",float: "right",marginLeft:"10px",marginTop: "-4px",alignItems:"center", justifyContent: "center"}} value={selectedOption} onChange={handleDropdownChange3}>
																	{monthData.map((item) => (
																		
																		<MenuItem value={item} style={{ textAlign: 'center' }}>
																			{item}  
																		</MenuItem>
																		
																		
																	))}
																</Select>
																<Box style={{ color: theme.palette.mode === "light" ? 'black' : 'white',  border: theme.palette.mode === "light" ? '1px solid black':'1px solid white', margin: 0,width:'70px',height:'26px',display: "flex",float: "right",marginLeft:"10px",marginTop: "-4px",alignItems:"center", justifyContent: "center"}}> {yearsData}</Box>
																</>
															// </Box>
															// </TableRow>
														)
														:
														(
															// <TableRow className="mytasks-table-head">
															// <Box>
																<Select style={{ color: theme.palette.mode === "light" ? 'black' : 'white', border: theme.palette.mode === "light" ? '1px solid black':'1px solid white', margin: 0,width:'114px' ,height:'24px',display: "flex",float: "right",marginLeft:"10px",marginTop: "-4px"}} value={selectedOption} onChange={handleDropdownChange}>
																	{currentQuarter.map((item,index) => (
																		
																		<MenuItem value={currentQuarter[index]} style={{ textAlign: 'center' }}>
																			{currentQuarter[index]}
																		</MenuItem>
																		
																	))}
																</Select>
															// </Box>
															// </TableRow>
														)
												}
												<Select style={{color: theme.palette.mode === "light" ? 'black' : 'white', border: theme.palette.mode === "light" ? '1px solid black':'1px solid white', margin: 0,height:'26px',display: "flex",float: "right",marginLeft:"10px" ,marginTop: "-4px"}} value={selectedOption2} onChange={handleDropdownChange2}>
													{value.map((value) => (
														<MenuItem  value={value} style={{ textAlign: 'center' }}>
															{value}
														</MenuItem>
													))}
												</Select>

												{/* <Box style={{ color: 'white', border: '1px solid white', margin: 0,height:'33px',width:'70px'}} value={selectedOption1} onChange={handleDropdownChange1}>
														<MenuItem value={"yearsData"} style={{ textAlign: 'center' }}>
															{yearsData}
														</MenuItem>
													
												</Box> */}
												
											{/* </Box> */}
											<TableCell></TableCell>
										</>
									)
										:
										<></>
								}
							{/* </MenuItem>
							)
							:
							<></>
	} */}
	
			{/* <TableCell></TableCell> */}
			<TableContainer component={Paper} onScroll={handleScroll} ref={scrollContainer} className="mytasks-table-container">
				<Table stickyHeader>
					{/* <TableHead></TableHead> */}
					<TableHead>
						<TableRow className="mytasks-table-head">
							<TableCell align="center" className="mytasks-table-grouper-header" />
							
							<TableCell align="center">S.NO.</TableCell>
							
							<TableCell align="center"></TableCell>
							<TableCell align="left">Taskname</TableCell>
							<TableCell align="center">Status</TableCell>
							<TableCell align="center">Priority</TableCell>
							<TableCell align="center">DRI</TableCell>
							{/* <TableCell></TableCell> */}
							<TableCell align="center" className="mytasks-table-grouper-header" />
							
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row, index) => (
							<Row key={index+"-"+row[6]} row={row} index={index} rowSpanInfo={rowSpanInfo[index]} classNameForColors={classNameForColors} ind={ind} 
							rowsCurrent={rowsCurrent} setRows={setRows} filterBy={filterBy} setFilterBy={setFilterBy} setData={setData} setShowData={setShowData} selectedRows={selectedRows}
							setSelectedRows={setSelectedRows} selectMode={selectMode} setSelectMode={setSelectMode} creatorRowIDs={creatorRowIDs} setCreatorRowIDs={setCreatorRowIDs} />
						))}
						{
							(lazyLoad !== null && searchText === "") ?
								<TableRow ref={loadingDiv}>
									<TableCell className={"mytasks-table-grouper-component loading"}></TableCell>
									<TableCell align="center" colSpan={6} >
										<div style={{
											whiteSpace: "nowrap",
											lineHeight: 0.5
										}}>
											<Skeleton height={"30px"}></Skeleton>
										</div>
									</TableCell>
								</TableRow>
								:
								<></>
						}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

function Row({ row, index, rowSpanInfo, ind, classNameForColors, rowsCurrent, setRows, filterBy, setFilterBy, setData, setShowData, selectedRows, setSelectedRows,
	selectMode, setSelectMode, creatorRowIDs, setCreatorRowIDs }) {

	const [moreVisibile, setMoreVisible] = useState(false);
	const [isSelected, setIsSelected] = useState(false);
	const [rowOpen, setRowOpen] = useState(false);

	const childRef = useRef();
	

	useEffect(() => {
		if(setData === true) {
			setIsSelected(false);
		}
	}, [setData]);

	const handleCheckboxChange = () => {
		if(selectMode === "quarterly") {
			setIsSelected(!isSelected);
			const rowIndex = index;
			const isSelectedRow = selectedRows.includes(rowIndex);
			
			let updatedSelectedRows;
			if (isSelectedRow) {
				updatedSelectedRows = selectedRows.filter((selectedRow) => selectedRow !== rowIndex);
			} else {
				updatedSelectedRows = [...selectedRows, rowIndex];
			}
	
			setSelectedRows(updatedSelectedRows);

			var detialsSet = [
				{ "TaskStatus": "QRMap", "ROWID": row[7]},
             ];
			 
			var datastore = window.catalyst.table;
			var table = datastore.tableId('5781000006856839');
			var updatePromise = table.updateRow(detialsSet);
			updatePromise
           .then((response) => {
                //    console.log(response.content);
 			})
            .catch((err) => {
                //    console.log(err);
			});

		} else {
			if(!isSelected) {
				if(!creatorRowIDs.includes(row[6])) {
					setCreatorRowIDs([...creatorRowIDs, row[6]]);
				}
			} else {
				var temp = [...creatorRowIDs]
				setCreatorRowIDs(temp.filter(item => {
					return item !== row[6]
				}));
			}
			
			setIsSelected(!isSelected);
		}
	};

	const handleClick = () => {
		if (rowOpen === index) {
				setRowOpen(null);
			}
	  
			else {
				setRowOpen(index);
			}
	  };

// console.log(row[7])

	// icons
	var priority = "None";
	if (row[3] === "High") {
		priority = <ArrowCircleUpTwoToneIcon style={{ color: "crimson" }} />;
	}
	else if (row[3] === "Medium") {
		priority = <RemoveCircleTwoToneIcon style={{ color: "orange" }} />;
	}
	else if (row[3] === "Low") {
		priority = <ArrowCircleDownTwoToneIcon style={{ color: "mediumseagreen" }} />;
	}
	else {
		priority = <Brightness1TwoToneIcon style={{ color: "grey" }} />;
	}

	var status = <HexagonIcon style={{ color: "grey", fontSize: "small" }} />;
	if (row[2] === "Open") {
		status = <HexagonIcon style={{ color: "green", fontSize: "small" }} />;
	}
	else if (row[2] === "In Progress") {
		status = <HexagonIcon style={{ color: "royalblue", fontSize: "small" }} />;
	}
	else if (row[2] === "In Review") {
		status = <HexagonIcon style={{ color: "bluegrey", fontSize: "small" }} />;
	}
	else if (row[2] === "To be Tested") {
		status = <HexagonIcon style={{ color: "orange", fontSize: "small" }} />;
	}
	else if (row[2] === "On Hold") {
		status = <HexagonIcon style={{ color: "goldenrod", fontSize: "small" }} />;
	}
	else if (row[2] === "Delayed") {
		status = <HexagonIcon style={{ color: "gold", fontSize: "small" }} />;
	}
	else if (row[2] === "Closed") {
		status = <HexagonIcon style={{ color: "tomato", fontSize: "small" }} />;
	}
	else if (row[2] === "Cancelled") {
		status = <HexagonIcon style={{ color: "navy", fontSize: "small" }} />;
	}
	else if (row[2] === "RMap") {
		status = <HexagonIcon style={{ color: "pink", fontSize: "small" }} />;
	}
	else if (row[2] === "Ongoing") {
		status = <HexagonIcon style={{ color: "salmon", fontSize: "small" }} />;
	}

	var { colorsCache } = useContext(TabStateContext);
	var colorClass = "color-a";
	if (index in colorsCache) {
		colorClass = colorsCache[index];
	}
	else {
		colorClass = classNameForColors[(ind.current) % 5];
		colorsCache[index] = colorClass;
	}






	// console.log()
	// side groupers
	var server = (
		<Tooltip title={row[1]} placement="right-start">
			<TableCell align="center" className={"mytasks-table-grouper-component mytasks-single-row " + row[1]}>
				<div style={{ whiteSpace: "nowrap" }}>{row[1].length > 8 ? row[1].slice(0, 8) + "..." : row[1]}</div>
			</TableCell>
		</Tooltip>
	);
	if (rowSpanInfo[1] === 0) {
		server = <></>;
	}
	else if (rowSpanInfo[1] !== 1) {
		server = (
			<TableCell align="center" className={"mytasks-table-grouper-component mytasks-multi-row " + row[1]} rowSpan={2*rowSpanInfo[1]} >
				<div style={{ whiteSpace: "nowrap" }}>{row[1]}</div>
			</TableCell>
		);
		ind.current = ind.current + 1;
		ind.current = ind.current + 1;
	}
	else {
		ind.current = ind.current + 1;
	}


	
if(row[9] !== null)
{

	var type = (
		<Tooltip title={row[9]} placement="right-start">
		<TableCell align="center" className={"mytasks-table-grouper-type mytasks-single-row " + row[9]}>

			<div style={{ whiteSpace: "nowrap" }}>{row[9].length > 8 ? row[9].slice(0, 8) + "..." : row[9]}</div>

	
		</TableCell>

</Tooltip>
	);

	if (rowSpanInfo[9] === 0) {
		type = <></>;
	}
	else if (rowSpanInfo[9] !== 1) {
		type = (
			<Tooltip title={row[9]} placement="left-start" >
			<TableCell align="center" className={"mytasks-table-grouper-type mytasks-multi-row " + row[9]} rowSpan={2*rowSpanInfo[9]}>
	
			<div style={{ whiteSpace: "nowrap" }}>{row[9].length > 8 ? row[9].slice(0, 8) + "..." : row[9]}</div>

	
			</TableCell>
			</Tooltip>
		);
		ind.current = ind.current + 1;
		ind.current = ind.current + 1;
	}
	else {
		ind.current = ind.current + 1;
	}



}










	const theme = useTheme();



	const TextOnlyTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
	))(`
	  color: black;
	  background-color: white;
  `);

 

  const [filternew,setfilternew] = useState(false);

  const { loginUserEmail, teamDetails, supportUsers, tokenMailID } = useContext(UserContext);
  const loginUserRole = teamDetails.current[loginUserEmail.current].Role;
//   console.log(loginUserRole)
	



const isCombinedRoadmapPresent = filterBy.includes('Combined Roadmap');
// console.log(row[12])

	return (
		<>

			<TableRow
				hover={true}
				sx={{

					backgroundColor: theme.palette.background.tableRow,
					'&:hover': {
						backgroundColor: theme.palette.background.tableRowHover + " !important"
					}
				}}
				onMouseEnter={() => setMoreVisible(true)}
				onMouseLeave={() => setMoreVisible(false)}
			>
				{server}
				{/* <TableCell align="center" style={{ width: "2.5vw", maxWidth: "2.5vw" }}>{index + 1}</TableCell> */}
				{setData ? (
					<TableCell align="center" style={{ width: "2.5vw", maxWidth: "2.5vw" }}>
						<Checkbox checked={isSelected} onChange={handleCheckboxChange} />
					</TableCell>
				) : (
					<TableCell align="center" style={{ width: "2.5vw", maxWidth: "2.5vw" }}>
						{index + 1}
					</TableCell>
				)}
					<TableCell align="center" className='slide-animation' style={{width: "2.5vw", maxWidth: "2.5vw"  }}>
				{row[12] !="-" ? (

					<IconButton size="small" onClick={handleClick}>

						{rowOpen === null ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon />}

					</IconButton>
							):(
								<div style={{ width: "2.5vw", maxWidth: "2.5vw" }}></div>
							)}
					 </TableCell>
		
				

               

				{["", " ", undefined, null].some(value => row[5] === value) ? (
					<TableCell align="left" style={{ maxWidth: "15vw", wordWrap: "break-word" }}>


					

						{row[9] == "Yes"? <> {row[0]}   <Chip label="Combined Roadmap" color="primary" className="custom-chip" /> </>:  row[0] }
				
						
						</TableCell>
				) : (

					// <Tooltip    title={<span style={{ fontFamily: 'Poppins' ,fontSize: '13px', color: "Black" ,backgroundImage:"" }}>{ row[5]} </span>}  followCursor >

					// <TableCell align="left" style={{maxWidth: "15vw", wordWrap: "break-word"}}>{row[0]}</TableCell>

					// </Tooltip>

					<TextOnlyTooltip title={<span style={{ fontFamily: 'Poppins', fontSize: '0.875rem', color: "Black" }}>{row[5]} </span>} followCursor >
						{/* <div style={{ marginBottom: "20px" }}> */}
						<TableCell align="left" style={{ maxWidth: "15vw", wordWrap: "break-word" }}>
							
						{row[9] == "Yes"? <> {row[0]}   <Chip label="Combined Roadmap" color="primary"      className="custom-chip"/> </>:  row[0] }
							
							</TableCell>
						{/* </div> */}
					</TextOnlyTooltip>





				)}
				<TableCell align="center" style={{ width: "10vw" }}><Box>{status}<p>{row[2]}</p></Box></TableCell>
				<TableCell align="center" style={{ width: "10vw" }}><Box>{priority}<p>{row[3]}</p></Box></TableCell>
				<TableCell align="right" style={{ width: "5vw" }} ><GetNameAndEmpID emails={row[4]} /></TableCell>
				{/* <TableCell></TableCell> */}
				
				<MoreSection className='slide-animation' moreVisibile={moreVisibile} setMoreVisible={setMoreVisible} creatorRowID={row[6]} rowsCurrent={rowsCurrent} setRows={setRows}
				filterBy={filterBy} setFilterBy={setFilterBy} selectMode={selectMode} setSelectMode={setSelectMode} creatorRowIDs={creatorRowIDs} setCreatorRowIDs={setCreatorRowIDs}
				setIsSelected={setIsSelected} setData={setData} setShowData={setShowData}/>
				  {isCombinedRoadmapPresent ? (
					<>{type}</>
					) : (
						null
					)}

			</TableRow>
			
			<TableRow className="info-div-row"
				sx={{
					backgroundColor: theme.palette.background.infoDivOuter + " !important"
				}}
			>
					<TableCell colSpan={8} style={{ padding: "0px"}}>
					
					<Collapse in={rowOpen} timeout="auto" unmountOnExit>
						{row[12] !="-" ? (
						<RoadMapLinkCards ChatUrls={row[11]} ChatDescription={row[12]} />
		            	):(null)}
						
					</Collapse>
					
				</TableCell>
				
			</TableRow>
		</>


	);
}

function MoreSection({ moreVisibile, setMoreVisible, creatorRowID, rowsCurrent, setRows, filterBy, setFilterBy, selectMode, setSelectMode, creatorRowIDs, setCreatorRowIDs,
	setIsSelected, setData, setShowData }) {

	const [moreAnchor, setMoreAnchor] = useState(null);
	const [showAlert1, setShowAlert1] = useState(false);
	const [showAlert2, setShowAlert2] = useState(false);
	const [showAlert3, setShowAlert3] = useState(false);


	async function handleEditClick() {
		setMoreAnchor(null);
		setShowAlert1(true);
		try {
			const result = await DeleteRecord();
			setShowAlert1(false);
			setShowAlert2(true);
			var rowsNew = [...rowsCurrent];
			var valueToRemove = creatorRowID;
			rowsNew = rowsNew.filter(row => !row.includes(valueToRemove));
			setRows(rowsNew);
		}
		catch (error) {

			setShowAlert1(false);
			setShowAlert3(true);
		}
	}

	async function DeleteRecord() {
		return new Promise((resolve, reject) => {
			updateRolesInCreator(creatorRowID)
				.then((result) => {
					// console.log(result);
					resolve("success");
				}).catch((err) => {
					// console.log(err);
					reject(err)
				});
		});
	}

	// useEffect(() => {
	//     if (showAlert1) {
	//         const timer = setTimeout(() => {
	//             setShowAlert1(false);
	//         }, 2000);

	//         return () => clearTimeout(timer);
	//     }
	// }, [showAlert1]);

	useEffect(() => {
		if (showAlert2) {
			const timer = setTimeout(() => {
				setShowAlert2(false);
			}, 3000);

			return () => clearTimeout(timer);
		}
	}, [showAlert2]);

	useEffect(() => {
		if (showAlert3) {
			const timer = setTimeout(() => {
				setShowAlert3(false);
			}, 2500);

			return () => clearTimeout(timer);
		}
	}, [showAlert3]);

	function handleDeleteSelect() {
		setSelectMode("delete");
		setMoreAnchor(null);
		setShowData(true);
		if(!creatorRowIDs.includes(creatorRowID)) {
			setCreatorRowIDs([...creatorRowIDs, creatorRowID]);
		}
		setTimeout(()=> {
			setIsSelected(true);
		}, 100);
	}



	return (
		<TableCell align="right" style={{ width: "5px", cursor: "pointer" }}>
			<Fade in={moreVisibile} timeout={100}>
				<MoreVertIcon onClick={(event) => setMoreAnchor(event.currentTarget)} />
			</Fade>
			<Menu anchorEl={moreAnchor}
				open={moreAnchor !== null}
				onClose={() => { setMoreAnchor(null) }}
			>
				<MenuItem>
					<Box style={{ display: "flex" }} onClick={handleDeleteSelect}>
						<CheckBox fontSize="small" />
						&nbsp;Select
					</Box>
				</MenuItem>
				<MenuItem onClick={handleEditClick}>
					<Box style={{ display: "flex" }}>
						<DeleteIcon fontSize="small" />

						&nbsp;Delete
					</Box>
				</MenuItem>
			</Menu>



			<Snackbar
				open={showAlert1}

				// onClose={() => setShowAlert1(false)}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}

			>
				<Alert severity="info">Deleting in progress...</Alert>
			</Snackbar>

			<Snackbar
				open={showAlert2}

				onClose={() => setShowAlert2(false)}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="success">Roadmap Deleted 👍 </Alert>


			</Snackbar>

			<Snackbar
				open={showAlert3}

				onClose={() => setShowAlert1(false)}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="error">Please try again after some time.</Alert>
			</Snackbar>





		</TableCell>
	);
}


export default MyTasks;

// ####################################################### util functions ###################################################################

function priorityCompareFunction(a, b) {
	const power = {
		"High": 3,
		"Medium": 2,
		"Low": 1,
		"None": 0
	};

	if (power[a[3]] < power[b[3]]) {
		return 1;
	}
	if (power[a[3]] > power[b[3]]) {
		return -1;
	}
	return 0;
}


function statusCompareFunction(a, b) {
	const power = {
		"Updated to IDC": 10,
		"Open": 9,
		"In Progress": 8,
		"Ongoing": 7,
		"To be Tested": 6,
		"In Review": 5,
		"On Hold": 4,
		"Delayed": 3,
		"Closed": 2,
		"Cancelled": 1,
		"Rmap": 0
	};

	if (power[a[2]] < power[b[2]]) {
		return 1;
	}
	if (power[a[2]] > power[b[2]]) {
		return -1;
	}
	return 0;
}

function serverCompareFunction(a, b) {
	if (a[1] < b[1]) {
		return -1;
	}
	if (a[1] > b[1]) {
		return 1;
	}
	return 0;
}



function tasknameCompareFunction(a, b) {
	if (a[0] < b[0]) {
		return -1;
	}
	else if (b[0] < a[0]) {
		return 1;
	}
	return 0;
};





function driCompareFunction(a, b) {
	if (a[4] < b[4]) {
		return -1;
	}
	else if (b[4] < a[4]) {
		return 1;
	}
	return 0;
}


async function setDriDropDown(setDriList, setDriTree, userDetails, teamDetails, dept, configuration) {
	const query = roadmap_component_get_query_5(dept);
	var result2 = await getFromTeamDetails(query);
	var head_parent = "srinivasan@zohocorp.com";
    try {
        const rootUsers = configuration.current[`department.rootUsers`];
        if(dept in rootUsers) {
            head_parent = configuration.current[`department.rootUsers`][dept];
        }
    } catch(e) {}
	// const head_root = "mariaraj@zohocorp.com";
	const head_root = [];
	const employees = result2;
	var hierarchicalData = [];
	hierarchicalData = createHierarchy(employees, head_parent);

	return new Promise((resolve, reject) => {
		const userEmail = userDetails.current.auth.email_id;
		const userRole = teamDetails.current[userEmail].Role;
		const userReportingTo = teamDetails.current[userEmail].ReportingTo;
		var users = [];
		if (userRole === "CEO" && head_root.includes[userEmail]) {
			for (const user in teamDetails.current) {
				users.push(user);
			}

			users.sort();
			setDriList(users);
			setDriTree(hierarchicalData);
		}

		else if (userRole === "CEO" && !head_root.includes(userEmail)) {
			for (const user in teamDetails.current) {
				users.push(user);
			}
			users.sort();
			setDriList(users);
			setDriTree(hierarchicalData);
			users = findMentees([userEmail], teamDetails);
			users.sort();
			users = [userEmail, ...users];
			setDriList(users);
			hierarchicalData = createHierarchy(employees, userReportingTo);
			const getDriList = [];
			getDriList.push(getMentorWithMentees(hierarchicalData, userEmail));
			var hierarchicalData11 = [];
			hierarchicalData11 = createHierarchy(employees, head_parent);
			hierarchicalData11.push(getDriList[0]);
			setDriTree(hierarchicalData11);
		}

		else {
			users = findMentees([userEmail], teamDetails);
			users.sort();
			users = [userEmail, ...users];
			setDriList(users);
			hierarchicalData = createHierarchy(employees, userReportingTo);
			const getDriList = [];
			getDriList.push(getMentorWithMentees(hierarchicalData, userEmail));
			setDriTree(getDriList);
		}
		return resolve();
	});
}



async function setServerDropDown(setServersList, userDetails, teamDetails, allServers) {
	return new Promise((resolve, reject) => {
		const userEmail = userDetails.current.auth.email_id;
		const userRole = teamDetails.current[userEmail].Role;

		if (userRole === "CEO") {
			setServersList(allServers);
		}
		else {
			setServersList(teamDetails.current[userEmail].Servers.split(","));



		}
		return resolve();
	});
}

function findMentees(userEmails, teamDetails) {
	if (userEmails.length === 0) {
		return [];
	}

	const arr = [];

	for (const person in teamDetails.current) {
		if (userEmails.includes(teamDetails.current[person].ReportingTo)) {
			arr.push(person);
		}
	}

	const newArr = arr.concat(findMentees(arr, teamDetails));

	return newArr;
}
function createHierarchy(data, reportingTo) {
	const hierarchy = [];

	for (const employee of data) {
		if (employee.ReportingTo === reportingTo) {
			const subordinates = createHierarchy(data, employee.Email);
			if (subordinates.length) {
				employee.mentees = subordinates;
			}
			hierarchy.push(employee);
		}
	}
	hierarchy.sort((a, b) => {
		const empIDA = parseInt(a.EmployeeID, 10);
		const empIDB = parseInt(b.EmployeeID, 10);
		return empIDA - empIDB;
	});
	// hierarchy.sort((a, b) => {
	// 	const empIDA = a.name.toLowerCase();
	// 	const empIDB = b.name.toLowerCase();
	// 	return empIDA.localeCompare(empIDB);
	//   });

	return hierarchy;
}

function getMentorWithMentees(hierarchy, mentorEmail) {
	for (const mentor of hierarchy) {
		if (mentor.Email === mentorEmail) {
			return mentor;
		} else if (mentor.mentees && mentor.mentees.length > 0) {
			const foundMentor = getMentorWithMentees(mentor.mentees, mentorEmail);
			if (foundMentor) {
				return foundMentor;
			}
		}
	}
	return null;
}