import MilestoneStat from './milestoneStat';
import TaskListStat from './taskListStat';
import TaskStat from './taskStat'
import { useTheme } from '@mui/material';
export default function AllDataStat({milestoneData,taskListData,taskData}) {
    
    const theme = useTheme();
    // console.log('milestoneData >>', milestoneData);
    // console.log('taskListData >>', taskListData);
    // console.log('taskData >>', taskData);
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',  // Adjust as needed for spacing
            alignItems: 'flex-start',        // Align items at the top
            gap: '10px' ,                    // Adjust gap between components
            color: theme.palette.mode === "dark" ? 'white': ''
        }}>
           <MilestoneStat milestoneData={milestoneData}/>
           <TaskListStat taskListData={taskListData}/>
           <TaskStat taskData={taskData}/>
        </div>
    );
}