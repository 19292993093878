export function getTimeRange(time, timeFrame) {

    var startDate = new Date();
    var endDate = new Date();

    if(timeFrame === "Daily") {
        if(time === "Past") {
            startDate.setDate(startDate.getDate() - 1);
            endDate.setDate(endDate.getDate() - 1);
        }
    } else if(timeFrame === "Weekly") {
        var dayOfTheWeek = startDate.getDay()-1;
        if(dayOfTheWeek === -1) {
            dayOfTheWeek = 6;
        }
        if(time === "Past") {
            startDate.setDate(startDate.getDate() - dayOfTheWeek - 7);
            endDate.setDate(endDate.getDate() - dayOfTheWeek - 1);
        } else {
            startDate.setDate(startDate.getDate() - dayOfTheWeek);
        }
    } else if(timeFrame === "Monthly") {
        var dayOfTheMonth = startDate.getDate();
        if(time === "Past") {
            endDate.setDate(endDate.getDate() - dayOfTheMonth);
            const daysInLastMonth = daysInMonth(endDate.getMonth(), endDate.getFullYear());
            startDate.setDate(startDate.getDate() - dayOfTheMonth + 1 - daysInLastMonth);
        } else {
            startDate.setDate(startDate.getDate() - dayOfTheMonth + 1);
        }
    }

    return [startDate, endDate];
}

function daysInMonth(month, year) {
    return new Date(parseInt(year), parseInt(month) + 1, 0).getDate();
}

export function formatDate(date) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
}

export function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}