
import React, { useState, useEffect, useContext, useRef } from 'react';
import { 
    Box,
    useTheme
} from '@mui/material';


import { TopBar } from './top_bar/TopBar';
import { TicketsTable } from './table/TicketsTable';
import { UserContext } from '../../../../contexts/UserContext';
import { ORDER_BY } from './top_bar/SortMenu';
import { executeQuery } from '../../../../util/QueryDB';
import { team_view_component_get_query_1,team_view_component_get_query_6 } from '../../../../catalystDB/queryGenerator';
import { filter } from 'd3';

const ROWS_PER_FETCH = 100;

export function TeamView({view}) {
    const {loginUserEmail, teamDetails, loginUserDept, serverConfiguration, configuration, allServers,GlobalServer} = useContext(UserContext);
    const theme = useTheme();
    // const userServers = teamDetails.current[loginUserEmail.current]?.Servers?.split(",");
    var userServers = (GlobalServer === "unknown" || GlobalServer === undefined) ?  teamDetails.current[loginUserEmail.current]?.Servers?.split(",")
    : GlobalServer;
    const [actions, setActions] = useState({
        search_text: view === "mine" ? loginUserEmail.current : undefined,
        show_closed: false,
        filters: {
            servers: view === "team" ?
            (GlobalServer === "unknown" || GlobalServer === undefined) ? userServers.filter((serv) => serv !== "Test" && allServers.includes(serv)) 
            : [GlobalServer]
            //  userServers.filter((serv) => serv !== "Test" && allServers.includes(serv)) 
             : 
             view === "department" && (GlobalServer != "unknown" && GlobalServer != undefined)? [GlobalServer] : []
             ,
            module: [],
            type: []
        },
        sort: undefined,
        // metadata
        meta: {
            filter_anchor: null,
            filter_server_anchor: null,
            filter_module_anchor: null,
            filter_type_anchor: null,
            sort_anchor: null
        }
    });
    const [currentFetchedRowsCount, setCurrentFetchedRowsCount] = useState(0); // set to -1 if all rows are fetched OR -2 if further fetching caused some error
    const [data, setData] = useState(undefined); // undefined -> fetch pending, null -> fetch failed
    var locked = useRef(false); // lazy load lock
    var ignore_list =  configuration.current[`tickets.ZVP.tickets_ignore_list`];
    if(ignore_list.includes(loginUserEmail.current)) {
        ignore_list = [];
    }
    useEffect(() => {
        setActions(
            {
                search_text: view === "mine" ? loginUserEmail.current : undefined,
                show_closed: false,
                filters: {
                    servers: view === "team" ?
                    (GlobalServer === "unknown" || GlobalServer === undefined) ? userServers.filter((serv) => serv !== "Test" && allServers.includes(serv)) 
                    : [GlobalServer]
                    //  userServers.filter((serv) => serv !== "Test" && allServers.includes(serv)) 
                     : 
                     view === "department" && (GlobalServer != "unknown" && GlobalServer != undefined)? [GlobalServer] : []
                     ,
                    module: [],
                    type: []
                },
                sort: undefined,
                // metadata
                meta: {
                    filter_anchor: null,
                    filter_server_anchor: null,
                    filter_module_anchor: null,
                    filter_type_anchor: null,
                    sort_anchor: null
                }
            }
        )

    }, [GlobalServer]);

    // initially fetching first 100 rows
    useEffect(() => {
        
        locked.current = false;
        setData(undefined);
        setCurrentFetchedRowsCount(0);
        const query = getQuery(actions, loginUserDept, serverConfiguration, 0+1, ignore_list, view,loginUserEmail);
        executeQuery(query).then(response => {
            if (response.length === 0) {
                setCurrentFetchedRowsCount(-1);
                setData([]);
            } else {
                setData(response.map(ticket => ticket.Tickets));
                if (response.length < ROWS_PER_FETCH) {
                    setCurrentFetchedRowsCount(-1);
                } else {
                    setCurrentFetchedRowsCount(currentFetchedRowsCount + response.length);
                }
            }
        }).catch(error => {
            console.log(error);
            setData(null);
        });

    }, [actions.search_text, actions.show_closed, actions.filters, actions.sort]);

    // console.log(actions.filters);

    // when scrolled to the bottom, 
    // fetching next 100 rows and extending the old data array if value is present,
    // if no value, currentFetchedRowsCount -> -1
    // if fetching further caused some error currentFetchedRowsCount -> -2
    async function getNextRows() {
        return new Promise((resolve, reject) => {
            if(currentFetchedRowsCount === -1 || currentFetchedRowsCount === -2 || data === undefined || data === null) {
                return resolve(false);
            }
            const query = getQuery(actions, loginUserDept, serverConfiguration, currentFetchedRowsCount+1, ignore_list,loginUserEmail);
            executeQuery(query).then(response => {
                if(response.length === 0) {
                    setCurrentFetchedRowsCount(-1);
                    return resolve(false);
                } else {
                    setData([...data, ...response.map(ticket => ticket.Tickets)]);
                    if(response.length < ROWS_PER_FETCH) {
                        setCurrentFetchedRowsCount(-1);
                        return resolve(false);
                    } else {
                        setCurrentFetchedRowsCount(currentFetchedRowsCount + response.length);
                        return resolve(true);
                    }
                }
            }).catch(error => {
                setCurrentFetchedRowsCount(-2);
                return resolve(false);
            });
        });
    }

    return (
        <Box className={`page-content-2`} sx={{color: theme.palette.background.textSecondary}}>
            <TopBar actions={actions} setActions={setActions} view={view} />
            <TicketsTable data={data} fetchedRowsCount={currentFetchedRowsCount} getNextRows={getNextRows} locked={locked} actions={actions} />
        </Box>
    );
}

function getQuery(actions, loginUserDept, serverConfiguration, offset, ignore_list, view,loginUserEmail) {
        // Get the current URL
        const url = window.location.href;

        // Use a regular expression to check if there's a number after "teamTickets/"
        const regex = /teamTickets\/(\d+)/;

        // Test the URL and extract the number
        const match = url.match(regex);

        if (match && match[1]) {
            const DeskTicketNumber = match[1];
            return team_view_component_get_query_6(actions,serverConfiguration,DeskTicketNumber,loginUserDept)
        } else {
            return team_view_component_get_query_1(actions, loginUserDept, serverConfiguration, offset, ignore_list, view, ORDER_BY, ROWS_PER_FETCH,loginUserEmail);
        }

    // return team_view_component_get_query_1(actions, loginUserDept, serverConfiguration, offset, ignore_list, view, ORDER_BY, ROWS_PER_FETCH,loginUserEmail);
}