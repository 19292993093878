/* eslint-disable react/prop-types */
import React from "react";
import { Carousel } from "./Report1.js";
import { useContext, useEffect, useRef, useState } from "react";
import { useTheme } from '@mui/material/styles';
import { Grid, Box } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { UserContext } from "../../../../contexts/UserContext.js";
import { getSection5Data } from "./ReportDataFetcher.js";
import CircularProgress from '@mui/material/CircularProgress';

export function ReportSection5({currentSelect}) {
    const myTheme = useTheme();
    const { allServers } = useContext(UserContext);

    var arr = [];
    for (var server of allServers) {
        arr.push(<LastWeek server={server} theme={myTheme} />);
    }

    return (
        <Carousel componentsList={arr} autoPlayEnabled={typeof currentSelect === "object"} currentSelect={currentSelect} />
    );
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.primary,
    height: "calc((100vh - 160px)/3)",
    display: "flex",
    justifyContent: "center",
    margin: "5px"
    // marginTop: "25px"
    // alignItems: "center"
}));

function LastWeek({ server, theme }) {

    const [dataArr, setDataArr] = useState(["-", "-", "-"]);
    const [loaded, setLoaded] = useState(false);
    const {loginUserDept} = useContext(UserContext);

    useEffect(() => {
		async function run() {
            try {
                var arr = await getSection5Data(server, loginUserDept);
                setDataArr(arr);
                setLoaded(true);
            } catch(e) {
                setLoaded(null);
            }
		}
		run();
	}, [theme.palette.mode, loaded]);


    if(loaded === null) {
        return <h1>uh oh! nothing found :(</h1>;
    }

    if(!loaded) {
        return <h1><CircularProgress /></h1>;
    }
    
    return (
        <Box style={{
            height: "100%",
            width: "100%"
        }}>
            <Box style={{
                fontWeight: 600,
                fontSize: "calc(1.0vw)",
                color: theme.palette.mode === "light" ? "black" : "white"
            }}>
                <span style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    flexDirection: "row"
                }}>LastWeek <ArrowRightIcon sx={{
                    fontSize: "calc(1.0vw)"
                }} /> {server}</span>
            </Box>
            <Box sx={{
                height: "calc(100%)",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Grid container spacing={1}>
                    <Grid item xs={4} style={{ textAlign: "center" }}>
                        <span>Task</span>
                        <Item sx={{
                            color: "rgb(228,37,39,1)",
                            backgroundColor: "rgb(228,37,39,0.08)",
                            "&:hover": {
                                backgroundColor: "rgb(228,37,39,0.2)"
                            },
                            lineHeight: 1
                        }}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "middle",
                                justifyContent: "center"
                            }}>
                                <Box sx={{
                                    fontSize: "calc(5vw)"
                                }}>
                                    {dataArr[0]}
                                </Box>
                                <Box>
                                    Completed
                                </Box>
                            </Box>
                        </Item>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "center" }}>
                        <span>Tasklist</span>
                        <Item sx={{
                            color: "rgb(8,153,73,1)",
                            backgroundColor: "rgb(8,153,73,0.08)",
                            "&:hover": {
                                backgroundColor: "rgb(8,153,73,0.2)"
                            },
                            lineHeight: 1
                        }}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "middle",
                                justifyContent: "center"
                            }}>
                                <Box sx={{
                                    fontSize: "calc(5vw)"
                                }}>
                                    {dataArr[1]}
                                </Box>
                                <Box>
                                    Completed
                                </Box>
                            </Box>
                        </Item>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "center" }}>
                        <span>Milestone</span>
                        <Item sx={{
                            color: "rgb(34, 109, 180, 1)",
                            backgroundColor: "rgb(34,109,180,0.08)",
                            "&:hover": {
                                backgroundColor: "rgb(34, 109, 180, 0.2)"
                            },
                            lineHeight: 1
                        }}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "middle",
                                justifyContent: "center"
                            }}>
                                <Box sx={{
                                    fontSize: "calc(5vw)"
                                }}>
                                    {dataArr[2]}
                                </Box>
                                <Box>
                                    Completed
                                </Box>
                            </Box>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    );
}
