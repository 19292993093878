// a function for grouping the array based on the given order(list of index of the column to group), pos=0 initially

// It works recursively (divide and conquer), 
// if order = [0, 1, 2]
// first arranges the rows into sub-set of groups based on 0th column
// then recursively calls itself to arrange the rows in the sub-set based on 1st column
// goes on until 2nd column
// then once the recursive call reaches the base condition, it returns the sub-set of grouped arrays
// finally it combines the sub-sets back to a set and returns the value
// @Manoj.S

/*
ex: let the arr be,
        [1, A, #]
        [2, A, &]
        [1, B, #]
        [1, A, *]

    let the order for grouping be [0, 1, ....] columns

                            .
                            |
                            | (groups based on 0th column)
                            v

                        [1, A, #]
                        [1, B, #]
                        [1, A, *]
                        [2, A, &]

                        /     \ 
                       /       \  (groups based on 1st column)
                       |       |
                       V       V

                [1, A, #]       [2, A, &]
                [1, A, *]
                [1, B, #]
                            .
                            .
                            .
                            .
                     \           /
                      \         /
                       \       /  (combines back to original sized array)
                        |     |
                        v     v

                       [1, A, #]
                       [1, A, *]
                       [1, B, #]
                       [2, A, &]
*/


export function group_by_order(arr, order, pos)    
{    
        //base condition           
        if(pos >= order.length)
        {                      
                return arr;
        }
                              
        var temp_map = {};           
        var grp_ind = order[pos];
        
        // encode to a map of groups       
        for(var i=0; i<arr.length; i++)
        {                            
                var row = arr[i];               
                var grp_elem = row[grp_ind];
                                            
                if(grp_elem in temp_map)
                {                                        
                        temp_map[grp_elem].push(row);
                }       
                else 
                {                                            
                        temp_map[grp_elem] = new Array();
                        temp_map[grp_elem].push(row);
                }
        }                              
        var temp_arr = new Array();        
        // decode it back into an array    
        for(var grp_elem in temp_map)
        {                                                 
                var grouped_arrs = temp_map[grp_elem];    
                grouped_arrs = group_by_order(grouped_arrs, order, pos+1); //recursive call to group that array based on order[pos+1]th column
                for(var row of grouped_arrs)
                {
                        temp_arr.push(row);
                }
        }
        arr = temp_arr;
        
        return arr;
}

// function that generates a reference table to set row span details
/*
 ex: 

 after group by order,

  [ 'A', '4', '^', 1 ],
  [ 'A', '4', '!', 1 ],
  [ 'A', '4', '!', 2 ],
  [ 'A', '4', '!', 1 ],
  [ 'A', '7', '&', 2 ],
  [ 'B', '1', '!', 1 ],
  [ 'B', '4', '!', 2 ],
  [ 'B', '4', '!', 2 ]

           | (finds the row span based on similar elements)
           v

  [ 5, 4, 1, 2 ],
  [ 0, 0, 3, 0 ],
  [ 0, 0, 0, 1 ],
  [ 0, 0, 0, 1 ],
  [ 0, 1, 1, 1 ],
  [ 3, 1, 3, 1 ],
  [ 0, 2, 0, 2 ],
  [ 0, 0, 0, 0 ]

          | (every column matches the grouping of their parents, 1st column will be grouped based on 0th column, 2nd column will be grouped based on 1st column and so on...
          v

  [ 5, 4, 1, 1 ],
  [ 0, 0, 3, 3 ],
  [ 0, 0, 0, 1 ],
  [ 0, 0, 0, 1 ],
  [ 0, 1, 1, 1 ],
  [ 3, 1, 1, 1 ],
  [ 0, 2, 2, 2 ],
  [ 0, 0, 0, 0 ]

*/

export function find_row_span(arr, order)
{
        var row = arr.length;
        var column = arr[0].length || 0;
	   // var column = arr[0].length;
        
        const zeros = (m, n) => [...Array(m)].map(e => Array(n).fill(0));
        var row_span_information = zeros(row, column);
        
        var count_arr = new Array(column).fill(0);
        //var value_arr = arr[row-1];
        
        for(var i=row-1; i>=0; i--)
        {
                for(var j=0; j<column; j++)
                {
                        if(i==0)
                        {
                                if(count_arr[j] == 0)
                                {
                                        row_span_information[i][j] = 1;
                                }   
                                else
                                {
                                        row_span_information[i][j] = count_arr[j]+1;
                                }
                        }   
                        else
                        {
                                var cur = arr[i][j];   
                                var prev = arr[i-1][j];
                                
                                if(cur == prev)
                                {
                                        count_arr[j]++;
                                }   
                                else
                                {
                                        row_span_information[i][j] = count_arr[j] + 1;
                                        count_arr[j] = 0;
                                }
                        }
                }
        }

        for(var j=1; j<column; j++)
        {
                if(!order.includes(j-1))
                {
                        continue;
                }
                var temp = 0;

                for(var i=0; i<row; i++)
                {
                        var cur = row_span_information[i][j];
                        var cur_parent = row_span_information[i][j-1];

                        if(cur_parent > 0 && cur === 0)
                        {
                                if(temp !== 0)
                                {
                                        row_span_information[i][j] = temp;
                                        temp = 0;
                                        cur = row_span_information[i][j];
                                }
                        }

                        if((cur_parent < cur && cur_parent !== 0))
                        {
                                temp = (cur - cur_parent);
                                row_span_information[i][j] = row_span_information[i][j-1];
                        }

                }
        }

        return row_span_information; 
}
