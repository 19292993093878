/* eslint-disable react/prop-types */ 
import React from "react";
import { useEffect, useRef, useState, useContext } from "react";
import { UserContext } from "../../../../../contexts/UserContext";
import { useTheme } from '@mui/material/styles';
import { Box, ButtonGroup, Skeleton, Tab, Button } from "@mui/material";
import { ReportContext } from "../newReportGeneral/NewReport";
import { getMilestoneRows } from "./ReportSection1DataFetcher"; 

import AirIcon from '@mui/icons-material/Air';
import { MilestoneCards } from "./MilestoneCards";
import { IOSSwitch } from "../newReportGeneral/NewReportUtilComponents";
import {Grid} from "@mui/material";

import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export function ReportSection1({setExpanded}) {
    const theme = useTheme();
    const [toggleOngoing, setToggleOngoing] = useState(true);
    const {loginUserDept} = useContext(UserContext);

    function handleToggleChange(state) {
        if(state) {
            if(state === "ongoing") {
                setToggleOngoing(true);
            } else {
                setToggleOngoing(false);
            }
        } else {
            setToggleOngoing(!toggleOngoing);
        }
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            width: "100%",
            height: "98%",
            gap: "10px"
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.1rem",
                fontWeight: "bold"
            }}>
                <Grid container spacing={0.5} sx={{ height: "100%" }}>
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={4}>
                        <Box style={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex"
                        }}>
                            Milestone Progress
                            <ArrowRightIcon />
                            {
                                toggleOngoing ? 
                                    "Ongoing" 
                                    :
                                    "Completed"
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box style={{
                            float: "right",
                            paddingRight: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            {/* <span style={{ marginRight: "10px", fontSize: "0.8rem" }}>
                                {toggleOngoing ? 
                                    "you are currently viewing Ongoing Milestones"
                                    :
                                    "you are currently viewing Completed Milestones"
                                }   
                            </span>
                            <IOSSwitch checked={toggleOngoing} onChange={handleToggleChange} /> */}
                            <ButtonGroup size="small">
                                <Button sx={{
                                    borderRadius: "40px",
                                    textTransform: "none"
                                }} variant={toggleOngoing ? "contained" : "outlined"} 
                                    onClick={() => handleToggleChange("ongoing")}
                                >
                                    Ongoing
                                </Button>
                                <Button sx={{
                                    borderRadius: "40px",
                                    textTransform: "none"
                                }} variant={toggleOngoing ? "outlined" : "contained"}
                                    onClick={() => handleToggleChange("completed")}
                                >
                                    Completed
                                </Button>
                            </ButtonGroup>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <ReportBody toggleOngoing={toggleOngoing} setToggleOngoing={setToggleOngoing}/>
        </Box>
       
    );
}

function ReportBody({toggleOngoing, setToggleOngoing}) {

    const {
        subjectType, 
        setSubjectType, 
        subject, 
        setSubject, 
        timeFrame, 
        setTimeFrame, 
        time, 
        setTime,
        timeRange,
        setTimeRange
    } = useContext(ReportContext);
    const theme = useTheme();
    const {teamDetails, loginUserDept} = useContext(UserContext);
    
    const [loaded, setLoaded] = useState(undefined);
    const [milestoneRows, setMilestoneRows] = useState(undefined);
    
    useEffect(() => {
        const controller = new AbortController();
        async function pullMilestoneRows() {

            getMilestoneRows(timeRange, subjectType, subject, teamDetails, loginUserDept).then((response) => {
                if(!controller.signal.aborted) {
                    if(response.length !== 0) {
                        setMilestoneRows(response);
                        setLoaded(true);
                    } else {
                        setLoaded(false);
                    }
                }
            }).catch((error) => {
                if(!controller.signal.aborted) {
                    setLoaded(false);
                }
            });
        }

        setLoaded(undefined);
        pullMilestoneRows();

        return () => {
            controller.abort();
        };

    }, [timeRange, subjectType,subject]);


    if(loaded === undefined) {
        return (
            <Skeleton variant={"rounded"} height={"98%"} width={"100%"} animation="wave"/>
        );
    } else if(loaded === false) {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%"
            }}>
                <Box>
                    <AirIcon sx={{
                        fontSize: "6rem",
                        color: theme.palette.mode === "light" ? "rgb(20, 20, 20, 0.06)": "rgb(200, 200, 200, 0.06)"
                    }} />
                </Box>
                <Box sx={{
                    fontSize: "0.7rem",
                    color: theme.palette.mode === "light" ? "rgb(10, 10, 10, 0.3)": "rgb(200, 200, 200, 0.4)"
                }}>
                    Nothing found
                </Box>
            </Box>
        );
    } else {
        return (
            <Box  sx={{
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                overflowY: "scroll",
                boxSizing: "border-box",
                backgroundColor: theme.palette.mode === 'dark' ? 'rgb(24,30,35)' : '#FAFAFA',
                borderRadius: "12px"
            }}
            >
                <MilestoneCards milestoneRows={milestoneRows} toggleOngoing={toggleOngoing} setToggleOngoing={setToggleOngoing}/>
            </Box>
        );
    }
}