import { TASK_BREAKER_DATA_FETCHER_1_DEFAULT, TASK_BREAKER_DATA_FETCHER_2_DEFAULT } from "../../../catalystDB/queryGenerator";
import { queryDB } from "../../../util/QueryDB";

export function getFromTaskBreaker(queryString = TASK_BREAKER_DATA_FETCHER_1_DEFAULT)
{
	return new Promise((resolve, reject) => 
	{
		const start = global.performance.now();

		queryDB(queryString).then((response) => {
			// console.log(response);
			const end = global.performance.now();
			// console.log(`took ${end-start} ms`);

			var data = [];
			for(const item of response)
			{
				const row = item.TaskBreaker;
				const result = [
					[undefined, "", " "].includes(row.MilestoneName) ? "-" : row.MilestoneName,
					[undefined, "", " "].includes(row.MilestoneType) ? "-" : row.MilestoneType,
					[undefined, "", " "].includes(row.Component) ? "-" : row.Component,
					!(["High", "Low", "Medium"].includes(row.TaskPriority)) ? "None" : row.TaskPriority,
					row.TaskName,
					[undefined, "", " "].includes(row.TaskStatus) ? "-" : row.TaskStatus,
					[undefined, "", " "].includes(row.DRI) ? "-" : row.DRI,
					[undefined, "", " "].includes(row.ReportingTo) ? "-" : row.ReportingTo,
					row.TaskDescription,
					row.TaskBreakerRoadmapRowid,
					[undefined, "", " "].includes(row.TaskCompletionDate) ? "-" : row.TaskCompletionDate,
					row.Email
					
				];
				data.push(result);
			}
			if(data.length === 0)
			{
				reject("none");
			}
			resolve(data);
		}).catch((error) => {
			reject(error);
		})

		// var zcql = window.catalyst.ZCatalystQL;
		// var zcqlPromise  = zcql.executeQuery(queryString);
		// zcqlPromise
		// 		.then((response) => {
		// 			// console.log(response);
		// 			const end = global.performance.now();
		// 			// console.log(`took ${end-start} ms`);

		// 			if(response.status === 400)
		// 			{
		// 				reject("error");
		// 			}
	
		// 			var data = [];
		// 			for(const item of response.content)
		// 			{
		// 				const row = item.TaskBreaker;
		// 				const result = [
		// 					[undefined, "", " "].includes(row.MilestoneName) ? "-" : row.MilestoneName,
		// 					[undefined, "", " "].includes(row.MilestoneType) ? "-" : row.MilestoneType,
		// 					[undefined, "", " "].includes(row.Component) ? "-" : row.Component,
		// 					!(["High", "Low", "Medium"].includes(row.TaskPriority)) ? "None" : row.TaskPriority,
		// 					row.TaskName,
		// 					[undefined, "", " "].includes(row.TaskStatus) ? "-" : row.TaskStatus,
		// 					[undefined, "", " "].includes(row.DRI) ? "-" : row.DRI,
		// 					[undefined, "", " "].includes(row.ReportingTo) ? "-" : row.ReportingTo,
		// 					row.TaskDescription
		// 				];
		// 				data.push(result);
		// 			}
		// 			if(data.length === 0)
		// 			{
		// 				reject("none");
		// 			}
		// 			resolve(data);
		// 		})
		// 		.catch((err) => {
		// 			reject(err);
		// 		});
	});
}

export function getFromTeamDetails(queryString = TASK_BREAKER_DATA_FETCHER_2_DEFAULT)
{
	return new Promise((resolve, reject) => {
		const start = global.performance.now();

		queryDB(queryString).then((response) => {
			//console.log(response);
			//const end = global.performance.now();
			//console.log(`took ${end-start} ms`);

			var data = [];
			//console.log(response.content);
			for(const item of response)
			{
				const row = item.TeamDetails;
				
				const result = {
					Email : [undefined, "", " "].includes(row.Email) ? "no email" : row.Email,
					name : [undefined, "", " "].includes(row.PeopleUserName) ? "no name" : row.PeopleUserName,
					ReportingTo : [undefined, "", " "].includes(row.ReportingTo) ? "no mentor" : row.ReportingTo,
					EmployeeID : [undefined, "", " "].includes(row.EmployeeID) ? "no empID" : row.EmployeeID,
					zuid : [undefined, "", " "].includes(row.zuid) ? "no zuid" : row.zuid,
					Role : [undefined, "", " "].includes(row.Role) ? "no role" : row.Role,
					image : `https://contacts.zoho.com/file?ID=${row.zuid}&exp=6000&fs=thumb`
				};
				//console.log(result);
				data.push(result);
			}
			if(data.length === 0)
			{
				reject("none");
			}
			resolve(data);
		}).catch((error) => {
			reject(error);
		})
	});
}


export function taskBreakerTransformFunction(response) {
	// console.log(response);
	var data = [];
	for(const item of response)
	{
		const row = item.TaskBreaker;
		const result = [
			[undefined, "", " "].includes(row.MilestoneName) ? "-" : row.MilestoneName,
			[undefined, "", " "].includes(row.MilestoneType) ? "-" : row.MilestoneType,
			[undefined, "", " "].includes(row.Component) ? "-" : row.Component,
			!(["High", "Low", "Medium"].includes(row.TaskPriority)) ? "None" : row.TaskPriority,
			row.TaskName,
			[undefined, "", " "].includes(row.TaskStatus) ? "-" : row.TaskStatus,
			[undefined, "", " "].includes(row.DRI) ? "-" : row.DRI,
			[undefined, "", " "].includes(row.ReportingTo) ? "-" : row.ReportingTo,
			row.TaskDescription,
			row.TaskBreakerRoadmapRowid,
			[undefined, "", " "].includes(row.TaskCompletionDate) ? "-" : row.TaskCompletionDate,
			row.Email
		];
		data.push(result);
	}
	// console.log(data);
	if(data.length === 0)
	{
		return undefined;
	}
	return data;
}
