/* eslint-disable react/prop-types */ 
import React from "react";
import { useEffect, useRef, useState, useContext } from "react";
import { UserContext } from "../../../../contexts/UserContext";
import { useTheme } from '@mui/material/styles';
import { Box, Tab } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';
import { Skeleton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from "@mui/material/Button";
import {Chip} from "@mui/material";
import DnsIcon from '@mui/icons-material/Dns';
import CancelIcon from '@mui/icons-material/Cancel';
import StarsIcon from '@mui/icons-material/Stars';
import TaskIcon from '@mui/icons-material/Task';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AirIcon from '@mui/icons-material/Air';
import Tooltip from '@mui/material/Tooltip';

import { queryDB } from "../../../../util/QueryDB";
import { 
    getNestedReportMilestoneUnderServer,
    getNestedReportServerData,
    getNestedReportTasklistUnderMilestone,
    getNestedReportTaskUnderTasklist
} from "../../../../pageBody/pageContent/report/Report1/ReportDataFetcher.js";
import { GetNameAndEmpID } from "../../../../util/Loading";
import PendingIcon from '@mui/icons-material/Pending';

export function Component({currentSelect, milestone_name}) {
    const myTheme = useTheme();
    //console.log(milestone_name);
    return (
        <NestedViewReport theme={myTheme} currentSelect={currentSelect} milestone_name={milestone_name}/>
    );
}

function NestedViewReport({theme, currentSelect, milestone_name}) {
    const [server, setServer] = useState(null);
    const [milestone, setMilestone] = useState(milestone_name === undefined ? null : milestone_name);
    const [tasklist, setTasklist] = useState(null);
    const [activeView, setActiveView] = useState(null);
    var lightMode = theme.palette.mode === "light";

    useEffect(() => {
        if(currentSelect === "Default" || typeof currentSelect === "object") {
            setServer(null);
            return;
        }
        setServer(currentSelect);
    }, [currentSelect, milestone_name]);

    useEffect(() => {
        if(milestone_name !== undefined) {
            setMilestone(milestone_name);
            setTasklist(null);
        }
    }, [milestone_name]);

    var breadcrumbsArr = [];
    var comp = <ServerView setServer={setServer}/>;
    if(server !== null) {
        comp = <MilestoneView server={server} setMilestone={setMilestone}/>;
        breadcrumbsArr.push(<CustomChip icon={<DnsIcon fontSize="small"/>} item={server} type={"server"} close={milestone_name !== undefined ? true: undefined} onClose={() => {
            setServer(null);
            setMilestone(null);
            setTasklist(null);
        }}/>);
    }
    if(milestone !== null) {
        comp = <TasklistView server={server} milestone={milestone} setTasklist={setTasklist}/>;
        breadcrumbsArr.push(<CustomChip icon={<StarsIcon fontSize="small"/>} item={milestone} type={"milestone"} onClose={() => {
            setMilestone(null);
            setTasklist(null);
        }}/>);
    }
    if(tasklist !== null) {
        comp = <TaskView server={server} milestone={milestone} tasklist={tasklist}/>;
        breadcrumbsArr.push(<CustomChip icon={<TaskIcon fontSize="small" />} item={tasklist} type={"tasklist"} onClose={() => {
            setTasklist(null);
        }}/>);
    }

    return (
        <Box style={{
            height: "98%",
            width: "100%",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column"
        }}>
            <Box style={{
                height: "30px",
                padding: "3px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
                border: lightMode ? "1px solid honeydew" : "",
                borderRadius: "6px",
                boxShadow: lightMode ? "1px 1px 10px 1px mintcream" : "",
                overflowX: "scroll",
                overflowY: "hidden",
                display: breadcrumbsArr.length !== 0 ? "" : "none"
            }} className="nested-view-overflow-container">
                <Breadcrumbs separator={<ArrowRightIcon />} fontSize="small">
                    {
                        breadcrumbsArr
                    }
                </Breadcrumbs>
                <div style={{
                }} className="unselectable">
                    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                </div>
            </Box>
            <Box style={{
                backgroundColor: server === null ? lightMode ? "whitesmoke" : theme.palette.background.infoDivOuter : "",
                paddingTop: "0px",
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                border: lightMode ? "1px solid honeydew" : "",
                borderRadius: "6px",
                boxShadow:  lightMode ? "1px 1px 10px 1px mintcream" : "",
                overflowY: "scroll",
                borderRadius: "15px"
            }} className={server === null ? "vertical-view-overflow-container":""}>
                {comp}
                {/* <Box onClick={() => {
                    setServer("AWServer");
                }}>
                    Server
                </Box>
                <Box onClick={() => {
                    setMilestone("AWServer_Feature_Milestone_1");
                }}>
                    Milestone
                </Box>
                <Box onClick={() => {
                    setTasklist("Test Tasklist no 1");
                }}>
                    tasklist
                </Box> */}
            </Box>
        </Box>
    );
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.3),
    textAlign: 'center',
    color: theme.palette.text.primary,
    height: "50px"
}));

function ServerView({setServer}) {
    const {allServers} = useContext(UserContext);
    console.log(setServer)
    return (
        <Box sx={{
            width: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
            padding: "15px",
            paddingLeft: "10px",
            paddingRight: "10px",
            boxSizing: "border-box",
            height: "98%"
        }}>
            <Grid container rowSpacing={1.2} columnSpacing={{ xs: 1, sm: 1, md: 0.8 }}>
                {allServers.map((server, index) => {
                    return (
                        <ServerViewCard server={server} setServer={setServer} key={index}/>
                    );
                })}              
            </Grid>
        </Box>
    );
}

function MilestoneView({server, setMilestone}) {
    const [loaded, setLoaded] = useState(null);
    const [onTimeMilestones, setOnTimeMilestones] = useState(null);
    const [delayedMilestones, setDelayedMilestones] = useState(null);
    const {loginUserDept} = useContext(UserContext);

    useEffect(() => {
        async function fetchData() {
            setLoaded(null);
            const response = await getNestedReportMilestoneUnderServer(server,loginUserDept);
            // console.log(response);

            var onTimeTemp = [];
            var delayedTemp = [];
            var onTimeCompTemp = [];
            var delayedCompTemp = [];

            try {
                response.map(object => {
                    // var startDate = new Date(object.TaskDetails.MilestoneStartDate_);
                    var dueDate = new Date(object.TaskDetails.MilestoneDueDate_);
                    var completionDate = new Date(object.TaskDetails.MilestoneCompletionDate);
                    var days = dateDiffInDays(new Date(), dueDate);
                    var milestoneName = (
                        <Box style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "5px"
                        }}>
                             <PendingIcon fontSize="small" className={days >= 0 ? "green-check-mark" : "red-check-mark"}/>
                            {object.TaskDetails.MilestoneName}
                        </Box>
                    );

                    var label1 = "Days Left";
                    var label2 = "Overdue";
                    if(object.TaskDetails.MilestoneCompletionDate !== null) {
                        var days = dateDiffInDays(completionDate, dueDate);
                        milestoneName =  (
                            <Box style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px"
                            }}>
                                <CheckCircleIcon fontSize="small" className={days >= 0 ? "green-check-mark" : "red-check-mark"}/>
                                {object.TaskDetails.MilestoneName}
                            </Box>
                        );
                        label1 = "Earlier By";
                        label2 = "Late By";
                    }

                    if (days >= 0) {
                        var obj = {
                            "name": milestoneName,
                            "email": object.TaskDetails.Email,
                            "content": {
                                "label": label1,
                                "value": days
                            },
                            "color": "rgb(8,153,73,1)",
                            "onClick": () => setMilestone(object.TaskDetails.MilestoneName)
                        };
                        if(label1 === "Days Left") {
                            onTimeTemp.push(obj);
                        } else {
                            onTimeCompTemp.push(obj);
                        }
                    } else {
                        var obj = {
                            "name": milestoneName,
                            "email": object.TaskDetails.Email,
                            "content": {
                                "label": label2,
                                "value": -1 * days
                            },
                            "color": "rgba(177, 44, 9, 1)",
                            "onClick": () => setMilestone(object.TaskDetails.MilestoneName)
                        };
                        if(label2 === "Overdue") {
                            delayedTemp.push(obj);
                        } else {
                            delayedCompTemp.push(obj);
                        }
                    }

                    setOnTimeMilestones([...onTimeTemp, ...onTimeCompTemp]);
                    setDelayedMilestones([...delayedTemp, ...delayedCompTemp]);
                })
                setLoaded(true);
            } catch (e) {
                setLoaded(false);
                return;
            }
        }

        fetchData();
    }, [server]);
    
    return (
        loaded === null ? 
            <Box style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <Skeleton height={"50%"} width={"95%"} variant="rectangular" />
                <Skeleton height={"50%"} width={"95%"} variant="rectangular" />
            </Box>
            :
            loaded === false ?
                <CompletionStatus onTimeItems={null} delayedItems={null} />
                :
                <CompletionStatus onTimeItems={onTimeMilestones} delayedItems={delayedMilestones} />
    );
}

function TasklistView({server, milestone, setTasklist}) {
    const [loaded, setLoaded] = useState(null);
    const [onTimeTasklist, setOnTimeTasklist] = useState(null);
    const [delayedTasklist, setDelayedTasklist] = useState(null);
    const {loginUserDept} = useContext(UserContext);

    useEffect(() => {
        setLoaded(null);
        setOnTimeTasklist(null);
        setDelayedTasklist(null);
        async function fetchData() {
            setLoaded(null);
            const response = await getNestedReportTasklistUnderMilestone(milestone,loginUserDept);
            // console.log(response);

            var onTimeTemp = [];
            var delayedTemp = [];
            var onTimeCompTemp = [];
            var delayedCompTemp = [];

            try {
                response.map(object => {
                    // var startDate = new Date(object.TaskDetails.MilestoneStartDate_);
                    var dueDate = new Date(object.TaskDetails.TasklistDueDate_);
                    var completionDate = new Date(object.TaskDetails.TasklistCompletionDate);
                    var days = dateDiffInDays(new Date(), dueDate);
                    var tasklistName = (
                        <Box style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "5px"
                        }}>
                             <PendingIcon fontSize="small" className={days >= 0 ? "green-check-mark" : "red-check-mark"}/>
                            {object.TaskDetails.TasklistName}
                        </Box>
                    );

                    var label1 = "Days Left";
                    var label2 = "Overdue";
                    if(object.TaskDetails.TasklistCompletionDate !== null) {
                        var days = dateDiffInDays(completionDate, dueDate);
                        tasklistName =  (
                            <Box style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px"
                            }}>
                                <CheckCircleIcon fontSize="small" className={days >= 0 ? "green-check-mark" : "red-check-mark"}/>
                                {object.TaskDetails.TasklistName}
                            </Box>
                        );
                        label1 = "Earlier By";
                        label2 = "Late By";
                    }

                    if (days >= 0) {
                        var obj = {
                            "name": tasklistName,
                            "email": object.TaskDetails.DRI,
                            "content": {
                                "label": label1,
                                "value": days
                            },
                            "color": "rgb(8,153,73,1)",
                            "onClick": () => setTasklist(object.TaskDetails.TasklistName)
                        };
                        if(label1 === "Days Left") {
                            onTimeTemp.push(obj);
                        } else {
                            onTimeCompTemp.push(obj);
                        }
                    } else {
                        var obj = {
                            "name": tasklistName,
                            "email": object.TaskDetails.DRI,
                            "content": {
                                "label": label2,
                                "value": -1 * days
                            },
                            "color": "rgba(177, 44, 9, 1)",
                            "onClick": () => setTasklist(object.TaskDetails.TasklistName)
                        };
                        if(label2 === "Overdue") {
                            delayedTemp.push(obj);
                        } else {
                            delayedCompTemp.push(obj);
                        }
                    }

                    setOnTimeTasklist([...onTimeTemp, ...onTimeCompTemp]);
                    setDelayedTasklist([...delayedTemp, ...delayedCompTemp]);
                })
                setLoaded(true);
            } catch (e) {
                setLoaded(false);
                return;
            }
        }

        fetchData();
    }, [server, milestone]);

    return (
        loaded === null ? 
            <Box style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <Skeleton height={"50%"} width={"95%"} variant="rectangular" />
                <Skeleton height={"50%"} width={"95%"} variant="rectangular" />
            </Box>
            :
            loaded === false ?
                <CompletionStatus onTimeItems={null} delayedItems={null} />
                :
                <CompletionStatus onTimeItems={onTimeTasklist} delayedItems={delayedTasklist} />
    );
}

function TaskView({server, milestone, tasklist}) {
    const [loaded, setLoaded] = useState(null);
    const [onTimeTasks, setOnTimeTasks] = useState(null);
    const [delayedTasks, setDelayedTasks] = useState(null);
    const {loginUserDept} = useContext(UserContext);

    useEffect(() => {
        async function fetchData() {
            setLoaded(null);
            const response = await getNestedReportTaskUnderTasklist(milestone, tasklist,loginUserDept);
            // console.log(response);

            var onTimeTemp = [];
            var delayedTemp = [];
            var onTimeCompTemp = [];
            var delayedCompTemp = [];

            try {
                response.map(object => {
                    // var startDate = new Date(object.TaskDetails.MilestoneStartDate_);
                    var dueDate = new Date(object.TaskBreaker.TaskDueDate_);
                    var completionDate = new Date(object.TaskBreaker.TaskCompletionDate);
                    var days = dateDiffInDays(new Date(), dueDate);
                    var taskName = (
                        <Box style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "5px"
                        }}>
                             <PendingIcon fontSize="small" className={days >= 0 ? "green-check-mark" : "red-check-mark"}/>
                            {object.TaskBreaker.TaskName}
                        </Box>
                    );

                    var label1 = "Days Left";
                    var label2 = "Overdue";
                    if(object.TaskBreaker.TaskCompletionDate !== null) {
                        var days = dateDiffInDays(completionDate, dueDate);
                        taskName =  (
                            <Box style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px"
                            }}>
                                <CheckCircleIcon fontSize="small" className={days >= 0 ? "green-check-mark" : "red-check-mark"}/>
                                {object.TaskBreaker.TaskName}
                            </Box>
                        );
                        label1 = "Earlier By";
                        label2 = "Late By";
                    }

                    if (days >= 0) {
                        var obj = {
                            "name": taskName,
                            "email": object.TaskBreaker.DRI,
                            "content": {
                                "label": label1,
                                "value": days
                            },
                            "color": "rgb(8,153,73,1)",
                            "onClick": () => {}
                        };
                        if(label1 === "Days Left") {
                            onTimeTemp.push(obj);
                        } else {
                            onTimeCompTemp.push(obj);
                        }
                    } else {
                        var obj = {
                            "name": taskName,
                            "email": object.TaskBreaker.DRI,
                            "content": {
                                "label": label2,
                                "value": -1 * days
                            },
                            "color": "rgba(177, 44, 9, 1)",
                            "onClick": () => {}
                        };
                        if(label2 === "Overdue") {
                            delayedTemp.push(obj);
                        } else {
                            delayedCompTemp.push(obj);
                        }
                    }

                    setOnTimeTasks([...onTimeTemp, ...onTimeCompTemp]);
                    setDelayedTasks([...delayedTemp, ...delayedCompTemp]);
                })
                setLoaded(true);
            } catch (e) {
                setLoaded(false);
                return;
            }
        }

        fetchData();
    }, [server, milestone, tasklist]);

    return (
        loaded === null ? 
            <Box style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <Skeleton height={"50%"} width={"95%"} variant="rectangular" />
                <Skeleton height={"50%"} width={"95%"} variant="rectangular" />
            </Box>
            :
            loaded === false ?
                <CompletionStatus onTimeItems={null} delayedItems={null} />
                :
                <CompletionStatus onTimeItems={onTimeTasks} delayedItems={delayedTasks} />
    );
    // var onTimeTasks = [
    //     {
    //         "name": "Task 1",
    //         "content": {
    //             "label": "Earlier By",
    //             "value": 10
    //         },
    //         "color": "rgb(8,153,73,1)"
    //     }
    // ];
    // var delayedTasks = [
    //     {
    //         "name": "Task 3",
    //         "content": {
    //             "label": "Late By",
    //             "value": 10
    //         },
    //         "color": "rgba(177, 44, 9, 1)"
    //     }
    // ];
}

function CustomChip({item, type, icon, onClose, close}) {
    var colors = {
        "server": {
            "icon": "#53291c",
            "border": "#53291c",
            "background": "rgba(177,44,9,1)"
        },
        "milestone": {
            "icon": "#11375c",
            "border": "#11375c",
            "background": "rgb(34, 109, 180, 1)"
        },
        "tasklist": {
            "icon": "#084d27",
            "border": "#084d27",
            "background": "rgb(8,153,73,1)"
        }
    }
    return (
        <Chip
            label={
                <Box style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <Box style={{
                        color: colors[type].icon,
                        borderRight: "1px solid "+colors[type].border,
                        paddingRight: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        lineHeight: 1
                    }}>
                        {icon}
                    </Box>
                    <Box style={{
                        paddingLeft: "6px",
                        color: "ghostwhite",
                        lineHeight: 1
                    }}>
                        <p style={{
                            fontSize: "12px",
                            margin: 0,
                            lineHeight: 1
                        }}>
                            {item}
                        </p>
                    </Box>
                </Box>
            }
            sx={{
                background: colors[type].background + " !important",
                border: "1px solid " + colors[type].border
            }}
            onDelete={close !== undefined ?
                null
                :onClose}
            deleteIcon={
                <CancelIcon
                    onMouseDown={(event) => event.stopPropagation()}
                    sx={{
                        color: colors[type].border + " !important"
                    }}
                    fontSize="small"
                />
            }
        />
    );
}

function ServerViewCard({server, setServer}) {

    var [onTimeCount, setOnTimeCount] = useState(null);
    var [delayedCount, setDelayedCount] = useState(null);
    var [loaded, setLoaded] = useState(null);
    const {loginUserDept} = useContext(UserContext);

    useEffect(() => {
		async function run() {
            try {
                setLoaded(null);
                const result = await getNestedReportServerData(server,loginUserDept);
                setOnTimeCount(result[0]);
                setDelayedCount(result[1]);
                setLoaded(true);
            } catch(e) {
                setLoaded(false);
            }  
		}
		run();
	}, []);

    if(onTimeCount === '0' && delayedCount === '0') {
        return (null);
    }

    return (
        <Grid xs={4}>
            <Item>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    gap: "5px",
                    cursor: "pointer"
                }} onClick={() => setServer(server)}>
                    <Box style={{
                        flex: 1,
                        height: "100%",
                        padding: "10px",
                        boxSizing: "border-box",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                        whiteSpace: "nowrap",
                        overflowX: "scroll"
                    }} className={"nested-view-overflow-container"}>
                        {server}
                    </Box>
                    {
                        loaded === null ?
                            <Skeleton variant="rectangular" height={"90%"} width={"80px"} sx={{ borderRadius: "6px" }} />
                            :
                            loaded === false ?
                                <div>-</div>
                                :
                                <Box style={{
                                    width: "80px",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    padding: "3px",
                                    boxSizing: "border-box",
                                    justifyContent: "center",
                                    backgroundColor: "rgba(8, 153, 73, 0.1)",
                                    borderRadius: "6px"
                                }}>
                                    <Box sx={{
                                        fontSize: "0.6rem",
                                        paddingBottom: 0,
                                        paddingTop: "2px",
                                        color: "rgb(8,153,73,1)"
                                    }}>
                                        On Time
                                    </Box>
                                    <Box sx={{
                                        flex: 1,
                                        fontSize: "1.8rem",
                                        lineHeight: 1,
                                        paddingBottom: "2px",
                                        width: "92%",
                                        overflowX: "scroll",
                                        overflowY: "hidden",
                                        color: "rgb(8,153,73,1)",
                                        fontWeight: "600"
                                    }} className="nested-view-overflow-container">
                                        {onTimeCount}
                                    </Box>
                                </Box>
                    }
                    {
                        loaded === null ?
                            <Skeleton variant="rectangular" height={"90%"} width={"80px"} sx={{ borderRadius: "6px" }} />
                            :
                            loaded === false ?
                                <div>-</div>
                                :
                                <Box style={{
                                    width: "80px",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: "3px",
                                    boxSizing: "border-box",
                                    backgroundColor: "rgba(177,44,9, 0.1)",
                                    borderRadius: "6px"
                                }}>
                                    <Box sx={{
                                        fontSize: "0.6rem",
                                        paddingBottom: 0,
                                        paddingTop: "2px",
                                        color: "rgb(177,44,9) !important"
                                    }}>
                                        Delayed
                                    </Box>
                                    <Box sx={{
                                        flex: 1,
                                        fontSize: "1.8rem",
                                        lineHeight: 1,
                                        paddingBottom: "2px",
                                        paddingTop: 0,
                                        width: "92%",
                                        overflowX: "scroll",
                                        overflowY: "hidden",
                                        color: "rgb(177,44,9) !important",
                                        fontWeight: "600"
                                    }} className="nested-view-overflow-container">
                                        {delayedCount}
                                    </Box>
                                </Box>
                    }
                </Box>
            </Item>
        </Grid>
    );
}

function CompletionStatus({onTimeItems, delayedItems}) {
    const theme = useTheme();

    return (
        <Box style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "5px",
            paddingTop: "5px",
            boxSizing: "border-box"
        }}>
            <Box style={{
                width: "100%",
                height: "100%",
                backgroundColor: theme.palette.background.outerSurface,
                borderRadius: "10px",
                padding: "10px",
                paddingTop: "5px",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <Box style={{
                    fontSize: "0.8rem",
                    color: "white",
                    fontWeight: "bold",
                    textShadow: "2px 2px 15px forestgreen",
                    color: "rgb(8,153,73,1)",
                    textAlign: "center",
                    width: "100%",
                    padding: "5px",
                    borderRadius: "5px",
                    backgroundColor: "rgb(8,153,73,0.1)",
                    border: "1px solid rgb(8, 153, 73, 0.3)",
                    marginBottom: "10px", // Add margin at the bottom to separate it from the content below
                }}>
                    On Time
                </Box>
                <Box style={{
                    flex: 1,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    overflowY: "scroll",
                    marginTop: "10px"
                }} className="vertical-view-overflow-container">
                    <Box style={{
                        height: "100%",
                        width: "100%",
                        display: "block"
                    }}>
                        {
                            onTimeItems === null || onTimeItems.length === 0 ?
                                <WowSuchEmpty />
                                :
                                onTimeItems.map((item, index) => {
                                    return <TaskViewCard taskItem={item} key={index} />;
                                })
                        }
                    </Box>

                </Box>
            </Box>
            <Box style={{
                width: "100%",
                height: "100%",
                backgroundColor: theme.palette.background.outerSurface,
                borderRadius: "10px",
                padding: "10px",
                paddingTop: "5px",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <Box style={{
                    fontSize: "0.8rem",
                    color: "white",
                    fontWeight: "bold",
                    textShadow: "2px 2px 15px crimson",
                    color: "rgba(177, 44, 9, 1)",
                    textAlign: "center",
                    width: "100%",
                    padding: "5px",
                    borderRadius: "5px",
                    backgroundColor: "rgba(177, 44, 9, 0.1)",
                    border: "1px solid rgba(177, 44, 9, 0.3)"
                }}>
                    Delayed
                </Box>
                <Box style={{
                    flex: 1,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    overflowY: "scroll",
                    marginTop: "10px"
                }} className="vertical-view-overflow-container">
                    <Box style={{
                        height: "100%",
                        width: "100%",
                        display: "block"
                    }}>
                        {
                            delayedItems === null || delayedItems.length === 0 ?
                                <WowSuchEmpty />
                                :
                                delayedItems.map((item, index) => {
                                    return <TaskViewCard taskItem={item} key={index} />
                                })
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

function TaskViewCard({taskItem}) {
    const theme = useTheme();
    return (
       <Paper sx={{
            height: "50px",
            marginBottom: "6px",
            backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            display: "flex",
            flexDirection: "row",
            padding: "10px",
            boxSizing: "border-box",
            gap: "10px",
            cursor: "pointer"
       }} onClick={taskItem.onClick}>
        <Tooltip title={taskItem.name}>
            <Box style={{
                height: "100%",
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                overflowX: "scroll",
                whiteSpace: "nowrap"
            }} className="nested-view-overflow-container">
                {taskItem.name}
            </Box>
            </Tooltip>
            <Box style={{
                height: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                color: taskItem.color
            }}>
                <Box style={{
                    height: "100%",
                    width: "170px",
                    minWidth: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    whiteSpace: "nowrap",
                    marginRight: "10px",
                    color: theme.palette.background.text
                }}>
                    <GetNameAndEmpID emails={taskItem.email} />
                </Box>
                <Box style={{
                    height: "100%",
                    flexGrow: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    whiteSpace: "nowrap",
                    fontSize: "0.6rem"
                }}>
                    {taskItem.content.label}
                </Box>
                <Box style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "60px"
                }}>
                    <Box style={{
                        fontSize: "1.8rem",
                        lineHeight: 1,
                        fontWeight: "600"
                    }}>
                        {taskItem.content.value}
                    </Box>
                    <Box style={{
                        fontSize: "0.5rem",
                        color: theme.palette.background.text
                    }}>
                        days
                    </Box>
                </Box>
            </Box>
        </Paper>
    );
}

function WowSuchEmpty() {
    const theme = useTheme();
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            paddingBottom: "20px",
            boxSizing: "border-box"
        }}>
            <AirIcon sx={{
                fontSize: "6rem",
                color: theme.palette.mode === "light" ? "rgb(20, 20, 20, 0.06)": "rgb(200, 200, 200, 0.06)"
            }}/>
            {/* <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 0
            }}>
                <Box sx={{
                    fontSize: "3rem",
                    lineHeight: 1
                }}>
                    Wow
                </Box>
                <Box sx={{
                    fontSize: "1rem",
                    lineHeight: 1,
                    minHeight: 0
                }}>
                    Empty
                </Box>
            </Box>

            <Box sx={{
                rotate: "90deg",
                fontSize: "1rem",
                lineHeight: 1,
                padding: 0
            }}>
                Such
            </Box> */}
        </Box>
    );
}

// ################################### util functions #########################################

function dateDiffInDays(a, b) {
	const _MS_PER_DAY = 1000 * 60 * 60 * 24;
	const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
	const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

	return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}
