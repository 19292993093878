/* eslint-disable react/prop-types */ 
import React, { useState, useContext, useEffect, createContext } from 'react';
import { 
	Grid,
	Paper,
	Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { UserContext } from '../../../../../contexts/UserContext.js';

import { ReportSection1 } from '../reportSection1/ReportSection1.js';
import { NewReportTopBar } from './NewReportTopBar.js';
import { getTimeRange } from './NewReportsUtil.js';
import { ReportSection2 } from '../reportSection2/ReportSection2.js';
import { ReportSection3 } from '../reportSection3/ReportSection3.js';
import { ReportSection4 } from '../reportSection4/ReportSection4.js';
import { ReportSection5 } from '../reportSection5/ReportSection5.js';
import { ReportSection6 } from '../reportSection6/ReportSection6.js';
import { ReportSection7 } from '../reportSection7/ReportSection7.js';
import { ReportSection8 } from '../reportSection8/ReportSection8.js';
import { ReportSection9 } from '../reportSection9/ReportSection9.js';
import { ReportSection10 } from '../reportSection10/ReportSection10.js';
import { ReportSection11 } from '../reportSection11/ReportSection11.js';
import { ReportSection12 } from '../reportSection12/ReportSection12.js';
import { ReportSection13 } from '../reportSection13/ReportSection13.js';
import { ReportSection14 } from '../reportSection14/ReportSection14.js';
import { ReportSection15 } from '../reportSection15/ReportSection15.js';

const Item = styled(Paper)(({ theme, h }) => ({
	backgroundColor: theme.palette.mode === "light" ? "#e3e5e6" : theme.palette.background.infoDivOuter,
	...theme.typography.body2,
	padding: theme.spacing(1),
	paddingTop: 0,
	paddingBottom: 0,
	color: theme.palette.text.primary,
	height: "calc((100vh - 100px)/" + h + ")",
	display: "flex",
	// justifyContent: "center",
	alignItems: "center"
}));

export const ReportContext = createContext();

export function NewReport() {
	const {allServers} = useContext(UserContext);

	const[subjectType, setSubjectType] = useState("Server");
	const[subject, setSubject] = useState(allServers[allServers.length-1]);
	const[timeFrame, setTimeFrame] = useState("Monthly");
	const[time, setTime] = useState("Past");
	const[timeRange, setTimeRange] = useState(getTimeRange(time, timeFrame));
	
	const [report1Expanded, setReport1Expanded] = useState(false);

	return (
		<ReportContext.Provider value={{
			subjectType, 
			setSubjectType, 
			subject, 
			setSubject, 
			timeFrame, 
			setTimeFrame, 
			time, 
			setTime,
			timeRange,
			setTimeRange
		}}>
			<Box sx={{
				display: "flex",
				flex: 1,
				flexDirection: "column",
				gap: "10px",
				boxSizing: "border-box",
				overflow: "hidden"
			}}  className="page-content-2">
				<NewReportTopBar />
				<Box sx={{
					overflowY: "scroll",
					flexGrow: 1
				}}>
					<Grid container spacing={0.5}>
						<Grid item xs={12}>
							<Item h={report1Expanded ? 1.04 : 1.04}>
								{/* <ReportSection1 setExpanded={setReport1Expanded}/> */}
								<ReportSection1 setExpanded={() => { return }} />
							</Item>
						</Grid>
						<Grid item xs={12}>
							<Item h={report1Expanded ? 1.04 : 1.04}>
								<ReportSection2 />
							</Item>
						</Grid>
						<Grid item xs={12}>
							<Item h={report1Expanded ? 2 : 2}>
								<ReportSection13 />
							</Item>
						</Grid>
						<Grid item xs={12}>
							<Item h={report1Expanded ? 2 : 2}>
								<ReportSection14 />
							</Item>
						</Grid>
						{/* <Grid item xs={4}>
							<Item h={report1Expanded ? 2 : 2}>
								<ReportSection15 />
							</Item>
						</Grid> */}
						<Grid item xs={6}>
							<Item h={report1Expanded ? 2 : 2}>
								<ReportSection6 />
							</Item>
						</Grid>
						<Grid item xs={6}>
							<Item h={report1Expanded ? 2 : 2}>
								<ReportSection4 />
							</Item>
						</Grid>
						<Grid item xs={6}>
							<Item h={report1Expanded ? 2 : 2}>
								<ReportSection5 />
							</Item>
						</Grid>
						<Grid item xs={6}>
							<Item h={report1Expanded ? 2 : 2}>
								<ReportSection3 />
							</Item>	
						</Grid>
						<Grid item xs={12}>
							<Item h={report1Expanded ? 1.04 : 1.04}>
								<ReportSection7 />
							</Item>
						</Grid>
						{/* <Grid item xs={6}>
							<Item h={report1Expanded ? 2 : 2}>
								<ReportSection9 />
							</Item>	
						</Grid> */}
						<Grid item xs={12}>
							<Item h={report1Expanded ? 2 : 2}>
								<ReportSection10 />
							</Item>	
						</Grid>
						{/* <Grid item xs={12}>
							<Item h={report1Expanded ? 1.04 : 1.04}>
								<ReportSection8 />
							</Item>
						</Grid> */}
						<Grid item xs={6}>
							<Item h={report1Expanded ? 2 : 2}>
								<ReportSection11 />
							</Item>	
						</Grid>
						<Grid item xs={6}>
							<Item h={report1Expanded ? 2 : 2}>
								<ReportSection12 />
							</Item>	
						</Grid>
					</Grid>
				</Box>
			</Box>
		</ReportContext.Provider>
	);
}
