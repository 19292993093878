/* eslint-disable react/prop-types */
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
    Box, 
    Skeleton,
    Button,
    ButtonGroup, 
    Dialog, 
    DialogActions, 
    DialogContent,
    TableContainer, 
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Select,
    MenuItem,
    TextField,
    Alert
} from "@mui/material";
import { Snackbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import { executeQuery, queryDB } from "../../../../util/QueryDB";
import { useTheme } from '@mui/material';
import { UserContext } from "../../../../contexts/UserContext";
import { GetNameAndEmpID } from "../../../../util/Loading";
import { Fade } from '@mui/material';
import { admin_zone_configuration_get_query_1, admin_zone_configuration_get_query_2, admin_zone_configuration_get_query_3, admin_zone_configuration_get_query_4, admin_zone_configuration_get_query_5 } from "../../../../catalystDB/queryGenerator";



export default function ReportConfiguration() {
    const [rows, setRows] = useState(null);
    const theme = useTheme();

    useEffect(() => {

        async function getConfRows() {
            const result = await queryDB(admin_zone_configuration_get_query_1());
            var arr = [];

            for(var r of result) {
                arr.push([r.Confs.KEY_, r.Confs.VALUE, r.Confs.ROWID]);
            }

            setRows(arr);
        }

        getConfRows();

    }, []);

    if(rows === null) {
        return (
            <Box className='page-content-2'>
                <Skeleton variant="rectangular" width={"100%"} height={35} />
                <br></br>
                <Skeleton variant="rectangular" width={"100%"} sx={{ height: "calc(100vh - 175px)" }} />
            </Box>
        );
    }

    return (
        <Box className='page-content-2'>
            {rows.length === 0 ? (
               <Fade in={true}>
               <div style={{ color: theme.palette.background.text }}>
               <TopBar rows={rows} setRows={setRows} />
                   <h1>No Tasks Found :(</h1>
               </div>
              </Fade>
            ) : (
                <>
                    <TopBar rows={rows} setRows={setRows} />
                    <Content rows={rows} setRows={setRows} />
                </>
            )}
        </Box>
    );
}

function TopBar({rows, setRows}) {

    const [openPopup, setOpenPopup] = useState(false);

    const handleMenuOpen = (event) => {
		setOpenPopup(true);
	};

    const HandleClosePopup = () => {
		setOpenPopup(false);
	};

    return (
        <div className="mytasks-top-bar">
            <div className="mytasks-top-bar-left-group">
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Button variant="contained" size="small" onClick={handleMenuOpen}>
                        <AddIcon />
                    </Button>
                </ButtonGroup>
            </div>
            <Dialog open={openPopup} onClose={HandleClosePopup} fullScreen>
                <DialogActions>
                    <Button onClick={HandleClosePopup} variant="text" fontSize="large"><CloseIcon /></Button>
                </DialogActions>
                <DialogContent>
                    <AddNewConf setOpenPopup={setOpenPopup} rows={rows} setRows={setRows}/>
                </DialogContent>
            </Dialog>
        </div>
    );
}

function Content({rows, setRows}) {
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('');
    return (
        <>
        <div className="mytasks-table">
            <TableContainer className="mytasks-table-container" sx={{
                flex: 1
            }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow className="mytasks-table-head">
                            <TableCell align="center" sx={{maxWidth: "5px"}}>S.No.</TableCell>
                            <TableCell align="center">KEY_</TableCell>
                            <TableCell align="center">Department</TableCell>
                            <TableCell align="right" sx={{maxWidth: "5px"}} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows.map((row, index) => {
                                return <Row index={index} row={row} rows={rows} setRows={setRows} key={index} alertMessage={alertMessage} alertOpen={alertOpen} setAlertMessage={setAlertMessage} setAlertOpen={setAlertOpen} alertSeverity={alertSeverity} setAlertSeverity={setAlertSeverity}/>
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
            <div>
                <Snackbar
                    open={alertOpen}
                    autoHideDuration={3000} // Adjust the duration as needed
                    onClose={() => setAlertOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                >
                    <Alert
                        onClose={() => setAlertOpen(false)}
                        severity={alertSeverity} 
                    >
                        {alertMessage}
                    </Alert>
                </Snackbar>
            </div>
        </>
    );
}

function Row({ index, row, rows, setRows, alertMessage, alertOpen, setAlertMessage, setAlertOpen, alertSeverity, setAlertSeverity }) {
    const theme = useTheme();
    const [isHovered, setIsHovered] = useState(false);

    return (
        <TableRow
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
                '& > *': { borderBottom: 'unset' },
                backgroundColor: theme.palette.background.tableRow,
                '&:hover': {
                    backgroundColor: theme.palette.background.tableRowHover + " !important"
                }
            }}
        >
            <TableCell align="center">{index + 1}</TableCell>
            <TableCell align="center">
                    <span>{row[0]}</span>
            </TableCell>
            <TableCell align="center">
                  
                        <div style={{ whiteSpace: 'pre-line' }}>
                            {row[1].length > 8
                                ? row[1]
                                    .split(',')
                                    .map((item, index, array) => (
                                        <div key={index}>
                                            {item.trim()}
                                            {index < array.length - 1 && ','}
                                        </div>
                                    ))
                                : row[1]}
                        </div>
                   
             </TableCell>

            <TableCell align="right">
                <Button sx={{
                    borderRadius: "300px",
                    visibility: isHovered ? "visible" : "hidden"
                }} color="error">
                    <DeleteIcon fontSize="small" onClick={() => handleRowDelete(row, rows, setRows, alertMessage, alertOpen, setAlertMessage, setAlertOpen, alertSeverity, setAlertSeverity)} />
                </Button>
            </TableCell>
        </TableRow>
    );
}

async function handleRowDelete(row, rows, setRows, alertMessage, alertOpen, setAlertMessage, setAlertOpen, alertSeverity, setAlertSeverity) {
    try {
        const rowID = row[2]; 

        var newRows = rows.filter((r) => {
            if (r === row) {
                return false;
            } else {
                return true;
            }
        });
        setRows(newRows);
        // console.log(rowID);
        try {
            await executeQuery(admin_zone_configuration_get_query_3(rowID));
            setAlertMessage('Deleted successfully');
            setAlertOpen(true);
            setAlertSeverity("success")
        } catch (e) {
            setAlertMessage('Failed to Delete :(');
            setAlertOpen(true);
            setAlertSeverity("error");
        }
    } catch(e) {
        
    }
}



function AddNewConf({setOpenPopup, rows, setRows}) {
    // const {allServers} = useContext(UserContext);
  

    const {teamDetails,allServers,loginUserDept} = useContext(UserContext);
    const theme = useTheme();
    var options = [];
    var labels = [];
    for(const people in teamDetails.current) {
        if(people === undefined || people === null || people === "") {
            continue;
        }
        options.push(people);
        labels.push(<GetNameAndEmpID emails={people} />);
    }
   
    const [keyName, setKeyName] = useState("");
    const [departmentName, setDepartmentName] = useState("");
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertSeverity, setAlertSeverity] = useState(null);

    const [error, setError] = useState('');

  

   const handleFormSubmit = async (event) => {
    event.preventDefault();
      
    const existingRecord = await executeQuery(admin_zone_configuration_get_query_2(keyName));

    if (existingRecord.length > 0) {
        
        try {
            const result = await executeQuery(admin_zone_configuration_get_query_4(departmentName, keyName));
            setAlertSeverity("success");
            setAlertMessage("Successfully updated!");
            setRows((prevRows) =>
                prevRows.map((prevRow) =>
                    prevRow[0] === keyName
                        ? [result[0].Confs.KEY_, result[0].Confs.VALUE, result[0].Confs.ROWID]
                        : prevRow
                )
            );
            setTimeout(() => {
                setAlertMessage(null);
                setOpenPopup(false);
            }, 1500);
        } catch (e) {
            setAlertSeverity("error");
            setAlertMessage("Unable to update :(");
            setTimeout(() => {
                setAlertMessage(null);
            }, 3000);
        }
    } else {


        try {
            // Try parsing the input as JSON object
            const parsedObject = JSON.parse(departmentName);
            console.log('Parsed as JSON object:', parsedObject);
            try {
                const result = await executeQuery(admin_zone_configuration_get_query_5(keyName, departmentName));
                setAlertSeverity("success");
                setAlertMessage("Parsed as JSON object : Successfully added!");
                setRows([...rows, [result[0].Confs.KEY_, result[0].Confs.VALUE, result[0].Confs.ROWID]]);
                setTimeout(() => {
                    setAlertMessage(null);
                    setOpenPopup(false);
                }, 1500);
            } catch (e) {
                setAlertSeverity("error");
                setAlertMessage("Unable to add :(");
                setTimeout(() => {
                    setAlertMessage(null);
                }, 3000);
            }
    
          
        } catch (objectError) {
            try {
                // If parsing as JSON object fails, try parsing as JSON array
                const parsedArray = JSON.parse(departmentName);
                console.log('Parsed as JSON array:', parsedArray);
                try {
                    const result = await executeQuery(admin_zone_configuration_get_query_5(keyName, departmentName));
                    setAlertSeverity("success");
                    setAlertMessage("Parsed as JSON array : Successfully added!");
                    setRows([...rows, [result[0].Confs.KEY_, result[0].Confs.VALUE, result[0].Confs.ROWID]]);
                    setTimeout(() => {
                        setAlertMessage(null);
                        setOpenPopup(false);
                    }, 1500);
                } catch (e) {
                    setAlertSeverity("error");
                    setAlertMessage("Unable to add :(");
                    setTimeout(() => {
                        setAlertMessage(null);
                    }, 3000);
                }
    
            } catch (arrayError) {
                // If parsing as both JSON object and array fails, it's not valid JSON
                console.error('Invalid JSON format');
                setAlertSeverity("error");
                setAlertMessage('Key Value --> Invalid JSON format');
                setTimeout(() => {
                    setAlertMessage(null);
                }, 3000);
            }
        }
    }
    
  };

 
  

   


    return (
        <Box sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            fontFamily: 'Poppins, sans-serif',
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
            backgroundColor: theme.palette.mode === "light" ? "#fff" : "1A1E24"
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                justifyContent: "center",
                fontFamily: 'Poppins, sans-serif',
                width: "90%",
                height: "88vh",
                padding: "0px 50px 10px 50px",
                border: "1px solid #ddd", 
                borderRadius: "5px", 
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)", 
                color: theme.palette.background.color,
                backgroundColor: theme.palette.mode === "light" ? "#fff" : "1A1E24",
                gap: "50px"
            }}>
                {
                    alertMessage !== null ?
                        <Alert severity={alertSeverity} sx={{marginTop: "10px"}}>{alertMessage}</Alert>
                        :
                        null

                }
                <form>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "54px",
                    alignItems: "center",
                    justifyContent: "left",
                    fontFamily: 'Poppins, sans-serif',
                    paddingLeft: "518px"
                }}>
                    <h4>Key Name</h4>
                    <TextField id="outlined-basic" variant="outlined" sx={{
                        width: "500px"
                    }} value={keyName} onChange={(event) => {
                        setKeyName(event.target.value);
                      }}/>
                </Box>

                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "54px",
                    alignItems: "center",
                    justifyContent: "left",
                    fontFamily: 'Poppins, sans-serif',
                    paddingLeft: "500px",
                    "& textarea": {
                        width: "300px !important",
                        margin: "30px 30px 30px 20px !important",
                        textTransform: "none !important",
                        minWidth: "280px !important",
                        minHeight: "200px !important",
                        maxHeight: "350px !important",
                        display: "block",
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                        resize: "vertical",
                        fontSize: "13px",
                        lineHeight: "1.5",
                        color: "inherit !important",
                        backgroundColor: "transparent !important",
                        boxShadow: "inset 0 2px 2px rgba(0, 0, 0, 0.1)",
                        transition: "border-color 0.2s, box-shadow 0.2s",
                        textDecoration: "none",
                        "&:focus": {
                            borderColor: "#0f766f",
                            outline: "none",
                            boxShadow: "inset 0 2px 2px rgba(0, 0, 0, 0.1), 0 0 5px rgba(102, 205, 233, 0.6)"
                        }
                    }
                }}>
                    <h4>Key Value</h4>
                    <textarea
                        rows={10}
                        spellCheck={"false"}
                        autoFocus
                        value={departmentName}
                        onChange={(event) => {
                            setDepartmentName(event.target.value);
                        }}
                    />
                </Box>
                <Box sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: 'Poppins, sans-serif',
                    paddingTop: "100px"
                }}>
                    <Button variant="contained" onClick={handleFormSubmit} style={{ marginTop: 5 }}>Submit</Button>
                </Box>
                </form>
            </Box>
        </Box>
    );
}