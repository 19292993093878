import { isDateInRange, isDateString } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    const ticketMap = {};
    const dataMap = {};

    catalystDB.Tickets.map(item => {
        const dateString = item.CREATEDTIME;

        if(isDateString(dateString) && isDateInRange(dateString, startTime)) {
            if([undefined, null, "", " "].includes(item.Priority_1)) {
                return;
            }
            
            if(item.Priority_1 in ticketMap) {
                ticketMap[item.Priority_1] += 1;
            } else {
                ticketMap[item.Priority_1] = 1;
            }

            if(item.Priority_1 in dataMap) {
                dataMap[item.Priority_1].push(item);
            } else {
                dataMap[item.Priority_1] = [item];
            }
        }
    }); 

    var chartData = [];

    const power = {
        "Emergency": 3,
        "High": 2,
        "Medium": 1,
        "Normal": 0
    };

    var keySet = Object.keys(ticketMap);

    keySet.sort((a, b) => {
        const power_1 = power[a];
        const power_2 = power[b];

        if(power_1 === undefined || power_2 === undefined) {
            return 0;
        } else if (power_1 > power_2) {
            return -1;
        } else {
            return 1;
        }
    });

    for(var priority of keySet) {
        chartData.push([
            priority,
            ticketMap[priority]
        ]);
    }

    return [chartData, dataMap];
}