import { isDateInRange, isDateString } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    var count = 0;

    catalystDB.Tickets.map(item => {
        const dateString = item.CREATEDTIME;

        if(isDateString(dateString) && isDateInRange(dateString, startTime)) {
            count++
        }
    }); 

    return {
        "value": count
    };
}