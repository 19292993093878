import { isDateInRange, isDateString } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    const ticketMap = {};
    const dataMap = {};

    catalystDB.Tickets.map(item => {
        const dateString = item.CREATEDTIME;

        if(isDateString(dateString) && isDateInRange(dateString, startTime)) {
            if([undefined, null, "", " "].includes(item.Status)) {
                return;
            }
            
            if(item.Status in ticketMap) {
                ticketMap[item.Status] += 1;
            } else {
                ticketMap[item.Status] = 1;
            }

            if(item.Status in dataMap) {
                dataMap[item.Status].push(item);
            } else {
                dataMap[item.Status] = [item];
            }
        }
    }); 

    var chartData = [];

    const power = {
        "Ticket Created": 6,
        "Open": 5,
        "Immediate Fix Moved": 4,
        "Immediate Fix In Progress": 3,
        "Permanent Fix In Progress": 2,
        "Completed": 1
    };

    var keySet = Object.keys(ticketMap);

    keySet.sort((a, b) => {
        const power_1 = power[a];
        const power_2 = power[b];

        if(power_1 === undefined || power_2 === undefined) {
            return 0;
        } else if (power_1 > power_2) {
            return -1;
        } else {
            return 1;
        }
    });

    for(var status of keySet) {
        chartData.push([
            status,
            ticketMap[status]
        ]);
    }

    return [chartData, dataMap];
}