import { dashboard_data_fetcher_get_query_1, dashboard_data_fetcher_get_query_10, dashboard_data_fetcher_get_query_2, dashboard_data_fetcher_get_query_3, dashboard_data_fetcher_get_query_4, dashboard_data_fetcher_get_query_5, dashboard_data_fetcher_get_query_6, dashboard_data_fetcher_get_query_7, dashboard_data_fetcher_get_query_8, dashboard_data_fetcher_get_query_9 } from "../../../../catalystDB/queryGenerator";
import { queryDBNew } from "../../../../util/QueryDB";

const ALL_REMOTE_DBS = [
    "TaskBreaker",
    "TaskDetails",
    "Tickets",
    "QATest",
    "AllBuildDetails"
];

const DAYS_IN_YEAR = 365.2425;
const DAYS_IN_MONTH = 30.436875;
const DAYS_IN_WEEK = 7;

const TODAY = new Date().toISOString().split("T")[0];

var temp = new Date();
temp.setDate(temp.getDate() - DAYS_IN_WEEK);
export const ONE_WEEK_BEFORE_TODAY = temp.toISOString().split("T")[0];

var temp = new Date();
temp.setDate(temp.getDate() - DAYS_IN_MONTH);
export const ONE_MONTH_BEFORE_TODAY = temp.toISOString().split("T")[0];


var temp = new Date();
temp.setDate(temp.getDate() - DAYS_IN_YEAR);
export const ONE_YEAR_BEFORE_TODAY = temp.toISOString().split("T")[0];


const QUERY_URL = document.location.origin + "/baas/v1/project/5781000006822630/query";

const cache = {};

export async function fetchRemoteDB(catalystDB, setCatalystDB, loginUserDept) {

    await fetchOneWeekData(catalystDB, setCatalystDB, loginUserDept);
    fetchOneYearData(catalystDB, setCatalystDB, loginUserDept);

}

async function fetchOneWeekData(catalystDB, setCatalystDB, loginUserDept) {
    const REMOTE_DBS_QUERY = {
        "TaskBreaker": dashboard_data_fetcher_get_query_1(ONE_WEEK_BEFORE_TODAY, loginUserDept),
        "TaskDetails": dashboard_data_fetcher_get_query_2(ONE_WEEK_BEFORE_TODAY, loginUserDept),
        "Tickets": dashboard_data_fetcher_get_query_3(ONE_WEEK_BEFORE_TODAY, loginUserDept),
        "QATest": dashboard_data_fetcher_get_query_4(ONE_WEEK_BEFORE_TODAY, loginUserDept),
        "AllBuildDetails": dashboard_data_fetcher_get_query_5(ONE_WEEK_BEFORE_TODAY, loginUserDept)
    }

    var responses = undefined;
    if ("catalystDB_pre" in cache && cache["catalystDB_pre"] !== undefined) {
        responses = cache.catalystDB_pre;
    } else {
        responses = await Promise.all([
            queryDBNew(REMOTE_DBS_QUERY.TaskBreaker),
            queryDBNew(REMOTE_DBS_QUERY.TaskDetails),
            queryDBNew(REMOTE_DBS_QUERY.Tickets),
            queryDBNew(REMOTE_DBS_QUERY.QATest),
            queryDBNew(REMOTE_DBS_QUERY.AllBuildDetails)
        ]);
        cache.catalystDB_pre = responses;
    }

    const catDB = {
        "TaskBreaker": undefined,
        "TaskDetails": undefined,
        "Tickets": undefined,
        "QATest": undefined,
        "AllBuildDetails": undefined,
        "all_data": false
    };

    responses.map((response, index) => {
        switch (index) {
            case 0: {
                if (response.status === 200) {
                    if (response.content.length === 0) {
                        catDB.TaskBreaker = null;
                    } else {
                        catDB.TaskBreaker = response.content.map(item => item.TaskBreaker);
                    }
                } else {
                    catDB.TaskBreaker = false;
                }
                break;
            }
            case 1: {
                if (response.status === 200) {
                    if (response.content.length === 0) {
                        catDB.TaskDetails = null;
                    } else {
                        catDB.TaskDetails = response.content.map(item => item.TaskDetails);
                    }
                } else {
                    catDB.TaskDetails = false;
                }
                break;
            }
            case 2: {
                if (response.status === 200) {
                    if (response.content.length === 0) {
                        catDB.Tickets = null;
                    } else {
                        catDB.Tickets = response.content.map(item => item.Tickets);
                    }
                } else {
                    catDB.Tickets = false;
                }
                break;
            }
            case 3: {
                if (response.status === 200) {
                    if (response.content.length === 0) {
                        catDB.QATest = null;
                    } else {
                        catDB.QATest = response.content.map(item => item.QATest);
                    }
                } else {
                    catDB.QATest = false;
                }
                break;
            }
            case 4: {
                if (response.status === 200) {
                    if (response.content.length === 0) {
                        catDB.AllBuildDetails = null;
                    } else {
                        catDB.AllBuildDetails = response.content.map(item => item.AllBuildDetails);
                    }
                } else {
                    catDB.AllBuildDetails = false;
                }
                break;
            }
            default: {
                break;
            }
        }
    });

    setCatalystDB(catDB);
}

async function fetchOneYearData(catalystDB, setCatalystDB, loginUserDept) {
    const REMOTE_DBS_QUERY = {
        "TaskBreaker": dashboard_data_fetcher_get_query_6(ONE_YEAR_BEFORE_TODAY, loginUserDept),
        "TaskDetails": dashboard_data_fetcher_get_query_7(ONE_YEAR_BEFORE_TODAY, loginUserDept),
        "Tickets": dashboard_data_fetcher_get_query_8(ONE_YEAR_BEFORE_TODAY, loginUserDept),
        "QATest": dashboard_data_fetcher_get_query_9(ONE_YEAR_BEFORE_TODAY, loginUserDept),
        "AllBuildDetails": dashboard_data_fetcher_get_query_10(ONE_YEAR_BEFORE_TODAY, loginUserDept)
    }

    var responses = undefined;
    if ("catalystDB" in cache && cache["catalystDB"] !== undefined) {
        responses = cache.catalystDB;
    } else {
        responses = await Promise.all([
            queryDBNew(REMOTE_DBS_QUERY.TaskBreaker),
            queryDBNew(REMOTE_DBS_QUERY.TaskDetails),
            queryDBNew(REMOTE_DBS_QUERY.Tickets),
            queryDBNew(REMOTE_DBS_QUERY.QATest),
            queryDBNew(REMOTE_DBS_QUERY.AllBuildDetails)
        ]);
        cache.catalystDB = responses;
    }

    const catDB = {
        "TaskBreaker": undefined,
        "TaskDetails": undefined,
        "Tickets": undefined,
        "QATest": undefined,
        "AllBuildDetails": undefined,
        "all_data": true
    };

    responses.map((response, index) => {
        switch (index) {
            case 0: {
                if (response.status === 200) {
                    if (response.content.length === 0) {
                        catDB.TaskBreaker = null;
                    } else {
                        catDB.TaskBreaker = response.content.map(item => item.TaskBreaker);
                    }
                } else {
                    catDB.TaskBreaker = false;
                }
                break;
            }
            case 1: {
                if (response.status === 200) {
                    if (response.content.length === 0) {
                        catDB.TaskDetails = null;
                    } else {
                        catDB.TaskDetails = response.content.map(item => item.TaskDetails);
                    }
                } else {
                    catDB.TaskDetails = false;
                }
                break;
            }
            case 2: {
                if (response.status === 200) {
                    if (response.content.length === 0) {
                        catDB.Tickets = null;
                    } else {
                        catDB.Tickets = response.content.map(item => item.Tickets);
                    }
                } else {
                    catDB.Tickets = false;
                }
                break;
            }
            case 3: {
                if (response.status === 200) {
                    if (response.content.length === 0) {
                        catDB.QATest = null;
                    } else {
                        catDB.QATest = response.content.map(item => item.QATest);
                    }
                } else {
                    catDB.QATest = false;
                }
                break;
            }
            case 4: {
                if (response.status === 200) {
                    if (response.content.length === 0) {
                        catDB.AllBuildDetails = null;
                    } else {
                        catDB.AllBuildDetails = response.content.map(item => item.AllBuildDetails);
                    }
                } else {
                    catDB.AllBuildDetails = false;
                }
                break;
            }
            default: {
                break;
            }
        }
    });

    setCatalystDB(catDB);
} 