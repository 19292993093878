//ignorei18n_start
// import React from "react";
import React, { useState, useRef, useLayoutEffect, useEffect, useContext } from 'react';
import Header from "./header/Header";
import TimelineComponent from "./timeline/Timeline";
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import {
    timeLine_page_component_get_query_1, timeLine_page_component_get_query_2, timeLine_page_component_get_query_3, timeLine_page_component_get_query_4, timeLine_page_component_get_query_5,
    timeLine_page_component_get_query_6, allStatData_page_component_get_query_1, allStatData_page_component_get_query_2, allStatData_page_component_get_query_3, allStatData_page_component_get_query_4,
    allStatData_page_component_get_query_5, allStatData_page_component_get_query_6,timeLine_page_component_get_query_7
} from "../../../../src/catalystDB/queryGenerator"
import { getFromTimelineDataUserBased, getFromTimelineDataServerBased, getForAllDataStat } from "../timeline_page/DataFetcherForTimeLinePage/DataFetcherForTimeLinePage"
import { UserContext } from '../../../contexts/UserContext.js';
import EmptyData from '../../../icons/EmptyData.png';
import EmptyDataDarkMode from '../../../icons/EmptyDataDarkMode.png'


export default function TimelinePage({ }) {

    const { userDetails, teamDetails, allServers, loginUserDept, configuration, GlobalServer } = useContext(UserContext);
    var initialMode = "Component";
    const theme = useTheme();
    const [toOpenTheAllData, setToOpenTheAllData] = useState(true);
    const [selectedServer, setSelectedServer] = useState("");
    const [selectedDri, setSelectedDri] = useState("");
    const [selectedMode, setSelectedMode] = useState(initialMode);
    const [data, setData] = useState();
    const [allDataWithWrongJasonAlso, setAllDataWithWrongJasonAlso] = useState();
    const [milestoneData, setMilestoneData] = useState([]);
    const [taskListData, setTaskListData] = useState([]);
    const [taskData, setTaskData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [LazzyLoading, setLazzyLoading] = useState(false);
    const [offsetData, setOffsetData] = useState(1);
    const [incrementData, setIncrementData] = useState(100);
    // const userserver = (GlobalServer === "unknown" || GlobalServer === undefined) ? allServers : [GlobalServer]
    const localStorageFetchedServer = localStorage.getItem('selectedServer') === null ? (GlobalServer === "unknown" || GlobalServer === undefined) ? null : GlobalServer : localStorage.getItem('selectedServer');

    // Determine the server to use based on the selectedServer value or existing logic
    const user_email = userDetails.current.auth.email_id;
    const userserver = teamDetails.current[user_email].Servers.split(",").filter(server => allServers.includes(server)).join(",");
    // const userserver =  allServers;

    var listOfComponents = userserver.split(",");
    var listOfManagers = Object.keys(teamDetails.current)
        .filter(email => {
            const role = teamDetails.current[email].Role;
            return role === 'Manager';
        });
    var listOfMentors = Object.keys(teamDetails.current)
        .filter(email => {
            const role = teamDetails.current[email].Role;
            return role === 'Mentor';
        });
    var listOfDevelopers = Object.keys(teamDetails.current)
        .filter(email => {
            const role = teamDetails.current[email].Role;
            return role === 'Developer';
        });

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                var result;
                if (localStorageFetchedServer === null) {
                    result = await getFromTimelineDataServerBased(timeLine_page_component_get_query_1(userserver), offsetData);
                }
                else {
                    result = await getFromTimelineDataServerBased(timeLine_page_component_get_query_2(localStorageFetchedServer), offsetData);
                }
                const validResults = result.filter(o => isValidJSON(o));
                const parsedResult = validResults.map(o => {
                    try {
                        if (isValidJSON(o)) {
                            return JSON.parse(o, dateTimeReviver);
                        }
                        else {
                            console.log(o)
                        }
                    } catch (e) {
                        console.log(e);
                        return o;
                    }
                });
                setAllDataWithWrongJasonAlso(result);
                setData(parsedResult);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        const fetchStatData = async () => {
            try {
                var queries;
                if (localStorageFetchedServer === null) {
                    queries = [
                        getForAllDataStat(allStatData_page_component_get_query_1()),
                        getForAllDataStat(allStatData_page_component_get_query_2()),
                        getForAllDataStat(allStatData_page_component_get_query_3())
                    ];
                }
                else {
                    queries = [
                        getForAllDataStat(allStatData_page_component_get_query_4(localStorageFetchedServer)),
                        getForAllDataStat(allStatData_page_component_get_query_5(localStorageFetchedServer)),
                        getForAllDataStat(allStatData_page_component_get_query_6(localStorageFetchedServer))
                    ];
                }
                // const queries = [
                //     getForAllDataStat(allStatData_page_component_get_query_1()),
                //     getForAllDataStat(allStatData_page_component_get_query_2()),
                //     getForAllDataStat(allStatData_page_component_get_query_3())
                // ];

                const results = await Promise.all(queries);
                const combinedResults = results.flat(); // Combine all results into a single array
                const milestoneResult = combinedResults[0].Quarterly;
                const taskListResult = combinedResults[1].Quarterly;
                const taskResult = combinedResults[2].Quarterly;

                var milestoneData;
                var taskListData;
                var taskData;

                if (localStorageFetchedServer === null) {
                    milestoneData = [
                        { label: "Total Count", value: parseInt(milestoneResult['SUM(Total_Milestone_Created)'], 10) },
                        { label: "Ongoing", value: parseInt(milestoneResult['SUM(Ongoing_Milestone)'], 10) },
                        { label: "Delayed", value: parseInt(milestoneResult['SUM(Delayed_Milestone)'], 10) },
                        { label: "On Time", value: parseInt(milestoneResult['SUM(Ontime_Milestone)'], 10) }
                    ];

                    taskListData = [
                        { label: "Total Count", value: parseInt(taskListResult['SUM(Total_Tasklist_created)'], 10) },
                        { label: "Ongoing", value: parseInt(taskListResult['SUM(Ongoing_Tasklist)'], 10) },
                        { label: "Delayed", value: parseInt(taskListResult['SUM(Delayed_Tasklist)'], 10) },
                        { label: "On Time", value: parseInt(taskListResult['SUM(Ontime_Tasklist)'], 10) }
                    ];

                    taskData = [
                        { label: "Total Count", value: parseInt(taskResult['SUM(Total_Task_Created)'], 10) },
                        { label: "Ongoing", value: parseInt(taskResult['SUM(Ongoing_Task)'], 10) },
                        { label: "Delayed", value: parseInt(taskResult['SUM(Delayed_Task)'], 10) },
                        { label: "On Time", value: parseInt(taskResult['SUM(Ontime_Task)'], 10) }
                    ];
                }
                else {
                    milestoneData = [
                        { label: "Total Count", value: parseInt(milestoneResult['Total_Milestone_Created'], 10) },
                        { label: "Ongoing", value: parseInt(milestoneResult['Ongoing_Milestone'], 10) },
                        { label: "Delayed", value: parseInt(milestoneResult['Delayed_Milestone'], 10) },
                        { label: "On Time", value: parseInt(milestoneResult['Ontime_Milestone'], 10) }
                    ];

                    taskListData = [
                        { label: "Total Count", value: parseInt(taskListResult['Total_Tasklist_created'], 10) },
                        { label: "Ongoing", value: parseInt(taskListResult['Ongoing_Tasklist'], 10) },
                        { label: "Delayed", value: parseInt(taskListResult['Delayed_Tasklist'], 10) },
                        { label: "On Time", value: parseInt(taskListResult['Ontime_Tasklist'], 10) }
                    ];

                    taskData = [
                        { label: "Total Count", value: parseInt(taskResult['Total_Task_Created'], 10) },
                        { label: "Ongoing", value: parseInt(taskResult['Ongoing_Task'], 10) },
                        { label: "Delayed", value: parseInt(taskResult['Delayed_Task'], 10) },
                        { label: "On Time", value: parseInt(taskResult['Ontime_Task'], 10) }
                    ];
                }

                setMilestoneData(milestoneData);
                setTaskListData(taskListData);
                setTaskData(taskData);
            } catch (error) {
                console.error(error);
            }
        };

        //  console.log('milestoneData >>', milestoneData);
        //  console.log('taskListData >>', taskListData);
        //  console.log('taskData >>', taskData);

        fetchData();
        fetchStatData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                var result;
                if (localStorageFetchedServer === null && (GlobalServer === "unknown" || GlobalServer === undefined)) {
                    result = await getFromTimelineDataServerBased(timeLine_page_component_get_query_1(userserver), offsetData);
                }
                else if (GlobalServer != "unknown" && GlobalServer != undefined) {
                    result = await getFromTimelineDataServerBased(timeLine_page_component_get_query_2(GlobalServer), offsetData);
                }
                else {
                    result = await getFromTimelineDataServerBased(timeLine_page_component_get_query_2(localStorageFetchedServer), offsetData);
                }
                const validResults = result.filter(o => isValidJSON(o));
                const parsedResult = validResults.map(o => {
                    try {
                        if (isValidJSON(o)) {
                            return JSON.parse(o, dateTimeReviver);
                        }
                        else {
                            console.log(o)
                        }
                    } catch (e) {
                        console.log(e);
                        return o;
                    }
                });
                setAllDataWithWrongJasonAlso(result);
                setData(parsedResult);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        const fetchStatData = async () => {
            try {
                var queries;
                if (localStorageFetchedServer === null) {
                    queries = [
                        getForAllDataStat(allStatData_page_component_get_query_1()),
                        getForAllDataStat(allStatData_page_component_get_query_2()),
                        getForAllDataStat(allStatData_page_component_get_query_3())
                    ];
                }
                else {
                    queries = [
                        getForAllDataStat(allStatData_page_component_get_query_4(localStorageFetchedServer)),
                        getForAllDataStat(allStatData_page_component_get_query_5(localStorageFetchedServer)),
                        getForAllDataStat(allStatData_page_component_get_query_6(localStorageFetchedServer))
                    ];
                }

                const results = await Promise.all(queries);
                const combinedResults = results.flat(); // Combine all results into a single array
                const milestoneResult = combinedResults[0].Quarterly;
                const taskListResult = combinedResults[1].Quarterly;
                const taskResult = combinedResults[2].Quarterly;

                const milestoneData = [
                    { label: "Total Count", value: parseInt(milestoneResult['Total_Milestone_Created'], 10) },
                    { label: "Ongoing", value: parseInt(milestoneResult['Ongoing_Milestone'], 10) },
                    { label: "Delayed", value: parseInt(milestoneResult['Delayed_Milestone'], 10) },
                    { label: "On Time", value: parseInt(milestoneResult['Ontime_Milestone'], 10) }
                ];

                const taskListData = [
                    { label: "Total Count", value: parseInt(taskListResult['Total_Tasklist_created'], 10) },
                    { label: "Ongoing", value: parseInt(taskListResult['Ongoing_Tasklist'], 10) },
                    { label: "Delayed", value: parseInt(taskListResult['Delayed_Tasklist'], 10) },
                    { label: "On Time", value: parseInt(taskListResult['Ontime_Tasklist'], 10) }
                ];

                const taskData = [
                    { label: "Total Count", value: parseInt(taskResult['Total_Task_Created'], 10) },
                    { label: "Ongoing", value: parseInt(taskResult['Ongoing_Task'], 10) },
                    { label: "Delayed", value: parseInt(taskResult['Delayed_Task'], 10) },
                    { label: "On Time", value: parseInt(taskResult['Ontime_Task'], 10) }
                ];

                // console.log('milestoneData >>', milestoneData);
                // console.log('taskListData >>', taskListData);
                // console.log('taskData >>', taskData);

                setMilestoneData(milestoneData);
                setTaskListData(taskListData);
                setTaskData(taskData);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
        if (GlobalServer != "unknown" && GlobalServer != undefined) {
            fetchStatData();
        }

    }, [GlobalServer]);


    function isValidJSON(jsonString) {
        try {
            JSON.parse(jsonString);
            return true;
        } catch (error) {
            return false;
        }
    }

    var handleDateRange = (startDate, endDate) => {
        const fetchData = async () => {
            try {
                setOffsetData(1);
                setLoading(true);
                var result;
                if ((startDate === "Reset" && endDate === "Reset") && localStorageFetchedServer === null && (GlobalServer === "unknown" || GlobalServer === undefined) && (selectedDri !== "" && selectedDri !== null)) {
                    result = await getFromTimelineDataUserBased(timeLine_page_component_get_query_3(selectedDri), offsetData);;
                }
                else if ((startDate === "Reset" && endDate === "Reset") && localStorageFetchedServer === null && (GlobalServer === "unknown" || GlobalServer === undefined)) {
                    result = await getFromTimelineDataServerBased(timeLine_page_component_get_query_1(userserver), offsetData);
                }
                else if ((startDate === "Reset" && endDate === "Reset") && (GlobalServer != "unknown" && GlobalServer != undefined)) {
                    result = await getFromTimelineDataServerBased(timeLine_page_component_get_query_2(GlobalServer), offsetData);
                }
                else if ((startDate === "Reset" && endDate === "Reset") && localStorageFetchedServer != null && (GlobalServer === "unknown" || GlobalServer === undefined)) {
                    result = await getFromTimelineDataServerBased(timeLine_page_component_get_query_2(localStorageFetchedServer), offsetData);
                }
                else if ((startDate != "Reset" && endDate != "Reset") && (GlobalServer != "unknown" && GlobalServer != undefined)) {
                    result = await getFromTimelineDataServerBased(timeLine_page_component_get_query_6(startDate, endDate, GlobalServer), offsetData);
                }
                else if ((startDate != "Reset" && endDate != "Reset") && localStorageFetchedServer != null && (GlobalServer === "unknown" || GlobalServer === undefined)) {
                    result = await getFromTimelineDataServerBased(timeLine_page_component_get_query_6(startDate, endDate, localStorageFetchedServer), offsetData);
                }
                else if(((startDate != "Reset" && endDate != "Reset") && localStorageFetchedServer === null && (GlobalServer === "unknown" || GlobalServer === undefined))){
                    result = await getFromTimelineDataUserBased(timeLine_page_component_get_query_7(startDate, endDate, selectedDri), offsetData);
                }
                else {
                    result = await getFromTimelineDataServerBased(timeLine_page_component_get_query_5(startDate, endDate), offsetData);
                }
                // const result = await getFromTimelineDataServerBased(timeLine_page_component_get_query_5(startDate,endDate),offsetData);
                const validResults = result.filter(o => isValidJSON(o));
                const parsedResult = validResults.map(o => {
                    try {
                        if (isValidJSON(o)) {
                            return JSON.parse(o, dateTimeReviver);
                        }
                        else {
                            console.log(o)
                        }

                    } catch (e) {
                        console.log(e);
                        return o;
                    }
                });
                setAllDataWithWrongJasonAlso(result);
                setData(parsedResult);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    };

    var handleSearchTextChange = (v) => {
        const fetchData = async () => {
            try {
                setOffsetData(1);
                setLoading(true);
                const result = await getFromTimelineDataUserBased(timeLine_page_component_get_query_4(v), offsetData);
                const validResults = result.filter(o => isValidJSON(o));
                const parsedResult = validResults.map(o => {
                    try {
                        if (isValidJSON(o)) {
                            return JSON.parse(o, dateTimeReviver);
                        }
                        else {
                            console.log(o)
                        }

                    } catch (e) {
                        console.log(e);
                        return o;
                    }
                });
                setAllDataWithWrongJasonAlso(result);
                setData(parsedResult);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    };

    var handleModeChange = v => {
        setSelectedMode(v);
    };

    var handleSecondaryModeChange = v => {
        if (selectedMode === "Component") {
            const fetchData = async () => {
                try {
                    setOffsetData(1);
                    setLoading(true);
                    setSelectedServer(v);
                    setSelectedDri("")
                    localStorage.setItem('selectedServer', v); // Store the selected server
                    const result = await getFromTimelineDataServerBased(timeLine_page_component_get_query_2(v), offsetData);
                    const validResults = result.filter(o => isValidJSON(o));
                    const parsedResult = validResults.map(o => {
                        try {
                            if (isValidJSON(o)) {
                                return JSON.parse(o, dateTimeReviver);
                            }
                            else {
                                console.log(o)
                            }

                        } catch (e) {
                            console.log(e);
                            return o;
                        }
                    });
                    setAllDataWithWrongJasonAlso(result);
                    setData(parsedResult);
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            };

            const fetchStatData = async () => {
                try {
                    const queries = [
                        getForAllDataStat(allStatData_page_component_get_query_4(v)),
                        getForAllDataStat(allStatData_page_component_get_query_5(v)),
                        getForAllDataStat(allStatData_page_component_get_query_6(v))
                    ];
                    const results = await Promise.all(queries);
                    const combinedResults = results.flat(); // Combine all results into a single array
                    const milestoneResult = combinedResults[0].Quarterly;
                    const taskListResult = combinedResults[1].Quarterly;
                    const taskResult = combinedResults[2].Quarterly;

                    const milestoneData = [
                        { label: "Total Count", value: parseInt(milestoneResult['Total_Milestone_Created'], 10) },
                        { label: "Ongoing", value: parseInt(milestoneResult['Ongoing_Milestone'], 10) },
                        { label: "Delayed", value: parseInt(milestoneResult['Delayed_Milestone'], 10) },
                        { label: "On Time", value: parseInt(milestoneResult['Ontime_Milestone'], 10) }
                    ];

                    const taskListData = [
                        { label: "Total Count", value: parseInt(taskListResult['Total_Tasklist_created'], 10) },
                        { label: "Ongoing", value: parseInt(taskListResult['Ongoing_Tasklist'], 10) },
                        { label: "Delayed", value: parseInt(taskListResult['Delayed_Tasklist'], 10) },
                        { label: "On Time", value: parseInt(taskListResult['Ontime_Tasklist'], 10) }
                    ];

                    const taskData = [
                        { label: "Total Count", value: parseInt(taskResult['Total_Task_Created'], 10) },
                        { label: "Ongoing", value: parseInt(taskResult['Ongoing_Task'], 10) },
                        { label: "Delayed", value: parseInt(taskResult['Delayed_Task'], 10) },
                        { label: "On Time", value: parseInt(taskResult['Ontime_Task'], 10) }
                    ];

                    setMilestoneData(milestoneData);
                    setTaskListData(taskListData);
                    setTaskData(taskData);
                } catch (error) {
                    console.error(error);
                }
            };
            fetchStatData();
            fetchData();
        }
        else {
            const fetchData = async () => {
                try {
                    setOffsetData(1);
                    setLoading(true);
                    setSelectedDri(v);
                    setSelectedServer("")
                    localStorage.removeItem('selectedServer');  // to remove the localstorage in browser
                    var result;
                    if (v === null || v === "null") {
                        result = await getFromTimelineDataServerBased(timeLine_page_component_get_query_1(userserver), offsetData);
                    }
                    else{
                        result = await getFromTimelineDataUserBased(timeLine_page_component_get_query_3(v), offsetData);
                    }
                    // const result = await getFromTimelineDataUserBased(timeLine_page_component_get_query_3(v), offsetData);
                    const validResults = result.filter(o => isValidJSON(o));
                    const parsedResult = validResults.map(o => {
                        try {
                            if (isValidJSON(o)) {
                                return JSON.parse(o, dateTimeReviver);
                            }
                            else {
                                console.log(o)
                            }

                        } catch (e) {
                            console.log(e);
                            return o;
                        }
                    });
                    setAllDataWithWrongJasonAlso(result);
                    setData(parsedResult);
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            };
            fetchData();
        }
    };

    const dateTimeReviver = function (key, value) {
        if (key.includes("date") && typeof value === 'string' && isDate(value)) {
            return new Date(value);
        }
        return value;
    }

    window.addEventListener('beforeunload', () => {
        localStorage.removeItem('selectedServer');
    });



    const fetchData = async () => {
        var offsetDataCount = offsetData + 300;
        if ((selectedDri === "" || selectedDri === null || selectedDri === "null") && selectedServer === "") {
            try {
                const result = await getFromTimelineDataServerBased(timeLine_page_component_get_query_1(userserver), offsetDataCount);
                const validResults = result.filter(o => isValidJSON(o));
                const parsedResult = validResults.map(o => {
                    try {
                        if (isValidJSON(o)) {
                            return JSON.parse(o, dateTimeReviver);
                        }
                        else {
                            console.log(o)
                        }

                    } catch (e) {
                        console.log(e);
                        return o;
                    }
                });
                setAllDataWithWrongJasonAlso([...data, ...parsedResult]);
                setData([...data, ...parsedResult]);
            } catch (error) {
                console.error(error);
            } finally {
                setLazzyLoading(false);
            }
        }
        else if (selectedServer !== "") {
            try {
                setLoading(true);
                setSelectedDri("")
                const result = await getFromTimelineDataServerBased(timeLine_page_component_get_query_2(selectedServer), offsetDataCount);
                const validResults = result.filter(o => isValidJSON(o));
                const parsedResult = validResults.map(o => {
                    try {
                        if (isValidJSON(o)) {
                            return JSON.parse(o, dateTimeReviver);
                        }
                        else {
                            console.log(o)
                        }

                    } catch (e) {
                        console.log(e);
                        return o;
                    }
                });
                setAllDataWithWrongJasonAlso([...data, ...parsedResult]);
                setData([...data, ...parsedResult]);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
        else if (selectedDri !== "") {
            try {
                setLoading(true);
                setSelectedServer("")
                const result = await getFromTimelineDataUserBased(timeLine_page_component_get_query_3(selectedDri), offsetDataCount);
                const validResults = result.filter(o => isValidJSON(o));
                const parsedResult = validResults.map(o => {
                    try {
                        if (isValidJSON(o)) {
                            return JSON.parse(o, dateTimeReviver);
                        }
                        else {
                            console.log(o)
                        }

                    } catch (e) {
                        console.log(e);
                        return o;
                    }
                });
                setAllDataWithWrongJasonAlso([...data, ...parsedResult]);
                setData([...data, ...parsedResult]);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
        setOffsetData(offsetDataCount);
    };

    return (
        <div className="page-content-2">
            <Header
                handleSearch={handleSearchTextChange}
                handleModeChange={handleModeChange}
                handleSecondaryModeChange={handleSecondaryModeChange}
                initialMode={initialMode}
                listOfManagers={listOfManagers}
                listOfMentors={listOfMentors}
                listOfDevelopers={listOfDevelopers}
                listOfComponents={listOfComponents}
                GlobalServer={GlobalServer}
                teamDetails={teamDetails}
                handleDateRange={handleDateRange}
                setToOpenTheAllData={setToOpenTheAllData}
            />
            <CircularWithValueLabel loading={loading} /> {/* Render loading indicator */}
            {/* Render your data when not loading */}
            {!loading && data.length !== 0 ? (
                <TimelineComponent data={data} fetchData={fetchData} LazzyLoading={LazzyLoading} setLazzyLoading={setLazzyLoading} offsetData={offsetData} incrementData={incrementData} setOffsetData={setOffsetData}
                    setIncrementData={setIncrementData} milestoneData={milestoneData} taskListData={taskListData} taskData={taskData} allDataWithWrongJasonAlso={allDataWithWrongJasonAlso} toOpenTheAllData={toOpenTheAllData} />
            ) : !loading && data.length === 0 ? (
                <div style={{
                    textAlign: "center",
                    marginTop: "40vh",
                    transform: "translateY(-50%)"
                }}>
                    <img src={theme.palette.mode === "dark" ?  EmptyDataDarkMode : EmptyData} alt="No Data Found Illustration" style={{ width: "200px"}} />
                    <p style={{ fontFamily: "Arial, sans-serif", fontSize: "20px", fontWeight: "bold", color: theme.palette.mode === "dark" ? "white" : "#333"  }}>
                        Oops! Looks like there's no data here.
                    </p>
                </div>
            ) : null}
        </div>
    );
}

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
};

function CircularWithValueLabel({ loading }) {
    const [progress, setProgress] = React.useState(10);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
        }, 800);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return loading ? (
        <div style={{ position: "fixed", top: "50%", left: "60%", transform: "translate(-50%,-50%)" }}>
            <CircularProgressWithLabel value={progress} />
        </div>
    ) : null;
}

function parseDates(data) {
    if (!data) return;
    for (const k in data) {
        const v = data[k];

        if (typeof v === "object") {
            data[k] = parseDates(v);
        }

        if (typeof v === "string" && isDate(v)) {
            data[k] = new Date(v);
        }
    }

    return data;
}

var isDate = function (date) {
    return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
}

//ignorei18n_end