import React from "react";

function ServerConfiguration() {
    return (
        <div className="page-content-2">
            <iframe
                src="https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/ExternalUserRoles/NnnZphAZyvOv9ts0NXQxzrC5wbxsrXrY25zRZe4P295hBYuHyUrSHwbSv4q0yhVtpO1DYrkJDMumyeYJvnH0MXFpA0JwBWH6zC0B?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true"
                title="Team Details"
                frameBorder={0}
                style={{
                    flex: 1
                }}
            />
        </div>
    );
}

export default ServerConfiguration;