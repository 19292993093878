/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import { Skeleton } from '@mui/material';
import Slide from '@mui/material/Slide';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { queryDB } from '../../../util/QueryDB';
import { GetNameAndEmpID, days_between } from '../../../util/Loading.js';
import Box from '@mui/material/Box';
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
// import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import CombinedTasklist from '../../pageContent/CombinedMilestone/CombinedTasklist'
// import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
// import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
// import InsertPageBreakRoundedIcon from '@mui/icons-material/InsertPageBreakRounded';
// import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import ArrowCircleUpTwoToneIcon from '@mui/icons-material/ArrowCircleUpTwoTone';
import ArrowCircleDownTwoToneIcon from '@mui/icons-material/ArrowCircleDownTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import Brightness1TwoToneIcon from '@mui/icons-material/Brightness1TwoTone';
import StopIcon from '@mui/icons-material/Stop';
import PentagonIcon from '@mui/icons-material/Pentagon';
import CircleIcon from '@mui/icons-material/Circle';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import HexagonIcon from '@mui/icons-material/Hexagon';
import { combined_milestone_info_get_query_1 } from '../../../catalystDB/queryGenerator.js';


var dataCache = {};
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function CombinedInfo({ MilestoneName, MilestoneStartDate, MilestoneDueDate, Status,MilestoneId,DRI,Server,index,Tasklistid,TasklistPriority,MilestoneDescription,MilestoneStatus,TasklistStatus}) {
    // console.log(Feature_type)
	const theme = useTheme();
	return (
        <>
        <Box className="info-div-paper"
		sx={{
			padding: "20px",
			margin: "10px",
			backgroundColor: theme.palette.background.infoDivInner + " !important"
		}}>

	   	<CombinedInfoTable MilestoneName={MilestoneName} MilestoneStartDate={MilestoneStartDate}  MilestoneDueDate={MilestoneDueDate} Status={Status} MilestoneId={MilestoneId} DRI={DRI} Server={Server} Index={index} Tasklistid={Tasklistid} TasklistPriority={TasklistPriority} MilestoneDescription={MilestoneDescription} MilestoneStatus={MilestoneStatus} TasklistStatus={TasklistStatus}/>
           </Box>
           </>
	);
}

export function CombinedInfoTable({ MilestoneName, MilestoneStartDate, MilestoneDueDate, Status,MilestoneId,DRI,Server,Index,Tasklistid,TasklistPriority,MilestoneDescription,MilestoneStatus,TasklistStatus}) {
    const [TaskListData, setTaskListData] = useState([]);
	const theme = useTheme();
    // console.log(MilestoneStatus)

	var color = "green";

	const today = new Date();
	const startDate = new Date(MilestoneStartDate);
	const endDate = new Date(MilestoneDueDate);
	const totDays = days_between(startDate, endDate);


	if (today < startDate) {
		color = "green";
	}
	else if(MilestoneDueDate[0] === '-'){
		if(theme.palette.mode === "dark"){
			color = "White";
		}
		else{
			color = "black";
		}
	}
	else if(MilestoneStatus === "Closed" || MilestoneStatus === "Updated to IDC" || MilestoneStatus === "Cancelled" || MilestoneStatus === "Completed"){
		if(theme.palette.mode === "dark"){
			color = "White";
		}
		else{
			color = "black";
		}
    }
	else if (days_between(today, startDate) < (0.25 * totDays).toFixed(0)) {
		color = "forestgreen";
	}
	else if (days_between(today, startDate) < (0.5 * totDays).toFixed(0)) {
		color = "darksalmon";
	}
	else if (days_between(today, startDate) < (0.75 * totDays).toFixed(0)) {
		color = "darkgoldenrod";
	}
	else if (days_between(today, startDate) < (totDays).toFixed(0)) {
		color = "chocolate";
	}
	else {
		color = "crimson";
	}
	if (MilestoneDueDate === "-" || MilestoneStartDate === "-") {
		color = "";
	}

	const TextOnlyTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
      ))(`
          color: black;
          background-color: white;
      `);
      
	
      // const uniqueMilestones = [...new Set(MilestoneId)];
      const uniqueMilestoneIds = new Set();
      let renderIndex = 0;
      const allCompleted = MilestoneStatus.every(status => status === "Completed");
      
    return ( 
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow className="tasklist-table-head">
                <TableCell align="center">S.NO</TableCell>
                <TableCell align="center">Server</TableCell>
                <TableCell align="center">Milestone Name</TableCell>
                <TableCell align="center">DRI</TableCell>
                <TableCell align="center">Priority</TableCell>
                <TableCell align="center">Milestone Start Date</TableCell>
                <TableCell align="center">{allCompleted ? "Milestone Completed Date" : "Milestone Due Date"}</TableCell>
                <TableCell align="center">Milestone Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

            
            {MilestoneName.map((name, index) => {
              const milestoneId = MilestoneId[index];

              if (!uniqueMilestoneIds.has(milestoneId)) {
                uniqueMilestoneIds.add(milestoneId);
                const renderedItem = (
                  <MilestoneTableRow
                    key={renderIndex}
                    Index={renderIndex}
                    MilestoneName={name}
                    Server={Server[index]}
                    DRI={DRI[index]}
                    MilestoneStartDate={MilestoneStartDate[index]}
                    MilestoneDueDate={MilestoneDueDate[index]}
                    color={color}
                    TasklistPriority={TasklistPriority[index]}
                    MilestoneDescription={MilestoneDescription[index]}
                    MilestoneStatus={MilestoneStatus[index]}
                  />
                );

                renderIndex++;

                return renderedItem;
              }
            })}



              {/* {MilestoneName.map((name, index) => (
                
                <MilestoneTableRow
                  key={index}
                  Index={index}
                  MilestoneName={name}
                  Server={Server[index]}
                  DRI={DRI[index]}
                  MilestoneStartDate={MilestoneStartDate[index]}
                  MilestoneDueDate={MilestoneDueDate[index]}
                  color={color}
                  TasklistPriority={TasklistPriority[index]}
                  MilestoneDescription={MilestoneDescription[index]}
                  MilestoneStatus={MilestoneStatus[index]}
                 
                />
              ))} */}
            </TableBody>
          </Table>
        </TableContainer>
      );
      
  }

  function MilestoneTableRow({ MilestoneName, Server, DRI, MilestoneStartDate, MilestoneDueDate, color, key ,Index,TasklistPriority,MilestoneDescription,MilestoneStatus}) {
    const [open, setOpen] = React.useState(null);
    const [taskListData, setTaskListData] = React.useState([]);
    const theme = useTheme();
  
    const handleClick = async () => {
      if (open === key) {
        setOpen(null);
      } else {
        setOpen(key);
        await fetchValuesData();
      }
    };
  
    const fetchValuesData = async () => {
      try {
        const query = combined_milestone_info_get_query_1(MilestoneName, Server);
        if (query in dataCache) {
          setTaskListData(dataCache[query]);
        } else {
          const result = await queryDB(query);
          dataCache[query] = result;
          setTaskListData(result);
        }
      } catch (error) {
       
      }
    };


    var dataAsArray = taskListData.map((item) => {
		return [
			
			item.TaskDetails.TasklistName,
			item.TaskDetails.DRI,
      [undefined, "", " ", null,0].includes(item.TaskDetails.TasklistPriority) ? "None" : item.TaskDetails.TasklistPriority,
			[undefined, "", " ", null].includes(item.TaskDetails.TasklistStartDate) ? "-" : item.TaskDetails.TasklistStartDate,
			[undefined, "", " ", null].includes(item.TaskDetails.TasklistDueDate) ? "-" : item.TaskDetails.TasklistDueDate,
      [undefined, "", " ", null,0].includes(item.TaskDetails.TasklistStatus) ? "-" : item.TaskDetails.TasklistStatus,
		  // item.TaskDetails.TasklistStatus,
			item.TaskDetails.Component,
			item.TaskDetails.Tasklistid,
			item.TaskDetails.TasklistDescription,
      item.TaskDetails.MilestoneId
		];
	});

  // var status = <></>;

	// if (tasklistStatus === "Ongoing") {
	// 	status = <AccessTimeFilledRoundedIcon style={{ color: "orange" }} />;
	// }
	// else if (tasklistStatus === "Completed") {
	// 	status = <CheckCircleRoundedIcon style={{ color: "green" }} />;
	// }
	// else if (tasklistStatus === "Ready for task breaking") {
	// 	status = <InsertPageBreakRoundedIcon style={{ color: "chocolate" }} />;
	// }
	// else if (tasklistStatus === "Broken down into individual tasks") {
	// 	status = <PlaylistAddCheckRoundedIcon style={{ color: "teal" }} />;
	// }

	var priority = "none";

	if (TasklistPriority === "High") {
		priority = <ArrowCircleUpTwoToneIcon style={{ color: "crimson" }} />;
	}
	else if (TasklistPriority === "Medium") {
		priority = <RemoveCircleTwoToneIcon style={{ color: "orange" }} />;
	}
	else if (TasklistPriority === "Low") {
		priority = <ArrowCircleDownTwoToneIcon style={{ color: "mediumseagreen" }} />;
	}
	else {
		priority = <Brightness1TwoToneIcon style={{ color: "grey" }} />;
	}

	const statusComponent = {
		"Open": {
			"name": "Open",
			"color": theme.palette.mode === "dark" ? "cornflowerblue" : "blue",
			"component": <PlayArrowIcon style={{ width: "22px", marginRight: "1px" }} />
		},
		"Ongoing": {
			"name": "Ongoing",
			"color": "orange",
			"component": <CircleIcon style={{ width: "15px", marginRight: "5px" }} />
		},
		"Onhold": {
			"name": "Onhold",
			"color": "mediumorchid",
			"component": <StopIcon style={{ width: "20px", marginRight: "5px" }} />
		},
		"TasklistCompleted": {
			"name": "Completed",
			"color": "green",
			"component": <PentagonIcon style={{ width: "15px", marginRight: "5px" }} />
		},
		"Closed": {
			"name": "Completed",
			"color": "green",
			"component": <PentagonIcon style={{ width: "15px", marginRight: "5px" }} />
		},
		"-": {
			"name": "-",
			"color": theme.palette.mode === "dark" ? "white" : "black",
			"component": <></>
		}
	};

	var color = "green";

	const today = new Date();
	const startDate = new Date(MilestoneStartDate);
	const endDate = new Date(MilestoneDueDate);
	const totDays = days_between(startDate, endDate);

	// console.log(days_between(today, startDate));

	if (today < startDate) {
		color = "green";
	}
	// else if(tasklistStatus === "Closed" || tasklistStatus === "Updated to IDC" || tasklistStatus === "Cancelled" || tasklistStatus === "Completed"){
	// 	if(theme.palette.mode === "dark"){
	// 		color = "White";
	// 	}
	// 	else{
	// 		color = "black";
	// 	}
  //   }
	else if (days_between(today, startDate) < (0.25 * totDays).toFixed(0)) {
		color = "forestgreen";
	}
	else if (days_between(today, startDate) < (0.5 * totDays).toFixed(0)) {
		color = "darksalmon";
	}
	else if (days_between(today, startDate) < (0.75 * totDays).toFixed(0)) {
		color = "darkgoldenrod";
	}
	else if (days_between(today, startDate) < (totDays).toFixed(0)) {
		color = "chocolate";
	}
	else {
		color = "crimson";
	}
	if (MilestoneStartDate === "-" || MilestoneDueDate === "-") {
		color = "";
	}

	const TextOnlyTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
      ))(`
          color: black;
          background-color: white;
      `);

      var status = <HexagonIcon style={{ color: "pink", fontSize: "small" }} />;
      // console.log(dataAsArray.map((item) => item[5]))
    return (
        <>
      <TableRow
        sx={{
          backgroundColor: theme.palette.background.tableRow,
          '&:hover': {
            backgroundColor: theme.palette.background.tableRowHover + " !important",
          },
        }}
      ><TableCell onClick={handleClick} align="center">{Index+1}</TableCell>
        <TableCell onClick={handleClick} align="center">{Server.length === 0 ? <Skeleton /> : Server} </TableCell>
        <TextOnlyTooltip title={<span style={{ fontFamily: 'Poppins' ,fontSize: '0.875rem', color: "Black" ,backgroundImage:"" }}>{MilestoneDescription} </span>}  followCursor >
        <TableCell onClick={handleClick} align="center">{MilestoneName.length === 0 ? <Skeleton /> : MilestoneName}</TableCell>
        </TextOnlyTooltip>
        <TableCell onClick={handleClick} align="center">{DRI.length === 0 ? <Skeleton /> : DRI}</TableCell>
        <TableCell  align="center"><Box>{TasklistPriority.length === 0 ? <Skeleton /> :priority}<p>{TasklistPriority.length === 0 ? <Skeleton /> :TasklistPriority}</p></Box></TableCell>
        <TableCell onClick={handleClick} align="center">{MilestoneStartDate.length === 0 ? <Skeleton /> : MilestoneStartDate}</TableCell>
        <TableCell
          onClick={handleClick}
          align="center"
          sx={{color: color + " !important"}}
        >
          {MilestoneDueDate.length === 0 ? <Skeleton /> : MilestoneDueDate}
        </TableCell>
        <TableCell onClick={handleClick} align="center"><Box>{status}<p>{MilestoneStatus.length === 0 ? <Skeleton /> : MilestoneStatus}</p></Box></TableCell>
        <TableCell align="left" className='slide-animation' style={{ width: "30px" }}>
          <IconButton size="small" onClick={handleClick}>
            {open === null ? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
       <TableRow>
						<TableCell colSpan={10} sx={{ padding: 0}}>
							<Collapse in={open !== null} timeout="auto" unmountOnExit>
								<CombinedTasklist TasklistName={dataAsArray.map((item) => item[0])} DRI={dataAsArray.map((item) => item[1])} TasklistPriority={dataAsArray.map((item) => item[2])} TasklistStartDate={dataAsArray.map((item) => item[3])} TasklistDueDate={dataAsArray.map((item) => item[4])} TasklistStatus={dataAsArray.map((item) => item[5])} milestone_name={MilestoneName} TaskServerName ={dataAsArray.map((item) => item[6])} Tasklistid={dataAsArray.map((item) => item[7])} TasklistDescription={dataAsArray.map((item) => item[8])}/>
							</Collapse>
						</TableCell>
			</TableRow>
         </>
    );
  }
  