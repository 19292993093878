/* eslint-disable react/prop-types */

import React, {useEffect} from "react";
import { Box } from "@mui/material";
import { PieChart } from "../../../../../util/components/pieChart/PieChart";
import { useTheme } from '@mui/material/styles';
import { PIE_CHART_COLORS_DARK, PIE_CHART_COLORS_LIGHT } from "../../../dashboard/dashboardMain/DashboardUtil";

export function OnTime_VS_Delayed({data}) {

    const theme = useTheme();

    var colors =  ["#1CBF54", "#B11C1D"];
    if(theme.palette.mode === "dark") {
        colors =["#1ECF5B", "#E54156"];
    }

    return (
        <Box sx={{
            height: "100%",
            width: "100%"
        }}>
             <PieChart data={data.tasks_on_time_vs_delayed}
                title={""}
                colors={colors}
                hallow={20}
                padAngle={ 0.060}
                // open={isOpen}
                // clickHandler={clickHandler}
                rerender={data.tasks_on_time_vs_delayed}
            />
        </Box>
    );
}