/* eslint-disable react/prop-types */
import React, { useState, useContext,useEffect } from "react";
import { UserContext } from "../../../../contexts/UserContext";
import { useTheme } from '@mui/material';
import {
    Box, 
    Skeleton,
    Button,
    ButtonGroup, 
    Dialog, 
    DialogActions, 
    DialogContent,
    TableContainer, 
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Select,
    MenuItem,
    TextField,
    Alert,
    Chip
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { executeQuery, queryDB } from "../../../../util/QueryDB";
import { admin_build_configuration_get_query, admin_build_configuration_get_query_2, admin_build_configuration_get_query_3 } from "../../../../catalystDB/queryGenerator";

export default function BuildConfiguration() {
    const [rows, setRows] = useState(null);
    const {loginUserDept} = useContext(UserContext);

    useEffect(() => {

        async function getDcOrder() {
            const result = await queryDB(admin_build_configuration_get_query(loginUserDept));
        //     var arr = [];

        //     for(var r of result) {
        //         arr.push([r.DCConfiguration.Server,
        //             r.DCConfiguration.CSEZ,
        //             r.DCConfiguration.LOCAL,
        //             r.DCConfiguration.CT2,
        //             r.DCConfiguration.US3PRE,
        //             r.DCConfiguration.US4PRE,
        //             r.DCConfiguration.US3,
        //             r.DCConfiguration.US4,
        //             r.DCConfiguration.IN2,
        //             r.DCConfiguration.IN1,
        //         ]);
        //     }

        //     setRows(arr);
        // }
            var arr = [];

            for (var r of result) {
                const propertiesToExtract = ["Server", "CSEZMain", "LOCALMain", "CT2", "US3PRE", "US4PRE", "US3", "US4", "IN2", "IN1", "EU1", "EU2", "AU1", "AU2", "JP1", "JP2","CN2","CN3","CA1","CA2","UK1","UK2","AR1","AR2","UBI1","UB2","LOCALPre","CSEZPre"];
                const extractedValues = [];

                for (const prop of propertiesToExtract) {
                    extractedValues.push(r.DCConfiguration[prop]);
                }

                arr.push(extractedValues);
            }

            setRows(arr);
            //console.log(arr);
        }
        getDcOrder();

    }, []);

    if(rows === null) {
        return (
            <Box className='page-content-2'>
                <Skeleton variant="rectangular" width={"100%"} height={35} />
                <br></br>
                <Skeleton variant="rectangular" width={"100%"} sx={{ height: "calc(100vh - 175px)" }} />
            </Box>
        );
    }

    return (
        <Box className='page-content-2' sx={{overflow:"scroll"}}>
             <TopBar rows={rows} setRows={setRows}/>
            <Content rows={rows} setRows={setRows}/>
            {/* <AddNewConf /> */}
        </Box>
    );
}

function TopBar({rows, setRows}) {

    const [openPopup, setOpenPopup] = useState(false);

    const handleMenuOpen = (event) => {
		setOpenPopup(true);
	};

    const HandleClosePopup = () => {
		setOpenPopup(false);
	};

    return (
        <div className="mytasks-top-bar">
            <div className="mytasks-top-bar-left-group">
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Button variant="contained" size="small" onClick={handleMenuOpen}>
                        <AddIcon />
                    </Button>
                </ButtonGroup>
            </div>
            <Dialog open={openPopup} onClose={HandleClosePopup} fullScreen>
                <DialogActions>
                    <Button onClick={HandleClosePopup} variant="text" fontSize="large"><CloseIcon /></Button>
                </DialogActions>
                <DialogContent>
                    <AddNewConf setOpenPopup={setOpenPopup} rows={rows} setRows={setRows}/>
                </DialogContent>
            </Dialog>
        </div>
    );
}

function Content({rows, setRows}) {
    return (
        <div className="mytasks-table">
            <TableContainer className="mytasks-table-container" sx={{
                flex: 1
            }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow className="mytasks-table-head">
                            <TableCell align="left" sx={{maxWidth: "5px"}}>S.No.</TableCell>
                            <TableCell align="center">Server</TableCell>
                            <TableCell align="center">CSEZMain</TableCell>
                            <TableCell align="center">LOCALMain</TableCell>
                            <TableCell align="center">CT2</TableCell>
                            <TableCell align="center">US3PRE</TableCell>
                            <TableCell align="center">US4PRE</TableCell>
                            <TableCell align="center">US3</TableCell>
                            <TableCell align="center">US4</TableCell>
                            <TableCell align="center">IN2</TableCell>
                            <TableCell align="center">IN1</TableCell>
                            <TableCell align="center">EU1</TableCell>
                            <TableCell align="center">EU2</TableCell>
                            <TableCell align="center">AU1</TableCell>
                            <TableCell align="center">AU2</TableCell>
                            <TableCell align="center">JP1</TableCell>
                            <TableCell align="center">JP2</TableCell>
                            <TableCell align="center">CN2</TableCell>
                            <TableCell align="center">CN3</TableCell>
                            <TableCell align="center">CA1</TableCell>
                            <TableCell align="center">CA2</TableCell>
                            <TableCell align="center">UK1</TableCell>
                            <TableCell align="center">UK2</TableCell>
                            <TableCell align="center">AR1</TableCell>
                            <TableCell align="center">AR2</TableCell>
                            <TableCell align="center">UBI1</TableCell>
                            <TableCell align="center">UB2</TableCell>
                            <TableCell align="center">LOCALPRE</TableCell>
                            <TableCell align="center">CSEZPRE</TableCell>
                            <TableCell align="right" sx={{maxWidth: "5px"}} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows.map((row, index) => {
                                return <Row index={index} row={row} rows={rows} setRows={setRows} key={index} />
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
function Row({index, row, rows, setRows}) {
    const theme = useTheme();

    return (
        <TableRow
            sx={{
                '& > *': { borderBottom: 'unset' },
                backgroundColor: theme.palette.background.tableRow,
                '&:hover': {
                    backgroundColor: theme.palette.background.tableRowHover + " !important"
                }
            }}
        >
            <TableCell>{index + 1}</TableCell>
            <TableCell align="center">{row[0]}</TableCell>
            <TableCell align="center">{row[1]}</TableCell>
            <TableCell align="center">{row[2]}</TableCell>
            <TableCell align="center">{row[3]}</TableCell>
            <TableCell align="center">{row[4]}</TableCell>
            <TableCell align="center">{row[5]}</TableCell>
            <TableCell align="center">{row[6]}</TableCell>
            <TableCell align="center">{row[7]}</TableCell>
            <TableCell align="center">{row[8]}</TableCell>
            <TableCell align="center">{row[9]}</TableCell>
            <TableCell align="center">{row[10]}</TableCell>
            <TableCell align="center">{row[11]}</TableCell>
            <TableCell align="center">{row[12]}</TableCell>
            <TableCell align="center">{row[13]}</TableCell>
            <TableCell align="center">{row[14]}</TableCell>
            <TableCell align="center">{row[15]}</TableCell>
            <TableCell align="center">{row[16]}</TableCell>
            <TableCell align="center">{row[17]}</TableCell>
            <TableCell align="center">{row[18]}</TableCell>
            <TableCell align="center">{row[19]}</TableCell>
            <TableCell align="center">{row[20]}</TableCell>
            <TableCell align="center">{row[21]}</TableCell>
            <TableCell align="center">{row[22]}</TableCell>
            <TableCell align="center">{row[23]}</TableCell>
            <TableCell align="center">{row[24]}</TableCell>
            <TableCell align="center">{row[25]}</TableCell>
            <TableCell align="center">{row[26]}</TableCell>
            <TableCell align="center">{row[27]}</TableCell>
            <TableCell align="right">
                <Button sx={{
                    borderRadius: "300px"
                }} color="error">
                </Button>
            </TableCell>
        </TableRow>
    );
}

function AddNewConf({setOpenPopup, rows, setRows})
{
    const { userDetails, teamDetails,loginUserDept } = useContext(UserContext);
    const user_email = userDetails.current.auth.email_id;
    const user_servers = teamDetails.current[user_email].Servers;
    const [selectedOption, setSelectedOption] = useState("");
    const [channelID, setChannelID] = useState("");
    const dcArray = ["CSEZMain", "LOCALMain", "CT2","US4","US3","IN1","IN2","US4PRE","US3PRE","EU1","EU2","AU1","AU2","CN2","CN3","JP1","JP2","AR1","AR2","UBI1","UB2","UK1","UK2","CA1","CA2","LOCALPre","CSEZPre"];
    const [reorderedDCs, setReorderedDCs] = useState(dcArray);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertSeverity, setAlertSeverity] = useState(null);
    //const dcArray = ["CSEZ", "LOCAL", "CT2"];

    const theme = useTheme();
    var options = [];
    var labels = [];

    options = options.concat(user_servers.split(','));

    async function handleFormSubmit() {

        // Initialize empty arrays for indexes and values
        const indexes = [];
        const values = [];
        //const indexedValues = [];

        //console.log(reorderedDCs);
         const missingDCs = dcArray.filter(dc => !reorderedDCs.includes(dc));
        // reorderedDCs.forEach((value, index) => {
        //     // Add 1 to the index to make it 1-based (1, 2, 3, ...)
        //     const indexedValue = `${index + 1}. ${value}`;
        //     indexedValues.push(indexedValue);
        // });
        const indexedValues = reorderedDCs.map((value, index) => {
            return `${index + 1}. ${value}`;
        });
        
        missingDCs.forEach((value) => {
            // Add missing DCs with index -1
            indexedValues.push(`-1. ${value}`);
        });
        
        //console.log(indexedValues);

        //console.log("Indexed Values:", indexedValues);

        // Spliting the index and value and storing them in separate arrays
        indexedValues.forEach((dc) => {
            const [index, value] = dc.split(". ");
            indexes.push(index);
            values.push(value);
        });
        //console.log(values);
        const valuesString = values.join(', ');

        // Now, 'valuesString' contains the comma-separated values
        const indexString = indexes.join(', ');
        //console.log("Indexes as String:", indexString);

        const pairs = [];

        for (let i = 0; i < values.length; i++) {
            const pair = `${values[i]}='${indexes[i]}'`;
            pairs.push(pair);
        }

        const resultString = pairs.join(',');
        //console.log(resultString);

        if (!selectedOption || !reorderedDCs) {
            setAlertSeverity("error");
            setAlertMessage("Please Select the Server.");
            setTimeout(() => {
                setAlertMessage(null);
            }, 3000);
            return;
        }

        try {
            const selectResult = await executeQuery(admin_build_configuration_get_query(loginUserDept));
            const serverArray = [];

            for (const row of selectResult) {
                serverArray.push(row.DCConfiguration.Server);
            }

            //console.log(serverArray);

            // Check if selectedOption is not in the serverArray
            if (!serverArray.includes(selectedOption)) {
                try {
                    // Execute the INSERT statement
                    const insertResult = await executeQuery(admin_build_configuration_get_query_2(valuesString, selectedOption, indexString, loginUserDept));
                    setAlertSeverity("success");
                    setAlertMessage("Successfully Added !");
                    setTimeout(() => {
                        setAlertMessage(null);
                        setOpenPopup(false);
                    }, 2000);

                } catch (e) {
                    console.error("Error inserting row:", e);
                    setAlertSeverity("error");
                    setAlertMessage("Unable to add :(");
                    setTimeout(() => {
                        setAlertMessage(null);
                    }, 3000);
                }
            } 
            else 
            {
                try 
                {
                //console.log("Row with Server value already exists in the database.");
                const updateResult = await executeQuery(admin_build_configuration_get_query_3(resultString, selectedOption, loginUserDept));
                setAlertSeverity("success");
                setAlertMessage("Successfully Updated the Values !");
                setTimeout(() => {
                    setAlertMessage(null);
                    setOpenPopup(false);
                }, 2000);
                // const updatedValues =[updateResult[0].DCConfiguration.Server,
                //     updateResult[0].DCConfiguration.LOCAL,
                //     updateResult[0].DCConfiguration.CT2,
                //     updateResult[0].DCConfiguration.US3PRE,
                //     updateResult[0].DCConfiguration.US4PRE,
                //     updateResult[0].DCConfiguration.US3,
                //     updateResult[0].DCConfiguration.US4,
                //     updateResult[0].DCConfiguration.IN2,
                //     updateResult[0].DCConfiguration.IN1,
                //     updateResult[0].DCConfiguration.EU1,
                //     updateResult[0].DCConfiguration.EU2,
                //     updateResult[0].DCConfiguration.AU1,
                //     updateResult[0].DCConfiguration.AU2,
                //     updateResult[0].DCConfiguration.JP1,
                //     updateResult[0].DCConfiguration.JP2,
                //     updateResult[0].DCConfiguration.CN2,
                //     updateResult[0].DCConfiguration.CN3,
                //     updateResult[0].DCConfiguration.CA1,
                //     updateResult[0].DCConfiguration.CA2,
                //     updateResult[0].DCConfiguration.UK1,
                //     updateResult[0].DCConfiguration.UK2,
                //     updateResult[0].DCConfiguration.AR1,
                //     updateResult[0].DCConfiguration.AR2,
                //     updateResult[0].DCConfiguration.IN2,
                //     updateResult[0].DCConfiguration.UBI1,
                //     updateResult[0].DCConfiguration.UB2
                // ];
                // const rowIndexToUpdate = rows.findIndex((row) => {
                //     console.log(row);
                //     return row[0] === selectedOption; // Replace this condition with your own
                // });
            
                // if (rowIndexToUpdate !== -1) {
                //     // Create a copy of the current rows array
                //     const updatedRows = [...rows];
            
                //     // Update the specific row with the updated values
                //     updatedRows[rowIndexToUpdate] = updatedValues;
            
                //     // Update the rows state with the modified copy
                //     setRows(updatedRows);
                // }
            }
                catch (e) {
                    setAlertSeverity("error");
                    setAlertMessage("Unable to add :(");
                    setTimeout(() => {
                        setAlertMessage(null);
                    }, 3000);
                }
            }
        }
        catch (e) {
            setAlertSeverity("error");
            setAlertMessage("Error while fetching in DB :(");
            setTimeout(() => {
                setAlertMessage(null);
            }, 3000);
        }
    }

    return (
        <Box
            sx={{
                width: "97%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: theme.palette.mode === "light" ? "#fff" : "1A1E24",
                padding: "20px", 
                fontFamily: 'Poppins, sans-serif'
            }}
        >
            <Box
                sx={{
                    border: "1px solid #ddd", 
                    borderRadius: "5px", 
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)", 
                    backgroundColor: "#fff", 
                    width: "90%", 
                    padding: "20px", 
                    fontFamily: 'Poppins, sans-serif',
                    color: theme.palette.background.color,
                    backgroundColor: theme.palette.mode === "light" ? "#fff" : "1A1E24"
                }}
            >
                <h2 style={{display:"flex",justifyContent: "center",alignItems: "center" }}>DC Configuration</h2>
                <form>
                    {/* Your form content here */}
                    {/* Server input */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            alignItems: "left",
                            justifyContent: "center",
                            fontFamily: 'Poppins, sans-serif'
                        }}
                    >
                        <h4>Server</h4>
                        <SingleSelect
                            options={options}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            label={labels}
                            height={"50px"}
                            required={true}
                        />
                    </Box>

                    {/* DC Configuration */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            alignItems: "left",
                            justifyContent: "center",
                            width: "50%",
                            paddingLeft: "375px",
                            fontFamily: 'Poppins, sans-serif'
                        }}
                    >
                        <h3 style={{display:"flex",justifyContent: "center",alignItems: "center" }}>DC Order</h3>
                        <DCList
                            dcArray={dcArray}
                            reorderedDCs={reorderedDCs}
                            setReorderedDCs={setReorderedDCs}
                        />
                    </Box>

                    {/* Alert message */}
                    {alertMessage !== null ? (
                        <Alert severity={alertSeverity} sx={{ marginTop: "10px" }}>
                            {alertMessage}
                        </Alert>
                    ) : null}

                    {/* Submit button */}
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontFamily: 'Poppins, sans-serif',
                            marginTop: "20px" // Add some space between the form and the submit button
                        }}
                    >
                        <Button variant="contained" onClick={handleFormSubmit}>
                            Submit
                        </Button>
                    </Box>
                </form>
            </Box>
        </Box>


    )
}

// function DCList({ dcArray,reorderedDCs,setReorderedDCs }) {
//     const [dcValues, setDCValues] = useState({});
//     //const [reorderedDCs, setReorderedDCs] = useState(dcArray);
//     const theme = useTheme();
    

//     const handleDCChange = (event, dcName) => {
//         const newValue = event.target.value;
//         setDCValues((prevValues) => ({
//             ...prevValues,
//             [dcName]: newValue,
//         }));
//     };

//     const handleDeleteDC = (index) => {
//         const updatedDCs = [...reorderedDCs];
//         updatedDCs.splice(index, 1);

//         if (updatedDCs.length === 0) {
//             updatedDCs.push("Default");
//         }

//         setReorderedDCs(updatedDCs);
//     };

//     const handleDragEnd = (result) => {
//         if (!result.destination) return; // If the item was dropped outside of the list

//         const updatedDCs = Array.from(reorderedDCs);
//         const [reorderedDC] = updatedDCs.splice(result.source.index, 1);
//         updatedDCs.splice(result.destination.index, 0, reorderedDC);

//         // Update the reorderedDCs state
//         setReorderedDCs(updatedDCs);
//         //console.log(updatedDCs);
//     };

//     return (
//         <>
//         <div>
//             <DragDropContext onDragEnd={handleDragEnd}>
//                 <Droppable droppableId="dc-list">
//                     {(provided) => (
//                         <div ref={provided.innerRef} {...provided.droppableProps}>
//                             {reorderedDCs.map((dcName, index) => (
//                                 <Draggable key={dcName} draggableId={dcName} index={index}>
//                                     {(provided) => (
//                                         <div
//                                             ref={provided.innerRef}
//                                             {...provided.draggableProps}
//                                             {...provided.dragHandleProps}
//                                         >
//                                             <Box
//                                                 sx={{
//                                                     display: "flex",
//                                                     alignItems: "center",
//                                                     gap: "10px"
//                                                 }}
//                                             >
//                                                 <Chip
//                                                     label={
//                                                         <Box
//                                                             style={{
//                                                                 display: "flex",
//                                                                 flexDirection: "row",
//                                                                 justifyContent: "center",
//                                                                 alignItems: "center",
//                                                             }}
//                                                         >
//                                                             <Box
//                                                                 style={{
//                                                                     color: theme.palette.background.color,
//                                                                     borderRight: "1px solid #53291c",
//                                                                     paddingRight: "6px",
//                                                                     width:"150px",
//                                                                     //height:"36px",
//                                                                     display: "flex",
//                                                                     alignItems: "left",
//                                                                     justifyContent: "left",
//                                                                 }}
//                                                             >
//                                                                 {index + 1}. {dcName}
//                                                             </Box>
//                                                         </Box>
//                                                     }
//                                                     sx={{
//                                                         background: (theme.palette.mode === "light" ? "champagne" : "#666666") + " !important",
//                                                         border: "1px solid #53291c",
//                                                     }}
//                                                     onDelete={() => handleDeleteDC(index)}
//                                                     deleteIcon={
//                                                         <CancelIcon
//                                                             onMouseDown={(event) =>
//                                                                 event.stopPropagation()
//                                                             }
//                                                             sx={{
//                                                                 color: "#53291c !important",
//                                                             }}
//                                                         />
//                                                     }
//                                                 />
//                                             </Box>
//                                         </div>
//                                     )}
//                                 </Draggable>
//                             ))}
//                             {provided.placeholder}
//                         </div>
//                     )}
//                 </Droppable>
//             </DragDropContext>
//         </div>
//         <Box>

//         </Box>
//         </>
//     );
// }

function DCList({ dcArray, reorderedDCs, setReorderedDCs }) {
    const [dcValues, setDCValues] = useState({});
    const [deletedDCs, setDeletedDCs] = useState([]); // Store deleted DCs

    const theme = useTheme();

    const handleDCChange = (event, dcName) => {
        const newValue = event.target.value;
        setDCValues((prevValues) => ({
            ...prevValues,
            [dcName]: newValue
        }));
    };

    const handleDeleteDC = (index) => {
        const updatedDCs = [...reorderedDCs];
        const [deletedDC] = updatedDCs.splice(index, 1);

        if (updatedDCs.length === 0) {
            return;
        }

        // Add the deleted DC to the deletedDCs array
        setDeletedDCs((prevDeletedDCs) => [...prevDeletedDCs, deletedDC]);

        if (updatedDCs.length === 0) 
        {
            updatedDCs.push(" ");
        }

        setReorderedDCs(updatedDCs);
        //console.log(updatedDCs);
    };

    const handleAddDeletedDC = (deletedDC, index) => {
        const updatedDeletedDCs = [...deletedDCs];
        updatedDeletedDCs.splice(index, 1);

        
        const updatedDCs = [...reorderedDCs];
        updatedDCs.push(deletedDC);

        setDeletedDCs(updatedDeletedDCs);
        setReorderedDCs(updatedDCs);
    };

    const handleDragEnd = (result) => {
        if (!result.destination)  // If the item was dropped outside of the list
        {
            return;
        }
        const updatedDCs = Array.from(reorderedDCs);
        const [reorderedDC] = updatedDCs.splice(result.source.index, 1);
        updatedDCs.splice(result.destination.index, 0, reorderedDC);

        // Update the reorderedDCs state
        setReorderedDCs(updatedDCs);
    };

    return (
        <div style={{display:'flex',
        flexDirection:"row",
        justifyContent:"space-between",
        width:"100%"
        }}>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="dc-list">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {reorderedDCs.map((dcName, index) => (
                                <Draggable key={dcName} draggableId={dcName} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "10px",
                                                    marginBottom: "1.5px"
                                                }}
                                            >
                                                <Chip
                                                    label={
                                                        <Box
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                justifyContent: "center",
                                                                alignItems: "center"
                                                            }}
                                                        >
                                                            <Box
                                                                style={{
                                                                    color: theme.palette.background.color,
                                                                    borderRight: "1px solid #53291c",
                                                                    paddingRight: "6px",
                                                                    width: "150px",
                                                                    display: "flex",
                                                                    alignItems: "left",
                                                                    justifyContent: "left"
                                                                }}
                                                            >
                                                                {index + 1}. {dcName}
                                                            </Box>
                                                        </Box>
                                                    }
                                                    sx={{
                                                        background: (theme.palette.mode === "light"
                                                            ? "champagne"
                                                            : "#666666") + " !important",
                                                        border: "1px solid #53291c"
                                                    }}
                                                    onDelete={() => handleDeleteDC(index)}
                                                    deleteIcon={
                                                        <CancelIcon
                                                            onMouseDown={(event) =>
                                                                event.stopPropagation()
                                                            }
                                                            sx={{
                                                                color: "#53291c !important"
                                                            }}
                                                        />
                                                    }
                                                />
                                            </Box>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <div>
            {deletedDCs.length > 0 && <h4>Available DCs:</h4>}
                {deletedDCs.map((deletedDC, index) => (
                    <Box
                        key={deletedDC}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center", // Align to the right
                            marginBottom: "1.5px"
                        }}
                    >
            {/* <Chip label={deletedDC} />
            <span
                style={{ cursor: "pointer" }}
                onClick={() => handleAddDeletedDC(deletedDC, index)}
            >
                (Add Back)
            </span> */}

                        <Chip
                            label={
                                <Box
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    <Box
                                        style={{
                                            color: theme.palette.background.color,
                                            borderRight: "1px solid #53291c",
                                            paddingRight: "6px",
                                            width: "150px",
                                            display: "flex",
                                            alignItems: "left",
                                            justifyContent: "left"
                                        }}
                                    >
                                        {deletedDC}
                                    </Box>
                                </Box>
                            }
                            sx={{
                                background: (theme.palette.mode === "light"
                                    ? "champagne"
                                    : "#666666") + " !important",
                                border: "1px solid #53291c"
                            }}
                            onDelete={() => handleAddDeletedDC(deletedDC, index)}
                            deleteIcon={
                                <AddIcon
                                    onMouseDown={(event) =>
                                        event.stopPropagation()
                                    }
                                    sx={{
                                        color: "#53291c !important"
                                    }}
                                />
                            }
                        />
                    </Box>
                ))}
            </div>
        </div>
    );
}


function SingleSelect({ options, selectedOption, label, setSelectedOption, height, required = false }) {
	const [clearButton, setClearButton] = useState(false);

	function handleSelect(event) 
	{
		setSelectedOption(event.target.value);
		setClearButton(true);
	}

	function handleMouseEnter() 
	{
		if (selectedOption === "") {
			return;
		}
		setClearButton(true);
	}

	function handleMouseLeave() 
	{
		if (selectedOption === "") {
			return;
		}
		setClearButton(false);
	}

	function handleClearClick() 
	{
		setSelectedOption("");
		setClearButton(false);
	}

	function handleUnfocus(event) 
	{
		setTimeout(() => setClearButton(false), 200);
	}

	return (
		<Box style={{
			display: "flex",
			flexDirection: "row",
			justifyContent: "left"
		}}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<Select
				required={required}
				labelId="role-user-email"
				value={selectedOption}
				style={{
					minHeight: height,
					height: "auto",
					minWidth: "250px",
					maxWidth: "400px"
				}}
				MenuProps={{
					style: {
						maxHeight: 600
					}
				}}
				onChange={handleSelect}
				onBlur={handleUnfocus}
			>
				{options.map((value, index) => (
					<MenuItem key={index} value={value}>{value}</MenuItem>
				))};
			</Select>
			<Button style={{ visibility: clearButton ? "visible" : "hidden" }} onClick={handleClearClick}>
				<CancelIcon fontSize="small" color="error" />
			</Button>
		</Box>
	);
}