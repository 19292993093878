import { QUERY_DB_1_DEFAULT, QUERY_DB_2_DEFAULT } from "../catalystDB/queryGenerator";

export async function queryDB(queryString = QUERY_DB_1_DEFAULT, offset=1) {
    const increment = 300;
    return new Promise((resolve, reject) => {
        var zcql = window.catalyst.ZCatalystQL;
		var zcqlPromise  = zcql.executeQuery(queryString+" LIMIT " + offset + ", " + increment);

        zcqlPromise.then(async (response) => {

            var responseFull = response.content;
            if(response.status !== 200)
            {
                return reject(response.content);
            }
            try
            {
                if(response.content.length !== 0)
                {
                    const nextResponses = await queryDB(queryString, offset=offset+increment);
        
                    responseFull = response.content.concat(nextResponses);
                }
            }

            
            catch(e)
            {
                console.log(e);
                return reject(e);
            }
            
            return resolve(responseFull);
        })
        .catch((err) => {
            return reject(err);
        });
    });
}

export async function queryDBNew(queryString = QUERY_DB_1_DEFAULT, offset=1) {
    const increment = 300;
    return new Promise((resolve, reject) => {
        var zcql = window.catalyst.ZCatalystQL;
		var zcqlPromise  = zcql.executeQuery(queryString+" LIMIT " + offset + ", " + increment);

        zcqlPromise.then(async (response) => {

            var responseFull = response.content;
            if(response.status !== 200)
            {
                return reject(response.content);
            }
            try
            {
                if(response.content.length !== 0)
                {
                    const nextResponses = await queryDB(queryString, offset=offset+increment);
        
                    responseFull = response.content.concat(nextResponses);
                }
            }

            
            catch(e)
            {
                console.log(e);
                return reject(e);
            }
            
            return resolve({
                "status": 200,
                "content": responseFull
            });
        })
        .catch((err) => {
            return reject(err);
        });
    });
}

export async function executeQuery(queryString) {
    return new Promise((resolve, reject) => {
        var zcql = window.catalyst.ZCatalystQL;
		var zcqlPromise  = zcql.executeQuery(queryString);
        zcqlPromise.then(async (response) => {

            var responseFull = response.content;
            if(response.status !== 200)
            {
                return reject(response.content);
            }
            
            return resolve(responseFull);
        })
        .catch((err) => {
            return reject(err);
        });
    });
}

export function getOthersDetails(queryString = QUERY_DB_2_DEFAULT)
{
	return new Promise((resolve, reject) => 
	{
		var zcql = window.catalyst.ZCatalystQL;
		var zcqlPromise  = zcql.executeQuery(queryString);

		zcqlPromise
				.then((response) => {

					if(response.status === 400)
					{
						reject("error");
					}
	
					var data = [];
			//console.log(response.content);
			for(const item of response.content)
			{
				const row = item.SupportMemberForm;
				
				const result = [
					[undefined, "", " "].includes(row.Email_ID) ? "no email" : row.Email_ID,
					[undefined, "", " "].includes(row.Name) ? "no name" : row.Name,
                    [undefined, "", " "].includes(row.Zuid) ? "no zuid" : row.Zuid
                ];
				//console.log(result);
				data.push(result);
			}
            //console.log(data);
			if(data.length === 0)
			{
				reject("none");
			}
			resolve(data);
		}).catch((error) => {
			reject(error);
		})
	});
}

export async function* lazyFetch(query) {
    var offset = 1;
    var increment = 100;
    var done = false;

    while(!done) {
        yield new Promise((resolve, reject) => {
            var zcql = window.catalyst.ZCatalystQL;
            var zcqlPromise  = zcql.executeQuery(query+" LIMIT " + offset + ", " + increment);
            const start = performance.now();
            zcqlPromise.then(async (response) => {
    
                const end = performance.now();
                // console.log(end-start);
                // console.log((end-start)/increment);
                if(response.status !== 200)
                {
                    return reject(response.content);
                }
                
                if(response.content.length === 0) {
                    done = true;
                    return resolve({
                        "content": undefined,
                        "isLast": true
                    });
                }
                
                return resolve({
                    "content": response.content,
                    "isLast": response.content.length < increment
                });
            })
            .catch((err) => {
                return reject(err);
            });
        });
        offset = offset+increment;
    }
}

export async function* lazyLoader(query, transformFunction) {
    var done = false;
    const lazyFetcher = lazyFetch(query);

    while (!done) {
        const data = await lazyFetcher.next();
        if (data.value.content === undefined) {
            done = true;
            yield {
                "content": undefined,
                "isLast": data.value.isLast
            };
        } else {
            var formattedData = transformFunction(data.value.content);
            yield {
                "content": formattedData,
                "isLast": data.value.isLast
            };
        }
    };
}