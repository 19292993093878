//ignorei18n_start
import React, { useEffect, useRef, useState } from "react";

import {motion} from "framer-motion";
 
import "../History.css";

export default function HistoryView({scrollAmount, setScrollAmount, data}) {

    const container = useRef();
    const [hovered, setHovered] = useState(false);

    useEffect(() => {
        if(!hovered) {
            const horizontal_shift = (container.current.scrollWidth-container.current.clientWidth) * (scrollAmount / 100);
            container.current.scrollLeft = horizontal_shift;
        }
    }, [scrollAmount]);

    function handleScroll() {
        const e = container.current;
        var scrollPercentage = 100 * e.scrollLeft / (e.scrollWidth-e.clientWidth);
        setScrollAmount(scrollPercentage);
    }
 
    return (
        <div className={"zvp_history_view"} onScroll={handleScroll} ref={container}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <div style={{ gridColumn: "auto / span 2" }}>
            </div>
            {
                data.milestones.map((d, i) => {
                    return <Card data={d} index={i} key={i}/>
                })
            }
            <div style={{ gridColumn: "auto / span 2" }}>
            </div>
        </div>
    );
}

function Card({data, index}) {
    return (
        <motion.div className="zvp_card_hero"
            initial={{ opacity: 0.6, rotateZ: index%2 ? -1 : 1, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: false, amount: 0.4 }}
            transition={{
                type: "spring",
                bounce: 0.4,
                damping: 10,
                duration: 1
            }}
        >
            <div 
                className="zvp_card_hero_title"
            >
                {data.server}
                {
                    data.subtitle &&
                        <div className="subtitle">
                            {
                                "~ " + data.subtitle + " ~"
                            }
                        </div>
                }
            </div>
            {
                data.core_functionality ?
                    <div 
                        className="zvp_card_hero_description"
                    >
                        {"\"" + data.core_functionality + "\""}
                    </div>
                    :
                    null
            }
            {
                data.clusters ?
                    <div 
                        className="zvp_card_hero_clusters"
                    >
                        {
                            data.clusters?.map((c, i) => {
                                return (
                                    <motion.div key={i+"-"+index}>
                                        {c && <span className="dot"></span>}
                                        {c}
                                    </motion.div>
                                );
                            })
                        }
                    </div>
                    :
                    null
            }
            {
                data.protocol ?
                    <div 
                        className="zvp_card_hero_protocols"
                    >
                        {
                            data.protocol?.map((c, i) => {
                                return (
                                    <motion.div key={i+"-"+index}>
                                        {c}
                                    </motion.div>
                                        
                                );
                            })
                        }
                    </div>
                    :
                    null
            }
            {
                data.notes ?
                    <div className="zvp_card_hero_notes">
                        {"{" + data.notes + "}"}
                    </div>
                    :
                    null
            }
        </motion.div>
    );
}
//ignorei18n_end