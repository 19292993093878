import React, { useState, useContext, useRef, useEffect } from 'react';
import { UserContext } from '../../../../contexts/UserContext';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

import {
  Box, 
  Button,
  ButtonGroup,
  Skeleton,
  Select,
  MenuItem,
  Grid,
  Collapse,
  TextField,
  Paper,
  Chip,
  Menu
} from "@mui/material";

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';

import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';

import { group_by_order, find_row_span } from '../../../../util/GroupingFunctions.js';
import { queryDB } from "../../../../util/QueryDB";
import { EmployeeCards } from '../../../../util/Loading.js';
import { GetNameAndEmpID, MultiLevelDropdown } from '../../../../util/Loading.js';
import { getFromTeamDetails } from '../../taskBreaker/TBDataFetcher.js';

import { getMentorWithMentees } from './Report5';
import { createHierarchy } from './Report5';
import { findMentees } from './Report5';
import { setDriDropDown } from './Report5';
import { ServerList } from './Report5';
import CombinedInfo from '../../../pageContent/CombinedMilestone/CombinedInfo'
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTheme } from '@mui/material';
import { report_5_external_component_get_query_1, report_5_external_component_get_query_2 } from '../../../../catalystDB/queryGenerator.js';

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	paddingTop: 0,
	paddingBottom: 0,
	color: theme.palette.text.primary,
	height: "calc((100vh - 150px))",
	display: "flex",
	// justifyContent: "center",
	alignItems: "center"
}));

export default function ExternalComponent({selectedOption,setSelectedOption}){
    const [searchText, setSearchText] = useState("");
    const { userDetails, teamDetails } = useContext(UserContext);
    const user_email = userDetails.current.auth.email_id;
	const user_role = teamDetails.current[user_email].Role;
	const userserver = teamDetails.current[user_email].Servers;
	const userserverArr = teamDetails.current[user_email].Servers.split(",");
    const [filterBy, setFilterBy] = useState(userserverArr);
    const [showRoadMap, setShowRoadMap] = useState(false);

    return(
        <>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                overflow: "hidden",
                gap: "5px"
            }} className="page-content-2">

                <Topbar selectedOption={selectedOption} setSelectedOption={setSelectedOption} searchText={searchText} setSearchText={setSearchText} filterBy={filterBy} setFilterBy={setFilterBy} showRoadMap={showRoadMap} setShowRoadMap={setShowRoadMap}/>

                {showRoadMap ? (
                    <RoadMapComponent
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        searchText={searchText}
                        setSearchText={setSearchText}
                    />
                ) : (
                    <MilestoneComponent
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        searchText={searchText}
                        setSearchText={setSearchText}
                        filterBy={filterBy}
                        setFilterBy={setFilterBy}
                    />
                )}
            
            </Box>
        </>
    )
}

function Topbar({ selectedOption, setSelectedOption, rows, searchText, setSearchText,filterBy,setFilterBy,showRoadMap,setShowRoadMap }) {
    const [open, setOpen] = useState(false);
    const [filterAnchor, setFilterAnchor] = useState(null);
    const [driTree, setDriTree] = useState([]);
    const [driList, setDriList] = useState([]);
    const [driAnchor, setDriAnchor] = useState(null);
    const { userDetails, teamDetails } = useContext(UserContext);

    useEffect(() => {
		async function run() {
            const user_email = userDetails.current.auth.email_id;

            try {
                await setDriDropDown(setDriList, setDriTree, userDetails, teamDetails);
            }
            catch (error) {
            }
		}
		run();
	}, []);

    function handleSearchTextChange(event) {
        setSearchText(event.target.value);
    }

    function handleSearchButtonClick() {
        setSearchText("");
        setOpen(!open);
    }

    function handleFilterButtonClick(event) {
		setFilterAnchor(event.currentTarget);
	}

	function handleFilterButtonClose() {
		setFilterAnchor(null);
	}

    function handleDriButtonClose() {
		setDriAnchor(null);
		setFilterAnchor(null);
	}

    async function handleFilterItemClick(event, item) {
		if (item === "Dri") {
			setDriAnchor(event.currentTarget);
		}
		else if (item === "Default") {
			setFilterBy(["Default"]);
		}
	}

    const handleDriTreeItemClick = async (item) => {
		var tempFilterBy = [...filterBy];
		var index = tempFilterBy.indexOf("Default");
		if (index > -1) {
			tempFilterBy.splice(index, 1);
		}
		index = tempFilterBy.indexOf(item.Email);
		if (index > -1) {
			tempFilterBy.splice(index, 1);
		}
		setFilterBy([item.Email, ...tempFilterBy]);
	};


    const handleButtonClick = () => {
        setShowRoadMap(!showRoadMap);
      };

	function handleChipClick(event, item) {
		if(event.detail === 2) {
			setFilterBy([item]);
		}
	}
    
    return (
        <>
            <div className="external-top-bar">
                <div className="external-top-bar-left" style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "20px",
                }}>
                    <Box style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                    width: "calc(50vw)",
                    overflowX: "scroll"
                }}
                    className="filter-sort-overflow-container"
                >
                     <ServerList
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        height={"50px"}
                        required={true}
                    />
                    {
                        searchText !== "" ?
                            <Chip
                                key={"search-chip"}
                                label={
                                    <Box style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <Box style={{

                                            color: "#0c2c70",
                                            borderRight: "1px solid #103fa3",
                                            paddingRight: "6px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <SearchIcon />
                                        </Box>
                                        <Box style={{
                                            paddingLeft: "6px",
                                            color: "ghostwhite"
                                            // width: "60px",
                                            // overflow: "hidden",
                                            // textOverflow: "ellipsis",
                                            // textAlign: "center"
                                        }}>
                                            {/* <Tooltip title={searchText} > */}
                                            {searchText}
                                            {/* </Tooltip> */}
                                        </Box>
                                    </Box>
                                }
                                sx={{
                                    bgcolor: "#226DB4 !important",
                                    border: "1px solid #0c2c70"
                                }}
                                onDelete={() => {
                                    setSearchText("");
                                }}
                                deleteIcon={
                                    <CancelIcon
                                        onMouseDown={(event) => event.stopPropagation()}
                                        sx={{
                                            color: "#0c2c70 !important"
                                        }}
                                    />
                                }
                            />
                            :
                            <></>
                    }
                    <div style={{
					}} className="unselectable">
						&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
					</div>
                    </Box>
                </div>
               
                <div className="milestone-top-bar-right-group">
                    <ButtonGroup variant="contained">
                        <Collapse in={open} timeout="auto" orientation="horizontal" unmountOnExit>
                            <TextField variant="outlined" placeholder="search" onChange={handleSearchTextChange} value={searchText} autoFocus />
                        </Collapse>
                        <Tooltip title="search"><Button variant="contained" size="small" onClick={handleSearchButtonClick}><SearchIcon /></Button></Tooltip>
                        <Tooltip title="filter by"><Button variant="contained" size="small" onClick={handleFilterButtonClick}><FilterAltOutlinedIcon /><ArrowDropDownIcon /></Button></Tooltip>
                        <Button variant="contained" onClick={handleButtonClick}>
                            {showRoadMap ? 'Show Milestones' : 'Show RoadMaps'}
                        </Button>
                    </ButtonGroup>
                    
                    <Menu
					anchorEl={filterAnchor}
					open={filterAnchor !== null}
					onClose={handleFilterButtonClose}
				>
					<MenuItem onClick={(event) => handleFilterItemClick(event, "Default")} style={{ justifyContent: "left" }}>Default</MenuItem>
					<MenuItem onClick={(event) => handleFilterItemClick(event, "Dri")}><ArrowLeftIcon />DRI</MenuItem>
                    </Menu>
                    {driTree && driTree.length > 0 && (
                        <MultiLevelDropdown
                            dritree={driTree}
                            driAnchor={driAnchor}
                            handleDriTreeButtonClose={handleDriButtonClose}
                            handleDriTreeItemClick={handleDriTreeItemClick}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

// function ServerList({ selectedOption, setSelectedOption, height, required = false }) {
//     const [serversList, setServersList] = useState([]);
//     const { userDetails, teamDetails } = useContext(UserContext);
//     const user_email = userDetails.current.auth.email_id;
//     const user_servers = teamDetails.current[user_email].Servers;
//     const [clearButton, setClearButton] = useState(false);

//     const sortedServersList = [...serversList].sort((a, b) => a.localeCompare(b));

//     function handleSelect(event) {
//         setSelectedOption(event.target.value);
//         setClearButton(true);
//     }

//     function handleMouseEnter() {
//         if (selectedOption === "") {
//             return;
//         }
//         setClearButton(true);
//     }

//     function handleMouseLeave() {
//         if (selectedOption === "") {
//             return;
//         }
//         setClearButton(false);
//     }

//     function handleClearClick() {
//         setSelectedOption("");
//         setClearButton(false);
//     }

//     function handleUnfocus(event) {
//         setTimeout(() => setClearButton(false), 200);
//     }


//     useEffect(() => {
//         async function fetchData() {
//             try {
//                 await setServerDropDown(setServersList, userDetails, teamDetails);
//             }
//             catch (error) {
//             }
//         }
//         fetchData();
//     }, []);

//     async function setServerDropDown(setServersList, userDetails, teamDetails) {
//         return new Promise((resolve, reject) => {
//             const userEmail = userDetails.current.auth.email_id;
//             const userRole = teamDetails.current[userEmail].Role;

//             if (userRole === "CEO") {
//                 setServersList(teamDetails.current["mariaraj@zohocorp.com"].Servers.split(","));
//             }
//             else {
//                 setServersList(teamDetails.current[userEmail].Servers.split(","));
//             }
//             return resolve();
//         });
//     }

//     return (
//         <>
//             <Chip
//                 label={
//                     <Box style={{
//                         display: "flex",
//                         flexDirection: "row",
//                         justifyContent: "center",
//                         alignItems: "center"
//                     }}
//                         onMouseEnter={handleMouseEnter}
//                         onMouseLeave={handleMouseLeave}
//                     >
//                         <Box style={{
//                             //paddingLeft: "6px",
//                             color: "ghostwhite"
//                         }}>
//                             {selectedOption}
//                             <Select
//                                 required={required}
//                                 //labelId="role-user-email"
//                                 value={""}
//                                 style={{
//                                     minHeight: height,
//                                     height: "auto",
//                                     minWidth: "30px",
//                                     maxWidth: "220px"
//                                 }}
//                                 MenuProps={{
//                                     style: {
//                                         maxHeight: 600
//                                     }
//                                 }}
//                                 onChange={handleSelect}
//                                 onBlur={handleUnfocus}
//                                 sx={{
//                                     boxShadow: "none",
//                                     ".MuiOutlinedInput-notchedOutline": { border: 0 },
//                                     "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
//                                     {
//                                         border: 0,
//                                     },
//                                     "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
//                                     {
//                                         border: 0,
//                                     },
//                                     padding: 0
//                                 }}
//                                 disableUnderline={true}
//                             >
//                                 {sortedServersList.map((value, index) => (
//                                     <MenuItem key={index} value={value}>{value}</MenuItem>
//                                 ))};
//                             </Select>
//                             {/* <Button style={{ visibility: clearButton ? "visible" : "hidden" }} onClick={handleClearClick}>
//                                 <CancelIcon fontSize="small" color="error" />
//                             </Button> */}
//                         </Box>
//                     </Box>
//                 }
//                 sx={{
//                     bgcolor: "#226DB4 !important",
//                     border: "1px solid #0c2c70"
//                 }}
//             />
//         </>
//     );
// }

function MilestoneComponent({ selectedOption, setSelectedOption , searchText , setSearchText, filterBy , setFilterBy}) {
    const [loaded, setLoaded] = useState(undefined);
    const [rows,setRows] = useState([]);
    const {loginUserDept} = useContext(UserContext);
    // const [searchText, setSearchText] = useState("");

    var rrows = [];

    async function QueryData() {
        try {
            const result = await queryDB(report_5_external_component_get_query_1(selectedOption, loginUserDept));
            // console.log(`SELECT MilestoneName,TasklistName,TasklistStartDate,TasklistDueDate,MilestoneStartDate,MilestoneDueDate,MilestoneStatus,MilestoneId,DRI,Component,Tasklistid,TasklistPriority,MilestoneDescription,TasklistStatus,TasklistUniqueID,TasklistDescription FROM TaskDetails where Component = '${selectedOption}' and TasklistStatus not in ('Completed','TasklistCompleted','Updated To Idc')`);
            return result; 
        } catch (error) {
            throw error;
        }
    }

    useEffect(() => {
        QueryData()
            .then((queryResult) => {
                if (queryResult.length > 0) {
                    for (var item of queryResult) {
                        var rrow = [];
                        rrow.push(item.TaskDetails.TasklistName);
                        rrow.push([undefined, "", " ", null].includes(item.TaskDetails.TasklistUniqueID) ? "-" : item.TaskDetails.TasklistUniqueID);
                        rrow.push([undefined, "", " ", null].includes(item.TaskDetails.TasklistPriority) ? "-" : item.TaskDetails.TasklistPriority);
                        rrow.push([undefined, "", " ", null].includes(item.TaskDetails.TasklistDueDate) ? "-" : item.TaskDetails.TasklistDueDate);
                        rrow.push(item.TaskDetails.DRI);
                        rrow.push([undefined, "", " ", null].includes(item.TaskDetails.TasklistStartDate) ? "-" : item.TaskDetails.TasklistStartDate);
                        rrow.push(item.TaskDetails.MilestoneName);
                        rrow.push([undefined, "", " ", null].includes(item.TaskDetails.MilestoneStartDate) ? "-" : item.TaskDetails.MilestoneStartDate);
                        rrow.push([undefined, "", " ", null].includes(item.TaskDetails.MilestoneDueDate) ? "-" : item.TaskDetails.MilestoneDueDate);
                        rrow.push(item.TaskDetails.MilestoneStatus);
                        rrow.push(item.TaskDetails.MilestoneId);
                        rrow.push(item.TaskDetails.Component);
                        rrow.push(item.TaskDetails.Tasklistid);
                        rrow.push([undefined, "", " ", null].includes(item.TaskDetails.MilestoneDescription) ? "-" : item.TaskDetails.MilestoneDescription);
                        rrow.push([undefined, "", " ", null].includes(item.TaskDetails.TasklistStatus) ? "-" : item.TaskDetails.TasklistStatus);
                        rrow.push([undefined, "", " ", null].includes(item.TaskDetails.TasklistDescription) ? "-" : item.TaskDetails.TasklistDescription);
                        rrows.push(rrow);
                    }
                    setRows(rrows);
                    setLoaded(true);
                } else {
                    setLoaded(false);

                }
            })
            .catch((error) => {
                console.error('Error querying data:', error);
                setLoaded(null);

            });
    }, [selectedOption]);

    if (loaded === undefined) {
        return (
            <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
        );
    } else if (loaded === null) {
        return (
            "something went wrong..."
        );
    } else if (loaded === false) {
        return (
            <h2 style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                No Data Found :(
              </h2>
        );
    } else {
    return(
        <Box sx={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
            //alignItems: "flex-end",
            // justifyContent: "flex-end"
        }}>
            {/* <TasklistTopbar selectedOption={selectedOption} setSelectedOption={setSelectedOption} rows={rows} searchText={searchText} setSearchText={setSearchText} /> */}
            <MilestoneTable selectedOption={selectedOption} setSelectedOption={setSelectedOption} rows={rows} searchText={searchText} setSearchText={setSearchText} />
        </Box>
    )
    }
}

function MilestoneTable({ selectedOption, setSelectedOption,rows ,searchText,setSearchText}) {

    rows = rows.filter(row_ => {
		for (const i in row_) {
            const item = row_[i];
			if (item === null || item === undefined) {
				continue;
			}
			const aString = item.toString().toLowerCase().replaceAll(" ", "");
			const bString = searchText.toLowerCase().replaceAll(" ", "");
			if (aString.indexOf(bString) !== -1) {
				return true;
			}
		}
		return false;
	});

    function CompareFunction(a, b) {

        const priorityMap = {
            high: 1,
            medium: 2,
            low: 3,
            none: 4
        };

        const priorityA = a[2] ? a[2].toLowerCase() : ''; // Ensure it's a valid string or empty string
        const priorityB = b[2] ? b[2].toLowerCase() : '';

        const priorityValueA = priorityMap[priorityA] || Number.MAX_VALUE;
        const priorityValueB = priorityMap[priorityB] || Number.MAX_VALUE;


        return priorityValueA - priorityValueB;
    }

    rows = rows.sort(CompareFunction);
    
    var filteredData = rows;
    let rowSpanInfo = [];
    var dataValue = [];

    if (rows.length > 0) {
        filteredData = group_by_order(rows, [2], 0);

        rowSpanInfo = find_row_span(filteredData, [2]);
    }

    
        return (
            <>
                <div className="external-table">
                    <TableContainer component={Paper} className="external-table-container">
                        <Table stickyHeader >
                            <TableHead>
                                <TableRow className="external-table-head">
                                    <TableCell align="left" className="external-table-grouper-header" />
                                    <TableCell align="left" className="external-table-grouper-header" />
                                    <TableCell align="center" style={{ width: '5vw' }}>S.No.</TableCell>
                                    <TableCell align="center" style={{ width: '25vw' }}>Tasklist</TableCell>
                                    <TableCell align="center" style={{ width: '10vw' }}>Due Date</TableCell>
                                    <TableCell align="center" style={{ width: '10vw' }}>Status</TableCell>
                                    <TableCell align="right" className="external-table-grouper-header" />

                                </TableRow>
                            </TableHead>
                            <TableBody id="list-view">
                                {
                                    Array.isArray(filteredData) ?
                                    filteredData.map((row, index) => {
                                            return <MilestoneRow key={index} row={row} index={index} rowSpanInfo={rowSpanInfo}/>;
                                        })
                                        :
                                        null
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </>
        );
    
}

function MilestoneRow({row, index , rowSpanInfo}){
    //console.log(row);
    var dataArray = []; 
    dataArray.push(row[6]);
    var dataArray1 = []; 
    dataArray1.push(row);
    // console.log(dataArray1.map((item) => item[5]))
    const [open, setOpen] = React.useState(null);
    const theme = useTheme();

    const handleClick = () => {
        if (open === index) {
                setOpen(null);
            }
      
            else {
                setOpen(index);
            }
      };

    return (
        <>
            <TableRow className="external-table-head">
                {
                    rowSpanInfo[index][2] !== 0 ?
                        <TableCell
                            className={`milestone-table-grouper-component milestone-single-row rotated-text ${row[2]}`}
                            align="center"
                            width={"6px"}
                            rowSpan={2 *rowSpanInfo[index][2]}
                            sx={{
                                fontSize: "0.8rem !important",
                                backgroundColor: row[2] === 'High' ? 'red !important' : row[2] === 'Medium' ? 'Orange !important' : row[2] === 'Low' ? 'lightgreen !important' : 'transparent'
                            }} >
                            {row[2]}
                        </TableCell>
                        :
                        null

                }
                 <TableCell align="left" className='slide-animation' style={{ width: "30px"  }}>
                    <IconButton
						size="small"
						onClick={handleClick}
					>
						{open === null ? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon />}
					</IconButton>
             </TableCell>
                <TableCell onClick={handleClick} align="center">{index+1}</TableCell>
                <TableCell onClick={handleClick} align="center" >
                    <Tooltip title={row[0]}>
                        {row[0]}
                    </Tooltip>
                </TableCell>
                <TableCell onClick={handleClick} align="center" >{row[3]}</TableCell>
                <TableCell onClick={handleClick} align="center" style={{ color: 'Orange' }}>{row[5]}</TableCell>
                <TableCell align="center"></TableCell>
            </TableRow>

            <TableRow className="info-div-row"	 sx={{backgroundColor: theme.palette.background.infoDivOuter + " !important"}}>
                     <TableCell colSpan={8} style={{ padding:0 }}>
                    <Collapse in={open !== null} timeout="auto" unmountOnExit>
                    <CombinedInfo   index={index}  MilestoneName={dataArray} MilestoneStartDate={dataArray1.map((item) => item[7])} MilestoneDueDate={dataArray1.map((item) => item[8])} Status={dataArray1.map((item) => item[9])} MilestoneId={dataArray1.map((item) => item[10])} DRI={dataArray1.map((item) => item[4])} Server={dataArray1.map((item) => item[11])} Tasklistid={dataArray1.map((item) => item[12])} TasklistPriority={dataArray1.map((item) => item[2])} MilestoneDescription={dataArray1.map((item) => item[13])} MilestoneStatus={dataArray1.map((item) => item[9])} TasklistStatus={dataArray1.map((item) => item[14])}/>
                    {/* <CombinedInfo></CombinedInfo> */}
                    </Collapse>
                    </TableCell>
            </TableRow>
        </>
    )
}

function RoadMapComponent({ selectedOption, setSelectedOption,searchText,setSearchText }) {
    const [loaded, setLoaded] = useState(undefined);
    const [rows, setRows] = useState([]);
    const {loginUserDept} = useContext(UserContext);
    //const [searchText, setSearchText] = useState("");

    var rrows = [];

    async function QueryData() {
        try {
            const result = await queryDB(report_5_external_component_get_query_2(selectedOption, loginUserDept));

            
            return result;
        } catch (error) {
            throw error;
        }
    }

    useEffect(() => {
        QueryData()
            .then((queryResult) => {
                if (queryResult.length > 0) {
                    for (var item of queryResult) {
                        var rrow = [];
                        rrow.push(item.ExternalFeatureReport.Feature);
                        rrow.push(item.ExternalFeatureReport.RequestedBy);
                        rrow.push(item.ExternalFeatureReport.Module);
                        rrows.push(rrow);
                    }
                    setRows(rrows);
                    setLoaded(true);
                } else {
                    setLoaded(false);

                }
            })
            .catch((error) => {
                console.error('Error querying Roadmap data:', error);
                setLoaded(null);

            });
    }, [selectedOption]);

    if (loaded === undefined) {
        return (
            <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
        );
    } else if (loaded === null) {
        return (
            "something went wrong..."
        );
    } else if (loaded === false) {
        return (
            <h2 style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                No RoadMap Found :(
              </h2>
        );
    } else {

        return (
            <Box sx={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                //alignItems: "flex-end",
                //justifyContent: "flex-start"
            }}>
                {/* <RoadMapTopbar selectedOption={selectedOption} setSelectedOption={setSelectedOption} rows={rows} searchText={searchText} setSearchText={setSearchText} /> */}
                <RoadMapTable selectedOption={selectedOption} setSelectedOption={setSelectedOption} rows={rows} searchText={searchText} setSearchText={setSearchText} />
            </Box>
        );
    }
}

function RoadMapTable({ selectedOption, setSelectedOption,rows ,searchText,setSearchText}){

    rows = rows.filter(row_ => {
		for (const i in row_) {
            const item = row_[i];
			if (item === null || item === undefined) {
				continue;
			}
			const aString = item.toString().toLowerCase().replaceAll(" ", "");
			const bString = searchText.toLowerCase().replaceAll(" ", "");
			if (aString.indexOf(bString) !== -1) {
				return true;
			}
		}
		return false;
	});

    function CompareFunction(a, b) {

        const priorityMap = {
            high: 1,
            medium: 2,
            low: 3,
            none: 4
        };

        const priorityA = a[2] ? a[2].toLowerCase() : ''; // Ensure it's a valid string or empty string
        const priorityB = b[2] ? b[2].toLowerCase() : '';

        const priorityValueA = priorityMap[priorityA] || Number.MAX_VALUE;
        const priorityValueB = priorityMap[priorityB] || Number.MAX_VALUE;


        return priorityValueA - priorityValueB;
    }

    rows = rows.sort(CompareFunction);
    
    var filteredData = rows;
    let rowSpanInfo = [];
    var dataValue = [];

    if (rows.length > 0) {
        filteredData = group_by_order(rows, [2], 0);

        rowSpanInfo = find_row_span(filteredData, [2]);
    }
    
    return (
        <>
            <div className="external-table">
                <TableContainer component={Paper} className="external-table-container">
                    <Table stickyHeader >
                        <TableHead>
                            <TableRow className="external-table-head">
                                <TableCell align="left" className="external-table-grouper-header" />
                                <TableCell align="left" style={{ width: '1vw' }}>S.No.</TableCell>
                                <TableCell align="center" style={{ width: '20vw' }}>RoadMap</TableCell>
                                <TableCell align="center" style={{ width: '10vw' }}>RequestedBy</TableCell>                        
                                <TableCell align="right" className="external-table-grouper-header" />
                            </TableRow>
                        </TableHead>
                        <TableBody id="list-view">
                            {
                                Array.isArray(filteredData) ?
                                filteredData.map((row, index) => {
                                        return <RoadmapRow key={index} row={row} index={index} rowSpanInfo={rowSpanInfo}/>;
                                    })
                                    :
                                    null
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}

function RoadmapRow({row, index , rowSpanInfo}){
    return (
        <>
            <TableRow className="external-table-head" >
                {
                    rowSpanInfo[index][2] !== 0 ?
                        <TableCell className={`milestone-table-grouper-component milestone-single-row rotated-text ${row[2]}`} align="center" width={"6px"} rowSpan={rowSpanInfo[index][2]} sx={{
                            fontSize: "0.8rem !important",
                            backgroundColor: row[2] === 'High' ? 'red !important' : row[2] === 'Medium' ? 'Orange !important' : row[2] === 'Low' ? 'lightgreen !important' : 'transparent'
                        }} >
                            {row[2]}
                        </TableCell>
                        :
                        null

                }
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center" >{row[0]}</TableCell>
                <TableCell align="center" ><EmployeeCards emails={row[1]} /></TableCell>
                <TableCell align="center"></TableCell>
            </TableRow>
        </>
    )
}

