import React, {useState, useEffect} from "react";
import Box from '@mui/material/Box';
import { NestedTable } from "./NestedTable";
import { queryDB } from "../../QueryDB";
import InfoDiv from "../../../pageBody/pageContent/milestone/InfoDiv";
import { CircularProgress } from "@mui/material";
import { ReportSection6 } from "../../../pageBody/pageContent/report/Report1/ReportSection6";
import { milestone_component_get_query_2 } from "../../../catalystDB/queryGenerator";

const cache = {};
const milestone_name_cache = {};

export function MilestoneComponent({ milestone_id }) {

    const query = milestone_component_get_query_2(milestone_id);
    const [loaded, setLoaded] = useState(undefined);
    const [data, setData] = useState(undefined);
    const [milestone_name, set_milestone_name] = useState(undefined);
    const [milestone_server, set_milestone_server] = useState(undefined);
    const [isMilestoneDeleted, setIsMilestoneDeleted] = useState(false);

    useEffect(() => {
        setLoaded(undefined);
        setData(undefined);
        if (query in cache) {
            const fetchedData = cache[query];
            setData(fetchedData);
            setLoaded(true);
            if (milestone_id in milestone_name_cache) {
                //console.log(milestone_name_cache);
                set_milestone_name(milestone_name_cache[milestone_id].name);
                set_milestone_server(milestone_name_cache[milestone_id].server);
            }
        } else {
            queryDB(query).then(response => {
                setIsMilestoneDeleted(response.map(tasklist => tasklist.TaskDetails.IsTasklistDeleted).reduce((accumulator, curr) => {
                    console.log(curr);
                    return accumulator && curr === "Yes"
                }, true));

                if(response.length > 1) {
                    response = [response[0]];
                }
                if (response.length > 0) {
                    const fetchedData = {
                        columns: ["Milestone", "Server", "DRI", "Start Date", "Due Date"],
                        rows: [
                            ...response.map((resp, index) => {
                                const d = resp.TaskDetails;
                                if (index === 0) {
                                    milestone_name_cache[milestone_id] = {
                                        "name": d.MilestoneName,
                                        "server": d.Component
                                    }
                                    set_milestone_name(d.MilestoneName);
                                    set_milestone_server(d.Component);
                                }
                                return {
                                    visible: [
                                        d.MilestoneName,
                                        d.Component,
                                        d.DRI,
                                        d.MilestoneStartDate_,
                                        d.MilestoneCompletionDate
                                    ],
                                    hidden: undefined,
                                    content: (
                                        <Box sx={{
                                            paddingLeft: "10px",
                                            paddingRight: "10px"
                                        }}>
                                            <InfoDiv milestoneName={d.MilestoneName} milestoneId={d.MilestoneId} milestoneStatus={d.MilestoneStatus} milestoneStartDate={d.MilestoneStartDate} toggle={{ current: false }} />
                                        </Box>
                                    )
                                };
                            })
                        ]
                    };
                    setData(fetchedData);
                    setLoaded(true);
                    cache[query] = fetchedData;
                } else {
                    setLoaded(false);
                }
            }).catch(error => {
                console.log(error);
                setLoaded(false);
            });
        }
    }, [milestone_id]);

    if (loaded === undefined) {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%"
            }}>
                <CircularProgress />
            </Box>
        );
    } else if (loaded === false) {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%"
            }}>
                No data
            </Box>
        );
    } else {
        return (
            <Box sx={{
                padding: "25px"
            }}>
                <NestedTable data={data} isDeleted={isMilestoneDeleted}/>
                <Box sx={{
                    padding: "20px",
                    height: "600px",
                    boxSizing: "border-box",
                }}>
                    <ReportSection6 currentSelect={milestone_server} milestone_name={milestone_name} />
                </Box>
            </Box>
        );
    }
}