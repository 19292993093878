import { isDateInRange, isDateString } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    const chartData = [];
    const dataMap = {
        "Tasklists Completed": []
    };
    
    var tasklistCount = 0;
    var tasklistCompletedCount = 0;

    catalystDB.TaskDetails.map(item => {

        const dateString = item.CREATEDTIME;

        if(isDateString(dateString) && isDateInRange(dateString, startTime)) {
            const completionDateString = item.TasklistCompletionDate;

            tasklistCount++;

            if([undefined, null, "", " "].includes(completionDateString) && !isDateString(completionDateString)) {
                return;
            }

            dataMap["Tasklists Completed"].push(item);

            tasklistCompletedCount++;
           
        }
    }); 

    
    chartData.push({
        "type": "bullet",
        "seriesname": "Tasklists Completed",
        "data": [
            [
                [
                    "",
                    tasklistCompletedCount,
                    [
                        tasklistCount
                    ]
                ]
            ]
        ]
    });

    return [chartData, dataMap];
}