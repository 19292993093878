import { roadmap_data_updater_get_query_1, roadmap_data_updater_get_query_2 } from "../../../catalystDB/queryGenerator";
import { queryDB } from "../../../util/QueryDB";

export async function updateRolesInCreator(creatorRowID)
{
    return new Promise((resolve, reject) => {
        fetch('/server/sample?table=RecordDelete&CreatorRowid=' +creatorRowID,   {
            method: 'POST'

        })
        .then((response) => response.json())
        .then((response) => {
        //console.log(response);
            if(response !== 3000)
            {
                reject("server error");
            }
            else
            {
            resolve("done");
            }
        })
        .catch((err) => {
         // console.log(err.message);
           reject(err);
        });
    });
}

export async function deleteMultipleRows(creatorRowIDs)
{

    return new Promise((resolve, reject) => {
        fetch('/server/sample?table=RecordDeleteMultiple&CreatorRowids=' +creatorRowIDs.join(","),   {
            method: 'POST'

        })
        .then((response) => response.json())
        .then(async (response) => {
            var removeFromCatalyst = [];
            // console.log(response);
            var finalArr = [];
            response.forEach(obj => {
                if(obj.code === 3000) {
                    finalArr.push(obj.ID.toString());
                } else {
                    removeFromCatalyst.push(obj.ID.toString());
                }
            });
            creatorRowIDs.forEach(item => {
                if(!finalArr.includes(item) && !removeFromCatalyst.includes(item)) {
                    removeFromCatalyst.push(item);
                }
            });
            // console.log(removeFromCatalyst);
            var zcql = window.catalyst.ZCatalystQL;
            var zcqlPromise  = zcql.executeQuery(roadmap_data_updater_get_query_1(removeFromCatalyst));
            zcqlPromise.then((result)=> {
            }).catch(e => {
                reject(e);
            })
            
            resolve(finalArr);
        })
        .catch((err) => {
            // console.log(err.message);
            var zcql = window.catalyst.ZCatalystQL;
            var zcqlPromise  = zcql.executeQuery(roadmap_data_updater_get_query_2(creatorRowIDs));
            zcqlPromise.then((result)=> {
                resolve(creatorRowIDs);
            }).catch(e => {
                reject(e);
            })
        });
    });
}