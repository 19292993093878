import React, { useState, useRef, useEffect, useContext } from 'react';
import { Button, ButtonGroup } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import AddIcon from '@mui/icons-material/Add';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import DateRangeIcon from '@mui/icons-material/DateRange';
import IconButton from '@mui/material/IconButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded';
import { keyframes } from '@mui/system';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import download_icon from '@mui/icons-material/Downloading';
import styles from '../../tickets/Tickets.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { MoreViewIncident } from '../../tickets/newTicketsTabCore/table/extra_components/MoreViewIncident.js'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LinkIcon from '@mui/icons-material/Link';


import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getFromTeamDetails } from '../../taskBreaker/TBDataFetcher.js';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

import RemoveIcon from '@mui/icons-material/Remove';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { group_by_order, find_row_span } from './../../../../util/GroupingFunctions.js';
import { GetNameAndEmpID, MultiLevelDropdown } from '../../../../util/Loading.js';
import { Skeleton } from '@mui/material';

import { useTheme } from '@mui/material';

import '../liveIncidents/Incident.css';
import { getFromTaskBreaker1 } from '../liveIncidents/IncidentManagementDataFetcher.js';
import { Fade } from '@mui/material';

import { UserContext } from '../../../../contexts/UserContext.js';


import { issue_incidents_get_query_1, issue_incidents_get_query_2, issue_incidents_get_query_3, issue_incidents_get_query_4 } from '../../../../catalystDB/queryGenerator.js';

function Incident() {
    const [searchText, setSearchText] = useState("");

    const [initialRows, setInitialRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [hasData, setHasData] = useState(null);

    const [driList, setDriList] = useState([]);
    const [driTree, setDriTree] = useState([]);
    const [serversList, setServersList] = useState([]);
    const { userDetails, teamDetails, loginUserDept, allServers, serverConfiguration, GlobalServer } = useContext(UserContext);
    const user_email = userDetails.current.auth.email_id;
    const user_role = teamDetails.current[user_email].Role;
    const user_servers = teamDetails.current[user_email].Servers;
    const queryStringSuffix = "('" + user_servers.replaceAll(",", "','") + "')";
    const toggle = useRef(false);


    const [sortBy, setSortBy] = useState("Default");
    const [filterBy, setFilterBy] = useState(["Default"]);
    var SelectedGlobalServer = (GlobalServer === "unknown" || GlobalServer === undefined) ? "No Server" : GlobalServer;

    const compareFunctions = {

        "Status": statusCompareFunction,
        "Date": dateCompareFunction

    }
    useEffect(() => {
        async function fetchData() {
            try {
                await setDriDropDown(setDriList, setDriTree, userDetails, teamDetails, loginUserDept.current);
                await setServerDropDown(setServersList, userDetails, teamDetails, allServers);

            }
            catch (error) {

            }

            if (SelectedGlobalServer === "No Server") {
                try {

                    const result = await getFromTaskBreaker1(issue_incidents_get_query_1(loginUserDept));
                    setInitialRows(result);
                    setHasData(true);
                }
                catch (error) {
                    setHasData(false);
                }
            }
        }
        fetchData();
    }, []);
    const hookFlag2 = useRef(true);

    useEffect(() => {
        async function run() {
            if (hookFlag2.current) {
                hookFlag2.current = false;
                return;
            }
            const toggleClosedFilter = toggle.current ? " (IncidentStatus in ('Approved and posted')) " : " (IncidentStatus in ('Approved and posted')) ";
            // setSearchText("");
            if (filterBy.includes("Default")) {
                try {
                    const result = await getFromTaskBreaker1(issue_incidents_get_query_2(toggleClosedFilter, loginUserDept));
                    // console.log(result)
                    setInitialRows(result);
                    setHasData(true);
                }
                catch (error) {
                    setHasData(false);
                }
            } else {
                setHasData(null)
                var driFilter = [];
                var componentFilter = [];
                for (var item of filterBy) {
                    if (item.includes("@zohocorp.com")) {
                        driFilter.push(item);
                    } else {
                        if (SelectedGlobalServer === "No Server") {
                            componentFilter.push(SelectedGlobalServer)
                        }
                        else {
                            componentFilter.push(item);
                        }
                    }
                }


                var server_products = [];
                try {

                    componentFilter.map(serv => {
                        if (serv in serverConfiguration.current) {

                            const products = JSON.parse(serverConfiguration.current[serv].IssueProductType);

                            products.forEach(prod => {
                                if (!server_products.includes(prod)) {
                                    server_products.push(prod);
                                }
                            });
                        }
                    });
                } catch (e) { }


                componentFilter = [...server_products];




                var queryFilter = "";
                if (componentFilter.length === 0 && driFilter.length !== 0) {
                    queryFilter = ` ( IncientCreatedBy in ('${driFilter.join("','")}') ) `;
                } else if (driFilter.length === 0 && componentFilter.length !== 0) {
                    queryFilter = ` ( ComponentName	 in ('${componentFilter.join("','")}') ) `;
                } else if (componentFilter.length !== 0 && driFilter.length !== 0) {
                    queryFilter = ` ( IncientCreatedBy in ('${driFilter.join("','")}') AND  ComponentName in ('${componentFilter.join("','")}') ) `;
                }

                var qString = issue_incidents_get_query_3(queryFilter, toggleClosedFilter, loginUserDept);


                try {
                    const result = await getFromTaskBreaker1(qString);

                    setInitialRows(result);
                    setHasData(true);
                } catch (e) {
                    setHasData(false);
                }
            }
        }
        run();
    }, [filterBy, toggle.current]);

    var rowsNew = group_by_order(initialRows, [2, 1], 0);

    useEffect(() => {
        if (sortBy !== "Default") {
            rowsNew = rowsNew.sort(compareFunctions[sortBy]);
        }
        setRows(rowsNew);

    }, [initialRows, sortBy]);

    useEffect(() => {
        if (SelectedGlobalServer != "No Server") {
            setFilterBy([SelectedGlobalServer]);
        }
        else {
            setFilterBy(["Default"])
        }
    }, [GlobalServer]);

    //var content = <div style={{textAlign: "center", marginTop: "35vh"}}><LoadingComponent /></div>;
    var content = (
        <>
            <IncidentTopBar rowsCurrent={rowsNew} setRows={(r) => { setRows(r) }} setSearchText={setSearchText} drisList={driList} drisTree={driTree} serversList={serversList.sort()}
                toggle={toggle} setHasData={setHasData} setInitialRows={setInitialRows} userEmail={user_email} userRole={user_role} sortBy={sortBy} setSortBy={setSortBy}
                filterBy={filterBy} setFilterBy={setFilterBy} searchText={""} SelectedGlobalServer={SelectedGlobalServer} />
            {/* <Skeleton variant="rectangular" width={1600} height={60} />; */}
            <Skeleton variant="rectangular" width={"100%"} sx={{ flex: 1 }} />
        </>
    );

    const theme = useTheme();

    if (initialRows.length !== 0 && hasData) {
        content = (
            <Fade in={true}>
                <div>
                    <IncidentTopBar rowsCurrent={rowsNew} setRows={(r) => { setRows(r) }} setSearchText={setSearchText} drisList={driList} drisTree={driTree} serversList={serversList.sort()}
                        toggle={toggle} setHasData={setHasData} setInitialRows={setInitialRows} userEmail={user_email} userRole={user_role} sortBy={sortBy} setSortBy={setSortBy}
                        filterBy={filterBy} setFilterBy={setFilterBy} searchText={searchText} SelectedGlobalServer={SelectedGlobalServer} />
                    <IncidentTable toggle={toggle} rows={rows} searchText={searchText} allRows={initialRows} setRows={(r) => { setRows(r) }} sortBy={sortBy} setSortBy={setSortBy}
                        filterBy={filterBy} setFilterBy={setFilterBy} />
                </div>
            </Fade>
        );
    }
    else if (hasData === false) {
        content = (
            <Fade in={true}>
                <div style={{ color: theme.palette.background.text }}>
                    <IncidentTopBar rowsCurrent={rowsNew} setRows={(r) => setRows(r)} setSearchText={setSearchText} drisList={driList} drisTree={driTree} serversList={serversList.sort()}
                        toggle={toggle} setHasData={setHasData} setInitialRows={setInitialRows} userEmail={user_email} userRole={user_role} allRows={initialRows} sortBy={sortBy} setSortBy={setSortBy}
                        filterBy={filterBy} setFilterBy={setFilterBy} searchText={searchText} SelectedGlobalServer={SelectedGlobalServer} />
                    <h1>No Incident Found :(</h1>
                </div>
            </Fade>
        );
    }
    return (
        <div className="page-content-2">
            {content}
        </div>
    );
}



function IncidentTopBar({ toggle, setRows, rowsCurrent, setSearchText, drisList, drisTree, serversList, setHasData, setInitialRows, userEmail, userRole, sortBy, setSortBy, filterBy, setFilterBy, searchText, SelectedGlobalServer }) {

    const serversMenuList = serversList.map((item, index) => {
        return <MenuItem key={index} onClick={() => handleServerItemClick(item)}>{item}</MenuItem>
    });
    // console.log(serversList);
    const grow = keyframes`
	0% {
		scale: 1;
	}
	20% {
		scale: 1.2;
		rotate: 0deg;
	}
	35% {
		rotate: -20deg;
	}
	45% {
		rotate: 20deg;
	}
	65% {
		rotate: -20deg;
	}
	80% {
		scale: 1.2;
		rotate: 0deg;
	}
	100% {
		scale: 1;
	}
	`;

    const [sortAnchor, setSortAnchor] = useState(null);
    const [filterAnchor, setFilterAnchor] = useState(null);
    const [driAnchor, setDriAnchor] = useState(null);
    const [serverAnchor, setServerAnchor] = useState(null);
    const [open, setOpen] = useState(false);
    const [minimize, setMinimize] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [iframeLoaded, setIframeLoaded] = useState(false);

    function handleSortButtonClick(event) {
        setSortAnchor(event.currentTarget);
    }

    function handleSortButtonClose() {
        setSortAnchor(null);
    }

    function handleSearchButtonClick() {
        // setSearchText("");
        setOpen(!open);
    }

    function handleFilterButtonClick(event) {
        setFilterAnchor(event.currentTarget);
    }

    function handleFilterButtonClose() {
        setFilterAnchor(null);
    }

    function handleDriButtonClose() {
        setDriAnchor(null);
        setFilterAnchor(null);
    }

    function handleEscKeyPress(event) {
        if (event.key === "Escape") {
            // setSearchText("");
            setOpen(null);
        }
    }



    const handleMenuOpen = (event) => {
        if (minimize) {
            setMinimize(false);
        } else {
            setOpenPopup(true);
        }
        // setAnchorEl(event.currentTarget);
    };
    // function handleDriTreeButtonClose() {
    //  setDriAnchor(null);
    //  //setDriTreeAnchor(null);
    //  setFilterAnchor(null);
    // }

    function handleServerButtonClose() {
        setServerAnchor(null);
        setFilterAnchor(null);
    }

    const handleClosePopup = () => {
        setFilterBy([...filterBy]);
        setOpenPopup(false);
        setIframeLoaded(false);
    };


    const handleIframeLoad = () => {
        setIframeLoaded(true);
    };

    function handleMinimizeClick(event) {
        setMinimize(true);
    }


    function handleSortItemClick(item) {
        setSortAnchor(null);
        setSortBy(item);

    }

    async function handleFilterItemClick(event, item) {
        if (item === "Server") {
            setServerAnchor(event.currentTarget);
        }
        else if (item === "Dri") {
            setDriAnchor(event.currentTarget);
        }

        else if (item === "Default") {

            setHasData(null);
            setFilterBy(["Default"]);
        }
    }




    const handleDriTreeItemClick = async (item) => {

        setHasData(null);
        var tempFilterBy = [...filterBy];
        var index = tempFilterBy.indexOf("Default");
        if (index > -1) {
            tempFilterBy.splice(index, 1);
        }
        index = tempFilterBy.indexOf(item.Email);
        if (index > -1) {
            tempFilterBy.splice(index, 1);
        }
        setFilterBy([item.Email, ...tempFilterBy]);
    };

    async function handleServerItemClick(item) {

        setHasData(null);
        var tempFilterBy = [...filterBy];
        var index = tempFilterBy.indexOf("Default");
        if (index > -1) {
            tempFilterBy.splice(index, 1);
        }
        index = tempFilterBy.indexOf(item);
        if (index > -1) {
            tempFilterBy.splice(index, 1);
        }
        setFilterBy([item, ...tempFilterBy]);
    }

    function handleToggleClosedButtonClick() {
        toggle.current = !toggle.current;
        setHasData(null);


    }

    function handleSearchTextChange(event) {
        setSearchText(event.target.value);
    }


    function handleChipClick(event, item) {
        if (event.detail === 2) {
            setFilterBy([item]);
        }
    }

    return (
        <div className="Incident-top-bar">
            <div className="Incident-top-bar-left-group" style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "20px"
            }}>

                {/* <Button variant="contained" size="small" onClick={handleMenuOpen}  sx={{
					borderRadius: "100px",
					padding: 0.9,
					paddingRight: 3.4,
					paddingLeft: 3.6
				}}> 
					{
						minimize ?
							<OpenInFullRoundedIcon sx={{
								animation: `${grow} 1s ease`
							}}/>
							:
							<AddIcon sx={{fontSize: "1.4rem"}} />
					}
				</Button> */}


                <Box style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    width: "calc(50vw)",
                    overflowX: "scroll"
                }}
                    className="filter-sort-overflow-container"
                >
                    {
                        searchText !== "" ?
                            <Chip
                                label={
                                    <Box style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <Box style={{
                                            color: "#0c2c70",
                                            borderRight: "1px solid #103fa3",
                                            paddingRight: "6px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <SearchIcon />
                                        </Box>
                                        <Box style={{
                                            paddingLeft: "6px",
                                            color: "ghostwhite"
                                        }}>
                                            {searchText}
                                        </Box>
                                    </Box>
                                }
                                sx={{
                                    bgcolor: "#226DB4 !important",
                                    border: "1px solid #0c2c70"
                                }}
                                onDelete={() => {
                                    setSearchText("");
                                }}
                                deleteIcon={
                                    <CancelIcon
                                        onMouseDown={(event) => event.stopPropagation()}
                                        sx={{
                                            color: "#0c2c70 !important"
                                        }}
                                    />
                                }
                            />
                            :
                            <></>
                    }
                    {
                        sortBy !== "Default" ?
                            <Chip
                                label={
                                    <Box style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <Box style={{
                                            color: "#14421c",
                                            borderRight: "1px solid #14421c",
                                            paddingRight: "6px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <SortIcon />
                                        </Box>
                                        <Box style={{
                                            paddingLeft: "6px",
                                            color: "ghostwhite",
                                            width: "70px",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            textAlign: "center"
                                        }}>
                                            <Tooltip title={sortBy}>
                                                {sortBy}
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                }
                                sx={{
                                    bgcolor: "rgb(8,153,73,0.9) !important",
                                    border: "1px solid #14421c"
                                }}
                                onDelete={() => {
                                    setSortBy("Default");
                                }}
                                deleteIcon={
                                    <CancelIcon
                                        onMouseDown={(event) => event.stopPropagation()}
                                        sx={{
                                            color: "#14421c !important"
                                        }}
                                    />
                                }
                            />
                            :
                            <></>
                    }
                    {
                        !filterBy.includes("Default") ?
                            filterBy.map((item, index) => {
                                return (
                                    <Chip
                                        key={index}
                                        label={
                                            <Box style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }} onClick={(event) => handleChipClick(event, item)}>
                                                <Box style={{
                                                    color: "#331511",
                                                    borderRight: "1px solid #53291c",
                                                    paddingRight: "6px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center"
                                                }}>
                                                    <FilterAltOutlinedIcon />
                                                </Box>
                                                <Box style={{
                                                    paddingLeft: "6px",
                                                    color: "ghostwhite",
                                                    width: "70px",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    textAlign: "center"
                                                }}>
                                                    <Tooltip title={item}>
                                                        {item}
                                                    </Tooltip>
                                                </Box>
                                            </Box>
                                        }
                                        sx={{
                                            // bgcolor: "rgb(228,37,39, 0.9) !important"
                                            background: "rgba(177,44,9,1) !important",
                                            border: "1px solid #53291c"
                                        }}
                                        onDelete={() => {
                                            setHasData(null);
                                            var tempFilterBy = [...filterBy];
                                            var index = tempFilterBy.indexOf(item);
                                            if (index > -1) {
                                                tempFilterBy.splice(index, 1);
                                            }
                                            if (tempFilterBy.length === 0) {
                                                tempFilterBy.push("Default");
                                            }
                                            setFilterBy(tempFilterBy);
                                        }}
                                        deleteIcon={
                                            <CancelIcon
                                                onMouseDown={(event) => event.stopPropagation()}
                                                sx={{
                                                    color: "#53291c !important"
                                                }}
                                            />
                                        }
                                    />
                                );
                            })
                            :
                            <></>
                    }
                    <div style={{
                    }} className="unselectable">
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                    </div>
                </Box>



            </div>
            <Dialog open={openPopup} onClose={handleClosePopup} fullScreen>
                <DialogActions>
                    <Button onClick={handleMinimizeClick} variant="text" fontSize="large"><RemoveIcon /></Button>
                    <Button onClick={handleClosePopup} variant="text" fontSize="large"><CloseIcon /></Button>
                </DialogActions>
                <DialogContent sx={{ height: "100%", width: "100%" }}>
                    {!iframeLoaded && (
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                height: '100%',
                                backgroundImage: 'linear-gradient(to right, #f0f0f0, #e0e0e0)',
                                borderRadius: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height="100%"
                                animation="wave"
                            />
                        </div>
                    )}
                    <iframe
                        src={"https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/Incident_Management?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true"}
                        height="100%"
                        width="100%"
                        title="External Content"
                        onLoad={handleIframeLoad}
                        frameBorder={0}
                        style={{ display: iframeLoaded ? 'block' : 'none' }}
                    />
                </DialogContent>
            </Dialog>


            <div className="Incident-top-bar-right-group">
                {/* <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Collapse in={open} timeout="auto" orientation="horizontal" unmountOnExit>
                        <TextField variant="outlined" placeholder="search" onChange={handleSearchTextChange} value={searchText} autoFocus onKeyDown={handleEscKeyPress} />
                    </Collapse>
                    <Tooltip title="search"><Button variant="contained" size="small" onClick={handleSearchButtonClick}><SearchIcon /></Button></Tooltip>

                


                    <Tooltip title="filter by"><Button variant="contained" size="small" onClick={handleFilterButtonClick}><FilterAltOutlinedIcon /><ArrowDropDownIcon /></Button></Tooltip>
                    <Tooltip title="sort by"><Button variant="contained" size="small" onClick={handleSortButtonClick}><SortIcon /><ArrowDropDownIcon /></Button></Tooltip>
                </ButtonGroup> */}
                <Menu
                    anchorEl={sortAnchor}
                    open={sortAnchor !== null}
                    onClose={handleSortButtonClose}
                >

                    <MenuItem onClick={() => handleSortItemClick("Date")}>
                        <DateRangeIcon />
                        &nbsp;
                        Date
                    </MenuItem>

                </Menu>
                <Menu
                    anchorEl={filterAnchor}
                    open={filterAnchor !== null}
                    onClose={handleFilterButtonClose}
                >
                    <MenuItem onClick={(event) => handleFilterItemClick(event, "Default")} style={{ justifyContent: "center" }}>Default</MenuItem>
                    <MenuItem onClick={(event) => handleFilterItemClick(event, "Dri")}><ArrowLeftIcon />DRI</MenuItem>
                    {SelectedGlobalServer === "No Server" ? (
                        <MenuItem onClick={(event) => handleFilterItemClick(event, "Server")}><ArrowLeftIcon />Server</MenuItem>
                    ) : (
                        <></>
                    )}
                </Menu>
                {drisTree && drisTree.length > 0 && (
                    <MultiLevelDropdown
                        dritree={drisTree}
                        driAnchor={driAnchor}
                        handleDriTreeButtonClose={handleDriButtonClose}
                        handleDriTreeItemClick={handleDriTreeItemClick}
                    />
                )}


                <Menu
                    anchorEl={serverAnchor}
                    open={serverAnchor !== null}
                    onClose={handleServerButtonClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    {serversMenuList}
                </Menu>
            </div>
        </div>
    );
}

function IncidentTable({ toggle, rows, searchText, allRows, setRows, sortBy, setSortBy, filterBy, setFilterBy }) {
    const classNameForColors = ["color-a", "color-b", "color-c", "color-d", "color-e", "color-f"];
    var ind = useRef(0);
    var newRows = rows.filter(row_ => {

        for (const item of row_) {
            if (item === null || item === undefined) {
                continue;
            }
            const aString = item.toLowerCase().replaceAll(" ", "");
            const bString = searchText.toLowerCase().replaceAll(" ", "");
            if (aString.indexOf(bString) !== -1) {
                return true;
            }
        }
        return false;
    });
    // console.log(newRows);
    const theme = useTheme();

    if (newRows.length === 0) {
        return <h1 style={{ color: theme.palette.background.text }}>No Incident Found :(</h1>;
    }

    const rowSpanInfo = find_row_span(newRows, [2]);

    return (
        <div className="Incident-table">
            <TableContainer component={Paper} className="Incident-table-container">
                <Table stickyHeader>
                    <TableHead>
                        <TableRow className="Incident-table-head">
                            <TableCell align="center">S.No.</TableCell>
                            <TableCell align="center">Incident ID</TableCell>
                            <TableCell align="center">Incident Title</TableCell>
                            <TableCell align="center">Server/Component Name</TableCell>
                            <TableCell align="center"> DC's Affected </TableCell>
                            <TableCell align="centre">RCA</TableCell>
                            <TableCell align="center"/>
                            <TableCell align="centre"></TableCell>
                            {/* <TableCell align="center" className="Incident-table-grouper-header" /> */}

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {newRows.map((row, index) => (
                            <Row key={index} row={row} index={index} rowSpanInfo={rowSpanInfo[index]} classNameForColors={classNameForColors} ind={ind} allRows={allRows} setRows={setRows} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

function Row({ row, index, rowSpanInfo, classNameForColors, ind, setRows, allRows }) {

    var initial_value = row[6];
    const [moreVisibile, setMoreVisible] = useState(true);

    const [showAlert, setShowAlert] = useState(false);
    const [showAlert2, setShowAlert2] = useState(false);
    const [showAlert3, setShowAlert3] = useState(false);
    const [showAlert4, setShowAlert4] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(null);
    const [Total_Count, setTotal_Count] = useState([]);
    const [open1, setOpen1] = useState(true);
    const [clickedRowData, setClickedRowData] = useState(null);
    const [moreViewOpen, setMoreViewOpen] = useState(false);
    const [localStatus, setLocalStatus] = useState(null);
    const [localClaimedDRI, setLocalClaimedDRI] = useState(null);
    // const handleViewDocument = () => {
    //     var filestore = window.catalyst.file;
    //     console.log(filestore);
    //     var folder = filestore.folderId("5781000016074892");
    //     console.log(folder);
    //     var file = folder.fileId(row[23]);
    //     var downloadPromise = file.getDownloadLink();
    //     downloadPromise
    //         .then((response) => {

    //             const a = document.createElement('a');
    //             a.style.display = 'none';

    //             // Set the anchor's href to the download URL
    //             a.href = "https://zvpone-709834020.catalystserverless.com" + response.content.download_url;

    //             // Set the anchor's download attribute if you want to specify a download filename
    //             // a.download = "filename.txt";

    //             // Trigger a click on the anchor element
    //             document.body.appendChild(a);
    //             a.click();

    //             // Clean up by removing the anchor element
    //             document.body.removeChild(a);
    //         });
    // }
    const handleViewDocument = () => {
        var fileId1 = row[23];

        if (fileId1 === "-") {
            setShowAlert(true);
        } else {
            var filestore = window.catalyst.file;
            var fileId = row[23];
            // console.log(fileId);
            var folder = filestore.folderId("5781000016074892");
            var file = folder.fileId(fileId);
            var downloadPromise = file.getDownloadLink();
            downloadPromise
                .then((response) => {
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = "" + response.content.download_url;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                });
        }
    };



    function handleMoreViewClick(newRows) {
        // console.log(data)
        setClickedRowData(newRows);
        setMoreViewOpen(true);
    }



    // icons
    // var priority = "None";
    // if(row[3] === "High")
    // {
    //     priority = <ArrowCircleUpTwoToneIcon style={{color: "crimson"}}/>;
    // }
    // else if(row[3] === "Medium")
    // {
    //     priority = <RemoveCircleTwoToneIcon style={{color: "orange"}}/>;
    // }
    // else if(row[3] === "Low")
    // {
    //     priority = <ArrowCircleDownTwoToneIcon style={{color: "mediumseagreen"}}/>;
    // }
    // else
    // {
    //     priority = <Brightness1TwoToneIcon style={{color: "grey"}}/>;
    // }

    // var status = <HexagonIcon style={{ color: "grey", fontSize: "small" }} />;
    // if (row[7] === "Open") {
    //     status = <HexagonIcon style={{ color: "green", fontSize: "small" }} />;
    // }
    // else if (row[7] === "In Progress") {
    //     status = <HexagonIcon style={{ color: "royalblue", fontSize: "small" }} />;
    // }
    // else if (row[7] === "In Review") {
    //     status = <HexagonIcon style={{ color: "bluegrey", fontSize: "small" }} />;
    // }
    // else if (row[7] === "To be Tested") {
    //     status = <HexagonIcon style={{ color: "orange", fontSize: "small" }} />;
    // }
    // else if (row[7] === "On Hold") {
    //     status = <HexagonIcon style={{ color: "goldenrod", fontSize: "small" }} />;
    // }
    // else if (row[7] === "Delayed") {
    //     status = <HexagonIcon style={{ color: "gold", fontSize: "small" }} />;
    // }
    // else if (row[7] === "Closed") {
    //     status = <HexagonIcon style={{ color: "tomato", fontSize: "small" }} />;
    // }
    // else if (row[7] === "Cancelled") {
    //     status = <HexagonIcon style={{ color: "navy", fontSize: "small" }} />;
    // }
    // else if (row[7] === "RMap") {
    //     status = <HexagonIcon style={{ color: "pink", fontSize: "small" }} />;
    // }
    // else if (row[7] === "Ongoing") {
    //     status = <HexagonIcon style={{ color: "salmon", fontSize: "small" }} />;
    // }



    // side groupers
    var server = (
        <Tooltip title={row[2]} placement="right-start">
            <TableCell align="center" className={"Incident-table-grouper-component Incident-single-row " + row[2]}>
                <div style={{ whiteSpace: "nowrap" }}>{row[2].length > 8 ? row[2].slice(0, 8) + "..." : row[2]}</div>
            </TableCell>
        </Tooltip>
    );

    if (rowSpanInfo[2] === 0) {
        server = <></>;
    }
    else if (rowSpanInfo[2] !== 1) {
        server = (
            <TableCell align="center" className={"Incident-table-grouper-component Incident-multi-row " + row[2]} rowSpan={rowSpanInfo[2]} >
                <div style={{ whiteSpace: "nowrap" }}>{row[2]}</div>
            </TableCell>
        );
        ind.current = ind.current + 1;
    }
    else {
        ind.current = ind.current + 1;
    }

    var type = (
        <TableCell align="center" className={"Incident-table-grouper-type Incident-single-row " + row[1]}>
            <div style={{ whiteSpace: "nowrap" }}>{row[1]}</div>
        </TableCell>
    );

    if (rowSpanInfo[1] === 0) {
        type = <></>;
    }
    else if (rowSpanInfo[1] !== 1) {
        type = (
            <TableCell align="center" className={"Incident-table-grouper-type Incident-multi-row " + row[1]} rowSpan={rowSpanInfo[1]}>
                <div style={{ whiteSpace: "nowrap" }}>{row[1]}</div>
            </TableCell>
        );
    }

    const theme = useTheme();
    var color = "black";

    const TextOnlyTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
    ))(`
          color: black;
          background-color: white;
      `);

    const handleClick = () => {


        if (open === index) {
            setOpen(null);
        }

        else {
            setOpen(index);
        }
    };

    useEffect(() => {
        if (showAlert) {
            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 2000);
            //setRowTicketNumber('');

            return () => clearTimeout(timer);
        }
    }, [showAlert]);

    var status_new;
    if (row[7] == "Open") {
        status_new = "Live";

    }
    else {
        status_new = row[7];
    }

    // const originalDate = new Date(row[21]); 

    // var formattedDate = format(originalDate, 'dd-MMM-yyyy HH:mm:ss:SSS');
    // var timeZoneInfo = 'GMT+0530 (India Standard Time)';
    // formattedDate = formattedDate + ' ' + timeZoneInfo;

    const originalStringduplicate = row[21];
    // const stringWithoutLastThreeChars = originalStringduplicate.slice(0, -3);
    // const originalDate =stringWithoutLastThreeChars; 
    // var formattedDate = format(originalStringduplicate, 'HH:mm');
    var timeZoneInfo = 'GMT+0530 (India Standard Time)';
    var formattedDate = row[21] + ' ' + timeZoneInfo;

    // console.log("newRows >>>>>>>>>>>>>>" + row);

    return (
        <>

            <TableRow
                hover={true}
                sx={{
                    '& > *': { borderBottom: 'unset' },
                    backgroundColor: theme.palette.background.tableRow,
                    '&:hover': {
                        backgroundColor: theme.palette.background.tableRowHover + " !important"
                    }
                }}
            // onMouseEnter={() => setMoreVisible(true)}
            // onMouseLeave={() => setMoreVisible(false)}
            >
                {/* {server}
                <TableCell align="center" className='slide-animation' style={{ width: "30px" }}>
                    <IconButton
                        size="small"
                        onClick={handleClick}
                    > */}
                {/* {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} */}
                {/* 
                        <OpenInNewIcon />
                    </IconButton>
                    <IconButton>

                        {/* <FontAwesomeIcon icon="faCircleInfo" /> */}
                {/* </IconButton> */}
                {/* </TableCell> */}
                <TableCell onClick={handleClick} align="center" style={{ width: "2.5vw", maxWidth: "2.5vw" }}>{index + 1}</TableCell>
                <TableCell onClick={handleClick} align="center" style={{ maxWidth: "20vw", wordWrap: "break-word" }} >
                    {row[12]}
                </TableCell>
                <TableCell onClick={handleClick} align="center" style={{ width: "13vw", minWidth: "5vw", hyphens: "none", wordWrap: "break-word" }}>
                    {row[4]}
                </TableCell>
                {/* <TableCell onClick={handleClick} align="center" style={{ minWidth: "6vw", maxWidth: "10vw", wordWrap: "break-word" }}>{row[1]}</TableCell> */}
                <TableCell onClick={handleClick} align="center" style={{ minWidth: "9vw", maxWidth: "2vw", wordWrap: "break-word" }}>{row[0]}</TableCell>
                <TableCell onClick={handleClick} align="center" style={{ minWidth: "9vw", maxWidth: "2vw" }}>
                    {row[2]}
                </TableCell>

                {/* <TableCell onClick={handleClick}  align="center"><Box className="flex-column">{priority}<p>{row[7]}</p></Box></TableCell>  */}
                {/* <TableCell onClick={handleClick} align="center" style={{ minWidth: "9vw", maxWidth: "2vw", wordWrap: "break-word", textAlign: "center !important" }}><Box className="flex-column">{status}<p>{row[7]}</p></Box></TableCell> */}

                {/* <TableCell align="center" onClick={handleClick} style={{ minWidth: "9vw", maxWidth: "2vw" }} >
                    <GetNameAndEmpID emails={row[8]} />
                </TableCell> */}


                <TableCell align="centre" style={{ width: "2vw", minWidth: "2vw" }} sx={{ display: 'flex', justifyContent: 'centre' }}> {/* Use flexbox to center content */}
                    {row[23] !== null && (
                        <Button variant="contained" color="action" size="small" onClick={handleViewDocument} sx={{ padding: 0 }}>
                            <Tooltip title="Click to Download Document">
                                <IconButton aria-label="download">
                                    <CloudDownloadIcon />
                                </IconButton>
                            </Tooltip>
                        </Button>
                    )}
                </TableCell>
 
                <TableCell align="centre">
                    <Button onClick={() => handleMoreViewClick(row)} sx={{ borderRadius: "100px", padding: "5px" }}>
                        <InfoOutlinedIcon />
                    </Button>
                    <MoreViewIncident data={clickedRowData && clickedRowData.Tickets} open={moreViewOpen} setOpen={setMoreViewOpen} localStatus={localStatus} localClaimedDRI={localClaimedDRI} handleClaimButtonClick={"No data"} row={row} />
                </TableCell>

                <MoreSection  moreVisibile={moreVisibile} setMoreVisible={setMoreVisible} row={row} />

            </TableRow>
            <Snackbar
                open={showAlert}

                onClose={() => setShowAlert(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity="error">No document has been attached.</Alert>
            </Snackbar>

        </>
    );
}


export default Incident;

function statusCompareFunction(a, b) {

    const power = {
        "Updated to IDC": 10,
        "Open": 9,
        "In Progress": 8,
        "Ongoing": 7,
        "To be Tested": 6,
        "In Review": 5,
        "On Hold": 4,
        "Delayed": 3,
        "Closed": 2,
        "Cancelled": 1,
        "Rmap": 0
    };

    if (power[a[7]] < power[b[7]]) {
        return 1;
    }
    if (power[a[7]] > power[b[7]]) {
        return -1;
    }
    return 0;
}


async function setDriDropDown(setDriList, setDriTree, userDetails, teamDetails, dept) {
    const query = issue_incidents_get_query_4(dept);
    var result2 = await getFromTeamDetails(query);
    const head_parent = "srinivasan@zohocorp.com";
    // const head_root = "mariaraj@zohocorp.com";
    const head_root = ["nishanth.g@zohocorp.com", "manoj.rs@zohocorp.com"];
    const employees = result2;
    var hierarchicalData = [];
    hierarchicalData = createHierarchy(employees, head_parent);

    return new Promise((resolve, reject) => {
        const userEmail = userDetails.current.auth.email_id;
        const userRole = teamDetails.current[userEmail].Role;
        const userReportingTo = teamDetails.current[userEmail].ReportingTo;
        var users = [];
        if (userRole === "CEO" && head_root.includes[userEmail]) {
            for (const user in teamDetails.current) {
                users.push(user);
            }

            users.sort();
            setDriList(users);
            setDriTree(hierarchicalData);
        }

        else if (userRole === "CEO" && !head_root.includes(userEmail)) {
            for (const user in teamDetails.current) {
                users.push(user);
            }
            users.sort();
            setDriList(users);
            setDriTree(hierarchicalData);
            users = findMentees([userEmail], teamDetails);
            users.sort();
            users = [userEmail, ...users];
            setDriList(users);
            hierarchicalData = createHierarchy(employees, userReportingTo);
            const getDriList = [];
            getDriList.push(getMentorWithMentees(hierarchicalData, userEmail));
            var hierarchicalData11 = [];
            hierarchicalData11 = createHierarchy(employees, head_parent);
            hierarchicalData11.push(getDriList[0]);
            setDriTree(hierarchicalData11);
        }

        else {
            users = findMentees([userEmail], teamDetails);
            users.sort();
            users = [userEmail, ...users];
            setDriList(users);
            hierarchicalData = createHierarchy(employees, userReportingTo);
            const getDriList = [];
            getDriList.push(getMentorWithMentees(hierarchicalData, userEmail));
            setDriTree(getDriList);
        }
        return resolve();
    });
}



async function setServerDropDown(setServersList, userDetails, teamDetails, allServers) {

    return new Promise((resolve, reject) => {
        const userEmail = userDetails.current.auth.email_id;
        const userRole = teamDetails.current[userEmail].Role;

        if (userRole === "CEO") {
            setServersList(allServers);
            // console.log(allServers)
        }
        else {
            setServersList(teamDetails.current[userEmail].Servers.split(","));
            // console.log(teamDetails.current[userEmail].Servers.split(","))
        }
        return resolve();
    });
}

function findMentees(userEmails, teamDetails) {
    if (userEmails.length === 0) {
        return [];
    }

    const arr = [];

    for (const person in teamDetails.current) {
        if (userEmails.includes(teamDetails.current[person].ReportingTo)) {
            arr.push(person);
        }
    }

    const newArr = arr.concat(findMentees(arr, teamDetails));

    return newArr;
}

function createHierarchy(data, reportingTo) {
    const hierarchy = [];

    for (const employee of data) {
        if (employee.ReportingTo === reportingTo) {
            const subordinates = createHierarchy(data, employee.Email);
            if (subordinates.length) {
                employee.mentees = subordinates;
            }
            hierarchy.push(employee);
        }
    }
    hierarchy.sort((a, b) => {
        const empIDA = parseInt(a.EmployeeID, 10);
        const empIDB = parseInt(b.EmployeeID, 10);
        return empIDA - empIDB;
    });
    // hierarchy.sort((a, b) => {
    //  const empIDA = a.name.toLowerCase();
    //  const empIDB = b.name.toLowerCase();
    //  return empIDA.localeCompare(empIDB);
    //   });

    return hierarchy;
}
function getMentorWithMentees(hierarchy, mentorEmail) {
    for (const mentor of hierarchy) {
        if (mentor.Email === mentorEmail) {
            return mentor;
        } else if (mentor.mentees && mentor.mentees.length > 0) {
            const foundMentor = getMentorWithMentees(mentor.mentees, mentorEmail);
            if (foundMentor) {
                return foundMentor;
            }
        }
    }
    return null;
}

function MoreSection({ moreVisibile, setMoreVisible, row }) {
    const [moreAnchor, setMoreAnchor] = useState(null);

    function handleEditClick() {
        
        window.open(row[23], '_blank');
    }
    return (
        <TableCell align="right" style={{ width: "2px", cursor: "pointer" }}>
            <Fade in={moreVisibile} timeout={100}>
                <MoreVertIcon onClick={(event) => setMoreAnchor(event.currentTarget)} />
            </Fade>
            <Menu anchorEl={moreAnchor}
                open={moreAnchor !== null}
                onClose={() => { setMoreAnchor(null) }}
            >
                <MenuItem onClick={handleEditClick}>
                    <Box style={{ display: "flex" }}>
                        <LinkIcon fontSize="medium" />
                        &nbsp;

                        &nbsp;Go to Thread

                    </Box>
                </MenuItem>
            </Menu>

        </TableCell>
    );
}

function dateCompareFunction(a, b) {
    if (a[21] < b[21]) {
        return -1;
    }
    else if (b[21] > a[21]) {
        return 1;
    }
    return 0;
}
