import { isDateInRange, isDateString } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    const chartData = [];
    
    const taskMap = {};

    catalystDB.TaskBreaker.map(item => {

        const dateString = item.CREATEDTIME;


        if(isDateString(dateString) && isDateInRange(dateString, startTime)) {
            const completionDateString = item.TaskCompletionDate;

            if([undefined, null, "", " "].includes(completionDateString) && !isDateString(completionDateString)) {
                return;
            }

            if(item.Component in taskMap) {
                if(item.DRI in taskMap[item.Component]) {
                    taskMap[item.Component][item.DRI] += 1; 
                } else {
                    taskMap[item.Component][item.DRI] = 1;
                }
            } else {
                taskMap[item.Component] = {};
                taskMap[item.Component][item.DRI] = 1;
            }
        }
    }); 

    for(const server in taskMap) {
        const arr = [];

        for(const user in taskMap[server]) {
            arr.push([
                user,
                taskMap[server][user]
            ])
        }

        chartData.push({
            "seriesname": server,
            "data": arr
        });
    }

    return chartData;
}