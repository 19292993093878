import { isDateInRange, isDateString } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    var totalCount = 0;
    var testResultCount = 0;
    var passesCount = 0;
    var failedCount = 0;
    const dataMap = {
        "Passed": [],
        "Failed": [],
        "No Tests": []
    };
    const allBuilds = {};

    catalystDB.AllBuildDetails.map(item => {
        const dateString = item.CREATEDTIME;

        if(isDateString(dateString) && isDateInRange(dateString, startTime)) {
            totalCount++;
            allBuilds[item.BuildLabel] = item;
        }
    }); 

    catalystDB.QATest.map(item => {
        const dateString = item.CREATEDTIME;

        if(isDateString(dateString) && isDateInRange(dateString, startTime)) {
            try {
                const obj = JSON.parse(item.testResult);
                const qaBuildLabel = item.newBuildLabel;
                testResultCount++;

                if(obj.result === true) {
                    if(qaBuildLabel in allBuilds) {
                        dataMap.Passed.push(allBuilds[qaBuildLabel]);
                        delete allBuilds[qaBuildLabel];
                    }
                    passesCount++;
                } else if(obj.result === false) {
                    if(qaBuildLabel in allBuilds) {
                        dataMap.Failed.push(allBuilds[qaBuildLabel]);
                        delete allBuilds[qaBuildLabel];
                    }
                    failedCount++;
                }

            } catch(e) {}
        }
    });

    for(const buildLabel in allBuilds) {
        dataMap["No Tests"].push(allBuilds[buildLabel]);
    }

    const chartData = [
        [
            "Passed",
            passesCount
        ],
        [
            "Failed",
            failedCount
        ],
        [
            "No Tests",
            (totalCount - testResultCount >= 0) ? (totalCount - testResultCount) : 0
        ]
    ];


    return [chartData, dataMap];
}