/* eslint-disable react/prop-types */ 
/* esline-disable react/jsx-key */
import React from "react";
import { useEffect, useRef, useState, useContext } from "react";
import { UserContext } from "../../../../../contexts/UserContext";
import { useTheme } from '@mui/material/styles';
import { Box, ButtonGroup, Skeleton, Tab } from "@mui/material";
import { ReportContext } from "../newReportGeneral/NewReport";
import { getMilestoneRows } from "../reportSection1/ReportSection1DataFetcher"; 

import AirIcon from '@mui/icons-material/Air';
import { GanttChart } from "../newReportGeneral/NewReportUtilComponents";
import { formatDate, isValidDate } from "../newReportGeneral/NewReportsUtil";

import { IOSSwitch } from "../newReportGeneral/NewReportUtilComponents";

export function ReportSection2() {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            width: "100%",
            height: "98%",
            gap: "10px"
        }}>
             <Box sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.1rem",
                fontWeight: "bold"
            }}>
                Milestone Timeline
            </Box>
            <ReportBody/>
        </Box>
       
    );
}   


function ReportBody({setExpanded}) {

    const {
        subjectType, 
        setSubjectType, 
        subject, 
        setSubject, 
        timeFrame, 
        setTimeFrame, 
        time, 
        setTime,
        timeRange,
        setTimeRange
    } = useContext(ReportContext);
    const theme = useTheme();
    const {teamDetails, loginUserDept} = useContext(UserContext);
    
    const [loaded, setLoaded] = useState(undefined);
    const [milestoneRows, setMilestoneRows] = useState(undefined);
    const [chartData, setChartData] = useState(undefined);
    const [toggle, setToggle] = useState(false);

    function handleToggleChange() {
        setToggle(!toggle);
    }

    // var chartData = [
    //     [
    //         [
    //             "Designsdfasdfasdfasdfasdfasdfasdfasdfasdfasdfsddfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdf",
    //             [
    //                 1463596200000,
    //                 1464028200000
    //             ],
    //             125,
    //             70000,
    //             "Augustine"
    //         ],
    //         [
    //             "TechDesign",
    //             [
    //                 1463855400000,
    //                 1464114600000
    //             ],
    //             75,
    //             45000,
    //             "Benjamin"
    //         ],
    //         [
    //             "Analyzing",
    //             [
    //                 1464028200000,
    //                 1464373800000
    //             ],
    //             90,
    //             90000,
    //             "David"
    //         ],
    //         [
    //             "Development",
    //             [
    //                 1464373800000,
    //                 1465410600000
    //             ],
    //             45,
    //             45500,
    //             "David"
    //         ],
    //         [
    //             "FrontEnd Dev",
    //             [
    //                 1464719400000,
    //                 1465583400000
    //             ],
    //             90,
    //             90000,
    //             "Catherine"
    //         ],
    //         [
    //             "Validating",
    //             [
    //                 1465669800000,
    //                 1465929000000
    //             ],
    //             90,
    //             90000,
    //             "David"
    //         ],
    //         [
    //             "Bug fixing",
    //             [
    //                 1465669800000,
    //                 1466101800000
    //             ],
    //             10,
    //             70000,
    //             "David"
    //         ],
    //         [
    //             "Feedback",
    //             [
    //                 1465929000000,
    //                 1466188200000
    //             ],
    //             50,
    //             85000,
    //             "Carl"
    //         ]
    //     ]
    // ];
    
    useEffect(() => {
        const controller = new AbortController();
        const date = new Date();
        const formattedDate = date.toLocaleDateString("en-US");
        async function pullMilestoneRows() {

            getMilestoneRows(timeRange, subjectType, subject, teamDetails, loginUserDept).then((response) => {
                if(!controller.signal.aborted) {
                    if(response.length !== 0) {
                        var arr = [];
                        var milestoneNameTable = {};

                        response.forEach((item) => {
                            if(toggle && (item.milestone_status === "Completed" || item.completed_date !== null)) {
                                return;
                            }

                            try {
                                var startTime = new Date(item.start_date).getTime();
                                var dueTime = new Date(item.due_date).getTime();
                                var completedTime = new Date(item.completed_date);
                                var perc = ((completedTime.getTime()-startTime)/(dueTime-startTime))*100;

                                if(startTime === 0) {
                                    return;
                                }
                                if(dueTime === 0) {
                                    dueTime = startTime;
                                }

                                if(perc < 0) {
                                    perc = 0;
                                }
                                var milestoneName = item.milestone_name;

                                if(milestoneName in milestoneNameTable) {
                                    milestoneNameTable[milestoneName] += 1;
                                    milestoneName += "-[" + milestoneNameTable[milestoneName] + "]";
                                } else {
                                    milestoneNameTable[milestoneName] = 1;
                                }

                                arr.push([
                                    milestoneName,
                                    [
                                        startTime,
                                        dueTime
                                    ],
                                    perc,
                                    item.completed_date === null ? "-" : formatDate(completedTime),
                                    item.milestone_dri + (perc > 100 ? " - (overdue)" : "")
                                ]);

                            } catch(e) {}
                        });

                        // arr.push([
                        //     "today",
                        //     [
                        //         new Date().getTime(),
                        //         new Date().getTime()
                        //     ],
                        //     0,
                        //     "-",
                        //     ""
                        // ]);

                        setMilestoneRows(response);
                        setChartData(arr);
                        setLoaded(true);
                    } else {
                        setLoaded(false);
                    }
                }
            }).catch((error) => {
                if(!controller.signal.aborted) {
                    setLoaded(false);
                }
            });
        }

        setLoaded(undefined);
        pullMilestoneRows();

        return () => {
            controller.abort();
        };

    }, [timeRange, subjectType, subject, toggle]);


    if(loaded === undefined) {
        return (
            <Skeleton variant={"rounded"} height={"98%"} width={"100%"} animation="wave" />
        );
    } else if(loaded === false) {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%"
            }}>
                <Box>
                    <AirIcon sx={{
                        fontSize: "6rem",
                        color: theme.palette.mode === "light" ? "rgb(20, 20, 20, 0.06)": "rgb(200, 200, 200, 0.06)"
                    }} />
                </Box>
                <Box sx={{
                    fontSize: "0.7rem",
                    color: theme.palette.mode === "light" ? "rgb(10, 10, 10, 0.3)": "rgb(200, 200, 200, 0.4)"
                }}>
                    Nothing found
                </Box>
            </Box>
        );
    } else {
        return (
            <Box  sx={{
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                overflowY: "scroll",
                boxSizing: "border-box",
                backgroundColor: theme.palette.mode === 'dark' ? 'rgb(24,30,35)' : '#FAFAFA',
                borderRadius: "12px",
                overflowX: "hidden",
                overflowY: "hidden"
            }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",
                    margin: "10px",
                    fontWeight: 900,
                    fontSize: "1rem",
                    gap: "10px"
                }}>
                    <Box>
                        Show Only Active Milestones
                    </Box>
                    <Box>
                        <IOSSwitch onChange={handleToggleChange} checked={toggle}/>
                    </Box>
                </Box>
                <Box sx={{
                    height: "97%"
                }}>
                    <GanttChart title={""} xaxisLabel={""} yaxisLabel={""} columnName1={"Scheduled Date"}
                    columnName2={"Completed Date"} columnName3={"Milestone Owner"} data={chartData} />
                </Box>
            </Box>
        );
    }
}