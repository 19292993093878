/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React, { useContext, useLayoutEffect, useEffect } from 'react';
import './SideBar.css';
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import BuildIcon from '@mui/icons-material/Build';

import { Link } from "react-router-dom";
import { TabDetails, TabStateContext } from './../../contexts/TabStateContext.js';
import { UserContext } from '../../contexts/UserContext';

import { useTheme } from '@mui/material';
import {Box} from '@mui/material';

import ConstructionIcon from '@mui/icons-material/Construction';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Tooltip from '@mui/material/Tooltip';

import WarningIcon from '@mui/icons-material/Warning';
import ReportIcon from '@mui/icons-material/Report';

import SupportIcon from '../../icons/customer-support.png';
import NewDCConfigurationIcon from '../../icons/newConfiguration.png';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DataUsageSharpIcon from '@mui/icons-material/DataUsageSharp';
import NewReleasesRoundedIcon from '@mui/icons-material/NewReleasesRounded';
import FlakyRoundedIcon from '@mui/icons-material/FlakyRounded';
import MilstoneAchievementIcon from '../../icons/MilestoneAchievement.svg'
import deveops from '../../icons/devops.png';

import AssessmentIcon from '@mui/icons-material/Assessment';

function SideBar() {
	const { userDetails, loginUserEmail, teamDetails } = useContext(UserContext);
	const loginUserRole = teamDetails.current[loginUserEmail.current].Role;
	const betaUser = teamDetails.current[loginUserEmail.current].BetaUser;

	const { tabState } = useContext(TabStateContext);
	const selectedOuterTab = tabState.current.outer;
	const isFullTab = TabDetails[selectedOuterTab].index < 0;

	useEffect(() => {
		if(isFullTab) {
			return;
		}
		const userEmail = userDetails.current.auth.email_id;
		const userRole = teamDetails.current[userEmail].BetaUser;
		const outerSideBar = document.getElementsByClassName("outer-side-bar")[0];
		const innerSideBar = document.getElementsByClassName("inner-side-bar")[0];

		outerSideBar.style.display = "block";
		innerSideBar.style.left = "50px";
		innerSideBar.style.width = "200px";
	}, [isFullTab]);

	return (
		<div className="side-bar">
			<OuterSideBar loginUserRole={loginUserRole} betaUser={betaUser} loginUserEmail={loginUserEmail}/>
			{
				isFullTab ? 
					null
					:
					<InnerSideBar loginUserRole={loginUserRole} loginUserEmail={loginUserEmail} betaUser={betaUser}/>
			}
		</div>
	);
}


export function OuterSideBar({loginUserRole, betaUser, loginUserEmail}) {
	const theme = useTheme();
	const {captureScreenshot} = useContext(TabStateContext);
	const {zoneAdmin , loginUserDept} = useContext(UserContext);
	
	const { tabState } = useContext(TabStateContext);
	const selectedOuterTab = tabState.current.outer;
	// console.log(tabState)
	// console.log(selectedOuterTab)
	// const selectedInnerTab = tabState.current.inner[selectedOuterTab];

	// <SupportAgentOutlinedIcon fontSize='large' />
	const iconsList = [<AddTaskOutlinedIcon fontSize='large' /> ,<img src={SupportIcon} width={"35px"} style={{filter: "tickets" === selectedOuterTab ? 'invert(0)' : "invert(1)"}}/> , <ConstructionIcon fontSize='large' />, <WarningIcon fontSize='large' />, <AdminPanelSettingsIcon fontSize='large' />, <ConnectWithoutContactIcon fontSize='large' />, <ReportIcon fontSize='large' />, <AssessmentIcon fontSize='large'/>,<FlakyRoundedIcon fontSize='large' />,<EditNoteIcon fontSize='large'/>,<DataUsageSharpIcon fontSize='large' />,<img src={deveops} width={"40px"} style={{filter: "devops" === selectedOuterTab ? 'invert(0)' : "invert(1)"}}/>];
	const iconsList2 = [<SpaceDashboardIcon fontSize='large' />,<MilestoneAchievementIcon />];

	var outerTabs = [];
	var outerTabsBottom = [];

	for (const outerTab in TabDetails) {
		const outerTabDetails = TabDetails[outerTab];

		const outerTabSegment = outerTabDetails.segment;
		const innerTabSegment = tabState.current.innerSegment[outerTab];
		// console.log(loginUserRole)
		
		// if(outerTab === "dashboard" && !["Manager", "Mentor", "CEO", "Super Admin"].includes(loginUserRole))
		// {
		// 	continue;
		// }

		// if(outerTab === "dashboard" &&  !(betaUser==='Yes' ))
		// {
		// 	continue;
		// }

		if (loginUserDept.current === "ZVP") {
			if(outerTab === "external" && !["Manager", "CEO", "Super Admin","ExternalUser","Mentor"].includes(loginUserRole) && !["deepa.mt@zohocorp.com","nagarajan.c@zohocorp.com"].includes(loginUserEmail.current))
			{				
				continue;
			}
			if(outerTab === "tickets" && !["Manager", "CEO", "Super Admin","ExternalUser","Developer","Mentor"].includes(loginUserRole))
			{
				continue;
			}
			if(outerTab === "admin" && !["Manager", "CEO", "Super Admin"].includes(loginUserRole))
			{
				continue;
			}
			if(outerTab === "reportIssue" && !["Manager", "CEO", "Super Admin","ExternalUser","Developer","Mentor"].includes(loginUserRole))
			{
				continue;
			}
			if(outerTab === "tasks" && !["Manager", "CEO", "Super Admin","ExternalUser","Developer","Mentor"].includes(loginUserRole))
			{
				continue;
			}
			if(outerTab === "build" && !["Manager", "CEO", "Super Admin","ExternalUser","Developer","Mentor"].includes(loginUserRole))
			{
				continue;
			}
			if(outerTab === "incident" && !["Manager", "CEO", "Super Admin","ExternalUser","Developer","Mentor"].includes(loginUserRole))
			{
				continue;
			}
			if(outerTab === "dashboard" &&  !["sharafath.s@zohocorp.com","vijayshankar.a@zohocorp.com","senthil.sr@zohocorp.com"].includes(loginUserEmail.current) )
			{
				continue;
			}
			if(outerTab === "devops" &&  !["sharafath.s@zohocorp.com","vijayshankar.a@zohocorp.com","senthil.sr@zohocorp.com","mariaraj@zohocorp.com"].includes(loginUserEmail.current) )
			{
				continue;
			}
			if(outerTab === "releases" && !["Manager","CEO", "Super Admin","Mentor","Developer"].includes(loginUserRole) && !["deepa.mt@zohocorp.com","nagarajan.c@zohocorp.com"].includes(loginUserEmail.current))
			{
				continue;
			}
			if(outerTab === "reports" && !["Manager", "Mentor", "CEO", "Super Admin"].includes(loginUserRole))
			{
				continue;
			}

			// if(outerTab === "build" && !(betaUser==='Yes'))
			// {
			// 	continue;
			// }

			// if(outerTab === "incident" && !(betaUser==='Yes'))
			// {
			// 	continue;
			// }

			if(outerTab === "admin" && !(betaUser==='Yes') && !(zoneAdmin.current))
			{
				continue;
			}
			if(outerTab === "roundtable" && !(betaUser==='Yes'))
			{
				continue;
			}
			// if(outerTab === "reportIssue" && !(betaUser==='Yes')) 
			// {
			// 	continue;
			// }
	
		
		
			// console.log(loginUserEmail.current);
			// console.log(betaUser);
			
		
			if(outerTab === "roundtable" && (betaUser==="Yes" && 	["mariaraj@zohocorp.com"].includes(loginUserEmail.current)))
			{
						
				continue;
			}
			if(outerTab === "roundtable" && (betaUser==="Yes" && 	["mariaraj@zohocorp.com"].includes(loginUserEmail.current)))
			{
						
				continue;
			}

			if (outerTab == "milestoneAchievement" && !["sharafath.s@zohocorp.com","vijayshankar.a@zohocorp.com","senthil.sr@zohocorp.com","mariaraj@zohocorp.com","jayasuriya.bm@zohocorp.com"].includes(loginUserEmail.current)) {
				continue;
			}
			if(outerTab === "newdcsetup" && !(betaUser==='Yes'))
			{
				continue;
			}
		}         
		else{
			if(outerTab === "external" && !["CEO"].includes(loginUserRole))
			{				
				continue;
			}
			if(outerTab === "tickets" && !["Manager", "CEO", "Super Admin","ExternalUser","Developer","Mentor"].includes(loginUserRole))
			{
				continue;
			}
			if(outerTab === "admin" && ["CEO"].includes(loginUserRole))
			{
				continue;
			}
			if(outerTab === "reportIssue" && !["CEO"].includes(loginUserRole))
			{
				continue;
			}
			if(outerTab === "tasks" && !["Manager", "CEO", "Super Admin","ExternalUser","Developer","Mentor"].includes(loginUserRole))
			{
				continue;
			}
			if(outerTab === "build" && ["CEO"].includes(loginUserRole))
			{
				continue;
			}
			if(outerTab === "incident" && !["CEO"].includes(loginUserRole))
			{
				continue;
			}
			if(outerTab === "dashboard" &&  !["sharafath.s@zohocorp.com","vijayshankar.a@zohocorp.com","senthil.sr@zohocorp.com"].includes(loginUserEmail.current) )
			{
				continue;
			}
			if(outerTab === "devops" &&  !["sharafath.s@zohocorp.com","vijayshankar.a@zohocorp.com","senthil.sr@zohocorp.com","mariaraj@zohocorp.com"].includes(loginUserEmail.current) )
			{
				continue;
			}
			
			if(outerTab === "releases" && ["Manager", "CEO", "Super Admin","ExternalUser","Developer","Mentor"].includes(loginUserRole))
			{
				continue;
			}

			if(outerTab === "reports" && !["CEO"].includes(loginUserRole))
			{
				continue;
			}

			// if(outerTab === "build" && !(betaUser==='Yes'))
			// {
			// 	continue;
			// }

			// if(outerTab === "incident" && !(betaUser==='Yes'))
			// {
			// 	continue;
			// }

			if(outerTab === "admin" && !(betaUser==='Yes') && !(zoneAdmin.current))
			{
				continue;
			}
			if(outerTab === "roundtable" && (betaUser==='Yes'))
			{
				continue;
			}
			// if(outerTab === "reportIssue" && !(betaUser==='Yes')) 
			// {
			// 	continue;
			// }
	
		
		
			// console.log(loginUserEmail.current);
			// console.log(betaUser);
			
		
			if(outerTab === "roundtable" && !(betaUser==="Yes" && 	["mariaraj@zohocorp.com"].includes(loginUserEmail.current)))
			{
				continue;
			}
			

		

			if(outerTab === "roundtable" && !(betaUser==="Yes" && 	["mariaraj@zohocorp.com"].includes(loginUserEmail.current)))
			{		
				continue;
			}

			if (outerTab == "milestoneAchievement" && !["sharafath.s@zohocorp.com","vijayshankar.a@zohocorp.com","senthil.sr@zohocorp.com","mariaraj@zohocorp.com","jayasuriya.bm@zohocorp.com"].includes(loginUserEmail.current)) {
				continue;
			}
			if(outerTab === "newdcsetup" && (betaUser==='Yes'))
			{
				continue;
			}
		}

		
		// if(outerTab === "admin" && betaUser !== "Yes") {
		// 	continue;
		// }
		// if(outerTab === "incident" && betaUser !== "Yes") {
		// 	continue;
		// }
		// if(outerTab === "build" && betaUser !== "Yes") {
		// 	continue;
		// }

		if(["dashboard"].includes(outerTab) || ["milestoneAchievement"].includes(outerTab)) {

			outerTabs.push(<Link className="link-component" draggable={false} to={outerTabSegment} key={outerTabDetails.index}>
				<li
					className={outerTab === selectedOuterTab ? "outer-tab outer-tab-selected" : "outer-tab"}
					style={{ backgroundColor: outerTab === selectedOuterTab ? theme.palette.primary.main : "", margin: 0, marginBottom: 15, userSelect: "none" }}
				>
					<Box sx={{
						color: outerTab === selectedOuterTab ? 'black' : 'white',
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						padding: "3px"
					}}>
						<Tooltip title={outerTabDetails.name} placement="top">
							{iconsList2[ (-1 * outerTabDetails.index) - 1]}
						</Tooltip>
					</Box>
				</li>
			</Link>);

		} else if(["admin"].includes(outerTab) || ["reportIssue"].includes(outerTab)) {
			outerTabsBottom.push(<Link className="link-component" draggable={false} to={outerTabSegment + innerTabSegment} key={outerTabDetails.index}>
				<li
					className={outerTab === selectedOuterTab ? "outer-tab outer-tab-selected" : "outer-tab"}
					style={{ backgroundColor: outerTab === selectedOuterTab ? theme.palette.primary.main : "", userSelect: "none" }}
				>
					<Box sx={{
						color: outerTab === selectedOuterTab ? 'black' : 'white',
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						padding: "3px"
					}} onClick={() => {
						if(outerTab === "reportIssue") {
							captureScreenshot();
						}
					}}>
						 <Tooltip title={outerTabDetails.name} placement="top">
							{iconsList[outerTabDetails.index]}
						 </Tooltip>
					</Box>
				</li>
			</Link>);
		} else {
			if(outerTabDetails.index < 0 ) {
				continue;
			}
			outerTabs.push(<Link className="link-component" draggable={false} to={outerTabSegment + innerTabSegment} key={outerTabDetails.index}>
				<li
					className={outerTab === selectedOuterTab ? "outer-tab outer-tab-selected" : "outer-tab"}
					style={{ backgroundColor: outerTab === selectedOuterTab ? theme.palette.primary.main : "", margin: 0, marginBottom: 15, userSelect: "none" }}
				>
					<Box sx={{
						color: outerTab === selectedOuterTab ? 'black' : 'white',
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						padding: "3px"
					}}>
						 <Tooltip title={outerTabDetails.name} placement="top">
						 	{iconsList[outerTabDetails.index]}
         				 </Tooltip>
						{/* {iconsList[outerTabDetails.index]} */}
					</Box>
				</li>
			</Link>);
		}
	}

	return (
		<Box className="outer-side-bar" sx={{
			display: "flex !important",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "space-between"
		}}>
			<ul style={{ 
				"listStyleType": "none",
				"padding": "0",
				"textAlign": "center",
				margin: 0, 
				marginTop: 10
			}}>
				{outerTabs}
			</ul>
			<ul style={{ 
				"listStyleType": "none",
				"padding": "0",
				"textAlign": "center"
			}}>
				{outerTabsBottom}
			</ul>
		</Box>
	);
}

function InnerSideBar({loginUserRole,loginUserEmail,betaUser}) {
	const theme = useTheme();

	const { tabState } = useContext(TabStateContext);

	const selectedOuterTab = tabState.current.outer;
	const selectedInnerTab = tabState.current.inner[selectedOuterTab];
	
	const isFullTab = TabDetails[selectedOuterTab].index < 0;
	const outerTabSegment = TabDetails[selectedOuterTab].segment;
	const innerTabsList = TabDetails[selectedOuterTab].innerTabsList;

	var innerTabs = [];

	useLayoutEffect(() => {

		if(isFullTab) {
			return;
		}

		const allTabs = document.getElementsByClassName("inner-tab");

		for (const tab of allTabs) {
			tab.addEventListener("mouseenter", handleTabMouseEnter);
			tab.addEventListener("mouseleave", handleTabMouseLeave);
		}

		function handleTabMouseEnter() {
			this.style.transform = "scale(1.1)"; // Scale the tab on hover
			this.style.transition = "transform 0.3s cubic-bezier(.66,.39,.21,.67)"; // Add transition for the scale animation
		}

		function handleTabMouseLeave() {
			this.style.transform = "scale(1)"; // Reset the scale on mouse leave
			this.style.transition = "transform 0.3s cubic-bezier(.66,.39,.21,.67)"; // Add transition for the scale animation
		}
		
	}, [selectedOuterTab]);

	function handleMouseEnter(event) {
		if (event.target.className !== "inner-tab inner-tab-selected") {
			event.target.style.color = theme.palette.primary.main;
		}
	}

	function handleMouseLeave(event) {
		if (event.target.className !== "inner-tab inner-tab-selected") {
			event.target.style.color = "white";
		}
		else {
			event.target.style.color = "black";
		}
	}

	function handleOnClick(event) {
		const allTabs = document.getElementsByClassName("inner-tab");

		for (const tab of allTabs) {
			if (tab !== event.target) {
				tab.style.color = "white";
			}
			else {
				tab.style.color = "black";
			}
		}
	}

	for (const innerTab in innerTabsList) {
		const innerTabDetails = innerTabsList[innerTab];
		const innerTabSegment = innerTabDetails.segment;

		// if(innerTab === "report")
		// {
		// 	continue;
		// }
		if (selectedOuterTab === "tickets" && innerTab === "approval" && !(loginUserRole === "CEO" || loginUserRole === "Super Admin" || loginUserRole === "Manager" || loginUserRole === "Mentor")) {
			continue;
		}

		// if(selectedOuterTab === "tickets" && innerTab === "SupportTimelinePage" && !["sharafath.s@zohocorp.com","vijayshankar.a@zohocorp.com","senthil.sr@zohocorp.com","mariaraj@zohocorp.com","manoj.sakthivel@zohocorp.com","jayasuriya.bm@zohocorp.com"].includes(loginUserEmail.current))
		// {
		// 	continue;
		// }
		if(selectedOuterTab === "tickets" && innerTab === "SupportTimelinePage" && !(loginUserRole === "CEO" || loginUserRole === "Manager" || loginUserRole === "Mentor"))
		{
			continue;
		}
		// loginUserEmail
		// if (selectedOuterTab === "tickets" && innerTab === "approval" && !(loginUserEmail != "deepa.mt@zohocorp.com" )) {
		// 	continue;
		// }

		if(selectedOuterTab === "tasks" && innerTab === "report" && !(loginUserRole === "CEO" || loginUserRole === "Super Admin" || loginUserRole === "Manager" || loginUserRole === "Mentor"))
		{
			continue;
		}
		// if(selectedOuterTab === "tasks" && innerTab === "TimelinePage" && !["sharafath.s@zohocorp.com","vijayshankar.a@zohocorp.com","senthil.sr@zohocorp.com","mariaraj@zohocorp.com","manoj.sakthivel@zohocorp.com","jayasuriya.bm@zohocorp.com"].includes(loginUserEmail.current))
		// {
		// 	continue;
		// }
		if(selectedOuterTab === "tasks" && innerTab === "TimelinePage" && !(loginUserRole === "CEO" || loginUserRole === "Manager" || loginUserRole === "Mentor"))
		{
			continue;
		}
	

		if(selectedOuterTab === "tasks" && innerTab === "roles" && !(loginUserRole === "CEO" || loginUserRole === "Super Admin" || loginUserRole === "Manager" || loginUserRole === "Mentor"))
		{
			continue;
		}

		if(selectedOuterTab === "tasks" && innerTab === "Release Planner" && !(loginUserRole === "CEO" || loginUserRole === "Super Admin" || loginUserRole === "Manager"))
		{
			continue;
		}
		// if(selectedOuterTab === "primary" && innerTab === "quarterly" && !(loginUserRole === "CEO" || loginUserRole === "Super Admin" || loginUserRole === "Manager" || loginUserEmail==="sharafath.s@zohocorp.com"))
		// {
		// 	continue;
		// }

		if(selectedOuterTab === "tasks" && innerTab === "Quarterly" && !(loginUserEmail==="sharafath.s@zohocorp.com" || betaUser==='Yes'))
		{
			continue;
		}

		if(selectedOuterTab === "tasks" && innerTab === "CombinedMilestone" && !(loginUserEmail==="sharafath.s@zohocorp.com" || betaUser==='Yes'))
		{
			continue;
		}

		if(selectedOuterTab === "tickets" && innerTab === "myActiveTickets" && !(betaUser==='Yes'))
		{
			continue;
		}

		if(selectedOuterTab === "tickets" && innerTab === "departmentTickets" && !(loginUserRole === "CEO" || loginUserRole === "Super Admin" || loginUserRole === "Manager"))
		{
			continue;
		}
		
		//if(selectedOuterTab === "newdc" && innerTab === "DCConfiguration" && !(betaUser==='Yes'))

		// if(selectedOuterTab === "reports" && innerTab === "timeline" && !(betaUser==='Yes'))
		// {
		// 	continue;
		// }
		if(selectedOuterTab === "tasks" && innerTab === "timeline" && !(loginUserRole === "CEO" || loginUserRole === "Super Admin" || loginUserRole === "Manager"))
		{
			continue;
		}

		innerTabs.push(<Link className="link-component" to={outerTabSegment + innerTabSegment} draggable={false} key={outerTabSegment + innerTabDetails.index}>
			<li
				className={innerTab === selectedInnerTab ? "inner-tab inner-tab-selected" : "inner-tab"}
				style={{ backgroundColor: innerTab === selectedInnerTab ? theme.palette.primary.main : "" }}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={handleOnClick}
			>
				{innerTabDetails.name}
			</li>
		</Link>);

	}

	return (
		<div className="inner-side-bar">
			<ul style={{ "listStyleType": "none", "padding": "0", "textAlign": "left" }}>
				{innerTabs}
			</ul>
		</div>
	);
}


export default SideBar;

function MilestoneAchievementIcon() {
	return (
		<svg  viewBox="0 0 122.88 106.69" fill='currentColor' height={30} width={30}>
			<path d="M58.61,43.44c.73.38,1.46.72,2.18,1s1.68.68,2.48,1h0c5.09,2,9.27,3.62,10.67,11.4A55.81,55.81,0,0,1,74.33,64h7.09v42.66H61.1V64h3.53c0-1.75-.09-3.61-.27-5.05a5.68,5.68,0,0,0-.17-.84,4.09,4.09,0,0,0-.31-.75l-.08-.15c-.28-.53-.55-1-.76-1.1l0,0c-.38-.14-.87-.3-1.43-.47s-1.39-.4-2.15-.6l-2.52-.61-2.22-.5c-1.1,4.73-2.52,10.16-4.15,15.42-.7,2.28-1.45,4.53-2.22,6.68h3.45v30.65H31.45V76h6.48c1-3.35,2-6.75,3-10.17,1.5-5.37,2.88-10.79,4.11-16.1-.35-.38-.69-.79-1-1.21a12.92,12.92,0,0,1-1-1.66,9,9,0,0,1-.76-2.09,7.14,7.14,0,0,1-.1-2.32l1.63-12.68a4,4,0,0,0-1.75.41h0a5.48,5.48,0,0,0-1.41,1.13c-.46.49-.92,1.08-1.41,1.71l0,0c-.39.49-.79,1-1.29,1.6q-2.74,3.18-5.5,6.34a1.12,1.12,0,0,1-.76.38h0a1.08,1.08,0,0,1-.79-.27c-1.73-1.55-4-3.47-5.54-5.11a1.07,1.07,0,0,1-.31-.72v0a1.12,1.12,0,0,1,.29-.78c1.81-1.89,3.71-4.22,5.42-6.26,1-1.23,1.89-2.35,2.82-3.33a16.33,16.33,0,0,1,3-2.6,13.1,13.1,0,0,1,3.88-1.72h0a21.39,21.39,0,0,1,5.16-.66h.08c1.43-.07,3.11,0,4.5,0l1.74,0c7,0,10.52,3.44,13.23,6.12l0,0c.57.56,1.1,1.08,1.58,1.49a5.83,5.83,0,0,0,1.4.91h0c.25.08.86-.23,1.55-.57l0,0a15,15,0,0,1,1.62-.72c2.36-1.08,4.78-2.39,7.13-3.36A3.41,3.41,0,0,1,80,23.62a4.46,4.46,0,0,1,1.59.47,5.06,5.06,0,0,1,1.85,1.59l0,0a4.84,4.84,0,0,1,.6,1.15v0a5,5,0,0,1-.15,3.56,7.47,7.47,0,0,1-1.36,2.14,1.12,1.12,0,0,1-.2.18.86.86,0,0,1-.19.11C80,33.92,77.81,35,75.61,36A25.53,25.53,0,0,1,71,37.64a13.75,13.75,0,0,1-5.52,0l-.17,0a14.5,14.5,0,0,1-5.76-3.41l-.93,9.24ZM0,106.69V88.05H20.32v18.64Zm94.85,0V53.3H90.14a3,3,0,0,1-2.53-1.19c-1.32-2,.49-4,1.75-5.34C92.9,42.86,101,32.16,102.73,30.1a2.92,2.92,0,0,1,4.57,0c1.81,2.13,10.32,13.23,13.71,17,1.18,1.32,2.63,3.12,1.41,5a3,3,0,0,1-2.53,1.19h-4.71v53.39ZM51,.67A8.65,8.65,0,0,1,54.34,0a8.84,8.84,0,0,1,3.34.66,8.65,8.65,0,0,1,2.83,1.88A8.53,8.53,0,0,1,62.4,5.36a8.74,8.74,0,0,1,.68,3.33A8.84,8.84,0,0,1,62.42,12,8.72,8.72,0,0,1,45.64,8.74a8.75,8.75,0,0,1,2.55-6.18A8.68,8.68,0,0,1,51,.67Z"/>
		</svg>
	);
}