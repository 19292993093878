import { isDateInRange, isDateString, isRoadmap } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    const chartData = [];
    const dataMap = {};
    const roadmapStatusMap = {};

    catalystDB.TaskBreaker.map(item => {
        const dateString = item.CREATEDTIME;


        if(isDateString(dateString) && isDateInRange(dateString, startTime) && isRoadmap(item)) {
            const roadmapStatus = item.TaskStatus;

            if([undefined, null, "", " "].includes(roadmapStatus)) {
                return;
            }

            if(roadmapStatus in roadmapStatusMap) {
                roadmapStatusMap[roadmapStatus] += 1;
            } else {
                roadmapStatusMap[roadmapStatus] = 1;
            }

            if(roadmapStatus in dataMap) {
                dataMap[roadmapStatus].push(item);
            } else {
                dataMap[roadmapStatus] = [item];
            }
        }
    }); 

    const power = {
        "Open": 10,
        "Ongoing": 9,
        "In Progress": 8,
        "On Hold": 7,
        "QRMap": 6,
        "RMap": 5
    };

    var keySet = Object.keys(roadmapStatusMap);

    keySet.sort((a, b) => {
        const power_1 = power[a];
        const power_2 = power[b];

        if(power_1 === undefined || power_2 === undefined) {
            return 0;
        } else if (power_1 > power_2) {
            return -1;
        } else {
            return 1;
        }
    });

    for(const key of keySet) {
        chartData.push([
            key,
            roadmapStatusMap[key]
        ]);
    }
    
    return [chartData, dataMap];
}