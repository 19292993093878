/* eslint-disable react/prop-types */ 
/* esline-disable react/jsx-key */
import React from "react";
import { useEffect, useRef, useState, useContext } from "react";
import { UserContext } from "../../../../../contexts/UserContext";
import { useTheme } from '@mui/material/styles';
import { 
    Box,
    ButtonGroup,
    Skeleton, 
    Tab,
    Grid,
    Paper
} from "@mui/material";
import styled from "@emotion/styled";
import { ReportContext } from "../newReportGeneral/NewReport";
import { getBuildRows, getMilestoneRows, getBRows } from "../reportSection1/ReportSection1DataFetcher"; 

import AirIcon from '@mui/icons-material/Air';
import _default from "@mui/material/styles/identifier";
import { BuildCard } from "./BuildCard";

export function BuildCards() {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            height: "100%",
            boxSizing: "border-box"
        }}>
            <Content />
        </Box>
    );
}

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? 'rgb(23,27,32)' : 'rgb(215, 219, 219, 0.8)',
	...theme.typography.body2,
	padding: theme.spacing(1),
	paddingTop: 0,
	paddingBottom: 0,
    borderRadius: "7px",
	color: theme.palette.text.primary,
	height: "120px",
	justifyContent: "center",
	alignItems: "center",
    marginBottom: "10px"
}));

function Content() {

    const [loaded, setLoaded] = useState(undefined);
    const [rows, setRows] = useState(undefined);
    const theme = useTheme();
    const {teamDetails, loginUserDept} = useContext(UserContext);

    const {
        subjectType, 
        subject, 
        timeRange
    } = useContext(ReportContext);

    useEffect(() => {
        const controller = new AbortController();
        setLoaded(undefined);
        getBuildRows(timeRange, subjectType, subject, teamDetails, loginUserDept).then((response) => {
            if (!controller.signal.aborted) {
                if (response.length !== 0) {
                    setRows(response);
                    setLoaded(true);
                } else {
                    setRows(undefined);
                    setLoaded(false);
                }
            }
        }).catch((error) => {
            if (!controller.signal.aborted) {
                setRows(undefined);
                setLoaded(false);
            }
        })

        return () => {
            controller.abort();
        }
    }, [timeRange, subjectType, subject]);

    if(loaded === undefined) {
        return (
            <Skeleton variant={"rounded"} height={"100%"} width={"100%"} animation="wave" />
        );
    } else if(loaded === false) {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%"
            }}>
                <Box>
                    <AirIcon sx={{
                        fontSize: "6rem",
                        color: theme.palette.mode === "light" ? "rgb(20, 20, 20, 0.06)": "rgb(200, 200, 200, 0.06)"
                    }} />
                </Box>
                <Box sx={{
                    fontSize: "0.7rem",
                    color: theme.palette.mode === "light" ? "rgb(10, 10, 10, 0.3)": "rgb(200, 200, 200, 0.4)"
                }}>
                    Nothing found
                </Box>
            </Box>
        );
    } else {
        return (
            <Grid container spacing={2} >
                {rows.map((buildRow, index) => {
                    return (
                        <Grid item xs={12} key={index} >
                            <Item>
                                <BuildCard buildRow={buildRow} />
                            </Item>
                        </Grid>
                    );
                })}
            </Grid>
        );
    }
}   
