// import React, { useContext, useState, useRef, useEffect } from 'react';
// import { getFromTaskBreaker } from '../myTasks/MyTasksDataFetcher.js';
// import "./Quartely.css";
// import "../roadmap/Roadmap.css";
// import { UserContext } from '../../../contexts/UserContext';
// import { colors, useTheme } from '@mui/material';
// import Button from '@mui/material/Button';
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
// import Table from '@mui/material/Table';
// import { queryDB } from '../../../util/QueryDB';

// const SquareBox = ({ index, tableData, setTableData, droppedData, setDroppedData }) => {
//   const [selectedOption, setSelectedOption] = useState('');
//   const [data, setData] = useState([]);
//   const [showSubmitButton, setShowSubmitButton] = useState(false);
// //   const { teamDetails } = useContext(UserContext);
//   const prevDataRef = useRef([]);
//   const [showOptions, setShowOptions] = useState(true);
//   const [check, setCheck] = useState(false);
//   const [draggedItems, setDraggedItems] = useState([]);
//   const [removedData, setRemovedData] = useState([]);
//   const [originalData, setOriginalData] = useState([]);
//   const theme = useTheme();

 


//   useEffect(() => {
//     prevDataRef.current = data;
//   }, [data]);

//   const handleClick = async (option,item,newOption) => {
//     if (selectedOption === option) {
//       setSelectedOption('');
//       setData([]);
//       setShowOptions(true);
//       return;
//     }
// 	// if (droppedData.length > 0 && selectedOption !== newOption) {
// 	// 	const confirmed = window.confirm(
// 	// 	  "You haven't submitted the data. Are you sure you want to change the option?"
// 	// 	);
  
// 	// 	if (confirmed) {
// 	// 	  setSelectedOption(newOption);
// 	// 	  setData([]);
// 	// 	  setShowOptions(true);
// 	// 	  setTableData([]);
// 	// 	  setDroppedData([]);
// 	// 	}
// 	//   } else {
// 	// 	setSelectedOption(newOption);
// 	// 	setData([]);
// 	// 	setShowOptions(true);
// 	// 	setTableData([]);
// 	// 	setDroppedData([]);
// 	//   }
//     setSelectedOption(option);
//     setShowOptions(false);
//     const result = await getFromTaskBreak(`SELECT * FROM TaskBreaker WHERE ReportName = 'Roadmap_Form_Report' AND TaskStatus != 'Closed' AND Tasklist_created_for_roadmap is null AND Component = '${option}'`);
//     setData(result);
//     setTableData(result);
//   };

//   function getFromTaskBreak(queryString = "SELECT * FROM TaskBreaker AND ReportName='TaskBreaker_Data_Report'")
//   {
// 	  return new Promise((resolve, reject) => 
// 	  {
// 		  const start = global.performance.now();
  
// 		  queryDB(queryString).then((response) => {
// 			  // console.log(response);
// 			  const end = global.performance.now();
// 			  // console.log(`took ${end-start} ms`);
  
// 			  var data = [];
// 			  for(const item of response)
// 			  {
// 				  const row = item.TaskBreaker;
// 				  const result = [
// 					  row.TaskName,
// 					  [undefined, "", " ", null].includes(row.MilestoneType) ? "-" : row.MilestoneType,
// 					  [undefined, "", " ", null].includes(row.Component) ? "-" : row.Component,
// 					  !(["High", "Low", "Medium"].includes(row.TaskPriority)) ? "None" : row.TaskPriority,
// 					  [undefined, "", " ", null].includes(row.MilestoneName) ? "-" : row.MilestoneName,
// 					  [undefined, "", " ", null].includes(row.TaskListName) ? "-" : row.TaskListName,
// 					  [undefined, "", " ", null].includes(row.TaskStatus) ? "-" : row.TaskStatus,
// 					  [undefined, "", " ", null].includes(row.DRI) ? "-" : row.DRI,
// 					  [undefined, "", " ", null].includes(row.TaskUrl) ? "#" : row.TaskUrl,
// 					  [undefined, "", " ", null].includes(row.TaskStartDate) ? "-" : row.TaskStartDate,
// 					  [undefined, "", " ", null].includes(row.TaskDueDate) ? "-" : row.TaskDueDate,
// 					  [undefined, "", " ", null].includes(row.CreatorRowid) ? "-" : row.CreatorRowid,
// 					  [undefined, "", " ", null].includes(row.CreatorRowid) ? "-" : row.CREATEDTIME,
// 					  [undefined, "", " ", null].includes(row.CreatorRowid) ? "-" : row.CREATORID,
// 					  [undefined, "", " ", null].includes(row.CreatorRowid) ? "-" : row.Email,
// 					  [undefined, "", " ", null].includes(row.CreatorRowid) ? "-" : row.MODIFIEDTIME,
// 					  [undefined, "", " ", null].includes(row.CreatorRowid) ? "-" : row.MilestoneID,
// 					  [undefined, "", " ", null].includes(row.CreatorRowid) ? "-" : row.MilestoneName,
// 					  [undefined, "", " ", null].includes(row.CreatorRowid) ? "-" : row.ProjectName,
// 					  [undefined, "", " ", null].includes(row.CreatorRowid) ? "-" : row.ROWID,
// 					  [undefined, "", " ", null].includes(row.CreatorRowid) ? "-" : row.ReportName,
// 					  [undefined, "", " ", null].includes(row.CreatorRowid) ? "-" : row.ReportingTo,
// 					  [undefined, "", " ", null].includes(row.CreatorRowid) ? "-" : row.StatusDetails,
// 					  [undefined, "", " ", null].includes(row.CreatorRowid) ? "-" : row.TaskBreakerRoadmapRowid,
// 					  [undefined, "", " ", null].includes(row.CreatorRowid) ? "-" : row.TaskCompletionDate,
// 					  [undefined, "", " ", null].includes(row.CreatorRowid) ? "-" : row.TaskDescription,
// 					  [undefined, "", " ", null].includes(row.CreatorRowid) ? "-" : row.TaskDueDateExtended,
// 					  [undefined, "", " ", null].includes(row.CreatorRowid) ? "-" : row.TaskID,
// 					  [undefined, "", " ", null].includes(row.CreatorRowid) ? "-" : row.Tasklist_created_for_roadmap,
// 					  [undefined, "", " ", null].includes(row.CreatorRowid) ? "-" : row.TaskListid,
// 					  [undefined, "", " ", null].includes(row.CreatorRowid) ? "-" : row.test,
// 					  row.TaskDescription
// 				  ];
// 				  data.push(result);
// 			  }
// 			  if(data.length === 0)
// 			  {
// 				  reject("none");
// 			  }
// 			  resolve(data);
// 		  }).catch((error) => {
// 			  reject(error);
// 		  })
// 	  });
//   }

//   const sendDataToJava = () => {
//     const javaEndpoint = '/server/quaertelysample';
//     fetch(javaEndpoint, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ data: droppedData }),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//       })
//       .catch((error) => {
//         console.error(error); 
//       });
//   };

//   const options = [
//     "Zone",
//     "AWServer",
//     "PServer",
//     "NIO Framework",
//     "PubSub",
//     "AMServer",
//     "ZVP Media",
//     "ZVP Turn",
//     "Intercomm",
//     "TURN-C",
//     "WPNServer",
//     "DAV",
//     "RTCPlatform",
//     "WSServer",
//     "MediaRouter-C",
//     "Common C",
//     "Network-C",
//     "ZVPInfra",
//     "MSServer",
//     "MPMServer",
//     "ZVP-QA",
//     "MEXServer",
//     "ZVPRTMPServer",
//     "AMS-MediaProcessor",
//     "AMS-Processor",
//     "AMExtConference",
//     "AWS-Framework",
//     "RTCPServer",
//     "ATS-Framework"
//   ];

//   const handleDragStart = (event, item) => {
// 	event.dataTransfer.setData('text/plain', JSON.stringify(item));
// 	setDraggedItems([...draggedItems, item]);
// 	const newTableData = tableData.filter(row => {
// 		if(row === item) {
// 			return false;
// 		} else {
// 			return true;
// 		}
// 	})
// 	setTableData(newTableData);
// };
  
  
// const handleDataMove = (droppedItem) => {
// 	if (index === 0) {
// 	  setDroppedData((prevDroppedData) => [...prevDroppedData, droppedItem]);
// 	  setRemovedData((prevData) => prevData.filter((data) => data !== droppedItem));
// 	  setShowSubmitButton(false);
// 	} else {
// 	  if (!prevDataRef.current.includes(droppedItem) && !droppedData.includes(droppedItem)) {
// 		if (removedData.includes(droppedItem)) {
// 		  setDroppedData((prevDroppedData) => [...prevDroppedData, droppedItem]);
// 		  setRemovedData((prevData) => prevData.filter((data) => data !== droppedItem));
// 		} else {
// 		  setDroppedData((prevDroppedData) => [...prevDroppedData, droppedItem]);
// 		}
// 		setShowSubmitButton(true);
// 		setCheck(true);
// 	  }
// 	}
//   };
  
  
  

//   const handleDrop = (event, items) => {
// 	event.preventDefault();
// 	const droppedItem = JSON.parse(event.dataTransfer.getData('text/plain'));
// 	handleDataMove(droppedItem);
//   };
  
  
//   const handleDragOver = (event) => {
//     event.preventDefault();
//   };

//   const handleDragEnter = (event) => {
//     event.preventDefault();
//   };

//   const handleDragLeave = (event) => {
//     event.preventDefault();
//   };

//   useEffect(() => {
//     prevDataRef.current = data;
//     setOriginalData(data);
//   }, [data]);

//   const handleRemoveItem = (index, item) => {
// 	setDroppedData((prevDroppedData) =>
// 	  prevDroppedData.filter((value, i) => {
// 		if (i === index) {
// 		  setTableData((prevTableData) => [...prevTableData, item]);
// 		}
// 		return i !== index;
// 	  })
// 	);
// 	if(selectedOption==='' && droppedData.length > 1){
// 	  setShowSubmitButton(true);
// 	}
// 	else{
// 		setShowSubmitButton(false);
// 	}
//   };
//   const rowNames = {
// 	'0': 'TaskName',
// 	'7': 'DRI',
// 	'9': 'TaskStartDate',
//   };

  

//   const handleSubmit = () => {
//     sendDataToJava();
//   };

 

//   return (
//     <>
//        <div>
//         {index === 0 && (
//           <div id='Quartely-server'>
//             <Select
//               value={selectedOption}
//               onChange={(e) => handleClick(e.target.value)}
//               style={{
//                 color: theme.palette.background.text,
//                 backgroundColor: theme.palette.background.infoDivInner,
//                 border: '1px solid #ccc',
//                 padding: '8px',
//                 borderRadius: '5px',
//                 cursor: 'pointer',
//               }}
//             >
            
//               {options.map((option, index) => (
//                 <MenuItem key={index} value={option} style={{ textAlign: 'center' }}>
// 				 {option}
// 			    </MenuItem>
//               ))}
//             </Select>
//           </div>
//         )}
//       </div>
//       <div
//         className="Quartely-square-box"
//         onDrop={handleDrop}
//         onDragOver={handleDragOver}
//         onDragEnter={handleDragEnter}
//         onDragLeave={handleDragLeave}
// 		style={{
// 			backgroundColor: theme.palette.background.infoDivInner
// 		}}
//       >
//       {index === 0 && (
//         <div className="drop-zone"  style={{
// 			backgroundColor: theme.palette.background.infoDivInner,
// 			color: theme.palette.background.text
// 		}}>
// 			{selectedOption != '' ? null : (
// 			<>
// 				<h1 className='QuartelyContent'>Please select the server</h1>
// 				<span className='QuartelyButton'>&#8626;</span>
// 			</>
//              )}
//             {selectedOption === '' ? null : (
// 				<Table className='QuaertelyTable table' style={{
// 					backgroundColor: theme.palette.background.infoDivInner
// 				}}>
//                 <thead>
//                 <tr>
//                     {Object.keys(tableData[0] || {}).map((key, columnIndex) => {
//                     if (columnIndex >= 1 && columnIndex <=6 || columnIndex===8 ) {
//                         return null;
//                     }
// 					else if (columnIndex >=10 && columnIndex <= 31) {
//                         return null;
//                     }
//                     return <th key={key} className='key' style={{
// 						backgroundColor:theme.palette.background.infoDivOuter
// 					}}>{rowNames[key] || key}</th>;
//                     })}
//                  </tr>
//                 </thead>
//                 <tbody>
// 					{tableData.map((item, index) => {
// 					// if (draggedItems.includes(item)) {
// 					// 	return null;
// 					// }
// 					return (
// 						<tr key={index} draggable onDragStart={(event) => handleDragStart(event, item)}
// 						sx={{
// 							backgroundColor: theme.palette.background.infoDivOuter + " !important"
// 						}}>
// 						{Object.entries(item).map(([key, value]) => {
//                                if(key>='1' && key<='6' || key ==='8'){
// 								return null;
// 							}
// 							else  if(key>='10' && key<='31'){
// 								return null;
// 							}
// 							else{
// 								return <td key={key} className='value'>{value}</td>;
// 							}
// 						})}
// 						</tr>
// 					);
// 					})}
//                 </tbody>
//             </Table>
//             )}
//         </div>
//         )}
		
//         {index === 1 && (
//   <div className="drop-zone" style={{
//     color: theme.palette.background.text
//   }}>
//     {selectedOption === '' ? null : (
//       <>
//         <h3>Drop Zone</h3>
//       </>
//     )}
//     <Table className='QuaertelyTable table'>
//       <thead>
//         <tr>
//           {Object.keys(droppedData[0] || {}).map((key, columnIndex) => {
//               if(key>='1' && key<='6' || key ==='8'){
// 				return null;
// 			}
// 			else  if(key>='10' && key<='31'){
// 				return null;
// 			}
//             return <th key={key} className='key' style={{
//               backgroundColor: theme.palette.background.infoDivOuter,
//               color: theme.palette.background.text
//             }}>{rowNames[key] || key}</th>;
//           })}
//           {showSubmitButton &&<th style={{ backgroundColor: theme.palette.background.infoDivOuter}}>Remove</th> }
//         </tr>
//       </thead>
//       <tbody>
//         {droppedData.map((item, index) => (
//           <tr key={index}>
//             {Object.entries(item).map(([key, value]) => {
//                if(key>='1' && key<='6' || key ==='8'){
// 				return null;
// 			}
// 			else  if(key>='10' && key<='31'){
// 				return null;
// 			}
//               return <td key={key} className='value' style={{ color: theme.palette.background.text }}>{value}</td>;
//             })}
//            <td>
// 			  <button className='WrongButton' onClick={() => handleRemoveItem(index, item)}>X</button>
// 		   </td>

//           </tr>
//         ))}

//       </tbody>
//     </Table>

//   </div>
// )}

//       </div>
// 			<>
// 				 {showSubmitButton &&
// 				  <Button id='Quaretely-sub' onClick={handleSubmit}   variant='contained' size='small' style={{borderRadius:5}}>Submit</Button>
// 				}
// 			</>

//     </>
//   );
// };

// const Quartely = () => {

//   return (null);
  
// 	const [tableData, setTableData] = useState([]);
// 	const [droppedData, setDroppedData] = useState([]);

//   return (
//     <div className='Quartely page-content'>
//       <SquareBox index={0} tableData={tableData} setTableData={setTableData} droppedData={droppedData} setDroppedData={setDroppedData}/>
//       <SquareBox index={1} tableData={tableData} setTableData={setTableData} droppedData={droppedData} setDroppedData={setDroppedData}/>
//     </div>
//   );
// };

// export default Quartely;


import React, { useState, useEffect } from 'react';
import { queryDB } from '../../../util/QueryDB';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import HexagonIcon from '@mui/icons-material/Hexagon';
import { group_by_order, find_row_span } from '../../../util/GroupingFunctions.js';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { QuarterlyInfo } from './QuarterlyInfo';
import { Skeleton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Button, ButtonGroup, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Link } from 'react-router-dom';
import Roadmap from '../roadmap/Roadmap.js'
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';
import Quarter from './QuarterlyRoadMap.js';
import { CombinedInnerRow1 } from './CombinedInnerRow';
import { UserContext } from '../../../contexts/UserContext';
import { useContext  } from 'react';
import { quarterly_component_get_query_1, quarterly_component_get_query_2, quarterly_component_get_query_3, quarterly_component_get_query_4 } from '../../../catalystDB/queryGenerator.js';

var dataCache = {};
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const MyTable = () => {
  const [valuesData, setValuesData] = useState([]);
  const [moreVisibile, setMoreVisible] = useState(false);
  const [DataCheck , setDataCheck] = useState(undefined);
  const [addAnchor, setAddAnchor] = useState(null);
  const [minimize, setMinimize] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [valuesDataRoad, setValuesDataRoad] = useState([]);
  const [valuesDataExternalRoad, setValuesDataExternalRoad] = useState([]);
  const {allServers, loginUserDept} = useContext(UserContext);
  
  const theme =useTheme();

  useEffect(() => {
    // Fetch data for values dropdown
    const fetchValuesData = async () => {
      try {
        const result = await queryDB(quarterly_component_get_query_1(loginUserDept));

        if(Array.isArray(result) && result.length === 0) {
          setDataCheck(false);
        } else {
          setValuesData(result);
          setDataCheck(true);
        }
      } catch (error) {
        setDataCheck(false);
        console.error(error);
      }
    };

    fetchValuesData();
    // fetchValuesData1();
  }, []);

  useEffect(() => {
    // Fetch data for values dropdown
    const fetchValuesData1 = async () => {
      try {
        setDataCheck(undefined);

        const result = await queryDB(quarterly_component_get_query_2(loginUserDept));
        setValuesDataRoad(result);

        if(Array.isArray(result) && result.length === 0) {
          setDataCheck(false);
        } else {
          setDataCheck(true);
        }
      } catch (error) {
        setDataCheck(false);
        console.error(error);
      }
    };

    fetchValuesData1();
    // fetchValuesData1();
  }, []);

  var dataAsArray1 = valuesDataRoad.map((item) => {
    // if (months.includes(item.QuarterlyRMap.Quareter)) {
    //   item.QuarterlyRMap.Quareter = "Month";
    // } else {
    //   item.QuarterlyRMap.Quareter = "Year";
    // }
    return [
      item.TaskBreaker.TaskName,
      item.TaskBreaker.TaskStatus,
      item.TaskBreaker.TaskPriority,
      item.TaskBreaker.DRI
    ];
  });
  useEffect(() => {
    // Fetch data for values dropdown
    const fetchValuesData2 = async () => {
      try {
        const result = await queryDB(quarterly_component_get_query_3(loginUserDept));
        setValuesDataExternalRoad(result);
        // setDataCheck(true)
      } catch (error) {
        setDataCheck(false);
        console.error(error);
      }
    };

    fetchValuesData2();
    // fetchValuesData1();
  }, []);

  var dataAsArray2 = valuesDataExternalRoad.map((item) => {
    // if (months.includes(item.QuarterlyRMap.Quareter)) {
    //   item.QuarterlyRMap.Quareter = "Month";
    // } else {
    //   item.QuarterlyRMap.Quareter = "Year";
    // }
    return [
      item.RoadmapExternal.Roadmap,
      item.RoadmapExternal.NewRoadMapName,
      item.RoadmapExternal.RequestedBy,
      item.RoadmapExternal.Server,
      item.RoadmapExternal.UserEmail,
      item.RoadmapExternal.RoadmapPriority
    ];
  });

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", "Month"];

  var dataAsArray = valuesData.map((item) => {
    // if (months.includes(item.QuarterlyRMap.Quareter)) {
    //   item.QuarterlyRMap.Quareter = "Month";
    // } else {
    //   item.QuarterlyRMap.Quareter = "Year";
    // }
    return [
      item.QuarterlyRMap.Year,
      item.QuarterlyRMap.RoadMapName,
      item.QuarterlyRMap.Status,
      item.QuarterlyRMap.Quareter,
      item.QuarterlyRMap.Server,
      item.QuarterlyRMap.MilestoneStatus,
      item.QuarterlyRMap.FeatureType,
      item.QuarterlyRMap.ParallelDueDayCount,
      [undefined, "", " ", null].includes(item.QuarterlyRMap.MilestoneStartDate) ? "-" : item.QuarterlyRMap.MilestoneStartDate,
      // item.QuarterlyRMap.MilestoneStartDate,
      item.QuarterlyRMap.MilestoneID,
      item.QuarterlyRMap.ServerName,
      // item.QuarterlyRMap.SequentialDueDayCount
      [undefined, "", " ", null,0].includes(item.QuarterlyRMap.SequentialDueDayCount) ? "-" : item.QuarterlyRMap.SequentialDueDayCount,
      item.QuarterlyRMap.ROWID,
      item.QuarterlyRMap.Quareter,
      item.QuarterlyRMap.Year,
      item.QuarterlyRMap.NewRoadMapName
    ];
  });
 
  dataAsArray.map((item,index) => {
    if (months.includes(dataAsArray[index][3])) {
      dataAsArray[index][3] = "Month";
    } else {
      dataAsArray[index][3] = "Year";
    }
  
  });
  // useEffect(() => {
    //  dataAsArray = valuesData.map((item,index) => {
    //   console.log(item)
    //   console.log(index)
      // if (months.includes(item.QuarterlyRMap.Quareter)) {
      //   item.QuarterlyRMap.Quareter = "Month";
      // } else {
      //   item.QuarterlyRMap.Quareter = "Year";
      // }
    // });
  // }, []);

  // console.log(valuesDataRoad)
  // console.log(dataAsArray1.map((item) => item[0]))

  if(DataCheck === false) {
    return (
      <Box className='page-content-2' sx={{
        fontSize: "2rem",
        color: theme.palette.background.text
      }}>
        No Data Found.
      </Box>
    );
  }

  if(DataCheck === undefined ) {
    return(
     <div className='page-content-7'>
    <div className="mytasks-table">
      {/* <TableContainer component={Paper} className="mytasks-table-container"> */}
        <Table stickyHeader>
          <TableHead>
            <TableRow >
        <TableCell colSpan={7}>
          <Skeleton
            variant="rectangular"
            width={"100%"}
            sx={{ height: "calc(100vh - 180px)" }}
          />
        </TableCell>
      </TableRow>
      </TableHead>
      </Table>
      {/* </TableContainer> */}
      </div>
      </div>);
  }

  var arr = group_by_order(dataAsArray, [4,3], 0);

  const unify_roadmap_map = {};
	arr.map((item, index) => {
		if(item[15] !== "-" && item[15] !== null && item[15] !== undefined && item[15] !== "") {
			if(item[15] in unify_roadmap_map) {
				unify_roadmap_map[item[15]].push(item);
			} else {
				unify_roadmap_map[item[15]] = [item];
			}
		}
	});
  const rowsNew = [];
	for(var item in unify_roadmap_map) {

// console.log(unify_roadmap_map[item][0][2])

    console.log();

		rowsNew.push([
      unify_roadmap_map[item][0][0],
			item,
			"-",
			unify_roadmap_map[item][0][3],
			"Combined",
			unify_roadmap_map[item][0][2],
			"-",
			"-",
			"-",
			null,
			"-",
      "-",
      "-",
      unify_roadmap_map[item][0][13],
      "-",
			item,
			unify_roadmap_map[item]
		]);
	}

  arr.map((item, index) => {
		if(item[15] !== "-" && item[15] !== null && item[15] !== undefined && item[15] in unify_roadmap_map) {
			return;
		} else {
			rowsNew.push(item);
		}
	});

  
  arr = rowsNew;
  const rowSpanInfo = find_row_span(arr, [4]);

  return (
    <>
    <div className='page-content-7'>
       <div className="mytasks-top-bar-left-group" style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				gap: "6px"
			}}>
        <Button variant="contained" size="small" 
				 onClick={() => setIsDialogOpen(true)}

				sx={{
					borderRadius: "100px",
					padding: 0.9,
					paddingRight: 1.2,
					paddingLeft: 1.2,
          marginBottom:"10px"
				}}>
					{
						// minimize ?
						// 	<OpenInFullRoundedIcon sx={{
						// 		animation: `${grow} 1s ease`,
						// 		fontSize: "1.4rem"
						// 	}} />
						// 	:
							<AddIcon sx={{fontSize: "1.4rem"}} />
						

							
					}
          </Button>
          </div>
         
      <div className="mytasks-table" style={{
        display: "flex",
        height: "100%"
      }}>

        <TableContainer sx={{
          maxHeight: "calc(100vh - 80px)"
        }}>
          <Table stickyHeader>
            <TableHead>
              {DataCheck === true && valuesData.length > 0 && ( 
                <TableRow className="mytasks-table-head">
                  <TableCell></TableCell>
                  <TableCell className='milestone-table-grouper-header'/>
                  <TableCell align="center">S.NO.</TableCell>
                  <TableCell align="center">Year</TableCell>
                  <TableCell align="center">Quarter</TableCell>
                  <TableCell align="center">Taskname</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell className='milestone-table-grouper-header'/>
                </TableRow>
              )}
            </TableHead>
            <TableBody>

              {
              DataCheck === false ? (
                <Box className='page-content-2' sx={{
                  fontSize: "2rem",
                  color: theme.palette.background.text
                }}>
                  No Data Found.
                </Box>
              ):
              DataCheck === undefined ? (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      sx={{ height: "calc(100vh - 180px)" }}
                    />
                  </TableCell>
                </TableRow>
              ) : valuesData.length === 0 ? ( // Display "No Data Available" when there is no data
                <TableRow>
                  <TableCell colSpan={7}>
                    <p>No data available</p>
                  </TableCell>
                </TableRow>
              ) : (
                arr.map((row, index) => (
                  <Row key={index} row={row} index={index} valuesData={valuesData} dataAsArray={arr} arr={arr} rowSpanInfo={rowSpanInfo}/>
                ))
              )
              
              }
              <Dialog  fullScreen   TransitionComponent={Transition}  PaperProps={{
                style: {
                  backgroundColor: theme.palette.background.pageContent,
                  boxShadow: "none"
                }
                }}
                sx={{
                overflow:"auto"
                }}
              
                keepMounted open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                  <div style={{ display: "block" }}>
                  <DialogActions style={{ float: "right", padding: "16px 24px 16px 24px" }}>
                    <Button  onClick={() => setIsDialogOpen(false)} variant="text"><CloseIcon fontSize="medium" /></Button>
                  </DialogActions>
                </div>
              
              <DialogContent>
                <Quarter></Quarter>
                {/* <QuartelyRoadMapExternal RoadMapName={dataAsArray1.map((item) => item[0])} RoadMapStatus={dataAsArray1.map((item) => item[1])} RoadMapPriority={dataAsArray1.map((item) => item[2])} RoadMapDri={dataAsArray1.map((item) => item[3])} 
                         RoadmapExternalName ={dataAsArray2.map((item) => item[0])} RoadMapNewName ={dataAsArray1.map((item) => item[1])} Requster={dataAsArray1.map((item) => item[2])} Server={dataAsArray1.map((item) => item[3])} RoadmapExternalDri={dataAsArray1.map((item) => item[4])} RoadmapPriorityExternal={dataAsArray1.map((item) => item[5])}
                /> */}
              </DialogContent>
              </Dialog>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
    </>
    
  );
  
};

function Row({row,index,valuesData,dataAsArray, arr, rowSpanInfo}){
  const [moreVisibile, setMoreVisible] = useState(false);
  const [statusCheck, setStatusCheck] = useState(false);
  const theme = useTheme();
  const [open, setOpen] = React.useState(null);
  const [MilestoneData, setMilestoneData] = useState([]);
  // const theme = useTheme();

  



  useEffect(() => {
    if (dataAsArray1.map((item) => item[5])==="Ongoing" || dataAsArray1.map((item) => item[5])==="In Progress" || dataAsArray1.map((item) => item[5])==="Closed"|| dataAsArray1.map((item) => item[5])==="Cancelled" || dataAsArray1.map((item) => item[5])==="Completed" ||  dataAsArray1.map((item) => item[5]).includes("Open")){
      setStatusCheck(true);
    }
		// fetchValuesData();
		fetchValuesData1();
	}, [statusCheck]);
  useEffect(() => {
   
		// fetchValuesData();
		fetchValuesData1();
	}, []);

  const fetchValuesData1 = async () => {
		try {
			const queryString = quarterly_component_get_query_4(arr, index);
			if(queryString in dataCache) {
				setMilestoneData(dataCache[queryString]);
			} else {
				const result = await queryDB(queryString);
				dataCache[queryString] = result;
				setMilestoneData(result);
			}
			
			//   console.log(result[15].MilestoneName);

		} catch (error) {
			console.error(error);
		}
	};

	var dataAsArray1 = MilestoneData.map((item) => {
		return [
			
			item.TaskDetails.MilestoneName,
			item.TaskDetails.Component,
			item.TaskDetails.DRI,
			[undefined, "", " ", null].includes(item.TaskDetails.MilestoneStartDate) ? "-" : item.TaskDetails.MilestoneStartDate,
			[undefined, "", " ", null].includes(item.TaskDetails.MilestoneDueDate) ? "-" : item.TaskDetails.MilestoneDueDate,
      item.TaskDetails.TasklistStatus
			
		];
	});
  // const extractedValue = dataAsArray1.map((item) => item[5]);

  // console.log( dataAsArray1.map((item) => item[5]))
  

  const handleClick = () => {
    if (open === index) {
			setOpen(null);
		}
  
		else {
			setOpen(index);
		}
  };


  // var dataAsArray = valuesData.map((item) => {
  //   if (months.includes(item.QuarterlyRMap.Quareter)) {
  //     item.QuarterlyRMap.Quareter = "Month";
  //   } else {
  //     item.QuarterlyRMap.Quareter = "Year";
  //   }
  //   return [
  //     item.QuarterlyRMap.Year,
  //     item.QuarterlyRMap.RoadMapName,
  //     item.QuarterlyRMap.Status,
  //     item.QuarterlyRMap.Quareter,
  //     item.QuarterlyRMap.Server,
  //     item.QuarterlyRMap.MilestoneStatus,
  //     item.QuarterlyRMap.FeatureType,
  //     item.QuarterlyRMap.ParallelDueDayCount,
  //     [undefined, "", " ", null].includes(item.QuarterlyRMap.MilestoneStartDate) ? "-" : item.QuarterlyRMap.MilestoneStartDate,
  //     // item.QuarterlyRMap.MilestoneStartDate,
  //     item.QuarterlyRMap.MilestoneID,
  //     item.QuarterlyRMap.ServerName,
  //     // item.QuarterlyRMap.SequentialDueDayCount
  //     [undefined, "", " ", null,0].includes(item.QuarterlyRMap.SequentialDueDayCount) ? "-" : item.QuarterlyRMap.SequentialDueDayCount,
  //     item.QuarterlyRMap.ROWID
  //   ];
  // });
 
  var MonthArr = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var yesarArr =["Q1","Q2","Q3","Q4"]
    // console.log(dataAsArray)
    // console.log(arr[1][3])
    // if (MonthArr.includes(dataAsArray[index][3])) {
    //   dataAsArray[index][3] = "Month";
    // } else {
    //   dataAsArray[index][3] = "Year";
    // }
    // var justNow;
    // if (arr[index].length > 16) {
    //   const result = arr[index][16].map((item, index) =>
    //     item[2].includes("MilestoneCreated") ? justNow = "MilestoneCreated" : ""
    //   );
    
      
    // }
    // console.log(arr[index][16]);
    
// dataAsArray1.map((item) => item[5])
  // var rowSpanInfo = '';
  var temp;
  if (dataAsArray.length !== 0) { 
  <TableCell colSpan={4} style={{ padding: "0px 10px 0px 10px" }} >
  <ListItemButton onClick={handleClick}>
    <ListItemText />
    {open ? <ExpandLess /> : <ExpandMore />}
  </ListItemButton>
 </TableCell>
    

    

    // console.log(arr[index][13])
    if (rowSpanInfo[index][3] !== 0) {
      if(arr[index][3]==='Year'){
      temp = (
        <TableCell align="center"  style={{ backgroundColor:'red',color:'white',width:'6px', padding: 0}} rowSpan={2*rowSpanInfo[index][3]}>
          <div style={{ whiteSpace: "nowrap",transform: 'rotate(-90deg)',width:'20px',padding:0,fontWeight:'10px'}} className='milestone-table-grouper-component'>
            {arr[index][3]}
          </div>
        </TableCell>
      );
    }
    else if(arr[index][3]==='Month'){
      temp = (
      <TableCell align="center"  style={{backgroundColor:'orange',color:'white',width:'6px', padding: 0}} rowSpan={2*rowSpanInfo[index][3]}>
      <div style={{ whiteSpace: "nowrap",transform: 'rotate(-90deg)',width:'20px',fontWeight:'10px'}} className='milestone-table-grouper-component'>
        {arr[index][3]}
      </div>
    </TableCell>
      );
    }
  }
  
    else{
      temp = (<> </>);
    }
  }
  if (rowSpanInfo[index][4] !== 0) {
  var serTemp =( 
    <Tooltip title={arr[index][4]} placement="right-start">
  <TableCell align="center" className={"mytasks-table-grouper-component mytasks-single-row " + arr[index][4]}  rowSpan={2*rowSpanInfo[index][4]}>
  <div style={{ whiteSpace: "nowrap"}} className='milestone-table-grouper-component'>
    {arr[index][4].length > 8 ? arr[index][4].slice(0.8) + "..." : arr[index][4]}
  </div>
</TableCell></Tooltip>)}
else{
  var serTemp = (<></>)
}
if(arr[index][9] === null){

}
else{
  // console.log(arr[index][9])
    var detialsSet = [
      { "Status": "MilestoneCreated", "ROWID": arr[index][12]},
    ];
    
    var datastore = window.catalyst.table;
    var table = datastore.tableId('5781000010268237');
    var updatePromise = table.updateRow(detialsSet);
    updatePromise
    .then((response) => {
      //    console.log(response.content);
    })
    .catch((err) => {
      //    console.log(err);
    });
}

  var status = <HexagonIcon style={{ color: "pink", fontSize: "small" }} />;
  const containsMilestoneCreated =
  arr[index] && arr[index][16] && arr[index][16].some(item => item.includes("MilestoneCreated" || "Not Completed"));

  if(arr[index][16] != null || undefined){
  // arr[index][16].map((innerArray, outerIndex) => (console.log(innerArray)))
 
  }
  var displayValue;
  // console.log(arr[index][5])
  if(arr[index][5] === "Not Completed"){
    displayValue ="Not Completed";
  // const statusValueCheck = statusCheck === true ? (arr[index][5]):(displayValue)
  }
  else if(arr[index][5] === "Completed"){
    displayValue ="Completed";
  // const statusValueCheck = statusCheck === true ? (arr[index][5]):(displayValue)
  }
  else if(arr[index][5] === "Ongoing"){
    displayValue ="Ongoing";
  // const statusValueCheck = statusCheck === true ? (arr[index][5]):(displayValue)
  }
  else{
    displayValue = containsMilestoneCreated ? "MilestoneCreated" : "QRMap";
  }
  // console.log(dataAsArray1.map((item) => item[5]))
  return (
    
    <>
      <TableRow
            key={index}
            hover={true}
            sx={{
              backgroundColor: theme.palette.background.tableRow,
              '&:hover': {
                backgroundColor: theme.palette.background.tableRowHover + " !important"
              }
            }}
            onMouseEnter={() => setMoreVisible(true)}
            onMouseLeave={() => setMoreVisible(false)}
          >
            {serTemp}
            {arr[index][9] === null && arr[index][16] === undefined ? (
              <>
       <TableCell align="center" className='slide-animation' style={{ width: "30px"  }}> </TableCell>
       <TableCell align="center" 	 style={{ width: "2.5vw", maxWidth: "2.5vw" }}>{index + 1}</TableCell>
       <TableCell allign="center" 	 style={{ width: "10vw" }}><Box>{arr[index][0]}</Box></TableCell>
       <TableCell allign="center" 	 style={{ width: "10vw" }}><Box>{arr[index][13]}</Box></TableCell>
       {/* <TableCell style={{ width: "5vw" }}></TableCell> */}

       <TableCell align="center"  >{arr[index][1]}</TableCell>
       {/* <TableCell style={{ width: "5vw" }}></TableCell> */}
       {/* <TableCell style={{ width: "5vw" }}></TableCell> */}
       <TableCell align="center" ><Box> {status}<p>{arr[index][2]}</p></Box></TableCell>

       {temp}
       </>
         )
         :
         (
          <>
           <TableCell align="center" className='slide-animation' style={{ width: "30px"  }}>
            <IconButton
						size="small"
						onClick={handleClick}
					>
						{open === null ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
					</IconButton>
             </TableCell>
               <TableCell align="center" 	onClick={handleClick} style={{ width: "2.5vw", maxWidth: "2.5vw" }}>{index + 1}</TableCell>
               <TableCell allign="center" 	onClick={handleClick} style={{ width: "10vw" }}><Box>{arr[index][0]}</Box></TableCell>
               <TableCell allign="center" 	onClick={handleClick} style={{ width: "10vw" }}><Box>{arr[index][13]}</Box></TableCell>
               {/* {arr[index][6] === "Parallel" ? (<TableCell  align="center"  style={{ width: "15vw" }} onClick={handleClick}><VscChromeMinimize></VscChromeMinimize><VscChromeMinimize></VscChromeMinimize></TableCell>) : (<TableCell  align="center"  style={{ width: "15vw" }} onClick={handleClick}>Type(Sequential) Days count---{arr[index][11]}</TableCell>)} */}
               <TableCell align="center"  onClick={handleClick}>{arr[index][1]}</TableCell>
               {/* <TableCell  align="right" hover={true} style={{ width: "8vw" }} onClick={handleClick}>
                 <Link  style={{textDecoration:"none",color:"inherit"}}>{dataAsArray1.map((item) => item[3])} </Link>
                 / 
                  {dataAsArray1.map((item) => item[4])}</TableCell>  */}
               {/* <TableCell  align="left"  onClick={handleClick}>{dataAsArray1.map((item) => item[4])}</TableCell> */}
               
               <TableCell align="center" onClick={handleClick}>
    <Box>
    {status}
      
      <p>
      {displayValue}
        {/* {statusCheck === true ? dataAsArray1.map((item) => item[5]) : arr[index][5] || ""} */}
      </p>
    </Box>
  </TableCell>
               {/* <TableCell align="center" onClick={handleClick}><Box> {status}<p>{arr[index][2]}</p></Box></TableCell> */}
               {temp}
               </>
             )}
              {/* <TableCell align="center" 	onClick={handleClick} style={{ width: "2.5vw", maxWidth: "2.5vw" }}>{index + 1}</TableCell>
              <TableCell allign="right" 	onClick={handleClick} style={{ width: "10vw" }}><Box>{arr[index][0]}</Box></TableCell>
              <TableCell align="center" 	onClick={handleClick} >{arr[index][1]}</TableCell>
              <TableCell align="center" onClick={handleClick}><Box> {status}<p>{arr[index][2]}</p></Box></TableCell>

             	<Link to={Start Date} target='_blank' style={{
						textDecoration: "none",
						color: "inherit"
					}}
						onMouseEnter={(event) => {
							event.target.style.color = theme.palette.primary.main;
						}}
						onMouseLeave={(event) => {
							event.target.style.color = "inherit";
						}}>
						{{dataAsArray1.map((item) => item[3])}}
					</Link>

              {temp} */}
      </TableRow>

              <TableRow className="info-div-row">
              <TableCell colSpan={6} style={{ padding: "0px 10px 0px 10px",backgroundColor:theme.palette.background.tableRow }}>
              <Collapse in={open !== null} timeout="auto" unmountOnExit>
                {
                  arr[index][16] === undefined ?
                    <QuarterlyInfo milestone_status={arr[index][5]} Feature_type={arr[index][6]} Parallel_DuedayCount={arr[index][7]} Milestone_Startdate={arr[index][8]}  MilestoneID={arr[index][9]} ServerName={arr[index][10]} Sequential_DueDayCount={arr[index][11]} index={index} RoadMapName={arr[index][1]}/>
                    :
                    <CombinedInnerRow1 rows={arr[index][16]} />
                }
              </Collapse>
              </TableCell>
              </TableRow>

   
    </>
    
    
  )  ;

}

export default MyTable;
