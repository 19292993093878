//ignorei18n_start
import React from "react";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { formatDate } from "../Timeline";
 
export default function History({data}) {
    // console.log(data.history);
 
    return (
        <div>
            <Timeline
                sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.2,
                    },
                }}
            >
                {
                    data.history.map((d, i) => {
                        return (
                            <TimelineItem>
                                <TimelineOppositeContent>
                                    <Lefty data={d} />
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot style={{backgroundColor: "#A40606"}}/>
                                    {
                                        i !== data.history.length - 1 ?
                                            <TimelineConnector style={{backgroundColor: "#D98324"}}/>
                                            :
                                            null
                                    }
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Righty data={d} />
                                </TimelineContent>
                            </TimelineItem>
                        );
                    })
                }
            </Timeline>
        </div>
    );
}

function Lefty({data}) {
    return (
        <div style={{
            fontSize: "12px",
            fontWeight: "bold"
        }}>
            {formatDate(data.time)}
        </div>
    );
}

function Righty({data}) {
    return (
        <div style={{
            fontSize: "14px"
        }}>
            {data.dri} 
            {/* todo */}
            <hr />
            {data.notes}
        </div>
    );
}
//ignorei18n_end