import React from "react";
import { Box, Tooltip } from "@mui/material";
import { styled, useTheme } from '@mui/material/styles';

import {COLORS} from './SplitSingle.js';

export function SplitMultiple({data}) {
    console.log(data);
    if(data === undefined || data === null || !Array.isArray(data) || data.length === 0) {
        return null;
    }
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            // justifyContent: "center",
            height: "100%",
            width: "100%"
        }}>
            <TopSection data={data} />
            <BottomSection data={data} />
        </Box>
    );
}


function TopSection({data}) {
    const theme= useTheme();
    var total = 0;
    var comp = 0;

    data.map(server => {
        total += server.total;
        comp += server.completion;
    });

    var completion_percentage = 0;
    if(total >= comp) {
        completion_percentage = ((comp/total) * 100);
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            fontSize: "0.8rem",
            color: theme.palette.background.textSecondary,
            gap: "10px",
            height: "10%",
            padding: "4px"
        }}>
            <Box>
                Overall
            </Box>
            <Box sx={{color: theme.palette.mode === "light" ? "" : "", fontWeight: "bold", fontSize: "1.1rem"}}>
                {completion_percentage.toFixed(1)}%
            </Box>
        </Box>
    );
}


export function BottomSection({data, other}) {
    const theme = useTheme();
    var total = 0;
    var completed = 0;

    data.map(server => {
        total += server.total;
        completed += server.completion;
    });

    var diff = 0;
    if(total >= completed) {
        diff = completed/total;
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "90%",
            padding: "4px"
        }}>
            <Box sx={{
                height: "25px",
                width: "100%",
                backgroundColor: theme.palette.mode === "light" ? "lightgrey" : "#333333aa",
                borderRadius: "4px",
                overflow: "hidden",
                padding: "3px",
                display: "flex",
                flexDirection: "row",
                gap: "1px",
                boxSizing: "border-box"
                // boxShadow: "1px 1px 10px -3px #3d4045"
            }}>
                {
                    data.map((server, index) => {
                        var rad = undefined;
                        if(index === 0) {
                            rad = "first";
                        }
                        if(index === data.length - 1) {
                            rad = "last";
                        }

                        var comp = 0;
                        if(completed >= server.completion) {
                            comp = ((server.completion/completed) * 100 * diff);
                        }
                        const col = COLORS[index%COLORS.length];
                        return (
                            <Tooltip title={
                                other === true ?
                                server.name
                                :
                                server.name + " " + `(${server.completion}/${server.total}) done`
                            }>
                                <Box sx={{
                                    height: "100%",
                                    width: comp + "%",
                                    backgroundColor: theme.palette.mode === "light" ? col.c : col.b,
                                    backgroundImage: `linear-gradient( 95deg, ${col.a} 0%, ${col.b} 50%, ${col.c} 100%)`,
                                    borderRadius: rad === "first" ? "4px 0px 0px 4px" : (rad === "last" ? "0px 4px 4px 0px" : "none"),
                                    cursor: "pointer"
                                }}>
                                </Box>           
                            </Tooltip>
                        );
                    })
                }
            </Box>
        </Box>
    );
}