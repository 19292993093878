import { isDateString, isDateInRange } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    var count = 0;

    catalystDB.TaskDetails.map(item => {
        const dateString = item.TasklistCompletionDate;

        if(isDateString(dateString) && isDateInRange(dateString, startTime)) {
            count++
        }
    }); 

    return {
        "value": count
    };
}