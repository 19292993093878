import React, { useEffect, useState, useContext } from 'react';
import { DashboardContext } from '../../dashboardMain/stateStorage/DashboardStateStorage';
import { useTheme } from '@emotion/react';

import {
    Box
} from '@mui/material';

import { getChartData } from './gridItem_7_dataProcessor';

import { StatCount } from '../../../../../util/components/StatCount/StatCount';

export function GridItem_7() {

    const { getDashboardContext, catalystDB, startTime } = useContext(DashboardContext);
    const { currentSelectedGridItem } = useContext(getDashboardContext());

    const isOpen = currentSelectedGridItem === "GridItem_7";
    const theme = useTheme();

    if(catalystDB === undefined) {
        return null;
    } else if(catalystDB.AllBuildDetails === null) {
        return null;
    }

    const [chartData, units] = getChartData(catalystDB, startTime);

    return (
        <Box sx={{
            height: "100%",
            width: "100%",
            boxSizing: "border-box"
        }}>
            <StatCount data={chartData} units={["", "", ""]} title={"Per Milestone ▸ Build Count"} />
        </Box>
    );

}