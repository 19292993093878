import React, { useState, useRef } from "react";

import { Box } from "@mui/material";
import { styled, useTheme } from '@mui/material/styles';
import { Tooltip } from "@mui/material";
import { SplitSingle } from "./SplitSingle";
import { GetNameAndEmpID } from "../../Loading";
import { SplitMultiple } from "./SplitMultiple";
import { MilestoneView } from "./MilestoneView";

export function TimelineCard({ step, shrink, card_number }) {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            boxSizing: "border-box"
        }}>
            <TopSection step={step} shrink={shrink} />
            {
                shrink ?
                    null
                    :
                    <BottomSection step={step} card_number={card_number} />
            }
        </Box>
    );
}

function TopSection({ step, shrink }) {
    const theme = useTheme();

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            height: shrink ? "100%" : "25%",
            transition: "all 0.3s ease"
        }}>
            {/* num 1 */}
            <Box sx={{
                flexGrow: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                maxWidth: "260px"
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    flexGrow: 1,
                    justifyContent: "flex-start",
                    lineHeight: 1,
                    width: "100%",
                    boxSizing: "border-box",
                    gap:"5px"
                }}>
                    <Box sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "0px 0px 4px 0px",
                        backgroundColor: "orange",
                        backgroundImage: ["Q1", "Q2", "Q3", "Q4"].includes(step.release_type) ?
                            'linear-gradient( 95deg,#a224b3 0%,#b32498 50%,#b32458 100%)'
                            :
                            'linear-gradient( 95deg, #2846c9 0%, #286ec9 50%, #2894c9 100%)',
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        color: "white",
                        fontSize: "0.8rem",
                        fontWeight: "bold"
                    }}>
                        {step.release_type}
                    </Box>
                    <Box sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "0px 0px 4px 0px",
                        backgroundColor: "orange",
                        backgroundImage: ["Internal"].includes(step.TypeOfRoadmap) ?
                            'linear-gradient( 95deg,#a881ff 0%,#b697ff 50%,#d3c0ff 100%)'
                            :
                            'linear-gradient( 95deg, #a3b7ca 0%, #7593af 50%, #476f95 100%)',
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        color: "white",
                        fontSize: "0.8rem",
                        fontWeight: "bold"
                    }}>
                        {step.TypeOfRoadmap}
                    </Box>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center",
                    paddingLeft: "10px",
                    flexGrow: 4,
                    boxSizing: "border-box",
                    justifyContent: "center",
                    lineHeight: 1,
                    width: "100%"
                }}>
                    <Box sx={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        flexDirection: "column",
                        // alignItems: "center",
                        justifyContent: "center",
                        gap: "5px"
                    }}>
                        <Box sx={{
                            fontSize: "0.7rem",
                            color: theme.palette.background.textSecondary,
                            display: "flex",
                            flexDirection: "row"
                        }}>
                            Feature
                        </Box>
                        <Tooltip title={step.feature_name} >
                            <Box sx={{
                                fontSize: "0.8rem",
                                display: "flex",
                                flexDirection: "row",
                                maxWidth: "250px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                cursor: "pointer",
                                overflowY: "scroll"
                            }}>
                                {
                                    step.feature_name.length > 38 ?
                                        step.feature_name.slice(0, 36) + "..."
                                        :
                                        step.feature_name
                                }
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                transform: shrink ? "translateY(6px)" : "translateY(9px)",
                fontSize: "0.6rem",
                color: theme.palette.background.textSecondary,
                flexGrow: 1
            }}>
                ⬥
            </Box>
            {
                step.module_name ?
                    <>
                        {/* num 2 */}
                        <Box sx={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            lineHeight: 1,
                            justifyContent: "center",
                            transform: shrink ? "translateY(6px)" : "translateY(9px)"
                        }}>
                            <Box sx={{
                                display: "flex",
                                width: "100%",
                                height: "100%",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px"
                            }}>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "5px"
                                }}>
                                    <Box style={{
                                        fontSize: "0.7rem",
                                        color: theme.palette.background.textSecondary,
                                        display: "flex",
                                        flexDirection: "row"
                                    }}>
                                        Module
                                    </Box>
                                    <Box style={{
                                        fontSize: "0.8rem",
                                        display: "flex",
                                        flexDirection: "row"
                                    }}>
                                        {step.module_name}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            transform: shrink ? "translateY(6px)" : "translateY(9px)",
                            fontSize: "0.6rem",
                            color: theme.palette.background.textSecondary,
                            flexGrow: 1
                        }}>
                            ⬥
                        </Box>
                    </>
                    :
                    null
            }
            {/* num 3 */}
            {
                step.due_date ?
                    <>
                        <Box sx={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            lineHeight: 1,
                            justifyContent: "center",
                            transform: shrink ? "translateY(6px)" : "translateY(9px)"
                        }}>
                            <Box sx={{
                                display: "flex",
                                width: "100%",
                                height: "100%",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>

                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "5px"
                                }}>
                                    <Box style={{
                                        fontSize: "0.7rem",
                                        color: theme.palette.background.textSecondary,
                                        display: "flex",
                                        flexDirection: "row"
                                    }}>
                                        ETA
                                    </Box>
                                    <Box style={{
                                        fontSize: "0.8rem",
                                        display: "flex",
                                        flexDirection: "row"
                                    }}>
                                        {fmtDate(step.due_date)}
                                    </Box>
                                </Box>


                            </Box>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            transform: shrink ? "translateY(6px)" : "translateY(9px)",
                            fontSize: "0.6rem",
                            color: theme.palette.background.textSecondary,
                            flexGrow: 1
                        }}>
                            ⬥
                        </Box>
                    </>
                    :
                    null
            }
            {/* num 4 */}
            <Box sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                lineHeight: 1,
                transform: shrink ? "translateY(6px)" : "translateY(9px)",
                paddingRight: "5px"
            }}>
                <Box sx={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px"
                }}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px"
                    }}>
                        <Box style={{
                            fontSize: "0.7rem",
                            color: theme.palette.background.textSecondary,
                            display: "flex",
                            flexDirection: "row"
                        }}>
                            Requested By
                        </Box>
                        <Box style={{
                            fontSize: "0.8rem",
                            display: "flex",
                            flexDirection: "row",
                            textTransform: "capitalize"
                        }}>
                            {step.requested_by}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

function BottomSection({ step, card_number }) {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "75%",
            gap: "10px",
            boxSizing: "border-box"
        }}>
            <ServersStats step={step} card_number={card_number} />
            <OverallStats step={step} />
        </Box>
    );
}

function ServersStats({ step, card_number }) {
    return (
        <Box sx={{
            height: "75%",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            overflowY: "scroll",
            padding: "0px 10px",
            boxSizing: "border-box"
        }}>
            {
                step.servers != null && step.servers != undefined && Array.isArray(step.servers) ?
                step.servers.map((server, index) => {
                   return <SingleItem server={server} servers={step.servers} index={index} step={step} card_number={card_number} />
                })
                :
                null
            }
        </Box>
    );
}

function SingleItem({server, index, step, card_number, servers}) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const card_item_ref = useRef();

    const highlight_color = theme.palette.mode === "light" ? "#adadad22" : "#30333855";

    function handleClick(index) {
        card_item_ref.current.style.backgroundColor = highlight_color;
        setTimeout(() => {
            card_item_ref.current.style.backgroundColor = "transparent";
        }, 150);
        setOpen(true);
    }

    return (
        <>
            <Box sx={{
                borderRadius: "5px",
                height: "20%",
                minWidth: step.servers.length <= 3 ? "99.5%" : "49.5%",
                margin: "5px 0px 5px 0px",
                // padding: "10px",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "row",
                overflow: "hidden",
                cursor: "pointer"
            }}
                onClick={() => handleClick(index)}
                ref={card_item_ref}
            >
                <SplitSingle data={server} index={index} />
            </Box>
            <MilestoneView open={open} setOpen={setOpen} servers={servers} milestone_id={server.milestone_id} index={index} />
        </>
    );
}

function OverallStats({ step }) {
    return (
        <Box sx={{
            height: "25%",
            display: "flex",
            flexDirection: "row",
            padding: "0px 10px 10px 10px",
            width: "100%",
            gap: "5px",
            boxSizing: "border-box"
        }}>
            <Box sx={{
                width: "100%",
                boxSizing: "border-box",
                borderRadius: "5px"
            }}>
                <SplitMultiple data={step.servers} />
            </Box>
            <Box sx={{
                width: "100%",
                boxSizing: "border-box",
                borderRadius: "5px",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <GetNameAndEmpID emails={Array.isArray(step?.dris) ? step?.dris?.join(",") : ""} full={true} w={"250px"} />
            </Box>
        </Box>
    );
}

function fmtDate(date) {
    if (!(date instanceof Date)) {
        return "Invalid Date";
    }

    const options = {
        day: "numeric",
        month: "short",
        year: "numeric",
        //   hour: "2-digit",
        //   minute: "2-digit",
        //   second: "2-digit",
        //   hour12: true,
    };

    return date.toLocaleString("en-US", options);
}