/* eslint-disable react/prop-types */
import React, {useEffect, useState, useContext} from 'react';

import {
    Box,
    Button,
    Skeleton,
    useTheme
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import Close from '@mui/icons-material/Close';
import { queryDB } from '../../../../util/QueryDB';

import { extractData } from './BuildStatus';
import { UserContext } from '../../../../contexts/UserContext';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { builds_qa_status_component_get_query_1, builds_qa_status_component_get_query_2 } from '../../../../catalystDB/queryGenerator';


export default function QAStatus({setActiveTab, Server, BuildLabel, dc}) {

    const [loaded, setLoaded] = useState(undefined);
    const [qaResult, setQAResult] = useState(null);
    const {configuration} = useContext(UserContext);
    const dc_with_extra_setups = configuration.current[`build.DC_with_extra_setups`];

    useEffect(() => {
		async function run() {
            try {
                var queryString = builds_qa_status_component_get_query_1(BuildLabel, dc);
                if(dc in dc_with_extra_setups) {
                    queryString = builds_qa_status_component_get_query_2(BuildLabel, dc_with_extra_setups, dc);
                }
                const result = await queryDB(queryString);
                console.log(result);
    
                setQAResult(result[0].QATest.testResult);
                setLoaded(true);
            } catch (error) {
                setQAResult(null);
                setLoaded(false);
            }
		}
		run();
	}, []);

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Box sx={{
                 display: "flex",
                 flexDirection: "row",
                 alignItems: "center",
                 justifyContent: "right",
                 width: "100%"
            }}>
                <Button sx={{
                    borderRadius: "40px"
                }} onClick={() => setActiveTab("BuildStatus")}>
                    <CloseIcon />
                </Button>
            </Box>
                {
                    loaded === false ?
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            --- Nothing found ---
                        </Box>
                        :
                        loaded === undefined ? 
                            <Skeleton component={"Rectangular"} height={"50px"} width={"100%"}/>
                            :
                            <QAComponent qaResult={qaResult}/>
                }
            <Box>

            </Box>
        </Box>
    );
}

function QAComponent({qaResult}) {
    var result = qaResult;

    try {
        result = JSON.parse(qaResult);
        if(result != null) {
            return <NestedTable obj={result} />;
        } else {
            throw new Error("Invalid QA result");
        }
    } catch(e) {
        try {
            result = JSON.parse(qaResult);
            result = JSON.stringify(result, null, 4);
            if(result === 'null') {
                return (
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        --- Nothing found ---
                    </Box>
                );
            }
            return (
                <Box sx={{
                    overflowX: "scroll",
                    width: "80vw"
                }}>
                    <pre>
                        {result}
                    </pre>
                </Box>
            );
        } catch(e) {}
    }

}

function NestedTable({obj}) {

    const theme = useTheme();

    const cell_style = {
        overflow: "hidden",
        padding: "10px 20px",
        border: theme.palette.mode === "light" ? "0.5px solid grey" : "0.5px solid darkgrey",
        borderRadius: "5px",
        whiteSpace: "nowrap",
        maxWidth: "500px",
        overflowX: "scroll",
    };

    const cell_style_key = {
        overflow: "hidden",
        padding: "10px 20px",
        color: theme.palette.mode === "light" ? "navy" : "lightsteelblue",
        borderRadius: "5px",
        border: theme.palette.mode === "light" ? "0.5px solid grey" : "0.5px solid darkgrey",
        whiteSpace: "nowrap",
        maxWidth: "500px",
        overflowX: "scroll"
    };

    return (
        <Box sx={{maxWidth: "100%", overflow: 'scroll', padding: "20px 10px"}}>
            <table style={{border: theme.palette.mode === "light" ? "0.5px solid grey" : "0.5px solid darkgrey", borderCollapse: "collapse", borderRadius: "10px", borderRadius: "5px"}}>
                {
                    Object.keys(obj).map(key => {
                        const value = obj[key];
                        if(typeof value === 'object') {
                            return (
                                <tr>
                                    <td style={cell_style_key}>{key}</td>
                                    <td style={cell_style}><NestedTable obj={value}/></td>
                                </tr>
                            );
                        } else {
                            return (
                                <tr>
                                    <td style={cell_style_key}>{key}</td>
                                    <td style={cell_style}>{
                                        value === true ? 
                                            <CheckCircleRoundedIcon sx={{color: "forestgreen"}} />
                                            :
                                            value === false ?
                                                <CancelRoundedIcon sx={{color: "brown"}}/>
                                            :
                                            value
                                    }</td>
                                </tr>
                            );
                        }
                    })
                }
            </table>
        </Box>
    );
}