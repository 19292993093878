import React, { useState, useContext, useRef, useEffect } from 'react';
import { UserContext } from '../../../../contexts/UserContext';
import Tooltip from '@mui/material/Tooltip';


import {
  Box, 
  Button,
  ButtonGroup,
  Skeleton,
  Select,
  MenuItem,
  Grid,
  Collapse,
  TextField,
  Paper,
  Chip,
  Menu
} from "@mui/material";

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';


import { group_by_order, find_row_span } from '../../../../util/GroupingFunctions.js';

import { useTheme } from '@mui/material';
import { fetchData } from '../Report3/reportdata.js';
import { Construction } from '@mui/icons-material';



export default function RawComponent({startTime, endTime,view,selection,teamDetails,data,milestoneName,setMilestoneName,status,setStatus}) {
    // console.log(data)
    return(
        <>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                overflow: "hidden",
                gap: "5px",
            }} >

                <MilestoneComponent
                    startTime={startTime}
                    endTime={endTime}
                    view={view}
                    selection={selection}
                    teamDetails={teamDetails}
                    milestoneName={milestoneName}
                    setMilestoneName={setMilestoneName}
                    status={status}
                />

            </Box>
        </>
    )
}


function MilestoneComponent({ startTime, endTime,view,selection,teamDetails,milestoneName,setMilestoneName,status }) {
    
    const [loaded, setLoaded] = useState(undefined);
    const [rows,setRows] = useState([]);
    const { loginUserDept } = useContext(UserContext);
    // const [searchText, setSearchText] = useState("");
    var rrows = [];

    async function QueryData() {
        try {
            const result = await fetchData(startTime, endTime, view, selection, teamDetails, status, false ,false,loginUserDept);
            // console.log(result);
            return Promise.resolve(result);
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }

    useEffect(() => {
        QueryData()
            .then((queryResult) => {
                // console.log('Query result - Tasklist data:', queryResult);
                if (queryResult[0].length > 0) {
                    for (var item of queryResult[0]) {
                        var rrow = [];
                        rrow.push([undefined, "", " ", null].includes(item.TaskDetails.MilestoneName) ? "-" : item.TaskDetails.MilestoneName);//no 0
                        rrow.push([undefined, "", " ", null].includes(item.TaskDetails.MilestoneStartDate) ? "-" : item.TaskDetails.MilestoneStartDate);//1
                        rrow.push([undefined, "", " ", null].includes(item.TaskDetails.MilestoneDueDate) ? "-" : item.TaskDetails.MilestoneDueDate);//2
                        rrow.push([undefined, "", " ", null].includes(item.TaskDetails.MilestoneStatus) ? "-" : item.TaskDetails.MilestoneStatus);//3
                        rrow.push([undefined, "", " ", null].includes(item.TaskDetails.MilestoneId) ? "-" : item.TaskDetails.MilestoneId);
                        rrow.push([undefined, "", " ", null].includes(item.TaskDetails.TasklistPriority) ? "-" : item.TaskDetails.TasklistPriority);//5
                        rrows.push(rrow);
                    }
                    setRows(rrows);
                    setLoaded(true);
                } else {
                    setLoaded(false);

                }
            })
            .catch((error) => {
                console.error('Error querying data:', error);
                setLoaded(null);

            });
    }, [selection,status,startTime,endTime]);

    if (loaded === undefined) {
        return (
            <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
        );
    } else if (loaded === null) {
        return (
            "something went wrong..."
        );
    } else if (loaded === false) {
        return (
            <h2 style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                No Data Found :(
              </h2>
        );
    } else {
    return(
        <Box sx={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
            alignItems: "self-start",
            overflowX: "auto"
            // justifyContent: "flex-end"
        }}>
            <MilestoneTable rows={rows} milestoneName={milestoneName} setMilestoneName={setMilestoneName} selection={selection}/>
        </Box>
    )
    }
}

function MilestoneTable({rows,milestoneName,setMilestoneName,selection}) {

    function CompareFunction(a, b) {

        const priorityMap = {
            high: 1,
            medium: 2,
            low: 3,
            none: 4
        };

        const priorityA = a[5] ? a[5].toLowerCase() : ''; // Ensure it's a valid string or empty string
        const priorityB = b[5] ? b[5].toLowerCase() : '';

        const priorityValueA = priorityMap[priorityA] || Number.MAX_VALUE;
        const priorityValueB = priorityMap[priorityB] || Number.MAX_VALUE;


        return priorityValueA - priorityValueB;
    }

    rows = rows.sort(CompareFunction);
    
    var filteredData = rows;
    let rowSpanInfo = [];
    var dataValue = [];

    if (rows.length > 0) {
        filteredData = group_by_order(rows, [5], 0);
        // console.log(filteredData);

        rowSpanInfo = find_row_span(filteredData, [5]);
        // console.log(rowSpanInfo);
    }
    
        return (
            <>
                <div className="external-table">
                    <TableContainer component={Paper} className="external-table-container">
                        <Table stickyHeader >
                            <TableHead>
                                <TableRow className="external-table-head">
                                    <TableCell align="left" className="external-table-grouper-header" />
                                    <TableCell align="center" style={{ width: '5vw' }}>S.No.</TableCell>
                                    <TableCell align="center" style={{ width: '25vw' }}>Milestone Name</TableCell>
                                    <TableCell align="center" style={{ width: '10vw' }}>Due Date</TableCell>
                                    <TableCell align="center" style={{ width: '10vw' }}>Status</TableCell>
                                    <TableCell align="right" className="external-table-grouper-header" />
                                </TableRow>
                            </TableHead>
                            <TableBody id="list-view">
                                {
                                    Array.isArray(filteredData) ?
                                    filteredData.map((row, index) => {
                                            return <MilestoneRow key={index} row={row} index={index} rowSpanInfo={rowSpanInfo} milestoneName={milestoneName} setMilestoneName={setMilestoneName} selection={selection} filteredData={filteredData}/>;
                                        })
                                        :
                                        null
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </>
        );
    
}

function MilestoneRow({row, index , rowSpanInfo,milestoneName,setMilestoneName,selection,filteredData}){
    //console.log(row);

    const theme = useTheme();
    
   
    useEffect(() => {
       setMilestoneName(filteredData[0][0])
   }, [row]);
 
    function handleClick(value) {
        setMilestoneName(value); // Set the MilestoneName state with the clicked value
        //console.log(`Clicked value: ${value}`);
    }
    

    return (
        <>
            <TableRow className="external-table-head">
                {
                    rowSpanInfo[index][5] !== 0 ?
                        <TableCell
                            className={`milestone-table-grouper-component milestone-single-row rotated-text ${row[5]}`}
                            align="center"
                            width={"6px"}
                            rowSpan={2 * rowSpanInfo[index][5]}
                            sx={{
                                fontSize: "0.8rem !important",
                                backgroundColor: row[5] === 'High' ? 'red !important' : row[5] === 'Medium' ? 'Orange !important' : row[5] === 'Low' ? 'lightgreen !important' : 'transparent'
                            }} >
                            {row[5]}
                        </TableCell>
                        :
                        null

                }
                <TableCell onClick={handleClick} align="center">{index + 1}</TableCell>
                <TableCell onClick={() => handleClick(row[0])} align="center" >
                    <Tooltip title={row[0]}>
                        {row[0]}
                    </Tooltip>
                </TableCell>
                <TableCell onClick={() => handleClick(row[0])} align="center" >{row[2]}</TableCell>
                <TableCell onClick={() => handleClick(row[0])} align="center" style={{ color: 'Orange' }}>{row[3]}</TableCell>
                <TableCell align="center"></TableCell>
            </TableRow>

            <TableRow className="info-div-row" sx={{ backgroundColor: theme.palette.background.infoDivOuter + " !important" }}>

            </TableRow>
        </>
    )
}

