/* eslint-disable react/prop-types */

import React, { createContext, useContext, useState } from 'react';
import { UserContext } from '../../../../../contexts/UserContext';

import TaskAltIcon from '@mui/icons-material/TaskAlt';
import BugReportIcon from '@mui/icons-material/BugReport';
import ConstructionIcon from '@mui/icons-material/Construction';

import { Dashboard_1 } from '../../dashboard_1/Dashboard_1';
import { Dashboard_2 } from '../../dashboard_2/Dashboard_2';
import { Dashboard_3 } from '../../dashboard_3/Dashboard_3';

import { Dashboard_1_ContextProvider } from './dashboard_1_StateStorage/Dashboard_1_StateStorage';
import { Dashboard_2_ContextProvider } from './dashboard_2_StateStorage/Dashboard_2_StateStorage';
import { Dashboard_3_ContextProvider } from './dashboard_3_StateStorage/Dashboard_3_StateStorage';

import { Dashboard_1_Context } from './dashboard_1_StateStorage/Dashboard_1_StateStorage';
import { Dashboard_2_Context } from './dashboard_2_StateStorage/Dashboard_2_StateStorage';
import { Dashboard_3_Context } from './dashboard_3_StateStorage/Dashboard_3_StateStorage';

export const DashboardContext = createContext();

export function DashboardContextProvider({ children }) {

    // outer states
    const {userDetails} = useContext(UserContext);

    // confs
    const DASHBOARD_NAMES = [
        "Tasks",
        "Issues",
        "Builds"
    ];
    const DASHBOARD_COMPONENTS_MAP = {
        "Tasks": {
            "component": <Dashboard_1 />,
            "icon": <TaskAltIcon />,
            "context": Dashboard_1_Context
        },
        "Issues": {
            "component": <Dashboard_2 />,
            "icon": <BugReportIcon />,
            "context": Dashboard_2_Context
        },
        "Builds": {
            "component": <Dashboard_3 />,
            "icon": <ConstructionIcon />,
            "context": Dashboard_3_Context
        }
    };

    // dashboard states
    const loginUser = userDetails.current.auth.email_id;
    const [currentDashboard, setCurrentDashboard] = useState(DASHBOARD_NAMES[0]);
    const [catalystDB, setCatalystDB] = useState(undefined);
    const [startTime, setStartTime] = useState("week");

    // setters
    function changeCurrentDashboard(operation) {
        const currentIndex = DASHBOARD_NAMES.indexOf(currentDashboard);
        var newIndex = 0;
        if(operation === "+") {
            newIndex = (currentIndex+1) % DASHBOARD_NAMES.length;
            setCurrentDashboard(DASHBOARD_NAMES[newIndex]);
        } else if(operation === "-") {
            if(currentIndex === 0) {
                newIndex = DASHBOARD_NAMES.length-1;
            } else {
                newIndex = (currentIndex-1);
            }
            setCurrentDashboard(DASHBOARD_NAMES[newIndex]);
        } else {
            if(DASHBOARD_NAMES.includes(operation)) {
                setCurrentDashboard(operation);
            }
        }
    }

    // getters
    function getDashboardComponent() {
        return DASHBOARD_COMPONENTS_MAP[currentDashboard].component;
    }

    function getDashboardContext() {
        return DASHBOARD_COMPONENTS_MAP[currentDashboard].context;
    }

    function getDashboardIcon(dashboard) {
        if(dashboard === undefined) {
            return DASHBOARD_COMPONENTS_MAP[currentDashboard].icon;
        } else {
            return DASHBOARD_COMPONENTS_MAP[dashboard].icon;
        }
    }

    function getAllDashboardNames() {
        const index = DASHBOARD_NAMES.indexOf(currentDashboard);
        const total = DASHBOARD_NAMES.length;
        const half = Math.floor(total / 2);

        const names = [];

        var s = total;
        var e = total-1;
        if(index <= half) {
            s = (index+half+1)%total;
            e = s - 1;
        } else {
            s = index-half;
            e = s - 1;
        }

        for(var i=s; i<total; i++) {
            names.push(DASHBOARD_NAMES[i]);
        }

        for(var i=0; i<=e; i++) {
            names.push(DASHBOARD_NAMES[i]);
        }

        return names;
    }

    // context provider
    return (
        <DashboardContext.Provider value={{
            loginUser,
            currentDashboard,
            changeCurrentDashboard,
            getDashboardComponent,
            getDashboardIcon,
            getAllDashboardNames,
            getDashboardContext,
            catalystDB,
            setCatalystDB,
            startTime,
            setStartTime
        }}>
            <Dashboard_1_ContextProvider>
                <Dashboard_2_ContextProvider>
                    <Dashboard_3_ContextProvider>
                        {children}
                    </Dashboard_3_ContextProvider>
                </Dashboard_2_ContextProvider>
            </Dashboard_1_ContextProvider>
        </DashboardContext.Provider>
    );
}