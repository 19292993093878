import { isDateInRange, isDateString } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    const chartData = [];
    const buildType = {};
    const dataMap = {};

    catalystDB.AllBuildDetails.map(item => {
        const dateString = item.CREATEDTIME;

        if(isDateString(dateString) && isDateInRange(dateString, startTime)) {
            if(item.BuildType in buildType) {
                buildType[item.BuildType]++;
            } else {
                buildType[item.BuildType] = 1;
            }

            if(item.BuildType in dataMap) {
                dataMap[item.BuildType].push(item);
            } else {
                dataMap[item.BuildType] = [item];
            }
        }
    }); 

    const power = {
        "Feature": 1,
        "Fix": 0
    };

    var keySet = Object.keys(buildType);

    keySet.sort((a, b) => {
        const power_1 = power[a];
        const power_2 = power[b];

        if(power_1 === undefined || power_2 === undefined) {
            return 0;
        } else if (power_1 > power_2) {
            return -1;
        } else {
            return 1;
        }
    });

    for(const type of keySet) {
        chartData.push([
            type,
            buildType[type]
        ]);
    }

    return [chartData, dataMap];
}