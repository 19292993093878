/* eslint-disable react/prop-types */
// import React from "react";
import {
  Box, Table, TableCell,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableBody
} from "@mui/material";
import React, { useState, useRef, useEffect, useContext, useLayoutEffect } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Fade } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { createTheme, ThemeProvider } from '@mui/material/styles';


import { useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Button, ButtonGroup } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Skeleton } from '@mui/material';
import CampaignIcon from '@mui/icons-material/Campaign';
import DialogContent from '@mui/material/DialogContent';
import { EmployeeCard, GetNameAndEmpID } from "../../../../util/Loading";
import { getFromTaskBreaker } from './IncidentDataFetcher.js';
import Typography from '@mui/material/Typography';




import Divider from '@mui/material/Divider';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';





import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { UserContext } from "../../../../contexts/UserContext";
import { incident_monitor_get_query_1 } from "../../../../catalystDB/queryGenerator.js";


function Monitor(userdata) {



  const [rows, setRows] = useState([]);
  const [contentLoaded, setContentLoaded] = useState(false);
  const {loginUserDept} = useContext(UserContext);

  // console.log("Monitor component rendered")

  useEffect(() => {
		async function run() {
      try {
        const result = await getFromTaskBreaker(incident_monitor_get_query_1(loginUserDept));
        setRows(result);
        setContentLoaded(true);
      }
      catch (e) {      
        setContentLoaded(null);
      }
		}
		run();
	}, []);

  

  if (contentLoaded === null) {
    return <Fade in={true}><h3 style={{ textAlign: "center" }}>No Incident Found :(</h3></Fade>;
  }
  if (!contentLoaded) {
    //return <div style={{textAlign: "center", transform: "scale(0.6)"}}><SpinningLoadingComponent /></div>;
    return <Skeleton variant="rectangular" width={"100%"} height={250} sx={{
      marginBottom: "10px",
      '&.MuiSkeleton-root': {
        transform: "none",
        animation: "none"
      }

    }} />;
  }



  return (
    <Box sx={{
      padding: "10px",
      paddingLeft: "0px",
      paddingRight: "0px"
    }}>

      <TableContainer component={Paper}>


        <Table>
          <TableHead>
            <TableRow className="tasklist-table-head">

              {/* <TableCell align="center" >Conversation</TableCell> */}
              {/* <TableCell align="center" style={{whiteSpace: "nowrap" }}>Conversation</TableCell> */}

              <TableCell align="center" style={{ overflowWrap: 'break-word', maxWidth: '23ch' }}>
                Conversation
              </TableCell>

            </TableRow>
          </TableHead>

          <TableBody>
            <Row userdata={userdata} />

          </TableBody>
        </Table>

      </TableContainer>
    </Box>
  );
};






function Row({ userdata }) {

  // const gg=data.data.data;


  const firstSplit = userdata.userdata.split("###-->");
  const finalResult = [];


  for (const element of firstSplit) {
    const secondSplit = element.split("###->");
    //   finalResult.push("Conversation :" + secondSplit);

  }


  return (
    <TableCell align="left" style={{ overflowWrap: 'break-word', maxWidth: '23ch', overflowY: 'scroll' }}>
      <div>
        {firstSplit.map((element, index) => {
          const secondSplit = element.split("###->");
          return (
            <div key={index}>
              {secondSplit[0] === "-" ? (
               <div
               style={{
                 display: "flex",
                 justifyContent: "center",
                 alignItems: "center",
                 height: "50px", 
                 fontSize: "18px", 
                 color: "gray"
               }}
             >
               No conversation found
             </div>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    justifyContent: "center",
                    marginBottom: "40px"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      fontWeight: "bold",
                      color: "purple",
                      marginBottom: "3px"
                    }}
                  >
                    {secondSplit[0]}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: "rgba(179, 161, 179, 0.4)",
                      width: "50%",
                      padding: "20px",
                      borderRadius: "0px 15px 15px 15px"
                    }}
                  >
                    {secondSplit[1]}
                  </Box>
                </Box>
              )}
            </div>
          );
        })}
      </div>
    </TableCell>
  );
  
  

}

export default Monitor;
