
/* eslint-disable react/prop-types */
// import React from "react";
import {
  Box, Table, TableCell,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableBody
} from "@mui/material";
import React, { useState, useRef, useEffect, useContext, useLayoutEffect } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Grid from '@mui/material/Grid';
import { Fade } from '@mui/material';

import Menu from '@mui/material/Menu';

import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import { EmployeeCards } from '../../../../util/Loading.js';
import Dialog from '@mui/material/Dialog';

import { useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Button, ButtonGroup } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Skeleton } from '@mui/material';
import CampaignIcon from '@mui/icons-material/Campaign';
import DialogContent from '@mui/material/DialogContent';
import { EmployeeCard, GetNameAndEmpID, GetNameAndEmpIDForIncident } from "../../../../util/Loading";
import { getFromTaskBreaker } from './IncidentDataFetcher.js';

import Divider from '@mui/material/Divider';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';





import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { UserContext } from "../../../../contexts/UserContext.js";
import { incident_history_get_query_1 } from "../../../../catalystDB/queryGenerator.js";

function History(props) {
  const { ClaimedBy, ForwardTo, ResolvedOn } = props;
  const [rows, setRows] = useState([]);
  const [contentLoaded, setContentLoaded] = useState(false);
  const { loginUserDept } = useContext(UserContext);

  useEffect(() => {
		async function run() {
      try {

        const result = await getFromTaskBreaker(incident_history_get_query_1(loginUserDept));
        setRows(result);
        setContentLoaded(true);
  
      }
      catch (e) {
  
        setContentLoaded(null);
      }
		}
		run();
	}, []);


  if (contentLoaded === null) {
    return <Fade in={true}><h3 style={{ textAlign: "center" }}>No Incident Found :(</h3></Fade>;
  }
  if (!contentLoaded) {
    //return <div style={{textAlign: "center", transform: "scale(0.6)"}}><SpinningLoadingComponent /></div>;
    return (<Skeleton variant="rectangular" width={"100%"} height={250} sx={{
      marginBottom: "10px",
      '&.MuiSkeleton-root': {
        transform: "none",
        animation: "none"
      }

    }} />
    );

  }
  return (
    <Box sx={{
      padding: "10px",
      paddingLeft: "0px",
      paddingRight: "0px"
    }}>

      <TableContainer component={Paper}>
        < ProgressBar ClaimedBy={ClaimedBy} ForwardTo={ForwardTo} ResolvedOn={ResolvedOn} />
        <Divider />
      </TableContainer>
    </Box>
  );
};






function ProgressBar(props) {
  const { ClaimedBy, ForwardTo, ResolvedOn } = props;

  const myMap = new Map();
  myMap.set("ClaimedBy", ClaimedBy);








  if (ForwardTo !== "-") {

    var ForwardToArray = [];
    ForwardToArray = ForwardTo.split('###=>').map((str) => str.trim());
    ForwardToArray.push(ForwardTo);

    myMap.set("ForwardTo", ForwardToArray);
  }





  myMap.set("Resolution Given", ResolvedOn);
  const [activeStep, setActiveStep] = useState(null);

  useEffect(() => {
		async function run() {
       // Replace this with your logic to determine the active step.
        // For this example, we're setting it to 2 for demonstration purposes.
        setActiveStep(9);
		}
		run();
	}, []);
  

  if (activeStep === null) {
    return (
      <Skeleton width={"30px"} height={"100%"} component={Box} sx={{
        marginRight: "10px",
        '&.MuiSkeleton-root': {
          transform: "none",
          animation: "none"
        }
      }} />
    );
  }

  const handleSplit = (inputString) => {
    const delimiter = '##==>';
    const splitArray = inputString.split(delimiter);

  };


  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {Array.from(myMap).map(([key, value]) => (
          <Step key={key} >
            <StepLabel>
              <Grid container alignItems="center">
                <Grid style={{ marginRight: '20px', textAlign: 'center' }} >

                  {key}


                </Grid>

                <Grid style={{ textAlign: 'center', marginRight: '20px' }}>

                  -
                </Grid>
                <Grid item style={{ textAlign: 'left', width: "7vw", minWidth: "14vw", wordWrap: "break-word" }}>

                  {/* {key === 'ResolvedOn' ? value :  {key === 'ClaimedBy' ?  <GetNameAndEmpID emails={value} />: <GetNameAndEmpIDForIncident emails={value[0]}  Forwardnotes={value[1]} Forwardtime={value[2]} /> }  } */}
                  {key === 'Resolution Given' ? (
                    value
                  ) : key === 'ClaimedBy' ? (
                    <GetNameAndEmpID emails={value} />
                  ) : (
                    <GetNameAndEmpIDForIncident
                      emails={value[0]}
                      Forwardnotes={value[1]}
                      Forwardtime={value[2]}
                    />
                  )}
                  {/* {key === 'ForwardTo' ?  <GetNameAndEmpIDForIncident emails={value}  Forwardnotes_and_forwardedtime={"firstforward notes ,Second forward notes, third forward notes" } />  : "" } */}
                </Grid>
              </Grid>
              {/* <Divider variant="middle" /> */}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );









}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    width: 3, // Adjust the width to control the thickness of the line
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? "#717171" : '#a0a0a0',
    borderRadius: 1
  }
}));


function Row({ row, index }) {

  return (
    <TableRow>
      <TableCell align="center">
        {index + 1}
      </TableCell>
      <TableCell>
        {row[0]}
      </TableCell>
      <TableCell>
        {row[2]}
      </TableCell>
      <TableCell>
        {row[3]}
      </TableCell>
      <TableCell>
        <EmployeeCard emails={row[4]} />
      </TableCell>

    </TableRow>
  );
}

export default History;
