import { executeQuery, queryDB } from "../../../../../util/QueryDB";
import { findPeopleUnderServer } from "../../../../../util/Loading";
import { findMentees } from "../../../../../util/Loading";
import { report_3_data_fetcher_get_query_13, report_3_data_fetcher_get_query_14 } from "../../../../../catalystDB/queryGenerator";

const cache = {};

export function fetchData(timeRange, subjectType, subject, teamDetails, loginUserDept) {
    var [start, end] = timeRange;
    start = start.toISOString().split("T")[0];
    end = end.toISOString().split("T")[0];
    const key = start+end+subjectType+subject;

    if(key in cache) {
        return Promise.resolve(cache[key]);
    }

    return new Promise((resolve, reject) => {
        const query_1 = getQuery_1(timeRange, subjectType, subject, teamDetails, loginUserDept);
        const query_2 = getQuery_2(timeRange, subjectType, subject, teamDetails, loginUserDept);
        var users = [];

        if(subjectType === "Server") {
            users = findPeopleUnderServer(subject, teamDetails);
        } else if (subjectType === "MentorTree") {
            users= findMentees([subject], teamDetails);
            users.push(subject);
        } else if (subjectType === "Mentor") {
            users.push(subject);
        }

        queryDB(query_1).then(response_1 => {
            // return resolve(response);
            queryDB(query_2).then(response_2 => {
                cache[key] = {
                    "tasks": response_2,
                    "tickets": response_1,
                    "users": users
                };
                return resolve({
                    "tasks": response_2,
                    "tickets": response_1,
                    "users": users
                });
            }).catch(error => {
                return reject(error);
            });
        }).catch(error => {
            return reject(error);
        }) 
    });
}

function getQuery_1(timeRange, subjectType, subject, teamDetails, loginUserDept) {
   return report_3_data_fetcher_get_query_13(timeRange, subjectType, subject, teamDetails, loginUserDept, findMentees, findPeopleUnderServer);
}

function getQuery_2(timeRange, subjectType, subject, teamDetails, loginUserDept) {
    return report_3_data_fetcher_get_query_14(timeRange, subjectType, subject, teamDetails, loginUserDept, findMentees);
}