/* eslint-disable react/prop-types */

import React, { useEffect, useState, useContext } from 'react';
import { DashboardContext } from '../../dashboardMain/stateStorage/DashboardStateStorage';
import { useTheme } from '@emotion/react';

import {
    Box, Collapse
} from '@mui/material';

import { getChartData } from './gridItem_6_dataProcessor';

import { PieChart } from '../../../../../util/components/pieChart/PieChart';
import { PIE_CHART_COLORS_LIGHT, PIE_CHART_COLORS_DARK } from '../../dashboardMain/DashboardUtil';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export function GridItem_6() {

    const { getDashboardContext, catalystDB, startTime } = useContext(DashboardContext);
    const { currentSelectedGridItem } = useContext(getDashboardContext());
    const [moreSectionData, setMoreSectionData] = useState(null);

    const isOpen = currentSelectedGridItem === "GridItem_6";
    const theme = useTheme();

    var colors = PIE_CHART_COLORS_LIGHT;
    if(theme.palette.mode === "dark") {
        colors = PIE_CHART_COLORS_DARK;
    }

    if (catalystDB === undefined) {
        return null;
    } else if (catalystDB.TaskDetails === null) {
        return null;
    }

    const [chartData, dataMap] = getChartData(catalystDB, startTime);

    function clickHandler(e, d) {
        if(!isOpen) {
            return;
        } else {
            const key = d.data[0].value;
            setMoreSectionData(key);
        }
    }

    return (
        <Box sx={{
            height: "100%",
            width: "100%",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Box sx={{
                height: "100%",
                width: "50%",
                display: moreSectionData !== null ? "block" : "none",
                boxSizing: "border-box",
                overflow: "hidden",
                marginLeft: "10px"
            }}>
                <MoreSection key_={moreSectionData} dataMap={dataMap} />
            </Box>
            <Box sx={{
                height: "100%",
                width: moreSectionData !== null ? "50%" : "100%",
                boxSizing: "border-box"
            }}>
                <PieChart data={chartData}
                    title={"Milestone Status"}
                    colors={colors}
                    hallow={isOpen ? 70 : 10}
                    padAngle={isOpen ? 0 : 0.025}
                    open={isOpen}
                    clickHandler={clickHandler}
                    rerender={moreSectionData}
                />
            </Box>
        </Box>
    );

}


function MoreSection({key_, dataMap}) {

    const theme = useTheme();

    const items = dataMap[key_];

    if([undefined, null].includes(items)) {
        return (
            <Box sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "15px",
                boxSizing: "border-box"
            }}>
                <Box sx={{flex: 1, color: theme.palette.background.textSecondary}}>
                    Milestone ⇒ {key_}
                </Box>
                <Box sx={{
                    flex: 1,
                    flexGrow: 1
                }}>
                    Nothing to Show.
                </Box>
            </Box>
        );    
    }

    const componentItems = items.reduce((accumulator, currentItem) => {
        const key = currentItem.Component;
        if(key in accumulator) {
            accumulator[key].push(currentItem);
        } else {
            accumulator[key] = [currentItem];
        }
        return accumulator;
    }, {});

    return (
        <Box sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "15px",
            boxSizing: "border-box"
        }}>
            <Box sx={{flex: 1, color: theme.palette.background.textSecondary, fontSize: "1.5rem", fontWeight: "bold"}}>
                Milestone ⇒ {key_}
            </Box>
            <Box sx={{
                flex: 1,
                flexGrow: 19,
                overflowY: "scroll",
                overflowX: "hidden",
                width: "100%"
            }}>
                {
                    Object.keys(componentItems).sort().map((item, index) => {
                        return (
                            <p style={{marginBottom: "20px"}} key={index}>
                                <ComponentList key_={item} items={componentItems[item]} />
                            </p>
                        );
                    })
                }
            </Box>
        </Box>
    );
}


function ComponentList({key_, items}) {
    const [open, setOpen] = useState(false);

    return (
        <Box>
            <Box sx={{
                display: "flex",
                cursor: "pointer",
                userSelect: "none",
                alignItems: "center"
            }} onClick={() => setOpen(!open)}>
                {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                <span>{key_} ⇒ <b style={{fontSize: "1.2rem", color: "mediumvioletred"}}>{items.length}</b></span>
            </Box>
            <Collapse in={open}>
                <Box sx={{
                    padding: "20px"
                }}>
                    <ol>
                        {
                            items.map((item, index) => {
                                return (
                                    <li style={{ marginBottom: "20px" }} key={index}>
                                        {item.MilestoneName}
                                        <br />
                                    </li>
                                );
                            })
                        }
                    </ol>
                </Box>
            </Collapse>
        </Box>
    );
}