import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../../contexts/UserContext.js';
import {
  Box, 
  Button,
  Select,
  MenuItem,
  Grid,
  Paper
} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import { Skeleton, Collapse } from '@mui/material';

import { queryDB } from '../../../util/QueryDB';
import { estimator_component_get_query_1, estimator_component_get_query_2, estimator_component_get_query_3 } from '../../../catalystDB/queryGenerator.js';

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	paddingTop: 0,
	paddingBottom: 0,
	color: theme.palette.text.primary,
	height: "calc((100vh - 120px))",
	display: "flex",
	// justifyContent: "center",
	alignItems: "center"
}));

export default function Estimator(){
    const [selectedOption, setSelectedOption] = useState("");
    return(
        <>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                overflow: "hidden",
                gap: "5px"
            }} className="page-content-2">
                <Box sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                    alignItems: "center",
                    justifyContent: "center",
                    left: 322,
                    right: 300,
                    top: 3,
                    marginLeft: "auto",
                    marginRight: "auto",
                    color: "white",
                    fontWeight: "bold",
                    cursor: "default",
                    transition: "top .5s"
                }}
                >
                    <ServerList
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        height={"50px"}
                        required={true}
                    />
                </Box>
                <Box sx={{
                    display: "flex",
                    flex: 1,
                    overflowY: "scroll"
                }}>
                    <Grid container spacing={0.5}>
                        <Grid item xs={4} sx={{ paddingTop: "10px !important" }}>
                            <Item>
                                <HighPriorityTasks selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>
                            </Item>
                        </Grid>
                        <Grid item xs={4} sx={{ paddingTop: "10px !important" }}>
                            <Item>
                                <MediumPriorityTasks selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>
                            </Item>
                        </Grid>
                        <Grid item xs={4} sx={{ paddingTop: "10px !important" }}>
                            <Item>
                                <LowPriorityTasks selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>
                            </Item>
                        </Grid>

                    </Grid>
                </Box>
            </Box>
        </>
    )
}

function ServerList({ selectedOption, setSelectedOption, height, required = false }) {
    const [serversList, setServersList] = useState([]);
    const { userDetails, teamDetails } = useContext(UserContext);
    const user_email = userDetails.current.auth.email_id;
    const user_servers = teamDetails.current[user_email].Servers;
    const [clearButton, setClearButton] = useState(false);

    const sortedServersList = [...serversList].sort((a, b) => a.localeCompare(b));

    function handleSelect(event) {
        setSelectedOption(event.target.value);
        setClearButton(true);
    }

    function handleMouseEnter() {
        if (selectedOption === "") {
            return;
        }
        setClearButton(true);
    }

    function handleMouseLeave() {
        if (selectedOption === "") {
            return;
        }
        setClearButton(false);
    }

    function handleClearClick() {
        setSelectedOption("");
        setClearButton(false);
    }

    function handleUnfocus(event) {
        setTimeout(() => setClearButton(false), 200);
    }


    useEffect(() => {
        async function fetchData() {
            try {
                await setServerDropDown(setServersList, userDetails, teamDetails);
            }
            catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, []);

    async function setServerDropDown(setServersList, userDetails, teamDetails) {
        return new Promise((resolve, reject) => {
            const userEmail = userDetails.current.auth.email_id;
            const userRole = teamDetails.current[userEmail].Role;

            if (userRole === "CEO") {
                setServersList(teamDetails.current["mariaraj@zohocorp.com"].Servers.split(","));
            }
            else {
                setServersList(teamDetails.current[userEmail].Servers.split(","));
            }
            return resolve();
        });
    }

    return (
        <Box style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "left"
        }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Select
                required={required}
                //labelId="role-user-email"
                value={selectedOption}
                style={{
                    minHeight: height,
                    height: "auto",
                    backgroundColor: "#202123",
                    color: "white",
                    minWidth: "250px",
                    maxWidth: "400px"
                }}
                MenuProps={{
                    style: {
                        maxHeight: 600
                    }
                }}
                onChange={handleSelect}
                onBlur={handleUnfocus}
            >
                {sortedServersList.map((value, index) => (
                    <MenuItem key={index} value={value}>{value}</MenuItem>
                ))};
            </Select>
            <Button style={{ visibility: clearButton ? "visible" : "hidden" }} onClick={handleClearClick}>
                <CancelIcon fontSize="small" color="error" />
            </Button>
        </Box>
    );
}

function HighPriorityTasks({ selectedOption, setSelectedOption}) {
    console.log(selectedOption);
    const [loaded, setLoaded] = useState(undefined);
    const [rows,setRows] = useState([]);

    var rrows = [];

    async function QueryData() {
        try {
            const result = await queryDB(estimator_component_get_query_1(selectedOption));
            return result;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    useEffect(() => {
        QueryData()
            .then((queryResult) => {
                 console.log('Query result - High Priority:', queryResult);
                if (queryResult.length > 0) {
                    for (var item of queryResult) {
                        var rrow = [];
                        rrow.push(item.TaskBreaker.TaskName);
                        rrows.push(rrow);
                        
                        
                    }
                    setRows(rrows);
                    setLoaded(true);
                } else {
                    setLoaded(false);
                    
                }
            })
            .catch((error) => {
                console.error('Error querying High priority data:', error);
                setLoaded(null);
                
            });
        }, [selectedOption]);

        console.log(rows);

       

    return (
        <div>
        <h3>{selectedOption}</h3>
        {loaded ? (
          <div>
            <h2>High Priority Tasks</h2>
            <ul>
              {rows.map((row, index) => (
                <li key={index}>{row[0]}</li>
              ))}
            </ul>
          </div>
        ) : loaded === false ? (
          <p>No high priority tasks found.</p>
        ) : loaded === null ? (
          <p>Error loading data.</p>
        ) : null}
      </div>
    );
}

function MediumPriorityTasks({ selectedOption, setSelectedOption}) {
    console.log(selectedOption);
    const [loaded, setLoaded] = useState(undefined);
    const [rows,setRows] = useState([]);

    var rrows = [];

    async function QueryData() {
        try {
            const result = await queryDB(estimator_component_get_query_2(selectedOption));
            return result; 
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    useEffect(() => {
        QueryData()
            .then((queryResult) => {
                 console.log('Query result - Medium Priority:', queryResult);
                if (queryResult.length > 0) {
                    for (var item of queryResult) {
                        var rrow = [];
                        rrow.push(item.TaskBreaker.TaskName);
                        rrows.push(rrow);
                        
                        
                    }
                    setRows(rrows);
                    setLoaded(true);
                } else {
                    setLoaded(false);
                    
                }
            })
            .catch((error) => {
                console.error('Error querying Medium Priority data:', error);
                setLoaded(null);
                
            });
        }, [selectedOption]);
    
    return (
        <div>
        <h3>{selectedOption}</h3>
        {loaded ? (
          <div>
            <h2>Medium Priority Tasks</h2>
            <ul>
              {rows.map((row, index) => (
                <li key={index}>{row[0]}</li>
              ))}
            </ul>
          </div>
        ) : loaded === false ? (
          <p>No Medium priority tasks found.</p>
        ) : loaded === null ? (
          <p>Error loading data.</p>
        ) : null}
      </div>
    );
}

function LowPriorityTasks({ selectedOption, setSelectedOption}) {
    console.log(selectedOption);
    const [loaded, setLoaded] = useState(undefined);
    const [rows,setRows] = useState([]);

    var rrows = [];

    async function QueryData() {
        try {
            const result = await queryDB(estimator_component_get_query_3(selectedOption));
            return result; 
        } catch (error) {
            console.log(error);
            throw error; 
        }
    }

    useEffect(() => {
        QueryData()
            .then((queryResult) => {
                 console.log('Query result - Low Priority:', queryResult);
                if (queryResult.length > 0) {
                    for (var item of queryResult) {
                        var rrow = [];
                        rrow.push(item.TaskBreaker.TaskName);
                        rrows.push(rrow);
                        
                        
                    }
                    setRows(rrows);
                    setLoaded(true);
                } else {
                    setLoaded(false);
                    
                }
            })
            .catch((error) => {
                console.error('Error querying Low Priority data:', error);
                setLoaded(null);
                
            });
        }, [selectedOption]);
    return (
        <div>
        <h3>{selectedOption}</h3>
        {loaded ? (
          <div>
            <h2>Low Priority Tasks</h2>
            <ul>
              {rows.map((row, index) => (
                <li key={index}>{row[0]}</li>
              ))}
            </ul>
          </div>
        ) : loaded === false ? (
          <p>No Low priority tasks found.</p>
        ) : loaded === null ? (
          <p>Error loading data.</p>
        ) : null}
      </div>
    );
}