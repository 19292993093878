/* eslint-disable react/prop-types */
import React, { useState, useContext, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { UserContext } from '../../../../contexts/UserContext';
import { ButtonGroup } from '@mui/material';
import { Tooltip } from '@mui/material';
import { Button, Popper } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { EmployeeCard } from '../../../../util/Loading.js';

import { GetNameAndEmpID } from '../../../../util/Loading';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import Fade from '@mui/material/Fade';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import RawComponent from './RawComponent.js';
import { Component } from './Component.js';


const ItemFullHeight = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(3),
	color: theme.palette.text.primary,
	height: "calc((100vh - 200px))",
	display: "flex",
	// justifyContent: "center",
	alignItems: "flex-start",
	overflow: "hidden",

}));

export function Report6() {
	const { allServers,GlobalServer} = useContext(UserContext);
	const minValue = getEpochFromDate("2022-01-01");
	const maxValue = getEpochFromDate(new Date().toDateString("YYYY-mm-dd")) + 2 * 24 * 60 * 60;
	const [selectedTime, setSelectedTime] = useState([maxValue - (90 * 24 * 60 * 60), maxValue]);
	const [timeRange, setTimeRange] = useState("week");
	const [server, setServer] = useState(allServers[0]);
    const [milestoneName,setMilestoneName] = useState("");
    const [status, setStatus] = useState("Completed");
	var SelectedGlobalServer = (GlobalServer === "unknown" || GlobalServer === undefined) ?  allServers[0] : GlobalServer;

	useEffect(() => {
        setServer(SelectedGlobalServer);
   }, [GlobalServer]);
   


	const stepValues = {
		"day": 1 * 60 * 60 * 24,
		"week": 1 * 60 * 60 * 24 * 7,
		"month": 1 * 60 * 60 * 24 * 31,
		"year": 1 * 60 * 60 * 24 * 365
	};
	const [viewSelected, setViewSelected] = useState("ServerView");

	return (
		<Box className="page-content-2">
			<Box style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "column",
				// marginTop: "10px"
				marginBottom: "10px"
			}}>
				<ReportTopBar currentSelect={server} setCurrentSelect={setServer} selectedTime={selectedTime} timeRange={timeRange} setTimeRange={setTimeRange}
					viewSelected={viewSelected} setViewSelected={setViewSelected} setSelectedTime={setSelectedTime} status={status} setStatus={setStatus} SelectedGlobalServer = {SelectedGlobalServer}/>
			</Box>
			<Report6Content startTime={selectedTime[0]} endTime={selectedTime[1]} view={viewSelected} selection={server} timeRange={timeRange} milestoneName={milestoneName} setMilestoneName={setMilestoneName} status={status} setStatus={setStatus} SelectedGlobalServer={SelectedGlobalServer}/>
		</Box>
	);
}

function Report6Content({ startTime, endTime, view, selection, timeRange,milestoneName,setMilestoneName,status,setStatus }) {
    const {teamDetails} = useContext(UserContext);

    // async function data(){
    //     var arr=[];
    //     return await fetchData(startTime, endTime, view, selection, teamDetails, false).then((data) => {
    //         console.log(data);
    //         return Promise.resolve([arr]);
    //     }).catch((e) => {
    //         // console.log(e);
    //         return Promise.reject(e);
    //     })
    // }
    // data();

	return (
		<Box style={{
			height: "calc(100%)",
			width: "100%",
			//backgroundColor: theme.palette.background.infoDivInner,
			borderRadius: "6px",
			overflow: "scroll"
		}}>
			<Grid container spacing={"3px"}>
				<Grid item xs={6}>
					<ItemFullHeight>
                        <RawComponent startTime={startTime} endTime={endTime} view={view} selection={selection} teamDetails={teamDetails} milestoneName={milestoneName} setMilestoneName={setMilestoneName} status={status} />
					</ItemFullHeight>
				</Grid>
				<Grid item xs={6}>
                    <ItemFullHeight>
                        <Component currentSelect={selection} milestone_name={milestoneName}/>
                    </ItemFullHeight>
				</Grid>
			</Grid>
		</Box>
	)
}

function ReportTopBar({ currentSelect, setCurrentSelect, selectedTime, timeRange, setTimeRange, viewSelected, setViewSelected, setSelectedTime,status,setStatus,SelectedGlobalServer}) {
	const [serverAnchor, setServerAnchor] = useState(null);
	const [timeRangeAnchor, setTimeRangeAnchor] = useState(null);
	const { allServers } = useContext(UserContext);
	const { teamDetails } = useContext(UserContext);
	var allUsers = Object.keys(teamDetails.current).sort();
	allUsers = allUsers.filter((item) => { return item !== "" });
	const theme = useTheme();

	function handleServerButtonClose(event, item) {
		setCurrentSelect(item);
		setServerAnchor(null);
	}

	function handleTimeRangeChange(item) {
		setTimeRange(item);
		setTimeRangeAnchor(null);
	}

	return (
		<div className="Report-3-top-bar" style={{
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between",
			width: "98%"
		}} >
			<div style={{
				display: "flex",
				width: "250px",
				justifyContent: "left",
				gap: 25
			}}>
				<div>
					{/* <Tooltip title="select time range">
						<Button variant="contained" size="small" onClick={(event) => setTimeRangeAnchor(event.currentTarget)} sx={{
							textTransform: "none",
							borderRadius: "40px"
						}}>
							{timeRange}<ArrowDropDownIcon />
						</Button>
					</Tooltip> */}
					<Menu
						anchorEl={timeRangeAnchor}
						open={timeRangeAnchor !== null}
						onClose={() => setTimeRangeAnchor(null)}
						sx={{
							maxHeight: "750px"
						}}
					>
						<MenuItem key={0} onClick={() => handleTimeRangeChange("day")}>{"day"}</MenuItem>
						<MenuItem key={0} onClick={() => handleTimeRangeChange("week")}>{"week"}</MenuItem>
						<MenuItem key={0} onClick={() => handleTimeRangeChange("month")}>{"month"}</MenuItem>
						<MenuItem key={0} onClick={() => handleTimeRangeChange("year")}>{"year"}</MenuItem>
					</Menu>
				</div>
				<div style={{
					float: "left",
				}}>
					<ButtonGroup >
						<Button variant={viewSelected === "ServerView" ? "outlined" : "contained"} size="small" onClick={(event) => { setViewSelected("MentorView"); setCurrentSelect(allUsers[0]) }} sx={{
							textTransform: "none",
							borderRadius: "40px"
						}}>
							Mentor view
						</Button>
						<Button variant={viewSelected === "ServerView" ? "contained" : "outlined"} size="small" onClick={(event) => { setViewSelected("ServerView"); setCurrentSelect(allServers[0]) }} sx={{
							textTransform: "none",
							borderRadius: "40px"
						}}>
							Server view
						</Button>
					</ButtonGroup>
				</div>
			</div>
            <div style={{marginRight:"50px",paddingLeft:"10px"}}>
            <ButtonGroup >
                    <Button variant={status === "Completed" ? "outlined" : "contained"}  size="small" onClick={(event) => { setStatus("Not Completed")}} sx={{
                        textTransform: "none",
                        borderRadius: "40px"
                    }}>
                        Ongoing
                    </Button>
                    <Button variant={status === "Completed" ? "contained" : "outlined"} size="small" onClick={(event) => { setStatus("Completed")}} sx={{
                        textTransform: "none",
                        borderRadius: "40px"
                    }}>
                        Completed
                    </Button>
                </ButtonGroup>
            </div>
			<div style={{ display: "flex", textAlign: "center" }}>
				<Box>
					<Box style={{
						color: theme.palette.background.text,
						textShadow: theme.palette.mode === "dark" ? "5px 5px 25px rgb(200, 200, 200, 0.5)" : "5px 5px 25px rgb(2, 2, 2, 0.5)",
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
						gap: "20px"
					}}>
						{/* <TimeRangeIndicators selectedTime={selectedTime} timeRange={timeRange}/> */}
						<TimeStamp timeRange={selectedTime} setTimeRange={setSelectedTime} timeFrame={timeRange} />
					</Box>
				</Box>
			</div>
			<div style={{
				display: "flex",
				width: "350px",
				justifyContent: "right"
			}}>

				<>
					<Tooltip title="select server">
						<Button variant="contained" size="small" onClick={(event) => setServerAnchor(event.currentTarget)} style={{
							textTransform: "none",
							borderRadius: "50px",
							padding: 0
						}}>
							{
								viewSelected === "ServerView" || currentSelect === "Overall" ?
									<Box sx={{
										padding: "10px 10px 10px 10px",
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "center"
									}} >
										{currentSelect}
										{SelectedGlobalServer === allServers[0] ? (<ArrowDropDownIcon />):(<></>)}
									</Box>
									:
									<Box sx={{
										padding: "0px 7px 0px 1px",
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "center"
									}}>
										{/* <GetNameAndEmpID emails={currentSelect} /> */}
										<EmployeeCard emails={currentSelect} />
										<ArrowDropDownIcon />
									</Box>
							}
						</Button>
					</Tooltip>
					<Menu
						anchorEl={serverAnchor}
						open={serverAnchor !== null}
						onClose={() => setServerAnchor(null)}
						sx={{
							maxHeight: "750px"
						}}
					>
						{
							SelectedGlobalServer === allServers[0] ? (
								<MenuItem key={"0-0"} onClick={(event) => handleServerButtonClose(event, "Test")}>{"Test"}</MenuItem>
							): (
								<></>
							)
						}
						{
							viewSelected === "ServerView" ?
								allServers.map((item, index) => {
									return (
										SelectedGlobalServer === allServers[0] ? (
											<MenuItem key={index} onClick={(event) => handleServerButtonClose(event, item)}>{item}</MenuItem>
										):(
											<></>
										)
									
									);
								})
								:
								allUsers.map((item, index) => {
									return <MenuItem key={index} onClick={(event) => handleServerButtonClose(event, item)}>{<GetNameAndEmpID emails={item} />}</MenuItem>
								})
						}
					</Menu>
				</>
			</div>
		</div>
	);
}

function TimeRangeIndicators({ selectedTime, timeRange }) {
	const theme = useTheme();
	if (timeRange === "week") {
		return (
			<>
				{
					selectedTime[0] === selectedTime[1] ?

						getMonthAndWeek(selectedTime[0], theme)

						:
						<>
							{getMonthAndWeek(selectedTime[0], theme)}
							to
							{getMonthAndWeek(selectedTime[1], theme)}
						</>
				}
			</>
		);
	} else if (timeRange === "day") {
		return (
			<>
				{
					selectedTime[0] === selectedTime[1] ?

						getDate(selectedTime[0], theme)

						:
						<>
							{getDate(selectedTime[0], theme)}
							to
							{getDate(selectedTime[1], theme)}
						</>
				}
			</>
		);
	} else if (timeRange === "month") {
		return (
			<>
				{
					selectedTime[0] === selectedTime[1] ?

						getMonthAndYear(selectedTime[0], theme)

						:
						<>
							{getMonthAndYear(selectedTime[0], theme)}
							to
							{getMonthAndYear(selectedTime[1], theme)}
						</>
				}
			</>
		);
	} else if (timeRange === "year") {
		return (
			<>
				{
					selectedTime[0] === selectedTime[1] ?

						getYear(selectedTime[0], theme)

						:
						<>
							{getYear(selectedTime[0], theme)}
							to
							{getYear(selectedTime[1], theme)}
						</>
				}
			</>
		);
	}

}

function TimeStamp({ timeRange, setTimeRange, timeFrame }) {

	const [startTime, endTime] = timeRange.map(item => {
		return new Date(item * 1000);
	});
	const [anchor1, setAnchor1] = useState(null);
	const [anchor2, setAnchor2] = useState(null);

	function handleClick1(event) {
		if (anchor2 !== null) {
			setAnchor2(null);
		}
		if (anchor1 !== null) {
			setAnchor1(null);
		} else {
			setAnchor1(event.currentTarget);
		}
	}

	function handleClick2(event) {
		if (anchor1 !== null) {
			setAnchor1(null);
		}
		if (anchor2 !== null) {
			setAnchor2(null);
		} else {
			setAnchor2(event.currentTarget);
		}
	}

	return (
		<Box sx={{
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			width: "100%",
			flex: 1
		}}>
			<ButtonGroup size="small">
				<Button variant={"contained"} sx={{
					borderRadius: "20px",
					fontSize: "0.7rem",
					textTransform: "none",
					gap: "5px",
					color: "white",
					fontWeight: "800"
				}} color="zohoBlue"
					onClick={handleClick1}
				>
					{formatDate(startTime)}
				</Button>
				<DateSetter date={startTime} setDate={(date) => {
					setTimeRange([getEpoch(date), getEpoch(endTime)]);
				}} open={anchor1 !== null} setAnchor={setAnchor1} anchorEl={anchor1} />
				{
					timeFrame !== "Daily" ?
					<Button variant={"outlined"} sx={{
						borderRadius: "20px",
						fontSize: "0.7rem",
						textTransform: "none",
						gap: "5px",
						fontWeight: "900"
					}} color="zohoBlue">
						to
					</Button>
					:
					null
				}
				{
					timeFrame !== "Daily" ?
							<Button variant={"contained"} sx={{
								borderRadius: "20px",
								fontSize: "0.7rem",
								textTransform: "none",
								gap: "5px",
								color: "white",
								fontWeight: "800"
							}} color="zohoBlue"
								onClick={handleClick2}
							>
								{formatDate(endTime)}
							</Button>
						:
						null
					}

			</ButtonGroup>
			<DateSetter date={endTime} setDate={(date) => {
				setTimeRange([getEpoch(startTime), getEpoch(date)]);
			}} open={anchor2 !== null} setAnchor={setAnchor2} anchorEl={anchor2} />
		</Box>
	);
}

// ####################################################################### Util #####################################################################################

function getEpochFromDate(dateString) {
	var date = new Date(dateString);

	var epochTime = Math.floor(date.getTime() / 1000);

	return epochTime;
}

function getEpoch(date) {

	var epochTime = Math.floor(date.getTime() / 1000);

	return epochTime;
}

function getMonthAndWeek(epochTime, theme) {
	var timestamp = epochTime * 1000;
	var date = new Date(timestamp);

	var monthNames = [
		"January", "February", "March", "April", "May", "June",
		"July", "August", "September", "October", "November", "December"
	];
	var month = monthNames[date.getMonth()];

	var dayOfMonth = date.getDate();
	var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
	var dayDifference = dayOfMonth - firstDayOfMonth.getDate();
	var weekOfMonth = Math.ceil((dayDifference + firstDayOfMonth.getDay() + 1) / 7);
	// if(weekOfMonth === 5) {
	// 	return month + " week " + weekOfMonth + " / " + monthNames[(date.getMonth()+1)%12] + " week 1";
	// }
	return (
		<div style={{
			backgroundColor: theme.palette.mode === "dark" ? "rgb(30, 35, 35, 0.7)" : "rgb(240, 240, 240, 0.7)",
			boxShadow: theme.palette.mode === "dark" ? "1px 1px 10px rgb(96, 125, 138, 0.2)" : "3px 3px 10px rgb(50, 50, 50, 0.4)",
			backdropFilter: "blur(50px)",
			padding: "3px",
			borderRadius: "50px",
			display: "flex",
			flexDirection: "row",
			border: theme.palette.mode === "dark" ? "0.5px solid grey" : "0.5px solid black"
		}}>
			<div style={{
				color: "rgb(228,37,39)",
				paddingLeft: "5px",
				paddingRight: "5px"
			}}>
				{month}
			</div>
			<div style={{
				color: "rgb(8,153,73)",
				borderLeft: theme.palette.mode === "dark" ? "1px solid grey" : "1px solid black",
				borderRight: theme.palette.mode === "dark" ? "1px solid grey" : "1px solid black",
				paddingLeft: "5px",
				paddingRight: "5px"
			}}>
				{"week "}
				{weekOfMonth}
			</div>
			<div style={{
				color: "rgb(34, 109, 180)",
				paddingLeft: "5px",
				paddingRight: "5px"
			}}>
				{date.getFullYear()}
			</div>
		</div>
	);
}

function getDate(epochTime, theme) {
	var timestamp = epochTime * 1000;
	var date = new Date(timestamp);

	var monthNames = [
		"January", "February", "March", "April", "May", "June",
		"July", "August", "September", "October", "November", "December"
	];
	var month = monthNames[date.getMonth()];

	return (
		<div style={{
			backgroundColor: theme.palette.mode === "dark" ? "rgb(30, 35, 35, 0.7)" : "rgb(240, 240, 240, 0.7)",
			boxShadow: theme.palette.mode === "dark" ? "1px 1px 10px rgb(96, 125, 138, 0.2)" : "3px 3px 10px rgb(50, 50, 50, 0.4)",
			backdropFilter: "blur(50px)",
			padding: "3px",
			borderRadius: "50px",
			display: "flex",
			flexDirection: "row",
			border: theme.palette.mode === "dark" ? "0.5px solid grey" : "0.5px solid black"
		}}>
			<div style={{
				color: "rgb(228,37,39)",
				paddingLeft: "5px",
				paddingRight: "5px"
			}}>
				{month}
			</div>
			<div style={{
				color: "rgb(8,153,73)",
				borderLeft: theme.palette.mode === "dark" ? "1px solid grey" : "1px solid black",
				borderRight: theme.palette.mode === "dark" ? "1px solid grey" : "1px solid black",
				paddingLeft: "5px",
				paddingRight: "5px"
			}}>
				{date.getUTCDate()}
			</div>
			<div style={{
				color: "rgb(34, 109, 180)",
				paddingLeft: "5px",
				paddingRight: "5px"
			}}>
				{date.getFullYear()}
			</div>
		</div>
	);
}


function getMonthAndYear(epochTime, theme) {
	var timestamp = epochTime * 1000;
	var date = new Date(timestamp);

	var monthNames = [
		"January", "February", "March", "April", "May", "June",
		"July", "August", "September", "October", "November", "December"
	];
	var month = monthNames[date.getMonth()];

	return (
		<div style={{
			backgroundColor: theme.palette.mode === "dark" ? "rgb(30, 35, 35, 0.7)" : "rgb(240, 240, 240, 0.7)",
			boxShadow: theme.palette.mode === "dark" ? "1px 1px 10px rgb(96, 125, 138, 0.2)" : "3px 3px 10px rgb(50, 50, 50, 0.4)",
			backdropFilter: "blur(50px)",
			padding: "3px",
			borderRadius: "50px",
			display: "flex",
			flexDirection: "row",
			border: theme.palette.mode === "dark" ? "0.5px solid grey" : "0.5px solid black"
		}}>
			<div style={{
				color: "rgb(228,37,39)",
				paddingLeft: "5px",
				paddingRight: "5px"
			}}>
				{month}
			</div>
			<div style={{
				color: "rgb(34, 109, 180)",
				paddingLeft: "5px",
				borderLeft: theme.palette.mode === "dark" ? "1px solid grey" : "1px solid black",
				paddingRight: "5px"
			}}>
				{date.getFullYear()}
			</div>
		</div>
	);
}


function getYear(epochTime, theme) {
	var timestamp = epochTime * 1000;
	var date = new Date(timestamp);

	return (
		<div style={{
			backgroundColor: theme.palette.mode === "dark" ? "rgb(30, 35, 35, 0.7)" : "rgb(240, 240, 240, 0.7)",
			boxShadow: theme.palette.mode === "dark" ? "1px 1px 10px rgb(96, 125, 138, 0.2)" : "3px 3px 10px rgb(50, 50, 50, 0.4)",
			backdropFilter: "blur(50px)",
			padding: "3px",
			borderRadius: "50px",
			display: "flex",
			flexDirection: "row",
			border: theme.palette.mode === "dark" ? "0.5px solid grey" : "0.5px solid black"
		}}>
			<div style={{
				color: "rgb(34, 109, 180)",
				paddingLeft: "5px",
				paddingRight: "5px"
			}}>
				{date.getFullYear()}
			</div>
		</div>
	);
}


function formatDate(date) {
	const options = { year: 'numeric', month: 'short', day: 'numeric' };
	return new Intl.DateTimeFormat('en-US', options).format(date);
}

function DateSetter({ date, setDate, open, setAnchor, anchorEl }) {
	const theme = useTheme();
	const [value, setValue] = useState(dayjs(date));

	return (
		<Popper open={open} anchorEl={anchorEl} modifiers={[
			{
				name: 'flip',
				enabled: false,
				options: {
					altBoundary: false,
					rootBoundary: 'document',
					padding: 0
				}
			},
			{
				name: 'preventOverflow',
				enabled: false,
				options: {
					altAxis: false,
					altBoundary: false,
					tether: true,
					rootBoundary: 'document',
					padding: 0
				}
			}
		]} transition>
			{({ TransitionProps }) => (
				<Fade {...TransitionProps} timeout={350}>
					<Box sx={{
						bgcolor: theme.palette.mode === "light" ? "rgb(250,250,250)" : "rgb(39,43,51)",
						color: theme.palette.background.text,
						borderRadius: "10px",
						marginTop: "10px",
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
						padding: "10px",
						boxShadow: "1px 1px 10px 1px " + (theme.palette.mode === "light" ? "grey" : "black")
					}} >
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								value={value}
								closeOnSelect={false}
								onChange={(newValue) => {
									setValue(newValue);
								}}
								renderInput={(params) => <TextField {...params} />}
							/>
						</LocalizationProvider>
						<Button onClick={() => {
							setAnchor(null);
							setDate(value.toDate());
						}}>
							Done
						</Button>
					</Box>
				</Fade>
			)}
		</Popper>
	);
}