import React, { useContext, useState } from "react";
import { 
    Box, MenuItem, MenuList, Menu 
} from "@mui/material";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { UserContext } from "../contexts/UserContext";
import { EmployeeCard, GetNameAndEmpID } from "./Loading";

export function OtherDRI({onClick}) {
    const {teamDetails, loginUserDept, loginUserEmail} = useContext(UserContext);
    const [anchor, setAnchor] = useState(null);
    const loginUserDetails = teamDetails.current[loginUserEmail.current];
    const zoneAdmin = loginUserDetails.ZoneAdmin === true;
    const CEO = loginUserDetails.Role === "CEO";
    if(!zoneAdmin && !CEO) {
        return null;
    }

    const otherDRIs = Object.keys(teamDetails.current).filter(per => {
        const p = teamDetails.current[per];
        const showedInDepartment = p.ShowedInDepartments === null ? "" : p.ShowedInDepartments;
        return p.ZoneAdmin || showedInDepartment.includes(loginUserDept.current);
    });

    return (
        <>
            <MenuItem onClick={(e) => setAnchor(e.currentTarget)}>
                <ArrowLeftIcon />Others
            </MenuItem>
            <DRIMenu anchor={anchor} setAnchor={setAnchor} list={otherDRIs} onClick={onClick}/>
        </>
    );
}

function DRIMenu({anchor, setAnchor, list, onClick}) {
    return (
        <Menu
            open={anchor !== null}
            anchorEl={anchor}
            transition
            disablePortal
            onClose={() => setAnchor(null)}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
        >
            <MenuList>
                {
                    list.map(item => {
                        return <MenuItem onClick={() => onClick({Email: item})}>
                            <EmployeeCard emails={item} />
                        </MenuItem>
                    })
                }          
            </MenuList>
        </Menu>
    );
}