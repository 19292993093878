import React from "react";
import { Box } from "@mui/material";
import { styled, useTheme } from '@mui/material/styles';
import {Tooltip} from "@mui/material";

export const COLORS = [
    {
        a: "#81c21f",
        b: "#40c21f",
        c: "#06702b"
    },
    {
        a: "#2b60c2",
        b: "#3a43c7",
        c: "#5239c4"
    },
    {
        a: "#c21f8e",
        b: "#c21f60",
        c: "#c21f2d"
    },
    {
        a: "#c21fb2",
        b: "#b21fc2",
        c: "#911fc2"
    },
    
    {
        a: "#c29c1f",
        b: "#c2811f",
        c: "#c24a1f"
    },
    {
        a: "#2bc28a",
        b: "#2bc2b3",
        c: "#2ba6c2"
    }
];

export function SplitSingle({data, index}) {
    const theme = useTheme();

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%"
        }}>
            <TopSection data={data} index={index} />
            <BottomSection data={data} index={index} />
        </Box>
    );
}

function TopSection({data, index}) {
    const theme= useTheme();

    var completion_percentage = 0;
    if(data.total >= data.completion) {
        completion_percentage = ((data.completion/data.total) * 100);
        if (isNaN(completion_percentage)) { // Correct way to check for NaN
            completion_percentage = 0;
        }
    }

    const col = COLORS[index%COLORS.length];

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            fontSize: "0.8rem",
            color: theme.palette.background.textSecondary,
            gap: "10px",
            height: "20%",
            padding: "4px"
        }}>
            <Box>
                {data.name}
            </Box>
            <Box sx={{color: theme.palette.mode === "light" ? col.c : col.a, fontWeight: "bold", fontSize: "1.1rem"}}>
                {completion_percentage.toFixed(1)}%
            </Box>
        </Box>
    );
}

function BottomSection({data, index}) {
    const theme = useTheme();

    var completion_percentage = 0;
    if(data.total >= data.completion) {
        completion_percentage = ((data.completion/data.total) * 100);
        if (isNaN(completion_percentage)) { // Correct way to check for NaN
            completion_percentage = 0;
        }
    }

    const col = COLORS[index%COLORS.length];

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "90%",
            padding: "4px"
        }}>
            <Box sx={{
                height: "6px",
                width: "100%",
                backgroundColor: theme.palette.mode === "light" ? "lightgrey" : "#333333aa",
                borderRadius: "100px",
                overflow: "hidden",
                padding: "1px"
                // boxShadow: "1px 1px 10px -3px #3d4045"
            }}>
                 <Tooltip title={data.name + " " + `(${data.completion}/${data.total}) done`}>
                    <Box sx={{
                        height: "100%",
                        width: completion_percentage + "%",
                        backgroundColor: "forestgreen",
                        backgroundImage: `linear-gradient( 95deg, ${col.a} 0%, ${col.b} 50%, ${col.c} 100%)`,
                        borderRadius: "100px",
                        cursor: "pointer"
                    }}>
                    </Box>
                 </Tooltip>
            </Box>
        </Box>
    );
}