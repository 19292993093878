/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";

import { 
    Dialog,
    Box,
    Divider,
    CircularProgress,
    Button
} from "@mui/material";
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import styles from "../TicketsTable.module.css";

export function DeclareAsIncident({data, setExtraComps, setAnchor, setMoreIconVisible, setEventFeedbackQueue}) {

    const [loaded, setLoaded] = useState(false);
    // const URL = `https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/Incident_Form?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true&Ticket_id=${data.DeskTicketNumber}`;
    const URL = `https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/Incident_Form?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true&Ticket_Desk_ID=${data.DeskTicketNumber}&Type_field=Ticket`;

    setAnchor(null);
    setMoreIconVisible(null);

    function sendNoteMessage() {
        const message = `Issue will be declared as incident if submitted.`;
        setEventFeedbackQueue(queue => {
            return [...queue, {
                message: message,
                auto_hide: false,
                loading: false
            }]
        });
        setTimeout(() => {
            setEventFeedbackQueue(queue => {
                return queue.filter(q => q.message !== message);
            });
        }, 3000);
    }

    function handleClose() {
        setExtraComps(null);
        sendNoteMessage();
    }

    function handleKeyDown(event) {
        console.log(event);
        if (event.keyCode === "Escape") {
            setExtraComps(null);
            sendNoteMessage();
        }
    }

    return (
        <Dialog onClose={handleClose} onKeyDown={handleKeyDown} open={true} fullWidth>
            <Box className={styles.flex_row_space_between}>
                <Box className={styles.declare_as_incident_note_container}>
                    <ErrorOutlineRoundedIcon fontSize="large"/>
                    <Box className={styles.flex_column_left}>
                        <span>Submiting this form will create an Incident</span>
                    </Box>
                </Box>
                <Button sx={{borderRadius: "10px"}} onClick={handleClose}>
                    <CloseRoundedIcon fontSize="large" />
                </Button>
            </Box>
            <Divider />
            <Box className={styles.declare_as_incident_iframe_container} >
                {
                    loaded ?
                        null
                        :
                        <Box  sx={{width: "96.5%", zIndex: 1000, display: "flex", height: "660px !important", position: "absolute", alignItems: "center", justifyContent: "center"}}>
                            <CircularProgress />
                        </Box>
                }
                <iframe src={URL} key={data.ID} className={styles.delcare_as_incident_iframe} height={"100%"} width={"100%"} onLoad={() => setLoaded(true)}/>
            </Box>
            <Divider />
        </Dialog>
    );
}