import React, { useState, useEffect, useContext } from 'react';
import { queryDB } from '../../../util/QueryDB';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import HexagonIcon from '@mui/icons-material/Hexagon';
import { group_by_order, find_row_span } from '../../../util/GroupingFunctions.js';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CombinedInfo from './../../pageContent/CombinedMilestone/CombinedInfo'
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import { GetNameAndEmpID, days_between } from '../../../util/Loading.js';
import { UserContext } from '../../../contexts/UserContext';
import { combined_milestone_get_query_1, combined_milestone_get_query_2 } from '../../../catalystDB/queryGenerator.js';


var dataCache = {};

const MyTable = () => {
  const [valuesData, setValuesData] = useState([]);
  const [DataCheck , setDataCheck] = useState(undefined);
  const theme = useTheme();
  const {loginUserDept} = useContext(UserContext);
  

  useEffect(() => {

    const fetchValuesData = async () => {
      try {
        const query = combined_milestone_get_query_1(loginUserDept);
        const result = await queryDB(query);
        setValuesData(result);
        if(Array.isArray(result) && result.length === 0) {
          setDataCheck(false);
        } else {
          setDataCheck(true)
        }
      } catch (error) {
        setDataCheck(false);
      }
    };

    fetchValuesData();
  }, []);


  var dataAsArray = valuesData.map((item) => {
    return [
      item.TaskDetails.MilestoneId,
      item.TaskDetails.MilestoneName,
      [undefined, "", " ", null].includes(item.TaskDetails.TasklistDescription) ? "-" : item.TaskDetails.TasklistDescription,
      item.TaskDetails.TasklistName,
      item.TaskDetails.Component,
      item.TaskDetails.MilestoneStatus,
      [undefined, "", " ", null,0].includes(item.TaskDetails.TasklistStatus) ? "-" : item.TaskDetails.TasklistStatus,
      // item.TaskDetails.TasklistStatus,
      item.TaskDetails.Tasklistid,
      [undefined, "", " ", null].includes(item.TaskDetails.MilestoneStartDate) ? "-" : item.TaskDetails.MilestoneStartDate,
      [undefined, "", " ", null,0].includes(item.TaskDetails.MilestoneDueDate) ? "-" : item.TaskDetails.MilestoneDueDate,
      [undefined, "", " ", null].includes(item.TaskDetails.TasklistStartDate_) ? "-" : item.TaskDetails.TasklistStartDate_,
      [undefined, "", " ", null,0].includes(item.TaskDetails.TasklistDueDate) ? "-" : item.TaskDetails.TasklistDueDate,
      item.TaskDetails.DRI,
      item.TaskDetails.Tasklistid,
      [undefined, "", " ", null,0].includes(item.TaskDetails.TasklistPriority) ? "None" : item.TaskDetails.TasklistPriority
    ];
  });


let uniqueMilestoneNames = [];
let checkData =[];

dataAsArray.forEach((row, index) => {
  if (!checkData.includes(row[1])) {
    uniqueMilestoneNames.push(row);
    checkData.push(row[1]);
  }
});

  return (
    <>
    {DataCheck === undefined ? (
    
    <div className='page-content-2'>
    <div className="mytasks-table">
      {/* <TableContainer component={Paper} className="mytasks-table-container"> */}
        <Table stickyHeader>
          <TableHead>
            <TableRow >
        <TableCell colSpan={7}>
          <Skeleton
            variant="rectangular"
            width={"100%"}
            sx={{ height: "calc(100vh - 180px)" }}
          />
        </TableCell>
      </TableRow>
      </TableHead>
      </Table>
      {/* </TableContainer> */}
      </div>
      </div>
  
      )
      :
      DataCheck === false ? (
        <Box className='page-content-2' sx={{
          color: theme.palette.background.text,
          fontSize: "2rem"
        }}>
          No Data Found.
        </Box>
      )
      :
      (
        <div className='page-content-2'>
      <div className="mytasks-table">
        <TableContainer component={Paper} className="mytasks-table-container">
          <Table stickyHeader>
            <TableHead>
              <TableRow className="mytasks-table-head">
              <TableCell></TableCell>
                <TableCell className='milestone-table-grouper-header'/>
                <TableCell align="center">S.NO.</TableCell>
                <TableCell align="center" >Milestone Name</TableCell>
                <TableCell align="center" >Start Date</TableCell>
                <TableCell align="center" >Due Date</TableCell>
                <TableCell align="center" >Status</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {uniqueMilestoneNames.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      sx={{ height: "calc(100vh - 180px)" }}
                    />
                  </TableCell>
                </TableRow>
                ) : (
                uniqueMilestoneNames.map((name, index) => (
                    <Row
                    key={0}
                    index={index}
                    dataAsArray={dataAsArray}
                    MilestoneName={name[1]}
                    MilestoneStartDate={name[8]}
                    MilestoneDueDate={name[9]}
                    Status={name[5]}
                    MilestoneId={name[0]}
                    DRI={name[12]}
                    uniqueMilestoneNames={uniqueMilestoneNames}
                    Server={name[4]}
                  />
                  ))
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
        )}
    
    </>
  );
};

function Row({index,dataAsArray,MilestoneName,MilestoneStartDate,MilestoneDueDate,Status,MilestoneId,DRI,uniqueMilestoneNames,Server}){
  const [moreVisibile, setMoreVisible] = useState(false);
  const [statusCheck, setStatusCheck] = useState(false);
  const theme = useTheme();
  const [open, setOpen] = React.useState(null);
  const [MilestoneData, setMilestoneData] = useState([]);
  const {loginUserDept} = useContext(UserContext);

  useEffect(() => {
		fetchValuesData1();
	}, []);

  const fetchValuesData1 = async () => {
		try {
			const queryString = combined_milestone_get_query_2(MilestoneName);
			if(queryString in dataCache) {
				setMilestoneData(dataCache[queryString]);
			} else {
				const result = await queryDB(queryString);
				dataCache[queryString] = result;
				setMilestoneData(result);
			}
			
		

		} catch (error) {
			// console.error(error);
		}
	};

	var dataAsArray1 = MilestoneData.map((item) => {
		return [
			
            item.TaskDetails.MilestoneId,
            item.TaskDetails.MilestoneName,
            [undefined, "", " ", null].includes(item.TaskDetails.TasklistDescription) ? "-" : item.TaskDetails.TasklistDescription,
          //   item.TaskDetails.TasklistDescription,
            item.TaskDetails.TasklistName,
            item.TaskDetails.Component,
            item.TaskDetails.MilestoneStatus,
            [undefined, "", " ", null,0].includes(item.TaskDetails.TasklistStatus) ? "-" : item.TaskDetails.TasklistStatus,
            // item.TaskDetails.TasklistStatus,
            item.TaskDetails.Tasklistid,
            [undefined, "", " ", null].includes(item.TaskDetails.MilestoneStartDate) ? "-" : item.TaskDetails.MilestoneStartDate,
            [undefined, "", " ", null,0].includes(item.TaskDetails.MilestoneDueDate) ? "-" : item.TaskDetails.MilestoneDueDate,
            [undefined, "", " ", null].includes(item.TaskDetails.TasklistStartDate) ? "-" : item.TaskDetails.TasklistStartDate,
            [undefined, "", " ", null,0].includes(item.TaskDetails.TasklistDueDate) ? "-" : item.TaskDetails.TasklistDueDate,
            item.TaskDetails.DRI,
            [undefined, "", " ", null,0].includes(item.TaskDetails.TasklistPriority) ? "None" : item.TaskDetails.TasklistPriority,
            // item.TaskDetails.TasklistPriority,
            item.TaskDetails.MilestoneDescription,
            item.TaskDetails.MilestoneStatus
			
		];
	});
 
  // console.log(dataAsArray1.map((item) => item[1]))

  if (dataAsArray1.map((item) => item[5])==="Ongoing" || dataAsArray1.map((item) => item[5])==="In Progress" || dataAsArray1.map((item) => item[5])==="Closed"|| dataAsArray1.map((item) => item[5])==="Cancelled" || dataAsArray1.map((item) => item[5])==="Completed" ||  dataAsArray1.map((item) => item[5])==="Open"){
    setStatusCheck(true);
  }

  const handleClick = () => {
    if (open === index) {
			setOpen(null);
		}
  
		else {
			setOpen(index);
		}
  };
  var color = "green";

	const today = new Date();
	const startDate = new Date(MilestoneStartDate);
	const endDate = new Date(MilestoneDueDate);
	const totDays = days_between(startDate, endDate);


	if (today < startDate) {
		color = "green";
	}
	// else if(tasklistStatus === "Closed" || tasklistStatus === "Updated to IDC" || tasklistStatus === "Cancelled" || tasklistStatus === "Completed"){
	// 	if(theme.palette.mode === "dark"){
	// 		color = "White";
	// 	}
	// 	else{
	// 		color = "black";
	// 	}
  //   }
	else if (days_between(today, startDate) < (0.25 * totDays).toFixed(0)) {
		color = "forestgreen";
	}
	else if (days_between(today, startDate) < (0.5 * totDays).toFixed(0)) {
		color = "darksalmon";
	}
	else if (days_between(today, startDate) < (0.75 * totDays).toFixed(0)) {
		color = "darkgoldenrod";
	}
	else if (days_between(today, startDate) < (totDays).toFixed(0)) {
		color = "chocolate";
	}
	else {
		color = "crimson";
	}
	if (MilestoneStartDate === "-" || MilestoneDueDate === "-") {
		color = "";
	}

  const allCompleted = dataAsArray1.map((item) => item[15]).every(status => status === "Completed");
  const validStartDates = dataAsArray1.map((item) => item[8])
  .filter(date => date !== '-')
  .map(date => new Date(date));

  const earliestStartDate = validStartDates.length > 0
    ? new Date(Math.min(...validStartDates))
    : null;

    const validStartDates1 = dataAsArray1.map((item) => item[9])
    .filter(date => date !== '-')
    .map(date => new Date(date));
  
    const oldestDueDatet = validStartDates1.length > 0
      ? new Date(Math.max(...validStartDates1))
      : null;
  // const oldestDueDate = Math.max(...MilestoneDueDate.map(date => new Date(date).getTime()));

  function formatDateToOriginal(date) {
    if (date instanceof Date && !isNaN(date)) {
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } else {
      return '-';
    }
  }

    const originalFormatStartDate = formatDateToOriginal(earliestStartDate);
    const originalFormatDueDate = formatDateToOriginal(oldestDueDatet);


  var status = <HexagonIcon style={{ color: "pink", fontSize: "small" }} />;
  return (
    
    <>
      <TableRow
            key={index}
            hover={true}
            sx={{
              backgroundColor: theme.palette.background.tableRow,
              '&:hover': {
                backgroundColor: theme.palette.background.tableRowHover + " !important"
              }
            }}
            onMouseEnter={() => setMoreVisible(true)}
            onMouseLeave={() => setMoreVisible(false)}
            
          >
            {/* {serTemp} */}
           
          
           <TableCell align="left" className='slide-animation' style={{ width: "30px"  }}>
            <IconButton
						size="small"
						onClick={handleClick}
					>
						{open === null ? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon />}
					</IconButton>
             </TableCell>
             <TableCell></TableCell>
               <TableCell align="center"  	onClick={handleClick} style={{ width: "2.5vw", maxWidth: "2.5vw" }}>{index+1}</TableCell>
               <TableCell allign="center" 	onClick={handleClick} style={{ width: "10vw" }}><Box>{MilestoneName}</Box></TableCell>
               <TableCell align="center"    onClick={handleClick}>{originalFormatStartDate}</TableCell>
               <TableCell align="center"  sx={{color: color + " !important"}}   onClick={handleClick}>{originalFormatDueDate}</TableCell>
               {/* <TableCell align="center"    onClick={handleClick}><Box> {status}<p>{Status}</p></Box></TableCell> */}
               <TableCell align="center"    onClick={handleClick}><Box> {status}<p>{allCompleted ? "Completed" : "Not Completed"}</p></Box></TableCell>
      </TableRow>

             
               
                     <TableRow className="info-div-row"	 sx={{backgroundColor: theme.palette.background.infoDivOuter + " !important"}}>
                     <TableCell colSpan={8} style={{ padding:0 }}>
                    <Collapse in={open !== null} timeout="auto" unmountOnExit>
                    <CombinedInfo   index={index}  MilestoneName={dataAsArray1.map((item) => item[1])} MilestoneStartDate={dataAsArray1.map((item) => item[8])} MilestoneDueDate={dataAsArray1.map((item) => item[9])} Status={dataAsArray1.map((item) => item[5])} MilestoneId={dataAsArray1.map((item) => item[0])} DRI={dataAsArray1.map((item) => item[12])} Server={dataAsArray1.map((item) => item[4])} Tasklistid={dataAsArray1.map((item) => item[7])} TasklistPriority={dataAsArray1.map((item) => item[13])} MilestoneDescription={dataAsArray1.map((item) => item[14])} MilestoneStatus={dataAsArray1.map((item) => item[15])} TasklistStatus={dataAsArray1.map((item) => item[6])}/>
                    </Collapse>
                    </TableCell>
                    </TableRow>       

    </>
    
    
  )  ;

}

export default MyTable;