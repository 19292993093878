import React, { useEffect, useState } from 'react';
import TaskList from './TaskList.js';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Paper from '@mui/material/Paper';

import { useTheme } from '@mui/material';
import { Skeleton } from '@mui/material';

import { getTasks } from './MilestoneDataFetcher.js';

import './Milestone.css';

import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { MilestoneReport } from '../report/external/milestoneReport/MilestoneReport.js';
import { info_div_get_query_1 } from '../../../catalystDB/queryGenerator.js';
import ThreadMsgLink from './ThreadMsgLink.js';

function InfoDiv({ milestoneName, milestoneId, milestoneStatus, milestoneStartDate ,toggle})
{
	const theme = useTheme();

	return (
		<Box style={{
				padding: "10px 0px 10px 0px",
				backgroundColor: theme.palette.background.infoDivOuter
			}}
		>
			<Paper className="info-div-paper"
				sx = {{
					backgroundColor: theme.palette.background.infoDivInner + " !important"
				}}
			>
				<ProgressBar milestoneId={milestoneId} milestoneStatus={milestoneStatus} milestoneStartDate={milestoneStartDate} />
				<Divider />
				<TaskList milestoneName={milestoneName} milestoneId={milestoneId} toggle={toggle} />
				<Divider />
				<ThreadMsgLink   milestoneName={milestoneName} milestoneId={milestoneId} toggle={toggle}/>
				{toggle.current == false ? (
					<>
					 			<br />
					 			<MilestoneReport milestoneId={milestoneId} />
					 		</>
				) : (
					null
				)}
				 {/* {
				 	toggle.current ? 
				 		<>
				 			<br />
				 			<MilestoneReport milestoneId={milestoneId} />
				 		</>
				 		:
				 		null
				 } */}
				
			</Paper>
		</Box>
	);
}

function ProgressBar({ milestoneId, milestoneStatus, milestoneStartDate })
{
	const steps = [
		"Approved",
		"Task Breaking",
		"Scheduled",
		"Ongoing",
		"Completed"
	];

	// console.log(milestoneStatus);

	const [activeStep, setActiveStep] = useState(null);

	useEffect(() => {

		async function run() {
			if (milestoneStatus === "Completed") {
				setActiveStep(4);
				return;
			}
			else {
				try {
					const tasks = await getTasks(info_div_get_query_1(milestoneId));

					// setActiveStep(1);
					const startDate = new Date(milestoneStartDate);
					const now = new Date();

					if (milestoneStartDate === "-") {
						setActiveStep(1);
						return;
					}
					else if (now < startDate) {
						setActiveStep(2);
						return;
					}
					else {
						setActiveStep(3);
						return;
					}
				}
				catch (e) {
					if (e === "none") {
						setActiveStep(0);
						return;
					}
					console.log(e);
					// setActiveStep(-1);
					return;
				}
			}
		}

		run();
		
		
	
	}, []);


	if(activeStep === null)
	{
		return <Skeleton width={"100%"} height={"30px"} component={Box} sx={{
			marginBottom: "10px",
			'&.MuiSkeleton-root': {
				transform: "none",
				animation: "none"
			}
		}}/>;
	}

	return (
		<>
			<Stepper activeStep={activeStep} connector={<ColorlibConnector />}>
				{steps.map((label) => (
				<Step key={label}>
					<StepLabel>{label}</StepLabel>
				</Step>
				))}
			</Stepper>
		</>
	);
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 22,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor: theme.palette.primary.main,
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor: theme.palette.mode === 'dark' ? "#717171" : '#a0a0a0',
		borderRadius: 1,
	},
}));

export default InfoDiv;