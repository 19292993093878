/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */

import { getDuration, isDateInRange, isDateString } from "../../dashboardMain/DashboardUtil";
import { Box } from "@mui/material";
import React from "react";

export function getChartData(catalystDB, startTime) {

    var total = 0;
    var count = 0;
    var min = Number.MAX_SAFE_INTEGER;
    var max = 0;

    var min_item = undefined;
    var max_item = undefined;

    catalystDB.Tickets.map(item => {
        const dateString = item.CREATEDTIME;
        const completedDateString = item.ResolvedOn;

        if (isDateString(dateString) && isDateInRange(dateString, startTime)) {
            if ([undefined, null, "", " "].includes(completedDateString)) {
                return;
            }

            if (isDateString(completedDateString)) {
                const date_1 = new Date(dateString);
                const date_2 = new Date(completedDateString);

                if (date_2 > date_1) {
                    const diff = date_2.getTime() - date_1.getTime();

                    if (diff >= 0) {
                        total += diff;
                        count++;

                        if (diff <= min) {
                            min = diff;
                            min_item = item;
                        }

                        if (diff >= max) {
                            max = diff;
                            max_item = item;
                        }
                    }
                }
            }
        }
    });

    var min_map = {};
    var max_map = {};

    if(min_item !== undefined) {
        var min_map = {
            "Ticket ID": min_item.TicketID,
            "Resolved On": min_item.ResolvedOn,
            "PRD ID": min_item.PRD_ID,
            "Chat ID": <a target="_blank" href={'https://cliq.zoho.com/company/64396901/chats/'+min_item.Chat_ID}>{min_item.Chat_ID}</a>
            // "Thread ID": min_item.ThreadID
        };
    }

    if(max_item !== undefined) {
        var max_map = {
            "Ticket ID": max_item.TicketID,
            "Resolved On": max_item.ResolvedOn,
            "PRD ID": max_item.PRD_ID,
            "Chat ID": <a target="_blank" href={'https://cliq.zoho.com/company/64396901/chats/'+max_item.Chat_ID}>{max_item.Chat_ID}</a>
            // "Thread ID": max_item.ThreadID
        };
    }

    var average;
    if (count === 0) {
        average = "-";
    } else {
        average = Math.floor(total / count);
    }

    return [
        {
            "average": average === "-" ? "-" : getDuration(average).duration,
            "min": min === Number.MAX_SAFE_INTEGER || min === 0 ? "-" : getDuration(min).duration,
            "max": max === 0 ? "-" : getDuration(max).duration,
            "average_hover": undefined,
            "min_hover": Object.keys(min_map).length === 0 ? null : <HoverDisplay map={min_map} color={"navy"} />,
            "max_hover": Object.keys(max_map).length === 0 ? null : <HoverDisplay map={max_map} color={"crimson"} />
        },
        [
            average === "-" ? "" : getDuration(average).unit,
            min === Number.MAX_SAFE_INTEGER || min === 0 ? "" : getDuration(min).unit,
            max === 0 ? "" : getDuration(max).unit
        ]
    ];
}

function HoverDisplay({ map, color }) {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            padding: "10px"
        }}>
            {
                Object.keys(map).map((key, index) => {
                    const value = map[key];

                    return (
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%"
                        }} key={index}>
                            <Box sx={{
                                fontSize: "0.8rem",
                                color: color,
                                fontWeight: "bold"
                            }}>
                                {key}
                            </Box>
                            <Box sx={{
                                fontSize: "1rem"
                            }}>
                                {value}
                            </Box>
                            <hr/>
                        </Box>
                    );
                })
            }
        </Box>
    );
}