/* eslint-disable react/prop-types */ 
/* esline-disable react/jsx-key */
import React from "react";
import { useEffect, useRef, useState, useContext } from "react";
import { UserContext } from "../../../../../contexts/UserContext";
import { useTheme } from '@mui/material/styles';
import { Box, ButtonGroup, Skeleton, Tab } from "@mui/material";
import { ReportContext } from "../newReportGeneral/NewReport";
import { getMilestoneRows } from "../reportSection1/ReportSection1DataFetcher"; 

import AirIcon from '@mui/icons-material/Air';
import { PieChart } from "../newReportGeneral/NewReportUtilComponents";

import { Grid } from "@mui/material";
import { IOSSwitch } from "../newReportGeneral/NewReportUtilComponents";

import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export function ReportSection6() {

    const [toggleOngoing, setToggleOngoing] = useState(true);

    function handleToggleChange() {
        setToggleOngoing(!toggleOngoing);
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            width: "100%",
            height: "98%",
            gap: "10px"
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.1rem",
                fontWeight: "bold"
            }}>
                <Grid container spacing={0.5} sx={{ height: "100%" }}>
                    <Grid item xs={3.5}>
                    </Grid>
                    <Grid item xs={4.5}>
                        <Box style={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex"
                        }}>
                            Milestone Status
                            <ArrowRightIcon />
                            {
                                toggleOngoing ? 
                                    "Ongoing"
                                    :
                                    "Completed"
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box style={{
                            float: "right",
                            paddingRight: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <span style={{ marginRight: "10px", fontSize: "0.8rem" }}>Toggle Ongoing</span>
                            <IOSSwitch checked={toggleOngoing} onChange={handleToggleChange} />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <ReportBody toggleOngoing={toggleOngoing} setToggleOngoing={setToggleOngoing} />
        </Box>
       
    );
}   


function ReportBody({toggleOngoing, setToggleOngoing}) {

    const {
        subjectType, 
        setSubjectType, 
        subject, 
        setSubject, 
        timeFrame, 
        setTimeFrame, 
        time, 
        setTime,
        timeRange,
        setTimeRange
    } = useContext(ReportContext);
    const theme = useTheme();
    const {teamDetails, loginUserDept} = useContext(UserContext);
    
    const [loaded, setLoaded] = useState(undefined);
    const [milestoneRows, setMilestoneRows] = useState(undefined);
    const [chartData, setChartData] = useState(undefined);

    // var chartData = [
    //     [
    //         [
    //             "sdfasdfasdfasdfasdfasdfasdfasdfasdf",
    //             19
    //         ],
    //         [
    //             1312137000000,
    //             13
    //         ],
    //         [
    //             1314815400000,
    //             13
    //         ],
    //         [
    //             1317407400000,
    //             12
    //         ],
    //         [
    //             1320085800000,
    //             15
    //         ],
    //         [
    //             1322677800000,
    //             9
    //         ],
    //         [
    //             1325356200000,
    //             17
    //         ],
    //         [
    //             1328034600000,
    //             18
    //         ],
    //         [
    //             1330540200000,
    //             24
    //         ],
    //         [
    //             1333218600000,
    //             14
    //         ],
    //         [
    //             1335810600000,
    //             16
    //         ],
    //         [
    //             1338489000000,
    //             18
    //         ]
    //     ]
    // ];
    
    useEffect(() => {
        const controller = new AbortController();
        async function pullMilestoneRows() {

            getMilestoneRows(timeRange, subjectType, subject, teamDetails, loginUserDept).then((response) => {
                if(!controller.signal.aborted) {
                    if(response.length !== 0) {
                        setMilestoneRows(response);

                        var onTimeCount = 0;
                        var delayedCount = 0;
                        var yetToCompleteCount = 0;
                        var onTimeOngoing = 0;
                        var delayedOngoing = 0;

                        response.forEach((item) => {
                            var startTime = new Date(item.start_date).getTime();
                            var dueTime = new Date(item.due_date).getTime();
                            var completedTime = new Date(item.completed_date).getTime();

                            if(completedTime > 0) {
                                if(completedTime <= dueTime) {
                                    onTimeCount += 1;
                                } else {
                                    delayedCount += 1;
                                }
                            } else {
                                yetToCompleteCount += 1;
                                if(new Date() <= dueTime) {
                                    onTimeOngoing += 1;
                                } else {
                                    delayedOngoing += 1;
                                }
                            }
                        })

                        if(toggleOngoing) {
                            setChartData([
                                [
                                    "On Track",
                                    onTimeOngoing
                                ],
                                [
                                    "Delayed",
                                    delayedOngoing
                                ]
                            ]);
                        } else {
                            setChartData([
                                [
                                    "On Schedule",
                                    onTimeCount
                                ],
                                [
                                    "Delayed",
                                    delayedCount
                                ]
                            ]);
                        }

                        

                        setLoaded(true);
                    } else {
                        setLoaded(false);
                    }
                }
            }).catch((error) => {
                if(!controller.signal.aborted) {
                    setLoaded(false);
                }
            });
        }

        setLoaded(undefined);
        pullMilestoneRows();

        return () => {
            controller.abort();
        };

    }, [timeRange, subjectType,subject, toggleOngoing]);


    if(loaded === undefined) {
        return (
            <Skeleton variant={"rounded"} height={"98%"} width={"100%"} animation="wave" />
        );
    } else if(loaded === false) {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%"
            }}>
                <Box>
                    <AirIcon sx={{
                        fontSize: "6rem",
                        color: theme.palette.mode === "light" ? "rgb(20, 20, 20, 0.06)": "rgb(200, 200, 200, 0.06)"
                    }} />
                </Box>
                <Box sx={{
                    fontSize: "0.7rem",
                    color: theme.palette.mode === "light" ? "rgb(10, 10, 10, 0.3)": "rgb(200, 200, 200, 0.4)"
                }}>
                    Nothing found
                </Box>
            </Box>
        );
    } else {
        return (
            <Box  sx={{
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                overflowY: "scroll",
                boxSizing: "border-box",
                backgroundColor: theme.palette.mode === 'dark' ? 'rgb(24,30,35)' : '#FAFAFA',
                borderRadius: "12px"
            }}
            >
                <PieChart title={""} columnName1={"col1"} columnName2={"col2"} data={chartData} colors={["#46BAC1", "#E7D469", "#FF759C"]}/>
            </Box>
        );
    }
}