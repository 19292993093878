import React from 'react';
import './TopBar.css';
import {useState, useEffect, useContext, useRef} from 'react';
import { UserContext } from './../contexts/UserContext.js';
import PropTypes from 'prop-types';

import ZVPLogo from './../icons/ZVPLogo.svg';

import Switch from '@mui/material/Switch';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import TextsmsIcon from '@mui/icons-material/Textsms';
import VideocamIcon from '@mui/icons-material/Videocam';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { ReactComponent as ZVP_LOGO } from "../icons/logos/ZVP_Logo.svg";
import { ReactComponent as WMS_LOGO } from  "../icons/logos/WMS_Logo.svg";

import { Box, AppBar, Toolbar, IconButton, Typography,
	Dialog, 
	DialogTitle,
	DialogActions,
	DialogContent,
	CircularProgress
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {useTheme} from '@mui/material/styles';
import LightModeTwoToneIcon from '@mui/icons-material/LightModeTwoTone';
import DarkModeTwoToneIcon from '@mui/icons-material/DarkModeTwoTone';

import Notifications from './Notifications.js';
import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close'; 

import { TabStateContext, TabDetails } from '../contexts/TabStateContext';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {Select} from '@mui/material';
import { executeQuery } from '../util/QueryDB';
import { top_bar_component_get_query_1 } from '../catalystDB/queryGenerator.js';
// import Alert from '@mui/material/Alert';


// import { myTheme } from './../contexts/ThemeContext.js' 

TopBar.propTypes = {
	mode: PropTypes.string.isRequired,
	setMode: PropTypes.func.isRequired
};

function TopBar({ mode, setMode})
{
	const theme = useTheme();
	const {teamDetails} = useContext(UserContext);

	return (
		// <div className="top-bar">
		// 	<TopBarLeftGroup />
		// 	<TopBarRightGroup />
		// </div>
		<Box sx={{ flexGrow: 1, borderBottom: "1px solid rgb(33, 34, 38);" }}>
			<AppBar position="static">
				<Toolbar style={{ padding: 0 }} sx={{ backgroundColor: theme.palette.background.topBar }}>
				<div className="top-bar">
					<TopBarLeftGroup />
					{/* {!navigator.onLine && (
					<Alert severity="error">This is an error Alert.</Alert>
					)} */}
					{!navigator.onLine && (
					<Alert sx={{marginLeft:"255px",width:"247px",alignItems:"center",textAlign:"center",justifyContent:"center"}} severity="error">Kindly check you network connection.</Alert>
					)}
					<TopBarRightGroup setMode={setMode} mode={mode} />
				</div>
				</Toolbar>
			</AppBar>
    	</Box>
	);
}

function TopBarLeftGroup()
{
	const {userDetails, teamDetails} = useContext(UserContext);
	const { tabState } = useContext(TabStateContext);

	const selectedOuterTab = tabState.current.outer;
	const isFullTab = TabDetails[selectedOuterTab].index < 0;

	function handleLogoClick() {
		if(isFullTab) {
			return;
		}
		const userEmail = userDetails.current.auth.email_id;
		const userRole = teamDetails.current[userEmail].BetaUser;

		const outerSideBar = document.getElementsByClassName("outer-side-bar")[0];
		const innerSideBar = document.getElementsByClassName("inner-side-bar")[0];
		const logo = document.getElementById("page-logo");

		if(outerSideBar.style.display === "none")
		{
			outerSideBar.style.display = "block";
			innerSideBar.style.left = "50px";
			innerSideBar.style.width = "200px";
			logo.style.transform = "rotate(0deg)";
		}
		else
		{
			outerSideBar.style.display = "none";
			innerSideBar.style.left = "0px";
			innerSideBar.style.width = "250px";
			logo.style.transform = "rotate(45deg)";
		}
	}

	return (
		<div className="top-bar-left-group">
			<Box sx={{
				cursor: "default"
				// '&:hover': {
				// 	animation: "spin 2s cubic-bezier(.66,.39,.21,.67) infinite",
				// 	"@keyframes spin": {
				// 		"0%": {
				// 			transform: "scale( .8 ) rotate(360deg)"
				// 		},
				// 		"35%": {
				// 			transform: "scale( .9 ) rotate(240deg)"
				// 		},
				// 		"70%": {
				// 			transform: "scale( 1 ) rotate(120deg)"
				// 		},
				// 		"100%": {
				// 			transform: "scale( .8 ) rotate(0deg)"
				// 		}
				// 	}
				// }
			}} onClick={handleLogoClick} id="page-logo">
				<img src={ZVPLogo} alt="ZVPLogo" />
			</Box>
			<h2 style={{
						cursor: "default", margin: "0px",
						background: "linear-gradient(#fff, #607D8B)",  
						WebkitBackgroundClip: "text",
						WebkitTextFillColor: "transparent"
					}}
			>
				ZONE
			</h2>
		</div>
	);
}

TopBarRightGroup.propTypes = {
	mode: PropTypes.string.isRequired,
	setMode: PropTypes.func.isRequired
};

function TopBarRightGroup({ mode, setMode })
{
	const { userDetails, teamDetails, setAuthState,configuration } = useContext(UserContext);

	const userAuthDetails = userDetails.current.auth;

	const userEmail = userDetails.current.auth.email_id;
	const userRole = teamDetails.current[userEmail].BetaUser;
	const TopBarSelector = configuration.current['global.topbar.showDepartmentSelector'];

// console.log(configuration.current);
// console.log(configuration.current['global.topbar.showDepartmentSelector']);

		
	if(userRole === "Yes")
	{
			isUserAllowed = true; 
	}
	else{
		isUserAllowed = false; 
	}

	const [anchor, setAnchor] = useState(null);
	const open = anchor !== null;

	function handleClick(event)
	{
		setAnchor(event.currentTarget);
	}

	function handleClose()
	{
		setAnchor(null);
	}

	function handleLogout()
	{
		setAnchor(null);
		// setAuthState("unauthorized");
		window.open("/#/login", "_blank");
		window.catalyst.auth.signOut("/#/login");
		// window.open(window.catalyst.auth.signOut("/#/login"), "_blank");
	}

	function changeMode()
	{
		if(mode === "light")
		{
			localStorage.setItem("theme", "dark");
			setMode("dark");
		}
		else if(mode === "dark")
		{
			localStorage.setItem("theme", "light");
			setMode("light");
		}
	}

	return (
		<div className="top-bar-right-group">
			{/* <TopBarTime /> */}
			{/* <Notifications/>		 */}
			<GlobalServerSelection />
			{ TopBarSelector.includes(userEmail) && <Department /> }
			{ ["manoj.sakthivel@zohocorp.com", "thirumaran.guna@zohocorp.com", "vijayshankar.a@zohocorp.com"].includes(userEmail) && <LearnDoc /> } 
			{isUserAllowed && <Notifications/>}
			<Avatar className="top-bar-profile"
			 		alt="Profile Photo"
			 		src={"https://contacts.zoho.com/file?ID=" + teamDetails.current[userAuthDetails.email_id].zuid + "&exp=6000&t=user&fs=thumb"}
			 		sx={{ width: 35, height: 35, marginLeft: 1 }}
			 		onClick={handleClick}
			 />
			 <Button onClick={changeMode} style={{minWidth: 0, borderRadius: "100px"}}>
				{ mode === "light" ? <LightModeTwoToneIcon sx={{fontSize: 34}}/> : <DarkModeTwoToneIcon sx={{fontSize: 34}}/>}
			 </Button>

			<Menu
		        anchorEl={anchor}
		        open={open}
		        onClose={handleClose}
		     >
		        <MenuItem>{userAuthDetails.first_name + " " + userAuthDetails.last_name}</MenuItem>
		        <MenuItem>{userAuthDetails.email_id}</MenuItem>
		        <MenuItem onClick={handleLogout}>Logout</MenuItem>
		    </Menu>

		</div>
	);
}

function LearnDoc() {

	const [open, setOpen] = useState(false);
	const theme = useTheme();

	function handleClose() {
		setOpen(false);
	}

	return (
		<Box sx={{
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: "30px"
		}}>
			<Dialog
				fullScreen
				open={open}
				keepMounted
				onClose={handleClose}
				PaperProps={{
					style: {
						backgroundColor: theme.palette.background.pageContent,
						boxShadow: "none"
					}
				}}
			>
				<div style={{ display: "block" }}>
					<DialogTitle style={{ float: "left", display: "flex", alignItems: "center" }}>

					</DialogTitle>
					<DialogActions style={{ float: "right", padding: "16px 24px 16px 24px" }}>
						<Button onClick={handleClose} variant="text"><CloseIcon fontSize="medium" /></Button>
					</DialogActions>
				</div>
				<DialogContent style={{ paddingTop: 0 }}>
					<iframe
						src="https://learn.zoho.com/portal/zohocorp/team/zoho-video-platform-1/manual/zone-1/article/milestone"
						width="100%"
						height="98%"
						frameBorder={0}
						style={{
							flex: 1
						}}
						sandbox
					/>
				</DialogContent>
			</Dialog>
			<Button sx={{
				color: "white",
				borderRadius: "100px"
			}}
			onClick={() => setOpen(true)}
			>
				<HelpIcon />
			</Button>
		</Box>
	);
}

function TopBarTime()
{
	const now = new Date();
	const formatter = new Intl.DateTimeFormat('en-US', {
		hour: 'numeric',
		minute: 'numeric',
		hour12: true
	});
	const timeString = formatter.format(now);
	const [currentTime, setCurrentTime] = useState(timeString);

	useEffect(() => {
		const now = new Date();
		const formatter = new Intl.DateTimeFormat('en-US', {
			hour: 'numeric',
			minute: 'numeric',
			hour12: true
		});
		var timeString = formatter.format(now);
		const interval = setInterval(() => {
			timeString = formatter.format(new Date())
	        setCurrentTime(timeString);
	    }, 1000);
	    return () => clearInterval(interval);
	});

	return (
		<div className="top-bar-time">
			<h2 style={{margin: 0, background: "linear-gradient(#fff, #607D8B)",  
								   WebkitBackgroundClip: "text",
   								   WebkitTextFillColor: "transparent"}}
			>
				{currentTime}
			</h2>
		</div>
	);
}

function GlobalServerSelection() {
	const {loginUserEmail,loginUserDept,zoneAdmin,configuration,GlobalServer,setGlobalServer,teamDetails,allServers,userDetails} = useContext(UserContext);
	const [ServerAnchor, setServerAnchor] = useState(null);
	const [SelectedServer, setSelectedServer] = useState(null);
	const [ServerSpinner, setServerSpinner] = useState(false);
	const theme = useTheme();
	const { tabState } = useContext(TabStateContext);
	const user_email = userDetails.current.auth.email_id;
	const [showAlert, setShowAlert] = useState(false);
	const filteredServers = teamDetails.current[user_email].Servers.split(",").filter(server => allServers.includes(server));


	function serverHandleClick (event){
		if(ServerAnchor === null) {
			setServerAnchor(event.currentTarget);
		} else {
			setServerAnchor(null);
		}
	}

	useEffect(() => {
		if (showAlert) {
			const timer = setTimeout(() => {
				setShowAlert(false);
			}, 2000);

			return () => clearTimeout(timer);
		}
	}, [showAlert]);

	function ServerhandleClose(event, servers) {
		setSelectedServer(servers)
		// console.log(servers)
		
		if (filteredServers.includes(servers) || servers == undefined || servers == "unknown") {
			setSelectedServer(servers);
			setGlobalServer(servers);
			localStorage.removeItem('selectedServerMilestone');
			localStorage.removeItem('selectedServerRoadMap');
			localStorage.removeItem('selectedServerTaskBreaker');
		} else {
			setShowAlert(true);	
		}	
	}
	

	return (
		<>
			<Box sx={{
				padding: zoneAdmin.current ? "1px 2px 1px 1px" : "1px 12px 1px 1px",
				backgroundColor: "rgba(17, 1, 1, 0.10)",
				borderRadius: "25px",
				boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
				backdropFilter: "blur(4.3px)",
				border: "1px solid rgba(17, 1, 1, 1)",
				display: "flex",
				flexDirection: "row",
				gap: "1px",
				alignItems: "center",
				justifyContent: "center",
				cursor: "pointer"
			}}
				onClick={(event) => serverHandleClick(event)}
			>
	
				<Box sx={{
					display: "flex",
					gap: "5px",
					alignItems: "center",
					justifyContent: "center"
				}}>
					{
						loginUserDept.current === "ZMP" ? 
							// <TextsmsIcon fontSize='1.5rem' />
							<WMS_LOGO style={{
								height: "22px",
								width: "22px",
								background: "rgba(20, 20, 20, 0.8)",
								padding: "6px",
								borderRadius: "100px"
							}} />
							:
							loginUserDept.current === "ZVP" ?
								// <VideocamIcon fontSize='1.5rem' />
								<ZVP_LOGO style={{
									height: "24px",
									width: "24px",
									background: "rgba(20, 20, 20, 0.8)",
									padding: "4px",
									borderRadius: "100px"
								}} />
								:
								<Diversity2Icon fontSize='1.5rem' />
					}
					<h6 style={{
						cursor: "default", margin: "0px",
						background: "linear-gradient(#fff, #607D8B)",
						WebkitBackgroundClip: "text",
						WebkitTextFillColor: "transparent",
						fontSize: "1.1rem",
						fontWeight: "bold",
						userSelect: "none"
					}}>
						{(GlobalServer != "unknown" && GlobalServer != undefined) ? (
							GlobalServer
							) 
							:
							(
								loginUserDept.current + " Servers"
							)
						}
						
					</h6>
				</Box>
				
						<Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}
						>
							{
									ServerAnchor !== null ? 
										<ArrowDropUpIcon />
										:
										<ArrowDropDownIcon />
							}
							<Menu
								value={loginUserDept.current}
								open={ServerAnchor !== null}
								anchorEl={ServerAnchor}
								onClose={(event) => ServerhandleClose(event)}
								sx={{
									borderRadius: "100px"
								}}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "center"
								}}
								transformOrigin={{
									vertical: "top",
									horizontal: "center"
								}}
								autoFocus={false}
							>
								{
									loginUserDept.current === "ZVP" ? (
										// configuration.current['department.ZVP.servers'].map(servers => {
											filteredServers.map(servers => {
											// configuration.current.departments.map(dept => {
												return (
													<MenuItem value={servers}  key={servers} onClick={(event) => ServerhandleClose(event, servers)}
														sx={{
															padding: "12px 10px 12px 10px",
															boxSizing: "border-box"
														}}
													>
														<Box sx={{
															display: "flex",
															flexDirection: "row",
															alignItems: "center",
															justifyContent: "space-between",
															width: "100%",
															gap: "30px",
															boxSizing: "border-box",
															marginLeft: "10px",
															fontWeight: "bold"
														}}>
															{servers}
															{
																loginUserDept.current === "ZMP" ?
																// <TextsmsIcon fontSize='medium' />
																<WMS_LOGO style={{
																		height: "23px",
																		width: "23px",
																		background: theme.palette.mode === "light" ? "#eeeeee" : "rgba(20, 20, 20, 0.8)",
																		padding: "6px",
																		borderRadius: "100px"
																	}} />
																	:
																	loginUserDept.current === "ZVP" ?
																		// <VideocamIcon fontSize='medium' />
																		<ZVP_LOGO style={{
																			height: "24px",
																			width: "24px",
																			background: theme.palette.mode === "light" ? "#eeeeee" : "rgba(20, 20, 20, 0.8)",
																			padding: "6px",
																			borderRadius: "100px"
																		}} />
																		:
																<Diversity2Icon fontSize='medium' />
															}
														</Box>
													</MenuItem>
												);
											})
									): (
										configuration.current['department.ZMP.servers'].map(servers => {
											// configuration.current.departments.map(dept => {
												return (
													<MenuItem value={servers}  key={servers} onClick={(event) => ServerhandleClose(event, servers)}
														sx={{
															padding: "12px 10px 12px 10px",
															boxSizing: "border-box"
														}}
													>
														<Box sx={{
															display: "flex",
															flexDirection: "row",
															alignItems: "center",
															justifyContent: "space-between",
															width: "100%",
															gap: "30px",
															boxSizing: "border-box",
															marginLeft: "10px",
															fontWeight: "bold"
														}}>
															{servers}
															{
																loginUserDept.current === "ZMP" ?
																// <TextsmsIcon fontSize='medium' />
																<WMS_LOGO style={{
																		height: "23px",
																		width: "23px",
																		background: theme.palette.mode === "light" ? "#eeeeee" : "rgba(20, 20, 20, 0.8)",
																		padding: "6px",
																		borderRadius: "100px"
																	}} />
																	:
																	loginUserDept.current === "ZVP" ?
																		// <VideocamIcon fontSize='medium' />
																		<ZVP_LOGO style={{
																			height: "24px",
																			width: "24px",
																			background: theme.palette.mode === "light" ? "#eeeeee" : "rgba(20, 20, 20, 0.8)",
																			padding: "6px",
																			borderRadius: "100px"
																		}} />
																		:
																<Diversity2Icon fontSize='medium' />
															}
														</Box>
													</MenuItem>
												);
											})
									)
									
									// })
								}
							</Menu>
					 </Box>
						
			</Box>

			<Snackbar
				open={showAlert}

				onClose={() => setShowAlert(false)}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="success">You can't select this server ❌ </Alert>
			</Snackbar>
		</>
	);
}

function Department() {
	const {loginUserEmail, loginUserDept, zoneAdmin, configuration , GlobalServer,setGlobalServer} = useContext(UserContext);
	const [anchor, setAnchor] = useState(null);
	const [ServerAnchor, setServerAnchor] = useState(null);
	const [SelectedServer, setSelectedServer] = useState(null);
	const [spinner, setSpinner] = useState(false);
	const theme = useTheme();
	const { tabState } = useContext(TabStateContext);


	function handleClose(event, dept) {
		setAnchor(null);
		if(dept) {
			if(dept === loginUserDept.current) {
				return;
			}
			setSpinner(true);
			executeQuery(top_bar_component_get_query_1(dept, loginUserEmail)).then(response => {
				window.location.reload(true);
			}).catch(error => {
				setSpinner(false);
				alert("Unable to switch department!")
			});
		}
	}

	function handleClick(event) {
		if(anchor === null) {
			setAnchor(event.currentTarget);
		} else {
			setAnchor(null);
		}
	}



	return (
		<>	
		<Box sx={{
			padding: zoneAdmin.current ? "1px 2px 1px 1px" : "1px 12px 1px 1px",
			backgroundColor: "rgba(17, 1, 1, 0.10)",
			borderRadius: "25px",
			boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
			backdropFilter: "blur(4.3px)",
			border: "1px solid rgba(17, 1, 1, 1)",
			display: "flex",
			flexDirection: "row",
			gap: "1px",
			alignItems: "center",
			justifyContent: "center",
			cursor: "pointer"
		}}
			onClick={(event) => handleClick(event)}
		>

			<Box sx={{
				display: "flex",
				gap: "5px",
				alignItems: "center",
				justifyContent: "center"
			}}>
				{
					loginUserDept.current === "ZMP" ? 
						// <TextsmsIcon fontSize='1.5rem' />
						<WMS_LOGO style={{
							height: "22px",
							width: "22px",
							background: "rgba(20, 20, 20, 0.8)",
							padding: "6px",
							borderRadius: "100px"
						}} />
						:
						loginUserDept.current === "ZVP" ?
							// <VideocamIcon fontSize='1.5rem' />
							<ZVP_LOGO style={{
								height: "24px",
								width: "24px",
								background: "rgba(20, 20, 20, 0.8)",
								padding: "4px",
								borderRadius: "100px"
							}} />
							:
							<Diversity2Icon fontSize='1.5rem' />
				}
				<h6 style={{
					cursor: "default", margin: "0px",
					background: "linear-gradient(#fff, #607D8B)",
					WebkitBackgroundClip: "text",
					WebkitTextFillColor: "transparent",
					fontSize: "1.1rem",
					fontWeight: "bold",
					userSelect: "none"
				}}>
					{loginUserDept.current}
				</h6>
			</Box>
			{
				zoneAdmin.current ? 
					<Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}
					>
						{
							spinner ? 
								<CircularProgress size={"1.2rem"} sx={{margin: "0px 5px 0px 5px"}} />
								:
								anchor !== null ? 
									<ArrowDropUpIcon />
									:
									<ArrowDropDownIcon />
						}
						<Menu
							value={loginUserDept.current}
							open={anchor !== null}
							anchorEl={anchor}
							onClose={(event) => handleClose(event)}
							sx={{
								borderRadius: "100px"
							}}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "center"
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "center"
							}}
							autoFocus={false}
						>
							{
								configuration.current.departments.map(dept => {
									return (
										<MenuItem value={dept} onClick={(event) => handleClose(event, dept)}
											sx={{
												padding: "12px 10px 12px 10px",
												boxSizing: "border-box"
											}}
										>
											<Box sx={{
												display: "flex",
												flexDirection: "row",
												alignItems: "center",
												justifyContent: "space-between",
												width: "100%",
												gap: "30px",
												boxSizing: "border-box",
												marginLeft: "10px",
												fontWeight: "bold"
											}}>
												{dept}
												{
													dept === "ZMP" ?
													// <TextsmsIcon fontSize='medium' />
													<WMS_LOGO style={{
															height: "23px",
															width: "23px",
															background: theme.palette.mode === "light" ? "#eeeeee" : "rgba(20, 20, 20, 0.8)",
															padding: "6px",
															borderRadius: "100px"
														}} />
														:
														dept === "ZVP" ?
															// <VideocamIcon fontSize='medium' />
															<ZVP_LOGO style={{
																height: "24px",
																width: "24px",
																background: theme.palette.mode === "light" ? "#eeeeee" : "rgba(20, 20, 20, 0.8)",
																padding: "6px",
																borderRadius: "100px"
															}} />
															:
													<Diversity2Icon fontSize='medium' />
												}
											</Box>
										</MenuItem>
									);
								})
							}
						</Menu>
					</Box>
					:
					null
			}
		</Box>
		</>
	);
}


var isUserAllowed;
export default TopBar;