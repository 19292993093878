import { timeLine_page_component_get_query_1,timeLine_page_component_get_query_2,allStatData_page_component_get_query_1} from "../../../../catalystDB/queryGenerator";


export function getFromTimelineDataUserBased(queryString = timeLine_page_component_get_query_1,offsetData)
{
	var increment = 300;
	return new Promise((resolve, reject) => 
	{
		const start = global.performance.now();
		var zcql = window.catalyst.ZCatalystQL;
		var zcqlPromise  = zcql.executeQuery(queryString + " LIMIT " + offsetData + ", " + increment);

		zcqlPromise
				.then((response) => {
		
					const end = global.performance.now();
			
					if(response.status === 400)
					{
						reject("error");
					}
					var newData = response.content.map(v => v.User_Based_Timeline_Report)
					.map(v => [undefined, "", " ", null].includes(v.Data) ? "-" : v.Data);

					resolve(newData);
				})
				.catch((err) => {
					reject(err);
				});
	});
}


export function getFromTimelineDataServerBased(queryString = timeLine_page_component_get_query_2,offsetData)
{
	var increment = 300;
	return new Promise((resolve, reject) => 
	{
		const start = global.performance.now();
		var zcql = window.catalyst.ZCatalystQL;
		var zcqlPromise  = zcql.executeQuery(queryString + " LIMIT " + offsetData + ", " + increment);

		zcqlPromise
				.then((response) => {
		
					const end = global.performance.now();
			
					if(response.status === 400)
					{
						reject("error");
					}

					var newData = response.content.map(v => v.TimelineData)
					.map(v => [undefined, "", " ", null].includes(v.Data) ? "-" : v.Data);

					resolve(newData);				
				})
				.catch((err) => {
					reject(err);
				});
	});
}


export function getForAllDataStat(queryString = allStatData_page_component_get_query_1)
{
	return new Promise((resolve, reject) => 
	{
		const start = global.performance.now();
		var zcql = window.catalyst.ZCatalystQL;
		var zcqlPromise  = zcql.executeQuery(queryString);

		zcqlPromise
				.then((response) => {
		
					const end = global.performance.now();
			
					if(response.status === 400)
					{
						reject("error");
					}

					
	
					// var data = [];
					// for(const item of response.content)
					// {
					// 	const row = item.TimelineData;
					// 	const result = 
					// 		[undefined, "", " ", null].includes(row.Data) ? "-" : row.Data;
					// 	data.push(result);
					// }
					// if(data.length === 0)
					// {
					// 	reject("none");
					// }
					resolve(response.content);
				})
				.catch((err) => {
					reject(err);
				});
	});
}