import React, {useContext} from 'react';

import ReleaseCycle from './releaseCycle/ReleaseCycle.js';
import MyTasks from './myTasks/MyTasks.js';
import Roadmap from './roadmap/Roadmap.js';
import Milestone from './milestone/Milestone.js';
import TaskBreaker from './taskBreaker/TaskBreaker.js';
import Roles from './roles/Roles.js';
import Report from './report/Report.js';

import { TabDetails, TabStateContext } from './../../contexts/TabStateContext.js';

function PageContent()
{
	const {tabState} = useContext(TabStateContext);

	const selectedOuterTab = tabState.current.outer;
	const selectedInnerTab = tabState.current.inner[selectedOuterTab];

	if(selectedInnerTab === null || selectedInnerTab === undefined) {
		return TabDetails[selectedOuterTab].component;
	}

	return TabDetails[selectedOuterTab].innerTabsList[selectedInnerTab].component;
}

export default PageContent;