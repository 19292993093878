import React, { useState, useContext, useEffect } from 'react';
import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { UserContext } from '../../../../contexts/UserContext.js';
import { ButtonGroup } from '@mui/material';
import { Tooltip } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { ReportSection1 } from './ReportSection1.js';
import { ReportSection2 } from './ReportSection2.js';

import "../Report.css";
import { ReportSection3 } from './ReportSection3.js';
import { ReportSection4 } from './ReportSection4.js';
import { ReportSection5 } from './ReportSection5.js';
import { ReportSection6 } from './ReportSection6.js';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Release_Planner_Report } from './Release_Planner_Report/Release_Planner_Report.js';
import { ReleasePlannerReport } from './new_release_planner_report/ReleasePlannerReport.js';
import { FreeResources } from './new_reports/FreeResources.js';


const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	paddingTop: 0,
	paddingBottom: 0,
	color: theme.palette.text.primary,
	height: "calc((100vh - 150px)/2)",
	display: "flex",
	// justifyContent: "center",
	alignItems: "center"
}));

export function Report1() {

	const {allServers,GlobalServer} = useContext(UserContext);
    var SelectedGlobalServer = (GlobalServer === "unknown" || GlobalServer === undefined) ?  "Default" : GlobalServer;
	const [currentSelect, setCurrentSelect] = useState("Default");

	useEffect(() => {
        setCurrentSelect(SelectedGlobalServer);
   }, [GlobalServer]);

	return (
		<Box sx={{
			display: "flex",
			flexDirection: "column",
			flex: 1,
			overflow: "hidden",
			gap: "5px"
		}}  className="page-content-2">
			<ReportTopBar currentSelect={currentSelect} setCurrentSelect={setCurrentSelect} SelectedGlobalServer={SelectedGlobalServer}/>
			<Box sx={{
				display: "flex",
				flex: 1,
				overflowY: "scroll"
			}}>
				<Grid container spacing={0.5}>
					{/* <Grid item xs={4}>
						<Item>
							<Carousel componentsList={['a', 'b', 'c', 'd', 'e']} /> // comment this
							<ReportSection2 currentSelect={currentSelect}/>
						</Item>
					</Grid> */}
					{/* <Grid item xs={4}>
						<Item>
							<Carousel componentsList={['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']} /> // comment this
							<ReportSection3 currentSelect={currentSelect}/>
						</Item>
					</Grid> */}
					<Grid item xs={4}>
						<Item>
							<ReleasePlannerReport currentSelect={currentSelect} />
						</Item>
					</Grid>
					<Grid item xs={8}>
						<Item>
							<FreeResources currentSelect={currentSelect} />
						</Item>
					</Grid>
					{
						currentSelect === 'Default' ?
							null
							:
							<Grid item xs={12} sx={{ paddingTop: "10px !important" }}>
								<Item>
									<ReportSection6 currentSelect={currentSelect} />
								</Item>
							</Grid>
					}
					<Grid item xs={12} sx={{paddingTop: "10px !important"}}>
						<Item>
							<ReportSection1 currentSelect={currentSelect}/>
						</Item>
					</Grid>
					{/* <Grid item xs={6}>
						<Item>
							<Carousel componentsList={[0, 1, 2, 3, 4, 5]} /> // comment this
							<ReportSection4 currentSelect={currentSelect}/>
						</Item>
					</Grid>
					<Grid item xs={6}>
						<Item>
							<Carousel componentsList={[0, 1, 2, 3, 4, 5]} /> // comment this
							<ReportSection5 currentSelect={currentSelect}/>
						</Item>
					</Grid> */}
					{/* <Grid item xs={6}>
						<Item>
							<Carousel componentsList={[0, 1, 2, 3, 4, 5]} />
						</Item>
					</Grid>
					<Grid item xs={6}>
						<Item>
							<Carousel componentsList={[0, 1, 2, 3, 4, 5]} />
						</Item>
					</Grid>
					<Grid item xs={6}>
						<Item>
							<Carousel componentsList={[0, 1, 2, 3, 4, 5]} />
						</Item>
					</Grid>
					<Grid item xs={6}>
						<Item>
							<Carousel componentsList={[0, 1, 2, 3, 4, 5]} />
						</Item>
					</Grid> */}
				</Grid>
			</Box>
		</Box>
	);
}

function ReportTopBar({currentSelect, setCurrentSelect,SelectedGlobalServer}) {
	const [serverAnchor, setServerAnchor] = useState(null);
	const {allServers} = useContext(UserContext);
	function handleServerButtonClose(item)
	{
		setCurrentSelect(item);
		setServerAnchor(null);
	}
	return (
		<div className="Report-top-bar" >
		{/* <div className="Report-top-bar-left-group">
		</div> */}
		<div className="Report-top-bar-right-group" style={{
			display: "flex",
			float: "right",
			marginBottom: "10px"
		}}>
			<ButtonGroup >
				<Tooltip title="select server">
						<Button variant="contained" size="small" onClick={(event) => setServerAnchor(event.currentTarget)} sx={{
							textTransform: "none",
							borderRadius: "40px"
						}}>
							{currentSelect}
							{SelectedGlobalServer != "Default" ? (<></>):(<ArrowDropDownIcon />)}
							
						</Button>
					</Tooltip>
				</ButtonGroup>
				{
					SelectedGlobalServer != "Default" ? (
						<></>
					): (
						<Menu
						anchorEl={serverAnchor}
						open={serverAnchor !== null}
						onClose={() => setServerAnchor(null)}
						 sx={{
							 maxHeight: "750px"
						 }}
					 >
						 <MenuItem key={"0-0"} onClick={() => handleServerButtonClose("Default")}>{"Default"}</MenuItem>
						 {/* <MenuItem key={"0-1"} onClick={() => handleServerButtonClose(<><PlayArrowIcon />{"AutoPlay"}</>)}><PlayArrowIcon />{"AutoPlay"}</MenuItem>  */}
						 {allServers.map((item, index) => {
							 return <MenuItem key={index} onClick={() => handleServerButtonClose(item)}>{item}</MenuItem>
						 })}
					 </Menu>
					)
				}
		
		</div>
	</div>
	);
}

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export function Carousel({ componentsList, heading, autoPlayEnabled, currentSelect="none", section="default" }) {
	const {allServers} = useContext(UserContext);
	const [autoPlay, setAutoPlay] = useState(autoPlayEnabled);
	var currentIndex = 0;
	if(currentSelect === "Default") {
		if(section === "section-2") {
			currentIndex = componentsList.length - 1;
		}
	}
	else if(currentSelect !== "none" && typeof currentSelect !== "object") {
		currentIndex = allServers.indexOf(currentSelect);
	}

	useEffect(() => {
		setActiveStep(currentIndex);
	}, [currentIndex]);

	useEffect(() => {
		setAutoPlay(autoPlayEnabled);
	}, [autoPlayEnabled])

	const theme = useTheme();
	const [activeStep, setActiveStep] = useState(currentIndex);
	const maxSteps = componentsList.length;

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStepChange = (step) => {
		setActiveStep(step);
	};

	return (
		<Box sx={{
			flexGrow: 1,
			width: "100%"
		}}
			onMouseEnter={() => {
				// console.log("auto play paused");
				if(autoPlay === true) {
					setAutoPlay(false);
				}
			}}

			onMouseLeave={() => {
				// console.log("auto play resumed");
				if(autoPlay === false) {
					setAutoPlay(true);
				}
			}}
		>
			<AutoPlaySwipeableViews
				axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
				index={activeStep}
				onChangeIndex={handleStepChange}
				enableMouseEvents
				interval={3000}
				autoplay={autoPlayEnabled && autoPlay}
			>
				{componentsList.map((comp, index) => (
					<div key={index}>
						{Math.abs(activeStep - index) <= 2 ? (
							<Box
								sx={{
									height: "calc((100vh - 220px)/2)",
									display: 'flex',
									overflow: 'hidden',
									width: '100%',
									alignItems: "center",
									justifyContent: "center"
								}}
							>
								{comp}
							</Box>
						) : null}
					</div>
				))}
			</AutoPlaySwipeableViews>
			<Box sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				height: "10px",
				paddingTop: "3px"
			}}>
				<Button size="small" onClick={handleBack} disabled={activeStep === 0}>
					{theme.direction === 'rtl' ? (
						<KeyboardArrowRight fontSize='10px' />
					) : (
						<KeyboardArrowLeft fontSize='10px' />
					)}
				</Button>
				<MobileStepper
					steps={maxSteps}
					position="static"
					activeStep={activeStep}
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						bgcolor: "transparent",
						scale: "0.8",
						width: "auto"
					}}
				/>
				<Button
					size="small"
					onClick={handleNext}
					disabled={activeStep === maxSteps - 1}

				>
					{theme.direction === 'rtl' ? (
						<KeyboardArrowLeft fontSize='10px' />
					) : (
						<KeyboardArrowRight fontSize='10px' />
					)}
				</Button>
			</Box>
		</Box>
	);
}