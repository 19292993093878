/* eslint-disable react/prop-types */

import React, {useEffect} from "react";
import { Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { SplitChart } from "../../../../../util/components/Split/SplitChart";


export function Split({data}) {

    const theme = useTheme();

    var colors = [];
    if(theme.palette.mode === "light") {
        colors = [ "#B21D1D", "#D6833E",  "#1BBF54", "#CC6E14", "#3982E3", "#A85FC9"];
    } else {
        colors = ["#E64256", "#E59659",  "#1DCF5B", "#EB7317", "#3982E3", "#BF6CE6"];
    }

    return (
        <Box sx={{
            height: "100%",
            width: "100%"
        }}>
            <SplitChart data={data.server_shares} colors={colors} theme={theme}/>
        </Box>
    );
}