import React, { useContext } from "react";
import { Carousel } from "./Report1.js";
import { useEffect, useRef, useState } from "react";
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Tab } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';
import { Skeleton } from '@mui/material';
import { getSection1Data1, getSection1Data2, getSection1Data3 } from "./ReportDataFetcher.js";
import CircularProgress from '@mui/material/CircularProgress';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { UserContext } from "../../../../contexts/UserContext.js";

export function ReportSection1({currentSelect}) {
    const myTheme = useTheme();
    // const Tab2_memo = React.memo(() => {
    //     console.log("here");
    //     return <Tab2 theme={myTheme} />;
    // }, [myTheme]);
    return (
        //<Carousel componentsList={[<Tab2_memo />]} />
        <Carousel componentsList={[<Tab1 theme={myTheme} currentSelect={currentSelect}/>, <Tab2 theme={myTheme} currentSelect={currentSelect}/>, <Tab3 theme={myTheme} currentSelect={currentSelect}/>]} autoPlayEnabled={typeof currentSelect === "object"} />
        // <Tab1 theme={myTheme} />
    );
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.primary,
    height: "calc((100vh - 160px)/8)",
    display: "flex",
    justifyContent: "center"
    // alignItems: "center"
}));

function Tab1({ theme, currentSelect }) {
    const [loaded, setLoaded] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const {loginUserDept} = useContext(UserContext);

    var data = {
        "canvas": {
            "theme": "zo",
            "fontFamily": "poppins",
            "fontColor": theme.palette.mode === "light" ? "black" : "white",
            "title": {
                "show": false,
                "text": "Overall Task Status",
                "fontWeight": "bold"
            },
            "subtitle": {
                "show": false
            },
            "background": {
                "alpha": 0
            }
        },
        "seriesdata": {
            "chartdata": [
                {
                    "type": "pyramid",
                    "yaxiscolumnorder": [
                        0
                    ],
                    "data": [
                        dataArr
                    ]
                }
            ]
        },
        "metadata": {
            "axes": {
                "x": [
                    0
                ],
                "y": [
                    [
                        1
                    ]
                ],
                "label": [
                    "{{val(1)}} ({{per(1)}})"
                ],
                "tooltip": [
                    0,
                    1
                ]
            },
            "columns": [
                {
                    "dataindex": 0,
                    "columnname": "Status",
                    "datatype": "ordinal"
                },
                {
                    "dataindex": 1,
                    "columnname": "Total",
                    "datatype": "numeric",
                    "numeric": {
                        "format": {
                            "type": "SIprefix"
                        },
                        "percentformat": {
                            "decimalPlaces": 1,
                            "suffix": "%",
                            "trailingZeros": false
                        }
                    }
                }
            ]
        },
        "legend": {
            "layout": "horizontal",
            "vAlign": "bottom",
            "hAlign": "center",
            "colors": [
                "pink",
                "green",
                "orange",
                "goldenrod",
                "brown",
                "purple",
                "violet",
                "red",
                "crimson",
                "tomato"
            ]
        },
        "chart": {
            "plot": {
                // "animation": {
                //     "enabled": false
                // },
                "datalabels": {
                    "handleOverlapping": true
                },
                "plotoptions": {
                    "pyramid": {
                        "threeDimension": true,
                        "datalabels": {
                            "type": "doubleside"
                        }
                    }
                }
            }
        }
    };

    const chartDiv = useRef();

    useEffect(() => {
        setLoaded(false);
    }, [currentSelect]);

    useEffect(() => {
		async function run() {
            if(loaded) {
                var chart = new window.$ZC.charts(chartDiv.current, data);
            } else {
                try {
                    var dataArr = await getSection1Data1(currentSelect, loginUserDept);
                    // console.log(dataArr)
                    setDataArr(dataArr);
                    setLoaded(true);
                } catch(e) {
                    setLoaded(null);
                }
            }
		}
		run();
	}, [theme.palette.mode, loaded]);

    if(loaded === null) {
        return <h1>uh oh! nothing found! :(</h1>;
    }

    if(!loaded) {
        return <h1><CircularProgress /></h1>;
    }

    var ongoing_total = 0;
    try {
        ongoing_total = Number.parseInt(dataArr[2][1])+Number.parseInt(dataArr[3][1]);
    } catch(e) {}

    return (
        <>
            <div style={{
                width: "calc(60%)",
                height: "100%",
            }}>
                <Box style={{
                    padding: "0px 10px",
                    fontWeight: 600,
                    fontSize: "calc(1.0vw)",
                    color: theme.palette.mode === "light" ? "black" : "white"
                }}>
                    <span style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                        flexDirection: "row"
                    }}>
                        Task Status
                        <ArrowRightIcon sx={{
                            fontSize: "calc(1.0vw)"
                        }} />
                        {currentSelect === "Default" || typeof currentSelect === "object" ? "Overall" : currentSelect}
                    </span>
                </Box>
                <div ref={chartDiv} style={{
                    height: "calc(94%)",
                    justifyContent: "left"
                }}
                >
                </div>
            </div>
            <div style={{
                width: "calc(40%)"
            }}
            >
                <Grid container spacing={1.5}>
                    <Grid item xs={4}>
                        <Item elevation={1} sx={{
                            backgroundColor: "rgb(255,192,203,0.08)",
                            "&:hover": {
                                backgroundColor: "rgb(255,192,203,0.2)"
                            },
                            height: "calc(88%)"
                        }}>
                            <CountDisplay label={"RMap"} value={dataArr[0][1]} color={"pink"} />
                        </Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item elevation={1} sx={{
                            backgroundColor: "rgb(0,128,0,0.08)",
                            "&:hover": {
                                backgroundColor: "rgb(0,128,0,0.2)"
                            },
                            height: "calc(88%)"
                        }}>
                            <CountDisplay label={"Open"} value={dataArr[1][1]} color={"green"} />
                        </Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item elevation={1} sx={{
                            backgroundColor: "rgb(255,165,0,0.08)",
                            "&:hover": {
                                backgroundColor: "rgb(255, 165, 0,0.2)"
                            },
                            height: "calc(88%)"
                        }}>
                            <CountDisplay label={"Ongoing"} value={ongoing_total} color={"orange"} />
                        </Item>
                    </Grid>
                    {/* <Grid item xs={3}>
                        <Item elevation={1} sx={{
                            backgroundColor: "rgb(184, 134, 11, 0.08)",
                            "&:hover": {
                                backgroundColor: "rgb(184, 134, 11, 0.2)"
                            },
                            height: "calc(88%)"
                        }}>
                            <CountDisplay label={"In Progress"} value={dataArr[3][1]} color={"goldenrod"} />
                        </Item>
                    </Grid> */}
                    <Grid item xs={4}>
                        <Item elevation={1} sx={{
                            backgroundColor: "rgb(165, 45, 45, 0.08)",
                            "&:hover": {
                                backgroundColor: "rgb(165, 45, 45, 0.2)"
                            },
                            height: "calc(88%)"
                        }}>
                            <CountDisplay label={"On Hold"} value={dataArr[4][1]} color={"brown"} />
                        </Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item elevation={1} sx={{
                            backgroundColor: "rgb(128, 0, 128, 0.08)",
                            "&:hover": {
                                backgroundColor: "rgb(128, 0, 128, 0.2)"
                            },
                            height: "calc(88%)"
                        }}>
                            <CountDisplay label={"To be Tested"} value={dataArr[5][1]} color={"purple"} />
                        </Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item elevation={1} sx={{
                            backgroundColor: "rgb(238, 130, 238, 0.08)",
                            "&:hover": {
                                backgroundColor: "rgb(238, 130, 238, 0.2)"
                            },
                            height: "calc(88%)"
                        }}>
                            <CountDisplay label={"In Review"} value={dataArr[6][1]} color={"violet"} />
                        </Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item elevation={1} sx={{
                            backgroundColor: "rgb(255, 0, 0, 0.08)",
                            "&:hover": {
                                backgroundColor: "rgb(255, 0, 0, 0.2)"
                            },
                            height: "calc(88%)"
                        }}>
                            <CountDisplay label={"Delayed"} value={dataArr[7][1]} color={"red"} />
                        </Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item elevation={1} sx={{
                            backgroundColor: "rgb(220, 20, 60, 0.08)",
                            "&:hover": {
                                backgroundColor: "rgb(220, 20, 60, 0.2)"
                            },
                            height: "calc(88%)"
                        }}>
                            <CountDisplay label={"Cancelled"} value={dataArr[8][1]} color={"crimson"} />
                        </Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item elevation={1} sx={{
                            backgroundColor: "rgb(255, 99, 71, 0.08)",
                            "&:hover": {
                                backgroundColor: "rgb(255, 99, 71, 0.2)"
                            },
                            height: "calc(88%)"
                        }}>
                            <CountDisplay label={"Closed"} value={dataArr[9][1]} color={"tomato"} />
                        </Item>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

function Tab2({ theme, currentSelect }) {
    const [loaded, setLoaded] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const {loginUserDept} = useContext(UserContext);

    var data = {
        "canvas": {
            "theme": "zo",
            "fontFamily": "poppins",
            "fontColor": theme.palette.mode === "light" ? "black" : "white",
            "title": {
                "show": false,
                "text": "Overall Tasklist Status",
                "fontWeight": "bold"
            },
            "subtitle": {
                "show": false
            },
            "background": {
                "alpha": 0
            }
        },
        "seriesdata": {
            "chartdata": [
                {
                    "type": "pyramid",
                    "yaxiscolumnorder": [
                        0
                    ],
                    "data": [
                        dataArr
                    ]
                }
            ]
        },
        "metadata": {
            "axes": {
                "x": [
                    0
                ],
                "y": [
                    [
                        1
                    ]
                ],
                "label": [
                    "{{val(1)}} ({{per(1)}})"
                ],
                "tooltip": [
                    0,
                    1
                ]
            },
            "columns": [
                {
                    "dataindex": 0,
                    "columnname": "Status",
                    "datatype": "ordinal"
                },
                {
                    "dataindex": 1,
                    "columnname": "Total",
                    "datatype": "numeric",
                    "numeric": {
                        "format": {
                            "type": "SIprefix"
                        },
                        "percentformat": {
                            "decimalPlaces": 1,
                            "suffix": "%",
                            "trailingZeros": false
                        }
                    }
                }
            ]
        },
        "legend": {
            "layout": "horizontal",
            "vAlign": "bottom",
            "hAlign": "center",
            "colors": [
                "green",
                "orange",
                "brown",
                "tomato"
            ]
        },
        "chart": {
            "plot": {
                // "animation": {
                //     "enabled": false
                // },
                "datalabels": {
                    "handleOverlapping": true
                },
                "plotoptions": {
                    "pyramid": {
                        "threeDimension": true,
                        "datalabels": {
                            "type": "doubleside"
                        }
                    }
                }
            }
        }
    };

    useEffect(() => {
        setLoaded(false);
    }, [currentSelect]);

    const chartDiv = useRef();

    useEffect(() => {
		async function run() {
            if(loaded) {
                var chart = new window.$ZC.charts(chartDiv.current, data);
            } else {
                try {
                    setDataArr(await getSection1Data2(currentSelect, loginUserDept));
                    setLoaded(true);
                } catch(e) {
                    setLoaded(null);
                }
            }
		}
		run();
	}, [theme.palette.mode, loaded]);

    if(loaded === null) {
        return <h1>unable to fetch :(</h1>;
    }

    if(!loaded) {
        return <h1><CircularProgress /></h1>;
    }

    return (
        <>
            <div style={{
                width: "calc(60%)",
                height: "100%",
            }}>
                <Box style={{
                    padding: "0px 10px",
                    fontWeight: 600,
                    fontSize: "calc(1.0vw)",
                    color: theme.palette.mode === "light" ? "black" : "white"
                }}>
                    <span style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                        flexDirection: "row"
                    }}>
                        Tasklist Status
                        <ArrowRightIcon sx={{
                            fontSize: "calc(1.0vw)"
                        }} />
                        {currentSelect === "Default" || typeof currentSelect === "object" ? "Overall" : currentSelect}
                    </span>
                </Box>
                <div ref={chartDiv} style={{
                    height: "calc(90%)",
                    justifyContent: "left"
                }}
                >
                </div>
            </div>
            <div style={{
                width: "calc(40%)"
            }}
            >
                <Grid container spacing={0.75}>
                    <Grid item xs={6}>
                        <Item elevation={1} sx={{
                            backgroundColor: "rgb(0,128,0,0.08)",
                            "&:hover": {
                                backgroundColor: "rgb(0,128,0,0.2)"
                            }
                        }}>
                            <CountDisplay label={"Open"} value={dataArr[0][1]} color={"green"} />
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                        <Item elevation={1} sx={{
                            backgroundColor: "rgb(255,165,0,0.08)",
                            "&:hover": {
                                backgroundColor: "rgb(255, 165, 0,0.2)"
                            }
                        }}>
                            <CountDisplay label={"Ongoing"} value={dataArr[1][1]} color={"orange"} />
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                        <Item elevation={1} sx={{
                            backgroundColor: "rgb(165, 45, 45, 0.08)",
                            "&:hover": {
                                backgroundColor: "rgb(165, 45, 45, 0.2)"
                            }
                        }}>
                            <CountDisplay label={"On Hold"} value={dataArr[2][1]} color={"brown"} />
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                        <Item elevation={1} sx={{
                            backgroundColor: "rgb(255, 99, 71, 0.08)",
                            "&:hover": {
                                backgroundColor: "rgb(255, 99, 71, 0.2)"
                            }
                        }}>
                            <CountDisplay label={"Completed"} value={dataArr[3][1]} color={"tomato"} />
                        </Item>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

function Tab3({ theme, currentSelect }) {
    const [loaded, setLoaded] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const {loginUserDept} = useContext(UserContext);

    var data = {
        "canvas": {
            "theme": "zo",
            "fontFamily": "poppins",
            "fontColor": theme.palette.mode === "light" ? "black" : "white",
            "title": {
                "show": false,
                "text": "Overall Milestone Status",
                "fontWeight": "bold"
            },
            "subtitle": {
                "show": false
            },
            "background": {
                "alpha": 0
            }
        },
        "seriesdata": {
            "chartdata": [
                {
                    "type": "pyramid",
                    "yaxiscolumnorder": [
                        0
                    ],
                    "data": [
                        dataArr
                    ]
                }
            ]
        },
        "metadata": {
            "axes": {
                "x": [
                    0
                ],
                "y": [
                    [
                        1
                    ]
                ],
                "label": [
                    "{{val(1)}} ({{per(1)}})"
                ],
                "tooltip": [
                    0,
                    1
                ]
            },
            "columns": [
                {
                    "dataindex": 0,
                    "columnname": "Status",
                    "datatype": "ordinal"
                },
                {
                    "dataindex": 1,
                    "columnname": "Total",
                    "datatype": "numeric",
                    "numeric": {
                        "format": {
                            "type": "SIprefix"
                        },
                        "percentformat": {
                            "decimalPlaces": 1,
                            "suffix": "%",
                            "trailingZeros": false
                        }
                    }
                }
            ]
        },
        "legend": {
            "layout": "horizontal",
            "vAlign": "bottom",
            "hAlign": "center",
            "colors": [
                "goldenrod",
                "tomato"
            ]
        },
        "chart": {
            "plot": {
                // "animation": {
                //     "enabled": false
                // },
                "datalabels": {
                    "handleOverlapping": true
                },
                "plotoptions": {
                    "pyramid": {
                        "threeDimension": true,
                        "datalabels": {
                            "type": "doubleside"
                        }
                    }
                }
            }
        }
    };

    useEffect(() => {
        setLoaded(false);
    }, [currentSelect]);

    const chartDiv = useRef();

    useEffect(() => {
		async function run() {
            if(loaded) {
                var chart = new window.$ZC.charts(chartDiv.current, data);
            } else {
                try {
                    setDataArr(await getSection1Data3(currentSelect, loginUserDept));
                    setLoaded(true);
                } catch(e) {
                    setLoaded(null);
                }
            }
		}
		run();
	}, [theme.palette.mode, loaded]);

    if(loaded === null) {
        return <h1>unable to fetch :(</h1>;
    }

    if(!loaded) {
        return <h1><CircularProgress /></h1>;
    }

    return (
        <>
            <div style={{
                width: "calc(60%)",
                height: "100%",
            }}>
                <Box style={{
                    padding: "0px 10px",
                    fontWeight: 600,
                    fontSize: "calc(1.0vw)",
                    color: theme.palette.mode === "light" ? "black" : "white"
                }}>
                    <span style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                        flexDirection: "row"
                    }}>
                        Milestone Status
                        <ArrowRightIcon sx={{
                            fontSize: "calc(1.0vw)"
                        }} />
                        {currentSelect === "Default" || typeof currentSelect === "object" ? "Overall" : currentSelect}
                    </span>
                </Box>
                <div ref={chartDiv} style={{
                    height: "calc(90%)",
                    justifyContent: "left"
                }}
                >
                </div>
            </div>
            <div style={{
                width: "calc(40%)"
            }}
            >
                <Grid container spacing={0.75}>
                    <Grid item xs={6}>
                        <Item elevation={1} sx={{
                            backgroundColor: "rgb(184, 134, 11, 0.08)",
                            "&:hover": {
                                backgroundColor: "rgb(184, 134, 11, 0.2)"
                            }
                        }}>
                            <CountDisplay label={"Ongoing"} value={dataArr[0][1]} color={"goldenrod"} />
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                        <Item elevation={1} sx={{
                            backgroundColor: "rgb(255, 99, 71, 0.08)",
                            "&:hover": {
                                backgroundColor: "rgb(255, 99, 71, 0.2)"
                            }
                        }}>
                            <CountDisplay label={"Completed"} value={dataArr[1][1]} color={"tomato"} />
                        </Item>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

function CountDisplay({ label, value, color }) {
    return (
        <Box style={{
            width: "100%",
            color: color
        }}>
            <Box style={{
                fontWeight: "medium",
                fontSize: "calc(0.75vw)",
                width: "100%",
                textAlign: "center",
                color: color,
                marginTop: "10px"
            }}>
                {label}
            </Box>
            <Box style={{
                marginTop: "0px",
                textAlign: "center",
                fontSize: "calc(3.5vw)",
                color: color,
                lineHeight: 1
            }}
            >
                {value}
            </Box>
        </Box>
    )
}
