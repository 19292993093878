import { ticketsReport_page_component_get_query_1 } from "../../../../catalystDB/queryGenerator";

export async function getFromTicketsReport(queryString = ticketsReport_page_component_get_query_1, offset) {
	// console.log(offset)
	// console.log(increment)
	var increment = 300;
    return new Promise((resolve, reject) => {
        const start = global.performance.now();
        var zcql = window.catalyst.ZCatalystQL;
        var zcqlPromise = zcql.executeQuery(queryString + " LIMIT " + offset + ", " + increment);

        zcqlPromise
            .then(async (response) => {
                if (response.status === 400) {
                    reject("error");
                }

				var newData = response.content.map(v => v.TicketsReport)
					.map(v => [undefined, "", " ", null].includes(v.data) ? "-" : v.data);

				resolve(newData);
               
			// 	var dataToConcat = [];
			// 	if (offset === 1) {
			// 		for (const item of response.content) {
			// 			const row = item.TicketsReport;
			// 			const result = [undefined, "", " ", null].includes(row.data) ? "-" : row.data;
			// 			data.push(result);
			// 		}
			// 	}
			// 	else{
			// 		// console.log(data)
			// 		for (const item of response.content) {
			// 			const row = item.TicketsReport;
			// 			const resultToConcat = [undefined, "", " ", null].includes(row.data) ? "-" : row.data;
			// 			dataToConcat.push(resultToConcat);
			// 		}
			// 	}
			//    console.log(dataToConcat);
			//    var ToStoreTheConcatData = [...data, ...dataToConcat];
            //    console.log(ToStoreTheConcatData)

                // if (response.content.length !== 0) { // Check if there is more data
                //     const nextResponses = await getFromTicketsReport(queryString, offset + increment, increment);
				// 	for (const value of nextResponses) {
				// 		const row = value.TicketsReport;
				// 		const result = [undefined, "", " ", null].includes(row.data) ? "-" : row.data;
				// 		data.concat(result);
				// 	}
				// 	console.log("Inside if >>>>" + data)
                // }

                // if (data.length === 0) {
                //     reject("none");
                // }
				// if (offset == 1) {
				// 	resolve(data);
				// }
				// else{
				// 	resolve(ToStoreTheConcatData);
				// }
                
            })
            .catch((err) => {
                reject(err);
            });
    });
}
