import React, {useState} from "react";

import { Box, Tooltip } from "@mui/material";

export function SplitChart({data, colors, theme}) {
    const total = Object.values(data).reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
    });

    const map = {};

    for(var k in data) {
        const v = data[k];

        map[k] = perc(v, total);
    }

    return (
        <Box sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            {
                Object.keys(map).map((k, ind) => {
                    const v = map[k];
                    return (
                        <Tooltip title={
                            <>
                                {k}
                                <span style={{ fontSize: "0.8rem", marginLeft: "5px" }}>
                                    {`- ${v.toFixed(1)}% `}
                                </span>
                            </>
                        }>
                            <Box sx={{
                                backgroundColor: colors[ind],
                                width: v + "%",
                                height: "40%",
                                padding: "7px",
                                borderRadius: "2px",
                                fontWeight: "bold",
                                color: theme.palette.mode === "light" ? "#eeffff" : "black",
                                fontSize: "1.1rem",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px",
                                textOverflow: "hidden",
                                overflow: "hidden",
                                cursor: "default",
                                whiteSpace: "nowrap"
                            }}>
                                {
                                    v < 5 ? 
                                        null
                                        :
                                    <>
                                        {k}
                                        <span style={{ fontSize: "0.8rem" }}>
                                            {` {${v.toFixed(1)}%} `}
                                        </span>
                                    </>
                                }
                                
                            </Box>
                        </Tooltip>
                    );
                })
            }
        </Box>
    );
}

function perc(a, b) {
    return (a===0 || b===0) ? 0 : ((a/b)*100);
}