import { isDateInRange, isDateString, isTask } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    var issueCount = 0;
    var tasksCount = 0;

    catalystDB.Tickets.map(item => {
        const dateString = item.CREATEDTIME;

        if(isDateString(dateString) && isDateInRange(dateString, startTime)) {      
            issueCount++;
        }
    }); 

    catalystDB.TaskBreaker.map(item => {
        const dateString = item.CREATEDTIME;

        if(isDateString(dateString) && isDateInRange(dateString, startTime) && isTask(item)) {      
            tasksCount++;
        }
    });

    var chartData = [
        [
            "Tasks",
            tasksCount
        ],
        [
            "Issues",
            issueCount
        ]
    ];

    return chartData;
}