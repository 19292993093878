import { isDateInRange, isDateString, isTask } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    const chartData = [];
    const dataMap = {};
    const taskStatusMap = {};

    catalystDB.TaskBreaker.map(item => {
        const dateString = item.CREATEDTIME;


        if(isDateString(dateString) && isDateInRange(dateString, startTime) && isTask(item)) {
            const TaskStatus = item.TaskStatus;

            if([undefined, null, "", " "].includes(TaskStatus)) {
                return;
            }

            if(TaskStatus in taskStatusMap) {
                taskStatusMap[TaskStatus] += 1;
            } else {
                taskStatusMap[TaskStatus] = 1;
            }

            if(TaskStatus in dataMap) {
                dataMap[TaskStatus].push(item);
            } else {
                dataMap[TaskStatus] = [item];
            }
        }
    }); 

    const power = {
        "Open": 10,
        "Delayed": 9,
        "Cancelled": 8,
        "Ongoing": 7,
        "In Progress": 6,
        "On Hold": 5,
        "To be Tested": 4,
        "In Review": 3,
        "Completed": 2,
        "Closed": 1,
        "Updated to IDC": 0,
        "RMap": -1,
        "QRMap": -2
    };

    var keySet = Object.keys(taskStatusMap);

    keySet.sort((a, b) => {
        const power_1 = power[a];
        const power_2 = power[b];

        if(power_1 === undefined || power_2 === undefined) {
            return 0;
        } else if (power_1 > power_2) {
            return -1;
        } else {
            return 1;
        }
    });

    for(const key of keySet) {
        chartData.push([
            key,
            taskStatusMap[key]
        ]);
    }
    
    return [chartData, dataMap];
}