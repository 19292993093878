import { isDateInRange, isDateString, isTask } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    const chartData = [];
    const dataMap = {
        "Tasks Completed": []
    };
    
    var taskCount = 0;
    var taskCompletedCount = 0;

    catalystDB.TaskBreaker.map(item => {

        const dateString = item.CREATEDTIME;


        if(isDateString(dateString) && isDateInRange(dateString, startTime) && isTask(item)) {
            const completionDateString = item.TaskCompletionDate;

            taskCount++;

            if([undefined, null, "", " "].includes(completionDateString) && !isDateString(completionDateString)) {
                return;
            }

            dataMap["Tasks Completed"].push(item);

            taskCompletedCount++;
        }
    }); 

    chartData.push({
        "type": "bullet",
        "seriesname": "Tasks Completed",
        "data": [
            [
                [
                    "",
                    taskCompletedCount,
                    [
                        taskCount
                    ]
                ]
            ]
        ]
    });

    // chartData.push({
    //     "type": "bullet",
    //     "seriesname": "Total Tasks",
    //     "data": [
    //         [
    //             [
    //                 "",
    //                 taskCount - taskCompletedCount,
    //                 [
    //                     taskCount
    //                 ]
    //             ]
    //         ]
    //     ]
    // });

    return [chartData, dataMap];
}