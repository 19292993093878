/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import { Skeleton } from '@mui/material';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
// import ListItemText from '@mui/material/ListItemText';
// import ListItem from '@mui/material/ListItem';
// import List from '@mui/material/List';
// import Divider from '@mui/material/Divider';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { TransitionProps } from '@mui/material/transitions';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import { queryDB } from '../../../util/QueryDB';
import Task from '../../../pageBody/pageContent/milestone/Task.js'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { GetNameAndEmpID, days_between } from '../../../util/Loading.js';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import InsertPageBreakRoundedIcon from '@mui/icons-material/InsertPageBreakRounded';
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import ArrowCircleUpTwoToneIcon from '@mui/icons-material/ArrowCircleUpTwoTone';
import ArrowCircleDownTwoToneIcon from '@mui/icons-material/ArrowCircleDownTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import Brightness1TwoToneIcon from '@mui/icons-material/Brightness1TwoTone';
import StopIcon from '@mui/icons-material/Stop';
import PentagonIcon from '@mui/icons-material/Pentagon';
import CircleIcon from '@mui/icons-material/Circle';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Opacity } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export function QuartelyTaskList({ TasklistName, DRI, TasklistPriority, TasklistStartDate,TasklistDueDate,TasklistStatus,milestone_name,TaskServerName,Tasklistid,RoadMapName,TasklistDescription}) {
    // console.log(Feature_type)
	const theme = useTheme();
	return (
		<Box className="info-div-paper"
		sx={{
			padding: "40px",
			margin: "10px",
			// backgroundColor:"#7e9a9a"
			backgroundColor: theme.palette.background.infoDivInner + " !important"
		}}
	>
	       <ProgressBar TasklistName={TasklistName} DRI={DRI}  TasklistPriority={TasklistPriority} TasklistStartDate={TasklistStartDate} TasklistDueDate={TasklistDueDate} TasklistStatus={TasklistStatus} milestone_name={milestone_name} TaskServerName={TaskServerName} Tasklistid={Tasklistid} RoadMapName={RoadMapName} TasklistDescription={TasklistDescription}/>
    
			<QuartelyTaskListTable TasklistName={TasklistName} DRI={DRI}  TasklistPriority={TasklistPriority} TasklistStartDate={TasklistStartDate} TasklistDueDate={TasklistDueDate} TasklistStatus={TasklistStatus} milestone_name={milestone_name} TaskServerName={TaskServerName} Tasklistid={Tasklistid} RoadMapName={RoadMapName} TasklistDescription={TasklistDescription}/>
		</Box>
	);
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 22,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor: theme.palette.primary.main,
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor: theme.palette.mode === 'dark' ? "#717171" : '#a0a0a0',
		borderRadius: 1,
	},
}));

function ProgressBar({  milestone_name, Server, DRI, Milestone_Startdate,MilestoneDueDate,index,MilestoneID,milestone_status,RoadMapName,TasklistDescription }) {
	// console.log(milestone_status)
	// Milestone Created , Task Breaking scheduled, ongoing completed
	const steps = [
		"Milestone Created",
		"Task Breaking",
		"scheduled",
		"ongoing",
		"Completed"
	];


	const [activeStep, setActiveStep] = useState(null);

	useEffect(() => {
		async function run() {
			if (milestone_status === "Completed") {
				setActiveStep(4);
				return;
			}
			else {
				try {
					// const tasks = await getTasks("SELECT TaskID FROM TaskBreaker WHERE MilestoneID= " + milestoneId);
	
					// setActiveStep(1);
					const startDate = new Date(Milestone_Startdate);
					const now = new Date();
	
					if (Milestone_Startdate === "-") {
						setActiveStep(1);
						return;
					}
					else if (now < startDate) {
						setActiveStep(2);
						return;
					}
					else {
						setActiveStep(3);
						return;
					}
				}
				catch (e) {
					if (e === "none") {
						setActiveStep(0);
						return;
					}
					console.log(e);
					// setActiveStep(-1);
					return;
				}
			}
		}
		run();
	}, []);


	if (activeStep === null) {
		return <Skeleton width={"100%"} height={50} />;
	}

	return (
		<>
			<Stepper activeStep={activeStep} connector={<ColorlibConnector />}>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
		</>
	);
}
export function QuartelyTaskListTable({ TasklistName, DRI, TasklistPriority, TasklistStartDate,TasklistDueDate,TasklistStatus,milestone_name,TaskServerName,Tasklistid,RoadMapName,TasklistDescription}) {


    return (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
                
              <TableRow  className="tasklist-table-head">
                <TableCell align="center" >TaskList Name</TableCell>
                <TableCell align="center" >DRI</TableCell>
                <TableCell align="center" >Priority</TableCell>
                <TableCell align="center" >TaskList Start Date</TableCell>
                <TableCell align="center" >TaskList Due Date</TableCell>
                <TableCell align="center" >TaskList Status</TableCell>

                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
			{/* {selectedTaskIndex !== null && (
				<Dialog
					fullScreen
					open={open}
					TransitionComponent={Transition}
					keepMounted
					onClose={handleClose}
					PaperProps={{
					style: {
						backgroundColor: theme.palette.background.pageContent,
						boxShadow: "none"
					}
					}}
				>
					<div style={{ display: "block" }}>
					<DialogTitle style={{ float: "left", display: "flex", alignItems: "center" }}>
						<Tooltip title={"Milestone Name"}><div>{milestone_name}</div></Tooltip>
						<ArrowRightIcon />
						<Tooltip title={"Tasklist Name"}><div>{TasklistName[selectedTaskIndex]}</div></Tooltip>
					</DialogTitle>
					<DialogActions style={{ float: "right", padding: "16px 24px 16px 24px" }}>
						<Button onClick={handleClose} variant="text"><CloseIcon fontSize="medium" /></Button>
					</DialogActions>
					</div>
					<DialogContent style={{ paddingTop: 0 }}>
					<DialogContentText component={'span'}>
						<Task
						tasklistId={Tasklistid[selectedTaskIndex]}
						milestoneName={milestone_name[0]}
						tasklistName={TasklistName[selectedTaskIndex]}
						serverName={TaskServerName[selectedTaskIndex]}
						/>
					</DialogContentText>
					</DialogContent>
				</Dialog>
				)} */}
			{TasklistName.map((name, index) => (
				<TaskListTableRow
				key={index}
				tasklistName={name}
				DRI={DRI[index]}
				tasklistPriority={TasklistPriority[index]}
				tasklistStartDate={TasklistStartDate[index]}
				tasklistDueDate={TasklistDueDate[index]}
				tasklistStatus={TasklistStatus[index]}
				milestoneName={milestone_name[0]}
				serverName={TaskServerName[index]}
				Tasklistid ={Tasklistid[index]}
				RoadMapName={RoadMapName}
				TasklistDescription={TasklistDescription}
			  />
        ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
}

function TaskListTableRow({ tasklistName, DRI, tasklistPriority, tasklistStartDate, tasklistDueDate, tasklistStatus, milestoneName, serverName,Tasklistid, RoadMapName,TasklistDescription}) {
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const [nameCheck,setNameCheck] = useState(false)
	const [completedTask,setCompletedTask] = useState(false)

	


	useEffect(() => {
		if(RoadMapName === tasklistName){
			setNameCheck(true)
		}

		if(tasklistStatus === "Completed" || tasklistStatus==="Closed"){
			setCompletedTask(true)
		}
	}, []);
	
  
	const handleClickOpen = () => {
	  setOpen(true);
	};
  
	const handleClose = () => {
	  setOpen(false);
	};
	var status = <></>;

	if (tasklistStatus === "Ongoing") {
		status = <AccessTimeFilledRoundedIcon style={{ color: "orange" }} />;
	}
	else if (tasklistStatus === "Completed") {
		status = <CheckCircleRoundedIcon style={{ color: "green" }} />;
	}
	else if (tasklistStatus === "Ready for task breaking") {
		status = <InsertPageBreakRoundedIcon style={{ color: "chocolate" }} />;
	}
	else if (tasklistStatus === "Broken down into individual tasks") {
		status = <PlaylistAddCheckRoundedIcon style={{ color: "teal" }} />;
	}

	var priority = "none";

	if (tasklistPriority === "High") {
		priority = <ArrowCircleUpTwoToneIcon style={{ color: "crimson" }} />;
	}
	else if (tasklistPriority === "Medium") {
		priority = <RemoveCircleTwoToneIcon style={{ color: "orange" }} />;
	}
	else if (tasklistPriority === "Low") {
		priority = <ArrowCircleDownTwoToneIcon style={{ color: "mediumseagreen" }} />;
	}
	else {
		priority = <Brightness1TwoToneIcon style={{ color: "grey" }} />;
	}

	const statusComponent = {
		"Open": {
			"name": "Open",
			"color": theme.palette.mode === "dark" ? "cornflowerblue" : "blue",
			"component": <PlayArrowIcon style={{ width: "22px", marginRight: "1px" }} />
		},
		"Ongoing": {
			"name": "Ongoing",
			"color": "orange",
			"component": <CircleIcon style={{ width: "15px", marginRight: "5px" }} />
		},
		"Onhold": {
			"name": "Onhold",
			"color": "mediumorchid",
			"component": <StopIcon style={{ width: "20px", marginRight: "5px" }} />
		},
		"TasklistCompleted": {
			"name": "Completed",
			"color": "green",
			"component": <PentagonIcon style={{ width: "15px", marginRight: "5px" }} />
		},
		"Closed": {
			"name": "Completed",
			"color": "green",
			"component": <PentagonIcon style={{ width: "15px", marginRight: "5px" }} />
		},
		"-": {
			"name": "-",
			"color": theme.palette.mode === "dark" ? "white" : "black",
			"component": <></>
		}
	};

	var color = "green";

	const today = new Date();
	const startDate = new Date(tasklistStartDate);
	const endDate = new Date(tasklistDueDate);
	const totDays = days_between(startDate, endDate);

	// console.log(days_between(today, startDate));

	if (today < startDate) {
		color = "green";
	}
	else if(tasklistStatus === "Closed" || tasklistStatus === "Updated to IDC" || tasklistStatus === "Cancelled" || tasklistStatus === "Completed"){
		if(theme.palette.mode === "dark"){
			color = "White";
		}
		else{
			color = "black";
		}
    }
	else if (days_between(today, startDate) < (0.25 * totDays).toFixed(0)) {
		color = "forestgreen";
	}
	else if (days_between(today, startDate) < (0.5 * totDays).toFixed(0)) {
		color = "darksalmon";
	}
	else if (days_between(today, startDate) < (0.75 * totDays).toFixed(0)) {
		color = "darkgoldenrod";
	}
	else if (days_between(today, startDate) < (totDays).toFixed(0)) {
		color = "chocolate";
	}
	else {
		color = "crimson";
	}
	if (tasklistDueDate === "-" || tasklistStartDate === "-") {
		color = "";
	}

	const TextOnlyTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
      ))(`
          color: black;
          background-color: white;
      `);

  
	return (
	  <>
	  {completedTask === true ? (
	  <>
	  <TableRow
		  hover={true}
		  onClick={handleClickOpen}
		  sx={{
			opacity:"0.5",
			transition:"opacity 0.3s ease-in-out",
			// opacity: 0.5; 
			// transition: opacity 0.3s ease-in-out,
			backgroundColor: theme.palette.background.tableRow + " !important",
			'&:hover': {
			  backgroundColor: theme.palette.background.tableRowHover + " !important"
			}
		  }}
		>
			<TextOnlyTooltip title={<span style={{ fontFamily: 'Poppins' ,fontSize: '0.875rem', color: "Black" ,backgroundImage:"" }}>{TasklistDescription} </span>}  followCursor >
		  <TableCell align="center">{tasklistName}</TableCell>
		  </TextOnlyTooltip>
		  <TableCell align="center">{DRI}</TableCell>
		  <TableCell align="center"><Box>{priority}<p>{tasklistPriority}</p></Box></TableCell>
		  <TableCell align="center">{tasklistStartDate}</TableCell>
		  <TableCell align="center" sx={{color: color + " !important"}}>{tasklistDueDate}</TableCell>
		  {nameCheck ? (<TableCell align="center">{
			
						statusComponent[tasklistStatus] === undefined ? 
						 
						tasklistStatus

						:

						<div style={{
							display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center",
							color: statusComponent[tasklistStatus] === undefined ? <></> : statusComponent[tasklistStatus].color
						}}>
							{statusComponent[tasklistStatus] === undefined ? "" : statusComponent[tasklistStatus].component}
							{statusComponent[tasklistStatus] === undefined ? "-" : statusComponent[tasklistStatus].name}<br></br>(QRMap)
						</div>
					}
					</TableCell>):( <TableCell align="center">{
			
						statusComponent[tasklistStatus] === undefined ? 
						 
						tasklistStatus

						:

						<div style={{
							display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center",
							color: statusComponent[tasklistStatus] === undefined ? <></> : statusComponent[tasklistStatus].color
						}}>
							{statusComponent[tasklistStatus] === undefined ? "" : statusComponent[tasklistStatus].component}
							{statusComponent[tasklistStatus] === undefined ? "-" : statusComponent[tasklistStatus].name}
						</div>
					}
					</TableCell>
					)}
		  <TableCell></TableCell>
		</TableRow>
		</>
		)
		:
		(
		<>
		<TableRow
		  hover={true}
		  onClick={handleClickOpen}
		  sx={{
			opacity:"1",
			transition:"opacity 0.3s ease-in-out",
			// opacity: 0.5; 
			// transition: opacity 0.3s ease-in-out,
			backgroundColor: theme.palette.background.tableRow + " !important",
			'&:hover': {
			  backgroundColor: theme.palette.background.tableRowHover + " !important"
			}
		  }}
		>
			<TextOnlyTooltip title={<span style={{ fontFamily: 'Poppins' ,fontSize: '0.875rem', color: "Black" ,backgroundImage:"" }}>{TasklistDescription} </span>}  followCursor >
		  <TableCell align="center">{tasklistName}</TableCell>
		  </TextOnlyTooltip>
		  <TableCell align="center">{DRI}</TableCell>
		  <TableCell align="center"><Box>{priority}<p>{tasklistPriority}</p></Box></TableCell>
		  <TableCell align="center">{tasklistStartDate}</TableCell>
		  <TableCell align="center" sx={{color: color + " !important"}}>{tasklistDueDate}</TableCell>
		  {nameCheck ? (<TableCell align="center">{
			
						statusComponent[tasklistStatus] === undefined ? 
						 
						tasklistStatus

						:

						<div style={{
							display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center",
							color: statusComponent[tasklistStatus] === undefined ? <></> : statusComponent[tasklistStatus].color
						}}>
							{statusComponent[tasklistStatus] === undefined ? "" : statusComponent[tasklistStatus].component}
							{statusComponent[tasklistStatus] === undefined ? "-" : statusComponent[tasklistStatus].name}<br></br>(QRMap)
						</div>
					}
					</TableCell>):( <TableCell align="center">{
			
						statusComponent[tasklistStatus] === undefined ? 
						 
						tasklistStatus

						:

						<div style={{
							display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center",
							color: statusComponent[tasklistStatus] === undefined ? <></> : statusComponent[tasklistStatus].color
						}}>
							{statusComponent[tasklistStatus] === undefined ? "" : statusComponent[tasklistStatus].component}
							{statusComponent[tasklistStatus] === undefined ? "-" : statusComponent[tasklistStatus].name}
						</div>
					}
					</TableCell>
					)}
		  <TableCell></TableCell>
		</TableRow>
		</>
		)}
		
  
		<Dialog
		  fullScreen
		  open={open}
		  TransitionComponent={Transition}
		  keepMounted
		  onClose={handleClose}
		  PaperProps={{
			style: {
			  backgroundColor: theme.palette.background.pageContent,
			  boxShadow: "none"
			}
		  }}
		>
		  <div style={{ display: "block" }}>
			<DialogTitle style={{ float: "left", display: "flex", alignItems: "center" }}>
			  <Tooltip title={"Milestone Name"}>
				<div>{milestoneName}</div>
			  </Tooltip>
			  <ArrowRightIcon />
			  <Tooltip title={"Tasklist Name"}>
				<div>{tasklistName}</div>
			  </Tooltip>
			</DialogTitle>
			<DialogActions style={{ float: "right", padding: "16px 24px 16px 24px" }}>
			  <Button onClick={handleClose} variant="text"><CloseIcon fontSize="medium" /></Button>
			</DialogActions>
		  </div>
		  <DialogContent style={{ paddingTop: 0 }}>
			<DialogContentText component={'span'}>
			  <Task
				tasklistId={Tasklistid}
				milestoneName={milestoneName}
				tasklistName={tasklistName}
				serverName={serverName}
			  />
			</DialogContentText>
		  </DialogContent>
		</Dialog>
	  </>
	);
  }
