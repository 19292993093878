import { isDateInRange, isDateString } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    const chartData = [];
    
    const ticketsMap = {};

    catalystDB.Tickets.map(item => {
        const dateString = item.CREATEDTIME;

        if(isDateString(dateString) && isDateInRange(dateString, startTime)) {

            if([undefined, null, "", " "].includes(item.Product_Type) || [undefined, null, "", " "].includes(item.ClaimedBy_Mail_ID)) {
                return;
            }

            if(item.Product_Type in ticketsMap) {
                if(item.ClaimedBy_Mail_ID in ticketsMap[item.Product_Type]) {
                    ticketsMap[item.Product_Type][item.ClaimedBy_Mail_ID] += 1; 
                } else {
                    ticketsMap[item.Product_Type][item.ClaimedBy_Mail_ID] = 1;
                }
            } else {
                ticketsMap[item.Product_Type] = {};
                ticketsMap[item.Product_Type][item.ClaimedBy_Mail_ID] = 1;
            }
        }
    }); 

    for(const productType in ticketsMap) {
        const arr = [];

        for(const user in ticketsMap[productType]) {
            arr.push([
                user,
                ticketsMap[productType][user]
            ])
        }

        chartData.push({
            "seriesname": productType,
            "data": arr
        });
    }

    return chartData;
}