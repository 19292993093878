import React, { useContext, useEffect, useState } from "react";
import { 
    Divider,
    Box,
    Menu,
    MenuList,
    MenuItem,
    ListItemText,
} from "@mui/material";
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import TitleIcon from '@mui/icons-material/Title';
import SearchIcon from '@mui/icons-material/Search';
import { Typography } from '@mui/material';
import styles from "./TopBar.module.css";
import { UserContext } from "../../../../../contexts/UserContext";

const FILTER_VARIANTS = Object.freeze({
    SERVER: "Server",
    MODULE: "Module",
    TYPE: "Type"
});

// topbar -> filter -> filter menu options
export function FilterMenu({actions, setActions, view}) {
    // console.log(actions)

    function handleFilterMenuClose() {
        setActions({
            ...actions,
            meta: {
                ...actions.meta,
                filter_anchor: null
            }
        });
    }

    function handleMenuItemClick(event, filter_variant) {
        switch(filter_variant) {
            case FILTER_VARIANTS.SERVER: {
                setActions({
                    ...actions,
                    meta: {
                        ...actions.meta,
                        filter_server_anchor: actions?.meta?.filter_server_anchor === null ? event.currentTarget : null
                    }
                });
                break;
            }
            case FILTER_VARIANTS.MODULE: {
                setActions({
                    ...actions,
                    meta: {
                        ...actions.meta,
                        filter_module_anchor: actions?.meta?.filter_module_anchor === null ? event.currentTarget : null
                    }
                });
                break;
            }
            case FILTER_VARIANTS.TYPE: {
                setActions({
                    ...actions,
                    meta: {
                        ...actions.meta,
                        filter_type_anchor: actions?.meta?.filter_type_anchor === null ? event.currentTarget : null
                    }
                });
                break;
            }
        }
    }

    return (
        <>
            <Menu anchorEl={actions?.meta?.filter_anchor}
                open={actions?.meta?.filter_anchor !== null}
                onClose={handleFilterMenuClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuList className={styles.filter_menu_main} >
                    <MenuItem className={styles.filter_menu_item} onClick={(event) => handleMenuItemClick(event, FILTER_VARIANTS.SERVER)}>
                        <Box className={styles.flex_row}>
                            {actions?.meta?.filter_server_anchor !== null ? <ArrowLeftRoundedIcon /> : <ArrowRightRoundedIcon />}
                            <ListItemText> Server </ListItemText>
                        </Box>
                        <DnsRoundedIcon fontSize="small" />
                    </MenuItem>
                    {
                        toShowModules(useContext(UserContext)) ? 
                            <MenuItem className={styles.filter_menu_item} onClick={(event) => handleMenuItemClick(event, FILTER_VARIANTS.MODULE)}>
                                <Box className={styles.flex_row}>
                                    {actions?.meta?.filter_module_anchor !== null ? <ArrowLeftRoundedIcon /> : <ArrowRightRoundedIcon />}
                                    <ListItemText> Module </ListItemText>
                                </Box>
                                <ViewModuleIcon fontSize="small" />
                            </MenuItem>
                            :
                            null
                    }
                    <MenuItem className={styles.filter_menu_item} onClick={(event) => handleMenuItemClick(event, FILTER_VARIANTS.TYPE)}>
                        <Box className={styles.flex_row}>
                            {actions?.meta?.filter_type_anchor !== null ? <ArrowLeftRoundedIcon /> : <ArrowRightRoundedIcon />}
                            <ListItemText> Type </ListItemText>
                        </Box>
                        <TitleIcon fontSize="small" />
                    </MenuItem>
                </MenuList>
            </Menu>
            <ServerFilterMenu actions={actions} setActions={setActions} view={view} />
            <ModuleFilterMenu actions={actions} setActions={setActions} view={view} />
            <TypeFilterMenu actions={actions} setActions={setActions} view={view} />
        </>
    );
}

// topbar -> filter -> filter menu options -> server filter options
function ServerFilterMenu({actions, setActions, view}) {

    const {allServers, teamDetails, loginUserEmail} = useContext(UserContext);
    const [servers, setServers] = useState(actions?.filters?.servers);
    const userServers = teamDetails.current[loginUserEmail.current]?.Servers?.split(",");
    const menuOpen = actions?.meta?.filter_server_anchor !== null;

    useEffect(() => {
        setServers(actions?.filters.servers);
    }, [actions?.filters.servers]);
    
    function handleServerFilterMenuClose() {
        setActions({
            ...actions,
            filters: {
                ...actions.filters,
                servers: servers
            },
            meta: {
                ...actions.meta,
                filter_server_anchor: null,
                filter_anchor: null
            }
        });
    }

    function handleMenuItemClick(server) {
        if(servers.includes(server)) {
            const newServers = servers.filter(serv => serv !== server);
            if(view === "team" && newServers.length === 0) {
                setServers(userServers.filter((serv) => serv !== "Test"));
            }
            else if(view === "approval" && newServers.length === 0) {
                setServers(userServers.filter((serv) => serv !== "Test"));
            }
             else {
                setServers(newServers);
            }
        } else {
            setServers([...servers, server]);
        }
    }

    return (
        <>
        {menuOpen && (
            <div className={styles.overlay}>
                <Typography  className={styles.overlayText}>
                    Click anywhere here to confirm the selection
                </Typography>
            </div>
        )}

        <Menu anchorEl={actions?.meta?.filter_server_anchor}
        // open={actions?.meta?.filter_server_anchor !== null}
        open={menuOpen}
        onClose={handleServerFilterMenuClose}
        anchorOrigin={{
            vertical: "top",
            horizontal: "left",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
    >
        <MenuList className={`${styles.filter_server_menu_main} ${styles.hide_scroll_bar}`} >
            {
                allServers.map(server => {
                    if(view === "team" && !userServers.includes(server)) {
                        return null;
                    }
                    return (
                        <>
                            <MenuItem className={styles.filter_server_menu_item} onClick={() => handleMenuItemClick(server)}>
                                <Box className={styles.flex_row_gap}>
                                    {
                                        servers.includes(server) ?
                                            <DoneRoundedIcon />
                                            :
                                            <span style={{ width: "18px" }} />
                                    }
                                    <ListItemText> {server} </ListItemText>
                                </Box>
                            </MenuItem>
                            <Divider light style={{margin: 0}} />
                        </>
                    );
                })
            }
        </MenuList>
    </Menu>
    </>
    );
}


// topbar -> filter -> filter menu options -> module filter options
function ModuleFilterMenu({actions, setActions, view}) {

    const {allServers, teamDetails, loginUserEmail, configuration, loginUserDept} = useContext(UserContext);
    const [modules, setModules] = useState(actions?.filters?.module);
    const userServers = teamDetails.current[loginUserEmail.current]?.Servers?.split(",");
    const menuOpen = actions?.meta?.filter_module_anchor !== null;
    var allModules = {};
    // console.log(configuration)
    configuration.current[`department.${loginUserDept.current}.ticket_modules`].map(ticketModule => {
        const toShow = ticketModule.servers.reduce((acc, cur) => {
            return acc || (userServers.includes(cur))
        }, false);

        if(toShow === true) {
            ticketModule.modules.map(mod => {
                if(!(mod in allModules)) {
                    allModules[mod] = true;
                }
            })
        }
    });
    allModules = Object.keys(allModules).sort();

    useEffect(() => {
        setModules(actions?.filters.module);
    }, [actions?.filters.module]);

    if(allModules.length === 0) {
        return null;
    }
    
    function handleModuleFilterMenuClose() {
        setActions({
            ...actions,
            filters: {
                ...actions.filters,
                module: modules,
                type: modules.length === 0 ? actions.filters.type : []
            },
            meta: {
                ...actions.meta,
                filter_module_anchor: null,
                filter_anchor: null
            }
        });
 }

    function handleMenuItemClick(module) {
        if(modules.includes(module)) {
            const newModules = modules.filter(mod => mod !== module);
            setModules(newModules);
        } else {
            setModules([...modules, module]);
        }
    }

    return (
        <>
        {menuOpen && (
            <div className={styles.overlay}>
                <Typography  className={styles.overlayText}>
                    Click anywhere here to confirm the selection
                </Typography>
            </div>
        )}
        <Menu anchorEl={actions?.meta?.filter_module_anchor}
        // open={actions?.meta?.filter_module_anchor !== null}
        open={menuOpen}
        onClose={handleModuleFilterMenuClose}
        anchorOrigin={{
            vertical: "top",
            horizontal: "left",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
    >
        <MenuList className={`${styles.filter_server_menu_main} ${styles.hide_scroll_bar}`} >
            {
                allModules.map(module => {
                    return (
                        <>
                            <MenuItem className={styles.filter_server_menu_item} onClick={() => handleMenuItemClick(module)}>
                                <Box className={styles.flex_row_gap}>
                                    {
                                        modules.includes(module) ?
                                            <DoneRoundedIcon />
                                            :
                                            <span style={{ width: "18px" }} />
                                    }
                                    <ListItemText> {module} </ListItemText>
                                </Box>
                            </MenuItem>
                            <Divider light style={{margin: 0}} />
                        </>
                    );
                })
            }
        </MenuList>
    </Menu>
    </>
    );
}


// topbar -> filter -> filter menu options -> type filter options
function TypeFilterMenu({actions, setActions, view}) {

    const {allServers, teamDetails, loginUserEmail, configuration, loginUserDept} = useContext(UserContext);
    const ticketTypes = configuration.current[`department.${loginUserDept.current}.ticket_types`];
    const [types, setTypes] = useState(actions?.filters?.type);
    const menuOpen = actions?.meta?.filter_type_anchor !== null;

    useEffect(() => {
        setTypes(actions?.filters.type);
    }, [actions?.filters.type]);
    
    function handleTypeFilterMenuClose() {
        setActions({
            ...actions,
            filters: {
                ...actions.filters,
                type: types,
                module: types.length === 0 ? actions.filters.module : []
            },
            meta: {
                ...actions.meta,
                filter_type_anchor: null,
                filter_anchor: null
            }
        });
    }

    function handleMenuItemClick(type) {
        if(types.includes(type)) {
            const newTypes = types.filter(typ => typ !== type);
            setTypes(newTypes);
        } else {
            setTypes([...types, type]);
        }
    }

    return (
        <>
        {menuOpen && (
            <div className={styles.overlay}>
                <Typography  className={styles.overlayText}>
                    Click anywhere here to confirm the selection
                </Typography>
            </div>
        )}
        <Menu anchorEl={actions?.meta?.filter_type_anchor}
        // open={actions?.meta?.filter_type_anchor !== null}
        open={menuOpen}
        onClose={handleTypeFilterMenuClose}
        anchorOrigin={{
            vertical: "top",
            horizontal: "left",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
    >
        <MenuList className={`${styles.filter_server_menu_main} ${styles.hide_scroll_bar}`} >
            {
                ticketTypes.map(type => {
                    return (
                        <>
                            <MenuItem className={styles.filter_server_menu_item} onClick={() => handleMenuItemClick(type)}>
                                <Box className={styles.flex_row_gap}>
                                    {
                                        types.includes(type) ?
                                            <DoneRoundedIcon />
                                            :
                                            <span style={{ width: "18px" }} />
                                    }
                                    <ListItemText> {type} </ListItemText>
                                </Box>
                            </MenuItem>
                            <Divider light style={{margin: 0}} />
                        </>
                    );
                })
            }
        </MenuList>
    </Menu>
    </>
    );
}

// utils

function toShowModules(context) {
    const {teamDetails, loginUserEmail, configuration, loginUserDept} = context;
    try {
        const userServers = teamDetails.current[loginUserEmail.current]?.Servers?.split(",");
        var allModules = {};
        configuration.current[`department.${loginUserDept.current}.ticket_modules`].map(ticketModule => {
            const toShow = ticketModule.servers.reduce((acc, cur) => {
                return acc || (userServers.includes(cur))
            }, false);
    
            if(toShow === true) {
                ticketModule.modules.map(mod => {
                    if(!(mod in allModules)) {
                        allModules[mod] = true;
                    }
                })
            }
        });
        allModules = Object.keys(allModules).sort();
    } catch(e) {
        return false;
    }
   
    return allModules.length !== 0;
}