/* eslint-disable react/prop-types */
import React, { useContext, useLayoutEffect, useEffect, useRef, useState } from 'react';
import { TabStateContext } from '../../../contexts/TabStateContext';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import CloseIcon from '@mui/icons-material/Close';

import "./ReportAnIssue.css";
import { UserContext } from '../../../contexts/UserContext';
import { dispatchIssueInSupportChannel } from './IssueDispatcher';

import {
    Box,
    Grid,
    Button,
    CircularProgress,
    Input,
    Dialog,
    DialogContent,
    DialogActions,
    Alert,
    Snackbar,
    DialogTitle,
    DialogContentText
} from '@mui/material';

export function ReportAnIssue() {

    const {screenshot} = useContext(TabStateContext);
    const [screenshot2, setScreenshot2] = useState(null);
    const screenshotBlob = useRef({
        "name": "zone_screenshot.png",
        "type": "image/png"
    });
    const screenshot2Blob = useRef();
    const theme = useTheme();
    const {userDetails} = useContext(UserContext);
    const [showScreenshot, setShowScreenshot] = useState(false);
    const [message, setMessage] = useState(null);
    const firstUpdate = useRef(true);
    const login_user_email = userDetails.current.auth.email_id;
    let controller = new AbortController();
    const [defaultImage, setDefaultImage] = useState(true);
    const [bannerSeverity, setBannerSeverity] = useState(null);
    const [bannerMessage, setBannerMessage] = useState("");
    const [confirmationDialog, setConfirmationDialog] = useState(false);

    useEffect(() => {

        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        setShowScreenshot(true);
        return () => {
            controller.abort();
            setShowScreenshot(false);
            firstUpdate.current = true;
        };

    }, [screenshot]);

    function handleSubmitClick() {
        if(message == null || message === "") {
            alert("Kindly enter your message in the text area!");
            return;
        }
        if(message.length > 1000) {
            alert(`Exceeded the maximum characters allowed to post in a cliq message\n\nMax Allowed: 1000\n\nEntered: ${message.length}`);
            return;
        }

        if(defaultImage && screenshot == null) {
            alert("No Image Selected, Please Choose an Image");
            return;
        } else if(!defaultImage && screenshot2 == null) {
            alert("No Image Selected, Please Choose an Image");
            return;
        }
        setConfirmationDialog(true);
    }

    function handlePostData() {
        try {
            setBannerSeverity("info");
            setBannerMessage((
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px"
                }}>
                    posting in cliq
                    <CircularProgress size="1.2rem" />
                </Box>
            ));
            if(defaultImage) {
                dispatchIssueInSupportChannel(controller, login_user_email, screenshot, message, screenshotBlob.current).then(async response => {
                    console.log(response);
                    const responseText = await response.text();
                    if(response.status == 204 || response.status == 200) {
                        setBannerSeverity("success");
                        setBannerMessage("Your query got posted in Zone Support Channel! (─ ‿ ─)")
                    } else {
                        setBannerSeverity("error");
                        var bMessage = "Something went wrong (・_＋)";
                        if(responseText.startsWith("Maximum")) {
                            bMessage = "Maximum allowed size of file has been exceeded. Limit: 50MB";
                        }
                        setBannerMessage(bMessage);
                    }
                    setTimeout(() => {
                        setBannerSeverity(null);
                    }, 3000);
                }).catch(error => {
                    setBannerSeverity("error");
                    setBannerMessage("Something went wrong (・_＋)");
                    setTimeout(() => {
                        setBannerSeverity(null);
                    }, 3000);
                });
                
            } else {
                dispatchIssueInSupportChannel(controller, login_user_email, screenshot2, message, screenshot2Blob.current).then(async (response) => {
                    console.log(response);
                    const responseText = await response.text();
                    if(response.status == 204 || response.status == 200) {
                        setBannerSeverity("success");
                        setBannerMessage("Your query got posted in Zone Support Channel! (─ ‿ ─)")
                    } else {
                        setBannerSeverity("error");
                        var bMessage = "Something went wrong (・_＋)";
                        if(responseText.startsWith("Maximum")) {
                            bMessage = "Maximum allowed size of file has been exceeded. Limit: 50MB";
                        }
                        setBannerMessage(bMessage);
                    }
                    setTimeout(() => {
                        setBannerSeverity(null);
                    }, 3000);
                }).catch(error => {
                    setBannerSeverity("error");
                    setBannerMessage("Something went wrong (・_＋)");
                    setTimeout(() => {
                        setBannerSeverity(null);
                    }, 3000);
                });
            }
        } catch(error) {
            setBannerSeverity("error");
            setBannerMessage("Something went wrong (・_＋)");
            setTimeout(() => {
                setBannerSeverity(null);
            }, 3000);
        }
    }

    function handleResetClick() {
        setDefaultImage(true);
        setMessage("");
        setShowScreenshot(true);

        var textarea = document.getElementById("message-textarea");
        textarea.style.height = "70px";
    }

    function handleImageChange(event) {
        try {
            const file = event.target.files[0];
            screenshot2Blob.current = file;
            var reader = new FileReader();
            reader.onload = function () {
                setScreenshot2(reader.result);
            }
            reader.readAsDataURL(file);
        } catch(e) {}
    }

    var mime_type = "";
    try {
        mime_type = screenshot2Blob.current.type;
    } catch(e) {}

    return (
        <div className='page-content-2'>
            <Snackbar open={bannerSeverity != null} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				style={{
                    position: "absolute",
                    top: "200px",
                    left: "calc(50vw + 120px)"
                }}>
                    {
                        bannerSeverity != null ?
                            <Alert severity={bannerSeverity}>
                                {bannerMessage}
                            </Alert>
                            :
                            null
                    }
			</Snackbar>
            <Dialog
                open={confirmationDialog}
                onClose={() => setConfirmationDialog(false)}
            >
                <DialogTitle>
                    {"Post on your behalf ?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This will post a message in "Zone Support" Channel in cliq on your behalf.
                        <br />
                        <br />
                        Are you sure want to proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmationDialog(false)}>Cancel</Button>
                    <Button onClick={() => { handlePostData(); setConfirmationDialog(false); }} autoFocus>
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>
            <Box sx={{
                height: "100%",
                maxHeight: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                color: theme.palette.background.text,
                overflowY: "auto",
                padding: "2px",
                boxSizing: "border-box"
            }}>
                <Box sx={{
                    fontSize: "1.5rem",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderBottom: "1px solid " + (theme.palette.mode === "light" ? "black" : "grey"),
                    paddingBottom: "10px",
                    marginBottom: "10px"
                }}>
                    Report An Issue
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 0,
                    width: "100%",
                    maxHeight: "100%",
                    flexGrow: 1,
                    boxSizing: "border-box",
                    overflowY: "auto",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "20px",
                    paddingTop: "0px"
                }}>
                    {
                        defaultImage ? 
                            <>
                                <Box sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center"
                                }}>
                                    <Button sx={{
                                        borderRadius: "50px",
                                        paddingBottom: "10px"
                                    }} onClick={() => setDefaultImage(false)}>
                                        {/* <CloseIcon fontSize='small' /> */}
                                        Choose a different image
                                    </Button>
                                </Box>
                                {
                                    showScreenshot ?
                                        <ImageSection screenshot={screenshot} />
                                        :
                                        <CircularProgress />
                                }
                            </>
                            :
                            <>
                                <Input
                                    accept="image/*"
                                    id="image-input"
                                    type="file"
                                    onChange={handleImageChange}
                                    style={{
                                        marginBottom: "10px"
                                    }}
                                    hidden
                                />
                                {
                                    mime_type.startsWith("image/") ?
                                        <ImageSection screenshot={screenshot2} />
                                        :
                                        "No Preview Available"

                                }
                            </>

                    }
                    <Box sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}>
                        <TextSection message={message} setMessage={setMessage} setScreenshot2={setScreenshot2} setDefaultImage={setDefaultImage} screenshot2Blob={screenshot2Blob}/>
                        <Box sx={{
                            fontSize: "1.5rem",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                            paddingLeft: "20px"
                        }}>
                            <Button
                                variant="contained"
                                size='small'
                                onClick={handleResetClick}
                                sx={{
                                    borderRadius: "10px",
                                    backgroundColor: theme.palette.zohoBlue.main
                                }}
                            >
                                Reset
                            </Button>
                            <Button
                                variant="contained"
                                size='small'
                                onClick={handleSubmitClick}
                                sx={{
                                    borderRadius: "10px",
                                    backgroundColor: theme.palette.zohoGreen.main
                                }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
           
        </div>
    );
}

function ImageSection({screenshot}) {
    const theme = useTheme();
    const [maximize, setMaximize] = useState(false);

    return (
        screenshot !== null ?
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                boxSizing: "border-box",
                overflowY: "hidden",
                paddingBottom: "20px"
            }}>
                <img style={{
                    objectFit: "contain",
                    maxHeight: "100%",
                    maxWidth: "100%",
                    height: "auto",
                    opacity: 0.9,
                    boxShadow: "1px 1px 10px 1px " + (theme.palette.mode === "light" ? "grey" : "black")
                }} src={screenshot} alt={'Screenshot'} onClick={() => setMaximize(true)}/>
                <Dialog open={maximize} onClose={() => setMaximize(false)} fullScreen>
                    <DialogActions>
                        <Button onClick={() => setMaximize(false)} variant="text" fontSize="large"><CloseIcon /></Button>
                    </DialogActions>
                    <DialogContent sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <img style={{
                            objectFit: "contain",
                            maxHeight: "100%",
                            maxWidth: "100%",
                            height: "auto",
                            opacity: 0.9,
                            boxShadow: "1px 1px 10px 1px " + (theme.palette.mode === "light" ? "grey" : "black")
                        }} src={screenshot} alt={'Screenshot'} onClick={() => setMaximize(true)} />
                    </DialogContent>
                </Dialog>
            </Box>
            :
            null
    );
}

function TextSection({message, setMessage, setScreenshot2, setDefaultImage, screenshot2Blob}) {
    const theme = useTheme();
    const textBox = useRef();

    useEffect(() => {
        var textarea = document.getElementById("message-textarea");
        var heightLimit = 450; 
    
        textarea.oninput = function () {
            textarea.style.height = "";
            textarea.style.height = Math.min(textarea.scrollHeight, heightLimit) + "px";
        };

    }, []);

    function handlePaste(event) {
        const clipboardItems = event.clipboardData.items;
        const items = [].slice.call(clipboardItems).filter(function (item) {
            return /^image\//.test(item.type);
        });
        if (items.length === 0) {
            return;
        }
        
        const item = items[0];
        const blob = item.getAsFile();
        screenshot2Blob.current = blob;

        var reader = new FileReader();
        reader.onload = function () {
            setScreenshot2(reader.result);
            setDefaultImage(false);
        }
        reader.readAsDataURL(blob);

    }

    return (
        <Box sx={{
            boxShadow: "1px 1px 7px 1px " + (theme.palette.mode === "light" ? "rgb(211, 206, 206)" : "black"),
            borderRadius: "10px",
            width: "95%"
         }}>
            <div className="chat-input">
                <textarea id="message-textarea" placeholder="paste an image (or) type a description..." style={{
                    backgroundColor: theme.palette.mode === "light" ? "whitesmoke" : theme.palette.background.outerSurface,
                    color: theme.palette.background.text,
                    height: "70px",
                    fontSize: "1rem"
                }} 
                onChange={(event) => {
                    if(event.target.value.length > 1000) {
                        textBox.current.style.border = "1px solid crimson";
                    } else {
                        textBox.current.style.border = "";
                    }
                    setMessage(event.target.value)
                }}
                ref={textBox}
                value={message}
                onPaste={handlePaste}
                ></textarea>
            </div>
        </Box>
    );
}