//ignorei18n_start
import React, { useEffect, useRef } from "react";
import { SplitSingle } from "./components/SplitSingle";
import { Transition } from 'react-transition-group';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TimelineOppositeContent } from "@mui/lab";
import {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { Chip, Divider, Stack, Tooltip } from "@mui/material";
import Milestone from "./milestone/Milestone";

import { styled } from '@mui/material/styles';

import DoneIcon from '@mui/icons-material/Done';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import AllDataStat from "../allDataStat/allData";


export default function TimelineComponent({
    data,fetchData,LazzyLoading,setLazzyLoading,offsetData,incrementData,setOffsetData
    ,setIncrementData,milestoneData,taskListData,taskData,allDataWithWrongJasonAlso,toOpenTheAllData
}) {
    sortData(data);
    const ColorlibConnector = styled(TimelineConnector)(({ theme }) => ({
        [`&.MuiTimelineConnector-root`]: {
            backgroundImage:
                'linear-gradient( 95deg, #2894c9 0%, #286ec9 50%, #2846c9 100%)'
        }
    }));

    const ColorlibDot = styled(TimelineDot)(({ theme }) => ({
        [`&.MuiTimelineDot-filled`]: {
            backgroundImage:
                'linear-gradient( 95deg, #2894c9 0%, #286ec9 50%, #2846c9 100%)',
            backgroundClip: 'padding-box'
        }
    }));

    const ref = useRef();
    var ind = 0;
    var closestDiff = Infinity;
    const today = new Date();
    data.map((d, i) => {
        if(d?.due_date || d?.completed_date) {
            const date = d.completed_date ? new Date(d.completed_date) : new Date(d.due_date);
            const diff = Math.abs(today.getTime() - new Date(date).getTime());
            if(diff <= closestDiff) {
                closestDiff = diff;
                ind = i;
            }
        }
    })

    useEffect(() => {
        ref.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
    }, []);
    
    const handleScroll = async (event) => {
        const container = event.target;
        if (container.scrollHeight - container.scrollTop <= container.clientHeight + 1 && (allDataWithWrongJasonAlso.length === 300 || allDataWithWrongJasonAlso.length > 300) ) {
            // Show loading indicator
            setLazzyLoading(true);
            fetchData();
        }
    };

    const duration = 800;

    const defaultStyle = {
        transition: `opacity ${duration}ms ease-in-out, max-height ${duration}ms ease-in-out`,
        opacity: 0,
        maxHeight: '0px',
        overflow: 'hidden'
    };

    const transitionStyles = {
        entering: { opacity: 1, maxHeight: '500px' },
        entered: { opacity: 1, maxHeight: '500px' },
        exiting: { opacity: 0, maxHeight: '0px' },
        exited: { opacity: 0, maxHeight: '0px' }
    };

    return (
        <>
         <Transition in={toOpenTheAllData} timeout={duration}>
                {state => (
                    <div style={{
                        ...defaultStyle,
                        ...transitionStyles[state]
                    }}>
                        <AllDataStat milestoneData={milestoneData} taskListData={taskListData} taskData={taskData} />
                    </div>
                )}
         </Transition>
        <div style={{
            overflow: "scroll",
            padding: "20px 0px",
            flex: 1,
        }} onScroll={handleScroll}>
             {/* <AllDataStat milestoneData={milestoneData} taskListData={taskListData} taskData={taskData}/> */}
            <Timeline
                sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.2,
                    },
                }}
            >
                {
                    data.map((d, i) => {
                        return (
                            <TimelineItem key={i} ref={i === ind ? ref : null}>
                                <TimelineOppositeContent color="text.secondary">
                                    <Lefty data={d} />
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <ColorlibDot>
                                        {
                                            data[i].completed_date !== undefined ?
                                                <Tooltip title={"Completed"} >
                                                    <DoneIcon sx={{
                                                        padding: "2px",
                                                        boxSizing: "border-box",
                                                        transition: "padding 0.3s ease"
                                                    }} />
                                                </Tooltip>
                                                :
                                                data[i].start_date !== undefined ?
                                                    <Tooltip title={"Ongoing"} >
                                                        <MoreHorizIcon sx={{
                                                            padding: "2px",
                                                            boxSmapizing: "border-box",
                                                            transition: "padding 0.3s ease"
                                                        }} />
                                                    </Tooltip>
                                                    :
                                                    <Tooltip title={"Yet to start"} >
                                                        <PlayArrowRoundedIcon sx={{
                                                            padding: "2px",
                                                            boxSizing: "border-box",
                                                            transition: "padding 0.3s ease"
                                                        }} />
                                                    </Tooltip>
                                        }
                                    </ColorlibDot>
                                    <ColorlibConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Righty data={d} />
                                </TimelineContent>
                            </TimelineItem>
                        );
                    })
                }
            </Timeline>
            {LazzyLoading &&
             <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Box sx={{ width: '90%' }}>
                    <LinearProgress />
                </Box>
            </Box>
         }
        </div>
        </>
    );
}

function Lefty({ data }) {

    const completed = data.completed_date ? true : false;
    const endDate = data.completed_date ? data.completed_date : data.due_date;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    try {
        endDate.setHours(0, 0, 0, 0);
    } catch(e) {}
    const dueDate = data.due_date;
    try {
        dueDate.setHours(0, 0, 0, 0);
    } catch(e) {}

    return (
        <div style={{
            height: "100px",
            width: "100%",
            display: "flex",
            flexDirection: "row-reverse"
        }}>
            {
                data.completed_date || data.due_date ?
                    <Stack direction="column" spacing={1} style={{ float: "right" }}>
                        <Chip label={
                            completed ? "Completed on" : (data.start_date ? "Due on" : "Termination date")
                        } color={completed ? "success" : "warning"} variant="filled" style={{ borderRadius: "8px", fontWeight: "bold", fontSize: "10px" }} />

                        <Chip label={
                            completed ? formatDate(data.completed_date) : formatDate(data.due_date)
                        } color="primary" variant="outlined" style={{ borderRadius: "8px", fontWeight: "bold" }} />
                        {
                            endDate && (dueDate || today) ?
                                <Chip label={
                                    data.completed_date ?
                                    dueDate?.getTime() < endDate?.getTime() ? "Delayed" : "On Time"
                                    :
                                    today.getTime() > endDate?.getTime() ? "Delayed" : "On Time"
                                } color={
                                    data.completed_date ?
                                    dueDate?.getTime() < endDate?.getTime() ? "error" : "success"
                                    :
                                    today.getTime() > endDate?.getTime() ? "error" : "success"
                                } variant="solid" style={{ borderRadius: "100px", width: "fit-content", fontSize: "10px", fontWeight: "bold" }} />
                                :
                                null
                        }
                    </Stack>
                    :
                    null
            }
        </div>
    );
}

function Righty({ data }) {

    // console.log(data.description)
    // console.log(data.milestone)

    const theme = useTheme();
    const completed = data.completed_date ? true : false;
    const TextOnlyTooltip = styled(({ className, ...props }) => (
		<Tooltip placement="top" {...props} componentsProps={{ tooltip: { className: className } }} />
	))(`
          color: black;
          background-color: white;
      `);

    return (
        <div style={{
            width: "100%",
            borderRadius: "10px",
            padding: "10px",
            boxSizing: "border-box",
            backgroundColor: theme.palette.mode === "dark" ? "#567a9922" : "rgb(248 250 252)",
            color: theme.palette.mode === "dark" ? "white" : "",
            boxShadow: "1px 1px 4px 0.5px rgba(71, 85, 105, 0.3)",
            marginBottom: "10px",
            display: "flex",
            flexDirection: "column",
            // gap: "5px",
            // borderRight: "3px solid " + (completed ? "green" : "crimson"),
            paddingLeft: "15px",
            boxShadow: "10px 0 15px -2px #3a43c733 inset",
            borderLeft: "3px solid #3a43c7",
        }}>
            {/* <div style={{ fontWeight: "bold" }}>
                {data.milestone_name}
                <TextOnlyTooltip title={<span style={{ fontFamily: 'Poppins', fontSize: '0.875rem', color: "Black", backgroundImage: "" }}>{data.description} </span>} followCursor >
                <InfoOutlinedIcon sx={{marginLeft:"6px",marginBottom:"-4px",fontSize:"19px"}}/>
                </TextOnlyTooltip>
            </div> */}

            <div style={{ fontWeight: "bold" }}>
                {data.description == null || data.description === undefined || data.description === "" ? (
                    data.milestone_name
                ) : (
                    <>
                    {/* {data.description} */}
                    {data.description.length > 50 ? data.description.slice(0, 50) + "..." : data.description}
                    <TextOnlyTooltip title={<span style={{ fontFamily: 'Poppins', fontSize: '0.875rem', color: "Black", backgroundImage: "" }}>{data.milestone_name}</span>} followCursor>
                        <InfoOutlinedIcon sx={{ marginLeft: "6px", marginBottom: "-4px", fontSize: "19px" }} />
                    </TextOnlyTooltip>
                    </>
                )}
            </div>

            <Milestone data={data} />
        </div>
    );
}

export function formatDate(date) {
    if(date === null || date === undefined || date === "") {
        return null;
    }
    // date = new Date(date);
    const year = date.getFullYear();
    const month = date.toLocaleString('en-US', { month: 'short' }); // Get month name (e.g., "Apr")

    const day = String(date.getDate()).padStart(2, '0');

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format

    return `${day}-${month}-${year}, ${formattedHours}:${minutes} ${ampm}`;
}


function sortData(data) {
    data.sort((a, b) => {
        const date_a = a.completed_date ? new Date(a.completed_date) : new Date(a.due_date);
        const date_b = b.completed_date ? new Date(b.completed_date) : new Date(b.due_date);

        return date_a > date_b;
    });
}
//ignorei18n_end