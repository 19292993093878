import React from "react";
import { 
    Divider,
    Box,
    Menu,
    MenuList,
    MenuItem,
    ListItemText,
} from "@mui/material";
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import AdjustRoundedIcon from '@mui/icons-material/AdjustRounded';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import TimerOffIcon from '@mui/icons-material/TimerOff';

import styles from "./TopBar.module.css";

const SORT_VARIANTS = Object.freeze({
    TIME: "Time",
    DELAYED: "Delayed",
    STATUS: "Status",
    PRIORITY: "Priority"
});

const SORT_ICONS = Object.freeze({
    TIME: <AccessTimeRoundedIcon fontSize="small" />,
    DELAYED: <TimerOffIcon fontSize="small" />,
    STATUS: <AdjustRoundedIcon fontSize="small" />,
    PRIORITY: <PriorityHighRoundedIcon fontSize="small" />
});

export const ORDER_BY = {
    undefined: "CREATEDTIME DESC",
    "Time": "CREATEDTIME DESC",
    "Delayed": "CREATEDTIME ASC",
    "Status": "Status DESC",
    "Priority": "Priority_1",
};

// topbar -> filter -> filter menu options
export function SortMenu({actions, setActions}) {

    function handleSortMenuClose() {
        setActions({
            ...actions,
            meta: {
                ...actions.meta,
                sort_anchor: null
            }
        });
    }

    function handleMenuItemClick(sort_variant) {
        setActions({
            ...actions,
            sort: actions?.sort !== sort_variant ? sort_variant : undefined,
            meta: {
                ...actions.meta,
                sort_anchor: null
            }
        });
    }

    return (
        <Menu anchorEl={actions?.meta?.sort_anchor}
            open={actions?.meta?.sort_anchor !== null}
            onClose={handleSortMenuClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
        >
            <MenuList className={styles.sort_menu_main} >
                {
                    Object.keys(SORT_VARIANTS).map(variant => {
                        return (
                            <>
                                <MenuItem className={styles.sort_menu_item} onClick={() => handleMenuItemClick(SORT_VARIANTS[variant])}>
                                    <Box className={styles.flex_row_gap_small}>
                                        {
                                            actions?.sort === SORT_VARIANTS[variant] || (actions?.sort === undefined && variant === "TIME") ?
                                                <DoneRoundedIcon />
                                                :
                                                <span style={{ width: "18px" }} />
                                        }
                                        <ListItemText> {SORT_VARIANTS[variant]} </ListItemText>
                                    </Box>
                                    {SORT_ICONS[variant]}
                                </MenuItem>
                                <Divider light style={{ margin: 0 }} />
                            </>
                        );
                    })
                }
            </MenuList>
        </Menu>
    );
}