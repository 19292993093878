/* eslint-disable react/prop-types */ 
/* eslint-disable react/jsx-key */ 
import React, { createContext, useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { useContext } from "react";
import { 
    Box,
    Paper,
    Grid,
    Tooltip,
    Skeleton
} from "@mui/material";
import { UserContext } from "../../../../../contexts/UserContext";
import { ReportContext } from "../newReportGeneral/NewReport";
import styled from "@emotion/styled";
import { EmployeeCard, GetNameAndEmpID } from "../../../../../util/Loading";
import { getTasksUnderMilestone } from "../reportSection1/ReportSection1DataFetcher";
import { TasksToolTipCard } from "../reportSection1/TasksToolTipCard";
import Grow from '@mui/material/Grow';

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? 'rgb(23,27,32)' : 'rgb(215, 219, 219, 0.8)',
	...theme.typography.body2,
	padding: theme.spacing(1),
	paddingTop: 0,
	paddingBottom: 0,
    borderRadius: "7px",
	color: theme.palette.text.primary,
	height: "120px",
	justifyContent: "center",
	alignItems: "center"
}));

export function BuildCard({buildRow}) {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%"
        }}>
            <MilestoneTopHalf buildRow={buildRow}/>
            <MilestoneBottomHalf buildRow={buildRow} />
        </Box>
    );
}


function MilestoneTopHalf({buildRow}) {
    const theme = useTheme();
    
    return (
        <Box sx={{
            height: "65%",
            fontSize: "1.05rem",
            fontWeight: "600"
        }}>
            <Grid container spacing={0.5} sx={{ height: "100%" }}>
                <Grid item xs={2.4}>
                    <Box style={{
                        justifyContent: "left !important",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                        paddingLeft: "10px",
                        height: "100%",
                        overflowX: "scroll",
                        marginRight: "20px"
                    }} className="nested-view-overflow-container-2">
                        {
                            [null, undefined, "", " "].includes(buildRow.server) ?
                            "-"
                            :
                            buildRow.server
                        }
                    </Box>
                </Grid>
                <Grid item xs={2.4}>
                    <FlexBox>
                        {
                            [null, undefined, "", " "].includes(buildRow.buildLabel) ?
                            "-"
                            :
                            buildRow.buildLabel
                        }
                    </FlexBox>
                </Grid>

                <Grid item xs={2.4}>
                    <FlexBox>
                        <span style={{
                            fontSize: "0.8rem",
                            color: theme.palette.mode === "dark" ? theme.palette.zohoGreen.light : theme.palette.zohoGreen.dark,
                            fontWeight: "bold"
                        }}>Build Type</span>
                        {
                            [null, undefined, "", " "].includes(buildRow.buildType) ?
                            "-"
                            :
                            buildRow.buildType
                        }
                    </FlexBox>
                </Grid>

                <Grid item xs={2.4} sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex"
                }}>
                    <FlexBox>
                        <span style={{
                            fontSize: "0.8rem",
                            color: theme.palette.mode === "dark" ? theme.palette.zohoBlue.light : theme.palette.zohoBlue.dark,
                            fontWeight: "bold"
                        }}>Build By</span>
                        {
                            [null, undefined, "", " "].includes(buildRow.buildBy) ?
                            "-"
                            :
                            <EmployeeCard emails={buildRow.buildBy} />
                        }
                    </FlexBox>
                </Grid>

                <Grid item xs={2.4}>
                    <FlexBox>
                        <span style={{
                            fontSize: "0.8rem",
                            color: theme.palette.mode === "dark" ? theme.palette.zohoRed.light : theme.palette.zohoRed.dark,
                            fontWeight: "bold"
                        }}>Comment</span>
                        {
                            [null, undefined, "", " "].includes(buildRow.buildComment) ?
                            "-"
                            :
                            buildRow.buildComment
                        }
                    </FlexBox>
                </Grid>

            </Grid>
        </Box>
    );
}

function MilestoneBottomHalf({buildRow}) {
    const theme = useTheme();

    return (
        <Box sx={{
            height: "65%",
            fontSize: "1.05rem",
            fontWeight: "600"
        }}>
            <Grid container spacing={0.5} sx={{height: "100%"}}>
                <Grid item xs={2.4}>
                    <FlexBox0>
                        <span style={{
                            fontSize: "0.8rem",
                            // color: theme.palette.mode === "dark" ? theme.palette.zohoBlue.light : theme.palette.zohoBlue.dark,
                            color: theme.palette.background.text,
                            fontWeight: "bold"
                        }}>Local</span>
                        {
                            [null, undefined, "", " "].includes(buildRow.localUpgradeStatus) ?
                                "-"
                                :
                                buildRow.localUpgradeStatus
                        }
                    </FlexBox0>
                </Grid>
                <Grid item xs={2.4}>
                    <FlexBox0>
                        <span style={{
                            fontSize: "0.8rem",
                            // color: theme.palette.mode === "dark" ? theme.palette.zohoBlue.light : theme.palette.zohoBlue.dark,
                            fontWeight: "bold",
                            color: theme.palette.background.text
                        }}>CSEZ</span>
                        {
                            [null, undefined, "", " "].includes(buildRow.csezUpgradeStatus) ?
                                "-"
                                :
                                buildRow.csezUpgradeStatus
                        }
                    </FlexBox0>
                </Grid>

                <Grid item xs={2.4}>
                    <FlexBox0>
                        <span style={{
                            fontSize: "0.8rem",
                            // color: theme.palette.mode === "dark" ? theme.palette.zohoBlue.light : theme.palette.zohoBlue.dark,
                            fontWeight: "bold",
                            color: theme.palette.background.text
                        }}>CT2</span>
                        {
                            [null, undefined, "", " "].includes(buildRow.ct2UpgradeStatus) ?
                                "-"
                                :
                                buildRow.ct2UpgradeStatus
                        }
                    </FlexBox0>
                </Grid>

                <Grid item xs={2.4}>
                    <FlexBox0>
                        <span style={{
                            fontSize: "0.8rem",
                            // color: theme.palette.mode === "dark" ? theme.palette.zohoBlue.light : theme.palette.zohoBlue.dark,
                            fontWeight: "bold",
                            color: theme.palette.background.text
                        }}>UBI1</span>
                        {
                            [null, undefined, "", " "].includes(buildRow.ubi1UpgradeStatus) ?
                                "-"
                                :
                                buildRow.ubi1UpgradeStatus
                        }
                    </FlexBox0>
                </Grid>

                <Grid item xs={2.4}>
                    <FlexBox0>
                        <span style={{
                            fontSize: "0.8rem",
                            // color: theme.palette.mode === "dark" ? theme.palette.zohoBlue.light : theme.palette.zohoBlue.dark,
                            fontWeight: "bold",
                            color: theme.palette.background.text
                        }}>UBI2</span>
                        {
                            [null, undefined, "", " "].includes(buildRow.ubi2UpgradeStatus) ?
                                "-"
                                :
                                buildRow.ubi2UpgradeStatus           
                        }
                    </FlexBox0>
                </Grid>
            </Grid>
        </Box>
    );
}

function FlexBox({ children }) {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            fontWeight: "400"
        }}>
            {children}
        </Box>
    );
}

function FlexBox0({ children }) {
    const theme = useTheme();

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            fontWeight: "400",
            backgroundColor: theme.palette.mode === "dark" ? "rgb(24,30,35)" : "rgb(248,248,248, 0.9)",
            borderRadius: "8px"
        }}>
            {children}
        </Box>
    );
}