import React, {useRef, useState} from "react";

import { 
    Dialog,
    DialogTitle,
    Button,
    Divider,
    Box
} from "@mui/material";

import styles from "../TicketsTable.module.css";
import { reply_as_bot } from "../postClaimActions";

export function BotReply({data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, setExtraComps, userEmail}) {

    const [message, setMessage] = useState("");

    function handleClose() {
        setExtraComps(null);
    }

    function handleButtonClick() {
        if(message !== "") {
            reply_as_bot(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, setExtraComps, userEmail, message)
            setExtraComps(null);
        } else {
            alert("Kindly, Enter a message !");
        }
    }

    function handleChange(event) {
        setMessage(event.target.value);
       
    }

    return (
        <Dialog onClose={handleClose} open={true} fullWidth>
            <DialogTitle>Message as Bot</DialogTitle>
            <Divider />
            <textarea className={`${styles.bot_reply_text_box} `} onChange={handleChange} rows={10} spellCheck={"false"} multiline autoFocus/>
            <Divider />
            <Box className={styles.action_button}>
                <Button variant="contained" onClick={handleButtonClick}>Post</Button>
            </Box>
        </Dialog>
    );
}