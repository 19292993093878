/* eslint-disable react/prop-types */ 
/* esline-disable react/jsx-key */
import React from "react";
import { useEffect, useRef, useState, useContext } from "react";
import { UserContext } from "../../../../../contexts/UserContext";
import { useTheme } from '@mui/material/styles';
import { Box, ButtonGroup, Skeleton, Tab } from "@mui/material";
import { ReportContext } from "../newReportGeneral/NewReport";
import { getMilestoneRows, getTasksForDRI, getTicketRows } from "../reportSection1/ReportSection1DataFetcher"; 

import AirIcon from '@mui/icons-material/Air';
import { NegativeBar, PieChart } from "../newReportGeneral/NewReportUtilComponents";
import { fetchData } from "./dataFetcher";
import { processData } from "./dataProcessor";
import { BarChart } from "../../../../../util/components/BarChart/BarChart";

export function ReportSection14() {

    const [data, setData] = useState(undefined);

    const {
        subjectType, // Server
        subject, // Zone
        timeFrame, // Monthly
        time, // Past
        timeRange // [start_date, end_date]
    } = useContext(ReportContext);

    const {teamDetails, loginUserDept} = useContext(UserContext);

    const theme = useTheme();

    var colors = [];
    if(theme.palette.mode === "light") {
        colors.push("#1b612e", "#ab261d");
    } else {
        colors.push("#34994f", "#eb4034");
    }

    useEffect(() => {
        setData(undefined);
        fetchData(timeRange, subjectType, subject, teamDetails, loginUserDept).then(d => {
            setData(processData(d, timeRange));
        }).catch(e => {
            setData(null);
        });
    }, [timeRange, subjectType, subject]);

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            width: "100%",
            height: "98%",
            gap: "10px"
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.1rem",
                fontWeight: "bold"
            }}>
                <span style={{"color": "forestgreen"}}>Tasks</span> 
                &nbsp;
                <span>vs</span> 
                &nbsp;
                <span style={{"color": "crimson"}}>Issues</span> 
                
                &nbsp;- User Wise
            </Box>
            {
                data === undefined ? 
                    (
                        <Skeleton variant={"rounded"} height={"98%"} width={"100%"} animation="wave" />
                    )
                    :
                    data === null ?
                        (
                            <NoData />
                        )
                        :
                        (
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                height: "100%",
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "1.1rem",
                                fontWeight: "bold"
                            }}>
                                <BarChart data={data}
                                    title={""}
                                    columnName1={"Users"}
                                    columnName2={"Count"}
                                    colors={colors}
                                    open={false}
                                    rotated={true}
                                    type={"full"}
                                />
                            </Box>
                        )
            }
        </Box>
    );
}   


function NoData() {
    const theme = useTheme();
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%"
        }}>
            <Box>
                <AirIcon sx={{
                    fontSize: "6rem",
                    color: theme.palette.mode === "light" ? "rgb(20, 20, 20, 0.06)": "rgb(200, 200, 200, 0.06)"
                }} />
            </Box>
            <Box sx={{
                fontSize: "0.7rem",
                color: theme.palette.mode === "light" ? "rgb(10, 10, 10, 0.3)": "rgb(200, 200, 200, 0.4)"
            }}>
                Nothing found
            </Box>
        </Box>
    );
}