//ignorei18n_start
import React, { useEffect, useMemo, useRef, useState } from "react";
 
import "../History.css";
import { findRowSpan } from "../History";

import {motion} from "framer-motion";
 
export default function YearViewTop({scrollAmount, setScrollAmount, data}) {

    const container = useRef();
    const [years, spans] = useMemo(() => {
        const ys = data.milestones.map(m => m.year);
        const spans = findRowSpan(ys);
        return [ys, spans];
    }, [data]);
    const [hovered, setHovered] = useState(false);

    useEffect(() => {
        if(!hovered) {
            const horizontal_shift = (container.current.scrollWidth-container.current.clientWidth) * (scrollAmount / 100);
            container.current.scrollLeft = horizontal_shift;
        }
    }, [scrollAmount]);

    function handleScroll() {
        const e = container.current;
        var scrollPercentage = 100 * e.scrollLeft / (e.scrollWidth-e.clientWidth);
        setScrollAmount(scrollPercentage);
    }
 
    return (
        <div className={"zvp_year_view_top"} onScroll={handleScroll} ref={container}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <div style={{ gridColumn: "auto / span 2" }}>
            </div>
            {
                years.map((y, i) => {
                    return (
                        spans[i] !== 0 ?
                            <div style={{ gridColumn: "auto / span " + spans[i] }} key={i}
                            >
                                <div>
                                    {y}
                                </div>
                            </div>
                            :
                            null
                    );
                })
            }
            <div style={{ gridColumn: "auto / span 2" }}>
            </div>
        </div>
    );
 
}
//ignorei18n_end