import { isDateInRange, isDateString } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    const chartData = [];
    const dataMap = {
        "Tickets Completed": []
    };

    var ticketsCount = 0;
    var ticketsCompletedCount = 0;

    catalystDB.Tickets.map(item => {
        const dateString = item.CREATEDTIME;

        if(isDateString(dateString) && isDateInRange(dateString, startTime)) {
            const ticketStatus = item.Status;

            ticketsCount++;

            if(ticketStatus === "Completed") {
                dataMap["Tickets Completed"].push(item);
                ticketsCompletedCount++;
            }
        }
    }); 

    chartData.push({
        "type": "bullet",
        "seriesname": "Tickets Completed",
        "data": [
            [
                [
                    "",
                    ticketsCompletedCount,
                    [
                        ticketsCount
                    ]
                ]
            ]
        ]
    });

    return [chartData, dataMap];
}