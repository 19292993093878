import { INCIDENT_DATA_FETCHER_1_DEFAULT } from "../../../../catalystDB/queryGenerator";

export function getFromTaskBreaker1(queryString = INCIDENT_DATA_FETCHER_1_DEFAULT)
{
	return new Promise((resolve, reject) => 
	{
		const start = global.performance.now();
		var zcql = window.catalyst.ZCatalystQL;
		var zcqlPromise  = zcql.executeQuery(queryString);

		zcqlPromise
				.then((response) => {
		
					const end = global.performance.now();
			
					if(response.status === 400)
					{
						reject("error");
					}
	
					var data = [];
					for(const item of response.content)
					{
						const row = item.IncidentManagementReport;
                        // console.log(item.IncidentManagementReport);
						const result = [
							[undefined, "", " ", null].includes(row.ComponentName) ? "-" : row.ComponentName,
							[undefined, "", " ", null].includes(row.ClusterName) ? "-" : row.ClusterName,
							[undefined, "", " ", null].includes(row.DCs_Affected) ? "-" : row.DCs_Affected,
							[undefined, "", " ", null].includes(row.Submitted_By) ? "-" : row.Submitted_By,
							[undefined, "", " ", null].includes(row.Incident_Title) ? "-" : row.Incident_Title,
							[undefined, "", " ", null].includes(row.ReasonForRejecting) ? "-" : row.ReasonForRejecting,
							[undefined, "", " ", null].includes(row.Notified_Product_Team) ? "#" : row.Notified_Product_Team,
							[undefined, "", " ", null].includes(row.IncidentStatus) ? "-" : row.IncidentStatus,
							[undefined, "", " ", null].includes(row.Associated_Developers) ? "-" : row.Associated_Developers,
							[undefined, "", " ", null].includes(row.Comments) ? "-" : row.Comments,
							[undefined, "", " ", null].includes(row.Claimed_By) ? "-" : row.Claimed_By,
							[undefined, "", " ", null].includes(row.Products_Affected) ? "-" : row.Products_Affected,
							[undefined, "", " ", null].includes(row.TicketID) ? "-" : row.TicketID,
							[undefined, "", " ", null].includes(row.Forward_History) ? "-" : row.Forward_History,
							[undefined, "", " ", null].includes(row.Resolved_On	) ? "-" : row.Resolved_On,
							[undefined, "", " ", null].includes(row.Title) ? "-" : row.Title	,
							[undefined, "", " ", null].includes(row.Type) ? "-" : row.Type	,
							[undefined, "", " ", null].includes(row.Query_Notes) ? "-" : row.Query_Notes,	
							[undefined, "", " ", null].includes(row.Session_Type) ? "-" : row.Session_Type	,
							[undefined, "", " ", null].includes(row.Module) ? "-" : row.Module	,
							[undefined, "", " ", null].includes(row.Issue_Priority) ? "-" : row.Issue_Priority,
							[undefined, "", " ", null].includes(row.CREATEDTIME) ? "-" : row.CREATEDTIME,
							[undefined, "", " ", null].includes(row.IncientCreatedBy) ? "-" : row.IncientCreatedBy,
							[undefined, "", " ", null].includes(row.FilestoreID) ? "-" : row.FilestoreID,		
							[undefined, "", " ", null].includes(row.ApprovedBy) ? "-" : row.ApprovedBy,
							[undefined, "", " ", null].includes(row.RootCause) ? "-" : row.RootCause,
							[undefined, "", " ", null].includes(row.IncidentTag) ? "-" : row.IncidentTag,						
							[undefined, "", " ", null].includes(row.ImpactType) ? "-" : row.ImpactType,
							[undefined, "", " ", null].includes(row.Immediate_Fix_Moved) ? "-" : row.Immediate_Fix_Moved,
							[undefined, "", " ", null].includes(row.Permanent_Fix_Moved) ? "-" : row.Permanent_Fix_Moved,
							[undefined, "", " ", null].includes(row.Immediate_Fix_Notes) ? "-" : row.Immediate_Fix_Notes,
							[undefined, "", " ", null].includes(row.Immediate_Fix_ETA) ? "-" : row.Immediate_Fix_ETA,
							[undefined, "", " ", null].includes(row.Permanent_Fix_Notes) ? "-" : row.Permanent_Fix_Notes,
							[undefined, "", " ", null].includes(row.Permanent_Fix_ETA) ? "-" : row.Permanent_Fix_ETA											

							
							

						];
						data.push(result);
					}
					if(data.length === 0)
					{
						reject("none");
					}
					resolve(data);
				})
				.catch((err) => {
					reject(err);
				});
	});
}





// export function getFromTeamDetails(queryString = "SELECT * FROM TeamDetails")
// {
// 	return new Promise((resolve, reject) => {
// 		const start = global.performance.now();
// 		var zcql = window.catalyst.ZCatalystQL;
// 		var zcqlPromise  = zcql.executeQuery(queryString);

// 		zcqlPromise
// 				.then((response) => {
// 					// console.log(response);
// 					const end = global.performance.now();
// 					console.log(`took ${end-start} ms`);

// 					if(response.status === 400)
// 					{
// 						reject("error");
// 					}

// 					var data = [];
// 					for(const item of response.content)
// 					{
// 						data.push(item);
// 					}
// 					if(data.length === 0)
// 					{
// 						reject("none");
// 					}
// 					resolve(data);
// 				})
// 				.catch((err) => {
// 					reject(err);
// 				});
// 	});
// }


// export function getData()
// {
// 	console.log(window.catalyst.table.tableId(5781000006858394));
// }

// export function getMyPagedRows(hasNext = true, next_token = undefined)
// {
// 	const start = global.performance.now();
//     if (!hasNext) javascript function that resolves a promise and returns the response value to the caller and how to wait for this function to complete and get the value from the call
//     {
//         return;
//     } 

//     window.catalyst.table
//         .tableId(5781000006856839)
//         .getPagedRows({ next_token, max_rows: 100 }) 
//         .then(resp => {
//             console.log('rows : ', resp.content); //Fetch the rows from the table
//             const end = global.performance.now();
// 	    	console.log(`${end-start} ms`);
//             return getMyPagedRows(resp.more_records, resp.next_token); 
//         })
//         .catch((err) => {
//             console.log(err.toString());
//             hasNext = false;
//         }); 
// }