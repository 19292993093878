import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { queryDB } from '../../../util/QueryDB';
import { useTheme } from '@mui/material';
import { Skeleton } from '@mui/material';
import { QuarterlyMilestoneCard } from './QuarterlyMilestoneCard';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import CircularProgress from '@mui/material/CircularProgress';
import { quarterly_info_component_get_query_1, quarterly_info_component_get_query_2 } from '../../../catalystDB/queryGenerator';


var dataCache = {};
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});
export function QuarterlyInfo({ milestone_status, Feature_type, Parallel_DuedayCount, Milestone_Startdate, MilestoneID, ServerName, Sequential_DueDayCount, index,RoadMapName}) {
	const theme = useTheme();
	// console.log(MilestoneID)

	return (
		<Box style={{
			padding: "10px 0px 10px 0px",
			backgroundColor: theme.palette.background.infoDivOuter
		}}
		>
			<Paper className="info-div-paper"
				sx={{
					backgroundColor: theme.palette.background.infoDivInner + " !important"
				}}
			>
				<ProgressBar milestone_status={milestone_status} Feature_type={Feature_type} Parallel_DuedayCount={Parallel_DuedayCount} Milestone_Startdate={Milestone_Startdate} MilestoneID={MilestoneID} ServerName={ServerName} Sequential_DueDayCount={Sequential_DueDayCount} index={index} RoadMapName={RoadMapName}/>
				{/*<Divider />*/}
				<QuaretelyMilstoneTable milestone_status={milestone_status} Feature_type={Feature_type} Parallel_DuedayCount={Parallel_DuedayCount} Milestone_Startdate={Milestone_Startdate} MilestoneID={MilestoneID} ServerName={ServerName} Sequential_DueDayCount={Sequential_DueDayCount} index={index} RoadMapName={RoadMapName}/>
			</Paper>
		</Box>
	);
}

function ProgressBar({ milestone_status, Feature_type, Parallel_DuedayCount, Milestone_Startdate, MilestoneID, ServerName, Sequential_DueDayCount, index ,RoadMapName}) {
	// console.log(milestone_status)
	// Milestone Created , Task Breaking scheduled, ongoing completed
	const steps = [
		"Milestone Created",
		"Task Breaking",
		"scheduled",
		"ongoing",
		"Completed"
	];


	const [activeStep, setActiveStep] = useState(null);

	useEffect(() => {
		async function run() {
			if (milestone_status === "Completed") {
				setActiveStep(4);
				return;
			}
			else {
				try {
					// const tasks = await getTasks("SELECT TaskID FROM TaskBreaker WHERE MilestoneID= " + milestoneId);
	
					// setActiveStep(1);
					const startDate = new Date(Milestone_Startdate);
					const now = new Date();
	
					if (Milestone_Startdate === "-") {
						setActiveStep(1);
						return;
					}
					else if (now < startDate) {
						setActiveStep(2);
						return;
					}
					else {
						setActiveStep(3);
						return;
					}
				}
				catch (e) {
					if (e === "none") {
						setActiveStep(0);
						return;
					}
					console.log(e);
					// setActiveStep(-1);
					return;
				}
			}
		}
		run();
	}, []);


	if (activeStep === null) {
		return <Skeleton width={"100%"} height={50} />;
	}

	return (
		<>
			<Stepper activeStep={activeStep} connector={<ColorlibConnector />}>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
		</>
	);
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 22,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor: theme.palette.primary.main,
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor: theme.palette.mode === 'dark' ? "#717171" : '#a0a0a0',
		borderRadius: 1,
	},
}));

function QuaretelyMilstoneTable({ milestone_status, Feature_type, Parallel_DuedayCount, Milestone_Startdate, MilestoneID, ServerName, Sequential_DueDayCount, index,RoadMapName}) {
	// console.log(RoadMapName)
	const [valuesData, setValuesData] = useState([]);
	const [MilestoneData, setMilestoneData] = useState([]);
	// const [open, setOpen] = React.useState(null);
	const [open, setOpen] = useState(false);

	const handleOpenDialog = () => {
	  setOpen(true);
	};
  
	const handleCloseDialog = () => {
	  setOpen(false);
	};
	const theme = useTheme();
	const handleClick = () => {
		setOpen(!open);
		// if (open === index) {
		// 	setOpen(null);
		// }

		// else {
		// 	setOpen(index);
		// }
	};

	useEffect(() => {
		fetchValuesData();
		fetchValuesData1();
	}, []);

	const fetchValuesData = async () => {
		try {
			const query = quarterly_info_component_get_query_1(MilestoneID);
			if(query in dataCache) {
				setValuesData(dataCache[query]);
			} else {
				const result = await queryDB(query);
				dataCache[query] = result;
				setValuesData(result);
			}
		} catch (error) {
			console.error(error);
		}
	};

	

	const fetchValuesData1 = async () => {
		try {
			const queryString = quarterly_info_component_get_query_2(MilestoneID);
			// console.log(queryString)
			if(queryString in dataCache) {
				setMilestoneData(dataCache[queryString]);
			} else {
				const result = await queryDB(queryString);
				dataCache[queryString] = result;
				setMilestoneData(result);
			}
			
			//   console.log(result[15].MilestoneName);

		} catch (error) {
			console.error(error);
		}
	};
	// console.log(MilestoneData)

	var dataAsArray = MilestoneData.map((item) => {
		return [
			
			item.TaskDetails.MilestoneName,
			item.TaskDetails.Component,
			item.TaskDetails.DRI,
			[undefined, "", " ", null].includes(item.TaskDetails.MilestoneStartDate) ? "-" : item.TaskDetails.MilestoneStartDate,
			[undefined, "", " ", null].includes(item.TaskDetails.MilestoneDueDate) ? "-" : item.TaskDetails.MilestoneDueDate,
			item.TaskDetails.MilestoneDescription
			
		];
	});
	// console.log(dataAsArray);


	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow className="tasklist-table-head">
						<TableCell align="center" >Feature Type</TableCell>
						<TableCell align="center" >Milestone Status</TableCell>
						{Feature_type === "Parallel" ? (<TableCell align="center" >Parallel Due Day Count</TableCell>) : (<TableCell align="center" >Sequential Due Day Count</TableCell>)}
						<TableCell align="center" >Milestone Start Date</TableCell>
						<TableCell align="center" >Server name</TableCell>
						{/* <TableCell></TableCell> */}
					</TableRow>
				</TableHead>
				<TableBody>
				<Dialog
				fullScreen
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleCloseDialog}
				PaperProps={{
				style: {
					backgroundColor: theme.palette.background.pageContent,
					boxShadow: "none"
				}
				}}
				>
				<DialogTitle style={{ float: "left", display: "flex", alignItems: "center" }}>
				<Tooltip title={"Road Map Name"}><div>Road Map</div></Tooltip>
				<ArrowRightIcon />
				<Tooltip><div>{RoadMapName}</div></Tooltip>
				</DialogTitle>
				<DialogActions style={{ float: "right", padding: "16px 24px 16px 24px" }}>
				<Button onClick={handleCloseDialog} variant="text"><CloseIcon fontSize="medium" /></Button>
				</DialogActions>
				<DialogContent style={{ paddingTop: 0 }}> 
				<QuarterlyMilestoneCard
					milestone_name={dataAsArray.map((item) => item[0])}
					Server={dataAsArray.map((item) => item[1])}
					DRI={dataAsArray.map((item) => item[2])}
					Milestone_Startdate={dataAsArray.map((item) => item[3])}
					MilestoneDueDate={dataAsArray.map((item) => item[4])}
					index={index}
					MilestoneID={MilestoneID}
					milestone_status={milestone_status}
					RoadMapName={RoadMapName}
					MilestoneDescription={dataAsArray.map((item) => item[5])}
				/>
				</DialogContent>
				</Dialog>
				{dataAsArray.length > 0 ?(<TableRow sx={{
              backgroundColor: theme.palette.background.tableRow,
              '&:hover': {
                backgroundColor: theme.palette.background.tableRowHover + " !important"
              }
            }}>
						<TableCell onClick={handleClick} align="center" >{Feature_type}</TableCell>
						<TableCell onClick={handleClick} align="center" >{milestone_status}</TableCell>
						{Feature_type === "Parallel" ? (<TableCell align="center" onClick={handleClick}>{Parallel_DuedayCount}</TableCell>) : (<TableCell align="center" onClick={handleClick}>{Sequential_DueDayCount}</TableCell>)}
						{/* <TableCell align="center" >{Parallel_DuedayCount}</TableCell> */}
						<TableCell align="center" onClick={handleClick} >
							{dataAsArray.length > 0 ? (
								<div>
									{dataAsArray.map((item) => (
									<div key={item[3]}>{item[3]}</div>
									))}
								</div>
								) : (
									<Box sx={{ display: 'flex' }}>
									   <CircularProgress />
								    </Box>
								)}
						</TableCell>
						<TableCell align="center" onClick={handleClick}>{valuesData.length > 0 ? (
							valuesData.map((data, index) => (
								<div key={index} onClick={handleClick}>{data.QuarterlyRMap.ServerName}</div>
							))
						) : (
							<Box sx={{ display: 'flex' }}>
							  <CircularProgress />
							</Box>
						)}
						</TableCell>
						{/* <TableCell colSpan={4}  align="center" style={{ padding: "0px 10px 0px 10px" }} >
						<ListItemButton onClick={handleClick}>
							<ListItemText />
							{open ? <ExpandLess /> : <ExpandMore />}
						</ListItemButton>
						</TableCell> */}
						{/* <TableCell align="left" className='slide-animation' style={{ width: "30px" }}>
							<IconButton size="small" onClick={handleClick}>
								{open === null ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
							</IconButton>
						</TableCell> */}
					</TableRow>
					)
					:
					(
					<TableRow sx={{
						backgroundColor: theme.palette.background.tableRow,
						'&:hover': {
						  backgroundColor: theme.palette.background.tableRowHover + " !important"
						}
					  }}>
								  <TableCell onClick={handleClick} align="center" ><Skeleton></Skeleton></TableCell>
								  <TableCell onClick={handleClick} align="center" ><Skeleton></Skeleton></TableCell>
								  <TableCell><Skeleton></Skeleton></TableCell>
								  {/* <TableCell align="center" >{Parallel_DuedayCount}</TableCell> */}
								  <TableCell align="center" onClick={handleClick} >
								  <Skeleton></Skeleton>
								  </TableCell>
								  <TableCell align="center" onClick={handleClick}><Skeleton></Skeleton>
								  </TableCell>
								  {/* <TableCell colSpan={4}  align="center" style={{ padding: "0px 10px 0px 10px" }} >
								  <ListItemButton onClick={handleClick}>
									  <ListItemText />
									  {open ? <ExpandLess /> : <ExpandMore />}
								  </ListItemButton>
								  </TableCell> */}
								  {/* <TableCell align="left" className='slide-animation' style={{ width: "30px" }}>
									  <IconButton size="small" onClick={handleClick}>
										  {open === null ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
									  </IconButton>
								  </TableCell> */}
							  </TableRow>
							  )}
					
					{/* <TableRow>
						<TableCell colSpan={6} sx={{padding: 0}}>
							<Collapse in={open !== null} timeout="auto" unmountOnExit>
								<QuarterlyMilestoneCard milestone_name={dataAsArray.map((item) => item[0])} Server={dataAsArray.map((item) => item[1])} DRI={dataAsArray.map((item) => item[2])} Milestone_Startdate={dataAsArray.map((item) => item[3])} MilestoneDueDate={dataAsArray.map((item) => item[4])} index={index} MilestoneID={MilestoneID} />
							</Collapse>
						</TableCell>
					</TableRow> */}
				</TableBody>
			</Table>
		</TableContainer>
	);
}


