/* eslint-disable react/prop-types */ 
/* eslint-disable react/jsx-key */ 
import React, { createContext, useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { useContext } from "react";
import { 
    Box,
    Paper,
    Grid,
    Tooltip,
    Skeleton
} from "@mui/material";
import { UserContext } from "../../../../../contexts/UserContext";
import { ReportContext } from "../newReportGeneral/NewReport";
import styled from "@emotion/styled";
import { EmployeeCard, GetNameAndEmpID } from "../../../../../util/Loading";
import { getTasksUnderMilestone } from "./ReportSection1DataFetcher";
import { TasksToolTipCard } from "./TasksToolTipCard";
import Grow from '@mui/material/Grow';

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? 'rgb(23,27,32)' : 'rgb(215, 219, 219, 0.8)',
	...theme.typography.body2,
	padding: theme.spacing(1),
	paddingTop: 0,
	paddingBottom: 0,
    borderRadius: "7px",
	color: theme.palette.text.primary,
	height: "120px",
	justifyContent: "center",
	alignItems: "center"
}));

export const TasksUnderMilestoneContext = createContext();

export function MilestoneCards({milestoneRows, toggleOngoing, setToggleOngoing}) {

    const {
        subjectType, 
        setSubjectType, 
        subject, 
        setSubject, 
        timeFrame, 
        setTimeFrame, 
        time, 
        setTime,
        timeRange,
        setTimeRange
    } = useContext(ReportContext);
    const theme = useTheme();

    const {teamDetails, loginUserDept} = useContext(UserContext);

    const [taskDetails, setTaskDetails] = useState(undefined); 

    useEffect(() => {

        setTaskDetails(undefined);
        var milestoneIdArray = []; 

        milestoneRows.forEach(row => {
            milestoneIdArray.push(row.milestone_id);
        });

        getTasksUnderMilestone(milestoneIdArray, timeRange[0], teamDetails, loginUserDept).then((response) => {
            setTaskDetails(response);
        }).catch((error) => {
            setTaskDetails(null); 
        });

    }, [toggleOngoing]);

    milestoneRows = milestoneRows.filter(row => {
        if(!toggleOngoing) {
            return row.completed_date !== null;
        } else {
            return row.completed_date === null;
        }
    });

    return (
        <TasksUnderMilestoneContext.Provider value={{
            taskDetails,
            setTaskDetails
        }}>
            <Box sx={{
                marginLeft: "10px",
                marginRight: "10px",
                marginTop: "10px",
                marginBottom: "10px"
            }}>
                <Grid container spacing={2} >
                    {milestoneRows.map((milestoneRow, index) => {
                        return (
                            <Grid item xs={12} key={toggleOngoing + "" + index} >
                                <Item>
                                    <MilestoneCard milestoneRow={milestoneRow} />
                                </Item>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        </TasksUnderMilestoneContext.Provider>
    );

}

function MilestoneCard({milestoneRow}) {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%"
        }}>
            <MilestoneTopHalf milestoneRow={milestoneRow}/>
            <MilestoneBottomHalf milestoneRow={milestoneRow} />
        </Box>
    );
}

function MilestoneTopHalf({milestoneRow}) {
    const theme = useTheme();

    return (
        <Box sx={{
            height: "65%",
            fontSize: "1.05rem",
            fontWeight: "600"
        }}>
            <Grid container spacing={0.5} sx={{height: "100%"}}>
                <Grid item xs={4}>
                    <Box style={{
                        justifyContent: "left !important",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                        paddingLeft: "10px",
                        height: "100%",
                        overflowX: "scroll",
                        marginRight: "20px"
                    }} className="nested-view-overflow-container-2">
                        {
                            [null, undefined, "", " "].includes(milestoneRow.milestone_name) ?
                                "-"
                                :
                                milestoneRow.milestone_name
                        }
                    </Box>
                </Grid>
                <Grid item xs={2.6}>
                    <FlexBox>
                        {
                            [null, undefined, "", " "].includes(milestoneRow.milestone_dri) ?
                                "-"
                                :
                                <GetNameAndEmpID emails={milestoneRow.milestone_dri} />
                        }
                    </FlexBox>
                </Grid>

                <Grid item xs={1.8}>
                    <FlexBox>
                        <span style={{
                            fontSize: "0.8rem",
                            color: theme.palette.mode === "dark" ? theme.palette.zohoYellow.light : theme.palette.zohoYellow.dark,
                            fontWeight: "bold"
                        }}>Started On</span>
                        {
                            [null, undefined, "", " "].includes(milestoneRow.start_date) && !isValidDateString(milestoneRow.start_date) ?
                                "-"
                                :
                                formatDate(milestoneRow.start_date)
                        }
                    </FlexBox>
                </Grid>

                <Grid item xs={1.8}>
                    <FlexBox>
                        <span style={{
                            fontSize: "0.8rem",
                            color: theme.palette.mode === "dark" ? theme.palette.zohoRed.light : theme.palette.zohoRed.dark,
                            fontWeight: "bold"
                        }}>Due On</span>
                        {
                            [null, undefined, "", " "].includes(milestoneRow.due_date) && !isValidDateString(milestoneRow.due_date) ?
                                "-"
                                :
                                formatDate(milestoneRow.due_date)
                        }
                    </FlexBox>
                </Grid>

                <Grid item xs={1.8}>
                    <FlexBox>
                        <span style={{
                            fontSize: "0.8rem",
                            color: theme.palette.mode === "dark" ? theme.palette.zohoGreen.light : theme.palette.zohoGreen.dark,
                            fontWeight: "bold"
                        }}>Completed On</span>
                        {
                            [null, undefined, "", " "].includes(milestoneRow.completed_date) && !isValidDateString(milestoneRow.completed_date) ?
                                "-"
                                :
                                formatDate(milestoneRow.completed_date)
                        }
                    </FlexBox>
                </Grid>
            </Grid>
        </Box>  
    );
}

function MilestoneBottomHalf({milestoneRow}) {
    const theme = useTheme();
    const milestoneID = milestoneRow.milestone_id;
    const {taskDetails} = useContext(TasksUnderMilestoneContext);
    var splits = [];

    if(taskDetails !== undefined) {
        try {
            var obj = taskDetails[milestoneID];
    
            var old_completed_count = obj.old_completed.length;
            var new_completed_count = obj.new_completed.length;
            var incomplete_count = obj.incomplete.length;
            var total = old_completed_count + new_completed_count + incomplete_count;
            if (total !== 0) {
                var old_completed_perc = (old_completed_count / total) * 100;
                var new_completed_perc = (new_completed_count / total) * 100;
                var incomplete_perc = (incomplete_count / total) * 100;

                if (isNaN(old_completed_perc)) {
                    old_completed_perc = 0;
                }
                if (isNaN(new_completed_perc)) {
                    new_completed_perc = 0;
                }
                if (isNaN(incomplete_perc)) {
                    incomplete_perc = 0;
                }

                splits = [
                    {
                        "label": old_completed_perc.toFixed(1) + "%",
                        "value": old_completed_perc,
                        "color": theme.palette.zohoGreen.main,
                        "color0": "green",
                        "type": "old_completed",
                        "list": obj.old_completed,
                        "className": "progress-bar-report",
                        "label2": "Completed before selected time"
                    },
                    {
                        "label": new_completed_perc.toFixed(1) + "%",
                        "value": new_completed_perc,
                        "color": theme.palette.zohoGreen.light,
                        "color0": "seagreen",
                        "type": "new_completed",
                        "list": obj.new_completed,
                        "className": "progress-bar-report bar-fill",
                        "label2": "Completed in selected time"
                    },
                    {
                        "label": incomplete_perc.toFixed(1) + "%",
                        "value": incomplete_perc,
                        "color": theme.palette.mode === "light" ? "rgb(175,158,151)" : "rgb(90,79,81)",
                        "color0": "crimson",
                        "type": "incomplete",
                        "list": obj.incomplete,
                        "className": "progress-bar-report",
                        "label2": "Incomplete"
                    }
                ];

            }

        } catch(e) {}
    }
    
    if(taskDetails === undefined) {
        return (
            <Skeleton animation={"wave"} height={"20px"} width={"100%"} />
        );
    }

    if(splits.length === 0) {
        return (
            <Box sx={{
                height: "35%"
            }}>
                {/* <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "100px",
                    height: "13px",
                    width: "100%",
                    overflow: "hidden"
                }}>
                    <Box sx={{
                        width: "100%",
                        backgroundColor: "#111111",
                        height: "10px",
                        opacity: 0.5,
                        borderRadius: "3px"
                    }}>
                    </Box>
                </Box> */}
                <Box sx={{
                    display: "flex",
                    borderRadius: "100px",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    overflow: "hidden",
                    fontWeight: "800",
                    fontSize: "0.8rem"
                }}>
                    No Data
                </Box>
            </Box>
        );
    }

    return (
        <Box sx={{
            height: "35%"
        }}>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "100px",
                height: "13px",
                width: "100%",
                overflow: "hidden"
            }}>
                {splits.map(item => {
                    return (
                        <Tooltip arrow={true} TransitionComponent={Grow}  TransitionProps={{ timeout: 300 }}
                        title={<TasksToolTipCard tasksArray={item.list} tasksType={item.type} color={item.color0}/>} PopperProps={{
                            sx: {
                              "& .MuiTooltip-tooltip": {
                                boxShadow: "1px 1px 3px 1px " + item.color,
                                maxWidth: "100000px",
                                opacity: 0,
                                backgroundColor: theme.palette.mode === "light" ? "rgb(250, 250, 250, 0.96)" : "rgb(46,50,55, 0.96)"
                              },
                              '& .MuiTooltip-arrow': {
                                color: item.color,
                                fontSize: 20,
                                "&::before": {
                                    border: "1px solid " + item.color
                                }
                              }
                            }
                          }} placement={item.type !== "new_completed" ? "bottom-start": "bottom"} className="hover-target">
                            <Box sx={{
                                width: item.value + "%",
                                backgroundColor: item.color,
                                height: "10px",
                                opacity: 0.8,
                                borderRadius: "4px",
                                "&:hover": {
                                    opacity: 1,
                                    height: "13px",
                                    borderRadius: 10
                                }
                            }} className={item.className + " hover-target hover-value" }>
                            </Box>
                        </Tooltip>

                    )
                })}
            </Box>
            <Box sx={{
                display: "flex",
                borderRadius: "100px",
                width: "100%",
                overflow: "hidden",
                fontWeight: "500"
            }}>
                {splits.map(item => {
                    if(item.value !== 0) {
                        return (
                            <Box sx={{
                                width: item.value + "%",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px",
                                color: item.color0
                            }}>
                                <Box>
                                    {item.label}
                                </Box>
                                {
                                    item.value >= 15 ? 
                                        <Box sx={{
                                            fontSize: "0.7rem"
                                        }}>
                                            {item.label2}
                                        </Box>
                                        :
                                        null
                                }
                            </Box>
                        )
                    }
                })}
            </Box>
        </Box>
    );
}

function FlexBox({children}) {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            fontWeight: "400"
        }}>
            {children}
        </Box>
    );
}

function formatDate(date) {
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    date = new Date(date);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const formattedDate = `${day}, ${months[monthIndex]} ${year}`;
    return formattedDate;
}

function isValidDateString(dateString) {
    const date = new Date(dateString);
    // Check if the parsed date is a valid date and not equal to "Invalid Date"
    return !isNaN(date) && date.toISOString() === dateString;
}