import React, { useEffect, useState, useContext } from "react";
import {
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    Divider,
    useTheme
} from '@mui/material';
import { UserContext } from "../../../../../contexts/UserContext";
import { release_planner_report_query } from "../../../../../catalystDB/queryGenerator";
import { executeQuery } from "../../../../../util/QueryDB";
import { BottomSection } from "../../../../../util/components/verticalTimeline/SplitMultiple";

const STATUS = Object.freeze({
    LOADING: 'Loading',
    OK: 'Ok',
    NONE: 'None',
    ERROR: 'Err'
});

// components

export function ReleasePlannerReport({ currentSelect }) {
    const theme = useTheme();
    const styles = new Styles(theme);
    const { loginUserDept } = useContext(UserContext);

    const [reportData, setReportData] = useState(null);
    const [status, setStatus] = useState(STATUS.LOADING);
    const [view, setView] = useState("Overall");

    function handleViewChange(newView) {
        setView(newView);
    }

    // fetching the data.
    useEffect(() => {
        setReportData(null);
        setStatus(STATUS.LOADING);
        async function getReportData() {
            const result = await generateReportData(currentSelect, loginUserDept.current, view);
            switch (result.status) {
                case STATUS.ERROR:
                    setReportData(null);
                    setStatus(STATUS.ERROR);
                    break;
                case STATUS.NONE:
                    setReportData(null);
                    setStatus(STATUS.NONE);
                    break;
                case STATUS.OK:
                    setReportData(result.data);
                    setStatus(STATUS.OK);
                    break;
            }
        }
        getReportData();
    }, [currentSelect, loginUserDept, view]);

    // showing the data.
    return (
        <Box sx={styles.container}>
            <ReportHeader styles={styles} />
            <Box sx={styles.topSelectors}>
                <ButtonGroup>
                    <Button variant={view === "Internal" ? "contained" : "outlined"} sx={{ fontSize: "0.6rem", borderRadius: "10px", textTransform: "none", padding: "1px 10px" }} onClick={() => handleViewChange('Internal')}>
                        Internal
                    </Button>
                    <Button variant={view === "Overall" ? "contained" : "outlined"} sx={{ fontSize: "0.6rem", borderRadius: "10px", textTransform: "none", padding: "1px 10px" }} onClick={() => handleViewChange('Overall')}>
                        Overall
                    </Button>
                    <Button variant={view === "External" ? "contained" : "outlined"} sx={{ fontSize: "0.6rem", borderRadius: "10px", textTransform: "none", padding: "1px 10px" }} onClick={() => handleViewChange('External')}>
                        External
                    </Button>
                </ButtonGroup>
            </Box>
            {
                status === STATUS.LOADING ?
                    <RPReportLoader styles={styles} />
                    :
                    status === STATUS.ERROR ?
                        <RPReportFailed styles={styles} />
                        :
                        status === STATUS.NONE ?
                            <RPReportNone styles={styles} />
                            :
                            status === STATUS.OK ?
                                <RPReport styles={styles} reportData={reportData} />
                                :
                                <></>
            }
        </Box>
    );
}

function ReportHeader({ styles }) {
    return (
        <Box sx={styles.header}>
            <h2 style={{margin: "5px"}}>Release Planner</h2>
        </Box>
    );
}

function RPReport({ styles, reportData }) {
    return (
        <Box sx={styles.reportBody}>
            <Box>
                <Box sx={styles.topCounters}>
                    <Box sx={styles.counterContainer}>
                        <Box sx={styles.counterLabel}>Completed</Box>
                        <Box sx={{ ...styles.counterValue, color: 'forestgreen' }}>{reportData.completed_total}</Box>
                    </Box>
                    <Divider orientation="vertical" flexItem variant="middle"/>
                    <Box sx={styles.counterContainer}>
                        <Box sx={styles.counterLabel}>Considered</Box>
                        <Box sx={{ ...styles.counterValue, color: 'rgb(66, 78, 180)' }}>{reportData.considered_total}</Box>
                    </Box>
                    <Divider orientation="vertical" flexItem variant="middle"/>
                    <Box sx={styles.counterContainer}>
                        <Box sx={styles.counterLabel}>Backlog</Box>
                        <Box sx={{ ...styles.counterValue, color: 'crimson' }}>{reportData.backlog_total}</Box>
                    </Box>
                </Box>
                <Box sx={styles.progressBar}>
                    <BottomSection data={getOverallData(reportData)} other={true} />
                </Box>
            </Box>
            {/* <Divider sx={{ margin: 1 }} /> */}
            <Box>
                <Box sx={styles.topCounters}>
                    <Box sx={styles.counterContainer}>
                        <Box sx={styles.counterLabel}>Completed - On Time</Box>
                        <Box sx={{ ...styles.counterValue, color: 'forestgreen' }}>{reportData.completed_ontime}</Box>
                    </Box>
                    <Divider orientation="vertical" flexItem variant="middle"/>
                    <Box sx={styles.counterContainer}>
                        <Box sx={styles.counterLabel}>Completed - Delayed</Box>
                        <Box sx={{ ...styles.counterValue, color: 'rgb(66, 78, 180)' }}>{reportData.completed_delayed}</Box>
                    </Box>
                </Box>
                <Box sx={styles.progressBar}>
                    <BottomSection data={getCompletedData(reportData)} other={true} />
                </Box>
            </Box>
            {/* <Divider sx={{ margin: 1 }} /> */}
            <Box>
                <Box sx={styles.topCounters}>
                    <Box sx={styles.counterContainer}>
                        <Box sx={styles.counterLabel}>Considered & On Schedule</Box>
                        <Box sx={{ ...styles.counterValue, color: 'forestgreen' }}>{reportData.considered_ontime}</Box>
                    </Box>
                    <Divider orientation="vertical" flexItem variant="middle"/>
                    <Box sx={styles.counterContainer}>
                        <Box sx={styles.counterLabel}>Considered & Overdue</Box>
                        <Box sx={{ ...styles.counterValue, color: 'rgb(66, 78, 180)' }}>{reportData.considered_overdue}</Box>
                    </Box>
                </Box>
                <Box sx={styles.progressBar}>
                    <BottomSection data={getConsideredData(reportData)} other={true} />
                </Box>
            </Box>
        </Box>
    );
}

function RPReportLoader({ styles }) {
    return (
        <Box sx={styles.preReportBody}>
            <Box sx={{transform: "translateY(-21px)"}}>
                <CircularProgress />
            </Box>
        </Box>
    );
}

function RPReportNone({ styles }) {
    return (
        <Box sx={styles.preReportBody}>
            <Box sx={{ transform: "translateY(-21px)" }}>
                currently no releases are planned
            </Box>
        </Box>
    );
}

function RPReportFailed({ styles }) {
    return (
        <Box sx={styles.preReportBody}>
            <Box sx={{ transform: "translateY(-21px)" }}>
                something went wrong, try again later.
            </Box>
        </Box>
    );
}

// helpers

async function generateReportData(currentSelect, department, view) {
    const result = {
        status: STATUS.ERROR,
        data: null
    };

    await processReportData(currentSelect, department, result, view)

    return result;
}

async function fetchReportData(currentSelect, department, view) {
    return new Promise((resolve, reject) => {
        const queries = release_planner_report_query(currentSelect, department, view);
        Promise.allSettled(queries.map((item) => executeQuery(item)))
            .then(response => {
                return resolve(response);
            }).catch(error => {
                return reject(error);
            });
    });
}

async function processReportData(currentSelect, department, result, view) {
    try {
        const res = [
            "total_backlog",
            "considered_ontime",
            "considered_overdue",
            "completed_ontime",
            "completed_delayed"
        ];
        const data = await fetchReportData(currentSelect, department, view);
        // console.log(data)
        const dta = {};
        data.filter(d => d.status === 'fulfilled')
            .map((d, ind) => {
                // console.log(d)
                // console.log(d.value[0].ExternalFeatureReport['COUNT(ROWID)'])
                try {
                    dta[res[ind]] = Number.parseInt(d.value[0].ExternalFeatureReport['COUNT(ROWID)']);
                } catch (e) {
                    dta[res[ind]] = 0;
                }
            });
            // console.log(dta)
        result.data = {
            "completed_total": dta.completed_ontime + dta.completed_delayed,
            "considered_total": dta.considered_ontime + dta.considered_overdue,
            "backlog_total": dta.total_backlog,
            "considered_ontime": dta.considered_ontime,
            "considered_overdue": dta.considered_overdue,
            "completed_ontime": dta.completed_ontime,
            "completed_delayed": dta.completed_delayed,
            "total": dta.completed_ontime + dta.completed_delayed + dta.considered_ontime + dta.considered_overdue + dta.total_backlog
        };
        result.status = STATUS.OK;
    } catch (e) {
        console.log(e);
        result.status = STATUS.ERROR;
        result.data = null;
    }
}

function getCompletedData(data) {
    return [{
        name: "Completed - On Time " + getPercentage(data.completed_ontime, data.completed_total),
        completion: data.completed_ontime,
        total: data.completed_ontime
    }, {
        name: "Completed - Delayed " + getPercentage(data.completed_delayed, data.completed_total),
        completion: data.completed_delayed,
        total: data.completed_delayed
    }];
}

function getConsideredData(data) {
    return [{
        name: "Considered & On Schedule " + getPercentage(data.considered_ontime, data.considered_total),
        completion: data.considered_ontime,
        total: data.considered_ontime
    }, {
        name: "Considered & Overdue " + getPercentage(data.considered_overdue, data.considered_total),
        completion: data.considered_overdue,
        total: data.considered_overdue
    }];
}

function getOverallData(data) {
    return [{
        name: "Completed " + getPercentage(data.completed_total, data.total),
        completion: data.completed_total,
        total: data.completed_total
    }, {
        name: "Considered " + getPercentage(data.considered_total, data.total),
        completion: data.considered_total,
        total: data.considered_total
    }, {
        name: "Backlog " + getPercentage(data.backlog_total, data.total),
        completion: data.backlog_total,
        total: data.backlog_total
    }];
}

function getPercentage(a, b) {
    if (b === 0) return "0%";
    return `(${a}/${b}) ${(100 * (a / b)).toFixed(1)}%`;
}

// styles 

class Styles {
    constructor(theme) {
        this.theme = theme;
    }

    get container() {
        return {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: "100%",
            height: "100%",
            overflow: "scroll"
        };
    }

    get header() {
        return {
            display: 'flex',
            flex: 1
        };
    }

    get topSelectors() {
        return {
            margin: '0px 0px 5px 0px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
        };
    }

    get reportBody() {
        return {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            width: '100%',
            flex: 10
        };
    }

    get topCounters() {
        return {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around'
        }
    }

    get counterContainer() {
        return {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }
    }

    get counterLabel() {
        return {
            fontSize: '0.6rem',
            color: this.theme.palette.background.textSecondary
        };
    }

    get counterValue() {
        return {
            fontSize: '2.5rem',
            lineHeight: 1.3,
            fontWeight: '500'
        };
    }

    get preReportBody() {
        return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 10
        };
    }

    get progressBar() {
        return {
            width: '100%'
        };
    }
}