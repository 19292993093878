/* eslint-disable react/prop-types */ 
/* esline-disable react/jsx-key */
import React from "react";
import { useEffect, useRef, useState, useContext } from "react";
import { UserContext } from "../../../../../contexts/UserContext";
import { useTheme } from '@mui/material/styles';
import { Box, ButtonGroup, Skeleton, Tab } from "@mui/material";
import { ReportContext } from "../newReportGeneral/NewReport";
import { getMilestoneRows, getTasksForDRI, getTicketRows } from "../reportSection1/ReportSection1DataFetcher"; 

import AirIcon from '@mui/icons-material/Air';
import { NegativeBar, PieChart } from "../newReportGeneral/NewReportUtilComponents";

export function ReportSection13() {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            width: "100%",
            height: "98%",
            gap: "10px"
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.1rem",
                fontWeight: "bold"
            }}>
                Tasks VS Issues
            </Box>
            <ReportBody/>
        </Box>
    );
}   


function ReportBody({setExpanded}) {

    const {
        subjectType, 
        setSubjectType, 
        subject, 
        setSubject, 
        timeFrame, 
        setTimeFrame, 
        time, 
        setTime,
        timeRange,
        setTimeRange
    } = useContext(ReportContext);
    const theme = useTheme();
    
    const [loaded, setLoaded] = useState(undefined);
    const [chartData, setChartData] = useState([]);
    const {teamDetails, loginUserDept} = useContext(UserContext);

    // const data = [
    //     {
    //         "seriesname": "Fans Lost",
    //         "type": "bar",
    //         "data": [
    //             [
    //                 [
    //                     "19 Oct 2017",
    //                     0
    //                 ],
    //                 [
    //                     "18 Oct 2017",
    //                     0
    //                 ],
    //                 [
    //                     "17 Oct 2017",
    //                     -2
    //                 ],
    //                 [
    //                     "16 Oct 2017",
    //                     0
    //                 ],
    //                 [
    //                     "15 Oct 2017",
    //                     -1
    //                 ],
    //                 [
    //                     "14 Oct 2017",
    //                     -1
    //                 ],
    //                 [
    //                     "13 Oct 2017",
    //                     -2
    //                 ]
    //             ]
    //         ]
    //     },
    //     {
    //         "seriesname": "New Fans",
    //         "type": "bar",
    //         "data": [
    //             [
    //                 [
    //                     "19 Oct 2017",
    //                     1
    //                 ],
    //                 [
    //                     "18 Oct 2017",
    //                     10
    //                 ],
    //                 [
    //                     "17 Oct 2017",
    //                     0
    //                 ],
    //                 [
    //                     "16 Oct 2017",
    //                     0
    //                 ],
    //                 [
    //                     "15 Oct 2017",
    //                     1
    //                 ],
    //                 [
    //                     "14 Oct 2017",
    //                     0
    //                 ],
    //                 [
    //                     "13 Oct 2017",
    //                     2
    //                 ]
    //             ]
    //         ]
    //     }
    // ];

    useEffect(() => {
        const controller = new AbortController();

        async function pullIssueRows(taskObj) {
            getTicketRows(timeRange, subjectType, subject, teamDetails, loginUserDept).then(response => {
                if(!controller.signal.aborted) {
                    if(response.length !== 0) {

                        var issueObj = {};
                        var timeTable = {};
                        var data = [];

                        response.forEach(item => {
                            try {

                                var date = new Date(item.CREATEDTIME);
                                date.setHours(0, 0, 0, 0);
                                date = date.getTime();
                                
                                if (date <= 0) {
                                    return;
                                }
                                if (date in timeTable) {
                                    timeTable[date] -= 1;
                                } else {
                                    timeTable[date] = -1;
                                }
                            } catch (e) {}

                        });

                        for (var key in timeTable) {
                            data.push([
                                key,
                                timeTable[key]
                            ])
                        }

                        issueObj.seriesname = "Issues";
                        issueObj.type = "bar";
                        issueObj.data = data;

                        var finalArr = joinBoth(taskObj, issueObj);

                        setChartData(finalArr);
                        setLoaded(true);

                    } else {
                        var issueObj = {};
                        issueObj.seriesname = "Issues";
                        issueObj.type = "bar";
                        issueObj.data = [];

                        setChartData([taskObj, issueObj]);
                        setLoaded(true);
                    }
                }
            }).catch(error => {
                if(!controller.signal.aborted) {
                    var issueObj = {};
                    issueObj.seriesname = "Issues";
                    issueObj.type = "bar";
                    issueObj.data = [];

                    setChartData([taskObj, issueObj]);
                    setLoaded(true);
                }
            });
        }

        async function pullTaskRows() {
            getTasksForDRI(timeRange, subjectType, subject, teamDetails, loginUserDept).then((response) => {
                if(!controller.signal.aborted) {
                    if(response.length !== 0) {
                        var taskObj = {};
                        var timeTable = {};
                        var data = [];

                        response.forEach(item => {
                            try {
                                var date = new Date(item.TaskStartDate_);
                                date.setHours(0, 0, 0, 0);
                                date = date.getTime(); 
                                if(date <= 0) {
                                    return;
                                }
                                if(date in timeTable) {
                                    timeTable[date] += 1
                                } else {
                                    timeTable[date] = 1;
                                }
                            } catch(e) {}
                        })

                        for(var key in timeTable) {
                            data.push([
                                key, 
                                timeTable[key]
                            ]);
                        }

                        taskObj.seriesname = "Tasks";
                        taskObj.type = "bar";
                        taskObj.data = data;

                        // console.log(taskObj);

                        pullIssueRows(taskObj);
 
                    } else {
                        var taskObj = {};
                        taskObj.seriesname = "Tasks";
                        taskObj.type = "bar";
                        taskObj.data = [];
                        pullIssueRows(taskObj);
                    }
                }
            }).catch((error) => {
                if(!controller.signal.aborted) {
                    var taskObj = {};
                    taskObj.seriesname = "Tasks";
                    taskObj.type = "bar";
                    taskObj.data = [];
                    pullIssueRows(taskObj);
                }
            });
        }

        setLoaded(undefined);
        pullTaskRows();

        return () => {
            controller.abort();
        };

    }, [timeRange, subjectType, subject]);

    if(loaded === undefined) {
        return (
            <Skeleton variant={"rounded"} height={"98%"} width={"100%"} animation="wave" />
        );
    } else if(loaded === false) {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%"
            }}>
                <Box>
                    <AirIcon sx={{
                        fontSize: "6rem",
                        color: theme.palette.mode === "light" ? "rgb(20, 20, 20, 0.06)": "rgb(200, 200, 200, 0.06)"
                    }} />
                </Box>
                <Box sx={{
                    fontSize: "0.7rem",
                    color: theme.palette.mode === "light" ? "rgb(10, 10, 10, 0.3)": "rgb(200, 200, 200, 0.4)"
                }}>
                    Nothing found
                </Box>
            </Box>
        );
    } else {
        return (
            <Box  sx={{
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                overflowY: "scroll",
                boxSizing: "border-box",
                backgroundColor: theme.palette.mode === 'dark' ? 'rgb(24,30,35)' : '#FAFAFA',
                borderRadius: "12px"
            }}
            >
                <NegativeBar title={""} columnName1={"col1"} columnName2={"col2"} data={chartData}/>
            </Box>
        );
    }
}

function joinBoth(obj1, obj2) {
    var obj1List = [];
    obj1.data.forEach(item => {
        obj1List.push(item[0]);
    });

    var obj2List = [];
    obj2.data.forEach(item => {
        obj2List.push(item[0]);
    });

    obj1List.forEach(item => {
        if(!obj2List.includes(item)) {
            obj2.data.push([item, 0]);
        }
    });

    obj2List.forEach(item => {
        if(!obj1List.includes(item)) {
            obj1.data.push([item, 0]);
        }
    });

    obj1.data.sort(dateComparator);
    obj2.data.sort(dateComparator);

    return [obj1, obj2];
}

function dateComparator(a, b) {
    if(a[0] < b[0]) {
        return -1;
    } else if(a[0] > b[0]) {
        return 1;
    } else {
        return 0;
    }
}