//ignorei18n_start
import React, { useEffect, useMemo, useRef, useState } from "react";

import "../History.css";
import { findRowSpan } from "../History";

export default function YearViewBottom({ scrollAmount, setScrollAmount, data }) {

    const container = useRef();
    const [value, setValue] = useState(0);
    const [hovered, setHovered] = useState(false);

    const [years, spans] = useMemo(() => {
        const ys = data.milestones.map(m => m.year);
        const spans = findRowSpan(ys);
        return [ys, spans];
    }, [data]);

    function handleChange(v) {
        setValue(v);
        setScrollAmount(v / 20);
    }

    useEffect(() => {
        if (!hovered) {
            setValue(scrollAmount * 20);
        }
    }, [scrollAmount]);

    return (
        <div className={"zvp_year_view_bottom"}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            ref={container}
        >
            <div style={{ gridTemplateColumns: `repeat(${data.milestones.length + 2}, 1fr)` }}>
                <div style={{ gridColumn: "auto / span 1" }} className="pads"></div>
                {
                    years.map((y, i) => {
                        return (
                            spans[i] !== 0 ?
                                <div style={{ gridColumn: "auto / span " + spans[i] }} key={i}
                                >
                                    <div>
                                        {y}
                                    </div>
                                </div>
                                :
                                null
                        );
                    })
                }
                <div style={{ gridColumn: "auto / span 1" }} className="pads"></div>
                <input type="range" min="0" max="2000" value={value} onChange={v => handleChange(v.target.value)} className="zvp_year_view_slider" />
            </div>
        </div>
    );

}
//ignorei18n_end