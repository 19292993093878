import { isDateInRange, isDateString } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    const chartData = [];
    const tasklistCountMap = {};

    catalystDB.TaskDetails.map(item => {
        const dateString = item.CREATEDTIME;

        if(isDateString(dateString) && isDateInRange(dateString, startTime)) {
            const completionDateString = item.TasklistCompletionDate;

            if([undefined, null, "", " "].includes(completionDateString)) {
                return;
            }

            if(completionDateString in tasklistCountMap) {
                tasklistCountMap[completionDateString] += 1;
            } else {
                tasklistCountMap[completionDateString] = 1;
            }
        }
    }); 

    
    var keys = Object.keys(tasklistCountMap);
    keys.sort((a, b) => {
        return new Date(a).getTime() < new Date(b).getTime() ? -1 : 1;
    })

    for(const key of keys) {
        chartData.push([
            key,
            tasklistCountMap[key]
        ]);
    }

    return chartData;
}