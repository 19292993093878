import React, { useEffect, useState } from "react";
import { Box, useTheme,Tooltip, Hidden } from "@mui/material";

import cliq_img from "./link_images/zoho_cliq.png";
import vani_img from "./link_images/zoho_vani.png";
import workDrive_img from "./link_images/workDrive.png";
import default_img from "./link_images/default.png";

export default function LinkCards({ ChatUrls , ChatDescription }) {
    const linksArray = ChatUrls.split('####');
    // console.log(linksArray)
    const descriptionArray = ChatDescription.split('####')
    // console.log(descriptionArray)

  const theme = useTheme();
  const [imageSrc, setImageSrc] = useState([]);

  useEffect(() => {

    const imageSources = linksArray.map(link => {
      if (link.includes("cliq")) {
        return cliq_img;
      } else if (link.includes("vanihq")) {
        return vani_img;
      } else if (link.includes("workdrive")) {
        return workDrive_img;
      } else {
        return default_img;
      }
    });

    setImageSrc(imageSources);
  }, [linksArray]);

  return (
    <Box
      sx={{
        // marginTop: "10px",
        minHeight: "80px",
        height: "80px",
        maxWidth: "1515px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "13px",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
          display: "none"
        }
      }}
    >
      {linksArray.map((link, index) => (
         
         <a
         key={index}
         href={link}
         target="_blank"  // Open link in a new tab
         rel="noopener noreferrer"  // Security best practice
         style={{ textDecoration: "none", color: "inherit" }}  // Remove default link styles
         >
          <Tooltip title={descriptionArray[index]}>
        <Box
          key={index}
          sx={{
            backgroundColor:
              theme.palette.mode === "light" ? "#f0f7fa" : "#425c63",
            height: "100%",
            minWidth: "200px",
            borderRadius: "10px",
            // padding: "10px",
            boxSizing: "border-box",
            fontSize: "0.7rem",
            display: "flex",
            flexDirection: "column",
            // gap: "10px",
            cursor: "pointer"
          }}
        >
          <Box
            sx={{
              flexGrow: 9,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: '3px'
            }}
          >
            <div>
              <img src={imageSrc[index]} style={{ paddingLeft: '10px'}} height={30} alt={link}/>
            </div>

            <div style={{fontSize: '15px', paddingLeft: '20px',  textOverflow: "ellipsis"}}>
              {descriptionArray[index].length > 12 ? `${descriptionArray[index].substring(0, 12)}...` : descriptionArray[index]}
              {/* {descriptionArray[index]} */}
            </div>

          </Box>

          
          {/* <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "row",
              // alignItems: "start",
              // justifyContent: "start"
            }}
          >
             {descriptionArray[index].length > 12 ? `${descriptionArray[index].substring(0, 12)}...` : descriptionArray[index]}
          </Box> */}
          
        </Box>
        </Tooltip> 
        </a>

      ))}
    </Box>
  );
}
