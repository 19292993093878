import React from "react";

import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";

export function Count({ data, unit, title, heading, colors }) {

    const theme = useTheme();

    return (
        <Box sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
            // padding: "12px",
            gap: "10px"
        }}>
            {
                title !== "" ?
                    <Box sx={{
                        height: "7%",
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        textTransform: "uppercase",
                        opacity: 0.7,
                        userSelect: "none"
                    }}>
                        {"༺ " + title + " ༻"}
                    </Box>
                    :
                    null
            }
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: colors[1],
                height: title === "" ? "100%" : "93%",
                width: "100%",
                // borderRadius: "10px",
                border: "1px solid " + colors[1],
                // boxShadow: "1px 1px 5px 1px " + COLORS[3],
                fontSize: "6rem",
                fontWeight: "bold"
            }}>
                <InnerBox cont1={data.value} cont2={unit} cont3={"༺ " + heading + " ༻"} color={colors[0]} />
            </Box>
        </Box>
    );
}

function InnerBox({ cont1, cont2, cont3, color }) {
    return (
        <>
            <Box sx={{
                fontSize: "1rem",
                lineHeight: 1,
                paddingBottom: "15px",
                color: color
            }}>
                {cont3}
            </Box>
            <Box sx={{
                lineHeight: 0.95,
                color: color
            }}>
                {cont1}
            </Box>
            <Box sx={{
                fontSize: "0.8rem",
                lineHeight: 1,
                color: color
            }}>
                {cont2}
            </Box>
        </>
    );
}