/* eslint-disable react/prop-types */ 
/* esline-disable react/jsx-key */
import React from "react";
import { useEffect, useRef, useState, useContext } from "react";
import { UserContext } from "../../../../../contexts/UserContext";
import { useTheme } from '@mui/material/styles';
import { Box, ButtonGroup, Skeleton, Tab } from "@mui/material";
import { ReportContext } from "../newReportGeneral/NewReport";
import { getMilestoneRows, getQARows } from "../reportSection1/ReportSection1DataFetcher"; 

import AirIcon from '@mui/icons-material/Air';
import { PieChart } from "../newReportGeneral/NewReportUtilComponents";

export function ReportSection10() {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            width: "100%",
            height: "98%",
            gap: "10px"
        }}>
             <Box sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.1rem",
                fontWeight: "bold"
            }}>
                QA Testing
            </Box>
            <ReportBody/>
        </Box>
       
    );
}   


function ReportBody({setExpanded}) {

    const {serverConfiguration} = useContext(UserContext);
    const {
        subjectType, 
        setSubjectType, 
        subject, 
        setSubject, 
        timeFrame, 
        setTimeFrame, 
        time, 
        setTime,
        timeRange,
        setTimeRange
    } = useContext(ReportContext);
    const theme = useTheme();
    const {teamDetails, loginUserDept} = useContext(UserContext);
    
    const [loaded, setLoaded] = useState(undefined);
    const [chartData, setChartData] = useState(undefined);

    useEffect(() => {
        setLoaded(undefined);
        const controller = new AbortController();
        
        const listOfServers = [];

        if(subjectType === "Server") {
            listOfServers.push(subject);
        } else {
            if(![undefined, null, "", " "].includes(teamDetails.current[subject])) {
                teamDetails.current[subject].Servers.split(",").forEach(item => {
                    listOfServers.push(item);
                })
            }
        }

        const listOfClusters = [];
        listOfServers.forEach(item => {
            if(serverConfiguration.current[item] !== undefined && serverConfiguration.current[item].Clusters !== undefined) {
                try {
                    var obj = JSON.parse(serverConfiguration.current[item].Clusters);
                    obj.forEach(i => {
                        listOfClusters.push(i);
                    })
                } catch(e) {
                    // console.error(e);
                }
            }
        });


        getQARows(timeRange, listOfClusters, teamDetails, loginUserDept).then(response => {
            var pending_count = 0;
            var no_test_count = 0;
            var success_count = 0;
            var failure_count = 0;

            response.forEach(item => {
                if(item.testStatus === "-") {
                    no_test_count += 1;
                } else if(item.testStatus === "Pending") {
                    pending_count += 1;
                } else if(item.testStatus === "Completed") {
                    try {
                        const obj = JSON.parse(item.testResult);
                        if(obj.result) {
                            success_count += 1;
                        } else {
                            failure_count += 1;
                        }
                    } catch(e) {
                    }
                }
            });

            if(no_test_count == 0 && pending_count == 0 && failure_count == 0 && success_count == 0) {
                setChartData(undefined);
                setLoaded(false);
            } else {
                setChartData([
                    [
                        "No Tests",
                        no_test_count
                    ],
                    [
                        "Pending",
                        pending_count
                    ],
                    [
                        "Success",
                        success_count
                    ],
                    [
                        "Failure",
                        failure_count
                    ]
                ]);
    
                setLoaded(true);
            }

        }).catch(error => {
            setLoaded(false);
        });

        return () => {
            controller.abort(); 
        };

    }, [timeRange, subjectType, subject]);


    if(loaded === undefined) {
        return (
            <Skeleton variant={"rounded"} height={"98%"} width={"100%"} animation="wave" />
        );
    } else if(loaded === false) {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%"
            }}>
                <Box>
                    <AirIcon sx={{
                        fontSize: "6rem",
                        color: theme.palette.mode === "light" ? "rgb(20, 20, 20, 0.06)": "rgb(200, 200, 200, 0.06)"
                    }} />
                </Box>
                <Box sx={{
                    fontSize: "0.7rem",
                    color: theme.palette.mode === "light" ? "rgb(10, 10, 10, 0.3)": "rgb(200, 200, 200, 0.4)"
                }}>
                    Nothing found
                </Box>
            </Box>
        );
    } else {
        return (
            <Box  sx={{
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                overflowY: "scroll",
                boxSizing: "border-box",
                backgroundColor: theme.palette.mode === 'dark' ? 'rgb(24,30,35)' : '#FAFAFA',
                borderRadius: "12px"
            }}
            >
                <PieChart title={""} columnName1={"col1"} columnName2={"col2"} data={chartData} colors={["#c055f2", "#f2d555", "#42f575", "#f54e42"]}/>
            </Box>
        );
    }
}