import { executeQuery, queryDB } from "../../../../../util/QueryDB";
import { findPeopleUnderServer } from "../../../../../util/Loading";
import { findMentees } from "../../../../../util/Loading";
import { report_3_data_fetcher_get_query_1, report_3_data_fetcher_get_query_10, report_3_data_fetcher_get_query_2, report_3_data_fetcher_get_query_3, report_3_data_fetcher_get_query_4, report_3_data_fetcher_get_query_5, report_3_data_fetcher_get_query_6, report_3_data_fetcher_get_query_7, report_3_data_fetcher_get_query_8, report_3_data_fetcher_get_query_9 } from "../../../../../catalystDB/queryGenerator";

var queryCache = {};

export async function getMilestoneRows(timeRange, subjectType, subject, teamDetails, loginUserDept) {

    const query = getQuery1(timeRange, subjectType, subject, teamDetails, loginUserDept);

    if(query in queryCache) {
        return queryCache[query];
    }

    return new Promise((resolve, reject) => {
        queryDB(query).then((response) => {
            var milestoneRows = [];

            response.forEach(item => {
                var milestoneRow = {};
                milestoneRow.milestone_name = item.TaskDetails.MilestoneName;
                milestoneRow.milestone_dri = item.TaskDetails.Email;
                milestoneRow.milestone_id = item.TaskDetails.MilestoneId;
                milestoneRow.start_date = item.TaskDetails.MilestoneStartDate_;
                milestoneRow.due_date = item.TaskDetails.MilestoneDueDate_;
                milestoneRow.completed_date = item.TaskDetails.MilestoneCompletionDate;
                milestoneRow.milestone_status = item.TaskDetails.MilestoneStatus;
                milestoneRow.milestone_type = item.TaskDetails.MilestoneType;
                milestoneRow.release_type = item.TaskDetails.Release_Type;

                milestoneRows.push(milestoneRow);
            });

            queryCache[query] = milestoneRows;

            return resolve(milestoneRows);

        }).catch((error) => {
            return reject(error);
        });
    });

}

export async function getTasklistRows(timeRange, subjectType, subject, teamDetails, loginUserDept) {

    const query = getQuery3(timeRange, subjectType, subject, teamDetails, loginUserDept);

    if(query in queryCache) {
        return queryCache[query];
    }

    return new Promise((resolve, reject) => {
        queryDB(query).then((response) => {
            var tasklistRows = [];

            response.forEach(item => {

                tasklistRows.push(item.TaskDetails);
            });

            queryCache[query] = tasklistRows;

            return resolve(tasklistRows);

        }).catch((error) => {
            return reject(error);
        });
    });

}

export async function getTasksUnderMilestone(milestoneIdArray, startDate, teamDetails, loginUserDept) {

    const query = getQuery2(milestoneIdArray, teamDetails, loginUserDept);

    if (query in queryCache) {
        return queryCache[query];
    }

    return new Promise((resolve, reject) => {
        queryDB(query).then((response) => {

            var old_completed = [];
            var new_completed = [];
            var incomplete = [];

            response.forEach(item => {
                var taskRow = {};
                taskRow.component = item.TaskBreaker.Component;
                taskRow.task_name = item.TaskBreaker.TaskName;
                taskRow.tasklist_name = item.TaskBreaker.TaskListName;
                taskRow.task_dri = item.TaskBreaker.DRI;
                taskRow.task_priority = item.TaskBreaker.TaskPriority;
                taskRow.task_status = item.TaskBreaker.TaskStatus;
                taskRow.milestone_id = item.TaskBreaker.MilestoneID;
                taskRow.task_id = item.TaskBreaker.TaskID;
                taskRow.start_date = item.TaskBreaker.TaskStartDate_;
                taskRow.due_date = item.TaskBreaker.TaskDueDate_;
                taskRow.completed_date = item.TaskBreaker.TaskCompletionDate;

                if((!["Closed", "Updated to IDC"].includes(taskRow.task_status) || [undefined, null, "", " "].includes(taskRow.completed_date)) && !["Cancelled"].includes(taskRow.task_status)) {
                    incomplete.push(taskRow);  
                } else {
                    try {
                        var taskdate = new Date(taskRow.completed_date);
                        if(isValidDate(taskdate)) {
                            if(taskdate < startDate) {
                                old_completed.push(taskRow);
                            } else {
                                new_completed.push(taskRow);
                            }
                        } else {
                            incomplete.push(taskRow);
                        }
                    } catch(e) {
                        incomplete.push(taskRow); 
                    }
                }
            });

            var finalObject = {};
            milestoneIdArray.forEach((milestoneID => {
                var obj = {
                    "old_completed": old_completed.filter(taskItem => {
                        if(taskItem.milestone_id === milestoneID) {
                            return true;
                        } else {
                            return false;
                        }
                    }),
                    "new_completed": new_completed.filter(taskItem => {
                        if(taskItem.milestone_id === milestoneID) {
                            return true;
                        } else {
                            return false;
                        }
                    }),
                    "incomplete": incomplete.filter(taskItem => {
                        if(taskItem.milestone_id === milestoneID) {
                            return true;
                        } else {
                            return false;
                        }
                    })
                };

                finalObject[milestoneID] = obj;

            }));

            queryCache[query] = finalObject;

            return resolve(finalObject);

        }).catch((error) => {
            return reject(error);
        });
    });

}

export async function getTasksForDRI(timeRange, subjectType, subject, teamDetails, loginUserDept) {

    const query = getQuery9(timeRange, subjectType, subject, teamDetails, loginUserDept);

    if (query in queryCache) {
        return queryCache[query];
    }

    return new Promise((resolve, reject) => {
        queryDB(query).then((response) => {

            var data = [];

            response.forEach(item => {
                data.push(item.TaskBreaker);
            })

            queryCache[query] = data;

            return resolve(data);

        }).catch((error) => {
            return reject(error);
        });
    });

}

export async function getRoadmapAsLikeTasklist(tasklistNameArray, tasklistComponentArray, teamDetails, loginUserDept) {
    var query = getQuery4(tasklistNameArray, tasklistComponentArray, teamDetails, loginUserDept);

    if(query in queryCache) {
        return queryCache[query];
    }

    var pairs = tasklistNameArray.map((item, index) => {
        return item+ "___" + tasklistComponentArray[index];
    })

    return new Promise((resolve, reject) => {
        queryDB(query).then((response) => {
            var roadmapRows = [];

            response.forEach(item => {
                if(pairs.includes(item.TaskBreaker.TaskName + "___" +  item.TaskBreaker.Component)) {
                    roadmapRows.push(item.TaskBreaker);
                } 
            });

            queryCache[query] = roadmapRows;

            return resolve(roadmapRows);

        }).catch((error) => {
            return reject(error);
        });
    });
}

export async function getTasksForMilestoneID(milestoneID, teamDetails, loginUserDept) {
    const query = getQuery5(milestoneID, teamDetails, loginUserDept);

    if (query in queryCache) {
        return queryCache[query];
    }

    return new Promise((resolve, reject) => {
        queryDB(query).then((response) => {

            var data = [];
            response.forEach(item => {
                data.push(item.TaskBreaker);

            });

            queryCache[query] = data;

            return resolve(data);

        }).catch((error) => {
            return reject(error);
        });
    });
}

export async function getBuildRows(timeRange, subjectType, subject, teamDetails, loginUserDept) {
    const query = getQuery6(timeRange, subjectType, subject, teamDetails, loginUserDept);

    if(query in queryCache) {
        return queryCache[query];
    }

    return new Promise((resolve, reject) => {
        queryDB(query).then((response) => {
            var buildRows = [];

            response.forEach(item => {
               buildRows.push(item.BuildBasicForm);
            });

            queryCache[query] = buildRows;

            return resolve(buildRows);

        }).catch((error) => {
            return reject(error);
        });
    });
}

export async function getTicketRows(timeRange, subjectType, subject, teamDetails, loginUserDept) {
    const query = getQuery7(timeRange, subjectType, subject, teamDetails, loginUserDept);

    if(query in queryCache) {
        return queryCache[query];
    }

    return new Promise((resolve, reject) => {
        queryDB(query).then((response) => {
            var ticketRows = [];

            response.forEach(item => {
               ticketRows.push(item.Tickets);
            });

            queryCache[query] = ticketRows;

            return resolve(ticketRows);

        }).catch((error) => {
            return reject(error);
        });
    });
}

export async function getBuildDetails(timeRange, subjectType, subject, teamDetails, loginUserDept) {
    const query = getQuery8(timeRange, subjectType, subject, loginUserDept);

    if(query in queryCache) {
        return queryCache[query];
    }

    return new Promise((resolve, reject) => {
        queryDB(query).then((response) => {
            var buildRows = [];

            response.forEach(item => {
               buildRows.push(item.BuildDetails);
            });

            queryCache[query] = buildRows;

            return resolve(buildRows);

        }).catch((error) => {
            return reject(error);
        });
    });
}

export async function getQARows(timeRange, listOfClusters, teamDetails, loginUserDept) {
    const query = getQuery10(timeRange, listOfClusters, teamDetails, loginUserDept);

    if(query in queryCache) {
        return queryCache[query];
    }

    return new Promise((resolve, reject) => {
        queryDB(query).then((response) => {
            var qaRows = [];

            response.forEach(item => {
               qaRows.push(item.QATest);
            });

            queryCache[query] = qaRows;

            return resolve(qaRows);

        }).catch((error) => {
            return reject(error);
        });
    });
}

function getQuery1(timeRange, subjectType, subject, teamDetails, loginUserDept) {
    return report_3_data_fetcher_get_query_1(timeRange, subjectType, subject, teamDetails, loginUserDept, findMentees);
}

function getQuery2(milestoneIdArray, teamDetails, loginUserDept) {
    return report_3_data_fetcher_get_query_2(milestoneIdArray, teamDetails, loginUserDept);
}

function getQuery3(timeRange, subjectType, subject, teamDetails, loginUserDept) {
    return report_3_data_fetcher_get_query_3(timeRange, subjectType, subject, teamDetails, loginUserDept, findMentees);
}

function getQuery4(tasklistNameArray, tasklistComponentArray, teamDetails, loginUserDept) {
    return report_3_data_fetcher_get_query_4(tasklistNameArray, tasklistComponentArray, teamDetails, loginUserDept);
}

function getQuery5(milestoneID, teamDetails, loginUserDept) {
    return report_3_data_fetcher_get_query_5(milestoneID, teamDetails, loginUserDept);
}

function getQuery6(timeRange, subjectType, subject, teamDetails, loginUserDept) {
   return report_3_data_fetcher_get_query_6(timeRange, subjectType, subject, teamDetails, loginUserDept, findMentees);
}

function getQuery7(timeRange, subjectType, subject, teamDetails, loginUserDept) {
    return report_3_data_fetcher_get_query_7(timeRange, subjectType, subject, teamDetails, loginUserDept, findMentees, findPeopleUnderServer);
}

function getQuery8(timeRange, subjectType, subject, teamDetails, loginUserDept) {
   return report_3_data_fetcher_get_query_8(timeRange, subjectType, subject, teamDetails, loginUserDept, findMentees);
}

function getQuery9(timeRange, subjectType, subject, teamDetails, loginUserDept) {
   return report_3_data_fetcher_get_query_9(timeRange, subjectType, subject, teamDetails, loginUserDept, findMentees);
}

function getQuery10(timeRange, listOfClusters, teamDetails, loginUserDept) {
    return report_3_data_fetcher_get_query_10(timeRange, listOfClusters, teamDetails, loginUserDept);
}

function isValidDate(date) {
    return date instanceof Date && !isNaN(date);
}

// export function getBRows() {
//     const backTick = "`";
//     const query = `        
//     SELECT *, BuildRecordsFor_Local_US.${backTick}US3-mainRevertReason${backTick}, BuildRecordsFor_EU_IN_AU_JP.*, BuildRecordsFor_CN_UK_CA_AR.*, BuildRecordsFor_UBI.* FROM BuildDetails
//     INNER JOIN BuildRecordsFor_Local_US ON BuildDetails.ROWID = BuildRecordsFor_Local_US.parentRowID
//     INNER JOIN BuildRecordsFor_EU_IN_AU_JP ON BuildDetails.ROWID = BuildRecordsFor_EU_IN_AU_JP.parentRowID
//     INNER JOIN BuildRecordsFor_CN_UK_CA_AR ON BuildDetails.ROWID = BuildRecordsFor_CN_UK_CA_AR.parentRowID
//     INNER JOIN BuildRecordsFor_UBI ON BuildDetails.ROWID = BuildRecordsFor_UBI.parentRowID
//     `;

//     executeQuery(query+"").then((response) => {
//         console.log(response);
//     }).catch(error => {
//         console.log(error);
//     })
// }

