import React, { useContext } from 'react';

import { GridLayout } from '../dashboardMain/GridLayout';

import { DashboardContext } from '../dashboardMain/stateStorage/DashboardStateStorage';

import { GridItem_1 } from './gridItem_1/GridItem_1';
import { GridItem_2 } from './gridItem_2/GridItem_2';
import { GridItem_3 } from './gridItem_3/GridItem_3';
import { GridItem_4 } from './gridItem_4/GridItem_4';
import { GridItem_5 } from './gridItem_5/GridItem_5';
import { GridItem_6 } from './gridItem_6/GridItem_6';
import { GridItem_7 } from './gridItem_7/GridItem_7';


export function Dashboard_3() {
    const layout = [
        {
            "title": "",
            "name": "GridItem_1_2_3",
            "value": null,
            "x": 1.5,
            "y": 12,
            "children": [
                {
                    "title": "Total Build Count",
                    "name": "GridItem_1",
                    "value": <GridItem_1 />,
                    "x": 12,
                    "y": 4,
                    "noExpansion": true
                },
                {
                    "title": "Feature vs Fix",
                    "name": "GridItem_2",
                    "value": <GridItem_2 />,
                    "x": 12,
                    "y": 4
                },
                {
                    "title": "Build Test Status",
                    "name": "GridItem_3",
                    "value": <GridItem_3 />,
                    "x": 12,
                    "y": 4
                }
            ]
        },
        {
            "title": "",
            "name": "GridItem_4_5_6_7",
            "value": null,
            "x": 10.5,
            "y": 12,
            "children": [
                {
                    "title": "",
                    "name": "GridItem_4_5",
                    "value": null,
                    "x": 12,
                    "y": 7,
                    "children": [
                        {
                            "title": "Server Build Member Contribution",
                            "name": "GridItem_4",
                            "value": <GridItem_4 />,
                            "x": 6,
                            "y": 12
                        },
                        {
                            "title": "Server Build Count",
                            "name": "GridItem_5",
                            "value": <GridItem_5 />,
                            "x": 6,
                            "y": 12
                        }
                    ]
                },
                {
                    "title": "",
                    "name": "GridItem_6_7",
                    "value": null,
                    "x": 12,
                    "y": 5,
                    "children": [
                        {
                            "title": "Build Count",
                            "name": "GridItem_6",
                            "value": <GridItem_6 />,
                            "x": 10,
                            "y": 12
                        },
                        {
                            "title": "Average Build Update Per Milestone",
                            "name": "GridItem_7",
                            "value": <GridItem_7 />,
                            "x": 2,
                            "y": 12,
                            "noExpansion": true
                        }
                    ]
                }
            ]
        }
    ];

    const {getAllDashboardNames} = useContext(DashboardContext);
    const DASHBOARD_NAMES = getAllDashboardNames();

    return (
        <GridLayout layout={layout} dashboardName={DASHBOARD_NAMES[2]} key={"DASHBOARD_3"}/>
    );
}