/* eslint-disable react/prop-types */

import React, {useEffect} from "react";
import { Box } from "@mui/material";

import { useTheme } from '@mui/material/styles';
import { EmployeeCard, GetNameAndEmpID } from "../../../../../util/Loading";

export function MilestoneSummary({data}) {

    const theme = useTheme();

    return (
        <Box sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            justifyContent: "space-between"
        }}>
            <Box>
                <Box sx={{
                    color: theme.palette.background.textSecondary,
                    marginBottom: "5px",
                    fontSize: "0.7rem"
                }}>
                    Duration
                </Box>
                <Box sx={{
                    color: theme.palette.background.text,
                    fontSize: "1.2rem"
                }}>
                    {
                        data.milestone_summary.duration.count === 0 ?

                        "Started and Completed on the Same Day"

                        :
                    
                        `${data.milestone_summary.duration.count} ${data.milestone_summary.duration.unit}`
                        + (
                            data.milestone_summary.duration.result ?
                            (
                                data.milestone_summary.duration.result === "sharp" ?
                                    ` (completed exactly on due date) `
                                    :
                                    ` (${data.milestone_summary.duration.result} by ${data.milestone_summary.duration.result_days} ${data.milestone_summary.duration.unit}) `
                            )
                            :
                            ""
                        )
                    
                    }
                    {/* 14 Days (late by 3 days) */}
                </Box>
            </Box>
            <Box>
                <Box sx={{
                    color: theme.palette.background.textSecondary,
                    marginBottom: "5px",
                    fontSize: "0.7rem"
                }}>
                    Total Tasks
                </Box>
                <Box sx={{
                    color: theme.palette.background.text,
                    fontSize: "1.2rem"
                }}>
                    {data.milestone_summary.total_tasks}
                    {/* 32 */}
                </Box>
            </Box>
            <Box>
                <Box sx={{
                    color: theme.palette.background.textSecondary,
                    marginBottom: "5px",
                    fontSize: "0.7rem"
                }}>
                    Total Tasklists
                </Box>
                <Box sx={{
                    color: theme.palette.background.text,
                    fontSize: "1.2rem"
                }}>
                    {data.milestone_summary.total_tasklists}
                    {/* 14 */}
                </Box>
            </Box>
            <Box>
                <Box sx={{
                    color: theme.palette.background.textSecondary,
                    fontSize: "0.7rem"
                }}>
                    Collaborators
                </Box>
                <Box sx={{
                    color: theme.palette.background.text,
                    fontSize: "1.2rem"
                }}>
                    <GetNameAndEmpID emails={data.milestone_summary.collaborators.join(",")} h={28} w={"400px"} full={true}/>
                </Box>
            </Box>
        </Box>
    );
}

