import { blue, green, red, blueGrey, yellow } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      topBar: "#272A32",
      pageContent: "ghostwhite",
      tableRow: "white",
      tableRowHover: "#ebeef0",
      infoDivInner: "#CFD8DC",
      infoDivOuter: "#F8F8FF",
      outerSurface: "rgba(242, 244, 245, 1)",
      innerSurface: "rgba(228, 237, 236, 1)",
      border: "rgb(174, 187, 191)",
      text: "black",
      textSecondary: "darkslategrey"
    },
    primary: {
      main: blueGrey[500]
    },
    zohoRed: {
      main: red[600],
      light: red[400],
      dark: red[900]
    },
    zohoGreen: {
      main: green[700],
      light: green[500],
      dark: green[900]
    },
    zohoBlue: {
      main: blue[700],
      light: blue[500],
      dark: blue[900]
    },
    zohoYellow: {
      main: yellow[700],
      light: yellow[600],
      dark: yellow[900]
    }
  },
  typography: {
    fontFamily: "'Poppins', sans-serif"
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#272A32"
        }
      }
    },
    MuiButton: { 
      styleOverrides: { 
        root: { minWidth: 0 } 
      } 
    }
  }
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      topBar: "#272A32",
      pageContent: "#1a1e24",
      tableRow: "#353b45",
      tableRowHover: "#303742",
      infoDivOuter: "#1A1E24",
      // infoDivInner: "#38474F",
      infoDivInner: "rgb(30,45,50)",
      outerSurface: "rgba(31, 36, 43, 1)",
      innerSurface: "rgba(189, 189, 189, 1)",
      border: "rgb(51, 59, 69)",
      text: "white",
      textSecondary: "lightgrey"
    },
    primary: {
      main: blueGrey[500]
    },
    secondary: {
      main: green[500]
    },
    zohoRed: {
      main: red[600],
      light: red[400],
      dark: red[900]
    },
    zohoGreen: {
      main: green[700],
      light: green[500],
      dark: green[900]
    },
    zohoBlue: {
      main: blue[700],
      light: blue[500],
      dark: blue[900]
    },
    zohoYellow: {
      main: yellow[700],
      light: yellow[600],
      dark: yellow[900]
    }
  },
  typography: {
    fontFamily: "'Poppins', sans-serif"
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#272A32"
        }
      }
    },
    MuiButton: { 
      styleOverrides: { 
        root: { minWidth: 0 } 
      } 
    }
  }
});