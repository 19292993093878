import React, {
    useCallback,
    useEffect,
    useRef,
    useState
} from "react";

import {
    Box,
    Button,
    Chip,
    Collapse,
    Popover,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from "@mui/material";

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';

import { styled, useTheme } from '@mui/material/styles';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import DoneIcon from '@mui/icons-material/Done';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { TimelineCard } from "./TimelineCard";
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import {Tooltip} from "@mui/material";
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Filter } from "./Filter";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import {timelineOppositeContentClasses} from '@mui/lab/TimelineOppositeContent';
import dayjs from 'dayjs';


export function Page() {

    var data = [];
    const activeStep = 16;

    for(var i=0; i< 30; i++) {
        data.push({
            start_date: i%3 == 0 ? new Date() : undefined,
            due_date: i%3 === 0 ? new Date() : (i%3 === 1 ? new Date("2023-07-30") : undefined ),
            completion_date: i%3 == 1 ? new Date("2002-05-19") : (i%3 == 2 ? new Date("2023-08-20") : undefined),
            module_name: "Module Test",
            servers: [
                {
                    name: "AMS",
                    completion: 8,
                    total: 10,
                    milestone_id: "2029477000000290003"
                },
                {
                    name: "Media",
                    completion: 16,
                    total: 20,
                    milestone_id: "2029477000000290003"
                },
                {
                    name: "AWS",
                    completion: 6,
                    total: 8,
                    milestone_id: "2029477000000222013"
                },
                {
                    name: "RTCP Cloud",
                    completion: 5,
                    total: 7,
                    milestone_id: "2029477000000222013"
                },
                {
                    name: "PubSub",
                    completion: 1,
                    total: 4,
                    milestone_id: "2029477000000222013"
                },
                {
                    name: "NioClient",
                    completion: 3,
                    total: 5,
                    milestone_id: "2029477000000222013"
                }
            ],
            dris: i%2 === 0 ? [
                "jayasuriya.bm@zohocorp.com",
                "karthick.masilamani@zohocorp.com"
            ] : ["manoj.sakthivel@zohocorp.com"],
            due_date_extension: [new Date(), new Date()],
            feature_name: "Test Feature Name comes here. asdfasdfasdfasdf dsfasdf asdf",
            release_type: i%2 === 0 ? "Q1" : "October",
            requested_by: i%2 === 0 ? "cliq" : "other"
        });
    }
    return (
        <Box className='page-content-without-padding' >
            <VerticalTimeline steps={data} activeStep={activeStep} />
        </Box>
    );
}

export function VerticalTimeline({ steps, activeStep}) {
    // console.log(steps);
    const activeComponent = useRef(null);
    const [shrink, setShrink] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [filter, setFilter] = useState({"feature_type": "both"});
    const [sort, setSort] = useState("due_date");

    const stepsNew = applySort(sort, applyFilter(searchText, steps, filter));

    useEffect(() => {
        scrollToActive();
    }, []);

    function scrollToActive() {
        if(activeComponent.current !== null) {
            activeComponent.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            height: "100%"
        }}>
            <Switcher shrink={shrink} setShrink={setShrink} scrollToActive={scrollToActive} searchText={searchText} setSearchText={setSearchText}
                filter={filter} setFilter={setFilter} />
            <TimelineCore steps={stepsNew} activeStep={activeStep} activeComponent={activeComponent} shrink={shrink} />
        </Box>
    );
}

function Switcher({shrink, setShrink, scrollToActive, searchText, setSearchText, filter, setFilter}) {

    const theme = useTheme();
    const [search, setSearch] = useState(false);
    const [anchor, setAnchor] = useState(null);

    function handleFilterClick(event) {
        if(anchor === null) {
            setAnchor(event.currentTarget);
        } else {
            setAnchor(null);
        }
    }

    function handleSearchClick() {
        if(search) {
            setSearchText("");
        }
        setSearch(cur => !cur);
    }

    function handleTextChange(event) {
        setSearchText(event.target.value);
    }

    function handleClick() {
        setShrink(cur => !cur);
    }
    // console.log(filter)

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            boxShadow: "0 0 50px " + (theme.palette.mode === "light" ? "#e6cfda" : "#0c254a"),
            clipPath: "inset(0px 0px -50px 0px)",
            paddingBottom: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
            position: "relative"
        }}>
            <Popover    
                open={anchor !== null}
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={() => setAnchor(null)}
                sx={{
                    userSelect: "none",
                    marginTop: "10px"
                }}
                PaperProps={{
                    style: { borderRadius: 10, boxShadow: "3px 3px 30px" + (theme.palette.mode === "light" ? "#e6cfda" : "#0c254a"), background: "transparent" },
                    sx:{
                        backgroundColor: theme.palette.mode === "dark" ? "#1A1E23aa" : "",
                        backdropFilter: theme.palette.mode === "dark" ? "blur(60px)" : "blur(35px)",
                        padding: 2,
                        border: "1px solid " + (theme.palette.mode === "light" ? "#e6cfda" : "#0c254a")
                    }
                }}
            >
                <Filter filter={filter} setFilter={setFilter} />
            </Popover>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                minWidth: "500px"
            }}>
                {/* <Button onClick={scrollToActive} sx={{
                    borderRadius: "100px",
                    backgroundImage: theme.palette.mode === "light" ?
                        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
                        :
                        'linear-gradient( 95deg, #2894c9 0%, #286ec9 50%, #2846c9 100%)',
                    textTransform: "none",
                    color: "#eeeeee",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    fontSize: "0.9rem"
                }}>
                    <Tooltip title={"Show Current Releases"} >
                        <TodayRoundedIcon />
                    </Tooltip>
                </Button> */}
                <Button onClick={handleFilterClick} sx={{
                    borderRadius: "100px",
                    backgroundImage: theme.palette.mode === "light" ?
                        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
                        :
                        'linear-gradient( 95deg, #2894c9 0%, #286ec9 50%, #2846c9 100%)',
                    textTransform: "none",
                    color: "#eeeeee",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    fontSize: "0.9rem"
                }}>
                    <Tooltip title={"Filter"} >
                        <FilterAltIcon sx={{
                        }}/>
                    </Tooltip>
                </Button>
                
                <Button onClick={handleSearchClick} sx={{
                    borderRadius: "100px",
                    backgroundImage: theme.palette.mode === "light" ?
                        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
                        :
                        'linear-gradient( 95deg, #2894c9 0%, #286ec9 50%, #2846c9 100%)',
                    textTransform: "none",
                    color: "#eeeeee",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    fontSize: "0.9rem"
                }}>
                    <Tooltip title={"Search"} >
                        <SearchRoundedIcon />
                    </Tooltip>
                </Button>
                <Collapse in={search} timeout="auto" orientation="horizontal" unmountOnExit >
                    <TextField variant="outlined" sx={{
                        width: "300px",
                        height: "10px"
                    }} onChange={handleTextChange} autoFocus >
                            {searchText}
                    </TextField>
                </Collapse>
            </Box>
            <Box sx={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                transform: "translateX(-100%)",
                fontWeight: "bold"
            }} >
                <h2 style={{
                    cursor: "default", margin: "0px",
                    backgroundImage: theme.palette.mode === "light" ?
                    'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
                    :
                    'linear-gradient( 95deg, #2894c9 0%, #286ec9 50%, #2846c9 100%)',
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent"
                }}
                >
                    Release Timeline
                </h2>
            </Box>
            <Button onClick={handleClick} sx={{
                borderRadius: "100px",
                backgroundImage: theme.palette.mode === "light" ?
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
                :
                'linear-gradient( 95deg, #2894c9 0%, #286ec9 50%, #2846c9 100%)',
                textTransform: "none",
                color: "#eeeeee",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                fontSize: "0.9rem",
                fontWeight: "bold"
            }}>
                {/* <>
                    {
                        shrink ?
                            "Expand"
                            :
                            "Shrink"

                    }
                </> */}
                <>
                    {
                        shrink ? 
                            <UnfoldMoreIcon />
                            :
                            <UnfoldLessIcon />
                    }
                </>
            </Button>
        </Box>
    );
}


function TimelineCore({steps, activeStep, activeComponent, shrink}) {
    // console.log(steps)
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedStep, setSelectedStep] = useState(null);
    const [alternativePositioning, setAlternativePositioning] = useState(true);
    
    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    const handleWindowResize = useCallback(() => {
        if(window.innerWidth < 1200) {
            setAlternativePositioning(false);
        } else {
            setAlternativePositioning(true);
        }
    }, []);

    const handleSubmit = () => {
        const formattedDueDate = selectedDate ? dayjs(selectedDate).format('YYYY-MM-DD') : null;
        console.log('Selected Date:', formattedDueDate);
        console.log('Selected Step CreatorRowid:', selectedStep.CreatorRowid);

       // Need to work one more in server

        // try {
        //     // Construct the URL with the "description" parameter
        //     //const url = '/server/externalRoadMap?table=NewRoadMap&description=' + description + '&server=' + row.RoadmapExternal.Server;
        //     const url = '/server/externalRoadMap?table=ReleasePlannerExtendDate&NewDueDate=' + formattedDueDate + '&CreatorRowId=' + selectedStep.CreatorRowid;
        //     const headers = {
        //         'Content-Type': 'application/json',
        //     };

        //     fetch(url, {
        //         method: 'POST',
        //         headers: headers,
        //     })
        //         .then(response => {
        //             if (!response.ok) {
        //                 throw new Error('Request failed with status: ' + response.status);
        //             }

        //             // Check if the response has content before parsing it as JSON
        //             if (response.headers.get('content-length') === '0') {
        //                 // Handle empty response here (no JSON data)
        //                 throw new Error('Empty response');
        //             }

        //             return response.json();
        //         })
        //         .then(data => {
        //             const statusCode = data.StatusCode;
        //             console.log(statusCode)
        //             // Handle the JSON data as needed
        //         })
        //         .catch(error => {
        //             console.error(error);
        //             // Handle errors, including empty response and invalid JSON
        //         });
        // }
        // catch (error) {
        //     console.error(error);
        // }

      handleClose();
    };

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleStepClick = (step) => {
        setSelectedStep(step); // Set the selected step
    };

    const compareDates = (d1, d2,d3) => {

        let date1 = new Date(d1).getTime();
        let date2 = new Date(d2).getTime();
        let date3;
        if(d3 != undefined){
            date3 = new Date(d3).getTime();
        }
      
        if (date1 < date2) {
          return null;

        }
        else if (date1 < date2  && d3 === undefined) {
            return null;
  
          }
         else if (date1 > date2 && d3 === undefined) {
            return(
                <Chip label="Overdue" variant="outlined" size="small" style={{fontSize: "10px", backgroundColor: "crimson",
                background: "linear-gradient(90deg, brown, crimson)",
                fontWeight: "bold",
                color: "white"}}/>
            );  
        }
        else if (d3 != undefined && date3 > date2) {
            return(
                <Chip label="Completed Late" variant="outlined" size="small" style={{fontSize: "10px", backgroundColor: "crimson",
                background: "linear-gradient(90deg, brown, crimson)",
                fontWeight: "bold",
                color: "white"}}/>
            );  
        } else {
          return null;
        }
      };


      const compareDatesForExtendDueDate = (d1, d2,d3,step) => {
        let date1 = new Date(d1).getTime();
        let date2 = new Date(d2).getTime();
       
      
        if (date1 < date2) {
          return null;
        }
        else if ((date1 > date2) && d3 === undefined) {
            return (
            <Chip onClick={handleClickOpen} label="Extend Due Date" clickable style={{borderRadius: "10px", border: "1px solid crimson", color: "crimson"}}/>
            );
          }
          else {
        //   console.log(d3)
          return null;
        }
      };

    // steps.map((step, index) => {
    //     var  monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    //     "July", "Aug", "Sep", "Oct", "Nov", "Dec"
    //   ];
    //      var fromDate = new Date(step.due_date);
    //      const Currentdate = new Date();
    //      let day = Currentdate.getDate();
    //      let month = monthNames[Currentdate.getMonth()];
    //      let year = Currentdate.getFullYear();

    //      let CurrentdateFormat = `${day}-${month}-${year}`;
       
    //     var DuedateFormat =fromDate.getDate()+'-'+monthNames[fromDate.getMonth()]+'-'+fromDate.getFullYear();
    //     compareDates(CurrentdateFormat,DuedateFormat) 
    // })

    return (
        <Box sx={{
            overflowY: "scroll",
            flex: 1
        }}>
            <Timeline position={alternativePositioning ? "alternate" : "right"}
                sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: alternativePositioning ? 1 : 0.2,
                    },
                }}
            >
                {
                    steps.map((step, index) => {
                        var  monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                        "July", "Aug", "Sep", "Oct", "Nov", "Dec"
                      ];
                         var DueDate = new Date(step.due_date);
                         var CompletionDate ;
                         var CompletionDateFormat;
                         if(step.completion_date === undefined){
                            CompletionDate = undefined;
                         }
                         else{
                            CompletionDate = new Date(step.completion_date);
                            CompletionDateFormat = CompletionDate.getDate()+'-'+monthNames[CompletionDate.getMonth()]+'-'+CompletionDate.getFullYear();
                         }
                        

                         const Currentdate = new Date();
                         let day = Currentdate.getDate();
                         let month = monthNames[Currentdate.getMonth()];
                         let year = Currentdate.getFullYear();
                
                         let CurrentdateFormat = `${day}-${month}-${year}`;
                       
                        var DuedateFormat =DueDate.getDate()+'-'+monthNames[DueDate.getMonth()]+'-'+DueDate.getFullYear();
                        return (
                            <TimelineItem
                                ref={
                                    (index + 1 === activeStep) ?
                                        activeComponent
                                        :
                                        null
                                }
                                onClick={() => handleStepClick(step)} // Set the step when item is clicked
                            >
                                <TimelineOppositeContent
                                    sx={{ m: 'auto 0' }}
                                    align="right"
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    {
                                        step.due_date ?
                                        <Box style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-end",
                                            gap: "2rem"
                                        }}>
                                            {/* <Box>
                                                {
                                                    compareDatesForExtendDueDate(CurrentdateFormat,DuedateFormat,CompletionDateFormat,step)
                                                    // step.completion_date === undefined &&  step.due_date !== undefined ?
                                                    //     <Chip label="Extend Due Date" clickable style={{borderRadius: "10px", border: "1px solid crimson", color: "crimson"
                                                    //     }}/>
                                                    //     :
                                                    //     null
                                                }
                                            </Box> */}
                                            <Box style={{
                                                display: "flex",
                                                gap: "2px",
                                                flexDirection: "column"
                                            }}>
                                                <Box>
                                                    {
                                                          compareDates(CurrentdateFormat,DuedateFormat,CompletionDateFormat) 
                                                        // step.completion_date === undefined &&  step.due_date !== undefined ?
                                                        //     <Chip label="Overdue" variant="outlined" size="small" style={{fontSize: "10px", backgroundColor: "crimson",
                                                        //         background: "linear-gradient(90deg, brown, crimson)",
                                                        //         fontWeight: "bold",
                                                        //         color: "white"}}/>
                                                        //     :
                                                        //     null
                                                    }
                                                </Box>
                                                <Box>
                                                    {
                                                        step.completion_date !== undefined ?
                                                            "Completed on"
                                                            :
                                                            step.due_date !== undefined ?
                                                                // "Due on"
                                                                " Expected Delivery"
                                                                :
                                                                "ETA: Stay Tuned!"
                                                    }
                                                </Box>
                                                <span style={{ color: theme.palette.mode === "light" ? "indianred" : "dodgerblue", fontWeight: "500" }}>
                                                    {
                                                        step.completion_date !== undefined ?
                                                            fmtDate(step.completion_date)
                                                            :
                                                            step.due_date !== undefined ?
                                                                fmtDate(step.due_date)
                                                                :
                                                                ""
                                                    }
                                                </span>
                                            </Box>
                                        </Box>
                                            :
                                            "ETA not provided"
                                    }
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <ColorlibConnector />
                                    <ColorlibDot>
                                        {
                                            step.completion_date !== undefined ?
                                                <Tooltip title={"Completed"} >
                                                    <DoneIcon sx={{
                                                        padding: shrink ? "2px" : "10px",
                                                        transition: "padding 0.3s ease"
                                                    }} />
                                                </Tooltip>
                                                :
                                                step.start_date !== undefined ?
                                                    <Tooltip title={"Ongoing"} >
                                                        <MoreHorizIcon sx={{
                                                            padding: shrink ? "2px" : "10px",
                                                            transition: "padding 0.3s ease"
                                                        }} />
                                                    </Tooltip>
                                                    :
                                                    <Tooltip title={"Yet to start"} >
                                                        <PlayArrowRoundedIcon sx={{
                                                            padding: shrink ? "2px" : "10px",
                                                            transition: "padding 0.3s ease"
                                                        }} />
                                                    </Tooltip>
                                        }
                                    </ColorlibDot>
                                    <ColorlibConnector />
                                </TimelineSeparator>
                                <TimelineContent sx={{ py: '12px', px: 2 }}>
                                    <Box sx={{
                                        height: shrink || step.servers.length === 0 ? "70px" : "350px",
                                        backgroundColor: theme.palette.mode === "light" ? "#e9ecf2" : "#47566e99",
                                        // padding: "10px",
                                        boxSizing: "border-box",
                                        overflow: "hidden",
                                        borderRadius: "10px",
                                        display: "flex",
                                        flexDirection: "unset",
                                        alignItems: "flex-start",
                                        border: "1px solid " + (theme.palette.mode === "light" ? "#cdd0d4" : "#202e45"),
                                        boxShadow: "1px 1px 20px " + (theme.palette.mode === "light" ? "#d0d3d9" : "#29303b"),
                                        color: theme.palette.background.text,
                                        transition: "height 0.3s ease"
                                    }}>
                                        <TimelineCard step={step} shrink={shrink || step.servers.length === 0} card_number={index} />
                                    </Box>
                                </TimelineContent>
                            </TimelineItem>
                        );
                    })
                }
            </Timeline>
            <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar  onChange={(selectedDate) => setSelectedDate(selectedDate)}/>
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit} autoFocus>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}


const ColorlibConnector = styled(TimelineConnector)(({ theme }) => ({
    [`&.MuiTimelineConnector-root`]: {
        backgroundImage: theme.palette.mode === "light" ?
            'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
            :
            'linear-gradient( 95deg, #2894c9 0%, #286ec9 50%, #2846c9 100%)'
    }
}));

const ColorlibDot = styled(TimelineDot)(({theme}) => ({
    [`&.MuiTimelineDot-filled`]: {
        backgroundImage: theme.palette.mode === "light" ? 
            'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
            :
            'linear-gradient( 95deg, #2894c9 0%, #286ec9 50%, #2846c9 100%)',
        backgroundClip: 'padding-box'
    }
}));

function fmtDate(date) {
    if (!(date instanceof Date)) {
      return "Invalid Date";
    }
  
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
  
    return date.toLocaleString("en-US", options);
  }


function applyFilter(searchText, steps, filter) {

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const result = steps.filter(item => {

        //search filter
        const itemString = (
            (isClean(item.servers) && Array.isArray(item.servers) && item.servers.length !== 0 ? item.servers.map(serv => serv.name).join(",") : "")
            +(isClean(item.dris) ? item.dris.toString() : "")
            +(isClean(item.feature_name) ? item.feature_name.toString() : "")
            +(isClean(item.module_name) ? item.module_name.toString() : "")
            +(isClean(item.release_type) ? item.release_type.toString() : "")
            +(isClean(item.requested_by) ? item.requested_by.toString() : "")
            +(isClean(item.start_date) ? item.start_date.toString() : "")
            +(isClean(item.due_date) ? item.due_date.toString() : "")
            +(isClean(item.completion_date) ? item.completion_date.toString() : "")
            ).toLowerCase();
        var searchFilter = itemString.includes(searchText.toLowerCase());
        if(searchText === "") {
            searchFilter = true;
        }

        // Don't show eta not provided cards
        const etaNotProvidedFilter = item.due_date !== undefined;

        // completed filter
        const completedFilter = filter.completed ? item.completion_date !== undefined : item.completion_date === undefined;

        // dri filter
        const driFilter = filter.dri == undefined || filter.dri.length === 0 || item.dris.includes(filter.dri);

        // quarter filter
        const quarterFilter = filter.quarter === undefined || filter.quarter.length === 0 || filter.quarter.includes(item.release_type);

        // month filter
        const monthFilter = filter.month === undefined || filter.month.length === 0 || ( item.due_date !== undefined && filter.month.includes(months[item.due_date.getMonth()]) );

        // feature type filter
        const featureTypeFilter = filter.feature_type === "both" || (filter.feature_type === "quarter" && item.release_type.startsWith("Q")) || (filter.feature_type === "month" && !item.release_type.startsWith("Q"));

        // start date filter
        const startDateFilter = filter.start_date === undefined || filter.start_date <= item.due_date;

        // end date filter
        const endDateFilter = filter.end_date === undefined || filter.end_date >= item.due_date;

        if(etaNotProvidedFilter && completedFilter && searchFilter && driFilter && quarterFilter && monthFilter && featureTypeFilter && startDateFilter && endDateFilter) {
            return true;
        } else {
            return false;
        }
    });

    return result;
}

function isClean(item) {
    return ![undefined, null, "", " "].includes(item);
}

function applySort(sortType, steps) {
    const newSteps = steps.sort((a, b) => {
        try {
            if (sortType === "due_date") {
                const date_1 = a.completion_date ? new Date(a.completion_date) : new Date(a.due_date);
                const date_2 = b.completion_date ? new Date(b.completion_date) : new Date(b.due_date);

                if (date_1 > date_2) {
                    return 1
                } else if (date_1 === date_2) {
                    return 0;
                } else {
                    return -1;
                }
            } else {
                return 0;
            }
        } catch (error) {
            return 0;
        }
    });
    return newSteps;
}