/* eslint-disable react/prop-types */

import React, {useEffect} from "react";
import { Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { BarChart } from "../../../../../util/components/BarChart/BarChart";

export function Contribution({data}) {

    const theme = useTheme();

    var colors = [];
    if(theme.palette.mode === "light") {
        colors.push("#1b612e");
    } else {
        colors.push("#34994f");
    }

    return (
        <Box sx={{
            height: "100%",
            width: "100%"
        }}>
            <BarChart data={data.user_contribution}
                title={""}
                columnName1={"Users"}
                columnName2={"Tasks"}
                colors={colors}
                open={false}
                rotated={true}
            />
        </Box>
    );
}