import React, {useState,useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import { getFromTaskDetailsForTasklists } from './MilestoneDataFetcher.js';
import {milestone_main_component_get_query_7} from '../../../catalystDB/queryGenerator.js';
import LinkCards from './LinkCards.js'



export default function ThreadMsgLink({milestoneName,milestoneId,toggle}) {

    const [rows, setRows] = useState([]);
	const [contentLoaded, setContentLoaded] = useState(false);
    const theme = useTheme();

	useEffect(() => {
		async function run() {
			try {

				const result = await getFromTaskDetailsForTasklists(milestone_main_component_get_query_7(milestoneName,milestoneId));
				// console.log(result)
				result.sort((a, b) => {
					const timeA = new Date(a[12]);
					const timeB = new Date(b[12]);
					
					
					if (timeA < timeB) {
					  return -1; 
					} else if (timeA > timeB) {
					  return 1; 
					} else {
					  return 0;
					}
				  });
				// console.log(result)
	
				result.sort((a, b) => {
					const timeA = new Date(a[12]);
					const timeB = new Date(b[12]);
	
					if (timeA < timeB) {
						return -1;
					} else if (timeA > timeB) {
						return 1;
					} else {
						return 0;
					}
				});
	
				result.map((rowData, index) => {
				  if(rowData[13] != '-'){
					setContentLoaded(true);
				  }
				  else{
					setContentLoaded(false);
				  }
				});
	
				
				setRows(result);
			}
			catch (e) {
				setContentLoaded(null);
			}
		}
		run();
	}, []);

   
	if (!contentLoaded) {
		return null;
	}

    return (
		 <>
            {rows.map((row, index) => (
                    <>
                      {row[13] != "-" ? (<LinkCards ChatUrls ={row[13]} ChatDescription = {row[14]}/>):(null)} 
                    </>
                ))}
        </>
	);

}