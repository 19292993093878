import { isDateInRange, isDateString, isRoadmap } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    const chartData = [];
    const dataMap = {};
    const roadmapFeatureMap = {};

    catalystDB.TaskBreaker.map(item => {
        const dateString = item.CREATEDTIME;


        if(isDateString(dateString) && isDateInRange(dateString, startTime) && isRoadmap(item)) {
            const featureType = item.Feature_Type;

            if([undefined, null, "", " "].includes(featureType)) {
                return;
            }

            if(featureType in roadmapFeatureMap) {
                roadmapFeatureMap[featureType] += 1;
            } else {
                roadmapFeatureMap[featureType] = 1;
            }

            if(featureType in dataMap) {
                dataMap[featureType].push(item);
            } else {
                dataMap[featureType] = [item];
            }
        }

    }); 

    for(const key in roadmapFeatureMap) {
        chartData.push([
            key,
            roadmapFeatureMap[key]
        ]);
    }
    
    return [chartData, dataMap];
}