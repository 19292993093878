import { isDateInRange, isDateString, getDuration } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    var total = 0;
    var count = 0;
    var min = Number.MAX_SAFE_INTEGER;
    var max = 0;

    const milestoneMap = {};

    catalystDB.AllBuildDetails.map(item => {
        const dateString = item.CREATEDTIME;

        if(isDateString(dateString) && isDateInRange(dateString, startTime)) {
            const associatedTasks = item.Associated_Tasks;

            if([undefined, null, "", " "].includes(associatedTasks)) {
                return;
            }

            try {
                const arr = associatedTasks.split(",");

                for(const itm of arr) {
                    if(itm in milestoneMap) {
                        milestoneMap[itm].push(item.BuildURL);
                    } else {
                        milestoneMap[itm] = [item.BuildURL];
                    }
                }

            } catch(e) {}

        }
    }); 

    for(const key in milestoneMap) {
        var arr = milestoneMap[key];

        total += arr.length;

        count++;

        if(arr.length <= min) {
            min = arr.length;
        }

        if(arr.length >= max) {
            max = arr.length;
        }
    }

    var average;
    if (count === 0) {
        average = "-";
    } else {
        average = Math.floor(total / count);
    }

    return [
        {
            "average": average === "-" ? "-" : getDuration(average).duration,
            "min": min === Number.MAX_SAFE_INTEGER || min === 0 ? "-" : getDuration(min).duration,
            "max": max === 0 ? "-" : getDuration(max).duration
        },
        [
            average === "-" ? "" : getDuration(average).unit,
            min === Number.MAX_SAFE_INTEGER || min === 0 ? "" : getDuration(min).unit,
            max === 0 ? "" : getDuration(max).unit
        ]
    ];
}