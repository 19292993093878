import { tickets_pre_claim_get_query_1 } from "../../../../../catalystDB/queryGenerator";
import { executeQuery } from "../../../../../util/QueryDB";

export function claim(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, loginUserEmail, setLocalStatus, setLocalClaimedDRI) {
 
    const pre_message = `Claiming #${data.DeskTicketNumber}`;
    const post_success_message = `Successfully claimed #${data.DeskTicketNumber}`;
    const post_failure_message = `Unable to claim #${data.DeskTicketNumber}`;
    // initial info message
    send_initial_msg(setEventFeedbackQueue, pre_message);

    const url = `/server/desk?task=CLAIM&statusValue=Open&ID=${data.ID}&thread_id=${encodeURIComponent(data.ThreadID)}&user=${loginUserEmail.current}&allDevelopers=${encodeURIComponent(data.AllDevelopers)}`;
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(response => {
        if(response.status !== 200) {
            return null;
        }
        return response;
    }).then(data => {
            if (data !== null && data.status == 200) { // success
                send_success_msg(setEventFeedbackQueue, post_success_message);
                setLocalStatus("Open");
                setLocalClaimedDRI(loginUserEmail.current);
            }
            else { // failure
                send_failure_msg(setEventFeedbackQueue, post_failure_message);
                setLocalStatus("Open");
            }
        })
        .catch(error => { // failure
            console.log('Error claiming ticket:', error);
            send_failure_msg(setEventFeedbackQueue, post_failure_message);
            setLocalStatus("Open");
        })
        .finally(() => {
            remove_initial_msg(setEventFeedbackQueue, pre_message);
        });
}

export function assign(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, setExtraComps, setLocalClaimedDRI, setLocalStatus, dri,currentLoginUser) { // 1st call -> trigger extra comp, 2nd call -> provide dri and proceed
    
    if (dri === undefined) { // triggers opening of an extra component, in order to get dri
       
        setExtraComps("ASSIGN");
        setAnchor(null);
        setMoreIconVisible(false);

    } else { // after dri is got from that component, that will callback this function again with the dri parameter
        // console.log("Assigned From: " + currentLoginUser);
        // console.log("Assigned To: " + dri);
        const pre_message = `Assigning ${dri} as DRI for #${data.DeskTicketNumber}`;
        const post_success_message = `Successfully assigned ${dri} as DRI for #${data.DeskTicketNumber}`;
        const post_failure_message = `Unable to assign DRI for #${data.DeskTicketNumber}`;
// console.log("assign");
        const url = `/server/desk?task=Assign&ID=${data.ID}&developer=${dri}&allDevelopers=${encodeURIComponent(data.AllDevelopers)}&user=${currentLoginUser}`;
        send_initial_msg(setEventFeedbackQueue, pre_message);
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(response => {
            if(response.status !== 200) {
                return null;
            }
            return response;  
        }).then(data => {
                if (data !== null && data.status == 200) { // success
                    send_success_msg(setEventFeedbackQueue, post_success_message);
                    setLocalClaimedDRI(dri);
                    setLocalStatus("Open");
                }
                else { // failure
                    send_failure_msg(setEventFeedbackQueue, post_failure_message);
                    setLocalClaimedDRI(null);   
                    setLocalStatus(null);
                }
            })
            .catch(error => {
                console.log("Error assigning ticket: ", error);
                send_failure_msg(setEventFeedbackQueue, post_failure_message);
                setLocalClaimedDRI(null);
                setLocalStatus(null);
            })
            .finally(() => {
                remove_initial_msg(setEventFeedbackQueue, pre_message);
            });
    }
}

export function reject(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, userEmail, setLocalStatus) {

    const pre_message = `Rejecting #${data.DeskTicketNumber}`;
    const post_success_message = `Successfully rejected #${data.DeskTicketNumber}`;
    const post_failure_message = `Unable to reject #${data.DeskTicketNumber}`;

    const default_description = "rejected";
    const url = `/server/desk?task=Reject&user=${userEmail}&ID=${data.ID}&description=${default_description}&allDevelopers=${encodeURIComponent(data.AllDevelopers)}`;

    setAnchor(null);
    setMoreIconVisible(false);
    send_initial_msg(setEventFeedbackQueue, pre_message);
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(response => {
        if(response.status !== 200) {
            return null;
        }
        return response;
    }).then(data => {
            if (data !== null && data.status == 200) { // success
                send_success_msg(setEventFeedbackQueue, post_success_message);
                setLocalStatus("Ticket Rejected");
            }
            else { // failure
                send_failure_msg(setEventFeedbackQueue, post_failure_message);
                setLocalStatus("Ticket Rejected");
            }
        })
        .catch(error => { // failure
            console.log('Error rejecting ticket:', error);
            send_failure_msg(setEventFeedbackQueue, post_failure_message);
            setLocalStatus("Ticket Rejected");
        })
        .finally(() => {
            remove_initial_msg(setEventFeedbackQueue, pre_message);
        });
}

export function delete_ticket(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, setLocalDeleted) {
    setAnchor(null);
    setMoreIconVisible(false);

    if (window.confirm(`Are you sure you want to delete Ticket #${data.DeskTicketNumber} ?`) == true) {

        const pre_message = `Deleting #${data.DeskTicketNumber}`;
        const post_success_message = `Successfully Deleted #${data.DeskTicketNumber}`;
        const post_failure_message = `Unable to Delete #${data.DeskTicketNumber}`;

        send_initial_msg(setEventFeedbackQueue, pre_message);

        fetch(`/server/userToken/deleteTicket?ticket_id=${data.DeskTicketId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(response => {
            // console.log(response);
            if(response.status === 200) {
                executeQuery(tickets_pre_claim_get_query_1(data)).then(response => {
                    setLocalDeleted(true);
                    send_success_msg(setEventFeedbackQueue, post_success_message);
                }).catch(error => {
                    console.log('Error deleting ticket:', error);
                    send_failure_msg(setEventFeedbackQueue, post_failure_message);
                })
            } else {
                send_failure_msg(setEventFeedbackQueue, post_failure_message);
            }
        }).catch(error => {
            console.log('Error deleting ticket:', error);
            send_failure_msg(setEventFeedbackQueue, post_failure_message);
        }).finally(() => {
            remove_initial_msg(setEventFeedbackQueue, pre_message);
        });
    } 
}


// ====================== util functions ====================

function send_initial_msg(setEventFeedbackQueue, pre_message) {
    setEventFeedbackQueue(queue => {
        return [...queue, {
            message: pre_message,
            auto_hide: false,
            loading: true
        }]
    });
}

function remove_initial_msg(setEventFeedbackQueue, pre_message) {
    setEventFeedbackQueue(queue => {
        return queue.filter(q => q.message !== pre_message);
    });
}

function send_success_msg(setEventFeedbackQueue, post_success_message) {
    setEventFeedbackQueue(queue => {
        return [...queue, {
            message: post_success_message,
            auto_hide: true,
            loading: false,
            success: true
        }]
    });
}

function send_failure_msg(setEventFeedbackQueue, post_failure_message) {
    setEventFeedbackQueue(queue => {
        return [...queue, {
            message: post_failure_message,
            auto_hide: true,
            loading: false,
            success: false
        }]
    });
}

