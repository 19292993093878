import { isDateInRange, isDateString, isTask } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    const chartData = [];
    const taskCountMap = {};

    catalystDB.TaskBreaker.map(item => {
        const dateString = item.CREATEDTIME;


        if(isDateString(dateString) && isDateInRange(dateString, startTime) && isTask(item)) {
            const completionDateString = item.TaskCompletionDate;

            if([undefined, null, "", " "].includes(completionDateString) && !isDateString(completionDateString)) {
                return;
            }

            if(item.Component in taskCountMap) {
                taskCountMap[item.Component] += 1;
            } else {
                taskCountMap[item.Component] = 1;
            }
        }
    }); 

    
    var keys = Object.keys(taskCountMap);
    keys.sort((a, b) => {
        return new Date(a).getTime() < new Date(b).getTime() ? -1 : 1;
    })

    for(const key of keys) {
        chartData.push([
            key,
            taskCountMap[key]
        ]);
    }

    return chartData;
}