import React, { useState, useContext } from "react";

import { 
    Menu,
    MenuList,
    MenuItem,
    Divider
} from "@mui/material";
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import ReplyRoundedIcon from '@mui/icons-material/ReplyRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import ShortcutRoundedIcon from '@mui/icons-material/ShortcutRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { close_ticket, forward_to, reply_as_bot, declare_as_incident, go_to_thread, follow_thread } from "./postClaimActions";
import { assign, reject } from "./preClaimActions";
import { AssignDRI } from "./extra_components/AssignDRI";
import { BotReply } from "./extra_components/BotReply";
import { CloseTicket } from "./extra_components/CloseTicket";
import { UserContext } from "../../../../../contexts/UserContext";
import { DeclareAsIncident } from "./extra_components/DeclareAsIncident";
import { Forward } from "./extra_components/Forward";
import { delete_ticket } from "./preClaimActions";

import styles from "./TicketsTable.module.css";

export const TICKET_STATUS = Object.freeze({
    TICKET_CREATED: "Ticket Created",
    OPEN: "Open",
    ON_HOLD: "On Hold",
    COMPLETED: "Completed",
    IMMEDIATE_FIX_IN_PROGRESS: "Immediate Fix In Progress",
    PERMANENT_FIX_IN_PROGRESS: "Permanent Fix In Progress",
    RESOLUTION_WAITING_FOR: "Resolution waiting for Approval",
    REJECTED: "Ticket Rejected"
});

export function MoreMenu({data, anchor, setAnchor, setMoreIconVisible, isPreClaim, setEventFeedbackQueue, setLocalClaimedDRI, setLocalStatus, localDeleted, setLocalDeleted}) {

    const [extraComps, setExtraComps] = useState(null); // either null or one of the below actions names
    const {loginUserEmail,teamDetails, loginUserZuid, zoneAdmin} = useContext(UserContext);
  
 const loginUserRole = teamDetails.current[loginUserEmail.current].Role;

    const MORE_ACTIONS_PRE_CLAIM = Object.freeze({
        GO_TO_THREAD: {
            name: "Go to Thread",
            onClick: () => go_to_thread(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue),
            icon: <ForumRoundedIcon fontSize="medium" />
        },
        REPLY_AS_BOT: {
            name: "Reply as Bot",
            onClick: () => reply_as_bot(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, setExtraComps, loginUserEmail.current),
            extra_comps: <BotReply data={data} setAnchor={setAnchor} setMoreIconVisible={setMoreIconVisible} setEventFeedbackQueue={setEventFeedbackQueue}
                setExtraComps={setExtraComps} userEmail={loginUserEmail.current} />,
            icon: <ReplyRoundedIcon fontSize="medium" />
        },
        DECLARE_AS_INCIDENT: {
            name: "Declare as Incident",
            onClick: () => declare_as_incident(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, setExtraComps),
            extra_comps: <DeclareAsIncident setExtraComps={setExtraComps} setAnchor={setAnchor} setMoreIconVisible={setMoreIconVisible} data={data} 
            setEventFeedbackQueue={setEventFeedbackQueue}/>,
            icon: <WarningRoundedIcon fontSize="medium" />
        },
        ASSIGN: {
            name: "Assign",
            onClick: () => assign(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, setExtraComps, setLocalClaimedDRI, setLocalStatus),
            extra_comps: <AssignDRI data={data} setAnchor={setAnchor} setMoreIconVisible={setMoreIconVisible} setEventFeedbackQueue={setEventFeedbackQueue}
                setLocalClaimedDRI={setLocalClaimedDRI} setExtraComps={setExtraComps} setLocalStatus={setLocalStatus} />,
            icon: <PersonRoundedIcon fontSize="medium" />
        },
        REJECT: {
            name: "Reject",
            onClick: () => reject(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, loginUserEmail.current, setLocalStatus),
            icon: <CloseRoundedIcon fontSize="medium" />
        }
    });
    
    const MORE_ACTIONS_POST_CLAIM = {
        GO_TO_THREAD: {
            name: "Go to Thread",
            onClick: () => go_to_thread(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue),
            icon: <ForumRoundedIcon fontSize="medium" />
        },
        FOLLOW_THREAD: {
            name: "Follow Thread",
            onClick: () => follow_thread(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, loginUserZuid.current),
            icon: <NotificationsRoundedIcon fontSize="medium" />
        },
        DECLARE_AS_INCIDENT: {
            name: "Declare as Incident",
            onClick: () => declare_as_incident(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, setExtraComps),
            extra_comps: <DeclareAsIncident setExtraComps={setExtraComps} setAnchor={setAnchor} setMoreIconVisible={setMoreIconVisible} data={data} 
                setEventFeedbackQueue={setEventFeedbackQueue}/>,
            icon: <WarningRoundedIcon fontSize="medium" />
        }
        // BOT_MESSAGES: "Bot Messages"
    };
    if (loginUserRole === "Mentor" || loginUserRole === "CEO") {
        MORE_ACTIONS_POST_CLAIM.ASSIGN = {
            name: "Assign",
            onClick: () => assign(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, setExtraComps, setLocalClaimedDRI, setLocalStatus),
            extra_comps: <AssignDRI data={data} setAnchor={setAnchor} setMoreIconVisible={setMoreIconVisible} setEventFeedbackQueue={setEventFeedbackQueue}
                setLocalClaimedDRI={setLocalClaimedDRI} setExtraComps={setExtraComps} setLocalStatus={setLocalStatus} />,
            icon: <PersonRoundedIcon fontSize="medium" />
        };
    }
    if (loginUserEmail.current === data.Current_Developer) {
        MORE_ACTIONS_POST_CLAIM.FORWARD_TO = {
            name: "Forward To",
            onClick: () => forward_to(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, setExtraComps, setLocalClaimedDRI),
            extra_comps: <Forward data={data} setAnchor={setAnchor} setMoreIconVisible={setMoreIconVisible} setEventFeedbackQueue={setEventFeedbackQueue}
                setExtraComps={setExtraComps} setLocalClaimedDRI={setLocalClaimedDRI} />,
            icon: <ShortcutRoundedIcon fontSize="medium" />
        };
        MORE_ACTIONS_POST_CLAIM.CLOSE_TICKET = {
            name: "Close Ticket",
            onClick: () => close_ticket(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, setExtraComps),
            extra_comps: <CloseTicket setExtraComps={setExtraComps} setAnchor={setAnchor} setMoreIconVisible={setMoreIconVisible} data={data} 
                setEventFeedbackQueue={setEventFeedbackQueue}/>,
            icon: <DoneRoundedIcon fontSize="medium" />
        };
    }
    if (loginUserEmail.current === data.Current_Developer || loginUserRole === "Mentor" || loginUserRole === "CEO") {
        MORE_ACTIONS_POST_CLAIM.REPLY_AS_BOT = {
            name: "Reply as Bot",
            onClick: () => reply_as_bot(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, setExtraComps, loginUserEmail.current),
            extra_comps: <BotReply data={data} setAnchor={setAnchor} setMoreIconVisible={setMoreIconVisible} setEventFeedbackQueue={setEventFeedbackQueue}
                setExtraComps={setExtraComps} userEmail={loginUserEmail.current} />,
            icon: <ReplyRoundedIcon fontSize="medium" />
        }
    }

    Object.freeze(MORE_ACTIONS_POST_CLAIM);
    
    function handleMoreMenuClose() {
        setAnchor(null);
        setMoreIconVisible(false);
    }

    const actions = isPreClaim ? Object.values(MORE_ACTIONS_PRE_CLAIM) : Object.values(MORE_ACTIONS_POST_CLAIM);

    const filteredActions = data.Status === TICKET_STATUS.COMPLETED ? 
        [MORE_ACTIONS_POST_CLAIM.GO_TO_THREAD] : actions;

    return (
        <>
            <Menu anchorEl={anchor}
                open={anchor !== null}
                onClose={handleMoreMenuClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuList className={styles.more_menu_main}>
                    {
                        filteredActions.map((action, index) => {
                            return (
                                <>
                                    <MenuItem className={styles.more_menu_item} key={index} onClick={action.onClick}>
                                        {action.name}
                                        {action.icon}
                                    </MenuItem>
                                    <Divider light style={{ margin: 0 }} />
                                </>
                            );
                        })

                    }
                    {
                        zoneAdmin.current ? 
                            <>
                                <MenuItem className={styles.more_menu_item} key={actions.length} onClick={() => delete_ticket(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, setLocalDeleted)} > 
                                    Delete
                                    <DeleteOutlineIcon fontSize="medium" />
                                </MenuItem>
                                <Divider light style={{ margin: 0 }} />
                            </>
                            :
                            null
                    }
                </MenuList>
            </Menu>
            {
                extraComps !== null ?
                    (
                        isPreClaim ?
                            MORE_ACTIONS_PRE_CLAIM[extraComps]?.extra_comps
                            :
                            MORE_ACTIONS_POST_CLAIM[extraComps]?.extra_comps
                    )
                    :
                    null
            }
        </>
    );
}