//ignorei18n_start
import React, { useState } from "react";
import { formatDate } from "../Timeline";

const keys = ["task_name", "start_date", "due_date", "completed_date", "dri", "status"];

export default function Tasks({data}) {

    return (
        <div style={{
            display: "grid",
            gridTemplateColumns: "4fr repeat(" + (keys.length - 1) + ", 1fr)", 
            padding: "15px 0px",
            gap: "15px",
            alignContent: "space-between",
            justifyContent: "space-between"
        }}>
            {
                keys.map((k, i) => {
                    return (
                        <div key={i} style={{
                            // justifySelf: "center",
                            fontSize: "10px",
                            color: "white",
                            opacity: 0.75,
                            backgroundColor: "darkgreen",
                            padding: "0px 4px",
                            borderRadius: "4px",
                            fontWeight: "bold",
                            boxShadow: "0 5px 10px -6px darkgreen", 
                            width: "fit-content"
                        }}>
                            {k.replaceAll("_", " ")}
                        </div>
                    )
                })
            }
            {
                data.map((d, i) => {
                    return <Task data={d} key={i} index={i+1} total={data.length} />
                })
            }
        </div>
    );
 
}

function Task({data, index}) {

    return (
        keys.map((k, i) => {
            return (
                <div style={{  
                    // justifySelf: "center",
                    fontSize: "12px",
                    fontWeight: k === "task_name" || k === "status" ? "bold" : "regular",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }} key={i}>
                    {
                        k === "task_name" ? index+". " : null
                    }
                    {
                        data[k] ? 
                        typeof data[k] === "object" ?
                            formatDate(data[k])
                            :
                            data[k]
                            :
                            "-"
                    }
                </div>
            );
        })
    );
}
//ignorei18n_end