import React, { useEffect, useState, useContext } from 'react';
import { DashboardContext } from '../../dashboardMain/stateStorage/DashboardStateStorage';
import { useTheme } from '@emotion/react';

import {
    Box
} from '@mui/material';

import { getChartData } from './gridItem_5_dataProcessor';

import { PIE_CHART_COLORS_DARK_2, PIE_CHART_COLORS_LIGHT_2 } from '../../dashboardMain/DashboardUtil';
import { PieChart } from '../../../../../util/components/pieChart/PieChart';

export function GridItem_5() {

    const { getDashboardContext, catalystDB, startTime } = useContext(DashboardContext);
    const { currentSelectedGridItem } = useContext(getDashboardContext());

    const isOpen = currentSelectedGridItem === "GridItem_5";
    const theme = useTheme();

    var colors = PIE_CHART_COLORS_LIGHT_2;
    if (theme.palette.mode === "dark") {
        colors = PIE_CHART_COLORS_DARK_2;
    }

    if (catalystDB === undefined) {
        return null;
    } else if (catalystDB.Tickets === null || catalystDB.TaskBreaker === null) {
        return null;
    }

    const chartData = getChartData(catalystDB, startTime);

    return (
        <Box sx={{
            height: "100%",
            width: "100%",
            boxSizing: "border-box"
        }}>
            <PieChart data={chartData}
                title={"Tasks vs Issues"}
                colors={colors}
                hallow={isOpen ? 70 : 10}
                padAngle={isOpen ? 0 : 0.025}
                open={isOpen}
            />
        </Box>
    );

}