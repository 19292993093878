//ignorei18n_start
import { Button, Collapse, Divider } from "@mui/material";
import React, { useState } from "react";
import { Stat } from "../milestone/Milestone";
import { formatDate } from "../Timeline";
import { SplitSingle } from "../components/SplitSingle";

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Tasks from "../tasks/Tasks";
import { isDate } from "date-fns";
 
export default function Tasklists({data}) {
 
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            padding: "15px 0px",
        }}>
            {
                data.map((d, i) => {
                    return <Tasklist data={d} key={i} index={i+1} total={data.length}/>
                })
            }
        </div>
    );
 
}

function Tasklist({data, index, total}) {
    const keys = ["start_date", "due_date", "completed_date", "dri", "status"];

    const [tasksOpen, setTasksOpen] = useState(false);

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            // gap: "5px"
        }}>
            <div style={{textIndent: "10px", fontWeight: "bold"}}>
                {index}. {data.tasklist_name}
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                paddingLeft: "10px"
            }}>
                {
                    keys.map((k, i) => {
                        return (
                            <React.Fragment key={i}>
                                <Stat k={k} v={
                                    isDate(data[k]) || typeof data[k] === "object" ? formatDate(data[k]) : data[k]
                                } color={"#c21f60"}/>
                                {
                                    i !== keys.length - 1 ? <Divider orientation="vertical" flexItem style={{}}/> : null
                                }
                            </React.Fragment>
                        );
                    })
                }
            </div>
            {/* <Divider /> */}
            <div style={{
                display: "flex",
                flexDirection: "row"
            }}>
                <Button onClick={() => setTasksOpen(s => !s)} style={{minWidth: 0}}>    
                    {
                        tasksOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                    }
                </Button>
                <SplitSingle data={data.progress} index={2} />
            </div>
            <Collapse orientation="vertical" in={tasksOpen} style={{
                padding: "10px 0px 10px 35px", 
                borderLeft: "3px solid #06702b", 
                boxShadow: "10px 0 15px -2px #06702b33 inset", 
                borderRadius: "10px",
                margin: "20px"
            }}>
                <Tasks data={data.tasks}/>
            </Collapse>
            {
                index-1 !== total - 1 ? <Divider style={{}}/> : null
            }
        </div>
    );
}
//ignorei18n_end