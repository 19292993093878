import React, { useEffect, useState, useContext } from 'react';
import { DashboardContext } from '../../dashboardMain/stateStorage/DashboardStateStorage';
import { useTheme } from '@emotion/react';

import {
    Box
} from '@mui/material';

import { getChartData } from './gridItem_8_dataProcessor';

import { LineChartReact } from '../../../../../util/components/LineChart/LineChart';
import { GanttChart } from '../../../../../util/components/GanttChart/GanttChart';
// import { LineChart } from '@mui/x-charts/LineChart';
import { TASK_LINE_CHART_COLORS_DARK, TASK_LINE_CHART_COLORS_LIGHT } from '../../dashboardMain/DashboardUtil';
import { isDateInRange, isDateString, isTask } from "../../dashboardMain/DashboardUtil";
// import * as React from 'react';
// import { BarChart } from '@mui/x-charts';

export function GridItem_8() {

    const { getDashboardContext, catalystDB, startTime } = useContext(DashboardContext);
    const { currentSelectedGridItem } = useContext(getDashboardContext());
    // const [completedTasks, setCompletedTasks] = useState([]);
    // const [taskNames, setTaskNames] = useState([]);
    // const [startDates, setStartDates] = useState([]);
    // const [onTimeStatus, setOnTimeStatus] = useState([]);
    // const [taskStatus, setTaskStatus] = useState([]);
    // const [OngoingStatus, setOngoingStatus] = useState([]);
    // useEffect(() => {
    //     // Filter data based on TaskStatus === "Completed"
    //     const filteredData = catalystDB.TaskBreaker.filter(item => item.TaskStatus === "Completed");
    
    //     // Set the filtered completed tasks
    //     setCompletedTasks(filteredData);
    //   }, [catalystDB.TaskBreaker]);

    //   useEffect(() => {
    //      processCompletedTasks();
    //   }, [startTime]);


    const isOpen = currentSelectedGridItem === "GridItem_8";
    const theme = useTheme();

    var colors = TASK_LINE_CHART_COLORS_LIGHT;
    if(theme.palette.mode === "dark") {
        colors = TASK_LINE_CHART_COLORS_DARK;
    }

    if (catalystDB === undefined) {
        return null;
    } else if (catalystDB.TaskBreaker === null) {
        return null;
    }
    // console.log(catalystDB.TaskBreaker)



//   const processCompletedTasks = () => {

//     // Process the completed tasks
//     const taskNamesArray = [];
//     const startDatesArray = [];
//     const onTimeStatusArray = [];
//     const TaskStatusValue = [];
//     const OngoingData = [];

//     catalystDB.TaskBreaker.map(task => {
//         const dateString = task.CREATEDTIME;

//         if(isDateString(dateString) && isDateInRange(dateString, startTime) && isTask(task)) {
//             // console.log(task)
//             // console.log(dateString)
//             const { TaskName, TaskStartDate, TaskDueDate, TaskCompletionDate,TaskStatus} = task;

//             // Compare TaskDueDate with TaskCompletionDate to determine if the task was completed on time or delayed
//             const isOnTime = new Date(TaskCompletionDate) <= new Date(TaskDueDate);
      
//             // Store task information in arrays
//             taskNamesArray.push(TaskName);
//             startDatesArray.push(TaskStartDate);
//             onTimeStatusArray.push(isOnTime ? 'On Time' : 'Delayed');
//             TaskStatusValue.push(TaskStatus);

//             // const completionDateString = item.TaskCompletionDate;

//             // if([undefined, null, "", " "].includes(completionDateString)) {
//             //     return;
//             // }

//             // if(completionDateString in taskCountMap) {
//             //     taskCountMap[completionDateString] += 1;
//             // } else {
//             //     taskCountMap[completionDateString] = 1;
//             // }
//         }


     
//     });

//     // Set the arrays in state
//     setTaskNames(taskNamesArray);
//     setStartDates(startDatesArray);
//     setOnTimeStatus(onTimeStatusArray);
//     setTaskStatus(TaskStatusValue);
//     taskStatus.forEach((task, index) => {
        
//         if (task === "Ongoing") {
//             OngoingData.push(startDates[index])
//         }
//       });
//     //   console.log(OngoingData);
//       setOngoingStatus(OngoingData)
    
//   };
  
// //   console.log(taskNames);
// //   console.log(startDates);
// //   console.log(onTimeStatus);
// //   console.log(taskStatus);
//      console.log(OngoingStatus);


    const chartData = getChartData(catalystDB, startTime);

    // const CompletedDelayed = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
    // const CompletedOnTime = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
    // const Completed = [0];
    // const OngoingDelayed = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
    // const OngoingOnTime = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
    // const Ongoing = [0];
    
    return (
        // <>
        // {startTime != "year" ? (
        //     <>
        //      <Box sx={{
        //         height: "50%",
        //         width: "100%",
        //         boxSizing: "border-box"
        //     }}>
        //         <LineChart 
        //         leftAxis={null}
        //         bottomAxis={null}
        //          series={[
        //             { data: OngoingDelayed, label: 'Delayed', area: true, stack: 'total', showMark: false },
        //             { data: OngoingOnTime, label: 'OnTime', area: true, stack: 'total', showMark: false },
        //             { data: Ongoing, label: 'Ongoing', area: true, stack: 'total', showMark: false }
        //           ]}
        //         />
        //     </Box>
        //     <Box sx={{
        //         height: "50%",
        //         width: "100%",
        //         boxSizing: "border-box"
        //     }}>
        //           <BarChart
        //           leftAxis={null}
        //           bottomAxis={null}
        //         //   series={[{label:"Completed"}]},
        //             series={[
        //                 { data: CompletedOnTime, label: 'OnTime', stack: 'total' },
        //                 { data: CompletedDelayed, label: 'Delayed', stack: 'total' },
        //                 { data: Completed, label: 'Completed', stack: 'total' },
        //             ]}
        //             // xAxis={{ visible: true }} 
        //             // yAxis={{ visible: false }} 
        //         />
        //     </Box>
        //     </>
        // ):(
            <Box sx={{
                height: "100%",
                width: "100%",
                boxSizing: "border-box"
            }}>
                <LineChartReact data={chartData}
                    title={"Task"}
                    columnName1={"Date"}
                    columnName2={"Completed"}
                    colors={colors}
                    open={isOpen}
                /> 
               {/*  <GanttChart></GanttChart> */}
            </Box>
        // )}
       
        // </>
    );

}
