import { ONE_WEEK_BEFORE_TODAY } from "./DashboardDataFetcher";
import { ONE_MONTH_BEFORE_TODAY } from "./DashboardDataFetcher";
import { ONE_YEAR_BEFORE_TODAY } from "./DashboardDataFetcher";

export const PIE_CHART_COLORS = [
    "#E6BB8E",
    "#D776E5",
    "#A6E660",
    "#6CC6E6",
    "#E67775",
    "#6A71E6"
];

// Dashboard - common --------------------------------------------------------------------------------------------

// red - #E64256, #B21D1D
// orange - #E59659, #D6833E
// yellow - #FAE76A, #E6DE00
// green - #1DCF5B, #1BBF54
// blue - #3982E3
// pinkviolet - #BF6CE6, #A85FC9
export const PIE_CHART_COLORS_LIGHT = [ "#B21D1D", "#D6833E", "#E6DE00",  "#1BBF54", "#3982E3", "#A85FC9"];
export const PIE_CHART_COLORS_DARK = ["#E64256", "#E59659", "#FAE76A", "#1DCF5B", "#3982E3", "#BF6CE6"];

export const PIE_CHART_COLORS_LIGHT_1 = [ "#B21D1D", "#D6833E", "#009AC4", "#CC6E14", "#71BF0A", "#E6DE00",  "#1BBF54", "#3982E3", "#A85FC9"];
export const PIE_CHART_COLORS_DARK_1 = ["#E64256", "#E59659", "#00E1FF", "#EB7317", "#B8FF0D", "#FAE76A", "#1DCF5B", "#3982E3", "#BF6CE6"];

export const PIE_CHART_COLORS_LIGHT_2 = [ "#1BBF54", "#B21D1D", "#3982E3"];
export const PIE_CHART_COLORS_DARK_2 = ["#1DCF5B", "#E64256", "#3982E3"];

export const PACKED_BUBBLE_CHART_COLORS_LIGHT = ["#BF1F13", "#0700DE", "#A88C08", "#04B725", "#A313C2", "#009AC4", "#CC6E14", "#71BF0A"];
export const PACKED_BUBBLE_CHART_COLORS_DARK = ["#FF2519", "#308DFF", "#EBC417", "#0DFF68", "#BF19FF", "#00E1FF", "#EB7317", "#B8FF0D"];

// Dashboard - 1  --------------------------------------------------------------------------------------------

export const TASK_COUNT_COLORS_LIGHT = ["#AB5B50", "#AB5B5055"];
export const TASK_COUNT_COLORS_DARK = ["#D67264", "#D6726433"]; 

export const TASKLIST_COUNT_COLORS_LIGHT = ["#318F3F", "#318F3F55"];
export const TASKLIST_COUNT_COLORS_DARK = ["#3DB34E", "#3DB34E33"];

export const MILESTONE_COUNT_COLORS_LIGHT = ["#30408F", "#30408F55"];
export const MILESTONE_COUNT_COLORS_DARK =  ["#026CBA", "#026CBA3a"];

export const TASK_LINE_CHART_COLORS_LIGHT = ["#A81100"];
export const TASK_LINE_CHART_COLORS_DARK = ["#D67264"];

export const TASKLIST_LINE_CHART_COLORS_LIGHT = ["#318F3F"];
export const TASKLIST_LINE_CHART_COLORS_DARK = ["#3DB34E"];

export const MILESTONE_LINE_CHART_COLORS_LIGHT = ["#30408F"];
export const MILESTONE_LINE_CHART_COLORS_DARK = ["#026CBA"];

export const TASK_RADAR_CHART_COLORS_LIGHT = ["#AB5B50", "#AB5B5055"];
export const TASK_RADAR_CHART_COLORS_DARK = ["#D67264", "#D6726433"];

export const TASK_BULLET_CHART_COLORS_LIGHT = ["#AB5B50", "#AB5B5055"];
export const TASK_BULLET_CHART_COLORS_DARK = ["#D67264", "#D6726433"];

export const TASKLIST_BULLET_CHART_COLORS_LIGHT = ["#318F3F", "#318F3F55"];
export const TASKLIST_BULLET_CHART_COLORS_DARK = ["#3DB34E", "#3DB34E33"];

export const MILESTONE_BULLET_CHART_COLORS_LIGHT = ["#30408F", "#30408F55"];
export const MILESTONE_BULLET_CHART_COLORS_DARK = ["#026CBA", "#026CBA3a"];

// Dashboard - 2  --------------------------------------------------------------------------------------------

export const ISSUE_COUNT_COLORS_LIGHT = ["#D05213", "#D0521355"];
export const ISSUE_COUNT_COLORS_DARK = ["#DC4600", "#DC460033"]; 

export const ISSUES_LINE_CHART_COLORS_LIGHT = ["#D05213"];
export const ISSUES_LINE_CHART_COLORS_DARK = ["#DC4600aa"];

export const ISSUES_BULLET_CHART_COLORS_LIGHT = ["#D05213", "#AB5B5055"];
export const ISSUES_BULLET_CHART_COLORS_DARK = ["#DC4600aa", "#D6726433"];

export const ISSUES_RADAR_CHART_COLORS_LIGHT = ["#D05213", "#AB5B5055"];
export const ISSUES_RADAR_CHART_COLORS_DARK = ["#DC4600", "#D6726433"];

// Dashboard - 3  --------------------------------------------------------------------------------------------

export const BUILD_COUNT_COLORS_LIGHT = ["#30408F", "#30408F55"];
export const BUILD_COUNT_COLORS_DARK = ["#026CBA", "#026CBA3a"];

export const BUILD_RADAR_CHART_COLORS_LIGHT = ["#364AC9", "#364AC955"];
export const BUILD_RADAR_CHART_COLORS_DARK =  ["#026CBA", "#026CBA3a"];

export const BUILD_LINE_CHART_COLORS_LIGHT = ["#30408F"];
export const BUILD_LINE_CHART_COLORS_DARK = ["#026CBA"];

// Dashboard - end  --------------------------------------------------------------------------------------------

const MILLIS_IN_ONE_MONTH = 2.628e+9;
const MILLIS_IN_ONE_WEEK = 6.048e+8;
const MILLIS_IN_ONE_DAY = 8.64e+7;
const MILLIS_IN_ONE_HOUR = 3.6e+6;
const MILLIS_IN_ONE_MIN = 60000;
const MILLIS_IN_ONE_SEC = 1000;

export var isDateString = function(dateString) {
    return (![null, undefined].includes(dateString) && new Date(dateString) !== "Invalid Date") && !isNaN(new Date(dateString));
}

export var isDateInRange = function(dateString, range) {

    var startDate = ONE_YEAR_BEFORE_TODAY;

    if(range !== undefined) {
        if(range === "week") {
            startDate = ONE_WEEK_BEFORE_TODAY;
        } else if(range === "month") {
            startDate = ONE_MONTH_BEFORE_TODAY;
        } else if(range === "year") {
            startDate = ONE_YEAR_BEFORE_TODAY;
        }
    }

    return new Date(dateString) >= new Date(startDate);
}

export var isTask = function(item) {
    return item.ReportName === "TaskBreaker_Data_Report";
}

export var isRoadmap = function(item) {
    return item.ReportName === "Roadmap_Form_Report";
}

export var getDuration = function(timestamp) {
    if(timestamp/MILLIS_IN_ONE_MONTH > 1) {
        const duration = timestamp/MILLIS_IN_ONE_MONTH;
        return {
            "duration": Math.floor(duration),
            "unit": duration >= 2 ? "Months" : "Month"
        }
    } else if(timestamp/MILLIS_IN_ONE_WEEK > 1) {
        const duration = timestamp/MILLIS_IN_ONE_WEEK;
        return {
            "duration": Math.floor(duration),
            "unit": duration >= 2 ? "Weeks" : "Week"
        }
    } else if(timestamp/MILLIS_IN_ONE_DAY > 1) {
        const duration = timestamp/MILLIS_IN_ONE_DAY;
        return {
            "duration": Math.floor(duration),
            "unit": duration >= 2 ? "Days" : "Day"
        }
    } else if(timestamp/MILLIS_IN_ONE_HOUR > 1) {
        const duration = timestamp/MILLIS_IN_ONE_HOUR;
        return {
            "duration": Math.floor(duration),
            "unit": duration >= 2 ? "Hours" : "Hour"
        }
    } else if(timestamp/MILLIS_IN_ONE_MIN > 1) {
        const duration = timestamp/MILLIS_IN_ONE_MIN;
        return {
            "duration": Math.floor(duration),
            "unit": duration >= 2 ? "Mins" : "Min"
        }
    } else if(timestamp/MILLIS_IN_ONE_SEC > 1) {
        const duration = timestamp/MILLIS_IN_ONE_SEC;
        return {
            "duration": Math.floor(duration),
            "unit": duration >= 2 ? "Secs" : "Sec"
        }
    } else {
        return {
            "duration": timestamp,
            "unit": "ms"
        }
    }
}