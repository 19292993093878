/* eslint-disable react/prop-types */
// import React from "react";
import {
    Box, Table, TableCell,
    TableContainer,
    Paper,
    TableHead,
    TableRow,
    TableBody
} from "@mui/material";
import React, { useState, useRef, useEffect, useContext, useLayoutEffect } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Fade } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';

import { useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Button, ButtonGroup } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Skeleton } from '@mui/material';
import CampaignIcon from '@mui/icons-material/Campaign';
import DialogContent from '@mui/material/DialogContent';
import { EmployeeCard, GetNameAndEmpID } from "../../../../util/Loading";
import { getFromTaskBreaker } from './IncidentDataFetcher.js';

import Divider from '@mui/material/Divider';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { UserContext } from "../../../../contexts/UserContext";
import { incidents_inference_get_query_1 } from "../../../../catalystDB/queryGenerator.js";

function Inference(DeskID) {


    const [rows, setRows] = useState([]);
    const [contentLoaded, setContentLoaded] = useState(false);
    const { loginUserDept } = useContext(UserContext);

    useEffect(() => {
		async function run() {
            try {

                const result = await getFromTaskBreaker(incidents_inference_get_query_1(DeskID, loginUserDept));
    
                setRows(result);
    
                setContentLoaded(true);
    
            }
            catch (e) {
    
                setContentLoaded(null);
            }
		}
		run();
	}, []);

    if (contentLoaded === null) {
        return <Fade in={true}><h3 style={{ textAlign: "center" }}>No Inference Found :(</h3></Fade>;
    }
    if (!contentLoaded) {
        //return <div style={{textAlign: "center", transform: "scale(0.6)"}}><SpinningLoadingComponent /></div>;
        return <Skeleton variant="rectangular" width={"100%"} height={250} sx={{
            marginBottom: "10px",
            '&.MuiSkeleton-root': {
                transform: "none",
                animation: "none"
            }

        }} />;
    }

    let i = 0;

    return (
        <Box sx={{
            padding: "10px",
            paddingLeft: "0px",
            paddingRight: "0px"
        }}>

            <TableContainer component={Paper}>
                <Table  >
                    <TableHead>
                        <TableRow
                            className="tasklist-table-head"


                        >

                            <TableCell align="center">Particulars</TableCell>
                            <TableCell align="center">User Response</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {rows[0].map((item, index) => (
                            [2, 11, 15, 16, 17, 18, 19, 20].includes(index) ?


                                <Row key={index} item={item} index={i++} /> : ""

                        ))}
                    </TableBody>
                </Table>

            </TableContainer>
        </Box>
    );
};








function Row({ item, index }) {

    const [myList, setMyList] = useState(["DC Type", "Product", "Title", "Type", "Query Notes", "Session Type", "Module", "Priority"]);

    function getItemByIndex(index) {
        return myList[index];
    }
    const theme = useTheme();

    return (

        <TableRow

            sx={{
                '& > *': { borderBottom: 'unset' },
                backgroundColor: theme.palette.background.tableRow,
                '&:hover': {
                    backgroundColor: theme.palette.background.tableRowHover + " !important"
                }
            }}>
            <TableCell align="center" style={{ minWidth: "6vw", maxWidth: "10vw", wordWrap: "break-word", fontWeight: "bold" }}>
                {getItemByIndex(index)}
            </TableCell>
            <TableCell align="center" style={{ minWidth: "6vw", maxWidth: "10vw", wordWrap: "break-word" }}>
                {item}
            </TableCell>
        </TableRow>

    );
}



export default Inference;