import { isDateInRange, isDateString } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    const chartData = [];
    const buildCountMap = {};

    catalystDB.AllBuildDetails.map(item => {
        const dateString = new Date(item.CREATEDTIME).toISOString().split("T")[0];

        if(isDateString(dateString) && isDateInRange(dateString, startTime)) {
            if(dateString in buildCountMap) {
                buildCountMap[dateString] += 1;
            } else {
                buildCountMap[dateString] = 1;
            }
        }
    }); 

    var keys = Object.keys(buildCountMap);
    keys.sort((a, b) => {
        return new Date(a).getTime() < new Date(b).getTime() ? -1 : 1;
    });

    for(const key in buildCountMap) {
        chartData.push([
            key,
            buildCountMap[key]
        ]);
    }

    return chartData;
}