export function processData(data) {
    const d = [];
    const users_map = {};
    data.users.map(user => {
        users_map[user] = {
            "tasks": 0,
            "tickets": 0
        };
    });

    data.tasks.map(item => {
        const task = item.TaskBreaker;
        if([undefined, null].includes(task.DRI)) {
            return;
        }

        if(task.DRI in users_map) {
            users_map[task.DRI].tasks += 1;
        }
    });

    data.tickets.map(item => {

        if([undefined, null, "", " "].includes(item.Tickets.ClaimedBy_AssingedTo)) {
            return;
        }

        try {
            const ticket = item.Tickets;
            const email = ticket.ClaimedBy_AssingedTo.split(" ")[0];
    
            if([undefined, null].includes(email)) {
                return;
            }
    
            if(email in users_map) {
                users_map[email].tickets += 1;
            }
        } catch(e) {
            return;
        }
    });


    const tasks_obj = {
        "type": 10,
        "seriesname": "Tasks",
        "data": Object.keys(users_map).map(user => {
            return [
                user,
                users_map[user].tasks
            ]
        })
    };
    const tickets_obj = {
        "type": 10,
        "seriesname": "Tickets",
        "data": Object.keys(users_map).map(user => {
            return [
                user,
                users_map[user].tickets
            ];
        })
    };

    d.push(tasks_obj);
    d.push(tickets_obj);

    return d;
}