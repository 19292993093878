import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea } from '@mui/material';
import zone_img from "./ImageForMeassageBubble/zone.png";
import Grid from '@mui/material/Grid';


export function ChatBubble({ AllComments }) {
    // console.log(AllComments)
    const commentsArray = AllComments.split("###-->");
    // console.log(commentsArray)

    // Extract names and descriptions
    const dataValue = [];
    const names = [];
    const descriptions = [];

    for (let i = 0; i < commentsArray.length; i++) {
        // console.log(commentsArray[i])
        const commentData = commentsArray[i].split("###->")
        // console.log(commentData)
        names.push(commentData[0]);
        descriptions.push(commentData[1]);
    }
    // console.log(dataValue)
    // console.log("Names:", names);
    // console.log("Descriptions:", descriptions);

    return (
        <Card sx={{ 
            minHeight: 400,
            maxHeight: 900, 
            margin: 5, 
            boxShadow: 4, 
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start !important",
            justifyContent: "flex-start !important",
            // backgroundColor: "hsla(110, 47%, 10%, 0.91)",
            backgroundColor: "rgb(248,250,242)",
            gap: "20px",
            padding: "30px",
            boxSizing: "border-box",
            overflowY: "scroll",
            borderRadius: "10px"
        }} className='non-more-item'>
            
           
{names.map((name, index) => (
     <Box sx={{
        backgroundColor: "rgb(241,242,232)",
        padding: "20px 20px",
        boxSizing: "border-box",
        borderRadius: "20px",
        fontSize: "0.8rem",
        // color: "hsl(110, 57%, 95%)",
        border: "0.5px solid hsl(110, 57%, 5%)",
        // boxShadow: "1px 1px 10px hsl(110, 57%, 25%)",
        maxWidth:"500px",
        minwidth:"500px",
        alignItems: "flex-start !important",
        justifyContent: "flex-start !important"
    }}>
                <Box sx={{
                            fontWeight: "bold",
                            color: "rgb(67,72,64)",
                            // padding:"2px"
                        }}>
                            {name}
                </Box>
                <Box sx={{
                    fontSize: "0.65rem",
                    color: "rgb(113,117,109)"
                }}>
                   {descriptions[index]}
                </Box>
        </Box>
))}
            {/* <img src={zone_img} width={"100px"}/> */}
            {/* <Grid container spacing={2}>
                <Grid item>
                    <CardMedia
                        component="img"
                        style={{ maxWidth: '220px', maxHeight: '150px', marginLeft: '65px' }}
                        //   height="220"
                        image={zone_img}
                        alt="green iguana"
                    />
                </Grid>
                <Grid item>
                    <CardMedia
                        component="img"
                        style={{ maxWidth: '220px', maxHeight: '150px', marginLeft: '65px' }}
                        //   height="220"
                        image={zone_img}
                        alt="green iguana"
                    />
                </Grid>
            </Grid>
            <CardContent sx={{ maxHeight: '180px', overflowY: 'auto', marginRight: "270px" }}>

                {names.map((name, index) => (
                    <>
                        <Typography gutterBottom variant="h6" component="div">
                            {name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {descriptions[index]}
                        </Typography>
                    </>
                ))}
            </CardContent> */}
        </Card>
    );
}


{/* <CardContent sx={{ maxHeight: '180px', overflowY: 'auto' }}>
<Box sx={{
                backgroundColor: "rgb(241,242,232)",
                padding: "20px 30px",
                boxSizing: "border-box",
                borderRadius: "20px",
                fontSize: "0.8rem",
                color: "hsl(110, 57%, 95%)",
                border: "0.5px solid hsl(110, 57%, 5%)",
                boxShadow: "1px 1px 10px hsl(110, 57%, 25%)",
                maxWidth:"500px",
                minwidth:"500px",
                alignItems: "flex-start !important",
                justifyContent: "flex-start !important"
            }}>
{names.map((name, index) => (
    <>
        <Box sx={{
                    fontSize: "0.65rem",
                    fontWeight: "bold",
                    color: "rgb(96,136,77)",
                    // padding:"2px"
                }}>
                    Sharafath
                </Box>
                <Box sx={{
                    color: "rgb(96,136,77)"
                }}>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.


                </Box>
    </>
))}
            </Box>
</CardContent> */}

