import * as React from 'react';
import {
    Drawer,
    Divider,
    Box,
    Button,
    useTheme
} from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import styles from "../TicketsTable.module.css";
import { GetNameAndEmpID } from '../../../../../../util/Loading';
import { TICKET_STATUS } from '../MoreMenu';
import {ChatBubble} from './chatBubble'
import { format } from 'date-fns';

export function MoreViewIncident({data, open, setOpen, localStatus, localClaimedDRI, handleClaimButtonClick,row}) {
    // console.log("newRows-MoreViewIncident >>>>>>>>>" + row);

    const theme = useTheme();

    function handleClose(event) {
        if (event.type === 'keydown' && (event.key === 'Escape')) {
            setOpen(false);
        }
    }

    const d = sanitize_and_format(row, handleClaimButtonClick, localStatus, localClaimedDRI, theme);

    return (
        <Drawer
            anchor={'right'}
            open={open}
            onClose={handleClose}
            className={styles.more_view_drawer}
        >
            <TopSection row={d} handleClick={() => setOpen(false)}/>
            <Divider light style={{ margin: 0, width: "100%" }} />
            <MiddleSection row={row} />
            {/* <Divider light style={{ margin: 0, width: "100%" }} />
            <BottomSection data={d}/> */}
        </Drawer>
    );
}




function TopSection({row, handleClick}) {
    const theme = useTheme();

    return (
        <Box className={styles.more_view_top}>
        <Box className={`${styles.more_view_status_display} ${theme.palette.mode}`}>
            {row[27]}
        </Box>
        <Box className={styles.ticket_id}>
            {row[12]}
        </Box>
        <Button onClick={handleClick}>
            <CloseRoundedIcon fontSize='large'/>
        </Button>
    </Box>
    
    
    );
}
function formatDateTime1(dateTimeString, half) {
    // console.log("date")
    let date;
    if (typeof dateTimeString === 'string') {
        date = new Date(dateTimeString.slice(0, -4));
        if (half !== undefined) {
            date = new Date(dateTimeString);
        }
    } else {
        date = dateTimeString;
    }
    if (!isValidDate(date)) {
        return "";
    }

    const dateOptions = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', dateOptions);

    return (
        <Box className={`${styles.flex_column} ${styles.flex_left_align}`}>
             <Box sx={{ color: "#d13819", fontWeight: "bold" }}>  {isToday(date) ? "Today" : isYesterday(date) ? "Yesterday" : formattedDate} </Box>
        </Box>
    );
}

function formatDateTime(dateTimeString, half) {
    var date = new Date(dateTimeString.slice(0, -4));
    if(half !== undefined) {
        date = new Date(dateTimeString);
    }
    if(!isValidDate(date)) {
        return "";
    }

    const dateOptions = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', dateOptions);
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

    return (
        <Box className={`${styles.flex_column} ${styles.flex_left_align}`}>
            <Box> {isToday(date) ? "Today" : isYesterday(date) ? "Yesterday" : formattedDate} </Box>
            <Box sx={{ color: "#d13819", fontWeight: "bold" }}> {formattedTime} </Box>
        </Box>
    );
}

function MiddleSection({row}) {
    row = Array.from(row)
    // console.log("Immediate Moved" + row[28])
    // console.log("Permanent Moved" + row[29])
    return (
        <Box className={styles.more_view_middle}>
           
           <MoreItem
    left={`Created Time`}
    right={formatDateTime(row[21])}
/>

            <MoreItem left={`DRI`} right={<GetNameAndEmpID emails={[row[3]]} />} />
            <MoreItem left={`Products Affected`} right={row[11]} />
            <MoreItem left={`Root Cause`} right={row[25]} />
            <MoreItem left={`Approved By`} right={<GetNameAndEmpID emails={[row[24]]} />} />
            <MoreItem left={`Incident Tag`} right={row[26]} />
    {row[28] === "Yes" &&  row[29] === "Yes" ? (
        <>
            <MoreItem left={`Immediate Fix Notes`} right={row[30]} />
            <MoreItem left={`Permanent Fix Notes`} right={row[32]} />
           
        </>
    ) : (
        <></>
    )}
    {row[28] === "Yes" &&  row[29] === "No" ? (
        <>
           <MoreItem left={`Immediate Fix Notes`} right={row[30]} />
            <MoreItem left={`Permanent Fix Notes`} right={row[32]} />
            <MoreItem left={`Permanent Fix ETA`} right={row[33]} />
           
        </>
    ) : (
        <></>
    )}
    
    {row[28] === "No" &&  row[29] === "No" ? (
        <>
 <MoreItem left={`Immediate Fix Notes`} right={row[30]} />
<MoreItem left={`Immediate Fix ETA`}  right={formatDateTime1(new Date(row[31]), 'yyyy-MM-dd')}/>
<MoreItem left={`Permanent Fix Notes`} right={row[32]} />
<MoreItem left={`Permanent Fix ETA`}  right={formatDateTime1(new Date(row[33]), 'yyyy-MM-dd')}/>

           
        </>
    ) : (
        <></>
    )}
 {row.AllComments != null ? (
                 <ChatBubble AllComments={row.AllComments}></ChatBubble>
            ):(
                <></>
            )}
           
        </Box>
    );
}

function BottomSection({row}) {
    return (
        <Box className={styles.more_view_bottom}>
        </Box>
    );
}

function MoreItem({left, right}) {
    const theme = useTheme();

    return (
        <Box sx={{width: "99%"}}>
            <Box className={styles.more_view_item} >
                <Box className={styles.more_view_item_left} sx={{color: theme.palette.background.textSecondary}}>
                    {left}
                </Box>
                <Box className={styles.more_view_item_right + " nested-view-overflow-container-3"}>
                    {right}
                </Box>
            </Box>
            <Divider style={{ margin: 0 , width: "100%"}} />
        </Box>
    );
}

// util funcitons 
function sanitize_and_format(row, callback, localStatus, localClaimedDRI, theme) {
    var d = Object.assign({}, row);

    d.TicketID = isUnclean(d.TicketID) ? "-" : `#${d.TicketID}`;
    d.CREATEDTIME = isUnclean(d.CREATEDTIME) ? "-" : formatDateTime(d.CREATEDTIME);
    // d.description = isUnclean(d.Issue) && isUnclean(d.Query) ? "-" : (isUnclean(d.Issue) ? d.Query : d.Issue);
    d.Submitted_By = isUnclean(d.Submitted_By) ? "-" : <GetNameAndEmpID emails={d.Submitted_By} />;
    // d.DRI = (
    //     localClaimedDRI === null ?
    //         (
    //             isUnclean(d.ForwardedTo_CurrentHolder) && isUnclean(d.ClaimedBy_AssingedTo) && isUnclean(d.RejectedBy) ? "-" :
    //                 (
    //                     d.Status === TICKET_STATUS.REJECTED ?
    //                         <GetNameAndEmpID emails={d.RejectedBy.split(" ")[0]} />
    //                         :
    //                     isUnclean(d.ForwardedTo_CurrentHolder) ?
    //                         <GetNameAndEmpID emails={d.ClaimedBy_AssingedTo.split(" ")[0]} />
    //                         :
    //                         <GetNameAndEmpID emails={d.ForwardedTo_CurrentHolder} />
    //                 )
    //         )
    //         :
    //         <GetNameAndEmpID emails={localClaimedDRI} />
    // );
    const color = d.Priority_1 === "Normal" ? "#188a04" : d.Priority_1 === "High" ? "#d1870f" : "#d10f0f";
    const colorBackground = d.Priority_1 === "Normal" ? "#188a0422" : d.Priority_1 === "High" ? "#d1870f22" : "#d10f0f22";
    d.Priority_1 = isUnclean(d.Priority_1) ? "-" : 
        <Box sx={{borderRadius: "50px", border: `0.2px solid ${color}`, padding: "5px 5px", backgroundColor: colorBackground}}>
            <box style={{color: color, fontWeight: "bold", minWidth: 0}}>{d.Priority_1}</box>
        </Box>
    d.isPreClaim = (d.Status === TICKET_STATUS.TICKET_CREATED);
    d.StatusDisplay = localStatus !== null ?
        localStatus
        : (
            isUnclean(d.Status) ?
                "-"
                :
                d.Status
        );
    d.Status = localStatus !== null ?
        localStatus
        :
        (
            isUnclean(d.Status) ?
                "-"
                :
                (
                    d.Status === TICKET_STATUS.TICKET_CREATED ?
                        <Button className={styles.claim_button} size='small' variant='contained' onClick={callback} >Claim</Button>
                        :
                        d.Status
                )
        );

    d.TicketID = isUnclean(d.TicketID) ?
        (
            <Box className={`${styles.flex_center_align} ${styles.desk_link}`}>
                <a href={d.DeskTicketURL} target='_blank'>
                    {d.DeskTicketNumber}
                </a>
            </Box>

        )
        :
        (
            <Box className={`${styles.flex_center_align} ${styles.gap_small}`}>
                <Box className={styles.desk_link}>
                    <a href={d.DeskTicketURL} target='_blank'>
                        {d.DeskTicketNumber}
                    </a>
                </Box>
                <Box className={`${styles.light_chip} ${styles[theme.palette.mode]}`} sx={{ color: "#d13819" }}> {d.TicketID} </Box>
            </Box>
        );
    d.Product_Type = isUnclean(d.Product_Type) ? "-" : d?.Product_Type?.replaceAll("_", "-");
    d.contributors = isUnclean(d.AllDevelopers) ? "-" : (
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "10px"}}>
            {
                // removeDuplicatesAndConvertToArray(d.AllDevelopers).map(dev => {
                //     return <GetNameAndEmpID emails={dev} />
                // })
                <GetNameAndEmpID emails={removeDuplicatesAndConvertToArray(d.AllDevelopers).join(",")} />
            }
        </Box>
    );
    d.resolvedBy = isUnclean(d.Closed_By) ? "-" : <GetNameAndEmpID emails={d?.Closed_By?.split(" ")[0]} />;
    d.resolvedOn = isUnclean(d.Closed_By) ? "-" : formatDateTime(d?.Closed_By, true);
    d.forwardHistory = isUnclean(d.ForwardHistory) ? "-" : getHistory(d.ForwardHistory);
    d.resolutionGiven = d.Type === "Issue" ? d.IssueResolutionGiven : d.QueryResolutionGiven;
    try {
        d.RejectedOn = formatDateTime(d.RejectedBy);
    } catch(e) {
        d.RejectedOn = "-";
    }
    try {
        d.RejectedBy = <GetNameAndEmpID emails={d.RejectedBy.split(" ")[0]} />;
    } catch(e) {
        d.RejectedBy = "-";
    }
    try {
        d.ReasonForRejecting = d.ReasonForRejecting;
    } catch(e) {
        d.ReasonForRejecting = "-";
    }

    return d;
}

function isUnclean(item) {
    return [undefined, null, NaN, "", " ", "-"].includes(item);
}



function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

function formatDateTime_(dateTimeString) {
    const date = new Date(dateTimeString.slice(0, -4));
    const dateOptions = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', dateOptions);
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

    return (
        <Box className={`${styles.flex_column} ${styles.flex_right_align}`} sx={{minWidth: "70px !important"}}>
            <Box> {isToday(date) ? "Today" : isYesterday(date) ? "Yesterday" : formattedDate} </Box>
            <Box sx={{ color: "#d13819", fontWeight: "bold" }}> {formattedTime} </Box>
        </Box>
    );
}

function getHistory(hist) {
    try {
        const hist_items = hist.split("-----------------------");
        var arr = hist_items.map(item => {
            if(item === "") {
                return;
            }
            try {
                return [
                    item.split("###")[0]?.split("=>>")[0]?.trim(),
                    item.split("###")[0]?.split("=>>")[1]?.trim(),
                    item.split("###")[0]?.split("=>>")[2]?.trim(),
                    item.split("###")[1]?.trim().replaceAll("\\n", "").replaceAll("\n", "")
                ];
            } catch(e) {
                return;
            }
        });
        arr = arr.filter(item => item !== undefined);
        return (
            <Timeline
                sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.2,
                    },
                }}
            >
                {
                    arr.map(item => {
                        return (
                            <TimelineItem>
                                <TimelineOppositeContent color="textSecondary">
                                    {formatDateTime_(item[3])}
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Box sx={{minHeight: "100px !important", gap: "10px", display: "flex", flexDirection: "column", wordWrap: "break-word" }}>
                                        <GetNameAndEmpID emails={item[2]} />
                                        {item[1]}
                                    </Box>
                                </TimelineContent>
                            </TimelineItem>
                        );
                    })
                }
            </Timeline>
        );
        return arr;
    } catch(e) {
        return "-";
    }
}

function removeDuplicatesAndConvertToArray(allDevelopers) {
    try {
        var arr = allDevelopers.split(",");
        const map = {"":true, " ": true, undefined: true, null: "true"};

        arr = arr.filter(item => {
            item = item.trim();
            if(item in map) {
                return false;
            } else {
                map[item] = true;
                return true;
            }
        });

        return arr;
    } catch(e) {
        return [];
    }
}

function isToday(someDate) {
    const today = new Date();
    return (
        someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
    );
}

function isYesterday(someDate) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return (
        someDate.getDate() === yesterday.getDate() &&
        someDate.getMonth() === yesterday.getMonth() &&
        someDate.getFullYear() === yesterday.getFullYear()
    );
}