import { isDateInRange, isDateString } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    const chartData = [];
    const ticketsCountMap = {};

    catalystDB.Tickets.map(item => {
        const dateString = item.CREATEDTIME;

        if(isDateString(dateString) && isDateInRange(dateString, startTime)) {
            const server = item.Product_Type;

            if([undefined, null, "", " "].includes(server)) {
                return;
            }
    
            if(server in ticketsCountMap) {
                ticketsCountMap[server]++;
            } else {
                ticketsCountMap[server] = 1;
            }
        }
    }); 

    for(const key in ticketsCountMap) {
        chartData.push([
            key,
            ticketsCountMap[key]
        ]);
    }

    return chartData;
}