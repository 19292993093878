import React, { useState, useEffect, useRef, useLayoutEffect, useContext } from 'react';
export function Checklist() {
    
    
return (
             
                                                                                                                                                                                                                                                                                                                     
   
  <div className="page-content-2">
            <iframe
               src="https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/CheckLists_for_Build_Upgrade?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true"
               width="100%"
                title="External Content"
                frameBorder={0}
                style={{
                    flex: 1
                }}
            />
        </div>
                
             
          );
      }
