/* eslint-disable react/prop-types */ 
/* esline-disable react/jsx-key */
import React from "react";
import { useEffect, useRef, useState, useContext } from "react";
import { UserContext } from "../../../../../contexts/UserContext";
import { useTheme } from '@mui/material/styles';
import { Box, ButtonGroup, Skeleton, Tab } from "@mui/material";
import { ReportContext } from "../newReportGeneral/NewReport";
import { getMilestoneRows, getTicketRows } from "../reportSection1/ReportSection1DataFetcher"; 

import AirIcon from '@mui/icons-material/Air';
import { PieChart, SunBurst } from "../newReportGeneral/NewReportUtilComponents";
import { PartialSunburst } from "../newReportGeneral/NewReportUtilComponents";
import { isValidDate } from "../newReportGeneral/NewReportsUtil";

export function ReportSection11() {
   
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            width: "100%",
            height: "98%",
            gap: "10px"
        }}>
             <Box sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.1rem",
                fontWeight: "bold"
            }}>
                Ticket Status
            </Box>
            <ReportBody/>
        </Box>
       
    );
}   


function ReportBody({setExpanded}) {

    const {
        subjectType, 
        setSubjectType, 
        subject, 
        setSubject, 
        timeFrame, 
        setTimeFrame, 
        time, 
        setTime,
        timeRange,
        setTimeRange
    } = useContext(ReportContext);
    const theme = useTheme();
    const chartContainer = useRef();
    
    const [loaded, setLoaded] = useState(undefined);
    // const [milestoneRows, setMilestoneRows] = useState(undefined);
    const [chartData, setChartData] = useState(undefined);
    const {teamDetails, loginUserDept} = useContext(UserContext);

    useEffect(() => {
        const controller = new AbortController();
        async function pullMilestoneRows() {

            getTicketRows(timeRange, subjectType, subject, teamDetails, loginUserDept).then((response) => {
                if(!controller.signal.aborted) {
                    if(response.length !== 0) {
                        const statusMap = {};
                        const data = [];
                        var open_onSla = 0;
                        var open_pastSla = 0;
                        response.forEach(item => {
                            if(item.Status === "Open") {
                                if (![undefined, null, "", " "].includes(item.ResolvedOn)) {
                                    try {
                                        const s_date = new Date(item.CREATEDTIME);
                                        const e_date = new Date(item.ResolvedOn);

                                        if (isValidDate(s_date) && isValidDate(e_date)) {
                                            if (dateDiffInDays(s_date, e_date) > 2) {
                                                open_pastSla += 1;
                                            } else if (dateDiffInDays(e_date, s_date) >= 0) {
                                                open_onSla += 1;
                                            }
                                        }

                                    } catch (e) { }
                                }
                            } else {
                                if(item.Status === null) {
                                    return;
                                }
                                if(item.Status in statusMap) {
                                    statusMap[item.Status] += 1;
                                } else {
                                    statusMap[item.Status] = 0;
                                }
                            }
                        });

                        for(var key in statusMap) {
                            // data.push([key, statusMap[key]]);
                            data.push({
                                "seriesname": key,
                                "data": ["", statusMap[key]]
                            });
                        }

                        data.push(
                            {
                                "data": [
                                    [
                                        "On SLA",
                                        open_onSla
                                    ],
                                    [
                                        "Past SLA",
                                        open_pastSla
                                    ]
                                ],
                                "seriesname": "Open"
                            }
                        );

                        setChartData(data);
                        setLoaded(true);
                    } else {
                        setLoaded(false);
                    }
                }
            }).catch((error) => {
                if(!controller.signal.aborted) {
                    setLoaded(false);
                }
            });
        }

        setLoaded(undefined);
        pullMilestoneRows();

        return () => {
            controller.abort();
        };

    }, [timeRange, subjectType,subject]);

    if(loaded === undefined) {
        return (
            <Skeleton variant={"rounded"} height={"98%"} width={"100%"} animation="wave" />
        );
    } else if(loaded === false) {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%"
            }}>
                <Box>
                    <AirIcon sx={{
                        fontSize: "6rem",
                        color: theme.palette.mode === "light" ? "rgb(20, 20, 20, 0.06)": "rgb(200, 200, 200, 0.06)"
                    }} />
                </Box>
                <Box sx={{
                    fontSize: "0.7rem",
                    color: theme.palette.mode === "light" ? "rgb(10, 10, 10, 0.3)": "rgb(200, 200, 200, 0.4)"
                }}>
                    Nothing found
                </Box>
            </Box>
        );
    } else {
        return (
            <Box  sx={{
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                overflowY: "hidden",
                boxSizing: "border-box",
                backgroundColor: theme.palette.mode === 'dark' ? 'rgb(24,30,35)' : '#FAFAFA',
                borderRadius: "12px"
            }}
            ref={chartContainer}
            >
                {/* <PieChart title={""} columnName1={"col1"} columnName2={"col2"} data={chartData} palette={"countries"}/> */}
                <SunBurst title={""} data={chartData} palette={"countries"} />
                {/* <PartialSunburst data={chartData} chartRef={chartContainer} /> */}
            </Box>
        );
    }
}

function dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}