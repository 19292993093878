/* eslint-disable react/prop-types */
/* eslint-disable dot-notation */
import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { formatDate } from '../../../../util/Loading';
import { GetNameAndEmpID } from '../../../../util/Loading';
import CloseIcon from '@mui/icons-material/Close';
import { queryDB } from '../../../../util/QueryDB';

import {
    Box,
    Button,
    Skeleton
} from '@mui/material';
import { UserContext } from '../../../../contexts/UserContext';
import { builds_build_status_component_get_query_1 } from '../../../../catalystDB/queryGenerator';

var cache = {};

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)'
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4'
        }
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4'
        }
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1
    }
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4'
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor'
    }
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

function QontoStepIncompleteIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <CloseIcon className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}
  
// const steps = [
//     ['CSEZ', "manoj.sakthivel@zohocorp.com", new Date()],
//     ['LOCAL', "karthick.tn@zohocorp.com", new Date()],
//     ['JP', "karthick.masilamani@zohocorp.com", new Date()],
//     ['UK', 'vijayshankar.a@zohocorp.com', new Date()],
//     ['US', 'manoj.sakthivel@zohocorp.com', new Date()],
//     ['CN', 'manoj.sakthivel@zohocorp.com', new Date()],
//     ['CA', 'manoj.sakthivel@zohocorp.com', new Date()]
// ];

export default function BuildStatus({setActiveTab, Server, BuildLabel, row}) {
    const [loaded, setLoaded] = useState(undefined);
    const [activeStep, setActiveStep] = useState(-1);
    const {teamDetails, loginUserDept, configuration} = useContext(UserContext);

    const [dcOrder, setDcOrder] = useState([]);

    useEffect(() => {
		async function run() {
			  //  console.log(row);
        // console.log(row);
        // console.log(extractData(row.AllBuildDetails));
        try {
            const status = extractData(row.AllBuildDetails, configuration);
            const queryString = builds_build_status_component_get_query_1(Server, loginUserDept);
            // if(queryString in cache) {
            //     setActiveStep(cache[queryString].activeStep);
            //     setDcOrder(cache[queryString].data);
            //     setLoaded(true);
            //     return;
            // }
            const result = await queryDB(queryString);
            //console.log(result);

            const ar = Array.from({ length: 100 }, () => -1);
            const arr = [];

            for(var key in result[0].DCConfiguration) {
                if(["CREATEDTIME", "CREATORID", "MODIFIEDTIME", "ROWID"].includes(key)) {
                    continue;
                }
                else {
                    try {
                        const value = parseInt(result[0].DCConfiguration[key], 10);
                        if(value <= 0) {
                            continue;
                        }
                        ar[value] = key;
                   
                    } catch(e) {}
                }
            }

            var index = 0;
            var max = -1;
            for(const item of ar) {
                if(item !== -1 && item !== undefined && item !== null) {
                    // if(item === "JP1") {
                    //     arr.push([item]);
                    // }
                    if(item in status) {
                        var arrr = [item];
                        try {
                            const details = status[item].data;
                            if(details["Reverted By"] !== undefined && details["Reverted By"] !== null) {
                                if(details["Reverted By"] !== undefined && details["Reverted By"] !== null) {
                                    arrr.push(details["Reverted By"]+"@zohocorp.com");
                                } else {
                                    arrr.push("-");
                                }
                                if(details["Reverted On"] !== undefined && details["Reverted On"] !== null) {
                                    try {
                                        arrr.push(new Date(details["Reverted On"]));
                                    } catch(e) {
                                        arrr.push(null);
                                    }
                                } else {
                                    arrr.push(null);
                                }
                                arrr.push("reverted");
                            }
                            else if(details["Assigned To"] !== undefined && details["Assigned On"] !== null && details["Upgraded By"] == null) 
                            {
                                if(details["Assigned To"] !== undefined && details["Assigned To"] !== null) {
                                    arrr.push(details["Assigned To"]);
                                } else {
                                    arrr.push("-");
                                }
                                if(details["Assigned On"] !== undefined && details["Assigned On"] !== null) {
                                    try {
                                        arrr.push(new Date(details["Assigned On"]));
                                    } catch(e) {
                                        arrr.push(null);
                                    }
                                } else {
                                    arrr.push(null);
                                }
                                arrr.push("Assigned To");
                            }
                            else {
                                if(details["Upgraded By"] !== undefined && details["Upgraded By"] !== null) {
                                    arrr.push(details["Upgraded By"]+"@zohocorp.com");
                                } else {
                                    arrr.push("-");
                                }
                                if(details["Upgraded On"] !== undefined && details["Upgraded On"] !== null) {
                                    try {
                                        arrr.push(new Date(details["Upgraded On"]));
                                    } catch(e) {
                                        arrr.push(null);
                                    }
                                } else {
                                    arrr.push(null);
                                }
                                arrr.push("upgraded");
                             
                            } 
                            // else {
                            //     if(details["Assigned By"] !== undefined && details["Assigne By"] !== null) {
                            //         arrr.push(details["Assigned By"]);
                            //     } else {
                            //         arrr.push("-");
                            //     }
                            //     if(details["Assigned On"] !== undefined && details["Assigned On"] !== null) {
                            //         try {
                            //             arrr.push(new Date(details["Assigned On"]));
                            //         } catch(e) {
                            //             arrr.push(null);
                            //         }
                            //     } else {
                            //         arrr.push(null);
                            //     }
                            //     arrr.push("assigned");
                            // }
                            max = index;
                        } catch(e) {}
                        arr.push(arrr);
                    } else {
                        arr.push([item]);
                    }
                }
                index++;
            }

            // cache[queryString] = {
            //     "data": arr,
            //     "activeStep": max
            // };
            setActiveStep(max);
            setDcOrder(arr);
            setLoaded(true);
        } catch (error) {
            // console.log(error);
            setLoaded(false);
        }
		}
		run();
	}, []);

    if(loaded === false) {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center"
            }}>
                --- Nothing found ---
            </Box>
        );
    } else if(loaded === undefined) {
        return (
            <Skeleton component={"Rectangular"} height={"50px"}/>
        );
    }

    return (
        <Box sx={{
            width: "1000px"
        }}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                {dcOrder.map((item, index) => (
                    <Step key={item}>
                        <StepLabel StepIconComponent={
                            item.length === 1 ?
                                QontoStepIncompleteIcon
                                :
                                QontoStepIcon
                        }>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "20px",
                                paddingRight: "20px",
                                paddingLeft: "20px"
                            }}>
                                <Box>
                                    {item[0]}
                                </Box>
                                {
                                    index < activeStep && item.length !== 1 ? 
                                        <Box sx={{
                                            minWidth: "100px",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: "15px"
                                        }}>
                                            {
                                                item[3] !== undefined && item[3] !== null ?
                                                    <Box sx={{
                                                        color: item[3] === "reverted" ? "crimson" : "foresetgreen",
                                                        fontSize: "0.8rem"
                                                    }}>
                                                        
                                                        {item[3]}
                                                    </Box>
                                                    :
                                                    null
                                            }
                                            <Box>
                                                {
                                                    isValidEmail(item[1], teamDetails) ?
                                                        <GetNameAndEmpID emails={item[1]} />
                                                        :
                                                        item[1] != undefined && item[1] != null ?
                                                            item[1].split("@")[0]
                                                            :
                                                            item[1]
                                                }
                                            </Box>
                                            <Box>
                                                {
                                                    item[2] !== null ?
                                                        (formatDate(item[2]))
                                                        :
                                                        "-"
                                                }
                                            </Box>
                                            <Box>
                                                <Button variant='contained' sx={{
                                                    fontSize: "10px",
                                                    borderRadius: "50px"
                                                }} onClick={() => setActiveTab(item[0])}>
                                                    View QA
                                                </Button>
                                            </Box>
                                        </Box>
                                        :
                                        <Box sx={{
                                            minWidth: "110px"
                                        }}>
                                        </Box>
                                }
                            </Box>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}

export function extractData(obj, configuration) {
    const dcMap= configuration.current[`build.DC_columnName_map`];
    // const dcMap = {
    //     "AR1": "AR1_Data",
    //     "AR2": "AR2_Data",
    //     "AU1": "AU1_Data",
    //     "AU2": "AU2_Data",
    //     "CA1": "CA1_Data",
    //     "CA2": "CA2_Data",
    //     "CN2": "CN2_Data",
    //     "CN3": "CN3_Data",
    //     "CSEZMain": "CSEZ_main_Data",
    //     "CSEZPre": "CSEZ_pre_Data",
    //     "CT2": "CT2_main",
    //     "EU1": "EU1_Data",
    //     "EU2": "EU2_Data",
    //     "IN1": "IN1_Data",
    //     "IN2": "IN2_Data",
    //     "JP1": "JP1_Data",
    //     "JP2": "JP2_Data",
    //     "LOCALMain": "LOCAL_main_Data",
    //     "LOCALPre": "LOCAL_pre_Data",
    //     "UBI1": "UBI1_Data",
    //     "UBI2": "UBI2_Data",
    //     "UK1": "UK1_Data",
    //     "UK2": "UK2_Data",
    //     "US3": "US3_main_Data",
    //     "US4": "US4_main_Data",
    //     "US3PRE": "US3_pre_Data",
    //     "US4PRE": "US4_pre_Data"
    // };
    var result = {};
    for(const key in dcMap) {
        const value = dcMap[key];
        if(obj[value] !== null && obj[value] !== undefined) {
            try {
                result[key] = JSON.parse(obj[value]);
            } catch(e) {}
        }
    }
    // if(obj.CSEZ_mainData !== null && obj.CSEZ_mainData !== undefined) {
    //     try {
    //         result["CSEZ"] = JSON.parse(obj.CSEZ_mainData);
    //     } catch(e) {}
    // }
    // if(obj.LOCAL_mainData !== null && obj.LOCAL_mainData !== undefined) {
    //     try {
    //         result["LOCAL"] = JSON.parse(obj.LOCAL_mainData);
    //     } catch(e) {}
    // }
    // if(obj.JP1Data !== null && obj.JP1Data !== undefined) {
    //     try {
    //         result["JP1"] = JSON.parse(obj.JP1Data);
    //     } catch(e) {}
    // }
    // if(obj.JP2Data !== null && obj.JP2Data !== undefined) {
    //     try {
    //         result["JP2"] = JSON.parse(obj.JP2Data);
    //     } catch(e) {}
    // }
    return result;
}

function isValidEmail(email, teamDetails) {
    if(email in teamDetails.current) {
        return true;
    } else {
        return false;
    }
}