import { daysBetween, isDateString } from "../../util/ReportUtil";

export function processData(data) {
    const d = {
        "milestone_summary": undefined,
        "tasks_priority": undefined,
        "tasks_on_time_vs_delayed": undefined,
        "builds_associated": undefined,
        "user_contribution": undefined,
        "server_shares": undefined
    };

    // console.log(data);

    d.milestone_summary = get_milestone_summary(data);
    d.tasks_priority = get_tasks_priority(data);
    d.tasks_on_time_vs_delayed = get_tasks_on_time_vs_delayed(data);
    d.builds_associated = get_builds_associated(data);
    d.user_contribution = get_user_contribution(data);
    d.server_shares = get_server_shares(data);

    // console.log(d);

    return d;
}

export function get_milestone_summary(data) {
    const d = {
        "duration": {
            "count": "-", // 16 days
            "unit": "days",
            "result": undefined, // late | early | sharp
            "result_days": undefined, // 5 => late by 5 days
            "start_date": "-",
            "due_date": "-",
            "completion_date": "-"
        },
        "total_tasks": "-", // 32
        "total_tasklists": "-", // 23
        "collaborators": [] // ["user_1_mail", "user_2_mail"]
    };

    if(data.TaskDetails === undefined || data.TaskDetails.length === 0) {
        return d;
    } 

    d.total_tasklists = data.TaskDetails.length;

    const milestone_data = data.TaskDetails[0];

    if(isDateString(milestone_data.MilestoneStartDate_) && isDateString(milestone_data.MilestoneDueDate_) && isDateString(milestone_data.MilestoneCompletionDate)) {
        const start_date = new Date(milestone_data.MilestoneStartDate_);
        const due_date = new Date(milestone_data.MilestoneDueDate_);
        const completion_date = new Date(milestone_data.MilestoneCompletionDate);

        d.duration.count = daysBetween(completion_date, start_date);

        const extra_days = daysBetween(due_date, completion_date);

        if(extra_days === 0) {
            d.duration.result = "sharp";
            d.duration.result_days = 0;
        } else if(extra_days > 0) {
            d.duration.result = "early";
            d.duration.result_days = extra_days;
        } else {
            d.duration.result = "late";
            d.duration.result_days = -1 * extra_days;
        }
    }

    if(isDateString(milestone_data.MilestoneStartDate_)) {
        d.duration.start_date = new Date(milestone_data.MilestoneStartDate_);
    }

    if(isDateString(milestone_data.MilestoneDueDate_)) {
        d.duration.due_date = new Date(milestone_data.MilestoneDueDate_);
    }

    if(isDateString(milestone_data.MilestoneCompletionDate)) {
        d.duration.completion_date = new Date(milestone_data.MilestoneCompletionDate);
    }

    const collaboratorsMap = {};

    data.TaskDetails.map(item => {
        if([undefined, null, "", " "].includes(item.DRI)) {
            return;
        }
        if(!(item.DRI in collaboratorsMap)) {
            collaboratorsMap[item.DRI] = true;
        }
    });

    if(data.TaskBreaker !== undefined && data.TaskBreaker.length !== 0) {
        data.TaskBreaker.map(item => {
            if([undefined, null, "", " "].includes(item.DRI)) {
                return;
            }
            if(!(item.DRI in collaboratorsMap)) {
                collaboratorsMap[item.DRI] = true;
            }
        })
        d.total_tasks = data.TaskBreaker.length;
    }

    d.collaborators = Object.keys(collaboratorsMap);

    return d;
}

export function get_tasks_priority(data) {
    const d = [];
    var high = 0;
    var low = 0;
    var medium = 0;
    var none = 0;

    if(data.TaskBreaker !== undefined && data.TaskBreaker.length !== 0) {
        data.TaskBreaker.map(item => {
            if([undefined, null, "", " "].includes(item.TaskPriority)) {
                return;
            }
            try {
                switch(item.TaskPriority) {
                    case "High": 
                        high++;
                        break;
                    case "Medium": 
                        medium++;
                        break;
                    case "Low": 
                        low++;
                        break;
                    default: 
                        none++;
                }
            } catch(e) {
            }
        });

        d.push(["High", high]);
        d.push(["Medium", medium]);
        d.push(["Low", low]);
        d.push(["None", none]);
    }

    return d;
}

export function get_tasks_on_time_vs_delayed(data) {

    const d = [];
    var on_time = 0;
    var delayed = 0;

    if(data.TaskBreaker !== undefined && data.TaskBreaker.length !== 0) {
        data.TaskBreaker.map(item => {
           if(isDateString(item.TaskDueDate_) && isDateString(item.TaskCompletionDate)) {
                const start_date = new Date(item.TaskDueDate_);
                const end_date = new Date(item.TaskCompletionDate);

                if(daysBetween(end_date, start_date) <= 0) {
                    on_time++;
                } else {
                    delayed++;
                }
           }
        });

        d.push([
            "On Time",
             on_time
        ]);

        d.push([
            "Delayed",
            delayed
        ]);

    }

    return d;
}

export function get_builds_associated(data) {
    const d = [];

    if(data.AllBuildDetails !== undefined && data.AllBuildDetails.length !== 0) {
        data.AllBuildDetails.map(item => {
            if(![undefined, null, "", " "].includes(item.BuildLabel)) {
                d.push({
                    "build_label": item.BuildLabel,
                    "build_url": item.BuildURL
                });
            }
        });
    }

    return d;
}

export function get_user_contribution(data) {
    const d = [];
    const contributors = {};

    if(data.TaskBreaker !== undefined && data.TaskBreaker.length !== 0) {
        data.TaskBreaker.map(item => {
            if([undefined, null, "", " "].includes(item.DRI)) {
                return;
            }

            if(item.DRI in contributors) {
                contributors[item.DRI] += 1;
            } else {
                contributors[item.DRI] = 1;
            }
        });

        for(var email in contributors) {
            var count = contributors[email];
            d.push([
                email,
                count
            ]);
        }

    }

    return d;
}

export function get_server_shares(data) {
    const d = {};
    const server_map = {};

    if(data.TaskDetails !== undefined && data.TaskDetails.length !== 0) {
        data.TaskDetails.map(item => {
            if(![undefined, null, "", " "].includes(item.Component)) {
                if(item.Component in server_map) {
                    server_map[item.Component] += 1;
                } else {
                    server_map[item.Component] = 1;
                }
            }
        });

        var total =  Object.values(server_map).reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
        }, 0);

        for(const server in server_map) {
            const count = server_map[server];
            if(count !== 0) {
                d[server] = count/total; 
            }
        }
    }

    return d;
}