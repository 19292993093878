import React from "react";

function ServerConfiguration() {
    return (
        <div className="page-content-2">
            <iframe
                src="https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/Server_DataBase?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true"
                width="100%"
                title="External Content"
                frameBorder={0}
                style={{
                    flex: 1
                }}
            />
        </div>
    );
}

export default ServerConfiguration;