import React, { useContext, useEffect } from 'react';
import './PageBody.css';
import SideBar from './sideBar/SideBar.js';
import PageContent from './pageContent/PageContent.js';
import {useState} from 'react';
import {useTheme} from '@mui/material/styles';
import { OuterSideBar } from './sideBar/SideBar.js';
import { TabDetails, TabStateContext } from './../contexts/TabStateContext.js';


import { Box } from '@mui/material';
import { UserContext } from '../contexts/UserContext';

function PageBody()
{
	const theme = useTheme();
	const { userDetails, loginUserEmail, teamDetails } = useContext(UserContext);
	const loginUserRole = teamDetails.current[loginUserEmail.current].Role;
	const betaUser = teamDetails.current[loginUserEmail.current].BetaUser;
	const { tabState } = useContext(TabStateContext);
	const selectedOuterTab = tabState.current.outer;
	const isFullTab = TabDetails[selectedOuterTab].index < 0;

	// useEffect(() => {
	// 	if(isFullTab) {
	// 		return;
	// 	}
	// 	const userEmail = userDetails.current.auth.email_id;
	// 	const userRole = teamDetails.current[userEmail].BetaUser;
	// 	const outerSideBar = document.getElementsByClassName("outer-side-bar")[0];
	// 	// const innerSideBar = document.getElementsByClassName("inner-side-bar")[0];

	// 	outerSideBar.style.display = "block";
	// 	// innerSideBar.style.left = "50px";
	// 	// innerSideBar.style.width = "200px";
	// }, [isFullTab]);

	return (
		<>
		<Box className="page-body"
			style={{backgroundColor: theme.palette.background.pageContent}}
		>
			<SideBar />
			{/* <OuterSideBar loginUserRole={loginUserRole} betaUser={betaUser} loginUserEmail={loginUserEmail}/>  */}
			<PageContent />
		</Box>
		{/* <Box 
		className = "page-body-outertab"
			style={{
				backgroundColor: theme.palette.background.pageContent,
				backgroundColor:"red"
			}}
		>
		<OuterSideBar loginUserRole={loginUserRole} betaUser={betaUser} loginUserEmail={loginUserEmail}/>
		</Box> */}
		</>
		
	);
}

export default PageBody;