/* eslint-disable react/prop-types */

import React, { useContext, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { CircularProgress } from "@mui/material";
import { process } from "./dataProcessor";
import { fetchData } from "./dataFetcher";
import { UserContext } from "../../../../../contexts/UserContext";

import { LineChartReact } from '../../../../../util/components/LineChart/LineChart';
import { TASK_LINE_CHART_COLORS_DARK, TASK_LINE_CHART_COLORS_LIGHT } from "../../../dashboard/dashboardMain/DashboardUtil";

export function OnTime_VS_Delayed_Tasks({startTime, endTime, view, selection}) {
	const theme = useTheme();
	const [data, setData] = useState(undefined);
	const teamDetails = useContext(UserContext);
	const {loginUserDept} = useContext(UserContext);

	var colors = ["#167016", "#9e2820"];
    if(theme.palette.mode === "dark") {
        colors = [ "#308c30", "#cc3f35"];
    }

	useEffect(() => {
		setData(undefined);
		fetchData(startTime, endTime, view, selection, teamDetails, loginUserDept).then((result) => {
			setData(process(result));
		}).catch(error => {
			setData(null);
		});
	}, [startTime, endTime, view, selection]);

	return (
		<Box sx={{
			width: "100%",
			height: "100%",
			justifyContent: "top"
		}}>
			<Box style={{
				fontWeight: 600,
				fontSize: "1rem",
				color: theme.palette.mode === "light" ? "black" : "white"
			}}>
				<span style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "left",
					flexDirection: "row"
				}}>
					<span style={{
						color: "forestgreen"
					}}>
						On Time
					</span>&nbsp;vs&nbsp;
					<span style={{
						color: "crimson"
					}}>
						Delayed 
					</span>
					&nbsp;Completed Tasks
				</span>
			</Box>
			{
				data === undefined ?
					<Box style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: "100%"
					}}>
						<CircularProgress />
					</Box>
					:
					data === null || (data[0].data.length === 0 && data[1].data.length === 0) ? 
						<Box style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							height: "100%"
						}}>
							<p>no completed tasks found in this time range</p>
						</Box>
						:
						<Box style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							height: "100%"
						}}>
							<LineChartReact data={data}
								title={""}
								columnName1={"Date"}
								columnName2={"Completed"}
								colors={colors}
								open={true}
								full={true}
								stacked={true} 
								type={"line"}
							/>
						</Box>
			}
		</Box>
	);
}
