/* eslint-disable react/prop-types */

import React, { createContext, useContext, useState } from 'react';

export const Dashboard_2_Context = createContext();

export function Dashboard_2_ContextProvider({ children }) {

    // outer states
    

    // confs
     const DASHBOARD_GRID_ITEMS = {
        "GridItem_2": {}
    };

    // dashboard states
    const [currentSelectedGridItem, setCurrentSelectedGridItem] = useState(null);

    // setters
    function changeCurrentSelectedGridItem(gridItem) {
        setCurrentSelectedGridItem(gridItem);
    }
    
    // getters
    

    // context provider
    return (
        <Dashboard_2_Context.Provider value={{
            currentSelectedGridItem,
            changeCurrentSelectedGridItem
        }}>
            {children}
        </Dashboard_2_Context.Provider>
    );
}
