import React, {useContext, useState} from "react";

import { 
    Dialog,
    DialogTitle,
    Button,
    Divider,
    Menu,
    MenuItem
} from "@mui/material";
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';

import { EmployeeCard } from "../../../../../../util/Loading";
import { UserContext } from "../../../../../../contexts/UserContext";
import styles from "../TicketsTable.module.css";
import { assign } from "../preClaimActions";

export function AssignDRI({data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, setExtraComps, setLocalClaimedDRI, setLocalStatus}) {

    const { teamDetails,loginUserEmail } = useContext(UserContext);

    var currentLoginUser = loginUserEmail.current;
    var users = teamDetails.current;
    var allUsers = Object.keys(users).sort();
    allUsers = allUsers.filter((item) => { return item !== "" });
    
    const [dri, setDri] = useState(allUsers[0]);

    function handleClose() {
        setExtraComps(null);
    }

    function handleButtonClick() {
        assign(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, setExtraComps, setLocalClaimedDRI, setLocalStatus, dri,currentLoginUser);
        setExtraComps(null);
    }

    return (
        <Dialog onClose={handleClose} open={true}>
            <DialogTitle>Choose Assignee</DialogTitle>
            <Divider />
            <DRIDropDown dri={dri} setDri={setDri} allUsers={allUsers} />
            <Button variant="contained" onClick={handleButtonClick}>Assign</Button>
        </Dialog>
    );
}

function DRIDropDown({dri, setDri, allUsers}) {
   
    const [anchor, setAnchor] = useState(null);
   
    function handleDRIClick(event, item) {
        setDri(item);
        setAnchor(null);
    }

    return (
        <>
            <Button className={`${styles.flex_row_center} ${styles.dri_button}`} variant="contained" onClick={(event) => setAnchor(event.currentTarget)}>
                <EmployeeCard emails={dri} />
                <ArrowDropDownRoundedIcon />
            </Button>
            <Menu
                anchorEl={anchor}
                open={anchor !== null}
                onClose={() => setAnchor(null)}
                sx={{
                    maxHeight: "350px",
                    marginTop: "3px"
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                {
                    allUsers.map((item, index) => {
                        return (
                            <MenuItem key={index} onClick={(event) => handleDRIClick(event, item)}>
                                {<EmployeeCard emails={item} />}
                            </MenuItem>
                        );
                    })
                }
            </Menu>
        </>
    );
}