import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    ButtonGroup,
    Collapse,
    TextField,
    Tooltip
} from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import SortRoundedIcon from '@mui/icons-material/SortRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import RemoveIcon from '@mui/icons-material/Remove';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';

import styles from "./TopBar.module.css";
import { TopChips } from './TopChips';
import { FilterMenu } from './FilterMenu';
import { SortMenu } from './SortMenu';

// topbar -> main
export function TopBar({actions, setActions, view}) {
    // console.log(actions);
    // console.log(setActions);
    // console.log(view);
    const [showSearch, setShowSearch] = useState(false);

    useEffect(() => {
        setShowSearch(false);
    }, [actions.search_text]);

    function handleCollapseKeydown(event) {
        if (event.code === "Escape") {
            setShowSearch(false);
            // setActions({
            //     ...actions,
            //     search_text: undefined
            // });
        }
    }

    function handleSearchChange(event) {
        if(event.key === "Enter" && event?.target?.value?.trim() !== "") {
            setActions({
                ...actions,
                search_text: [undefined, null, ""].includes(event?.target?.value) ? undefined : event?.target?.value?.toLowerCase()
            });
        }
    }

    return (
        <Box className={styles.topbar} >
            <TopChips actions={actions} setActions={setActions} view={view} />
            <Box className={styles.topbar_right_side}>
                {/* search box */}
                <Collapse in={showSearch} onKeyDown={handleCollapseKeydown} timeout="auto" orientation="horizontal">
                    <TextField className={styles.search_box} onKeyDown={handleSearchChange} autoFocus spellCheck='false' />
                </Collapse>
                {/* action buttons */}
                <ButtonGroup variant='contained' size='small'>
                    {/* search action */}
                    {
                        view === "mine" ?
                            null
                            :
                            <Search showSearch={showSearch} setShowSearch={setShowSearch} actions={actions} setActions={setActions} />
                    }
                    {/* toggle closed tickets action */}
                    <Toggle actions={actions} setActions={setActions} />
                    {/* filter action */}
                    <Filter actions={actions} setActions={setActions} view={view} />
                    {/* sort action */}
                    <Sort actions={actions} setActions={setActions} />
                    {/*data sort action*/}
                    <DateSort actions={actions} setActions={setActions} />
                </ButtonGroup>
                {/* Filter box */}
                <FilterMenu actions={actions} setActions={setActions} view={view} />
                {/* Sort Box */}
                <SortMenu actions={actions} setActions={setActions} />
            </Box>
        </Box>
    );
}

// topbar -> searchBox
function Search({ showSearch, setShowSearch, actions, setActions }) {

    function handleSearchButtonClick() {
        setShowSearch(state => !state);
        // console.log("Searching..")
        // setActions({
        //     ...actions,
        //     search_text: undefined
        // });
    }

    return (
        <Tooltip title="Search">
            <Button onClick={handleSearchButtonClick} className={showSearch ? styles.button_no_border : styles.button_border} >
                <SearchRoundedIcon fontSize='medium' />
            </Button>
        </Tooltip>
    );
}

// topbar -> switch closed and open tickets
function Toggle({actions, setActions}) {

    function handleToggleClick() {
        setActions({
            ...actions,
            show_closed: nextState(actions.show_closed)
        });
    }

    function nextState(cur) {
        switch(cur) {
            case true: return false;
            case false: return null;
            case null: return true;
        }
    }

    return (
        <Button onClick={handleToggleClick} >
            <Tooltip title={
                actions.show_closed === true ?
                    "Showing Completed Tickets" 
                    :
                actions.show_closed === false ?  
                    "Showing Pending Tickets"
                    :
                actions.show_closed === null ?
                    "Showing Rejected Tickets"
                    :
                    "Showing Tickets"
            } >
                {
                    actions.show_closed === true ? 
                        <DoneRoundedIcon fontSize='medium' /> 
                        :
                    actions.show_closed === false ?
                        <MoreHorizRoundedIcon fontSize='medium' />
                        :
                    actions.show_closed === null ?
                        <RemoveIcon fontSize='medium' />
                        :
                        <RemoveIcon fontSize='medium' />
                }
            </Tooltip>
        </Button>
    );
}

// topbar -> filter fetched rows
function Filter({actions, setActions}) {

    function handleFilterClick(event) {
        setActions({
            ...actions,
            meta: {
                ...actions.meta,
                filter_anchor: actions?.meta?.filter_anchor === null ? event.currentTarget : null
            }
        });
    }

    return (
        <Tooltip title="Filter">
            <Button onClick={handleFilterClick}><FilterAltRoundedIcon fontSize='medium' /></Button>
        </Tooltip>
    );
}

// topbar -> sort fetched rows
function Sort({actions, setActions}) {

    function handleSortClick(event) {
        setActions({
            ...actions,
            meta: {
                ...actions.meta,
                sort_anchor: actions?.meta?.sort_anchor === null ? event.currentTarget : null
            }
        });
    }

    return (
        <Tooltip title="Sort">
            <Button onClick={handleSortClick}><SortRoundedIcon fontSize='medium' /></Button>
        </Tooltip>
    );
}

// topbar -> date sort fetched rows
function DateSort({actions, setActions}) {

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dateOpen, setDateOpen] = useState(false);

    function handleDateSortClick(event) {
        setActions({
            ...actions,
            meta: {
                ...actions.meta,
                sort_anchor: actions?.meta?.sort_anchor === null ? event.currentTarget : null
            }
        });
    }

    const handleSubmit = () => {
        const formattedStartDate = startDate ? dayjs(startDate).format('YYYY-MM-DD') : null;
        const formattedEndDate = endDate ? dayjs(endDate).format('YYYY-MM-DD') : null;
        // handleDateRange(formattedStartDate, formattedEndDate);
        setDateOpen(false);
      };
    
      const handleReset = () => {
        setDateOpen(false);
        setStartDate(null);
        setEndDate(null);
        // handleDateRange("Reset", "Reset");
      };

    return (
        <>
            <Tooltip title="Date Sort">
                <Button onClick={() => setDateOpen(s => !s)}><DateRangeOutlinedIcon fontSize='medium' /></Button>
            </Tooltip>
            {dateOpen && (
                <div style={{ position: 'absolute', top: '23%',right:"0%", zIndex: 1000, background: 'white', border: '1px solid #ccc', padding: '10px', maxHeight: "600px",
                  }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box display="flex" flexDirection="row">
                            <Typography variant="caption">Start Date</Typography>
                                <DateCalendar value={startDate ? dayjs(startDate) : null} onChange={(newStartDate) => setStartDate(newStartDate)} />
                            <Typography variant="caption">End Date</Typography>
                            <DateCalendar  value={endDate ? dayjs(endDate) : null} onChange={(newEndDate) => setEndDate(newEndDate)} />
                        </Box>
                        <Box sx={{padding:"0px 250px !important",gap:"2px ! important"}}>
                            <Button sx = {{marginRight:"10px"}} onClick={() => setDateOpen(false)}>Cancel</Button>
                            <Button sx = {{marginRight:"10px"}} onClick={handleReset}>Reset</Button>
                            <Button onClick={handleSubmit} autoFocus> Submit</Button>
                        </Box>
                    </LocalizationProvider>
                </div>
            )}
          </>
    );
}