/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";

export function BarChart({title, columnName1, columnName2, data, colors, palette, open, type, rotated}) {
    const chartRef = useRef();
    const theme = useTheme();
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);

    var chartJSON = {
        "legend": {
            "show": false,
            "layout": "vertical",
            "colorPallete": {
                "options": {
                    "multicolor": palette === undefined ? "BrightNDull" : palette
                }
            },
            "colors": colors !== null ? colors: ""
        },
        "metadata": {
            "axes": {
                "x": [
                    0
                ],
                "y": [
                    [
                        1
                    ]
                ],
                "tooltip": [
                    0,
                    1
                ]
            },
            "columns": [
                {
                    "dataindex": 0,
                    "columnname": columnName1,
                    "datatype": "ordinal"
                    // "time": {
                    //     "subfunction": "DATE",
                    //     "inputformat": "YYYY-MM-dd",
                    //     "format": {
                    //         "specifier": "MMM, dd"
                    //     }
                    // },
                    // "numeric": {
                    //     "format": {
                    //         "type": "SIprefix"
                    //     }
                    // }
                },
                {
                    "dataindex": 1,
                    "columnname": columnName2,
                    "datatype": "numeric"
                }
            ]
        },
        "seriesdata": {
            "chartdata": type === "full" ? data : [
                {
                    "type":"bar",
                    "data": data
                }
            ]
        },
        "tooltip": {
            "backgroundColor": "white",
            "opacity": 0.95,
            "fontColor": "rgba(0,0,0,0.7)",
            "shadow": "2px 2px 2px rgba(0,0,0,0.3)",
            "fontSize": 15,
            "maxWidth": "100%"
        },
        "chart": {
            "axes": {
                "rotated": rotated,
                "xaxis": {
                    "label": {
                        "show": false,
                        "text": columnName1,
                        "fontSize": 5
                    },
                    "ticklabel": {
                        "textOverflow": "ellipsis"
                    },
                    "axisline": {
                        "color": theme.palette.background.textSecondary
                    }
                },
                "yaxis": [
                    {
                        "label": {
                            "show": false,
                            "text": columnName2,
                            "fontSize": 5
                        },
                        "grid": {
                            "strokeWidth": 0
                        },
                        "axisline": {
                            "color": theme.palette.background.textSecondary
                        }
                    }
                ]
            },
            "plot": {
                "plotoptions": {
                    // "bar": {
                    //     "mode": "monotone",
                    //     "marker": {
                    //         "size": open ? 0.9 : 0.2
                    //     }
                    //     // "animation": {
                    //     //     "type": "pointTween"
                    //     // }
                    // },
                    "bar": {
                        "mode": "monotone",
                        "fillOpacity": 0.8,
                        "strokeWidth": 2,
                        "outerPadding": 0.01,
                        "marker": {
                            "enabled": true,
                            "size": open ? 0.65 : 0.1,
                            "innerStrokeWidth": 2,
                            "outerStrokeWidth": 0,
                            "outerFillColor": "transparent",
                            "outerStrokeOpacity": 0,
                            "innerFillColor": null
                        },
                        "animation": {
                            "type": "vertPathTweenAll"
                        },
                        "maxBandWidth": 8
                    }
                }
            }
        },
        "canvas": {
            "fontFamily": "poppins",
            "fontSize": 1,
            "fontWeight": "bold",
            "fontColor": theme.palette.background.textSecondary,
            "title": {
                "text": title,
                "hAlign": "center",
                "fontSize": "1em"
            },
            "subtitle": {
                "show": false
            },
            "background": {
                "alpha": 0
            },
            "events": {
                "onerror": null
            },
            "border": {
                "show": false
            }
        }
    };

    useEffect(() => {

        var chartObj = null;

        function updateSize() {
            setWindowSize([window.innerWidth, window.innerHeight]);
        }

        if(chartRef !== undefined && chartRef.current !== undefined) {
            chartObj = window.$ZC.charts(chartRef.current, chartJSON);
            window.addEventListener('resize', updateSize);
        }

        return () => {
            if(chartObj !== null) {
                chartObj.destroy();
            }
            window.removeEventListener('resize', updateSize);
        }

    }, [theme.palette.mode, windowSize, data]); 

    return (
        <Box ref={chartRef} sx={{
            height: "100%",
            width: "100%"
        }}>
        </Box>
    );
}