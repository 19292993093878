import React from 'react';
import { createContext } from 'react';
import { useRef } from 'react';
import PropTypes from 'prop-types';

import { useScreenshot } from 'use-react-screenshot'

import ReleaseCycle from './../pageBody/pageContent/releaseCycle/ReleaseCycle.js';
import MyTasks from './../pageBody/pageContent/myTasks/MyTasks.js';
import Roadmap from './../pageBody/pageContent/roadmap/Roadmap.js';
import Milestone from './../pageBody/pageContent/milestone/Milestone.js';
import TaskBreaker from './../pageBody/pageContent/taskBreaker/TaskBreaker.js';
import Roles from './../pageBody/pageContent/roles/Roles.js';
import Report from './../pageBody/pageContent/report/Report.js';

import External from './../pageBody/pageContent/External/External.js';
import External1 from '../pageBody/pageContent/External/External.js';
import CurrentProgress from './../pageBody/pageContent/External/current/currentProgress.js';
import Tickets from './../pageBody/pageContent/tickets/Tickets.js';
import Departmentview from './../pageBody/pageContent/tickets/Departmentview.js';
import Teamview from './../pageBody/pageContent/tickets/Teamview.js';
import BuildUpdate from '../pageBody/pageContent/builds/buildUpdate/BuildUpdate.js';
import NewDCConfiguration from '../pageBody/pageContent/newdcsetup/NewDCConfiguration.js';
import Estimator from '../pageBody/pageContent/estimator/Estimator.js';
import {ExternalUser} from '../pageBody/pageContent/External/ExternalUser.js';
// import Scheduler from '../pageBody/pageContent/External/Scheduler.js';
import PermissionsPage from '../loginPage/PermissionsPage.js';
import InternalRoadmap from '../pageBody/pageContent/External/internalroadmap/InternalRoadmap.js';
import WorkBench from '../pageBody/pageContent/devops/WorkBench.js'


// import Daashboard from '../pageBody/pageContent/tickets/Daashboard.js';
import Dashboard from '../pageBody/pageContent/dashboard/dashboardMain/Dashboard.js';

import MyBuilds from '../pageBody/pageContent/builds/myBuilds/myBuilds.js';
import SelfBuilds from '../pageBody/pageContent/builds/selfBuilds/slefBulid.js';
import ServerBuilds from '../pageBody/pageContent/builds/serverBuilds/serverBuild.js';
import BuildConfiguration from '../pageBody/pageContent/admin/buildconfiguration/BuildConfiguration.js';
import ServerMapping from '../pageBody/pageContent/admin/serverMapping/ServerMapping.js';
import { Checklist } from '../pageBody/pageContent/builds/Checklist/Checklist.js';
import ZoneConfiguration from '../pageBody/pageContent/admin/zoneconfiguration/ZoneConfiguration.js';
import TeamDetails from '../pageBody/pageContent/admin/teamdetails/TeamDetails.js';
import ExternalFeatureUser from '../pageBody/pageContent/admin/externalFeatureUser/ExternalFeatureUser.js';
import ExternalTicketUsers from '../pageBody/pageContent/admin/externalticketusers/ExternalTicketUsers.js';
import LiveIncident from '../pageBody/pageContent/admin/liveIncidentTab/LiveIncident.js';
import Combined from '../pageBody/pageContent/CombinedMilestone/Combined.js';
import Quartely from '../pageBody/pageContent/quartely/Quartely.js';
import RoundTable from '../pageBody/pageContent/roundtable/RoundTable.js';
import AdminIncidents from '../pageBody/pageContent/admin/incidents/Incidents.js';
import LiveIncidents from '../pageBody/pageContent/incidents/liveIncidents/LiveIncidents.js';
import PastIncidents from '../pageBody/pageContent/incidents/pastIncidents/PastIncidents.js';
import IncidentManagement from '../pageBody/pageContent/incidents/IncidentManagement/IncidentManagement.js';
import ServerConfiguration from '../pageBody/pageContent/admin/serverConfigurations/ServerConfiguration.js';
import ReportConfiguration from '../pageBody/pageContent/admin/reportConfiguration/ReportConfiguration.js';
import MyActiveTickets from '../pageBody/pageContent/tickets/MyActiveTickets.js';
import { ReportAnIssue } from '../pageBody/Miscs/reportAnIssue/ReportAnIssue.js';
import { NewReport } from '../pageBody/pageContent/report/Report4/newReportGeneral/NewReport.js';
import { Report1 } from '../pageBody/pageContent/report/Report1/Report1.js';
import { Report2 } from '../pageBody/pageContent/report/Report2/Report2.js';
import { Report3 } from '../pageBody/pageContent/report/Report3/Report3.js';
import { Report5 } from '../pageBody/pageContent/report/Report5/Report5.js';
import { Report6 } from '../pageBody/pageContent/report/Report6/Report6.js';
import { Page } from '../util/components/verticalTimeline/VerticalTimeline.js';
import { TeamView } from '../pageBody/pageContent/tickets/newTicketsTabCore/TeamView.js';

import { DepartmentTickets } from '../pageBody/pageContent/tickets/newTicketsTab/DepartmentTickets.js';
import { Approval } from '../pageBody/pageContent/tickets/newTicketsTab/Approval.js';
import { MyTickets } from '../pageBody/pageContent/tickets/newTicketsTab/MyTickets.js';
import { TeamTickets } from '../pageBody/pageContent/tickets/newTicketsTab/TeamTickets.js';
import SupportTimelinePage  from '../pageBody/pageContent/tickets/support_timeline_page/SupportTimelinePage.js'
import TimelinePage from '../pageBody/pageContent/timeline_page/TimelinePage.js'
import TeamReports from '../pageBody/pageContent/teamReport/teamReport.js'
import History from '../pageBody/pageContent/history/History.js'


export const TabDetails = {
		"dashboard": {
			"index": -1,
			"name": "Dashboard",
			"segment": "/dashboard",
			"innerTabsList": null,
			"component": <Dashboard />
		},
		"releases": {
			"index": 8,
			"name": "Releases",
			"segment": "/releases",
			"innerTabsList": {
				"Release Planner": {
					"index": 0,
					"name": "Release Planner",
					"segment": "/releaseplanner",
					"component": <RoundTable />
				},
				"timeline": {
					"index": 1,
					"name": "Timeline",
					"segment": "/timeline",
					"component": <Report5 />
				}
			}
		},
		"tasks": {
			"index": 0,
			"name": "Tasks",
			"segment": "/tasks",
			"innerTabsList": {
								// "releaseCycle": {
								// 	"index": 0,
								// 	"name": "Release Cycle",
								// 	"segment": "/releaseCycle",
								// 	"component": <ReleaseCycle />
								// },
								"myTasks": {
									"index": 1,
									"name": "My Tasks",
									"segment": "/myTasks",
									"component": <MyTasks />
								},
								"roadmap": {
									"index": 2,
									"name": "Roadmap", 
									"segment": "/roadmap",
									"component": <Roadmap />
								},
								"milestone": {
									"index": 3,
									"name": "Milestone",
									"segment": "/milestone",
									"component": <Milestone />
								},
								"taskBreaker": {
									"index": 4,
									"name": "Task Breaker",
									"segment": "/taskBreaker",
									"component": <TaskBreaker />
								},
								"Combined": {
									"index": 5,
									"name": "Combined Milestone",
									"segment": "/CombinedMilestone",
									"component": <Combined />
								},
								"TimelinePage": {
									"index": 6,
									"name": "Reports",
									"segment": "/TimelinePage",
									"component": <TimelinePage />
								},
								// "report": {
								// 	"index": 7,
								// 	"name": "Report",
								// 	"segment": "/report",
								// 	"component": <Report />
								// }
							}
		},

		"tickets": {
			"index": 1,
			"name": "Tickets",
			"segment": "/tickets",
			"innerTabsList": {
								// "tickets": {
								// 	"index": 0,
								// 	"name": "My Tickets",
								// 	"segment": "/myTickets",
								// 	"component": <Tickets />
								// },
								// "teamview": {
								// 	"index": 1,
								// 	"name": "Team View",
								// 	"segment": "/teamview",
								// 	"component": <Teamview/>
								// },
								// "departmentview": {
								// 	"index": 2,
								// 	"name": "Department View", 
								// 	"segment": "/departmentview",
								// 	"component": <Departmentview/>
								// },
								// "myActiveTickets": {
								// 	"index": 3,
								// 	"name": "Raised Tickets", 
								// 	"segment": "/RaisedTickets",
								// 	"component": <MyActiveTickets/>
								// },
								// "newTickets__temp": {
								// 	"index": 1,
								// 	"name": "newTickets__temp",
								// 	"segment": "/newTickets__temp",
								// 	"component": <TeamView />
								// },
								"myTickets": {
									"index": 0,
									"name": "My Tickets",
									"segment": "/myTickets",
									"component": <MyTickets />
								},
								"teamTickets": {
									"index": 1,
									"name": "Team Tickets",
									"segment": "/teamTickets",
									"component": <TeamTickets />
								},
								"departmentTickets": {
									"index": 2,
									"name": "Department Tickets",
									"segment": "/departmentTickets",
									"component": <DepartmentTickets />
								},
								"approval": {
									"index": 3,
									"name": "My Approvals",
									"segment": "/approval",
									"component": <Approval />
								},
								"SupportTimelinePage": {
									"index": 4,
									"name": "Tickets Report",
									"segment": "/SupportTimelinePage",
									"component": <SupportTimelinePage />
								}
								// "incidents": {
								// 	"index": 3,
								// 	"name": "Incidents",
								// 	"segment": "/incidents",
								// 	"component": <Incidents/>
								// }
								// },
								// "daashboard": {
								// 	"index": 3,
								// 	"name": "DaashBoard", 
								// 	"segment": "/daashboard",
								// 	"component": <Daashboard/>
								// }
							}
		},

		"build": {
			"index": 2,
			"name": "Build",
			"segment": "/build",
			"innerTabsList": {
				"selfBulid": {
						"index": 0,
						"name": "My Builds",
						"segment": "/selfBuilds",
						"component": <SelfBuilds />
					},
				// "myBuilds": {
				// 	"index": 0,
				// 	"name": "My Builds",
				// 	"segment": "/myBuilds",
				// 	"component": <MyBuilds />
				// },
				"serverBuild": {
					"index": 1,
					"name": "Team Builds",
					"segment": "/serverBuilds",
					"component": <ServerBuilds />
				},
				"buildUpdate": {
					"index": 2,
					"name": "BuildUpgrade",
					"segment": "/buildUpdates",
					"component": <BuildUpdate/>
				},
				"Checklist": {
					"index": 3,
					"name": "Checklist",
					"segment": "/Checklist",
					"component": <Checklist/>
				}
			}
		},

		"reports": {
			"index": 7,
			"name": "Reports",
			"segment": "/reports",
			"innerTabsList": {
				"comprehensiveReport": {
					"index": 0,
					"name": "Comprehensive Report",
					"segment": "/comprehensiveReport",
					"component": <NewReport />
				},
				"overview": {
					"index": 1,
					"name": "Overview",
					"segment": "/overview",
					"component": <Report1 />
				},
				"insights": {
					"index": 2,
					"name": "Insights",
					"segment": "/insights",
					"component": <Report2 />
				},
				"rawView": {
					"index": 3,
					"name": "Raw View",
					"segment": "/rawView",
					"component": <Report6 />
				},
				// "report6": {
				// 	"index": 4,
				// 	"name": "Report 6",
				// 	"segment": "/report6",
				// 	// "component": <Report5 />
				// 	"component": <Report6 />
				// }
				
			}
		},

		"incident": {
			"index": 3,
			"name": "Incident",
			"segment": "/incident",
			"innerTabsList": {
				"liveIncidents": {
					"index": 0,
					"name": "Live Incidents",
					"segment": "/liveIncidents",
					"component": <LiveIncidents />
				},
				// "pastIncidents": {
				// 	"index": 1,
				// 	"name": "Past Incidents",
				// 	"segment": "/pastIncidents",
				// 	"component": <PastIncidents />
				// },
				"IncidentManagement": {
					"index": 1,
					"name": "Past Incidents",
					"segment": "/IncidentManagement",
					"component": <IncidentManagement />
				}
			}
		},

		"reportIssue": {
			"index": 6,
			"name": "Report Issue",
			"segment": "/reportIssue",
			"innerTabsList": {
				"reportAnIssue": {
					"index": 0,
					"name": "Report An Issue",
					"segment": "/reportAnIssue",
					"component": <ReportAnIssue />
				}
			}
		},

		"admin": {
			"index": 4,
			"name": "Admin",
			"segment": "/admin",
			"innerTabsList": {
				"teamdetails": {
					"index": 0,
					"name": "Access Request Form",
					"segment": "/teamdetails",
					"component": <TeamDetails />
				},
				"buildConfiguration": {
					"index": 1,
					"name": "Build Configuration",
					"segment": "/buildconfiguration",
					"component": <BuildConfiguration />
				},
				"externalFeatureUser": {
					"index": 2,
					"name": "External Access Form",
					"segment": "/externalFeatureUser",
					"component": <ExternalFeatureUser />
				},
				"externalticketusers": {
					"index": 3,
					"name": "External Ticket Users",
					"segment": "/externalticketusers",
					"component": <ExternalTicketUsers />
				},
				"liveIncidentTab": {
					"index": 4,
					"name": "Incident Form",
					"segment": "/liveIncidentTab",
					"component": <LiveIncident />
				},
				// "roles": {
				// 	"index": 5,
				// 	"name": "Roles",
				// 	"segment": "/roles",
				// 	"component": <Roles />
				// },
				"reportConfiguration": {
					"index": 5,
					"name": "Report Configuration",
					"segment": "/reportConfiguration",
					"component": <ReportConfiguration />
				},
				"serverConfiguration": {
					"index": 6,
					"name": "Server Configuration",
					"segment": "/serverConfiguration",
					"component": <ServerConfiguration />
				},
				"serverMapping": {
					"index": 7,
					"name": "Server Mapping",
					"segment": "/serverMapping",
					"component": <ServerMapping />
				},
				"zoneconfiguration": {
					"index": 8,
					"name": "Zone Configuration",
					"segment": "/zoneconfiguration",
					"component": <ZoneConfiguration />
				}
				
				
				// "incidents": {
				// 	"index": 1,
				// 	"name": "Incidents",
				// 	"segment": "/incidents",
				// 	"component": <AdminIncidents />
				// }
			}
		},

		"external": {
			"index": 5,
			"name": "External",
			"segment": "/external",
			"innerTabsList": {
					// "permissions": {
					// 	"index": 0,
					// 	"name": "External Roadmap",
					// 	"segment": "/external",
					// 	"component": <External/>
					// },
					// "externalPermissions": {
					// 	"index": 1,
					// 	"name": "Current Progress",
					// 	"segment": "/currentProgress",
					// 	"component": <CurrentProgress/>
					// },
					// "internal": {
					// 	"index": 2,
					// 	"name": "Internal Roadmap",
					// 	"segment": "/internalroadmap",
					// 	"component": <InternalRoadmap/>
					// },
					"externalUser": {
						"index": 0,
						"name": "Feature Request",
						"segment": "/externaluser",
						"component": <ExternalUser/>
					}
					
			}
		},
		"milestoneAchievement": {
			"index": -2,
			"name": "Milestone Achievement",
			"segment": "/milestoneAchievement",
			"innerTabsList": null,
			"component": <History />
		},
		"devops": {
			"index": 11,
			"name": "Devops",
			"segment": "/devops",
			"innerTabsList": {
					"WorkBench": {
						"index": 0,
						"name": "Work Bench",
						"segment": "/WorkBench",
						"component": <WorkBench/>
					}
					
			}
		}

	// 	"newdcsetup": {
	// 		"index": 8,
	// 		"name": "NewDCSetup",
	// 		"segment": "/newdcsetup",
	// 		"innerTabsList": {
	// 				"newDCConfiguration": {
	// 					"index": 0,
	// 					"name": "New DC Configuration",
	// 					"segment": "/newDCConfiguration",
	// 					"component": <NewDCConfiguration/>
	// 				}
	// 		}
	// 	},

	// 	"estimator": {
	// 		"index": 9,
	// 		"name": "Estimator",
	// 		"segment": "/estimator",
	// 		"innerTabsList": {
	// 				"newEstimater": {
	// 					"index": 0,
	// 					"name": "New Estimator",
	// 					"segment": "/newEstimator",
	// 					"component": <Estimator/>
	// 				}
	// 		}
	// },
	// "roundtable": {
	// 	"index": 10,
	// 	"name": "RoundTable",
	// 	"segment": "/roundtable",
	// 	"innerTabsList": {
	// 		"newRoundTable": {
	// 			"index": 0,
	// 			"name": "New Round Table",
	// 			"segment": "/newRoundTable",
	// 			"component":  <Quartely />
	// 		}
	// 	}
	// }
};

var tabStateInitial = {
	"outer": "tasks",
	"outerSegment": "/tasks",
	"inner": {
		"dashboard": null,
		"releases": "releaseplanner",
		"tasks": "myTasks",
		"tickets": "myTickets",
		// "build": "myBuilds",
		"build": "slefBuilds",
		"reports": "comprehensiveReport",
		"incident": "LiveIncidents",
		"admin": "serverConfiguration",
		"reportIssue": "reportAnIssue",
		"newdcsetup": "newdcsetup",
		"external":"external",
		"newdcsetup": "newdcsetup",
		"estimator": "estimator",
		"roundtable": "roundtable",
		"milestoneAchievement": null,
		"devops": "devops"
	},
	"innerSegment": {
		"dashboard": null,
		"releases": "/releaseplanner",
		"tasks": "/releaseCycle",
		"tickets": "/myTickets",
		// "build": "/myBuilds",
		"build": "/selfBuilds",
		"reports": "/comprehensiveReport",
		"incident": "/liveIncidents",
		"admin": "/serverConfiguration",
		"reportIssue": "/reportAnIssue",
		"newdcsetup": "/NewDCConfiguration",
		"external":"/externaluser",
		"estimator":"/newEstimator",
		"roundtable":"/newRoundTable",
		"milestoneAchievement": null,
		"devops": "/WorkBench"
	}
};

export const TabStateContext = createContext();

TabStateProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export function TabStateProvider({ children })
{
	const tabState = useRef(tabStateInitial);
	var colorsCache = {};

	const screenShotRef = useRef(null);
    const [screenshot, takeScreenshot] = useScreenshot();

    function captureScreenshot() {
		if(window.location.hash !== "#/reportIssue/reportAnIssue") {
			takeScreenshot(screenShotRef.current);
		}
    }

	function changeTabState(outerTabSelected, innerTabSelected)
	{
		const outerTabSegment = TabDetails[outerTabSelected].segment;

		if(innerTabSelected === null) {
			var innerTabSegment = null;
		} else {
			var innerTabSegment = TabDetails[outerTabSelected].innerTabsList[innerTabSelected].segment;
		}
		const newInner = {...tabState.current.inner};
		newInner[outerTabSelected] = innerTabSelected;

		const newInnerSegment = {...tabState.current.innerSegment};
		newInnerSegment[outerTabSelected] = innerTabSegment;

		tabState.current = {
			"outer": outerTabSelected,
			"outerSegment": outerTabSegment,
			"inner": {
				...newInner
			},
			"innerSegment": {
				...newInnerSegment
			}
		};
	}

	return (
	    <TabStateContext.Provider value={{tabState, changeTabState, colorsCache, screenshot, captureScreenshot, screenShotRef}}>
	      {children}
	    </TabStateContext.Provider>
	);

}