import { isDateString, isDateInRange } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    var count = 0;

    const milestoneIdMap = {};

    catalystDB.TaskDetails.map(item => {

        if([undefined, null, "", " "].includes(item.MilestoneId)) {
            return;
        }

        if(item.MilestoneId in milestoneIdMap) {
            return;
        } else {
            milestoneIdMap[item.MilestoneId] = true;
        }

        const dateString = item.MilestoneCompletionDate;

        if(isDateString(dateString) && isDateInRange(dateString, startTime)) {
            count++
        }
    }); 

    return {
        "value": count
    };
}