/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";

export function PieChart({title, data, colors, palette, hallow, padAngle, open, clickHandler, rerender, suffix}) {
    const chartRef = useRef();
    const theme = useTheme();
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);

    var chartJSON = {
        "legend": {
            "show": open,
            "layout": "vertical",
            "colorPallete": {
                "options": {
                    "multicolor": palette === undefined ? "BrightNDull" : palette
                }
            },
            "colors": colors !== null ? colors: ""
        },
        "chart": {
            "plot": {
                "datalabels": {
                    "handleOverlapping": false
                },
                "plotoptions": {
                    "pie": {
                        "sliceOutHeight": open ? 14 : 1,
                        "outerPadding": 2,
                        "fillOpacity": 0.9,
                        "padAngle": padAngle,
                        "datalabels": {
                            "show": false,
                            "type": "doubleside"
                        },
                        "strokeColor": null,
                        "innerRadius": hallow,
                        "legendHighlightEffect": {
                            "selectedSeries": "horizontalStripesfill"
                        },
                        "gradients": {
                            "type": "radial",
                            "options": {
                                "radial": {
                                    "radius":0, //80
                                    "spreadMethod": "reflect",
                                    "colorGamma": [
                                        -0.9,
                                        0
                                    ]
                                }
                            }
                        },
                        "events": {
                            "click": clickHandler
                        }
                    }
                }
            }
        },
        "metadata": {
            "axes": {
                "x": [
                    0
                ],
                "y": [
                    [
                        1
                    ]
                ],
                "tooltip": [
                    "<div style='text-align:center;padding:0 10px 0 10px;color:black'><div style='font-size:12px;'>{{val(0)}}</div><div style='line-height: 1'>{{val(1)}}</div></div>"
                ]
            },
            "columns": [
                {
                    "dataindex": 0,
                    "columnname": "",
                    "datatype": "ordinal"
                },
                {
                    "dataindex": 1,
                    "columnname": "",
                    "datatype": "numeric"
                }
            ]
        },
        "seriesdata": {
            "chartdata": [
                {
                    "type": "pie",
                    "data": data
                }
            ]
        },
        "tooltip": {
            "backgroundColor": "white",
            "opacity": 0.95,
            "fontColor": "rgba(0,0,0,0.7)",
            "shadow": "2px 2px 2px rgba(0,0,0,0.3)",
            "fontSize": 15,
            "maxWidth": "100%"
        },
        "canvas": {
            "fontFamily": "poppins",
            "fontSize": 1,
            "fontWeight": "bold",
            "fontColor": theme.palette.background.textSecondary,
            "title": {
                "text": title,
                "hAlign": "center",
                "fontSize": "1em"
            },
            "subtitle": {
                "show": false
            },
            "background": {
                "alpha": 0
            },
            "events": {
                "onerror": null
            },
            "border": {
                "show": false
            }
        }
    };

    var chartObj = null;
    useEffect(() => {

        function updateSize() {
            setWindowSize([window.innerWidth, window.innerHeight]);
        }

        if(chartRef !== undefined && chartRef.current !== undefined) {
            chartObj = window.$ZC.charts(chartRef.current, chartJSON);
            window.addEventListener('resize', updateSize);
        }

        return () => {
            if(chartObj !== null) {
                chartObj.destroy();
            }
            window.removeEventListener('resize', updateSize);
        }

    }, [theme.palette.mode, windowSize, rerender, data]); 

    // useEffect(() => {
    //     console.log(rerender);
    //     console.log(chartObj);
    //     if(chartObj !== null) {
    //         chartObj.redraw();
    //     }
    // }, [rerender])

    return (
        <Box ref={chartRef} sx={{
            height: "100%",
            width: "100%"
        }}>
        </Box>
    );
}