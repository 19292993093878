/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import { Skeleton } from '@mui/material';
import Slide from '@mui/material/Slide';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {QuartelyTaskList} from './QuartelyTaskList'
import { queryDB } from '../../../util/QueryDB';
import { GetNameAndEmpID, days_between } from '../../../util/Loading.js';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { quarterly_milestone_card_component_get_query_1 } from '../../../catalystDB/queryGenerator.js';

var dataCache = {};
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export function QuarterlyMilestoneCard({ milestone_name, Server, DRI, Milestone_Startdate,MilestoneDueDate,index,MilestoneID,milestone_status,RoadMapName,MilestoneDescription}) {
    // console.log(Feature_type)
	const theme = useTheme();
	return (

	   	<QuaretelyMilstoneTable milestone_name={milestone_name} Server={Server}  DRI={DRI} Milestone_Startdate={Milestone_Startdate} MilestoneDueDate={MilestoneDueDate} index={index} MilestoneID={MilestoneID} milestone_status={milestone_status} RoadMapName={RoadMapName} MilestoneDescription={MilestoneDescription}/>
			
	);
}




const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 22
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor: theme.palette.primary.main
		}
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor: theme.palette.mode === 'dark' ? "#717171" : '#a0a0a0',
		borderRadius: 1
	}
}));


export function QuaretelyMilstoneTable({ milestone_name, Server, DRI, Milestone_Startdate,MilestoneDueDate,index,MilestoneID,RoadMapName,MilestoneDescription,milestone_status }) {
    // console.log(milestone_name)
    const [open, setOpen] = React.useState(null);
    const [TaskListData, setTaskListData] = useState([]);
	const theme = useTheme();
	const handleClick = () => {
		if (open === index) {
			setOpen(null);
		}

		else {
			setOpen(index);
		}
	};

	var color = "green";

	const today = new Date();
	const startDate = new Date(Milestone_Startdate);
	const endDate = new Date(MilestoneDueDate);
	const totDays = days_between(startDate, endDate);

	// console.log(days_between(today, startDate));

	if (today < startDate) {
		color = "green";
	}
	else if(MilestoneDueDate[0] === '-'){
		if(theme.palette.mode === "dark"){
			color = "White";
		}
		else{
			color = "black";
		}
	}
	else if(milestone_status === "Closed" || milestone_status === "Updated to IDC" || milestone_status === "Cancelled" || milestone_status === "Completed"){
		if(theme.palette.mode === "dark"){
			color = "White";
		}
		else{
			color = "black";
		}
    }
	else if (days_between(today, startDate) < (0.25 * totDays).toFixed(0)) {
		color = "forestgreen";
	}
	else if (days_between(today, startDate) < (0.5 * totDays).toFixed(0)) {
		color = "darksalmon";
	}
	else if (days_between(today, startDate) < (0.75 * totDays).toFixed(0)) {
		color = "darkgoldenrod";
	}
	else if (days_between(today, startDate) < (totDays).toFixed(0)) {
		color = "chocolate";
	}
	else {
		color = "crimson";
	}
	if (MilestoneDueDate === "-" || Milestone_Startdate === "-") {
		color = "";
	}

	const TextOnlyTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
      ))(`
          color: black;
          background-color: white;
      `);

	useEffect(() => {
		fetchValuesData();
	}, []);

	const fetchValuesData = async () => {
		try {
			const query = quarterly_milestone_card_component_get_query_1(MilestoneID);
			// console.log(query)
			if(query in dataCache) {
				setTaskListData(dataCache[query]);
			} else {
				const result = await queryDB(query);
				dataCache[query] = result;
				setTaskListData(result);
			}
		} catch (error) {
			// console.error(error);
		}
	};

	

    var dataAsArray = TaskListData.map((item) => {
		return [
			
			item.TaskDetails.TasklistName,
			item.TaskDetails.DRI,
			item.TaskDetails.TasklistPriority,
			[undefined, "", " ", null].includes(item.TaskDetails.TasklistStartDate) ? "-" : item.TaskDetails.TasklistStartDate,
			[undefined, "", " ", null].includes(item.TaskDetails.TasklistDueDate) ? "-" : item.TaskDetails.TasklistDueDate,
		    item.TaskDetails.TasklistStatus,
			item.TaskDetails.Component,
			item.TaskDetails.Tasklistid,
			item.TaskDetails.TasklistDescription
		];
	});
	

    return ( 
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow  className="tasklist-table-head">
              <TableCell align="center" >Milestone Name</TableCell>
              <TableCell align="center" >Server</TableCell>
              <TableCell align="center" >DRI</TableCell>
              <TableCell align="center" >Milestone Start Date</TableCell>
              <TableCell align="center" >Milestone Due Date</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
	<TableRow
	sx={{
	  backgroundColor: theme.palette.background.tableRow,
	  '&:hover': {
		backgroundColor: theme.palette.background.tableRowHover + " !important"
	  }
	}}
  >
	          <TextOnlyTooltip title={<span style={{ fontFamily: 'Poppins' ,fontSize: '0.875rem', color: "Black" ,backgroundImage:"" }}>{MilestoneDescription} </span>}  followCursor >
	               <TableCell  onClick={handleClick} align="center" >{milestone_name.length === 0 ? (<Skeleton></Skeleton>):(milestone_name)}</TableCell>
			  </TextOnlyTooltip>
              <TableCell  onClick={handleClick} align="center" >{Server.length === 0 ? (<Skeleton></Skeleton>):(Server)}</TableCell>
              <TableCell  onClick={handleClick} align="center" >{DRI.length === 0 ? (<Skeleton></Skeleton>):(DRI)}</TableCell>
              <TableCell  onClick={handleClick} align="center" >{Milestone_Startdate.length === 0 ? (<Skeleton></Skeleton>):(Milestone_Startdate)}</TableCell>
              <TableCell  onClick={handleClick} align="center"  style={{color:color}}>{MilestoneDueDate.length === 0 ? (<Skeleton></Skeleton>):(MilestoneDueDate)}</TableCell>
              <TableCell align="left" className='slide-animation' style={{ width: "30px" }}>
							<IconButton size="small" onClick={handleClick}>
								{open === null ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
							</IconButton>
			 </TableCell>
            </TableRow>
            <TableRow>
						<TableCell colSpan={6} sx={{padding: 0}}>
							<Collapse in={open !== null} timeout="auto" unmountOnExit>
								<QuartelyTaskList TasklistName={dataAsArray.map((item) => item[0])} DRI={dataAsArray.map((item) => item[1])} TasklistPriority={dataAsArray.map((item) => item[2])} TasklistStartDate={dataAsArray.map((item) => item[3])} TasklistDueDate={dataAsArray.map((item) => item[4])} TasklistStatus={dataAsArray.map((item) => item[5])} milestone_name={milestone_name} TaskServerName ={dataAsArray.map((item) => item[6])} Tasklistid={dataAsArray.map((item) => item[7])} TasklistDescription={dataAsArray.map((item) => item[8])} RoadMapName={RoadMapName}/>
							</Collapse>
						</TableCell>
			</TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
