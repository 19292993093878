/* eslint-disable react/prop-types */
import { queryDB } from "../../../../util/QueryDB";
import { findMentees, findPeopleUnderServer } from "../../../../util/Loading";
import { UserContext } from '../../../../contexts/UserContext';
import { report_3_report_data_get_query_1, report_3_report_data_get_query_2, report_3_report_data_get_query_3 } from "../../../../catalystDB/queryGenerator";

export function fetchData(startTime, endTime, view, selection, teamDetails, status,getRmap=false, getIssues=false, loginUserDept) {

    //const { loginUserDept } = useContext(UserContext);
    const startDate = new Date(startTime*1000).toISOString().split("T")[0];
    const endDate = new Date(endTime*1000).toISOString().split("T")[0];
    var totalCount = getRmap ? 4 : 3;
    totalCount = getIssues ? totalCount+1 : totalCount;
    // console.log(startDate);
    // console.log(endDate);
    const key = startTime+"-"+endTime;

    var count = 0;
    var result = [{},{},{},{},{}];

    var filter = "";
    if(selection === "Overall") 
	{
        filter = "";
    }
    else if(view === "ServerView") 
	{
        filter = " AND Component = '" + selection + "'";
    } else if(view === "MentorView") 
	{
        var menteesList = findMentees([selection], teamDetails);
        menteesList.push(selection);
        filter = " AND DRI IN ('" + menteesList.join("','") + "')";
    }   

    var issuesFilter = "";
    if(selection === "Overall") 
	{
        issuesFilter = "";
    } else if(view === "ServerView") 
	{
       var peopleList = findPeopleUnderServer(selection, teamDetails);
    //    console.log(peopleList);
       peopleList.push(selection);
       issuesFilter = " AND (ClaimedBy_AssingedTo IN '" + peopleList.join("','") + "' ";
       peopleList.map((people) => {
           issuesFilter += " OR AllForwarded_From_to_To LIKE '*" + people + "*' ";
       })
       issuesFilter += ")";
    } else if(view === "MentorView") 
	{
        var menteesList = findMentees([selection], teamDetails);
        menteesList.push(selection);
        issuesFilter = " AND (ClaimedBy_AssingedTo IN '" + menteesList.join("','") + "' ";
        menteesList.map((mentee) => {
            issuesFilter += " OR AllForwarded_From_to_To LIKE '*" + mentee + "*' ";
        })
        issuesFilter += ")";
    }

	//const milestoneQuery = `SELECT MilestoneName, MilestoneID, MilestoneStartDate, MilestoneCompletionDate, MilestoneDueDate FROM TaskDetails WHERE CREATEDTIME >= '${startDate}' AND CREATEDTIME <= '${endDate}' ${filter} AND department= '${loginUserDept.current}' AND IsTasklistDeleted is null GROUP BY MilestoneId`;
    const milestoneQuery =  report_3_report_data_get_query_1(status, startDate, endDate, filter, loginUserDept);

	const tasklsitQuery = report_3_report_data_get_query_2(startDate, endDate, filter, loginUserDept);
    //console.log(tasklsitQuery);
	const taskQuery = report_3_report_data_get_query_3(startDate, endDate, filter, loginUserDept);
    //console.log(taskQuery);


	return new Promise((resolve, reject) => {
        getData(milestoneQuery).then((res) => {
            result[0] = res;
            count++;
            if(count == totalCount) {
                return resolve(result);
            }
        }).catch((e) => {
            return reject(e);
        })
        getData(tasklsitQuery).then((res) => {
            result[1] = res;
            count++;
            if(count == totalCount) {
                return resolve(result);
            }
        }).catch((e) => {
            return reject(e);
        })
        getData(taskQuery).then((res) => {
            result[2] = res;
            count++;
            if(count == totalCount) {
                return resolve(result);
            }
        }).catch((e) => {
            return reject(e);
        })
    })
}


var cache = {};

function getData(query) {
    if(cache[query] === undefined) {
        return new Promise(async (resolve, reject) => {
            try {
                const data = await queryDB(query);
                 //console.log(data);
                // localStorage.setItem(query, JSON.stringify(data));
                cache[query] = data;
                //console.log(data);
                return resolve(data);
            } catch (e) {
                return reject(e);
            }
        });
    } else {
        // return Promise.resolve(JSON.parse(localStorage.getItem(query)));
        return Promise.resolve(cache[query]);
    }    
}


export async function getSankeyData(startTime, endTime, view, selection, teamDetails,showTaskStatus, loginUserDept) 
{
    return await fetchData(startTime, endTime, view, selection, teamDetails, false, false, loginUserDept).then((data) => {
        // console.log(data);
        //console.log(showTaskStatus);
        var arr = [];

        data[1].forEach(element => {
            arr.push([element.TaskDetails.MilestoneName, element.TaskDetails.TasklistName, 1]);
        });

        // data[2].forEach(element => {
        //     if (showTaskStatus === element.TaskBreaker.TaskStatus) 
        //     {
        //         arr.push([element.TaskBreaker.TaskListName, element.TaskBreaker.TaskName, 1]);
        //         arr.push([element.TaskBreaker.TaskName, element.TaskBreaker.TaskStatus, 1]);
        //     }
        //     else if (showTaskStatus === "Overall")
        //     {
        //         arr.push([element.TaskBreaker.TaskListName, element.TaskBreaker.TaskName, 1]);
        //     }
        // });
        // console.log(arr);
        return Promise.resolve([arr]);
    }).catch((e) => {
        // console.log(e);
        return Promise.reject(e);
    })
}
