import React, { useContext } from 'react';

import { GridLayout } from '../dashboardMain/GridLayout';

import { DashboardContext } from '../dashboardMain/stateStorage/DashboardStateStorage';
import { GridItem_1 } from './gridItem_1/GridItem_1';
import { GridItem_2 } from './gridItem_2/GridItem_2';
import { GridItem_3 } from './gridItem_3/GridItem_3';
import { GridItem_4 } from './gridItem_4/GridItem_4';
import { GridItem_5 } from './gridItem_5/GridItem_5';
import { GridItem_6 } from './gridItem_6/GridItem_6';
import { GridItem_7 } from './gridItem_7/GridItem_7';
import { GridItem_8 } from './gridItem_8/GridItem_8';
import { GridItem_9 } from './gridItem_9/GridItem_9';

export function Dashboard_2() {
    const layout = [
        {
            "title": "",
            "name": "GridItem_1_2",
            "value": null,
            "x": 1.4,
            "y": 6,
            "children": [
                {
                    "title": "Issue Count",
                    "name": "GridItem_1",
                    "value": <GridItem_1 />,
                    "x": 12,
                    "y": 6,
                    "noExpansion": true
                },
                {
                    "title": "Issue Priority",
                    "name": "GridItem_2",
                    "value": <GridItem_2 />,
                    "x": 12,
                    "y": 6
                }
            ],
            "noExpansion": true
        },
        {
            "title": "Issue Count Serverwise",
            "name": "GridItem_3",
            "value": <GridItem_3 />,
            "x": 5,
            "y": 6
        },
        {
            "title": "",
            "name": "GridItem_4_5_6",
            "value": null,
            "x": 5.6,
            "y": 6,
            "children": [
                {
                    "title": "",
                    "name": "GridItem_4_5",
                    "value": null,
                    "x": 12,
                    "y": 8.5,
                    "children": [
                        {
                            "title": "Ticket Status",
                            "name": "GridItem_4",
                            "value": <GridItem_4 />,
                            "x": 6,
                            "y": 12
                        },
                        {
                            "title": "Tasks Vs Issues",
                            "name": "GridItem_5",
                            "value": <GridItem_5 />,
                            "x": 6,
                            "y": 12
                        }
                    ],
                    "noExpansion": true
                },
                {
                    "title": "Total Issues Vs Completed",
                    "name": "GridItem_6",
                    "value": <GridItem_6 />,
                    "x": 12,
                    "y": 3.5
                }
            ],
            "noExpansion": true
        },
        {
            "title": "Team Members Issue Contribution",
            "name": "GridItem_7",
            "value": <GridItem_7 />,
            "x": 5,
            "y": 6
        },
        {
            "title": "Monthly Issues",
            "name": "GridItem_8",
            "value": <GridItem_8 />,
            "x": 5,
            "y": 6
        },
        {
            "title": "Time Spent On Issues",
            "name": "GridItem_9",
            "value": <GridItem_9 />,
            "x": 2,
            "y": 6,
            "noExpansion": true
        }
    ];

    const {getAllDashboardNames} = useContext(DashboardContext);
    const DASHBOARD_NAMES = getAllDashboardNames();

    return (
        <GridLayout layout={layout} dashboardName={DASHBOARD_NAMES[1]}key={"DASHBOARD_2"} />
    );
}