/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import { TableBody, TableHead, TableRow, useTheme } from '@mui/material';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import  { tableCellClasses } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Switch from '@mui/material/Switch';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';

import { UserContext } from '../../../contexts/UserContext.js';
import { GetNameAndEmpID } from '../../../util/Loading.js';
import CancelIcon from '@mui/icons-material/Cancel';

import { updateRolesInCreator } from './RolesDataUpdater.js';

import "./Roles.css";

function Roles() {
	const { userDetails, teamDetails, allServers } = useContext(UserContext);
	const loginUser = userDetails.current.auth.email_id;
	const loginUserDetails = teamDetails.current[loginUser];
	const loginUserRole = loginUserDetails.Role;
	// console.log(loginUser);
	// console.log(loginUserDetails);
	// const theme = useTheme();
	// const rolesFormUrl = "https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/Roles?zc_ifr_load=true&zc_Export=true";
	const productOwnerServersString = loginUserDetails.ProductOwner === undefined ? "" : loginUserDetails.ProductOwner.replace(/^\[|\]$/g, '');
	const milestoneAutoApprovalServersString = loginUserDetails.MilestoneApproval === undefined ? "" : loginUserDetails.MilestoneApproval.replace(/^\[|\]$/g, '');
	const userValues = [];
	const [userEmail, setUserEmail] = useState(loginUser);
	const [permission, setPermission] = useState(loginUserDetails.Role === "CEO" ? "Super Admin" : (loginUserDetails.Role === null ? "Developer" : loginUserDetails.Role));
	const [servers, setServers] = useState(loginUserDetails.Servers === "" ? [] : loginUserDetails.Servers.split(","));
	const [productOwnerServers, setProductOwnerServers] = useState(productOwnerServersString === "" ? [] : productOwnerServersString.split(",").map(item => item.trim()));
	const [milestoneAutoApprovalServers, setMilestoneAutoApprovalServers] = useState(milestoneAutoApprovalServersString === "" ? [] : milestoneAutoApprovalServersString.split(",").map(item => item.trim()));
	// const [superAdmin, setSuperAdmin] = useState(false);
	const [sameAsServer, setSameAsServer] = useState(servers.every(item => milestoneAutoApprovalServers.includes(item)) && milestoneAutoApprovalServers.every(item => servers.includes(item)));

	const [formDisabled, setFormDisabled] = useState(false);
	const [showBanner, setShowBanner] = useState(false);
	const [bannerSeverity, setBannerSeverity] = useState("success");
	var allPermissions = ["Super Admin", "Manager", "Mentor", "Developer"];
	if(loginUserRole !== "CEO") {
		allPermissions = ["Manager", "Mentor", "Developer"];
	}
	// const allServers = ["DAV", "WPNServer", "TURN-C", "Intercomm", "NIO Framework", "PubSub", "PServer", "AWServer", "AMServer", "ZVP Media",
	// 	"ZVP Turn", "Zone", "Test", "RTCPlatform", "WSServer", "MediaRouter-C", "Common C", "Network-C", "ZVPInfra", "MSServer"];
	// allServers.sort();
	const allProductOwnerServers = [...allServers];
	const allMilestoneAutoApprovalServers = [...allServers];

	for (var member in teamDetails.current) 
	{
		if ([undefined, "", " ", null].includes(member) || member.includes("@zohotest.com")) {
			continue;
		}
		userValues.push(member);
	}
	userValues.sort();
	// console.log(allServers)

	// return (
	// 	<>
	// 		<div className="page-content">
	// 			<iframe src={rolesFormUrl}
	// 								 frameBorder={0} style={theme.palette.mode === "light" ? {
	// 					width: "100%",
	// 					height: "calc(100vh - 130px)",
	// 				}: {
	// 					width: "100%",
	// 					height: "calc(100vh - 130px)",
	// 					filter: "invert(0.9) hue-rotate(135deg)"
	// 				}} 
	// 			></iframe>	
	// 		</div>
	// 	</>
	// );

	// function handleSuperAdminChange()
	// {
	// 	setSuperAdmin(!superAdmin);
	// }

	function handleSetUserEmail(value)
	{
		const unwanted = ["[]", "", undefined, null, " "];
		if(value === "")
		{
			handleFormReset();
			return;
		}
		const selectedUserDetails = teamDetails.current[value];
		// console.log(selectedUserDetails);
		const serverValues = unwanted.includes(selectedUserDetails.Servers) ? [] : selectedUserDetails.Servers.split(",");
		const milestoneAutoApprovalServersValues = unwanted.includes(selectedUserDetails.MilestoneApproval) ? [] : selectedUserDetails.MilestoneApproval.replace(/^\[|\]$/g, '').split(",").map(item => item.trim());

		setPermission(selectedUserDetails.Role === "CEO" ? "Super Admin" : (unwanted.includes(selectedUserDetails.Role) ? "Developer" : selectedUserDetails.Role));
		setServers(serverValues);
		setProductOwnerServers(unwanted.includes(selectedUserDetails.ProductOwner) ? [] : selectedUserDetails.ProductOwner.replace(/^\[|\]$/g, '').split(",").map(item => item.trim()));
		setMilestoneAutoApprovalServers(milestoneAutoApprovalServersValues);
		setSameAsServer(milestoneAutoApprovalServersValues.length !== 0 && serverValues.every(item => milestoneAutoApprovalServersValues.includes(item)) && milestoneAutoApprovalServersValues.every(item => serverValues.includes(item)));

		setUserEmail(value);
	}

	function handleSameAsServerChange() 
	{
		if (sameAsServer === false) // going to change to true
		{
			setMilestoneAutoApprovalServers(servers);
		}
		setSameAsServer(!sameAsServer);
	}

	function handleSetServers(value) 
	{
		if (value.every(item => milestoneAutoApprovalServers.includes(item)) && milestoneAutoApprovalServers.every(item => value.includes(item))
			&& milestoneAutoApprovalServers.length !== 0) {
			setSameAsServer(true);
		}
		else {
			setSameAsServer(false);
		}
		setServers(value);
	}

	function handleSetMilestoneAutoApprovalServers(value) 
	{
		if (value.every(item => servers.includes(item)) && servers.every(item => value.includes(item))
			&& servers.length !== 0) {
			setSameAsServer(true);
		}
		else {
			setSameAsServer(false);
		}
		setMilestoneAutoApprovalServers(value);
	}

	async function handleFormSubmit() 
	{
		setFormDisabled(true);
		setShowBanner(true);
		
		try {
			setBannerSeverity("info");
			const result = await postForm();
			setBannerSeverity("success");

			setUserEmail("");
			setPermission("");
			setServers([]);
			setProductOwnerServers([]);
			setMilestoneAutoApprovalServers([]);
			setSameAsServer(false);
			setFormDisabled(false);

			setTimeout(() => {
				setShowBanner(false);
				window.location.reload();
			}, 1000);

		}
		catch (error) {
			setTimeout(() => {
				setShowBanner(false);
			}, 3000);
			setFormDisabled(false);
			// console.log(error);
			setBannerSeverity("error");
		}

	}

	function handleFormReset() 
	{
		setFormDisabled(true);

		setUserEmail("");
		setPermission("");
		setServers([]);
		setProductOwnerServers([]);
		setMilestoneAutoApprovalServers([]);
		setSameAsServer(false);

		setFormDisabled(false);
	}

	async function postForm() 
	{
		var paramString = "?";
		paramString += "UserEmail=" + userEmail;
		paramString += "&Permission=" + permission;
		paramString += "&Servers=" + servers;
		paramString += "&ProductOwnerServers=" + productOwnerServers;
		paramString += "&MilestoneAutoApprovalServers=" + milestoneAutoApprovalServers;

		return new Promise((resolve, reject) => {
			updateRolesInCreator(paramString)
				.then((result) => {
					// console.log(result);
					resolve("success");
				}).catch((err) => {
					// console.log(err);
					reject(err)
				});

			// setTimeout(() => {
			// 	console.log("UserEmail: " + userEmail);
			// 	console.log("Permission: " + permission);
			// 	console.log("Servers: " + servers);
			// 	console.log("ProductOwnerServers: " + productOwnerServers);
			// 	console.log("MilestoneAutoApprovalServers: " + milestoneAutoApprovalServers);
			// 	console.log("SameAsServer: " + sameAsServer);
			// 	resolve("success");
			// }, 2000);
		});
	}

	// console.log(productOwnerServers);

	return (
		<div className="page-content-2" style={{ position: "relative" }}>
			<Snackbar open={showBanner} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				style={{ position: "absolute" }}>
				<Alert severity={bannerSeverity}>
					{bannerSeverity === "success" ? "Successfully Updated! 👍" : (bannerSeverity === "error" ? "Unable to Update :(" : "updating...")}
				</Alert>
			</Snackbar>
			<Paper className="roles-paper-surface" style={{ padding: 50 }}>
				<h2 style={{ margin: 0, paddingBottom: 6 }}>Access Control</h2>
				<Divider light />
				<form autoComplete="off" style={{
					marginTop: 25,
					pointerEvents: formDisabled ? "none" : "initial",
					opacity: formDisabled ? 0.25 : 1
				}}
					// onSubmit={handleFormSubmit}
					onReset={handleFormReset}
				>
					<TableContainer component={Paper} className="roles-table-container"
						sx={{
							marginBottom: "25px"
						}}>
						<Table sx={{
							[`& .${tableCellClasses.root}`]: {
								borderBottom: "none"
							}
						}}>
							<TableBody>
								<TableRow>
									<TableCell style={{ width: "300px" }} >
										<p style={{ fontSize: "15px" }}>User</p>
									</TableCell>
									<TableCell>
										<SingleSelect options={userValues} selectedOption={userEmail} label={userValues.map((value, ind) => <GetNameAndEmpID key={ind} emails={value} />)}
											setSelectedOption={handleSetUserEmail} height={"60px"} required={true} />
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={{ width: "300px" }} >
										<p style={{ fontSize: "15px" }}>Role</p>
									</TableCell>
									<TableCell>
										<SingleSelect options={allPermissions} selectedOption={permission} label={allPermissions}
											setSelectedOption={setPermission} height={"40px"} required={true} />
									</TableCell>
								</TableRow>
								{/* <TableRow>
									<TableCell style={{ width: "300px" }} >
										<p style={{ fontSize: "15px" }}>Super Admin</p>
									</TableCell>
									<TableCell>
										<IOSSwitch onChange={handleSuperAdminChange} checked={superAdmin} />
									</TableCell>
								</TableRow> */}
								<TableRow>
									<TableCell style={{ width: "300px" }} >
										<p style={{ fontSize: "15px" }}>Servers</p>
									</TableCell>
									<TableCell>
										<MultiSelect options={allServers} selectedOptions={servers} label={allServers}
											setSelectedOptions={handleSetServers} height={"40px"} />
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={{ width: "300px" }} >
										<p style={{ fontSize: "15px" }}>Product Owner</p>
									</TableCell>
									<TableCell>
										<MultiSelect options={allProductOwnerServers} selectedOptions={productOwnerServers} label={allProductOwnerServers}
											setSelectedOptions={setProductOwnerServers} height={"40px"} />
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={{ width: "300px" }} >
										<p style={{ fontSize: "15px" }}>Milestone Auto Approval Same as the Servers?</p>
									</TableCell>
									<TableCell>
										<IOSSwitch onChange={handleSameAsServerChange} checked={sameAsServer} />
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={{ width: "300px" }} >
										<p style={{ fontSize: "15px" }}>Milestone Auto Approval for</p>
									</TableCell>
									<TableCell>
										<MultiSelect options={allMilestoneAutoApprovalServers} selectedOptions={milestoneAutoApprovalServers} label={allMilestoneAutoApprovalServers}
											setSelectedOptions={handleSetMilestoneAutoApprovalServers} height={"40px"} />
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					{/* <Divider light /> */}
					<Button variant="contained" onClick={handleFormSubmit} style={{ marginTop: 5 }}>Submit</Button>
					<Button variant="outlined" type={"reset"} style={{ marginTop: 5, marginLeft: 20 }}>Reset</Button>
				</form>
			</Paper>
		</div>
	);
}

function MultiSelect({ options, selectedOptions, label, setSelectedOptions, height, required = false }) {
	const [clearButton, setClearButton] = useState(false);

	function handleSelect(event) 
	{
		setSelectedOptions(event.target.value);
	}

	function handleMouseEnter() 
	{
		if (selectedOptions.length === 0) {
			return;
		}
		setClearButton(true);
	}

	function handleMouseLeave() 
	{
		if (selectedOptions.length === 0) {
			return;
		}
		setClearButton(false);
	}

	function handleFocus() 
	{
		if (selectedOptions.length === 0) {
			return;
		}
		setClearButton(true);
	}

	function handleUnfocus(event) 
	{
		setTimeout(() => setClearButton(false), 200);
	}

	function handleClearClick() 
	{
		setSelectedOptions([]);
		setClearButton(false);
	}

	return (
		<Box style={{
			display: "flex",
			flexDirection: "row",
			justifyContent: "left"
		}}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<Select
				multiple
				required={required}
				autoFocus="true"
				value={selectedOptions}
				onChange={handleSelect}
				onFocus={handleFocus}
				onBlur={handleUnfocus}
				style={{
					minHeight: height,
					height: "auto",
					minWidth: "250px",
					maxWidth: "800px"
				}}
				MenuProps={{
					style: {
						maxHeight: 500
					},
					style: { maxWidth: 0, maxHeight: 500, position: 'absolute' },
					disableScrollLock: true,
					autoFocus: true
				}}
				renderValue={(selected) => (
					<Stack gap={1} direction="row" flexWrap="wrap">
						{selectedOptions.map((value) => (
							<Chip
								key={value}
								label={value}
								onDelete={() =>
									setSelectedOptions(
										selectedOptions.filter((item) => item !== value)
									)
								}
								deleteIcon={
									<CancelIcon
										onMouseDown={(event) => event.stopPropagation()}
									/>
								}
							/>
						))}
					</Stack>
				)}
			>
				{options.map((opt, index) => (
					<MenuItem key={index} value={opt}>
						{label[index]}
					</MenuItem>
				))}
			</Select>
			<Button style={{ visibility: clearButton ? "visible" : "hidden" }} onClick={handleClearClick}>
				<CancelIcon fontSize="small" color="error" />
			</Button>
		</Box>
	);
}

function SingleSelect({ options, selectedOption, label, setSelectedOption, height, required = false }) {
	const [clearButton, setClearButton] = useState(false);

	function handleSelect(event) 
	{
		setSelectedOption(event.target.value);
		setClearButton(true);
	}

	function handleMouseEnter() 
	{
		if (selectedOption === "") {
			return;
		}
		setClearButton(true);
	}

	function handleMouseLeave() 
	{
		if (selectedOption === "") {
			return;
		}
		setClearButton(false);
	}

	function handleClearClick() 
	{
		setSelectedOption("");
		setClearButton(false);
	}

	function handleUnfocus(event) 
	{
		setTimeout(() => setClearButton(false), 200);
	}

	return (
		<Box style={{
			display: "flex",
			flexDirection: "row",
			justifyContent: "left"
		}}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<Select
				required={required}
				labelId="role-user-email"
				value={selectedOption}
				style={{
					minHeight: height,
					height: "auto",
					minWidth: "250px",
					maxWidth: "400px"
				}}
				MenuProps={{
					style: {
						maxHeight: 600
					}
				}}
				onChange={handleSelect}
				onBlur={handleUnfocus}
			>
				{options.map((value, index) => (
					<MenuItem key={index} value={value}>{label[index]}</MenuItem>
				))};
			</Select>
			<Button style={{ visibility: clearButton ? "visible" : "hidden" }} onClick={handleClearClick}>
				<CancelIcon fontSize="small" color="error" />
			</Button>
		</Box>
	);
}

const IOSSwitch = styled((props) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} onChange={props.onChange} checked={props.checked} />
))(({ theme }) => ({
	width: 46,
	height: 26,
	padding: 0,
	'& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 2,
		transitionDuration: '250ms',
		'&.Mui-checked': {
			transform: 'translateX(20px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				backgroundColor: theme.palette.mode === 'dark' ? 'primary' : 'primary',
				opacity: 1,
				border: 0
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.5
			}
		},
		'&.Mui-focusVisible .MuiSwitch-thumb': {
			color: '#33cf4d',
			border: '6px solid #fff'
		},
		'&.Mui-disabled .MuiSwitch-thumb': {
			color:
				theme.palette.mode === 'light'
					? theme.palette.grey[100]
					: theme.palette.grey[600]
		},
		'&.Mui-disabled + .MuiSwitch-track': {
			opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
		}
	},
	'& .MuiSwitch-thumb': {
		boxSizing: 'border-box',
		width: 22,
		height: 22
	},
	'& .MuiSwitch-track': {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
		opacity: 1,
		transition: theme.transitions.create(['background-color'], {
			duration: 500
		})
	}
}));

export default Roles;