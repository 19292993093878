/* eslint-disable react/prop-types */
import React, { useState, useContext, useRef, useEffect, createContext } from 'react';
import {
    Grid,
    Paper,
    Box,
    Button,
    ButtonGroup,
    Menu,
    MenuItem,
    Popper,
    Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { UserContext } from '../../../../../contexts/UserContext.js';
import { ReportContext } from './NewReport.js';

import TodayIcon from '@mui/icons-material/Today';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import HistoryIcon from '@mui/icons-material/History';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import DnsIcon from '@mui/icons-material/Dns';
import Person3Icon from '@mui/icons-material/Person3';
import Groups3Icon from '@mui/icons-material/Groups3';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown.js';
import { EmployeeCard } from '../../../../../util/Loading.js';
import { useTheme } from '@emotion/react';
import { getTimeRange } from './NewReportsUtil.js';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import Fade from '@mui/material/Fade';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { it } from 'faker/lib/locales.js';

function Item({ children }) {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            height: "100%",
            alignItems: "center",
            justifyContent: "center"
        }}>
            {children}
        </Box>
    );
}


export function NewReportTopBar() {

    const {
        subjectType,
        setSubjectType,
        subject,
        setSubject,
        timeFrame,
        setTimeFrame,
        time,
        setTime,
        timeRange,
        setTimeRange
    } = useContext(ReportContext);

    // const { allServers } = useContext(UserContext);
    const { teamDetails,allServers ,GlobalServer} = useContext(UserContext);
    var allUsers = Object.keys(teamDetails.current).sort();
    allUsers = allUsers.filter((item) => { return item !== "" });
    var SelectedGlobalServer = (GlobalServer === "unknown" || GlobalServer === undefined) ?  allServers[0] : GlobalServer;
    // console.log(SelectedGlobalServer)
    var prev_serverState = useRef(SelectedGlobalServer);
    var prev_mentorState = useRef(allUsers[0]);
    var prev_devState = useRef(allUsers[0]);

    useEffect(() => {
        setSubject(SelectedGlobalServer);
   }, [GlobalServer]);

   

    return (
        <Box sx={{
        }}>
            <Grid container spacing={0}>
                <Grid item xs={2.6}>
                    <Item>
                        <TimeFrame time={time} timeFrame={timeFrame} setTimeFrame={setTimeFrame} setTimeRange={setTimeRange} />
                    </Item>
                </Grid>
                <Grid item xs={1.7}>
                    <Item>
                        <Time time={time} setTime={setTime} timeFrame={timeFrame} setTimeRange={setTimeRange} />
                    </Item>
                </Grid>
                <Grid item xs={2.6}>
                    <Item>
                        <TimeStamp timeRange={timeRange} setTimeRange={setTimeRange} timeFrame={timeFrame} />
                    </Item>
                </Grid>
                <Grid item xs={1.9}>
                    <Item>
                        <SubjectType subjectType={subjectType} setSubjectType={setSubjectType} subject={subject} setSubject={setSubject} prev_serverState={prev_serverState} prev_mentorState={prev_mentorState} prev_devState={prev_devState} />
                    </Item>
                </Grid>
                <Grid item xs={3.2}>
                    <Item >
                        <Subject subjectType={subjectType} subject={subject} setSubject={setSubject} prev_mentorState={prev_mentorState} prev_serverState={prev_serverState} prev_devState={prev_devState} />
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
}

function TimeFrame({ time, timeFrame, setTimeFrame, setTimeRange }) {
    return (
        <Box sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            width: "100%",
            flex: 1
        }}>
            <ButtonGroup size="small">
                <Button variant={timeFrame === "Daily" ? "contained" : "outlined"} sx={{
                    borderRadius: "20px",
                    fontSize: "0.7rem",
                    textTransform: "none",
                    gap: "5px",
                    color: timeFrame === "Daily" ? "white" : "",
                    fontWeight: "800"
                }} onClick={() => {
                    setTimeFrame("Daily");
                    setTimeRange(getTimeRange(time, "Daily"));
                }} color="zohoRed">
                    <TodayIcon fontSize='small' />
                    Daily
                </Button>
                <Button variant={timeFrame === "Weekly" ? "contained" : "outlined"} sx={{
                    borderRadius: "20px",
                    fontSize: "0.7rem",
                    textTransform: "none",
                    gap: "5px",
                    color: timeFrame === "Weekly" ? "white" : "",
                    fontWeight: "800"
                }} onClick={() => {
                    setTimeFrame("Weekly");
                    setTimeRange(getTimeRange(time, "Weekly"));
                }} color="zohoRed">
                    <DateRangeIcon fontSize='small' />
                    Weekly
                </Button>
                <Button variant={timeFrame === "Monthly" ? "contained" : "outlined"} sx={{
                    borderRadius: "20px",
                    fontSize: "0.7rem",
                    textTransform: "none",
                    gap: "5px",
                    color: timeFrame === "Monthly" ? "white" : "",
                    fontWeight: "800"
                }} onClick={() => {
                    setTimeFrame("Monthly");
                    setTimeRange(getTimeRange(time, "Monthly"))
                }} color="zohoRed">
                    <CalendarMonthIcon fontSize='small' />
                    Monthly
                </Button>
            </ButtonGroup>
        </Box>
    );
}

function Time({ time, setTime, timeFrame, setTimeRange }) {
    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            flex: 1
        }}>
            <ButtonGroup size="small">
                <Button variant={time === "Past" ? "contained" : "outlined"} sx={{
                    borderRadius: "20px",
                    fontSize: "0.7rem",
                    textTransform: "none",
                    gap: "5px",
                    color: time === "Past" ? "white" : "",
                    fontWeight: "800"
                }} onClick={() => {
                    setTime("Past");
                    setTimeRange(getTimeRange("Past", timeFrame));
                }} color="zohoGreen">
                    <HistoryIcon fontSize='small' />
                    Past
                </Button>
                <Button variant={time === "Present" ? "contained" : "outlined"} sx={{
                    borderRadius: "20px",
                    fontSize: "0.7rem",
                    textTransform: "none",
                    gap: "5px",
                    color: time === "Present" ? "white" : "",
                    fontWeight: "800"
                }} onClick={() => {
                    setTime("Present");
                    setTimeRange(getTimeRange("Present", timeFrame));
                }} color="zohoGreen">
                    <WatchLaterIcon fontSize='small' />
                    Present
                </Button>
            </ButtonGroup>
        </Box>
    );
}

function SubjectType({ subjectType, setSubjectType, subject, setSubject, prev_mentorState, prev_serverState, prev_devState }) {

    const { allServers } = useContext(UserContext);
    const { teamDetails } = useContext(UserContext);
    var allUsers = Object.keys(teamDetails.current).sort();
    allUsers = allUsers.filter((item) => { return item !== "" });

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            flex: 1
        }}>
            <ButtonGroup size="small">
                <Tooltip title="Server">
                    <Button variant={subjectType === "Server" ? "contained" : "outlined"} sx={{
                        borderRadius: "20px",
                        fontSize: "0.7rem",
                        textTransform: "none",
                        gap: "5px",
                        color: subjectType === "Server" ? "black" : "",
                        fontWeight: "900"
                    }} onClick={() => {
                        setSubjectType("Server");
                        setSubject(prev_serverState.current);
                    }} color="zohoYellow">
                        <DnsIcon fontSize='small' />
                        {
                            subjectType === "Server" ? "Server" : null
                        }
                    </Button>
                </Tooltip>
                <Tooltip title="Mentor">
                    <Button variant={subjectType === "MentorTree" ? "contained" : "outlined"} sx={{
                        borderRadius: "20px",
                        fontSize: "0.7rem",
                        textTransform: "none",
                        gap: "5px",
                        color: subjectType === "MentorTree" ? "black" : "",
                        fontWeight: "900"
                    }} onClick={() => {
                        setSubjectType("MentorTree");
                        setSubject(prev_mentorState.current);
                    }} color="zohoYellow">
                        <Groups3Icon fontSize='small' />
                        {
                            subjectType === "MentorTree" ? "Mentor" : null
                        }
                    </Button>
                </Tooltip>
                <Tooltip title="Developer">
                    <Button variant={subjectType === "Mentor" ? "contained" : "outlined"} sx={{
                        borderRadius: "20px",
                        fontSize: "0.7rem",
                        textTransform: "none",
                        gap: "5px",
                        color: subjectType === "Mentor" ? "black" : "",
                        fontWeight: "900"
                    }} onClick={() => {
                        setSubjectType("Mentor");
                        setSubject(prev_devState.current);
                    }} color="zohoYellow">
                        <Person3Icon fontSize='small' />
                        {
                            subjectType === "Mentor" ? "Developer" : null
                        }
                    </Button>
                </Tooltip>
            </ButtonGroup>
        </Box>
    );
}

function Subject({ subjectType, subject, setSubject, prev_mentorState, prev_serverState, prev_devState }) {

    const theme = useTheme();
    const [anchor, setAnchor] = useState(null);
    const { allServers,teamDetails,GlobalServer} = useContext(UserContext);
    var SelectedGlobalServer = (GlobalServer === "unknown" || GlobalServer === undefined) ?  "No Server" : GlobalServer;
    var users = teamDetails.current;

    if (subjectType === "MentorTree") {
        var mentorUsers = {};
        for (var usr in users) {
            if (usr === "mariaraj@zohocorp.com") {
                continue;
            }
            if (users[usr].Role === "Mentor" || users[usr].Role === "CEO") {
                mentorUsers[usr] = users[usr];
            }
        }
        users = mentorUsers;
    }

    var allUsers = Object.keys(users).sort();
    allUsers = allUsers.filter((item) => { return item !== "" });

    function handleSubjectClick(event, item) {

        if (subjectType === "Server") {
            prev_serverState.current = item;
        } else if (subjectType === "MentorTree") {
            prev_mentorState.current = item;
        } else {
            prev_devState.current = item;
        }

        setSubject(item);
        setAnchor(null);
    }

    return (
        <Box sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "right"
        }}>
            <Button variant="contained" size="small" onClick={(event) => setAnchor(event.currentTarget)} style={{
                textTransform: "none",
                padding: "0px",
                paddingRight: "5px",
                borderRadius: "200px",
                fontWeight: "900",
                borderWidth: "2px"
            }} disableRipple="true">
                {
                    subjectType === "Server" ?
                        <>
                            <Box sx={{
                                padding: "6.5px",
                                paddingLeft: "15px"
                            }}>
                                {subject}
                            </Box>
                            {SelectedGlobalServer === "No Server" ? (<ArrowDropDownIcon />):(<></>)}
                            
                        </>
                        :
                        <>
                            <EmployeeCard emails={subject} />
                            <ArrowDropDownIcon />
                        </>
                }
            </Button>
            <Menu
                anchorEl={anchor}
                open={anchor !== null}
                onClose={() => setAnchor(null)}
                sx={{
                    maxHeight: "750px"
                }}
            >
                {
                    subjectType === "Server" ?
                        allServers.map((item, index) => {
                            return (
                                SelectedGlobalServer === "No Server" ?
                                (
                                    <MenuItem key={index} onClick={(event) => handleSubjectClick(event, item)}>
                                        {item}
                                    </MenuItem>
                                )
                                :
                                (
                                    <></>
                                )
                            );
                        })
                        :
                        allUsers.map((item, index) => {
                            return (
                                <MenuItem key={index} onClick={(event) => handleSubjectClick(event, item)}>
                                    {<EmployeeCard emails={item} />}
                                </MenuItem>
                            );
                        })
                }
            </Menu>
        </Box>
    );
}

function TimeStamp({ timeRange, setTimeRange, timeFrame }) {

    const [startTime, endTime] = timeRange;
    const [anchor1, setAnchor1] = useState(null);
    const [anchor2, setAnchor2] = useState(null);

    function handleClick1(event) {
        if (anchor2 !== null) {
            setAnchor2(null);
        }
        if (anchor1 !== null) {
            setAnchor1(null);
        } else {
            setAnchor1(event.currentTarget);
        }
    }

    function handleClick2(event) {
        if (anchor1 !== null) {
            setAnchor1(null);
        }
        if (anchor2 !== null) {
            setAnchor2(null);
        } else {
            setAnchor2(event.currentTarget);
        }
    }

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            flex: 1
        }}>
            <ButtonGroup size="small">
                <Button variant={"contained"} sx={{
                    borderRadius: "20px",
                    fontSize: "0.7rem",
                    textTransform: "none",
                    gap: "5px",
                    color: "white",
                    fontWeight: "800"
                }} color="zohoBlue"
                    onClick={handleClick1}
                >
                    {formatDate(startTime)}
                </Button>
                {
                    timeFrame !== "Daily" ?
                        <Button variant={"outlined"} sx={{
                            borderRadius: "20px",
                            fontSize: "0.7rem",
                            textTransform: "none",
                            gap: "5px",
                            fontWeight: "900"
                        }} color="zohoBlue">
                            to
                        </Button>
                        :
                        null
                }
                {
                    timeFrame !== "Daily" ?
                        <Button variant={"contained"} sx={{
                            borderRadius: "20px",
                            fontSize: "0.7rem",
                            textTransform: "none",
                            gap: "5px",
                            color: "white",
                            fontWeight: "800"
                        }} color="zohoBlue"
                            onClick={handleClick2}
                        >
                            {formatDate(endTime)}
                        </Button>
                        :
                        null
                }

            </ButtonGroup>
            <DateSetter date={startTime} setDate={(date) => {
                setTimeRange([date, endTime]);
            }} open={anchor1 !== null} setAnchor={setAnchor1} anchorEl={anchor1} />
            <DateSetter date={endTime} setDate={(date) => {
                setTimeRange([startTime, date]);
            }} open={anchor2 !== null} setAnchor={setAnchor2} anchorEl={anchor2} />
        </Box>
    );
}

function formatDate(date) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
}

function DateSetter({ date, setDate, open, setAnchor, anchorEl }) {
    const theme = useTheme();
    const [value, setValue] = useState(dayjs(date));

    return (
        <Popper open={open} anchorEl={anchorEl} modifiers={[
            {
                name: 'flip',
                enabled: false,
                options: {
                    altBoundary: false,
                    rootBoundary: 'document',
                    padding: 0
                }
            },
            {
                name: 'preventOverflow',
                enabled: false,
                options: {
                    altAxis: false,
                    altBoundary: false,
                    tether: true,
                    rootBoundary: 'document',
                    padding: 0
                }
            }
        ]} transition>
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Box sx={{
                        bgcolor: theme.palette.mode === "light" ? "rgb(250,250,250)" : "rgb(39,43,51)",
                        color: theme.palette.background.text,
                        borderRadius: "10px",
                        marginTop: "10px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "10px",
                        boxShadow: "1px 1px 10px 1px " + (theme.palette.mode === "light" ? "grey" : "black")
                    }} >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={value}
                                closeOnSelect={false}
                                onChange={(newValue) => {
                                    setValue(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        <Button onClick={() => {
                            setAnchor(null);
                            setDate(value.toDate());
                        }}>
                            Done
                        </Button>
                    </Box>
                </Fade>
            )}
        </Popper>
    );
}