import React, { useState, useRef, useEffect, useContext, useLayoutEffect } from 'react';
//import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded';

import Dialog from '@mui/material/Dialog';
//import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
//import Skeleton from '@mui/material/Skeleton';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';
import  { createTheme, ThemeProvider, styled }  from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveIcon from '@mui/icons-material/Remove';
import { keyframes } from '@mui/system';

import ArrowCircleUpTwoToneIcon from '@mui/icons-material/ArrowCircleUpTwoTone';
import ArrowCircleDownTwoToneIcon from '@mui/icons-material/ArrowCircleDownTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import Brightness1TwoToneIcon from '@mui/icons-material/Brightness1TwoTone';
import HexagonIcon from '@mui/icons-material/Hexagon';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';

import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import FilterListIcon from '@mui/icons-material/FilterList';
import BlockIcon from '@mui/icons-material/Block';
import AllOutIcon from '@mui/icons-material/AllOut';
import Alert from '@mui/material/Alert';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { group_by_order, find_row_span } from './../../../util/GroupingFunctions.js';
import { GetNameAndEmpID, MultiLevelDropdown } from '../../../util/Loading.js';
import { Skeleton } from '@mui/material';

import { useTheme } from '@mui/material';
import './TaskBreaker.css';
import { getFromTaskBreaker, getFromTeamDetails } from './TBDataFetcher.js';
import { Fade } from '@mui/material';

import { UserContext } from '../../../contexts/UserContext.js';
import { TabStateContext } from '../../../contexts/TabStateContext.js';

import { lazyFetch, lazyLoader } from '../../../util/QueryDB.js';
import { taskBreakerTransformFunction } from './TBDataFetcher.js';
import { task_breaker_component_get_query_1, task_breaker_component_get_query_2, task_breaker_component_get_query_3, task_breaker_component_get_query_4, task_breaker_component_get_query_5 } from '../../../catalystDB/queryGenerator.js';
import { OtherDRI } from '../../../util/OtherDRI.js';

function TaskBreaker() {
	const [searchText, setSearchText] = useState("");

	const [initialRows, setInitialRows] = useState([]);
	const [rows, setRows] = useState([]);
	const [hasData, setHasData] = useState(null);

	const [driList, setDriList] = useState([]);
	const [driTree, setDriTree] = useState([]);

	const [serversList, setServersList] = useState([]);
	const { userDetails, teamDetails, allServers, loginUserDept, configuration,GlobalServer } = useContext(UserContext);
	const user_email = userDetails.current.auth.email_id;
	const toggle = useRef(false);

	// const userServers = teamDetails.current[user_email].Servers.split(",").filter(server => {
	// 	return allServers.includes(server);
	// });
	// console.log(userServers)
	const localStorageFetchedServer = localStorage.getItem('selectedServerTaskBreaker');
	// const userServers =(GlobalServer === "unknown" || GlobalServer === undefined) ?  teamDetails.current[user_email].Servers.split(",").filter(server => {
	// 	return allServers.includes(server);
	// })
    // : GlobalServer.split(",");

	var userServers = (GlobalServer === "unknown" || GlobalServer === undefined) ? (localStorageFetchedServer !== null) ? [localStorageFetchedServer] : teamDetails.current[user_email].Servers.split(",").filter(server => {
		return allServers.includes(server);
	}) :  GlobalServer.split(",");
	var allServer = teamDetails.current[user_email].Servers.split(",").filter(server => { return allServers.includes(server); })

	
	// console.log(userserver1)
	
	const [sortBy, setSortBy] = useState("Default");
	const [filterBy, setFilterBy] = useState(userServers);



	const userserver = teamDetails.current[user_email].Servers;
	const query_String_Suffix = "('" + userserver.replaceAll(",", "','") + "')";
	const user_role = teamDetails.current[user_email].Role;
	// var query = `SELECT * FROM TaskBreaker WHERE ReportName = 'TaskBreaker_Data_Report' AND Component IN ${query_String_Suffix} AND TaskStatus not in ('Closed', 'Cancelled') ORDER BY Component`;
	// if(user_role === "CEO") {
	// 	query = `SELECT * FROM TaskBreaker WHERE ReportName= 'TaskBreaker_Data_Report' AND TaskStatus not in ('Closed', 'Cancelled') ORDER BY Component`;
	// }

	var queryFilter = ` ( Component in ('${filterBy.join("','")}') ) `;
	var query = task_breaker_component_get_query_1(queryFilter);

	const compareFunctions = {
		"Server": serverCompareFunction,
		"Type": typeCompareFunction,
		"MilestoneName": milestoneNameCompareFunction,
		"Priority": priorityCompareFunction,
		"TaskName": tasknameCompareFunction,
		"Status": statusCompareFunction,
		"DRI": driCompareFunction,
		"Reporting To": taskBreakerCompareFunction
	}

	const [lazyLoad, setLazyLoad] = useState(lazyLoader(query, taskBreakerTransformFunction));


	useEffect(() => {
		async function fetchData() {
			try {
				await setDriDropDown(setDriList, setDriTree, userDetails, teamDetails, loginUserDept.current, configuration);
				await setServerDropDown(setServersList, userDetails, teamDetails, allServers);
			}
			catch (error) {
				console.log(error);
			}

			if (lazyLoad !== null && ((GlobalServer === "unknown" || GlobalServer === undefined) && localStorageFetchedServer === null)) {
				try {
					const result = await lazyLoad.next();

					if (result.value === undefined || result.value.content === undefined) {
						setHasData(false);
						setLazyLoad(null);
					} else if (result.value.isLast) {
						setHasData(true);
						setInitialRows(result.value.content);
						setLazyLoad(null);
					} else {
						setHasData(true);
						setInitialRows(result.value.content);
					}

					// let result = await getFromTaskBreaker(queryString);
					// // console.log(result);
					// setHasData(result.length > 0);
					// setInitialRows(result);
				} catch (error) {
					console.log(error);
					setHasData(false);
				}
			}
		}
		fetchData();

	}, []);
    
	window.addEventListener('beforeunload', () => {
        localStorage.removeItem('selectedServerTaskBreaker');
    });

	var rowsNew = group_by_order(initialRows, [2, 0], 0);

	const hookFlag = useRef(true);

	useEffect(() => {
		async function run() {
			if(hookFlag.current) {
				hookFlag.current = false;
				return;
			}
			const toggleClosedFilter = toggle.current ? " (TaskStatus in ('Closed', 'Cancelled','Updated to IDC')) " : " (TaskStatus not in ('Closed', 'Cancelled','Updated to IDC')) ";
			// setSearchText("");
			if (filterBy.includes("Default")) {
				queryFilter = `( Component in ${query_String_Suffix} )`;
	
					// const query = `SELECT * FROM TaskBreaker WHERE (${queryFilter}) AND ReportName= 'Roadmap_Form_Report' AND TaskStatus not in ('Closed', 'Cancelled') AND Tasklist_created_for_roadmap is null ORDER BY Component`;
				var qString = task_breaker_component_get_query_2(queryFilter, query_String_Suffix, toggleClosedFilter);
				if (user_role === "CEO") {
					qString =  task_breaker_component_get_query_3(queryFilter, toggleClosedFilter);
				}
				try {
					setHasData(null);
					const newLazyLoader = lazyLoader(qString, taskBreakerTransformFunction);
					setLazyLoad(newLazyLoader);
					const result = await newLazyLoader.next();
					if (result.value === undefined || result.value.content === undefined) {
						setHasData(false);
						setLazyLoad(null);
					} else if (result.value.isLast) {
						setHasData(true);
						setInitialRows(result.value.content);
						setLazyLoad(null);
					} else {
						setHasData(true);
						setInitialRows(result.value.content);
					}
				}
				catch (error) {
					console.log(error);
					setHasData(false);
					setLazyLoad(null);
				}
			} else {
				// var filters = [];
				// for(var item of filterBy) {
				// 	if(item.includes("@zohocorp.com")) {
				// 		filters.push(`DRI= '${item}'`);
				// 	} else {
				// 		filters.push(`Component= '${item}'`);
				// 	}
				// }
				// var queryFilter = filters.join(" OR ");
	
				var driFilter = [];
				var componentFilter = [];
				for(var item of filterBy) {
					if(item.includes("@zohocorp.com")) {
						driFilter.push(item);
					} else {
						if(GlobalServer === "unknown" || GlobalServer === undefined){
							componentFilter.push(item);
						}
						else{
							componentFilter.push(GlobalServer);
						}
						
					}
				}
				var queryFilter = "";
				if(componentFilter.length === 0 && driFilter.length !== 0) {
					queryFilter = ` ( DRI in ('${driFilter.join("','")}') ) `;
				} else if(driFilter.length === 0 && componentFilter.length !== 0) {
					queryFilter = ` ( Component in ('${componentFilter.join("','")}') ) `;
				} else if(componentFilter.length !== 0 && driFilter.length !== 0) {
					queryFilter = ` ( DRI in ('${driFilter.join("','")}') AND  Component in ('${componentFilter.join("','")}') ) `;
				}
	
				var qString = task_breaker_component_get_query_4(queryFilter, toggleClosedFilter);
				setHasData(null);
				const newLazyLoader = lazyLoader(qString, taskBreakerTransformFunction);
				setLazyLoad(newLazyLoader);
				const result = await newLazyLoader.next();
				if (result.value.content === undefined) {
					setHasData(false);
					setLazyLoad(null);
				} else if (result.value.isLast) {
					setHasData(true);
					setInitialRows(result.value.content);
					setLazyLoad(null);
				} else {
					setHasData(true);
					setInitialRows(result.value.content);
				}
			}
		}
		run();
	}, [filterBy, toggle.current]);

	useEffect(() => {
		if(GlobalServer != "unknown" && GlobalServer === undefined){
			setFilterBy(userServers)
		}
		else if(GlobalServer != "unknown" && GlobalServer != undefined){
			setFilterBy([GlobalServer])
		}
		// console.log("hi")
	  }, [GlobalServer]);


	
	useEffect(() => {
		if(sortBy !== "Default") {
			rowsNew = rowsNew.sort(compareFunctions[sortBy]);
		}
		setRows(rowsNew);
	}, [initialRows, sortBy]);

	var content = (
		<>
			<TaskBreakerTopBar rowsCurrent={rowsNew} setRows={(r) => { setRows(r) }} setSearchText={setSearchText} drisList={driList} drisTree={driTree} serversList={serversList.sort()}
						toggle={toggle} setHasData={setHasData} setInitialRows={setInitialRows} userEmail={user_email} userRole={user_role} queryStringSuffix={query_String_Suffix} query={query} 
						lazyLoad={lazyLoad} setLazyLoad={setLazyLoad} sortBy={sortBy} setSortBy={setSortBy} filterBy={filterBy} setFilterBy={setFilterBy} searchText={""} userServers={userServers} GlobalServer={GlobalServer} allServer={allServer}/>
			<Skeleton variant="rectangular" width={"100%"} sx={{ flex: 1 }} />
		</>
	);

	const theme = useTheme();

	if (initialRows.length !== 0 && hasData) {
		content = (
			<Fade in={true}>
				<div>
					<TaskBreakerTopBar rowsCurrent={rowsNew} setRows={(r) => { setRows(r) }} setSearchText={setSearchText} drisList={driList} drisTree={driTree} serversList={serversList.sort()}
						toggle={toggle} setHasData={setHasData} setInitialRows={setInitialRows} userEmail={user_email} userRole={user_role} queryStringSuffix={query_String_Suffix} query={query} 
						lazyLoad={lazyLoad} setLazyLoad={setLazyLoad} sortBy={sortBy} setSortBy={setSortBy} filterBy={filterBy} setFilterBy={setFilterBy} searchText={searchText} userServers={userServers} GlobalServer={GlobalServer} allServer={allServer}/>
					<TaskBreakerTable toggle={toggle} rows={rows} searchText={searchText} lazyLoad={lazyLoad} setInitialRows={setInitialRows} initialRows={initialRows}
						setLazyLoad={setLazyLoad} filterBy={filterBy} setFilterBy={setFilterBy}/>
				</div>
			</Fade>
		);
	}
	else if (hasData === false) {
		content = (
			<Fade in={true}>
				<div style={{ color: theme.palette.background.text }}>
					<TaskBreakerTopBar rowsCurrent={rowsNew} setRows={(r) => setRows(r)} setSearchText={setSearchText} drisList={driList} drisTree={driTree} serversList={serversList.sort()}
						toggle={toggle} setHasData={setHasData} setInitialRows={setInitialRows} userEmail={user_email} userRole={user_role} queryStringSuffix={query_String_Suffix} query={query} 
						lazyLoad={lazyLoad} setLazyLoad={setLazyLoad} sortBy={sortBy} setSortBy={setSortBy} filterBy={filterBy} setFilterBy={setFilterBy} searchText={searchText} userServers={userServers} GlobalServer={GlobalServer} allServer={allServer}/>
					<h1>No Tasks Found :(</h1>
				</div>
			</Fade>
		);
	}
	return (
		<div className="page-content-2">
			{content}
		</div>
	);
}

function TaskBreakerTopBar({ toggle, setRows, rowsCurrent, setSearchText, drisList, drisTree, serversList, setHasData, setInitialRows, userEmail, userRole, queryStringSuffix,
	query, lazyLoad, setLazyLoad, sortBy, setSortBy, filterBy, setFilterBy, searchText,userServers,GlobalServer,allServer}) {
	// const drisMenuList = drisList.map((item, index) => {
	// 	return <MenuItem key={index} onClick={() => handleDriItemClick(item)}>{item}</MenuItem>
	// });

	const serversMenuList = serversList.map((item, index) => {
		return <MenuItem key={index} onClick={() => handleServerItemClick(item)}>{item}</MenuItem>
	});

	const [sortAnchor, setSortAnchor] = useState(null);
	const [filterAnchor, setFilterAnchor] = useState(null);
	const [driAnchor, setDriAnchor] = useState(null);
	const [serverAnchor, setServerAnchor] = useState(null);
	const [open, setOpen] = useState(false);
	const [minimize, setMinimize] = useState(false);

	function handleSortButtonClick(event) {
		setSortAnchor(event.currentTarget);
	}

	function handleSortButtonClose() {
		setSortAnchor(null);
	}

	function handleSearchButtonClick() {
		// setSearchText("");
		setOpen(!open);
	}

	function handleFilterButtonClick(event) {
		setFilterAnchor(event.currentTarget);
	}

	function handleFilterButtonClose() {
		setFilterAnchor(null);
	}

	function handleDriButtonClose() {
		setDriAnchor(null);
		setFilterAnchor(null);
	}

	function handleDriTreeButtonClose() {
		setDriAnchor(null);
		setFilterAnchor(null);
	}

	function handleServerButtonClose() {
		setServerAnchor(null);
		setFilterAnchor(null);
	}

	function handleSortItemClick(item) {
		setSortAnchor(null);
		if(item === "TaskBreaker") {
			setSortBy("Reporting To");
		} else {
			setSortBy(item); 
		}
		//var rowsNew;
		// if (item === "Default") {
		// 	const rowsNew = group_by_order(rowsCurrent, [2, 0], 0);
		// 	setRows(rowsNew);
		// }
		// else if (item === "Server") {
		// 	var rowsNew = [...rowsCurrent];
		// 	rowsNew.sort(serverCompareFunction);
		// 	setRows(rowsNew);
		// }
		// else if (item === "Type") {
		// 	var rowsNew = [...rowsCurrent];
		// 	rowsNew.sort(typeCompareFunction);
		// 	setRows(rowsNew);
		// }
		// else if (item === "Priority") {
		// 	var rowsNew = [...rowsCurrent];
		// 	rowsNew.sort(priorityCompareFunction);
		// 	setRows(rowsNew);
		// }
		// else if (item === "Status") {
		// 	var rowsNew = [...rowsCurrent];
		// 	rowsNew.sort(statusCompareFunction);
		// 	setRows(rowsNew);
		// }
		// else if (item === "TaskName") {
		// 	var rowsNew = [...rowsCurrent];
		// 	rowsNew.sort(tasknameCompareFunction);
		// 	setRows(rowsNew);
		// }
		// else if (item === "MilestoneName") {
		// 	var rowsNew = [...rowsCurrent];
		// 	rowsNew.sort(milestoneNameCompareFunction);
		// 	setRows(rowsNew);
		// }

		// else if (item === "DRI") {
		// 	var rowsNew = [...rowsCurrent];
		// 	rowsNew.sort(driCompareFunction);
		// 	setRows(rowsNew);
		// }
		// else if (item === "TaskBreaker") {
		// 	var rowsNew = [...rowsCurrent];
		// 	rowsNew.sort(taskBreakerCompareFunction);
		// 	setRows(rowsNew);
		// }
	}

	async function handleFilterItemClick(event, item) {
		if (item === "Server") {
			setServerAnchor(event.currentTarget);
		}
		else if (item === "Dri") {
			setDriAnchor(event.currentTarget);
		}

		else if (item === "Default") {
			// const rowsNew = group_by_order(rowsCurrent, [2, 0], 0);
			// setRows(rowsNew);
			setFilterBy(["Default"]);
			// try {
			// 	setHasData(null);
			// 	let result;
			// 	if (userRole === "CEO") {
			// 		result = await getFromTaskBreaker(`SELECT * FROM TaskBreaker WHERE ReportName= 'TaskBreaker_Data_Report'`);
			// 		// console.log(result);		
			// 	}
			// 	else {
			// 		result = await getFromTaskBreaker(`SELECT * FROM TaskBreaker WHERE ReportName = 'TaskBreaker_Data_Report' AND Component IN ${queryStringSuffix}`);
			// 		// console.log(result);	
			// 	}
			// 	setInitialRows(result);
			// 	setHasData(true);
			// }
			// catch (error) {
			// 	setHasData(false);
			// }
		}
	}


	// async function handleDriItemClick(item) {
	// 	try {
	// 		setHasData(null);
	// 		const result = await getFromTaskBreaker(`SELECT * FROM TaskBreaker WHERE DRI= '${item}' AND ReportName='TaskBreaker_Data_Report'`);
	// 		setInitialRows(result);
	// 		setHasData(true);
	// 	}
	// 	catch (error) {
	// 		setHasData(false);
	// 	};
	// }

	const handleDriTreeItemClick = async (item) => {
		// console.log(item);
		// try {
		// 	setHasData(null);
		// 	const result = await getFromTaskBreaker(`SELECT * FROM TaskBreaker WHERE DRI= '${item.Email}' AND ReportName='TaskBreaker_Data_Report'`);
		// 	setInitialRows(result);
		// 	setHasData(true);
		// 	//setCloseMenu(true);
		// }
		// catch (error) {
		// 	setHasData(false);
		// };

		var tempFilterBy = [...filterBy];
		var index = tempFilterBy.indexOf("Default");
		if (index > -1) {
			tempFilterBy.splice(index, 1);
		}
		index = tempFilterBy.indexOf(item.Email);
		if (index > -1) {
			tempFilterBy.splice(index, 1);
		}
		setFilterBy([item.Email, ...tempFilterBy]);
	};


	async function handleServerItemClick(item) {
		// try {
		// 	setHasData(null);
		// 	const result = await getFromTaskBreaker(`SELECT * FROM TaskBreaker WHERE Component = '${item}' AND ReportName='TaskBreaker_Data_Report'`);
		// 	// console.log(result);
		// 	setInitialRows(result);
		// 	setHasData(true);
		// }
		// catch (error) {
		// 	setHasData(false);
		// }

		var tempFilterBy = [...filterBy];
		var index = tempFilterBy.indexOf("Default");
		if (index > -1) {
			tempFilterBy.splice(index, 1);
		}
		index = tempFilterBy.indexOf(item);
		if (index > -1) {
			tempFilterBy.splice(index, 1);
		}
		// setFilterBy([item, ...tempFilterBy]);
		setFilterBy([item]);
		localStorage.setItem('selectedServerTaskBreaker', item);
	}

	function handleToggleClosedButtonClick() {
		toggle.current = !toggle.current;
		setHasData(null);
		// console.log(rowsCurrent);
		// console.log(toggle.current);
		// var rowsNew = rowsCurrent.filter(row_ => {
		// 	if (toggle.current) {
		// 		if (row_[5] === 'Closed') {
		// 			setRows(rowsNew);
		// 			return true;
		// 		}
		// 		return false;
		// 	}
		// 	else {
		// 		if (row_[5] === 'Closed') {
		// 			return false;
		// 		}
		// 		return true;
		// 	}
		// });
		// // console.log(rowsNew);
		// setRows(rowsNew);
	}

	function handleSearchTextChange(event) {
		setSearchText(event.target.value);
	}

	// const [anchorEl, setAnchorEl] = useState(null);
	const [openPopup, setOpenPopup] = useState(false);
	const [iframeLoaded, setIframeLoaded] = useState(false);

	const handleMenuOpen = (event) => {
		if(minimize) {
			setMinimize(false);
		} else {
			setOpenPopup(true);
		}
		// setAnchorEl(event.currentTarget);
	};

	// const handleMenuClose = () => {
	// 	setAnchorEl(null);
	// };

	// const handleAddItemClick = (event) => {
	// 	// Logic for handling the selected item in the dropdown
	// 	if (event.target.textContent === 'Create New TaskBreaker') {
	// 		setOpenPopup(true);
	// 	}
	// 	handleMenuClose(); // Close the dropdown menu
	// };

	const handleClosePopup = () => {
		if(milestoneName !== undefined || serverName !== undefined || tasklistName !== undefined) {
			const urlWithoutParams = window.location.origin + window.location.pathname + window.location.hash;
			window.location.href = urlWithoutParams;
		}


		setFilterBy([...filterBy]);
		setOpenPopup(false);
		setIframeLoaded(false);
	};


	const handleIframeLoad = () => {
		setIframeLoaded(true);
	};

	function handleEscKeyPress(event) {
		if(event.key === "Escape") {
			// setSearchText("");
			setOpen(false);
		}
	}

	function handleMinimizeClick(event) {
		setMinimize(true);
	}

	const params = new URL(window.location.href).searchParams;
	const milestoneName = params.get("MilestoneName");
	const serverName = params.get("ServerName");
	const tasklistName = params.get("Parameter_Passing_Tasklist");

	// console.log(milestoneName);
	// console.log(serverName);
	// console.log(tasklistName);

	if(milestoneName === null && serverName ===null && tasklistName === null )
	{
		var iframeUrl = "https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/Report?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true";
	}
	else
	{
		var iframeUrl = "https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/Report?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true";
		// iframeUrl = iframeUrl + "&ParameterPassing=" + milestoneName + "&Parameter_ServerName=" + serverName + "&Parameter_Passing_Tasklist=" + tasklistName;
	
			if(tasklistName.includes("`")){
				iframeUrl = iframeUrl + "&ParameterPassing=" + milestoneName + "&Parameter_ServerName=" +  serverName;
			}
			else{
				iframeUrl = iframeUrl + "&ParameterPassing=" + milestoneName + "&Parameter_ServerName=" +  serverName + "&Parameter_Passing_Tasklist=" + tasklistName;
			}
		

	}
	
	useLayoutEffect(() => {
		if (milestoneName != null && serverName != null && tasklistName != null) {
			setOpenPopup(true);
		}
	}, []);

	const grow = keyframes`
	0% {
		scale: 1;
	}
	20% {
		scale: 1.2;
		rotate: 0deg;
	}
	35% {
		rotate: -20deg;
	}
	45% {
		rotate: 20deg;
	}
	65% {
		rotate: -20deg;
	}
	80% {
		scale: 1.2;
		rotate: 0deg;
	}
	100% {
		scale: 1;
	}
	`;

	function handleChipClick(event, item) {
		if(event.detail === 2) {
			setFilterBy([item]);
			localStorage.setItem('selectedServerTaskBreaker', item); // Store the selected server
		}
	}
	
	return (
		<div className="mytasks-top-bar">
			{/* <div className="mytasks-top-bar-left-group">
				<ButtonGroup variant="contained" aria-label="outlined primary button group">
					<Button variant="contained" size="small"><AddIcon /></Button>
					<Button variant="contained" size="small"><EditNoteIcon /></Button>
				</ButtonGroup>
			</div> */}
			<div className="mytasks-top-bar-left-group" style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				gap: "20px",
			}}>
				<Button variant="contained" size="small" onClick={handleMenuOpen}  sx={{
					borderRadius: "100px",
					padding: 0.9,
					paddingRight: 1.2,
					paddingLeft: 1.2
				}}> 
					{
						minimize ?
							<OpenInFullRoundedIcon sx={{
								animation: `${grow} 1s ease`
							}}/>
							:
							<AddIcon sx={{fontSize: "1.4rem"}} />
					}
				</Button>
				{/* <Menu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleMenuClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
				>
					<MenuItem onClick={handleAddItemClick}>Create New TaskBreaker</MenuItem>
				</Menu> */}
				<Box style={{
					display: "flex",
					flexDirection: "row",
					gap: "10px",
					width: "calc(50vw)",
					overflowX: "scroll"
				}}
					className="filter-sort-overflow-container"
				>
					{
                        searchText !== "" ?
                            <Chip
                                label={
                                    <Box style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <Box style={{
                                            color: "#0c2c70",
                                            borderRight: "1px solid #103fa3",
                                            paddingRight: "6px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <SearchIcon />
                                        </Box>
                                        <Box style={{
                                            paddingLeft: "6px",
                                            color: "ghostwhite"
                                        }}>
                                            {searchText}
                                        </Box>
                                    </Box>
                                }
                                sx={{
                                    bgcolor: "#226DB4 !important",
                                    border: "1px solid #0c2c70"
                                }}
                                onDelete={() => {
                                    setSearchText("");
                                }}
                                deleteIcon={
                                    <CancelIcon
                                        onMouseDown={(event) => event.stopPropagation()}
                                        sx={{
                                            color: "#0c2c70 !important"
                                        }}
                                    />
                                }
                            />
                            :
                            <></>
                    }
					{
						sortBy !== "Default" ?
							<Chip
								label={
									<Box style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "center",
										alignItems: "center"
									}}>
										<Box style={{
											color: "#1c5326",
											borderRight: "1px solid #1c5326",
											paddingRight: "6px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center"
										}}>
											<SortIcon />
										</Box>
										<Box style={{
											paddingLeft: "6px",
											color: "ghostwhite",
											width: "70px",
											overflow: "hidden",
											textOverflow: "ellipsis",
											textAlign: "center"
										}}>
											<Tooltip title={sortBy}>
												{sortBy}
											</Tooltip>
										</Box>
									</Box>
								}
								sx={{
									bgcolor: "rgb(8,153,73,0.9) !important",
									border: "1px solid #1c5326"
								}}
								onDelete={() => {
									setSortBy("Default");
								}}
								deleteIcon={
									<CancelIcon
										onMouseDown={(event) => event.stopPropagation()}
										sx={{
											color: "#1c5326 !important"
										}}
									/>
								}
							/>
							:
							<></>
					}
					{
						!filterBy.includes("Default") ?
							filterBy.map((item, index) => {
								return (
									<Chip
										key={index}
										label={
											<Box style={{
												display: "flex",
												flexDirection: "row",
												justifyContent: "center",
												alignItems: "center"
											}} onClick={(event) => handleChipClick(event, item)}>
												<Box style={{
													color: "#331511",
													borderRight: "1px solid #53291c",
													paddingRight: "6px",
													display: "flex",
													alignItems: "center",
													justifyContent: "center"
												}}>
													<FilterAltOutlinedIcon />
												</Box>
												<Box style={{
													paddingLeft: "6px",
													color: "ghostwhite",
													width: "70px",
													overflow: "hidden",
													textOverflow: "ellipsis",
													textAlign: "center"
												}}>
													<Tooltip title={item}>
														{item}
													</Tooltip>
												</Box>
											</Box>
										}
										sx={{
											// bgcolor: "rgb(228,37,39, 0.9) !important"
											background: "rgba(177,44,9,1) !important",
											border: "1px solid #53291c"
										}}
										onDelete={() => {
											localStorage.removeItem('selectedServerTaskBreaker');
											var tempFilterBy = [...filterBy];
											var index = tempFilterBy.indexOf(item);
											// console.log(tempFilterBy)
											
											if (index > -1) {
												tempFilterBy.splice(index, 1);
												setFilterBy(tempFilterBy);
											}
											if (tempFilterBy.length === 0) {
												
												tempFilterBy.push("Zone");
												userServers = localStorage.getItem('selectedServerTaskBreaker') !== null ? [localStorage.getItem('selectedServerTaskBreaker')] : allServer;
												setFilterBy(userServers);
											}
											else if(tempFilterBy.length != 0){
												setFilterBy(tempFilterBy);
											}
										}}
										deleteIcon={
											GlobalServer === "unknown" ||  GlobalServer === undefined ? (
												<CancelIcon
												onMouseDown={(event) => event.stopPropagation()}
												sx={{
													color: "#53291c !important"
												}}
											/>
											) : (
												<></>
											)
										}
									/>
								);
							})
							:
							<></>
					}

					<div style={{
					}} className="unselectable">
						&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
					</div>
				</Box>
			</div>
			<Dialog open={openPopup} onClose={handleClosePopup} sx={{
				// zIndex: minimize ? -1 : 1300
				display: minimize ? "none" : "block"
			}} fullScreen>
				<DialogActions>
				{!navigator.onLine && (
					<Alert sx={{marginRight:"669px",width:"247px",alignItems:"center",textAlign:"center",justifyContent:"center"}} severity="error">Kindly check you network connection.</Alert>
				)}
					<Button onClick={handleMinimizeClick} variant="text" fontSize="large"><RemoveIcon/></Button>
					<Button onClick={handleClosePopup} variant="text" fontSize="large"><CloseIcon /></Button>
				</DialogActions>
				<DialogContent>
					{!iframeLoaded && (
						<div
							style={{
								position: 'relative',
								width: '100%',
								height: '100%',
								backgroundImage: 'linear-gradient(to right, #f0f0f0, #e0e0e0)',
								borderRadius: 0,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Skeleton
								variant="rectangular"
								width="100%"
								height="100%"
								animation="wave"
							/>
						</div>
					)}
					<iframe
						src={iframeUrl}
						width="100%"
						height="100%"
						title="External Content"
						onLoad={handleIframeLoad}
						frameBorder={0}
						style={{ display: iframeLoaded ? 'block' : 'none' }}
					/>
				</DialogContent>
			</Dialog>
			<div className="mytasks-top-bar-right-group">
				<ButtonGroup variant="contained" aria-label="outlined primary button group">
					<Collapse in={open} timeout="auto" orientation="horizontal" unmountOnExit>
						<TextField variant="outlined" placeholder="search" onChange={handleSearchTextChange} value={searchText} autoFocus onKeyDown={handleEscKeyPress}/>
					</Collapse>
					<Tooltip title="search"><Button variant="contained" size="small" onClick={handleSearchButtonClick}><SearchIcon /></Button></Tooltip>
					<Tooltip title={"toggle open/closed tasks"}>
						<Button variant="contained" size="small" onClick={handleToggleClosedButtonClick}>
							{!toggle.current ? <AllOutIcon /> : <BlockIcon />}
							{/* <BlockIcon />s */}
						</Button>
					</Tooltip>
					<Tooltip title="filter by"><Button variant="contained" size="small" onClick={handleFilterButtonClick}><FilterAltOutlinedIcon /><ArrowDropDownIcon /></Button></Tooltip>
					<Tooltip title="sort by"><Button variant="contained" size="small" onClick={handleSortButtonClick}><SortIcon /><ArrowDropDownIcon /></Button></Tooltip>
				</ButtonGroup>
				<Menu
					anchorEl={sortAnchor}
					open={sortAnchor !== null}
					onClose={handleSortButtonClose}
				>
					<MenuItem onClick={() => handleSortItemClick("Default")}>Default</MenuItem>
					<MenuItem onClick={() => handleSortItemClick("Server")}>Server</MenuItem>
					{(GlobalServer !== "unknown" && GlobalServer !== undefined) ?(
						<></>
					):(
						<MenuItem onClick={() => handleSortItemClick("Server")}>Server</MenuItem>
					)}
					<MenuItem onClick={() => handleSortItemClick("Type")}>Type</MenuItem>
					<MenuItem onClick={() => handleSortItemClick("Priority")}>Priority</MenuItem>
					<MenuItem onClick={() => handleSortItemClick("MilestoneName")}>MilestoneName</MenuItem>
					<MenuItem onClick={() => handleSortItemClick("TaskName")}>TaskName</MenuItem>
					<MenuItem onClick={() => handleSortItemClick("Status")}>Status</MenuItem>
					<MenuItem onClick={() => handleSortItemClick("DRI")}>DRI</MenuItem>
					<MenuItem onClick={() => handleSortItemClick("TaskBreaker")}>Reporting To</MenuItem>
				</Menu>
				<Menu
					anchorEl={filterAnchor}
					open={filterAnchor !== null}
					onClose={handleFilterButtonClose}
				>
					<MenuItem onClick={(event) => handleFilterItemClick(event, "Default")} style={{ justifyContent: "center" }}>Default</MenuItem>
					<MenuItem onClick={(event) => handleFilterItemClick(event, "Dri")}><ArrowLeftIcon />DRI</MenuItem>
					<OtherDRI onClick={handleDriTreeItemClick}/>
					{(GlobalServer !== "unknown" && GlobalServer !== undefined) ?(
						<></>
					):(
						<MenuItem onClick={(event) => handleFilterItemClick(event, "Server")}><ArrowLeftIcon />Server</MenuItem>
					)}
					{/* <MenuItem onClick={(event) => handleFilterItemClick(event, "Server")}><ArrowLeftIcon />Server</MenuItem> */}
				</Menu>
				{drisTree && drisTree.length > 0 && (
					<MultiLevelDropdown
						dritree={drisTree}
						driAnchor={driAnchor}
						handleDriTreeButtonClose={handleDriButtonClose}
						handleDriTreeItemClick={handleDriTreeItemClick}
					/>
				)}
				{/* {userRole !== "CEO" ? (
				<Menu
					anchorEl={driAnchor}
					open={driAnchor !== null}
					onClose={handleDriButtonClose}
					anchorOrigin={{
						vertical: "top",
						horizontal: "left",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
				>
					{drisMenuList}
				</Menu>
				) : (
					<MultiLevelDropdown
						dritree={drisTree}
						driAnchor={driAnchor}
						handleDriTreeButtonClose={handleDriButtonClose}
						handleDriTreeItemClick={handleDriTreeItemClick}
					/>
				)} */}
				<Menu
					anchorEl={serverAnchor}
					open={serverAnchor !== null}
					onClose={handleServerButtonClose}
					anchorOrigin={{
						vertical: "top",
						horizontal: "left",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
				>
					{serversMenuList}
				</Menu>
			</div>
		</div >
	);
}

function TaskBreakerTable({ toggle, rows, searchText, lazyLoad, setLazyLoad, initialRows, setInitialRows, filterBy, setFilterBy }) {
	const classNameForColors = ["color-a", "color-b", "color-c", "color-d", "color-e", "color-f"];
	var ind = useRef(0);
	var scrollContainer = useRef();
	var lastScrollTop = useRef(0);
	var loadingDiv = useRef();
	var locked = useRef(false);

	// const rowsNew = rows.filter(row_ => {
	// 	if (toggle.current) {
	// 		if (row_[5] === "Closed") {
	// 			return true;
	// 		}
	// 		return false;
	// 	}
	// 	else {
	// 		if (row_[5] === "Closed") {
	// 			return false;
	// 		}
	// 		return true;
	// 	}
	// });

	useLayoutEffect(() => {
		const elements = document.querySelectorAll('.milestone-grouper-name');

		elements.forEach((element, index) => {
			if(index%2 == 0) {
				element.style.backgroundColor = 'rgb(196, 45, 19)';
			} else {
				element.style.backgroundColor = 'rgb(17, 103, 168)';
			}
		});
	});

	var newRows = rows.filter(row_ => {
		for (const item of row_) {
			let aString = "";
			let bString = "";
			if (item !== null && item !== undefined) {
				aString = item.toLowerCase()?.replaceAll(" ", "");
				bString = searchText.toLowerCase()?.replaceAll(" ", "");
				if (aString.indexOf(bString) !== -1) {
					return true;
				}
			}
			else {
				aString = null;
				bString = null;
			}
		}
		return false;
	});

	const handleScroll = async (event) => {
		const myDiv = scrollContainer.current;
		var needMore = false;

		if (myDiv.scrollTop > lastScrollTop.current && (myDiv.offsetHeight + myDiv.scrollTop >= myDiv.scrollHeight - 50)) {
			needMore = true;
		}

		lastScrollTop.current = myDiv.scrollTop;
		if (lazyLoad === null || searchText !== "") {
			return;
		}
		if (needMore && !locked.current) {
			locked.current = true;
			const data = await lazyLoad.next();
			if (data.value.content === undefined) {
				setLazyLoad(null);
				// setDoneFetching(true);
			} else if (data.value.isLast) {
				setInitialRows([...initialRows, ...data.value.content]);
				setLazyLoad(null);
			} else {
				// setRows([...rows, ...data.value]);
				setInitialRows([...initialRows, ...data.value.content]);
			}
			locked.current = false;
		}
	};

	const theme = useTheme();

	if (newRows.length === 0) {
		return <h1 style={{ color: theme.palette.background.text }}>No Tasks Found :(</h1>;
	}

	const rowSpanInfo = find_row_span(newRows, [2, 0]);

	

	return (
		<div className="mytasks-table">
			<TableContainer component={Paper} onScroll={handleScroll} ref={scrollContainer} className="mytasks-table-container">
				<Table stickyHeader>
					<TableHead>
						<TableRow className="mytasks-table-head">
							<TableCell align="center" className="mytasks-table-grouper-header" />
							<TableCell align="center">S.No.</TableCell>
							<TableCell align="left">Milestone Name</TableCell>
							<TableCell align="center">Priority</TableCell>
							<TableCell align="center">DRI</TableCell>
							<TableCell align="left">Task Name</TableCell>
							<TableCell align="center">Status</TableCell>
							<TableCell align="center">Reporting To</TableCell>
							<TableCell align="center">Task Created By</TableCell>
							<TableCell align="right" />
							<TableCell align="center" className="mytasks-table-grouper-header" />
						</TableRow>
					</TableHead>
					<TableBody>
						{newRows.map((row, index) => (
							<Row key={index} row={row} index={index} rowSpanInfo={rowSpanInfo[index]} classNameForColors={classNameForColors} ind={ind} 
						     filterBy={filterBy} setFilterBy={setFilterBy} />
						))}
						{
							(lazyLoad !== null && searchText === "") ?
								<TableRow ref={loadingDiv}>
									<TableCell className={"mytasks-table-grouper-component loading"}></TableCell>
									<TableCell align="center" colSpan={8} >
										<div style={{
											whiteSpace: "nowrap",
											lineHeight: 0.5
										}}>
											<Skeleton height={"30px"}></Skeleton>
										</div>
									</TableCell>
								</TableRow>
								:
								<></>
						}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

function Row({ row, index, rowSpanInfo, classNameForColors, ind, filterBy, setFilterBy }) {
	const [moreVisibile, setMoreVisible] = useState(false);
	var milestoneAlternation = true;
	// icons
	var priority = "None";
	if (row[3] === "High") {
		priority = <ArrowCircleUpTwoToneIcon style={{ color: "crimson" }} />;
	}
	else if (row[3] === "Medium") {
		priority = <RemoveCircleTwoToneIcon style={{ color: "orange" }} />;
	}
	else if (row[3] === "Low") {
		priority = <ArrowCircleDownTwoToneIcon style={{ color: "mediumseagreen" }} />;
	}
	else {
		priority = <Brightness1TwoToneIcon style={{ color: "grey" }} />;
	}

	var status = <HexagonIcon style={{ color: "grey", fontSize: "small" }} />;
	if (row[5] === "Open") {
		status = <HexagonIcon style={{ color: "green", fontSize: "small" }} />;
	}
	else if (row[5] === "In Progress") {
		status = <HexagonIcon style={{ color: "royalblue", fontSize: "small" }} />;
	}
	else if (row[5] === "In Review") {
		status = <HexagonIcon style={{ color: "bluegrey", fontSize: "small" }} />;
	}
	else if (row[5] === "To be Tested") {
		status = <HexagonIcon style={{ color: "orange", fontSize: "small" }} />;
	}
	else if (row[5] === "On Hold") {
		status = <HexagonIcon style={{ color: "goldenrod", fontSize: "small" }} />;
	}
	else if (row[5] === "Delayed") {
		status = <HexagonIcon style={{ color: "gold", fontSize: "small" }} />;
	}
	else if (row[5] === "Closed") {
		status = <HexagonIcon style={{ color: "tomato", fontSize: "small" }} />;
	}
	else if (row[5] === "Cancelled") {
		status = <HexagonIcon style={{ color: "navy", fontSize: "small" }} />;
	}
	else if (row[5] === "RMap") {
		status = <HexagonIcon style={{ color: "pink", fontSize: "small" }} />;
	}
	else if (row[5] === "Ongoing") {
		status = <HexagonIcon style={{ color: "salmon", fontSize: "small" }} />;
	}

	var { colorsCache } = useContext(TabStateContext);
	var colorClass = "color-a";
	if (index in colorsCache) {
		colorClass = colorsCache[index];
	}
	else {
		colorClass = classNameForColors[(ind.current) % 5];
		colorsCache[index] = colorClass;
	}


	// side groupers
	var server = (
		<Tooltip title={row[2]} placement="right-start">
			<TableCell align="center" className={"mytasks-table-grouper-component mytasks-single-row " + row[2]}>
				<div style={{ whiteSpace: "nowrap" }}>{row[2].length > 8 ? row[2].slice(0, 8) + "..." : row[2]}</div>
			</TableCell>
		</Tooltip>
	);

	// server grouper
	if (rowSpanInfo[2] === 0) {
		server = <></>;
	}
	else if (rowSpanInfo[2] !== 1) {
		server = (
			<TableCell align="center" className={"mytasks-table-grouper-component mytasks-multi-row " + row[2]} rowSpan={rowSpanInfo[2]} >
				<div style={{ whiteSpace: "nowrap" }}>{row[2]}</div>
			</TableCell>
		);
		ind.current = ind.current + 1;
	}
	else {
		ind.current = ind.current + 1;
	}

	// type grouper
	var type = (
		<TableCell align="center" className={"mytasks-table-grouper-type mytasks-single-row " + row[1]}>
			<div style={{ whiteSpace: "nowrap" }}>{row[1]}</div>
		</TableCell>
	);

	if (rowSpanInfo[1] === 0) {
		type = <></>;
	}
	else if (rowSpanInfo[1] !== 1) {
		type = (
			<TableCell align="center" className={"mytasks-table-grouper-type mytasks-multi-row " + row[1]} rowSpan={rowSpanInfo[1]}>
				<div style={{ whiteSpace: "nowrap" }}>{row[1]}</div>
			</TableCell>
		);
	}

	// milestone name grouper

	var milestoneNameGrouper = <></>;

	if (rowSpanInfo[0] === 0) {
		milestoneNameGrouper = <></>;
	}
	else {
		var content = row[0];
		if(content !== undefined && content !== null) {
			var text =  content.split("_")[1];

			var number = "";
			if (content.split("_")[3] === undefined) {
				const matches = content.match(/\d+$/);
				if (matches) {
					number = parseInt(matches[0]);
				}
			} else {
				var number = content.split("_")[3];
			}

			if (rowSpanInfo[0] === 1) {
				content = text.substring(0, 3) + ".-" + number;
			} else {
				content = text + "-" + number;
			}
		}
	
		milestoneNameGrouper = (
			<TableCell align="center" className={"milestone-grouper-name"} rowSpan={rowSpanInfo[0]}>
				<div style={{ whiteSpace: "nowrap" }}>{content}</div>
			</TableCell>
		);
	}

	const theme = useTheme();
	const TextOnlyTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
      ))(`
          color: black;
          background-color: white;
      `);

	return (
		<>
		<TableRow
			hover={true}
			sx={{
				'& > *': { borderBottom: 'unset' },
				backgroundColor: theme.palette.background.tableRow,
				'&:hover': {
					backgroundColor: theme.palette.background.tableRowHover + " !important"
				}
			}}
			onMouseEnter={() => setMoreVisible(true)} 
			onMouseLeave={() => setMoreVisible(false)}
		>
			{server}
			<TableCell  className='slide-animation' align="center" style={{ width: "10px", maxWidth: "30px" }}>{index + 1}</TableCell>

			<TableCell  className='slide-animation'  align="left" style={{ minWidth: "8vw", maxWidth: "10vw", wordWrap: "break-word" }}>{row[0]}</TableCell>
			<TableCell  className='slide-animation' align="center"><Box className="flex-column">{priority}<p>{row[3]}</p></Box></TableCell>
			<TableCell  className='slide-animation'  align="center" style={{ width: "8vw" }}><GetNameAndEmpID emails={row[6]} /> </TableCell>

			{["", " ", undefined, null].some(value => row[8] === value) ? (

				<TableCell   className='slide-animation'   align="left" style={{ width: "10vw", maxWidth: "13vw", wordWrap: "break-word" }}>{row[4]}</TableCell>

			) : (

		
				<TextOnlyTooltip title={<span style={{ fontFamily: 'Poppins' ,fontSize: '0.875rem', color: "Black" ,backgroundImage:"" }}>{ row[8]} </span>}  followCursor >

					<TableCell className='slide-animation'   align="left" style={{ width: "10vw", maxWidth: "13vw", wordWrap: "break-word" }}>{row[4]}</TableCell>

					</TextOnlyTooltip>
			)}

			<TableCell  className='slide-animation'   align="center"><Box className="flex-row">{status}<p>{row[5]}</p></Box></TableCell>
			
			<TableCell   className='slide-animation'  align="center" style={{ width: "8vw" }}><GetNameAndEmpID emails={row[7]} /></TableCell>
			<TableCell   className='slide-animation'  align="center" style={{ width: "8vw" }}><GetNameAndEmpID emails={row[11]} /></TableCell>
			<MoreSection className='slide-animation'   moreVisibile={moreVisibile} setMoreVisible={setMoreVisible} creatorRowID={row[9]} filterBy={filterBy} setFilterBy={setFilterBy} />
			
			{/* {type} */}
			{milestoneNameGrouper}
		</TableRow>
		</>
	);
}

function MoreSection({moreVisibile, setMoreVisible, creatorRowID, filterBy, setFilterBy})
{
	const [moreAnchor, setMoreAnchor] = useState(null);
	const [editIframeLoaded, setEditIframeLoaded] = useState(false);
	const [openPopupEdit, setOpenPopupEdit] = useState(false);
	const milestoneEditUrl = "https://creatorapp.zoho.com/zohointranet/zvp-team/Report/record-edit/All_Reports/"+creatorRowID+"/";

	const handleClosePopupEdit = () => {
		setFilterBy([...filterBy]);
		setOpenPopupEdit(false);
		setEditIframeLoaded(false);
		setMoreVisible(false);
	};

	const handleEditIframeLoad = () => {
		setEditIframeLoaded(true);
	};

	function handleEditClick()
	{
		setOpenPopupEdit(true);
		setMoreAnchor(null);
	}

	return (
		<TableCell align="right" style={{width: "5px", cursor: "pointer"}}>
			<Fade in={moreVisibile} timeout={100}>
				<MoreVertIcon onClick={(event) => setMoreAnchor(event.currentTarget)} />
			</Fade>
			<Menu anchorEl={moreAnchor}
					open={moreAnchor !== null}
					onClose={() => {setMoreAnchor(null)}}
			>
				<MenuItem onClick={handleEditClick}>
					<Box style={{display: "flex"}}>
						<EditIcon fontSize="small"/>
						&nbsp;Edit
					</Box>
				</MenuItem>
			</Menu>
			<Dialog open={openPopupEdit} onClose={handleClosePopupEdit} fullScreen>
				<DialogActions>
				{!navigator.onLine && (
					<Alert sx={{marginRight:"669px",width:"247px",alignItems:"center",textAlign:"center",justifyContent:"center"}} severity="error">Kindly check you network connection.</Alert>
				)}
					<Button onClick={handleClosePopupEdit} variant="text" fontSize="large"><CloseIcon /></Button>
				</DialogActions>
				<DialogContent>
					{!editIframeLoaded && (
						<div
							style={{
								position: 'relative',
								width: '100%',
								height: '100%',
								backgroundImage: 'linear-gradient(to right, #f0f0f0, #e0e0e0)',
								borderRadius: 0,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Skeleton
								variant="rectangular"
								width="100%"
								height="100%"
								animation="wave"
							/>
						</div>
					)}
					<iframe
						src={milestoneEditUrl}
						width="100%"
						height="100%"
						title="External Content"
						frameBorder={0}
						onLoad={handleEditIframeLoad}
						style={{ display: editIframeLoaded ? 'block' : 'none' }}
					/>
				</DialogContent>
			</Dialog>
		</TableCell>
	);
}


// function GetName({ emails }) {
// 	const { teamDetails } = useContext(UserContext);
// 	const [showFullName, setShowFullName] = useState(false);
// 	var email=['hari.kandregula@zohocorp.com','vijayshankar.a@zohocorp.com','manoj.sakthivel@zohocorp.com','jayasuriya.bm@zohocorp.com'];
// 	email.push(emails);


// 	const getEmailDetails = (email) => {
// 	  const username = teamDetails.current[email]?.PeopleUserName || "NA";
// 	  const zuid = teamDetails.current[email]?.zuid || "NA";
// 	  return { username, zuid };
// 	};

// 	const handleMouseEnter = () => {
// 	  setShowFullName(true);
// 	};

// 	const handleMouseLeave = () => {
// 	  setShowFullName(false);
// 	};

// 	const renderAvatars = (emails) => {
// 	  const emailList = Array.isArray(emails) ? emails.slice(0, 3) : [];
// 	  const numEmails = emailList.length;

// 	  return (
// 		<div style={{ display: 'flex', alignItems: 'center' }}>
// 		  {emailList.map((email, index) => {
// 			const { username, zuid } = getEmailDetails(email);

// 			return (
// 			  <Avatar
// 				key={index}
// 				alt={username}
// 				src={`https://contacts.zoho.com/file?ID=${zuid}&exp=6000&fs=thumb`}
// 				sx={{ width: 40, height: 40, marginRight: index !== numEmails - 1 ? '4px' : 0 }}
// 			  />
// 			);
// 		  })}
// 		  {numEmails > 3 && (
// 			<div style={{ marginLeft: '4px' }}>
// 			  <Avatar
// 				alt={`+${numEmails - 3}`}
// 				sx={{ width: 40, height: 40, backgroundColor: 'gray', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
// 			  >
// 				+{numEmails - 3}
// 			  </Avatar>
// 			</div>
// 		  )}
// 		</div>
// 	  );
// 	};

// 	return (
// 	  <div
// 		className="flex-container"
// 		style={{
// 		  width: "100%",
// 		  height: '50px',
// 		  whiteSpace: showFullName ? 'normal' : 'nowrap',
// 		  overflow: showFullName ? 'auto' : 'hidden',
// 		  textOverflow: 'ellipsis',
// 		  scrollbarWidth: "none",
// 		  display: 'flex',
// 		  alignItems: 'center'
// 		}}
// 		onMouseEnter={handleMouseEnter}
// 		onMouseLeave={handleMouseLeave}
// 	  >
// 		<div style={{ paddingTop: '5px', float: 'left' }}>
// 		  {Array.isArray(email) ? (
// 			renderAvatars(email)
// 		  ) : (
// 			<Avatar
// 			  alt={email}
// 			  src={`https://contacts.zoho.com/file?ID=${getEmailDetails(email).zuid}&exp=6000&fs=thumb`}
// 			  sx={{ width: 40, height: 40 }}
// 			/>
// 		  )}
// 		</div>
// 		<div style={{ width: '100%', float: 'right', paddingLeft: '10px' }}>
// 		  <div style={{ height: '18px', fontSize: '0.775rem', textAlign: 'left' }}>
// 			{Array.isArray(email) && showFullName ? email.join(", ") : getEmailDetails(email).username}
// 		  </div>
// 		</div>
// 		<br style={{ clear: 'both' }} />
// 	  </div>
// 	);
//   }

export default TaskBreaker;


// ####################################################### util functions ###################################################################

function priorityCompareFunction(a, b) {
	const power = {
		"High": 3,
		"Medium": 2,
		"Low": 1,
		"None": 0
	};

	if (power[a[3]] < power[b[3]]) {
		return 1;
	}
	if (power[a[3]] > power[b[3]]) {
		return -1;
	}
	return 0;
}


function statusCompareFunction(a, b) {
	const power = {
		"Updated to IDC":10,
		"Open": 9,
		"In Progress": 8,
		"Ongoing": 7,
		"To be Tested": 6,
		"In Review": 5,
		"On Hold": 4,
		"Delayed": 3,
		"Closed": 2,
		"Cancelled": 1,
		"Rmap": 0
	};

	if (power[a[5]] < power[b[5]]) {
		return 1;
	}
	if (power[a[5]] > power[b[5]]) {
		return -1;
	}
	return 0;
}

function serverCompareFunction(a, b) {
	if (a[2] < b[2]) {
		return -1;
	}
	if (a[2] > b[2]) {
		return 1;
	}
	return 0;
}

function typeCompareFunction(a, b) {
	if (a[1] < b[1]) {
		return -1;
	}
	if (a[1] > b[1]) {
		return 1;
	}
	return 0;
}

function tasknameCompareFunction(a, b) {
	if (a[4] < b[4]) {
		return -1;
	}
	else if (b[4] < a[4]) {
		return 1;
	}
	return 0;
};

function milestoneNameCompareFunction(a, b) {
	if (a[0] < b[0]) {
		return -1;
	}
	else if (b[0] < a[0]) {
		return 1;
	}
	return 0;
}

function driCompareFunction(a, b) {
	if (a[6] < b[6]) {
		return -1;
	}
	else if (b[6] < a[6]) {
		return 1;
	}
	return 0;
}
function taskBreakerCompareFunction(a, b) {
	if (a[7] < b[7]) {
		return -1;
	}
	else if (b[7] < a[7]) {
		return 1;
	}
	return 0;
}


async function setDriDropDown(setDriList, setDriTree, userDetails, teamDetails, dept, configuration) {
	const query = task_breaker_component_get_query_5(dept);
	var result2 = await getFromTeamDetails(query);
	var head_parent = "srinivasan@zohocorp.com";
    try {
        const rootUsers = configuration.current[`department.rootUsers`];
        if(dept in rootUsers) {
            head_parent = configuration.current[`department.rootUsers`][dept];
        }
    } catch(e) {}
	// const head_root = "mariaraj@zohocorp.com";
	const head_root = [];
	const employees = result2;
	var hierarchicalData = [];
	hierarchicalData = createHierarchy(employees, head_parent);

	return new Promise((resolve, reject) => {
		const userEmail = userDetails.current.auth.email_id;
		const userRole = teamDetails.current[userEmail].Role;
		const userReportingTo = teamDetails.current[userEmail].ReportingTo;
		var users = [];
		if (userRole === "CEO" && head_root.includes[userEmail]) {
			for (const user in teamDetails.current) {
				users.push(user);
			}

			users.sort();
			setDriList(users);
			setDriTree(hierarchicalData);
		}

		else if (userRole === "CEO" && !head_root.includes(userEmail)) {
			for (const user in teamDetails.current) {
				users.push(user);
			}
			users.sort();
			setDriList(users);
			setDriTree(hierarchicalData);
			users = findMentees([userEmail], teamDetails);
			users.sort();
			users = [userEmail, ...users];
			setDriList(users);
			hierarchicalData = createHierarchy(employees, userReportingTo);
			const getDriList = [];
			getDriList.push(getMentorWithMentees(hierarchicalData, userEmail));
			var hierarchicalData11 = [];
			hierarchicalData11 = createHierarchy(employees, head_parent);
			hierarchicalData11.push(getDriList[0]);
			setDriTree(hierarchicalData11);
		}

		else {
			users = findMentees([userEmail], teamDetails);
			users.sort();
			users = [userEmail, ...users];
			setDriList(users);
			hierarchicalData = createHierarchy(employees, userReportingTo);
			const getDriList = [];
			getDriList.push(getMentorWithMentees(hierarchicalData, userEmail));
			setDriTree(getDriList);
		}
		return resolve();
	});
}


async function setServerDropDown(setServersList, userDetails, teamDetails, allServers) {
	return new Promise((resolve, reject) => {
		// console.log(teamDetails);
		const userEmail = userDetails.current.auth.email_id;
		const userRole = teamDetails.current[userEmail].Role;

		if (userRole === "CEO") {
			setServersList(allServers);
		}
		else {
			setServersList(teamDetails.current[userEmail].Servers.split(","));
		}
		return resolve();
	});
}

function findMentees(userEmails, teamDetails) {
	if (userEmails.length === 0) {
		return [];
	}

	const arr = [];

	for (const person in teamDetails.current) {
		if (userEmails.includes(teamDetails.current[person].ReportingTo)) {
			arr.push(person);
		}
	}

	const newArr = arr.concat(findMentees(arr, teamDetails));

	return newArr;
}

function createHierarchy(data, reportingTo) {
	const hierarchy = [];

	for (const employee of data) {
		//console.log(employee);
		if (employee.ReportingTo === reportingTo) {
			const subordinates = createHierarchy(data, employee.Email);
			if (subordinates.length) {
				employee.mentees = subordinates;
			}
			hierarchy.push(employee);
		}
	}
	hierarchy.sort((a, b) => {
		const empIDA = parseInt(a.EmployeeID, 10);
		const empIDB = parseInt(b.EmployeeID, 10);
		return empIDA - empIDB;
	});
	// hierarchy.sort((a, b) => {
	// 	const empIDA = a.name.toLowerCase();
	// 	const empIDB = b.name.toLowerCase();
	// 	return empIDA.localeCompare(empIDB);
	//   });


	return hierarchy;
}

function getMentorWithMentees(hierarchy, mentorEmail) {
	for (const mentor of hierarchy) {
		if (mentor.Email === mentorEmail) {
			return mentor;
		} else if (mentor.mentees && mentor.mentees.length > 0) {
			const foundMentor = getMentorWithMentees(mentor.mentees, mentorEmail);
			if (foundMentor) {
				return foundMentor;
			}
		}
	}
	return null;
}
