/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-target-blank */

import React, {useEffect} from "react";
import { Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export function BuildsAssociated({data}) {

    const theme = useTheme();

    return (
        <Box sx={{
            height: "95%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            paddingLeft: "15px",
            paddingTop: "10px",
            overflowY: "scroll",
            boxSizing: "border-box"
        }}>
            {
                data.builds_associated === undefined || data.builds_associated.length === 0 ? 
                <Box sx={{
                    height: "100%",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex"
                }}>
                    no builds
                </Box>  
                :
                data.builds_associated.map((item, index) => {
                    return (
                        <Box sx={{
                            paddingBottom: "10px",
                            color: theme.palette.background.text,
                            fontSize: "1.2rem",
                            textOverflow: "ellipsis",
                            gap: "10px",
                            display: "flex"
                        }} key={"builds_associated-"+index}>
                            {index+1}. {item.build_label}
                            <a href={item.build_url} target="_blank" style={{
                                textDecoration: "none",
                                color: theme.palette.background.textSecondary
                            }}>
                                <OpenInNewIcon />
                            </a>
                        </Box>
                    );
                })
            }
        </Box>
    );
}