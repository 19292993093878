/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";

export function RadarChart({title, columnName1, columnName2, data, colors, palette, open}) {
    const chartRef = useRef();
    const theme = useTheme();
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);

    var chartJSON = {
        "legend": {
            "show": false,
            "layout": "vertical",
            "colorPallete": {
                "options": {
                    "multicolor": palette === undefined ? "BrightNDull" : palette
                }
            },
            "colors": colors !== null ? colors: ""
        },
        "metadata": {
            "axes": {
                "x": [
                    0
                ],
                "y": [
                    [
                        1
                    ]
                ],
                "tooltip": [
                    0,
                    1
                ]
            },
            "columns": [
                {
                    "dataindex": 0,
                    "columnname": columnName1,
                    "datatype": "ordinal"
                },
                {
                    "dataindex": 1,
                    "columnname": columnName2,
                    "datatype": "numeric"
                }
            ]
        },
        "seriesdata": {
            "type": "web",
            "chartdata": [
                {
                    "data": data
                }
            ]
        },
        "chart": {
            "axes": {
                "rotated": false,
                "xaxis": {
                    "grid": {
                        "color": theme.palette.background.textSecondary,
                        "strokeWidth": 0.8
                    },
                    "tickmark": {
                        "color": "black",
                        "strokeWidth": 1
                    },
                    "axisline": {
                        "show": false
                    }
                },
                "yaxis": [
                    {
                        "ticklabel": {
                            "fontColor": "transparent"
                        },
                        "grid": {
                            "color": "grey",
                            "strokeWidth": 0.3
                        },
                        "label": {
                            "show": false
                        },
                        "axisline": {
                            "show": false
                        },
                        "tickmark": {
                            "color": "transparent"
                        }
                    }
                ]
            },
            "plot": {
                "renderer": {
                    "mode": "CANVAS"
                },
                "plotoptions": {
                    "web": {
                        "marker": {
                            "size": open ? 2 : 0.9
                        },
                        "fillOpacity": 0.9
                    }
                }
            }
        },
        "tooltip": {
            "backgroundColor": "white",
            "opacity": 0.95,
            "fontColor": "rgba(0,0,0,0.7)",
            "shadow": "2px 2px 2px rgba(0,0,0,0.3)",
            "fontSize": 15,
            "maxWidth": "100%"
        },
        "canvas": {
            "fontFamily": "poppins",
            "fontSize": 1,
            "fontWeight": "bold",
            "fontColor": theme.palette.background.textSecondary,
            "title": {
                "text": title,
                "hAlign": "center",
                "fontSize": "1em"
            },
            "subtitle": {
                "show": false
            },
            "background": {
                "alpha": 0
            },
            "events": {
                "onerror": null
            },
            "border": {
                "show": false
            }
        }
    };

    useEffect(() => {

        var chartObj = null;

        function updateSize() {
            setWindowSize([window.innerWidth, window.innerHeight]);
        }

        if(chartRef !== undefined && chartRef.current !== undefined) {
            chartObj = window.$ZC.charts(chartRef.current, chartJSON);
            window.addEventListener('resize', updateSize);
        }

        return () => {
            if(chartObj !== null) {
                chartObj.destroy();
            }
            window.removeEventListener('resize', updateSize);
        }

    }, [theme.palette.mode, windowSize, data]); 

    return (
        <Box ref={chartRef} sx={{
            height: "100%",
            width: "100%"
        }}>
        </Box>
    );
}