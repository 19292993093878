import React, { useState, useEffect, useContext } from 'react';
// import { queryDB } from '../../../util/QueryDB';
import { queryDB } from '../../../../util/QueryDB';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import HexagonIcon from '@mui/icons-material/Hexagon';
import { group_by_order, find_row_span } from '../../../../util/GroupingFunctions.js';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
// import CombinedInfo from './../../pageContent/CombinedMilestone/CombinedInfo'
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import { GetNameAndEmpID, days_between } from '../../../../util/Loading.js';
// import { UserContext } from '../../../contexts/UserContext.js';
import { UserContext } from '../../../../contexts/UserContext';
import { styled } from '@mui/material/styles';

import BuildStatus from './BuildStatus';
import InfoDiv from './InfoDiv';
import { builds_self_builds_component_get_query_1 } from '../../../../catalystDB/queryGenerator.js';

const SelfMyTable = () => {
  const [valuesData, setValuesData] = useState([]);
  const [DataCheck, setDataCheck] = useState(false);
  const { userDetails, teamDetails, loginUserDept } = useContext(UserContext);

  const theme = useTheme();


  useEffect(() => {

    const fetchValuesData = async () => {
      try {
        const result = await queryDB(builds_self_builds_component_get_query_1(userDetails, loginUserDept));
        // const result = await queryDB(`SELECT * FROM  AllBuildDetails`);
        setValuesData(result);
        setDataCheck(true)
      } catch (error) {
        console.error(error);
      }
    };

    fetchValuesData();
  }, []);
  //   console.log(valuesData)

  var dataAsArray = valuesData.map((item) => {
    return [
      item.AllBuildDetails.Server,
      item.AllBuildDetails.BuildLabel,
      item.AllBuildDetails.BuildBy,
      // [undefined, "", " ", null].includes(item.TaskDetails.TasklistDescription) ? "-" : item.TaskDetails.TasklistDescription,
      item.AllBuildDetails.BuildType,
      item.AllBuildDetails.BuildURL,
      [undefined, "", " ", null].includes(item.AllBuildDetails.ApprovalStatus) ? "-" : item.AllBuildDetails.ApprovalStatus,
      [undefined, "", " ", null].includes(item.AllBuildDetails.ApprovedOrDeclinedBy) ? "-" : item.AllBuildDetails.ApprovedOrDeclinedBy,
      [undefined, "", " ", null].includes(item.AllBuildDetails.ApprovedOrDeclinedOn) ? "-" : item.AllBuildDetails.ApprovedOrDeclinedOn,
      [undefined, "", " ", null].includes(item.AllBuildDetails.DeclineReason) ? "-" : item.AllBuildDetails.DeclineReason,
      item.AllBuildDetails.CREATEDTIME,
      item.AllBuildDetails.BuildComment,
      item,
      [undefined, "", " ", null].includes(item.AllBuildDetails.Build_Requested_Time) ? "-" : item.AllBuildDetails.Build_Requested_Time,
    ];
  });





  // console.log(DataCheck)
  return (
    <>
      {DataCheck === false ? (

        <div className='page-content-2'>
          <div className="mytasks-table">
            <Table stickyHeader>
              <TableHead>
                <TableRow >
                  <TableCell colSpan={7}>
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      sx={{ height: "calc(100vh - 180px)" }}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </div>
        </div>

      )
        :
        (
          valuesData.length === 0 ? (
            <div className='page-content-2'>
              <div className="mytasks-table">
                <Box component="h1" sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }}>
                  No data available
                </Box>
              </div>
            </div>
          ) : (
            <div className='page-content-2'>
              <div className="mytasks-table">
                <TableContainer component={Paper} className="mytasks-table-container">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow className="mytasks-table-head">
                        {/* <TableCell className='milestone-table-grouper-header'/> */}
                        <TableCell align="center">S.NO.</TableCell>
                        <TableCell align="center" >Server</TableCell>
                        <TableCell align="center" >Build Label</TableCell>
                        <TableCell align="center" >Build Type</TableCell>
                        <TableCell align="center" >BuildBy</TableCell>
                        <TableCell align="center" >Approval Status</TableCell>
                        <TableCell align="center" >Requested Time</TableCell>
                        <TableCell></TableCell>

                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {dataAsArray.map((name, index) => (
                        <Row
                          index={index}
                          key={index}
                          Server={dataAsArray.map((item) => item[0])[index]}
                          BuildLabel={dataAsArray.map((item) => item[1])[index]}
                          BuildBy={dataAsArray.map((item) => item[2])[index]}
                          BuildType={dataAsArray.map((item) => item[3])[index]}
                          BuildURL={dataAsArray.map((item) => item[4])[index]}
                          ApprovalStatus={dataAsArray.map((item) => item[5])[index]}
                          ApprovedOrDeclinedBy={dataAsArray.map((item) => item[6])[index]}
                          ApprovedOrDeclinedOn={dataAsArray.map((item) => item[7])[index]}
                          DeclineReason={dataAsArray.map((item) => item[8])[index]}
                          CREATEDTIME={dataAsArray.map((item) => item[9])[index]}
                          BuildComment={dataAsArray.map((item) => item[10])[index]}
                          row={dataAsArray[index][11]}
                          RequestedTime={dataAsArray.map((item) => item[12])[index]}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          )
        )}

    </>
  );

}

function Row({ index, Server, BuildLabel, BuildBy, BuildType, BuildURL, ApprovalStatus, ApprovedOrDeclinedBy, ApprovedOrDeclinedOn, DeclineReason, CREATEDTIME, BuildComment, row,RequestedTime }) {
  //console.log(row);
  const [moreVisibile, setMoreVisible] = useState(false);
  const [statusCheck, setStatusCheck] = useState(false);
  const theme = useTheme();
  const [open, setOpen] = React.useState(null);
  const [approvedData, setApprovedData] = useState(false);

  useEffect(() => {
    if (ApprovalStatus === "Auto Approved" || ApprovalStatus === "Approved" || ApprovalStatus === "-") {
      setApprovedData(true)
    }

  }, []);

  const handleClick = () => {
    if (open === index) {
      setOpen(null);
    }

    else {
      setOpen(index);
    }
  };
  const TextOnlyTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
  ))(`
          color: black;
          background-color: white;
      `);

  return (

    <>
      <TableRow
        key={index}
        hover={true}
        sx={{
          backgroundColor: theme.palette.background.tableRow,
          '&:hover': {
            backgroundColor: theme.palette.background.tableRowHover + " !important"
          }
        }}
        onMouseEnter={() => setMoreVisible(true)}
        onMouseLeave={() => setMoreVisible(false)}
      >
        {/* {serTemp} */}



        <TableCell align="center" style={{ width: "2.5vw", maxWidth: "2.5vw" }} onClick={handleClick}>{index + 1}</TableCell>
        <TableCell allign="center" onClick={handleClick}><Box>{Server}</Box></TableCell>
        <TableCell align="center" style={{ maxWidth: "20vw", wordWrap: "break-word" }}>
          <Link to={BuildURL} target='_blank' className="tasklist-projects-url-link"
            style={{ textDecoration: "none", color: "inherit" }}
            onMouseEnter={(event) => {
              event.target.style.color = theme.palette.primary.main;
            }}
            onMouseLeave={(event) => {
              event.target.style.color = "inherit";
            }}>
            <TextOnlyTooltip title={<span style={{ fontFamily: 'Poppins', fontSize: '0.875rem', color: "Black", backgroundImage: "" }}>{BuildComment} </span>} followCursor >
              {BuildLabel}
            </TextOnlyTooltip>
          </Link>
        </TableCell>
        <TableCell align="center" onClick={handleClick}>{BuildType}</TableCell>
        <TableCell align="center" className='slide-animation' style={{ width: "5vw" }} onClick={handleClick}><GetNameAndEmpID emails={BuildBy} /></TableCell>
        {approvedData === true ? (
          <TextOnlyTooltip title={<span style={{ fontFamily: 'Poppins', fontSize: '0.875rem', color: "Black", backgroundImage: "" }}>{ApprovedOrDeclinedBy}<br></br>{ApprovedOrDeclinedOn}</span>} followCursor >
            <TableCell align="center">{ApprovalStatus}</TableCell>
          </TextOnlyTooltip>)
          :
          (<TextOnlyTooltip title={<span style={{ fontFamily: 'Poppins', fontSize: '0.875rem', color: "Black", backgroundImage: "" }}>{ApprovedOrDeclinedBy}<br></br>{ApprovedOrDeclinedOn}<br></br>{DeclineReason} </span>} followCursor >
            <TableCell align="center">{ApprovalStatus}</TableCell>
          </TextOnlyTooltip>)}
          <TableCell align="center" style={{ width: "5.5vw", maxWidth: "5.5vw" }} onClick={handleClick}>{RequestedTime}</TableCell>
        <TableCell align="left" className='slide-animation' style={{ width: "30px" }}>
          <IconButton
            size="small"
            onClick={handleClick}
          >
            {open === null ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </IconButton>
        </TableCell>
      </TableRow>



      <TableRow className="info-div-row" sx={{ backgroundColor: theme.palette.background.infoDivOuter + " !important" }}>
        <TableCell colSpan={8} style={{ padding: 0 }}>
          <Collapse in={open !== null} timeout="auto" unmountOnExit>
            <Box className="info-div-paper"
              sx={{
                padding: "20px",
                margin: "10px",
                backgroundColor: theme.palette.background.infoDivInner + " !important"
              }}>
                <InfoDiv Server={Server} BuildLabel={BuildLabel} row={row}/>
            </Box>
            {/* <CombinedInfo   index={index}  MilestoneName={dataAsArray1.map((item) => item[1])} MilestoneStartDate={dataAsArray1.map((item) => item[8])} MilestoneDueDate={dataAsArray1.map((item) => item[9])} Status={dataAsArray1.map((item) => item[5])} MilestoneId={dataAsArray1.map((item) => item[0])} DRI={dataAsArray1.map((item) => item[12])} Server={dataAsArray1.map((item) => item[4])} Tasklistid={dataAsArray1.map((item) => item[7])} TasklistPriority={dataAsArray1.map((item) => item[13])} MilestoneDescription={dataAsArray1.map((item) => item[14])} MilestoneStatus={dataAsArray1.map((item) => item[15])} TasklistStatus={dataAsArray1.map((item) => item[6])}/> */}
          </Collapse>
        </TableCell>
      </TableRow>

    </>


  );
}



export default SelfMyTable;



