import React, {useRef} from 'react';
import {useEffect} from "react";
import ZVPLogo from "./../icons/ZVPLogo.svg";
import { Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import './LoginPage.css';

function LoginPage()
{
	const loginDiv = useRef();
	const mainDiv = useRef();
	const theme = useTheme();

	const config =   {
        css_url : "/app/embeddedIframe.css",
        signInProvidersOnly: true
    };

	useEffect(() => {
		async function run() {
			window.catalyst.auth.signIn("loginBox", config);
		while(loginDiv.current.innerHTML === "")
		{
			await new Promise(r => setTimeout(r, 2000));
			if(loginDiv.current.innerHTML !== "")
			{
				setTimeout(() => {
					loginDiv.current.style.visibility = "visible";
					mainDiv.current.classList.remove("login-loading");
				}, 1000);
			}
		}
		}
		run();
	}, []);

	// useEffect(async () =>
	// {
	// 	window.catalyst.auth.signIn("loginBox", config);
	// 	while(loginDiv.current.innerHTML === "")
	// 	{
	// 		await new Promise(r => setTimeout(r, 2000));
	// 		if(loginDiv.current.innerHTML !== "")
	// 		{
	// 			setTimeout(() => {
	// 				loginDiv.current.style.visibility = "visible";
	// 				mainDiv.current.classList.remove("login-loading");
	// 			}, 1000);
	// 		}
	// 	}
	// });
	// console.log(loginDiv)
	// console.log("Hi");

	return (
		<div style={{
			"height": "100%",
			"width": "100%",
			"overflow": "hidden",
			"backgroundColor": theme.palette.background.pageContent
		}}>
			<div className="login-card-div login-loading" ref={mainDiv} style={{
				"backgroundColor" : theme.palette.mode === "light" ? "ghostwhite" : "#272a32",
				"color": theme.palette.background.text
			}}>
				<img width="100px" src={ZVPLogo} className="logo-img" />
	            <h1>ZONE</h1>
	            <h3>Welcome to Zone Portal !</h3>
				<div id="loginBox" ref={loginDiv} style={{visibility: "hidden"}}></div>
			</div>
		</div>
	);
}

export default LoginPage;