/* eslint-disable react/prop-types */ 
/* esline-disable react/jsx-key */
import React from "react";
import { useEffect, useRef, useState, useContext } from "react";
import { UserContext } from "../../../../../contexts/UserContext";
import { useTheme } from '@mui/material/styles';
import { Box, ButtonGroup, Skeleton, Tab } from "@mui/material";
import { ReportContext } from "../newReportGeneral/NewReport";
import { getMilestoneRows, getTasksForDRI } from "../reportSection1/ReportSection1DataFetcher"; 
import { DialChart, PieChart } from "../newReportGeneral/NewReportUtilComponents";
import dayjs from "dayjs";

import AirIcon from '@mui/icons-material/Air';

export function ReportSection3() {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            width: "100%",
            height: "98%",
            gap: "10px"
        }}>
             <Box sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.1rem",
                fontWeight: "bold"
            }}>
                On Time Percentage
            </Box>
            <ReportBody/>
        </Box>
       
    );
}   


function ReportBody({setExpanded}) {

    const {
        subjectType, 
        setSubjectType, 
        subject, 
        setSubject, 
        timeFrame, 
        setTimeFrame, 
        time, 
        setTime,
        timeRange,
        setTimeRange
    } = useContext(ReportContext);
    const theme = useTheme();
    const {teamDetails, loginUserDept} = useContext(UserContext);
    
    const [loaded, setLoaded] = useState(undefined);
    const [chartData, setChartData] = useState(undefined);
 
    useEffect(() => {
        const controller = new AbortController();
        async function pullTaskRows() {

            getTasksForDRI(timeRange, subjectType, subject, teamDetails, loginUserDept).then((response) => {
                if(!controller.signal.aborted) {
                    if(response.length !== 0) {

                        var startDate = undefined;
                        var dueDate = undefined;
                        var completedDate = undefined;
                        var nowDate = new Date();

                        var onTimeCount = 0;
                        var delayedCount = 0;

                        response.forEach(item => {
                            try{
                                if(dayjs(item.TaskStartDate_).isValid()) {
                                    startDate = new Date(item.TaskStartDate_);
                                }
                            } catch(e) {}

                            try{
                                if(dayjs(item.TaskDueDate_).isValid()) {
                                    dueDate = new Date(item.TaskDueDate_);
                                }
                            } catch(e) {}

                            try{
                                if(dayjs(item.TaskCompletionDate).isValid()) {
                                    completedDate = new Date(item.TaskCompletionDate);
                                }
                            } catch(e) {}

                            if(startDate === undefined || dueDate === undefined) {
                                return;
                            }

                            if(completedDate !== undefined) {
                                if(completedDate <= dueDate) {
                                    onTimeCount += 1;
                                } else {
                                    delayedCount += 1;
                                }
                            } else {
                                if(nowDate <= dueDate) {
                                    onTimeCount += 1;
                                } else {
                                    delayedCount += 1;
                                }
                            }
                        })

                        var percentage = 0;
                        if(onTimeCount+delayedCount > 0) {
                            percentage = (onTimeCount / (onTimeCount+delayedCount))*100;
                        }

                        setChartData([
                            [
                                "On Time Tasks",
                                percentage
                            ]
                        ]);
                        setLoaded(true);
                    } else {
                        setLoaded(false);
                        setChartData(undefined);
                    }
                }
            }).catch((error) => {
                if(!controller.signal.aborted) {
                    setLoaded(false);
                    setChartData(undefined);
                }
            });
        }

        setLoaded(undefined);
        pullTaskRows();

        return () => {
            controller.abort();
        };

    }, [timeRange, subjectType,subject]);


    if(loaded === undefined) {
        return (
            <Skeleton variant={"rounded"} height={"98%"} width={"100%"} animation="wave" />
        );
    } else if(loaded === false) {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%"
            }}>
                <Box>
                    <AirIcon sx={{
                        fontSize: "6rem",
                        color: theme.palette.mode === "light" ? "rgb(20, 20, 20, 0.06)": "rgb(200, 200, 200, 0.06)"
                    }} />
                </Box>
                <Box sx={{
                    fontSize: "0.7rem",
                    color: theme.palette.mode === "light" ? "rgb(10, 10, 10, 0.3)": "rgb(200, 200, 200, 0.4)"
                }}>
                    Nothing found
                </Box>
            </Box>
        );
    } else {
        return (
            <Box  sx={{
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                overflowY: "scroll",
                boxSizing: "border-box",
                backgroundColor: theme.palette.mode === 'dark' ? 'rgb(24,30,35)' : '#FAFAFA',
                borderRadius: "12px",
                overflowX: "hidden"
            }}
            >
                <DialChart title={""} columnName1={"col1"} columnName2={"col2"} data={chartData} />
            </Box>
        );
    }
}