import React, { useContext } from 'react';

import { GridLayout } from '../dashboardMain/GridLayout';
import { DashboardContext } from '../dashboardMain/stateStorage/DashboardStateStorage';

import { GridItem_1 } from './gridItem_1/GridItem_1';
import { GridItem_2 } from './gridItem_2/GridItem_2';
import { GridItem_3 } from './gridItem_3/GridItem_3';
import { GridItem_4 } from './gridItem_4/GridItem_4';
import { GridItem_5 } from './gridItem_5/GridItem_5';
import { GridItem_6 } from './gridItem_6/GridItem_6';
import { GridItem_7 } from './gridItem_7/GridItem_7';
import { GridItem_8 } from './gridItem_8/GridItem_8';
import { GridItem_9 } from './gridItem_9/GridItem_9';
import { GridItem_10 } from './gridItem_10/GridItem_10';
import { GridItem_11 } from './gridItem_11/GridItem_11';
import { GridItem_12 } from './gridItem_12/GridItem_12';
import { GridItem_13 } from './gridItem_13/GridItem_13';
import { GridItem_14 } from './gridItem_14/GridItem_14';
import { GridItem_15 } from './gridItem_15/GridItem_15';
import { touchRippleClasses } from '@mui/material';

export function Dashboard_1() {
    const layout = [
        {
            "title": "Task Count",
            "name": "GridItem_1",
            "value": <GridItem_1 />,
            "x": 1.7,
            "y": 2.4,
            "noExpansion": true
        },
        {
            "title": "Tasklist Count",
            "name": "GridItem_2",
            "value": <GridItem_2 />,
            "x": 1.7,
            "y": 2.4,
            "noExpansion": true
        },
        {
            "title": "Milestone Count",
            "name": "GridItem_3",
            "value": <GridItem_3 />,
            "x": 1.7,
            "y": 2.4,
            "noExpansion": true
        },
        {
            "title": "Roadmap Type",
            "name": "GridItem_5",
            "value": <GridItem_5 />,
            "x": 1.7,
            "y": 2.4
        },
        {
            "title": "Roadmap Status",
            "name": "GridItem_4",
            "value": <GridItem_4 />,
            "x": 1.7,
            "y": 2.4
        },
        {
            "title": "Task Status",
            "name": "GridItem_7",
            "value": <GridItem_7 />,
            "x": 1.7,
            "y": 2.4
        },
        {
            "title": "MilestoneStatus",
            "name": "GridItem_6",
            "value": <GridItem_6 />,
            "x": 1.8,
            "y": 2.4
        },
        {
            "title": "Task Line Graph",
            "name": "GridItem_8",
            "value": <GridItem_8 />,
            "x": 4,
            "y": 4.8
        },
        {
            "title": "Tasklist Line Graph",
            "name": "GridItem_9",
            "value": <GridItem_9 />,
            "x": 4,
            "y": 4.8
        },
        {
            "title": "Milestone Line Graph",
            "name": "GridItem_10",
            "value": <GridItem_10 />,
            "x": 4,
            "y": 4.8
        },
        {
            "title": "Server - Task Completion Count",
            "name": "GridItem_11",
            "value": <GridItem_11 />,
            "x": 4,
            "y": 4.8
        },
        {
            "title": "Team Members Contribution",
            "name": "GridItem_12",
            "value": <GridItem_12 />,
            "x": 4,
            "y": 4.8
        },
        {
            "title": "",
            "name": "GridItem_13_14_15",
            "value": null,
            "x": 4,
            "y": 4.8,
            "children": [
                {
                    "title": "Task Completion Bullet",
                    "name": "GridItem_13",
                    "value":  <GridItem_13 />,
                    "x": 12,
                    "y": 4
                },
                {
                    "title": "Tasklist Completion Bullet",
                    "name": "GridItem_14",
                    "value":  <GridItem_14 />,
                    "x": 12,
                    "y": 4
                },
                {
                    "title": "Milestone Completion Bullet",
                    "name": "GridItem_15",
                    "value":  <GridItem_15 />,
                    "x": 12,
                    "y": 4
                }
            ]
        }
    ];

    const {getAllDashboardNames} = useContext(DashboardContext);
    const DASHBOARD_NAMES = getAllDashboardNames();

    return (
        <GridLayout layout={layout} dashboardName={DASHBOARD_NAMES[0]} key={"DASHBOARD_1"}/>
    );
}