import React, { useState, useEffect, useContext } from 'react';
// import { queryDB } from '../../../util/QueryDB';
import { queryDB } from '../../../../util/QueryDB';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
// import CombinedInfo from './../../pageContent/CombinedMilestone/CombinedInfo'
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import { GetNameAndEmpID, days_between } from '../../../../util/Loading.js';
// import { UserContext } from '../../../contexts/UserContext.js';
import { UserContext } from '../../../../contexts/UserContext';
import { styled } from '@mui/material/styles';
import InfoDiv from '../selfBuilds/InfoDiv';
import { builds_server_builds_component_get_query_1 } from '../../../../catalystDB/queryGenerator.js';

const SelfMyTable = () => {
    const [valuesData, setValuesData] = useState([]);
    const [DataCheck, setDataCheck] = useState(false);
    const { userDetails, teamDetails, loginUserDept, configuration, GlobalServer } = useContext(UserContext);
    const user_email = userDetails.current.auth.email_id;
    // const user_servers = teamDetails.current[user_email].Servers;
    var user_servers = (GlobalServer === "unknown" || GlobalServer === undefined) ? teamDetails.current[user_email].Servers : GlobalServer;
    const queryStringSuffix = "('" + user_servers.replaceAll(",", "','") + "')";
    // console.log(user_servers)

    const theme = useTheme();

    useEffect(() => {
        setDataCheck(false);
        const fetchValuesData = async () => {
            try {
                //   const result = await queryDB(`SELECT * FROM  AllBuildDetails  WHERE BuildBy = '*${userDetails.current.auth.email_id}*'`);
                const result = await queryDB(builds_server_builds_component_get_query_1(user_email, queryStringSuffix, loginUserDept));
                setValuesData(result);
                setDataCheck(true)
            } catch (error) {
                console.error(error);
            }
        };

        fetchValuesData();

    }, [GlobalServer]);

    useEffect(() => {


        const fetchValuesData = async () => {
            try {
                //   const result = await queryDB(`SELECT * FROM  AllBuildDetails  WHERE BuildBy = '*${userDetails.current.auth.email_id}*'`);
                const result = await queryDB(builds_server_builds_component_get_query_1(user_email, queryStringSuffix, loginUserDept));
                setValuesData(result);
                setDataCheck(true)
            } catch (error) {
                console.error(error);
            }
        };

        fetchValuesData();
    }, []);
    //   console.log(valuesData)

    var dataAsArray = valuesData.map((item) => {
        return [
            item.AllBuildDetails.Server,
            item.AllBuildDetails.BuildLabel,
            item.AllBuildDetails.BuildBy,
            // [undefined, "", " ", null].includes(item.TaskDetails.TasklistDescription) ? "-" : item.TaskDetails.TasklistDescription,
            item.AllBuildDetails.BuildType,
            item.AllBuildDetails.BuildURL,
            [undefined, "", " ", null].includes(item.AllBuildDetails.ApprovalStatus) ? "-" : item.AllBuildDetails.ApprovalStatus,
            [undefined, "", " ", null].includes(item.AllBuildDetails.ApprovedOrDeclinedBy) ? "-" : item.AllBuildDetails.ApprovedOrDeclinedBy,
            [undefined, "", " ", null].includes(item.AllBuildDetails.ApprovedOrDeclinedOn) ? "-" : item.AllBuildDetails.ApprovedOrDeclinedOn,
            [undefined, "", " ", null].includes(item.AllBuildDetails.DeclineReason) ? "-" : item.AllBuildDetails.DeclineReason,
            item.AllBuildDetails.CREATEDTIME,
            item.AllBuildDetails.BuildComment,
            item,
            [undefined, "", " ", null].includes(item.AllBuildDetails.Build_Requested_Time) ? "-" : item.AllBuildDetails.Build_Requested_Time,
            [undefined, "", " ", null].includes(item.AllBuildDetails.HackSawUrl) ? "-" : item.AllBuildDetails.HackSawUrl
        ];
    });

    // console.log(dataAsArray)
    return (
        <>
            {DataCheck === false ? (

                <div className='page-content-2'>
                    <div className="mytasks-table">
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow >
                                    <TableCell colSpan={7}>
                                        <Skeleton
                                            variant="rectangular"
                                            width={"100%"}
                                            sx={{ height: "calc(100vh - 180px)" }}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </div>
                </div>

            )
                :
                (
                    valuesData.length === 0 ? (
                        <div className='page-content-2'>
                            <div className="mytasks-table">
                                <Box component="h1" sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }}>
                                    No data available
                                </Box>
                            </div>
                        </div>
                    ) : (
                        <div className='page-content-2'>
                            <div className="mytasks-table">
                                <TableContainer component={Paper} className="mytasks-table-container">
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow className="mytasks-table-head">
                                                {/* <TableCell className='milestone-table-grouper-header'/> */}
                                                <TableCell align="center">S.NO.</TableCell>
                                                <TableCell align="center" >Server</TableCell>
                                                <TableCell align="center" >Build Label</TableCell>
                                                <TableCell align="center" >Build Type</TableCell>
                                                <TableCell align="center" >BuildBy</TableCell>
                                                <TableCell align="center" >Approval Status</TableCell>
                                                <TableCell align="center">Requested Time</TableCell>
                                                <TableCell></TableCell>

                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {dataAsArray
                                                // .sort((rowA, rowB) => {
                                                //     const hasValuesA = Object.keys(extractBuildData(rowA[11].AllBuildDetails, configuration)).length !== 0;
                                                //     const hasValuesB = Object.keys(extractBuildData(rowB[11].AllBuildDetails, configuration)).length !== 0;

                                                //     // Sort rows with values first, and rows without values last
                                                //     if (hasValuesA && !hasValuesB) {
                                                //         return -1;
                                                //     } else if (!hasValuesA && hasValuesB) {
                                                //         return 1;
                                                //     } else {
                                                //         return 0;
                                                //     }
                                                // })
                                                .map((name, index) => (
                                                    <Row
                                                        index={index}
                                                        Server={dataAsArray.map((item) => item[0])[index]}
                                                        BuildLabel={dataAsArray.map((item) => item[1])[index]}
                                                        BuildBy={dataAsArray.map((item) => item[2])[index]}
                                                        BuildType={dataAsArray.map((item) => item[3])[index]}
                                                        BuildURL={dataAsArray.map((item) => item[4])[index]}
                                                        ApprovalStatus={dataAsArray.map((item) => item[5])[index]}
                                                        ApprovedOrDeclinedBy={dataAsArray.map((item) => item[6])[index]}
                                                        ApprovedOrDeclinedOn={dataAsArray.map((item) => item[7])[index]}
                                                        DeclineReason={dataAsArray.map((item) => item[8])[index]}
                                                        CREATEDTIME={dataAsArray.map((item) => item[9])[index]}
                                                        BuildComment={dataAsArray.map((item) => item[10])[index]}
                                                        row={dataAsArray[index][11]}
                                                        RequestedTime={dataAsArray.map((item) => item[12])[index]}
                                                        HackSawUrl={dataAsArray.map((item) => item[13])[index]}
                                                    />
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    )
                )}

        </>
    );

}

function Row({ index, Server, BuildLabel, BuildBy, BuildType, BuildURL, ApprovalStatus, ApprovedOrDeclinedBy, ApprovedOrDeclinedOn, DeclineReason, CREATEDTIME, BuildComment, row, RequestedTime, HackSawUrl }) {
    const { configuration } = useContext(UserContext);
    const status = extractBuildData(row.AllBuildDetails, configuration);
    //console.log(status);
    const [moreVisibile, setMoreVisible] = useState(false);
    const [statusCheck, setStatusCheck] = useState(false);
    const theme = useTheme();
    const [open, setOpen] = React.useState(null);
    const [approvedData, setApprovedData] = useState(false);
    //console.log(row);

    useEffect(() => {
        if (ApprovalStatus === "Auto Approved" || ApprovalStatus === "Approved" || ApprovalStatus === "-") {
            setApprovedData(true)
        }

    }, []);

    const handleClick = () => {
        if (Object.keys(status).length !== 0) {
        if (open === index) {
            setOpen(null);
        } else {
            setOpen(index);
        }
        }
    };
    const TextOnlyTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
    ))(`
          color: black;
          background-color: white;
      `);

    return (

        <>
            <TableRow
                key={index}
                hover={true}
                sx={{
                    backgroundColor: theme.palette.background.tableRow,
                    '&:hover': {
                        backgroundColor: theme.palette.background.tableRowHover + " !important"
                    }
                }}
                onMouseEnter={() => setMoreVisible(true)}
                onMouseLeave={() => setMoreVisible(false)}
            >
                {/* {serTemp} */}



                <TableCell align="center" style={{ width: "2.5vw", maxWidth: "2.5vw" }} onClick={handleClick}>{index + 1}</TableCell>
                {HackSawUrl === "-" ? (
                    <TableCell allign="center" onClick={handleClick}><Box>{Server}</Box></TableCell>
                ) : (
                    <TableCell allign="center">
                        <Link to={HackSawUrl} target='_blank' className="tasklist-projects-url-link"
                            style={{ textDecoration: "none", color: "inherit" }}
                            onMouseEnter={(event) => {
                                event.target.style.color = theme.palette.primary.main;
                            }}
                            onMouseLeave={(event) => {
                                event.target.style.color = "inherit";
                            }}>
                            <TextOnlyTooltip title={<span style={{ fontFamily: 'Poppins', fontSize: '0.875rem', color: "Black", backgroundImage: "" }}>{BuildComment} </span>} followCursor >
                                {Server}
                            </TextOnlyTooltip>
                        </Link>
                    </TableCell>
                )}
                <TableCell align="center" style={{ maxWidth: "20vw", wordWrap: "break-word" }}>
                    <Link to={BuildURL} target='_blank' className="tasklist-projects-url-link"
                        style={{ textDecoration: "none", color: "inherit" }}
                        onMouseEnter={(event) => {
                            event.target.style.color = theme.palette.primary.main;
                        }}
                        onMouseLeave={(event) => {
                            event.target.style.color = "inherit";
                        }}>
                        <TextOnlyTooltip title={<span style={{ fontFamily: 'Poppins', fontSize: '0.875rem', color: "Black", backgroundImage: "" }}>{BuildComment} </span>} followCursor >
                            {/* {BuildLabel} */}
                            {BuildURL != null && BuildURL.includes("milestone") ? ( 
                                <>
                                    
                                    {BuildLabel}
                                    <br></br>
                                    [Milestone]
                                    
                                </>
                                )
                                :
                                (
                                    // {BuildLabel}
                                    <>
                                     {BuildLabel}
                                    </>
                                )}
                        </TextOnlyTooltip>
                    </Link>
                </TableCell>
                <TableCell align="center" onClick={handleClick}>{BuildType}</TableCell>
                <TableCell align="center" className='slide-animation' style={{ width: "5vw" }} onClick={handleClick}><GetNameAndEmpID emails={BuildBy} /></TableCell>
                {approvedData === true ? (
                    <TextOnlyTooltip title={<span style={{ fontFamily: 'Poppins', fontSize: '0.875rem', color: "Black", backgroundImage: "" }}>{ApprovedOrDeclinedBy}<br></br>{ApprovedOrDeclinedOn}</span>} followCursor >
                        <TableCell align="center">{ApprovalStatus}</TableCell>
                    </TextOnlyTooltip>)
                    :
                    (<TextOnlyTooltip title={<span style={{ fontFamily: 'Poppins', fontSize: '0.875rem', color: "Black", backgroundImage: "" }}>{ApprovedOrDeclinedBy}<br></br>{ApprovedOrDeclinedOn}<br></br>{DeclineReason} </span>} followCursor >
                        <TableCell align="center">{ApprovalStatus}</TableCell>
                    </TextOnlyTooltip>)}

                <TableCell align="center" style={{ width: "5.5vw", maxWidth: "5.5vw" }} onClick={handleClick}>{RequestedTime}</TableCell>
                <TableCell align="left" className='slide-animation' style={{ width: "30px" }}>
                    {Object.keys(status).length !== 0 && (

                    <IconButton
                        size="small"
                        onClick={handleClick}
                    >
                        {open === null ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                    </IconButton>
                     )}
                </TableCell>

            </TableRow>



            <TableRow className="info-div-row" sx={{ backgroundColor: theme.palette.background.infoDivOuter + " !important" }}>
                <TableCell colSpan={8} style={{ padding: 0 }}>
                    <Collapse in={open !== null} timeout="auto" unmountOnExit>
                        <Box className="info-div-paper"
                            sx={{
                                padding: "20px",
                                margin: "10px",
                                backgroundColor: theme.palette.background.infoDivInner + " !important"
                            }}>
                            <InfoDiv Server={Server} BuildLabel={BuildLabel} row={row} />
                        </Box>
                        {/* <CombinedInfo   index={index}  MilestoneName={dataAsArray1.map((item) => item[1])} MilestoneStartDate={dataAsArray1.map((item) => item[8])} MilestoneDueDate={dataAsArray1.map((item) => item[9])} Status={dataAsArray1.map((item) => item[5])} MilestoneId={dataAsArray1.map((item) => item[0])} DRI={dataAsArray1.map((item) => item[12])} Server={dataAsArray1.map((item) => item[4])} Tasklistid={dataAsArray1.map((item) => item[7])} TasklistPriority={dataAsArray1.map((item) => item[13])} MilestoneDescription={dataAsArray1.map((item) => item[14])} MilestoneStatus={dataAsArray1.map((item) => item[15])} TasklistStatus={dataAsArray1.map((item) => item[6])}/> */}
                    </Collapse>
                </TableCell>

            </TableRow>

        </>


    );
}

function extractBuildData(obj, configuration) {
    const dcMap = configuration.current[`build.DC_columnName_map`];
    // const dcMap = {
    //     "AR1": "AR1_Data",
    //     "AR2": "AR2_Data",
    //     "AU1": "AU1_Data",
    //     "AU2": "AU2_Data",
    //     "CA1": "CA1_Data",
    //     "CA2": "CA2_Data",
    //     "CN2": "CN2_Data",
    //     "CN3": "CN3_Data",
    //     "CSEZMain": "CSEZ_main_Data",
    //     "CSEZPre": "CSEZ_pre_Data",
    //     "CT2": "CT2_main",
    //     "EU1": "EU1_Data",
    //     "EU2": "EU2_Data",
    //     "IN1": "IN1_Data",
    //     "IN2": "IN2_Data",
    //     "JP1": "JP1_Data",
    //     "JP2": "JP2_Data",
    //     "LOCALMain": "LOCAL_main_Data",
    //     "LOCALPre": "LOCAL_pre_Data",
    //     "UBI1": "UBI1_Data",
    //     "UBI2": "UBI2_Data",
    //     "UK1": "UK1_Data",
    //     "UK2": "UK2_Data",
    //     "US3": "US3_main_Data",
    //     "US4": "US4_main_Data",
    //     "US3PRE": "US3_pre_Data",
    //     "US4PRE": "US4_pre_Data"
    // };
    var result = {};
    for (const key in dcMap) {
        const value = dcMap[key];
        if (obj[value] !== null && obj[value] !== undefined) {
            try {
                result[key] = JSON.parse(obj[value]);
            } catch (e) { }
        }
    }
    return result;
}

export default SelfMyTable;



