const CHANNEL_UNIQUE_NAME = "testchannelwig";
const POST_MESSAGE_IN_CHANNEL_API_URL = `/server/cliqIntegration/postInSupportChannel`;
const HTTP_METHOD = `POST`;

export async function dispatchIssueInSupportChannel(controller, user_email, image, message, blob) {

    var body = {
        "image": image.split(",")[1],
        "message": message,
        "user_email": user_email,
        "mime_type": blob.type,
        "name": blob.name
    };

    return await fetch(POST_MESSAGE_IN_CHANNEL_API_URL, {
        method: HTTP_METHOD,
        body: JSON.stringify(body),
        signal: controller.signal
    }).then(response => {
        return Promise.resolve(response);
    }).catch(error => {
        return Promise.reject(error);
    });

}