import { isDateInRange, isDateString } from "../../dashboardMain/DashboardUtil";

export function getChartData(catalystDB, startTime) {

    const chartData = [];
    const milestoneIdMap = {};
    
    const milestoneCountMap = {};

    catalystDB.TaskDetails.map(item => {

        if([undefined, null, "", " "].includes(item.MilestoneId)) {
            return;
        }

        if(item.MilestoneId in milestoneIdMap) {
            return;
        } else {
            milestoneIdMap[item.MilestoneId] = true;
        }

        const dateString = item.CREATEDTIME;


        if(isDateString(dateString) && isDateInRange(dateString, startTime)) {
            const completionDateString = item.MilestoneCompletionDate;

            if([undefined, null, "", " "].includes(completionDateString)) {
                return;
            }

            if(completionDateString in milestoneCountMap) {
                milestoneCountMap[completionDateString] += 1;
            } else {
                milestoneCountMap[completionDateString] = 1;
            }
        }
    }); 

    
    var keys = Object.keys(milestoneCountMap);
    keys.sort((a, b) => {
        return new Date(a).getTime() < new Date(b).getTime() ? -1 : 1;
    })

    for(const key of keys) {
        chartData.push([
            key,
            milestoneCountMap[key]
        ]);
    }

    return chartData;
}