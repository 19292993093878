import { APP_DATA_FETCHER_1_DEFAULT, APP_DATA_FETCHER_2_DEFAULT } from "./catalystDB/queryGenerator";

export function getFromTeamDetails(queryString = APP_DATA_FETCHER_1_DEFAULT)
{
	return new Promise((resolve, reject) => {
		const start = global.performance.now();
		var zcql = window.catalyst.ZCatalystQL;
		var zcqlPromise  = zcql.executeQuery(queryString);

		zcqlPromise
				.then((response) => {
					// console.log(response);
					const end = global.performance.now();
					// console.log(`took ${end-start} ms`);

					if(response.status === 400)
					{
						reject("error");
					}

					var data = [];
					for(const item of response.content)
					{
						data.push(item);
					}
					if(data.length === 0)
					{
						reject("none");
					}
					resolve(data);
				})
				.catch((err) => {
					reject(err);
				});
	});
}

export function getTokenDetails(queryString = APP_DATA_FETCHER_2_DEFAULT)
{
	return new Promise((resolve, reject) => {
		const start = global.performance.now();
		var zcql = window.catalyst.ZCatalystQL;
		var zcqlPromise  = zcql.executeQuery(queryString);

		zcqlPromise
				.then((response) => {
					// console.log(response);
					const end = global.performance.now();
					// console.log(`took ${end-start} ms`);

					if(response.status === 400)
					{
						reject("error");
					}

					var data = [];
					for(const item of response.content)
					{
						data.push(item);
					}
					// if(data.length === 0)
					// {
					// 	resolve(data);
					// }
					resolve(data);
				})
				.catch((err) => {
					reject(err);
				});
	});
}
